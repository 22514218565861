// React
import React, { useState } from 'react';

// Configs
import Configs from '../Configs';

// Dropdowns
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// I18N
import {t} from 'i18next'; 

// Icons
import { RiOpenaiFill, RiQuestionAnswerFill } from 'react-icons/ri';

// --------------------------------------------------
// Modal
// --------------------------------------------------
function QuestionItem(props) {
    // Default values
    const TYPES = [
        {label:t('pulse.questions.type.standard'), value: 'standard'}, 
        {label:t('pulse.questions.type.open'), value: 'open'},
    ];

    const CATEGORIES = [
        {label:t('pulse.questions.category.Leadership'), value: 'Leadership'},
        {label:t('pulse.questions.category.Work Environment'), value: 'Work Environment'},
        {label:t('pulse.questions.category.Team'), value: 'Team'},
        {label:t('pulse.questions.category.Satisfaction'), value: 'Satisfaction'},
        {label:t('pulse.questions.category.Manager'), value: 'Manager'},
        {label:t('pulse.questions.category.Environment'), value: 'Environment'},
        {label:t('pulse.questions.category.Engagement'), value: 'Engagement'},
        {label:t('pulse.questions.category.Diversity, Equity, Inclusion'), value: 'Diversity, Equity, Inclusion'},
        {label:t('pulse.questions.category.Culture'), value: 'Culture'},
    ];

    const SUBCATEGORIES = [

        {label:t('pulse.questions.topic.WorkLife'), value: 'WorkLife'},
        {label:t('pulse.questions.topic.Role') , value: 'Role'},
        {label:t('pulse.questions.topic.Motivation'), value: 'Motivation'},
        {label:t('pulse.questions.topic.Leadership'), value: 'Leadership'},
        {label:t('pulse.questions.topic.Teamwork'), value: 'Teamwork'},
        {label:t('pulse.questions.topic.Satisfaction'), value: 'Satisfaction'},
        {label:t('pulse.questions.topic.Goals'), value: 'Goals'},
        {label:t('pulse.questions.topic.Diversity, Equity, Inclusion'), value: 'Diversity, Equity, Inclusion'},
        {label:t('pulse.questions.topic.Career'), value: 'Career'},
        {label:t('pulse.questions.topic.Innovation'), value: 'Innovation'},
        {label:t('pulse.questions.topic.eNPS'), value: 'eNPS'},
        {label:t('pulse.questions.topic.Empowerment'), value: 'Empowerment'},
        {label:t('pulse.questions.topic.Feedback'), value: 'Feedback'},
        {label:t('pulse.questions.topic.Productivity'), value: 'Productivity'},
        {label:t('pulse.questions.topic.Recognition'), value: 'Recognition'},        
        {label:t('pulse.questions.topic.Communication'), value: 'Communication'},
        // {label:"Walk The Talk", value: 'Walk The Talk'},
    ];

    const RESPONSE_STRINGS = [
        t('strongly-agree'),
        t('agree'),
        t('neutral'),
        t('disagree'),
        t('strongly-disagree')
    ]

    const [question, setQuestion] = useState('');
    const [topic, setTopic] = useState('');
    const [type, setType] = useState('');
    const [category, setCategory] = useState('');
    const [allowEmpty, setAllowEmpty] = useState(false);
    const [responses, setResponses] = useState(['', '', '', '', '']);

// --------------------------------------------------
// Functions
// --------------------------------------------------
    // Handlers
    const handleResponseChange = (index, value) => {
        const newResponses = [...responses];
        newResponses[index] = value;
        setResponses(newResponses);
    };

    // Submit
    async function validateInput () {
        if (question === '') {
            alert('Please enter a question');
            return false;
        }
        if (type === '') {
            alert('Please select a type');
            return false;
        }
        if (type.value === 'standard') {
            if (responses.some((response) => response === '')) {
                alert('Please enter all responses');
                return false;
            }
        }

        // Create object to send
        const auxQuestion = {
            Question_ESP: question,
            Topic: topic.value,
            type: type.value,
            Category: category.value,
            Resp1_String_ESP: responses[0],
            Resp2_String_ESP: responses[1],
            Resp3_String_ESP: responses[2],
            Resp4_String_ESP: responses[3],
            Resp5_String_ESP: responses[4],
            Rather_Not_Answer: allowEmpty ? 1 : 0,
        }
        
        // Create question
        props?.createQuestion(auxQuestion);
    };

// --------------------------------------------------
// Render
// --------------------------------------------------
    if (!props?.isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-700 flex items-center justify-center z-50 ">
            <div className="bg-white w-full h-full relative overflow-y-auto">
                {/* Header */}
                <div className='flex flex-col'>
                    <div className="flex flex-row justify-between bg-gray-100 p-6 items-center">
                            <h2 className="flex text-2xl font-bold items-center">
                            <RiQuestionAnswerFill className='mr-4' /> {t('pulse.questions.create')}
                            </h2>
                        <button onClick={props?.onClose} className=" text-gray-700 hover:text-gray-900 font-bold text-2xl">
                            X
                        </button>
                    </div>
                    <div className='flex flex-row'>
                        <span className='title-description m-4 mt-8 ml-8'>
                             {t('pulse.questions.create.description')}
                        </span>
                    </div>
                </div>

                {/* Form */}
                <div className="p-8 pt-4  text-sm">
                    {/* Question */}
                    <h3 className="font-medium mb-2 ">{t('pulse.questions.create.question')}</h3>
                    <input 
                        type="text"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder={t('pulse.questions.create.question.example')}
                        className="input-field w-full mb-4"
                    />
                    {/* To-Do: Question OpenAI suggestion */}
                    {/* <RiOpenaiFill /> */}

                    <h3 className="font-medium mb-2 ">{t('pulse.questions.type')}</h3>

                    <Select
                        options={TYPES}
                        id="Type"
                        isSearchable
                        value={type}
                        placeholder={t('select-an-option')}
                        styles={Configs.formDropdownStyle}
                        onChange={(e) => setType(e)}
                        /> 

                    {/* Answers */}
                    {type.value === 'standard' ? 

                    <React.Fragment>
                        <h3 className="mt-4 font-medium mb-2 ">{t('pulse.questions.create.answers')}</h3>
                        {[...Array(5)].map((_, index) => (
                            <input 
                                key={index}
                                type="text"
                                value={responses[index]}
                                onChange={(e) => handleResponseChange(index, e.target.value)}
                                placeholder={RESPONSE_STRINGS[index]}
                                className="w-full p-2 border rounded mb-2"
                            />
                        ))}
                        <label className="flex items-center mb-4">
                            <input type="checkbox" value={allowEmpty} onChange={(e) => setAllowEmpty(e.target.checked)} className="mr-2" />
                            {t('pulse.questions.create.allow-rna')}
                        </label>

                        </React.Fragment>
                    : 
                    
                    null
                }

                    {/* Categorization */}
                    <div className="mt-8 flex flex-col">

                    <span className="mb-2 font-medium ">{t('pulse.questions.create.category')}</span>
                    <CreatableSelect
                                    options={CATEGORIES}
                                    id="Category"
                                    isSearchable
                                    value={category}
                                    placeholder={t('select-an-option')}
                                    styles={Configs.formDropdownStyle}
                                    onChange={(e) => setCategory(e)}
                                    />    
                                                    
                                                
                        <span className="mt-4 font-medium mb-2">{t('pulse.questions.create.topic')}</span>
                        <CreatableSelect
                                options={SUBCATEGORIES}
                                id="Topic"
                                isSearchable
                                value={topic}
                                placeholder={t('select-an-option')}
                                styles={Configs.formDropdownStyle}
                                onChange={(e) => setTopic(e)}
                                /> 
                    </div> 
                    
                </div>
                {/* Footer */}
                <div className='flex flex-col'>
                    <div className="flex flex-row justify-end p-6 items-center">
                        <button type="button" id="cancel" className="secondary-button " onClick={props?.onClose}> {t('cancel')} </button>
                        <button id="create" className='save-button' onClick={validateInput}>{t('pulse.questions.create')}</button>                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuestionItem;