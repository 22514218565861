import React, {useState, useEffect} from "react";
import Configs from '../Configs';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';



/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function CollapsibleListItem(props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

// Props
const [collapsed, setCollapsed] = useState (props.collapsed);
const title = props.title;
const content = props.content;
const editURL = props.editURL;

// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        // calculateResult(selectedValues);
}, []);


// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-col m-1 border border-gray rounded p-8 ml-6 mr-6 justify-between items-center'>
        <button className="flex flex-row w-full justify-between items-center" onClick={() => setCollapsed(!collapsed)}>
            <div className='flex flex-col items-start'> 
                <span className='text-sm mr-2'>{t('title')}</span>
                <span className="text-l font-bold"> {title} </span>
            </div>
            <div className="flex">
            {collapsed ?
                        <React.Fragment>
                            <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                                <path d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z" fill="currentColor" fill-rule="evenodd">
                                </path>
                            </svg>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div>
                                <svg fill="none" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-up" class="inline h-1.5">
                                    <path d="M12.085 6.23a1.09 1.09 0 01-.004 1.477 1.093 1.093 0 01-1.605.005l-3.84-4.17a.544.544 0 00-.8 0l-3.84 4.17a1.093 1.093 0 01-1.604-.005A1.093 1.093 0 01.388 6.23L5.436.747a1.088 1.088 0 011.6 0l5.05 5.482z" fill="currentColor" fill-rule="evenodd">
                                    </path>
                                </svg>
                            </div>

                        </React.Fragment>
            }
            </div>
        </button>


        <div className={collapsed ? 
            "hidden"
            :
            "flex flex-row items-start text-left w-full mt-8 justify-between"}>
                <div className='flex flex-col items-start w-full'> 
                    <span className='text-sm mr-2'>{t('notes')}</span>
                    <textarea 
                    className="text-l w-full"
                    id="meetingContent"
                    rows="10"
                    type="text"
                    value={content}
                    disabled={true} /> 
                </div>
                {editURL ? 
                
                    <div className="flex flex-col items-center">
                        <span className='text-sm mr-2'>{t('action')}</span>
                        <Link to={editURL}>
                            <span>✏️</span>
                        </Link>
                        

                    </div>
                :
                    null
                }
        </div>
    </div>

  );
}

export default CollapsibleListItem;