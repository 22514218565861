// React
import React, {useState, useEffect} from "react";

// Configs & Widgets 
import Configs from '../Configs';
import { useData } from "../common/dataContext";

// 3P
import {XYPlot, XAxis, YAxis, HorizontalGridLines, DiscreteColorLegend, AreaSeries,  LineMarkSeries, LabelSeries, Hint} from 'react-vis';
import '../../node_modules/react-vis/dist/style.css';
import { useTranslation } from 'react-i18next';

// ------------------------------------------------------------------------------------------
/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function KPIGraph({
    data
}) {
// Translation object
const {t} = useTranslation();

const [graphData, setGraphData] = useState ([]);
const [xAxisValues, setxAxisValues] = useState ([]);
const [labelsSeries, setLabelsSeries] = useState ([]);
const [showHint, setShowHint] = useState(false);
const maxY = data?.reduce((max, p) => p.metricValue > max ? p.metricValue : max, data[0].metricValue);

// Company personalization
// const {companyPrefereneces} = useData();

// Line colors
// const scoreColor = companyPrefereneces?.personalization?.emailsHeaderColor ? companyPrefereneces?.personalization?.emailsHeaderColor : Configs.companyColor;
// const benchmarkColor = companyPrefereneces?.personalization?.emailsHeaderSecondaryColor ? companyPrefereneces?.personalization?.emailsHeaderSecondaryColor : Configs.companyTerciaryColor;
const scoreColor = "#4F46E5";

// Label element offset
const labelOffsetX = 6;
const labelOffsetY = 30;
const fontSize = 12;
const fontBold = 600;
const maxData = 5;

if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][main()] Props: ", data);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate graphs
        if (data) KPIGraph(data);
}, [data]);

// ------------------------------------------------------------------------------------------
// Frontend support methods
// ------------------------------------------------------------------------------------------
async function KPIGraph (inputData) {
    // if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Input data: ", inputData);

    const inputDataAux = inputData?.slice(0, maxData);

    var dataAux = [];
    var xAxisAux = [];
    var labelsSeriesAux= [];

    // if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Input data for graph: ", inputData);

 
    // For each input data, update Aux
    inputDataAux?.forEach((datapoint, index) => {
    //   if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Input data for graph (datapoint): ", datapoint, index);

      // Calculating where to place it in the X graph axis
      var xAux = maxData - index -1;
      
      // Create data object
      var objectAux = {
        x: xAux,
        date: datapoint?.month + "/" + datapoint?.year,
        y: datapoint?.metricValue,
      };

      // Object for labels
      var labelObjectAux = {
        x: xAux,
        date: datapoint?.month + "/" + datapoint?.year,
        y: datapoint?.metricValue,
        label: datapoint?.metricValue ? Number(datapoint?.metricValue).toFixed(1) : 0,
        xOffset: labelOffsetX,
        yOffset: (datapoint?.metricValue === 0 ? -10 : labelOffsetY),
        style: {fontSize: fontSize, fontWeight: fontBold},
      }

      // push to Arrays
      dataAux.push(objectAux);
      xAxisAux.push(objectAux.date);
      labelsSeriesAux.push(labelObjectAux);
    });
    
    if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Graph data: ", dataAux);
    if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Axis data: ", xAxisAux);
  
    // Retun data
    setGraphData([...dataAux]);
    setLabelsSeries([...labelsSeriesAux]);
    // Reverse axis data to fit the graph
    setxAxisValues([...xAxisAux]);

  }
  

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
              
          <div className="flex flex-row items-center">
                    <div className='flex flex-col rounded items-center m-4 text-sm m-1 w-full'>
                          <div className='flex flex-col lg:flex-row overflow-x-auto'>
                            <XYPlot 
                              height={150} 
                              width={300}
                              margin={{left: 20, right:20, top:25,bottom:25}}
                              xDomain={[0, maxData-1]}
                              yDomain={[0, maxY]}
                              >
                              

                            <XAxis 
                                tickFormat={index => xAxisValues[maxData-1 - index]}
                             />
                            
                            <HorizontalGridLines tickValues={[1,maxY/2,maxY]} />

                                    <AreaSeries
                                        className=""
                                        stroke={scoreColor}
                                        opacity={0.1}
                                        curve="curveNatural"
                                        data={graphData}
                                        fill={scoreColor}
                                        />
                                  <LineMarkSeries
                                    className="mark-series-example"
                                    curve={'curveNatural'}
                                    strokeWidth={2}
                                    opacity={1}
                                    color = {scoreColor}
                                    data={graphData}

                                    onValueMouseOver={(datapoint, event)=>{
                                    // does something on click
                                    // you can access the value of the event
                                        setShowHint(datapoint);
                                    }}
                                    onValueMouseOut={() => setShowHint(false)}
                                    />
                                
                                    {/* <LabelSeries 
                                      allowOffsetToBeReversed
                                      animated
                                      data={labelsSeries} 
                                      getLabel={d => d.label}
                                      /> */}

                                    {showHint ? <Hint className='rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50' value={showHint} /> : null}


                            </XYPlot>

                          </div>


                        </div>
            </div>

  );
}

export default KPIGraph;

