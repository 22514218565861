// React
import React, { useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

// Frameworks
import Configs from '../../Configs';
import CryptoJS from 'crypto-js';
import { t } from 'i18next';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Headers
import SuperAdminHeader from './superAdminHeader';
import Header from '../../common/header';
import Bot from '../../common/support/bot';
import UserListItem from '../../widgets/userListItem';
import { useAuth } from '../../common/appContext';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import NoneditableEditor from '../../widgets/noneditableEditor';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';

// Icons
import {AiOutlineInfoCircle} from 'react-icons/ai';
import Tooltip from '../../common/support/tooltip';


function CompanyCreate () {

    // Params for edition
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    const navigate = useNavigate();

    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const {token, companyID, employeeID} = useAuth();
    const securityLevel = sessionStorage.getItem('securityLevel') ? CryptoJS.AES.decrypt(sessionStorage.getItem('securityLevel'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;

    // Toaster & Loader
    const [toasterMessage, setToasterMessage] = useState(null);
    const [isCompanyLoading, setIsCompanyLoading] = useState(false);

    // Form fields
    const [companyName, setCompanyName] = useState(null);
    const [taxID, setTaxID] = useState(null);
    const [companyEmail, setCompanyEmail] = useState(null);
    const [companyPhone, setCompanyPhone] = useState(null);
    const [companyAddress, setCompanyAddress] = useState(null);
    const [companyCity, setCompanyCity] = useState(null);
    const [companyPostalCode, setCompanyPostalCode] = useState(null);
    const [companyCountry, setCompanyCountry] = useState(null);
    const [authentication, setAuthentication] = useState(null);
    const [googleHD, setGoogleHD] = useState(null);
    const [azureTID, setAzureTID] = useState(null);

    // Dropdowns
    const [countries, setCountries] = useState(null);
    const [cities, setCities] = useState(null);

// ---------------------------------------------------------------------------------------------------
// Methods to load when page is loaded
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        if (params) {
            // getEmployee() 
        } else {
            // 
        }

        // Populate all dropdowns
        populateDropdowns();
      }, []);      



// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------
    async function postNewCompany (companyObject) {
        // Post new employee
        // Indicate loading status
        setIsCompanyLoading(true);

        // fetch from server
        fetch(Configs.platformAdminPostNewCompanyAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
            method: 'post',
            credentials: 'include',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}` // notice the Bearer before your token
            },      
            body: JSON.stringify({
                companyID: companyID,
                employeeID: employeeID,
                newCompany: companyObject
            }),
            })
              .then((response) => {
                setIsCompanyLoading(false);

                // if (Configs.devEnvironment) console.log (response);
                if (response.status === 200) {
                  setToasterMessage({message: t('success'), type: Configs.successToaster});
    
                  // Clear frontend fields
                  setCompanyName(null);
                  setTaxID(null);
                  setCompanyCountry(null);


                // Clear "cachés" to ensure re-load of employees
                sessionStorage.removeItem('companyMembers');
                sessionStorage.removeItem('organigram');
                sessionStorage.removeItem('teamDirects');
                        
                  // Pass toaster message and go back
                  navigate(-1, { state: { toasterMessage: {message:(t('success')), type: Configs.successToaster} } });
    
                } else if (response.status === 400) {
                  // Something went wrong - inform user
                  setToasterMessage({message: t('warning'), type: Configs.warningToaster});
                } else if (response.status === 401) {
                    // Force logout
                    setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                      sessionStorage.clear();
                      window.location.reload();
                } else if (response.status === 403) {
                      // Force logout
                      setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                      sessionStorage.clear();
                      window.location.reload();
                } else if (response.status === 404) {
                      setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
                } else if (response.status === 500) {
                  //
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
                  
                } else {

                    throw response;
                }
              })
        
              .catch((error) => {
                console.error("[companyCreate.js][postNewEmployee()] error:", error);
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
                setIsCompanyLoading(false);
              });      
        
    }
// ---------------------------------------------------------------------------------------------------
// Form management methods
// ---------------------------------------------------------------------------------------------------  
async function cancel () {
    // Go back to previous page
    navigate(-1);
}

async function validateInputsCreate () {
    if (Configs.devEnvironment) console.log("[companyCreate.js][validateInputsCreate()] - START");
    
    // Check if the inputs are correct - inputs are: employeeName, employeeSurname, area, subArea, role, manager, level
    if (!companyName || !taxID || !companyCountry || !companyEmail) {
        setToasterMessage({type: Configs.warningToaster, message: t('field.missing')});
        return false;
    }
    
    // Create auxObject to be uploaded to the database
    var auxNewCompany = {
        name: companyName,
        taxID: taxID,
        country: companyCountry,
        email: companyEmail,
        timestamp: new Date(),
    }
    if (Configs.devEnvironment) console.log("[companyCreate.js][validateInputsCreate()] auxNewCompany: ", auxNewCompany);

    // Post employee
    postNewCompany(auxNewCompany);
}

async function validateInputsEdit  () {
    // Pending
}

async function populateDropdowns () {
    // used to populate countries and cities dropdowns
    // Get countries

    

}

async function createOption (e, type) {
    if (Configs.devEnvironment) console.log("[companyCreate.js][createOption()] Creating option:", e, type);

    switch (type) {
        case 'country':
            // Add area to current list of subareas
            let countriesAux = countries;
            countriesAux.push({value: e, label: e});
            
            // Order alphabetically
            countriesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setCountries([...countriesAux]);
            setCompanyCountry(e);
            break;
        case 'city':
            // Add subarea to current list of subareas
            let citiesAux = cities;
            citiesAux.push({value: e, label: e});
            
            // Order alphabetically
            citiesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setCities([...citiesAux]);
            setCompanyCity(e);
            break;
        default:
            break;

    }
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
};

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
return (
      <React.Fragment>
      <Header />

      <Bot/>

      <div className="main">

        <SuperAdminHeader />

        <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
            
            <div className="flex flex-col mt-2 md:ml-2 m-1">
                    <div className='flex flex-col'>
                        <h2 className="flex text-xl mb-4 ">
                        <div className='flex flex-col md:flex-row justify-left'>
                            {params ? 
                                <Tooltip content={t('admin.company.edit.description')} >
                                    <div id="title" className='section-title'>
                                        {t('admin.company.edit.title')}
                                    </div>
                                </Tooltip>
                                :
                                <Tooltip content={t('admin.company.create.description')} >
                                    <div id="title" className='section-title'>
                                        {t('admin.company.create.title')}
                                    </div>
                                </Tooltip>
                            }

                        </div>
                        </h2>
                    </div>
                    
                </div>
                       
                        <React.Fragment>
                            
                                    <div className="flex flex-col mt-2 lg:ml-2 m-1 space-y-8 w-full lg:w-2/3 text-sm">

                                        {isCompanyLoading ?
                                        <Loader/>
                                        :
                                        <React.Fragment>
                                                {/* Company name */}
                                                <div class="flex flex-col lg:flex-row lg:items-center lg:space-x-4 text-left ">
                                                <div className='flex lg:w-[80px]'>
                                                        <label for="name" className="block text-sm font-medium leading-6 text-gray-900">
                                                            {t('name')}:
                                                        </label>
                                                    </div>
                                                    <div className='flex w-full'>
                                                        <div class="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 ">
                                                        <input type="text" 
                                                            id="name" 
                                                            value={companyName} 
                                                            onChange={(e) => {setCompanyName(e.target.value)}}
                                                            className="bg-white block flex-1 border border-gray-200 rounded py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                            placeholder={t('admin.company.name.placeholder')} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* VAT/GST */}
                                                <div class="flex flex-col lg:flex-row lg:items-center lg:space-x-4 text-left ">
                                                <div className='flex lg:w-[80px]'>
                                                        <label for="surname" class="block text-sm font-medium leading-6 text-gray-900">
                                                            {t('VAT/GST')}:
                                                        </label>
                                                    </div>
                                                    <div className='flex w-full'>
                                                        <div class="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 ">
                                                            <input type="text" 
                                                            id="surname" 
                                                            value={taxID} 
                                                            onChange={(e) => {setTaxID(e.target.value)}}
                                                            className="bg-white block flex-1 border border-gray-200 rounded py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                            placeholder={t('admin.company.taxID.placeholder')} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Country */}
                                                <div class="flex flex-col lg:flex-row lg:items-center lg:space-x-4 text-left ">
                                                    <div className='flex lg:w-[80px]'>
                                                            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">
                                                                {t('admin.company.country')}:
                                                            </label>
                                                        </div>
                                                        <div className='flex w-full'>
                                                            <div class="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 ">
                                                                <input type="text" 
                                                                id="companyCountry" 
                                                                value={companyCountry} 
                                                                onChange={(e) => {setCompanyCountry(e.target.value)}}
                                                                className="bg-white block flex-1 border border-gray-200 rounded py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                                placeholder={t('admin.company.country.placeholder')} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                {/* Employee e-mail (=username) */}
                                                <div class="flex flex-col lg:flex-row lg:items-center lg:space-x-4 text-left ">
                                                    <div className='flex lg:w-[80px]'>
                                                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
                                                            {t('email')}
                                                        </label>
                                                    </div>
                                                    <div className='flex w-full'>
                                                        <div class="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 ">
                                                            <input 
                                                            type="text" 
                                                            id="email" 
                                                            value={companyEmail} 
                                                            onChange={(e) => {setCompanyEmail(e.target.value)}}
                                                            className="bg-white block flex-1 border border-gray-200 rounded py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                            placeholder={t('admin.company.email.placeholder')} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <SectionSeparator />

                                                {/* Authentication mechanism - radio button between Google or Azure */}
                                                <div class="flex flex-col lg:flex-row lg:items-start lg:space-x-4 text-left ">
                                                    <div className='flex lg:w-[80px]'>
                                                        <label for="authentication" class="block text-sm font-medium leading-6 text-gray-900">
                                                            {t('admin.company.authentication')}
                                                        </label>
                                                    </div>
                                                    <div className='flex w-full'>
                                                        <div class="flex full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 ">
                                                            <div className='flex flex-col w-full p-4 space-y-2'>
                                                                <div className='flex flex-row items-center'>
                                                                    <input type="radio" onClick={() => setAuthentication("google")} id="google" name="authentication" value="google" className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300" />
                                                                    <label for="google" class="ml-3 block text-sm font-medium leading-5 text-gray-700">
                                                                        {t('admin.company.authentication.google')}
                                                                    </label>
                                                                </div>
                                                                <div className='flex flex-row items-center'>
                                                                    <input type="radio" onClick={() => setAuthentication("azure")} id="azure" name="authentication" value="azure" className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300" />
                                                                    <label for="azure" class="ml-3 block text-sm font-medium leading-5 text-gray-700">
                                                                        {t('admin.company.authentication.azure')}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="flex flex-col lg:flex-row lg:items-start lg:space-x-4 text-left ">
                                                    {authentication === 'azure' ?
                                                        <React.Fragment>
                                                            {/* Azure tid field */}
                                                            <div class="flex flex-col lg:flex-row lg:items-center lg:space-x-4 text-left ">
                                                                <div className='flex lg:w-[80px]'>
                                                                    <label for="azureTID" class="block text-sm font-medium leading-6 text-gray-900">
                                                                        {t('admin.company.azureTID')}
                                                                    </label>
                                                                </div>
                                                                <div className='flex w-full'>
                                                                    <div class="flex full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 ">
                                                                        <input type="text" id="azureTID" name="azureTID" value={azureTID} onChange={(e) => {setAzureTID(e.target.value)}} 
                                                                        className="bg-white block flex-1 border border-gray-200 rounded py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                                        placeholder={t('admin.company.azureTID.placeholder')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>

                                                        :
                                                        authentication === 'google' ?

                                                        <React.Fragment>
                                                            {/* Google HD input field */}
                                                            <div class="flex flex-col lg:flex-row lg:items-center lg:space-x-4 text-left ">
                                                                <div className='flex lg:w-[80px]'>
                                                                    <label for="googleHD" class="block text-sm font-medium leading-6 text-gray-900">
                                                                        {t('admin.company.googleHD')}
                                                                    </label>
                                                                </div>
                                                                <div className='flex w-full'>
                                                                    <div class="flex full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 ">
                                                                        <input type="text" id="googleHD" name="googleHD" value={googleHD} onChange={(e) => {setGoogleHD(e.target.value)}} 
                                                                        className="bg-white block flex-1 border border-gray-200 rounded py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                                        placeholder={t('admin.company.googleHD.placeholder')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>

                                                        :

                                                        null
                                                    }
                                                </div>


                                            </React.Fragment>
                                        }
                                    </div>

                            </React.Fragment>
                                



                
                            {securityLevel?.includes(Configs.securityLevelSuperAdmin) ? 
                                <div className='flex flex-col m-4 lg:m-2 lg:flex-row items-center justify-end '>

                                    <button type="button" id="cancelButton" class="text-sm font-medium leading-6 text-gray-900 m-8 " onClick={cancel}> {t('cancel')} </button>

                                    {params ? 
                                        <button id="editButton" className='save-button' onClick={validateInputsEdit}>{t('admin.company.edit.title')}</button>
                                    :
                                        <button id="createButton" className='save-button' onClick={validateInputsCreate}>{t('admin.company.create.title')}</button>
                                    }
                                    

                                </div>
                            : 
                                null 
                            }
                      
                            <SectionSeparator />
            

        </div>
        </div>
      </React.Fragment>
    );
}

export default CompanyCreate;