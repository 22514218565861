// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import DaysOffHeader from '../../common/daysOffHeader.js';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import Forbidden from '../../common/forbidden';
import Pagination from '../../widgets/pagination';

// Widgets
import UserListItem from '../../widgets/userListItem';
import FilterWidget from '../../widgets/data/filterWidget.js';
import CalendarWidget from '../../widgets/calendarView.js'
import RequestListItem from '../../widgets/daysOff/requestListItem.js';
import FeedbackPopup from '../../widgets/feedback/feedbackPopUp.js';
import CalendarDetailedView from '../../widgets/calendarDetailedView.js';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip.js';
// import Tooltip from '../../common/support/tooltip';

// --------------------------------------------------------------
function DaysOffTeam () {
    const {t} = useTranslation();
    // const navigate = useNavigate();

    const {token, employeeID, companyID, securityLevel} = useAuth();
    const {teamDirects, isDirectsLoading, teamOrg, isOrgLoading, companyOrg, toasterMessage, setToasterMessage} = useData();
    const {holidaysTeamPendingRequests, setHolidaysTeamPendingRequests, getHolidaysTeamPendingRequests, isHolidaysTeamPendingRequestsLoading, setIsHolidaysTeamPendingRequestsLoading} = useData();
    const {teamHolidayCalendar, isTeamHolidayCalendarLoading, getTeamHolidayCalendar} = useData();
    
    const [filteredData, setFilteredData] = useState(teamDirects);
    const [filteredHolidayRequests, setFilteredHolidayRequests ] = useState(holidaysTeamPendingRequests);

    // Team calendar
    const [teamCalendar, setTeamCalendar] = useState(null);
    const [teamCalendarApproved, setTeamCalendarApproved] = useState(null);
    const [teamCalendarPending, setTeamCalendarPending] = useState(null);
    const [detailedTeamCalendar, setDetailedTeamCalendar] = useState(null);
    const [detailedViewStartDate, setDetailedViewStartDate] = useState(new Date());

    const [resolvingRequestLoading, setResolvingRequestLoading] = useState(false);
    const [isTeamCalendarLoading, setIsTeamCalendarLoading] = useState(false);

    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

    const detailedViewURL = "../timeoff/team/detailed/";

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    if (Configs.devEnvironment) console.log("[daysoffTeam.js][main()] Team Directs: ", teamDirects);
    if (Configs.devEnvironment) console.log("[daysoffTeam.js][main()] teamHolidayCalendar: ", teamHolidayCalendar);
    if (Configs.devEnvironment) console.log("[daysoffTeam.js][main()] filteredHolidayRequests: ", filteredHolidayRequests);


// ---------------------------------------------------------------------------------------------------
// Page start methods
// --------------------------------------------------------------------------------------------------- 
useEffect (() => {
    // Get team calendar
    if (teamHolidayCalendar) transformTeamCalendar(teamHolidayCalendar);

    // if ((teamCalendarApproved  === null) && (teamCalendarPending === null)) {
    //   getTeamHolidayCalendar();
    // }
    if (holidaysTeamPendingRequests) setFilteredHolidayRequests(holidaysTeamPendingRequests);
        
    }, [teamDirects, teamOrg, companyOrg, holidaysTeamPendingRequests, teamHolidayCalendar]);

// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------
  async function resolvePendingRequest (requestID, action, comment) {
    // Load state
    setResolvingRequestLoading(true);

    // fetch from server      
    fetch(Configs.platformPutTimeOffAPI + "?employeeID=" + employeeID + "&companyID=" + companyID +
     "&requestID=" + requestID + "&status=" + action, {
      method: 'put',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      },
      body: JSON.stringify({
        companyID: companyID,
        employeeID: employeeID,
        requestID: requestID,
        status: action,
        comment: comment
        })
      })
      .then((response) => {
          setResolvingRequestLoading(false);

        if (response.status === 200) {
            response.json()
            .then ( body => {
                if (Configs.devEnvironment) console.log("[daysOffTeam.js][resolvePendingRequest()] Body from request: ", body);
                setToasterMessage({message: t('time-off.request-resolved'), type: Configs.successToaster});
                // getPendingRequestsForManager();

                // Remove request from list
                var auxRequests = [...holidaysTeamPendingRequests];
                auxRequests = auxRequests.filter(request => request.requestID !== requestID);
                setHolidaysTeamPendingRequests(auxRequests);

                // Show feedback popup
                setShowFeedbackPopup(true);

                getHolidaysTeamPendingRequests();
            })
            .catch(error => {
              setToasterMessage({message: t('pulse.load.warning'), type: Configs.errorToaster});
              console.error("[daysOffTeam.js][resolvePendingRequest()] Error parsing response JSON", error);
            });
        } else if (response.status === 400) {
          // Something went wrong - inform user
          setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
        } else if (response.status === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            sessionStorage.clear();
            window.location.reload();
        } else if (response.status === 403) {
            // Force logout
            setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            // sessionStorage.clear();
            // window.location.reload();
        } else if (response.status === 404) {
            // No results
            // setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
        } else {
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }
      })
      .catch((error) => {
        console.log("[daysOffTeam.js][resolvePendingRequest()] Error fetching data: ", error);
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
        setResolvingRequestLoading(false);
      });

  }

  async function downloadAttachmentAPI (fileID) {
    if(Configs.devEnvironment) console.log ("[DaysOffTeam.js][downloadAttachmentAPI()] - fileID: ", fileID);

    // fetch from server
    fetch(Configs.platformGetDownloadFileAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&fileID=" + fileID, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      }
      })
        .then((response) => {
          // if (Configs.devEnvironment) console.log("[DaysOffHome.js][downloadAttachmentAPI] - response: ", response);

          if (response.status === 200) {
            response?.blob()
            .then (blob => {
              if (Configs.devEnvironment) console.log ("[DaysOffHome.js][downloadAttachmentAPI] - blob: ", blob);

              // Create a URL for the blob
              const url = window.URL.createObjectURL(blob);
              // Create a temporary anchor element and trigger a download
              const a = document.createElement('a');
              a.href = url;
              a.download = "Sick_" + fileID; // Provide a file name here
              document.body.appendChild(a); // Append to the document
              a.click(); // Trigger the download
              a.remove(); // Clean up
              window.URL.revokeObjectURL(url); // Revoke the blob URL
              })
            .catch(error => {
              console.error("[DaysOffHome.js][downloadAttachmentAPI] Error parsing response BLOB: ", error);
              setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            });
          } else if (response.status === 401) {
            // Force logout

            sessionStorage.clear(); 
            window.location.reload();
          } else {
            //
            throw response;
          }
        })
        .catch((error) => {
          console.error("[DaysOffHome.js][downloadAttachmentAPI] Error fetching data: ", error);
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        });
  }

// ---------------------------------------------------------------------------------------------------
// Requests methods
// ---------------------------------------------------------------------------------------------------
async function approveRequest (e, index) {
  if (Configs.devEnvironment) console.log("[daysOffTeam.js][approveRequest()] Index, request: ", index, currentRows[index]);
  e.preventDefault();

  // Get request ID
  const requestID = currentRows[index]?.requestID;

  // PUT request to server
  resolvePendingRequest(requestID, Configs.daysOffStatusApproved, null);
}

async function rejectRequest (e, index) {
  if (Configs.devEnvironment) console.log("[daysOffTeam.js][rejectRequest()] Index: ", index, currentRows[index]);
  e.preventDefault();

  // Pop-up with comment for rejection
  const comment = prompt(t('time-off.team.request-rejection-comment'));

  if (comment === "" || comment === null) {
    setToasterMessage({message: t('time-off.team.request-rejection-comment'), type: Configs.warningToaster});
    return;
  }

  // Get request ID
  const requestID = currentRows[index]?.requestID;

  // PUT request to server
  resolvePendingRequest(requestID, Configs.daysOffStatusRejected, comment);

}

// ---------------------------------------------------------------------------------------------------
// Data transformation methods
// ---------------------------------------------------------------------------------------------------
function transformTeamCalendar (calendarAux) {
  ///       { employeeID: 1, daysOffApproved: ["2024-05-01", "2024-05-19"], daysOffPending: ["2024-05-02", "2024-05-19"] },
  if (Configs.devEnvironment) console.log("[daysOffTeam.js][transformTeamCalendar()] calendarAux: ", calendarAux);

  // Check if there are events  
  if (calendarAux.length > 0) {
      // Aux arrays
      var auxCalendar = [];
      var auxApproved = [];
      var auxPending = [];

      // Create a set with employeeIDs from teamDirects
      let employeeIDSet = new Set();
      if (securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) {
        employeeIDSet = new Set(companyOrg?.map(employee => employee.employeeID));
      } else {
        employeeIDSet = new Set(teamDirects?.map(employee => employee.employeeID));
      }

      // Iterate across requests, and add dates between startDate and endDate
      calendarAux?.map(request => {
          // Add employee ID to set
          // employeeIDSet.add(request?.employeeID);

          request?.request?.map(date => {
            var auxStartDate = new Date(date?.startDate).setHours(0,0,0,0);
            var auxEndDate = new Date(date?.endDate).setHours(0,0,0,0);
            var currentDate = auxStartDate;

            // Iterate across dates
            while (currentDate <= auxEndDate) {
              if (Configs.devEnvironment) console.log("[daysOffTeam.js][transformTeamCalendar()] Current date: ", currentDate);

              // Add to calendar
              auxCalendar.push({
                employee: request?.employee?.employee,
                leaveType: date?.leaveType,
                date: currentDate,
                status: request?.status
              });

              // Add to approved/pending
              if (date?.status === Configs.daysOffStatusApproved) {
                auxApproved.push(currentDate);
              } else if (date?.status === Configs.daysOffStatusPending) {
                auxPending.push(currentDate);
              }

              // Move to next day
              currentDate = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1)).setHours(0,0,0,0);
            }
          });
      });

      // Iterate across employees
      var auxDetailedCalendar = [];
      employeeIDSet.forEach(employeeID => {
        const employeeInfo = companyOrg?.find(employee => parseInt(employee.employeeID) === parseInt(employeeID));
        var auxEmployee = { 
          employeeID: employeeID,
          employee: employeeInfo.employee,
          manager: employeeInfo.manager,
          managerID: employeeInfo.managerID,
          area: employeeInfo.area,
          subarea: employeeInfo.subarea,
          daysOffApproved: [],
          daysOffPending: [],
          daysOffType: []};

        // Iterate across requests
        calendarAux?.forEach(request => {
          if (parseInt(request?.employeeID) === parseInt(employeeID)) {
            // Check status of request.status and add to the corresponding array
            // Add each date from startDate to endDate
            request?.request?.map(interval => {
              var auxStartDate = new Date(interval?.startDate)
              auxStartDate.setHours(0,0,0,0);
              var auxEndDate = new Date(interval?.endDate)
              auxEndDate.setHours(0,0,0,0);
              var currentDate = new Date(auxStartDate);

              // Iterate across dates
              while (currentDate <= auxEndDate) {

                // Add to daysOffType as object
                auxEmployee.daysOffType.push({date: new Date(currentDate), leaveType: interval?.leaveType});

                // Add to corresponding array
                if (request?.status === Configs.daysOffStatusApproved) {
                  auxEmployee.daysOffApproved.push(new Date(currentDate));

                } else if (request?.status === Configs.daysOffStatusPending) {
                  auxEmployee.daysOffPending.push(new Date(currentDate));
                }

                // Move to next day
                currentDate.setDate(currentDate.getDate() + 1);
                currentDate.setHours(0,0,0,0);
              }
            });
          }
        });

        // Add to detailed calendar
        auxDetailedCalendar.push(auxEmployee);

        if (Configs.devEnvironment) console.log("[daysOffTeam.js][transformTeamCalendar()] auxDetailedCalendar: ", auxDetailedCalendar);

      });

      // Set state
      setDetailedTeamCalendar([...auxDetailedCalendar]);
      setTeamCalendar([...auxCalendar]);
      // setFilteredData([...auxDetailedCalendar]);

    } else {
      setTeamCalendar([]);
    }

  }
// ---------------------------------------------------------------------------------------------------
// Attachment methods
// ---------------------------------------------------------------------------------------------------
const viewAttachmentMethod = (index, row) => {

  if (Configs.devEnvironment) console.log("[daysOffTeam.js][viewAttachmentMethod()] - row: ", row);

  // Download attachment
  downloadAttachmentAPI(row?.fileId);
};

// ---------------------------------------------------------------------------------------------------
// Calendar methods
// ---------------------------------------------------------------------------------------------------

function setTeamPlanningCalendarDate (date) {
  setDetailedViewStartDate(date);
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
        setToasterMessage(null);
    };
      
// ---------------------------------------------------------------------------------------------------
// Filter methods
// ---------------------------------------------------------------------------------------------------   
    const handleFilterChange = (filteredDataInput) => {
        if (Configs.devEnvironment) console.log("[daysOffTeam.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
            setFilteredData(filteredDataInput);

        // Iterate throuw filtered data, and get a new Set with employeeIDs
        let employeeIDSet = new Set(filteredDataInput.map(employee => parseInt(employee.employeeID)));

        // Filter pendingRequests
        const auxHolidayRequests = [...holidaysTeamPendingRequests];
        if (Configs.devEnvironment) console.log("[daysOffTeam.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);

        var auxFilteredRequests = [];

        // Iterate through holidaysTeamPendingRequests and add pendingrequests to auxFilteredRequests
        holidaysTeamPendingRequests?.forEach(request => {
          if (Configs.devEnvironment) console.log("[daysOffTeam.js][handleFilterChange()] request: ", request);

          if (employeeIDSet.has(parseInt(request.employeeID))) {
            auxFilteredRequests.push(request);
          }
        });

        // Filter pending requests
    
        if (Configs.devEnvironment) console.log("[daysOffTeam.js][handleFilterChange()] filteredRequests: ", auxFilteredRequests);

        setFilteredHolidayRequests([...auxFilteredRequests]);
      };

// ------------------------------------------------------------
// Pagination methods
// ------------------------------------------------------------
 // Calculate the indices of the first and last row on the current page
 const indexOfLastRow = currentPage * rowsPerPage;
 const indexOfFirstRow = indexOfLastRow - rowsPerPage;
 const currentRows = filteredHolidayRequests?.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        if (Configs.devEnvironment) console.log("[recognitionHome.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page since the number of pages will change
    };

// ------------------------------------------------------
// Return
// ------------------------------------------------------
return (
    <React.Fragment>
        <Header />
        <Bot/>

        <div className="main">
          <DaysOffHeader />

            {/* Main screen */}
            <div className='flex flex-row items-start'>
              <div className="left-big-view">              

                  {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                  <FeedbackPopup showPopup={showFeedbackPopup} showFeedbackChance={Configs.feedbackPopupChance} 
                  onFeedbackSubmitted={() => setShowFeedbackPopup(false)}/>

                      <div className="flex flex-col mt-2 md:ml-2 m-1">
                        <h2 className="flex text-xl text-left mb-4 ">
                          <div className='flex flex-col md:flex-row justify-left'>
                            <Tooltip content={t('time-off.team.description')}>
                              <div className='section-title'>
                                📅 {t('time-off.team.title')}
                              </div>
                            </Tooltip>
                          </div>
                        </h2>
                        <span className="title-description">
                          {/* Section description */}
                          

                          {/* Self-service resources section */}
                          {/* {i18next.language === 'es' ? 
                                <a href="https://resources.kincode.app/es/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                    <b className='ml-1 hover:underline'>{t('oneOnOne.home.help')} </b>
                                </a>
                                  
                                  : 

                                <a href="https://resources.kincode.app/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                  <b className='ml-1 hover:underline'>{t('oneOnOne.home.help')} </b>
                                </a>
                            } */}
                          
                        </span>
                    </div>
                    {/* Pending requests */}
                  <div className='flex flex-col mb-8'>
                    { isHolidaysTeamPendingRequestsLoading ? 
                      <Loader />
                    :

                    teamCalendar?.length > 0 ?
                    // Pending requests
                      <div className='flex flex-col space-y-2 w-full'>
                          {/* Filters widget */}
                          <div className='flex'>
                              {securityLevel?.includes(Configs.securityLevelHR) && <FilterWidget data={detailedTeamCalendar} onFilterChange={handleFilterChange} companyData={companyOrg}
                              // teamTypeFilter={true} 
                              // defaultTeamTypeFilter={"company"}
                              // managerFilter={true}
                              // employeeFilter={true}
                              areaFilter={true} 
                              subareaFilter={true}
                              />}
                          </div>
                          <CalendarDetailedView teamMembers={filteredData} startDate={detailedViewStartDate} setStartDate={setDetailedViewStartDate} />
                      </div>                  
                    :
                    // No pending requests
                      <React.Fragment >          
                          <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                              <span className="title-description italic">🏖️ {" " + t('time-off.empty')}{" "}
                              </span>
                          </div>
                      </React.Fragment>  
                  }

                  <SectionSeparator />
                  </div>

                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                        <h2 className="flex text-xl text-left mb-4 ">
                          <div className='flex flex-col md:flex-row justify-left'>
                            <Tooltip content={t('time-off.team.pending-requests.description')}>
                              <div className='section-title'>
                                🌴 {t('time-off.team.pending-requests.title')}
                              </div>
                            </Tooltip>
                          </div>
                        </h2>
                        <span className="title-description">
                          {/* Section description */}
                          

                          {/* Self-service resources section */}
                          {/* {i18next.language === 'es' ? 
                                <a href="https://resources.kincode.app/es/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                    <b className='ml-1 hover:underline'>{t('oneOnOne.home.help')} </b>
                                </a>
                                  
                                  : 

                                <a href="https://resources.kincode.app/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                  <b className='ml-1 hover:underline'>{t('oneOnOne.home.help')} </b>
                                </a>
                            } */}
                          
                        </span>
                    </div>

                  {/* Pending requests */}
                  <div className='flex flex-col mb-8'>
                    { isHolidaysTeamPendingRequestsLoading ? 
                      <Loader />
                    :

                    filteredHolidayRequests?.length > 0 ?
                    // Pending requests
                      <div className='flex flex-col space-y-2 w-full'>
                        {currentRows?.map((request, index) => (
                          <RequestListItem request={request} index={index} key={index} loading={resolvingRequestLoading}
                            handleApprove={approveRequest} handleReject={rejectRequest} collapsed={true} viewAttachmentMethod={viewAttachmentMethod}
                            setDetailedCalendarDate={setTeamPlanningCalendarDate}
                          />
                        ))}

                            <Pagination 
                            totalRows={filteredHolidayRequests?.length} 
                            rowsPerPage={rowsPerPage} 
                            currentPage={currentPage} 
                            onPageChange={handlePageChange} 
                            handleRowsPerPageChange={handleRowsPerPageChange}/>


                      </div>                  
                    :
                    // No pending requests
                      <React.Fragment >          
                          <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                              <span className="title-description italic">🏖️ {" " + t('time-off.empty')}{" "}
                              </span>
                          </div>
                      </React.Fragment>  
                  }

                    

                  </div>

              </div>

              {/* Help side */}
              <div className='right-small-view'>
                {/* Calendar widget */}
                {isTeamCalendarLoading ?
                  <Loader />
                :
                  <CalendarWidget weekends={true} 
                  events={teamCalendar}
                  highlightedDates={teamCalendarApproved} 
                  pendingDates={teamCalendarPending}
                  setSelectedDate={setTeamPlanningCalendarDate}
                  // detailedView={detailedViewURL}
                   />
                }
              </div>
            </div>
        </div>
    </React.Fragment>
);
}

export default DaysOffTeam;