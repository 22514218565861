// React
import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import { t } from 'i18next';

// Common
import Configs from '../../Configs';
// import {headersInfo} from '../constants/headersInfo';
import { useAuth } from '../appContext';
import { useData } from '../dataContext';
import * as apiCalls from '../apiCalls';

// Icons
import {IoLogOut, IoSettings} from 'react-icons/io5';
import {FaRegBell} from 'react-icons/fa';
import {BsPersonBadgeFill} from 'react-icons/bs';

// ------------------------------
function HeaderEnd () {
    const navigate = useNavigate();

    const {securityLevel, companyID, employeeID, token, setAuthenticated, setIsGoogleAuthenticated, setIsAzureAuthenticated} = useAuth();
    const {setToasterMessage, toasterMessage} = useData();
    const {pendingToDos, profile} = useData();
    const hasNotifications = (pendingToDos?.length > 0 && pendingToDos?.find(element => element.status === Configs.notificationStatusUnread)) ? true : false;

    // Profile dropdown
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);

    // ----
    // API calls
    // ---- 
    async function logout() {
        try {
            const response = await apiCalls.logout(companyID, employeeID, token);
            if (response?.status === 200) {
                setAuthenticated(false);
                setIsGoogleAuthenticated(false);
                setIsAzureAuthenticated(false);
                sessionStorage.clear();
                navigate('/');
                setToasterMessage({message:t('logout.success'), type:Configs.successToaster});
                // window.location.href = Configs.devEnvironment ? "http://localhost:3000" : "https://platform.kincode.app";
            } else {
                console.error('[header.js][logout()] We could not log out');
                setToasterMessage({message:t('error'), type:Configs.errorToaster});
                sessionStorage.clear();
                window.location.href = Configs.devEnvironment ? "http://localhost:3000" : "https://platform.kincode.app";
            }
        } catch (error) {
            console.error('[header.js][logout()] We could not log out:', error);
        }
    }

    // ------------------------------
    // Render
    // ------------------------------
    return (
        <React.Fragment>
            {/* Settings and Profile */}
            <div className='flex flex-row items-center justify-end space-x-4'>
                {/* Notifications */}
                <Link className='flex flex-row items-start relative' to="/#to-dos-title">
                    {hasNotifications && <span className="h-2 w-2 -top-1 absolute right-2 bg-red-500 text-xs rounded-full mr-1 animate-pulse" />}
                    <FaRegBell className='flex mr-4 text-xl cursor-pointer'/>
                </Link>
                {/* Profile pic */}
                <div className='relative'>
                    {/* Image button */}
                    <button onClick={() => setShowProfileDropdown(!showProfileDropdown)} >
                        {profile?.employeeInfo?.profilePicture ? 
                            <img src={profile?.employeeInfo?.profilePicture} className='h-8 w-8 rounded-full border border-gray-300' alt='Profile picture' />
                            :
                            <div className='flex flex-row text-center justify-center items-center text-xs h-8 w-8 text-shrink border border-gray-300 rounded-full '>{profile?.employeeInfo?.name?.slice(0,1) + profile?.employeeInfo?.surname?.slice(0,1)}</div>
                        }
                    </button>
                    {/* Dropdown menu */}
                    {showProfileDropdown && <div class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                        {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                        <Link to="/profile/" className="flex flex-row items-center block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabindex="-1" id="user-menu-item-0"><BsPersonBadgeFill className='mr-2'/> {t('profile')}</Link>
                        <Link to="/settings/" className="flex flex-row items-center block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabindex="-1" id="user-menu-item-1"><IoSettings className='mr-2'/> {t('settings')} </Link>
                        <button onClick={() => logout()} className="flex flex-row items-center w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabindex="-1" id="user-menu-item-2"><IoLogOut className='mr-2' /> {t('logout')} </button>
                    </div>}
                </div>
            </div>
        </React.Fragment>

    );
}

// ------------------------------
export default HeaderEnd;