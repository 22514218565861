// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom';

// 3P
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import OneOnOneHeader from './oneOnOneHeader.js';
import Bot from '../../common/support/bot';
import CollapsibleMeetingItem from '../../widgets/collapsibleMeetingItem';
import UserListItem from '../../widgets/userListItem.js';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import Pagination from '../../widgets/pagination';
import FeedbackPopup from '../../widgets/feedback/feedbackPopUp';
import PersonalPerformance from '../../widgets/personal/personalPerformance.js';

// Icons
import {AiOutlineInfoCircle } from 'react-icons/ai';

// Toaster && Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// TapTap text editor
import Editor from '../../widgets/editor';
import NoneditableEditor from '../../widgets/noneditableEditor';
import SectionSeparator from '../../widgets/sectionSeparator';

// ---------------------------------------------------------------
function OneOnOnePersonal () {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // var currentProfile = JSON.parse(sessionStorage.getItem('profileInfo'));
    // var [profile, setProfile] = useState(currentProfile ? currentProfile : null);
    const {token, companyID, employeeID, securityLevel} = useAuth();
    const {companyPreferences, selectedProfile, profile} = useData();
    const {toasterMessage, setToasterMessage} = useData();  
    const {meetingsData, isMeetingsDataLoading, getSpace} = useData();
    const managerInfo = sessionStorage.getItem("managerInfo") ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem("managerInfo"),Configs.privateKey).toString(CryptoJS.enc.Utf8)) : profile?.managerInfo;

    // States for meeting status
    const [meetingTitle, setMeetingTitle] = useState("");
    const [meetingContent, setMeetingContent] = useState(null);
    const [privateNotesContent, setPrivateNotesContent] = useState(null);
    const [attritionRisk, setAttritionRisk] = useState(0);
    const [initialContent, setInitialContent] = useState(t('oneOnOne.meeting.initial-content'));
    const [privateNotesInitialContent, setPrivateNotesInitialContent] = useState(t('oneOnOne.meeting.private-notes.initial-content'));
    const [privateNotesOpen, setPrivateNotesOpen] = useState(false);
    const [attritionRiskOpen, setAttritionRiskOpen] = useState(false);
    const [meetingEmployee, setMeetingEmployee] = useState("");
    const [readableDate, setReadableDate] = useState(new Date());
    const [existingDraft, setExistingDraft] = useState(null);
    const [historySpace, setHistorySpace] = useState([]);

    // Tips modal
    const [tipsModalOpen, setTipsModalOpen] = useState(false);

    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

    // Variables 
    const [modifiedHistoryData, setModifiedHistoryData] = useState(null);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Default number of rows per page

    if (Configs.devEnvironment) console.log ("[oneOnOnePersonal.js][main()] Params: ", params, selectedProfile, profile);
    if (Configs.devEnvironment) console.log ("[oneOnOnePersonal.js][main()] Manager Info: ", managerInfo);
    if (Configs.devEnvironment) console.log ("[oneOnOnePersonal.js][main()] Meetings data: ", meetingsData);


  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // --------------------------------------------------------------------------------------------------- 
    // Methods to load when page is loaded
    useEffect (() => {
        // Get information to populate dashboard - if history is empty or if current history is not for the current employee
        if (meetingsData) {
          // Existing meeting data is valid
          setHistorySpace(meetingsData);
          setModifiedHistoryData(meetingsData);
        }

        const handleClickOutside = (event) => {
          if (event.target.classList.contains('modal-overlay')) {
            setTipsModalOpen(false);
          }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
          // setSelectedProfile(null);
          document.removeEventListener('click', handleClickOutside);
        };
      
      }, [location.pathname, meetingsData]);

  // ---------------------------------------------------------------------------------------------------
  // API methods
  // --------------------------------------------------------------------------------------------------- 
    
    async function saveMeeting () {
      // Validate content first
      if (meetingContent === null || meetingContent === "" || meetingTitle === "" || meetingTitle === null) {
        setToasterMessage({message: t('field.missing'), type: Configs.warningToaster});
        return;
      }

      // Create object to send to server
      const auxObject = {
            companyID: existingDraft ? existingDraft[0].companyID: companyID,
            managerID: existingDraft ?  existingDraft[0].managerID : (selectedProfile ? selectedProfile?.managerID : params?.managerID ? params?.managerID : profile?.employeeInfo?.managerID),
            employeeID: existingDraft ?  existingDraft[0].employeeID : (selectedProfile ? selectedProfile?.employeeID : (params?.employeeID ? params.employeeID : employeeID)),
            title:existingDraft ? existingDraft[0].title : (new Date(readableDate).toLocaleDateString() + " - " + (selectedProfile ? selectedProfile?.name :params?.employeeName ? params.employeeName : profile?.employeeInfo?.name) + " - " + meetingTitle),
            content: meetingContent,
            date: existingDraft ? existingDraft[0].date : readableDate,
      }

      // Only if submitting private notes and attrition risk
      if (privateNotesContent) auxObject.privateNotes = privateNotesContent;
      if (attritionRisk) auxObject.attritionRisk = attritionRisk;

      // fetch from server only if we have meetingID
        // HTTP POST request options
          const requestOptions = {
              method: 'POST',
              credentials: 'include',
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
              body: JSON.stringify(auxObject)
          };

    // Post to server      
    fetch(Configs.platformPostMeetingAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          // All good
          // navigate('/oneonone/');
          // sessionStorage.removeItem('performanceReviewDraft');
          if (Configs.devEnvironment) console.log("[oneOnOnePersonal.js][saveMeeting()] - Meeting saved successfully");
          setToasterMessage({message: t('oneOnOne.save.success'), type: Configs.successToaster});

          setShowFeedbackPopup(true);

            // setMeetingTitle("");
            // setMeetingContent(null);
            // setPrivateNotesContent(null);
            // setAttritionRisk(null);
            // setInitialContent(t('oneOnOne.meeting.content.placeholder'));
            // setPrivateNotesInitialContent(t('oneOnOne.meeting.private-notes.placeholder'));
            // setAttritionRiskOpen(false);
            // setPrivateNotesOpen(false);
            // setReadableDate(new Date());
            if (selectedProfile) {
              getSpace(selectedProfile?.companyID, selectedProfile?.employeeID, selectedProfile?.managerID);
            } else {
              getSpace(companyID, employeeID, profile?.employeeInfo?.managerID);
            }

        } else if (response.status === 403) {
            // Inform that something went wrong
            setToasterMessage({message: t('error'), type: Configs.errorToaster});

            
              
        } else if (response.status === 400) {
          // Force logout
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          sessionStorage.clear();
            window.location.reload();
        } else {
          // There is an error - delete info
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }
      })

      .catch((error) => {
        console.log("[oneOnOnePersonal.js][saveMeeting()] Error fetching data: ", error);
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
      });

  }

  async function deleteMeeting (meetingID) {
    if (!window.confirm(t('delete.confirm'))) {    
      // Stay
      console.log ("confirm");
    } else {
      // I'm sure, proceed

      // fetch from server only if we have meetingID
      // HTTP POST request options
        const requestOptions = {
            // method: 'DELETE',
            method: 'GET',
            credentials: 'include',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
            // body: JSON.stringify({ 
            //   companyID: existingDraft ? existingDraft[0].companyID: companyID,
            //   employeeID: employeeID,
            //   meetingID: meetingID,
            // })
        };

      // Post to server      
      fetch(Configs.platformDeleteMeetingAPI + "?companyID=" + (existingDraft ? existingDraft[0].companyID : companyID) + "&employeeID=" + employeeID + "&meetingID=" + meetingID, requestOptions)
      .then((response) => {
        // console.log (response);
        if (response.status === 200) {
          // All good
          // navigate('/oneonone/');
          // sessionStorage.removeItem('performanceReviewDraft');
          if (Configs.devEnvironment) console.log("[oneOnOnePersonal.js][deleteMeeting()] - Meeting deleted successfully");
            if (selectedProfile) {
              getSpace(selectedProfile?.companyID, selectedProfile?.employeeID, selectedProfile?.managerID);
            } else {
              getSpace(companyID, employeeID, profile?.employeeInfo?.managerID);
            }
            setToasterMessage({message: t('success'), type: Configs.successToaster});

          } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('warning'), type: Configs.warningToaster});
          } else if (response.status === 401) {
              // Force logout
              setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
              sessionStorage.clear();
              window.location.reload();
          } else if (response.status === 403) {
              // Force logout
              setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
              sessionStorage.clear();
              window.location.reload();
          } else if (response.status === 409) {
              setToasterMessage({message: t('warning'), type: Configs.warningToaster});
          } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
          }
      })
      .catch((error) => {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
        console.log("[oneOnOnePersonal.js][deleteMeeting()] Error fetching data: ", error);
      });
    }
}

  // ---------------------------------------------------------------------------------------------------
  // Frontend control methods
  // --------------------------------------------------------------------------------------------------- 
    async function save (e) {
        // Save 1:1 to database
        e.preventDefault();

        // API request
        saveMeeting();
    }
    async function deleteMethod (e) {
      e.preventDefault();

      deleteMeeting();
    }

    async function enablePrivateNotes (e) {
      e.preventDefault();

      setPrivateNotesOpen(!privateNotesOpen);
    }

    async function enableAttritionRisk (e) {
      e.preventDefault();

      setAttritionRiskOpen(!attritionRiskOpen);
    }

  // ------------------------------------------------------------
  // Pagination methods
  // ------------------------------------------------------------
  // Calculate the indices of the first and last row on the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = modifiedHistoryData?.slice(indexOfFirstRow, indexOfLastRow);

      const handlePageChange = (page) => {
          setCurrentPage(page);
      };

      const handleRowsPerPageChange = (event) => {
          if (Configs.devEnvironment) console.log("[oneOnOnePersonal.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
          setRowsPerPage(parseInt(event.target.value, 10));
          setCurrentPage(1); // Reset to first page since the number of pages will change
      };

  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
    };
  
  // ---------------------------------------------------------------------------------------------------
  // Render methods
  // ---------------------------------------------------------------------------------------------------    

    return (
      <React.Fragment>
      <Header />
      <Bot/>

      <div className="main">
          <OneOnOneHeader />
            
        <div className='flex flex-row items-start'>
        <div className="left-big-view">      
        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
        <FeedbackPopup showPopup={showFeedbackPopup} showFeedbackChance={Configs.feedbackPopupChance} 
                  onFeedbackSubmitted={() => setShowFeedbackPopup(false)}/>

        <div className="flex flex-col m-2 text-left ">
            <div className="flex flex-col mt-2 md:ml-2 m-1">

                <h2 className="flex flex-col text-xl mb-4 mt-4 md:mt-0">              
                    <div className='flex flex-col justify-left'>
                          {!selectedProfile ? 

                            managerInfo ? 
                              <div className='flex mb-8'>
                                <UserListItem user={managerInfo?.name + " " + managerInfo?.surname} 
                                initials={managerInfo?.name?.slice(0,1)+managerInfo?.surname?.slice(0,1)} 
                                role={managerInfo?.role} level={managerInfo?.level}
                                area={managerInfo?.area} subarea={managerInfo?.subarea}
                                profilePicture={managerInfo?.profilePicture} />
                              </div>
                              :
                              null
                          :
                            params &&
                            <div className='flex mb-8'>
                              <UserListItem user={selectedProfile ? (selectedProfile?.name + " " + selectedProfile?.surname) : (params?.employeeName + " " + params?.employeeSurname)} 
                              initials={params?.employeeName?.slice(0,1)+params?.employeeSurname?.slice(0,1)} 
                              role={params?.employeeRole} level={params?.employeeLevel}
                              area={params?.employeeArea} subarea={params?.employeeSubarea}
                              profilePicture={params?.employeeProfilePicture} />
                            </div>
                        }

                        <div className='flex flex-row px-3'>
                        {existingDraft ?
                            <span>
                              {t('oneOnOne.meeting.title') } - {existingDraft[0].title}
                            </span>
                          :
                            <div className="flex flex-col">
                              <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 md:w-1/12 text-left dark:text-white">
                                  {t('date')}:
                              </label>
                              <input type="date" className='input-field  ' 
                              value={readableDate ? new Date(readableDate).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10)} 
                              onChange={(e) => setReadableDate(e.target.value)}/>
                            </div>

                          }  
                        
                        </div>

                        
                    </div>
                </h2>
                
                <form className="w-full text-justify mt-4">
                
                  {existingDraft ?

                    null
                  :
                        <div className="flex flex-col items-start w-full px-3 mb-8">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 md:w-1/12 text-left dark:text-white">
                              {t('title')}:
                            </label>
                            <input
                              className="w-full input-field "
                              id="title"
                              type="text"
                              autoFocus
                              placeholder={t('meeting-title')}
                              onChange = {(e) => setMeetingTitle(e.target.value)} value = {meetingTitle}
                            />
                        </div>
                  }
                        {/* Confidential fields only available for managers*/}

                        {selectedProfile === null || 
                          parseInt(params?.employeeID) === parseInt(employeeID) &&  parseInt(params?.companyID) === parseInt(companyID) ?
                          null
                        :
                        <React.Fragment>
                          <div className="flex flex-col items-start w-full px-3 mt-8">
                            <label className='flex flex-row items-center block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white'>
                              {t('confidential-fields')}:
                            </label>
                            
                            <div className="flex flex-row w-full space-x-2 text-sm">
                              <button onClick={(e) => enablePrivateNotes(e)} className={'secondary-button shadow flex' + (privateNotesOpen ? ' bg-green-200 ' : ' bg-gray-200 ' )}>
                                {privateNotesOpen ? 
                                <p><strong className='mr-2'>-</strong>{t('private-notes')}</p>
                                :
                                <p><strong className='mr-2'>+</strong>{t('private-notes')}</p>
                                }
                              </button>

                              <button onClick={(e) => enableAttritionRisk(e)} className={'secondary-button shadow ' + (attritionRiskOpen ? ' bg-green-200 ' : ' bg-gray-200 ' )}>
                                {attritionRiskOpen ? 
                                <p><strong className='mr-2'>-</strong>{t('oneOnOne.meeting.attrition-risk')}</p>
                                :
                                <p><strong className='mr-2'>+</strong>{t('oneOnOne.meeting.attrition-risk')}</p>
                                }
                              </button>
                            </div>
                          </div>


                          {privateNotesOpen ?
                            <div className="flex flex-col items-start w-full px-3 mt-8">
                                <label className="flex flex-row items-center block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white">
                                    {t('private-notes')}:
                                    <div className='group ml-2 cursor-pointer'>
                                        <AiOutlineInfoCircle />

                                        <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50">
                                          <NoneditableEditor
                                              content={t('oneOnOne.meeting.private-notes.initial-content')}
                                              />
                                        </div>
                                    </div>
                                </label>
                                <div className='flex flex-col items-start w-full input-field'>
                                  <Editor setContent={setPrivateNotesContent} content={privateNotesContent} initialContent={privateNotesInitialContent} placeholder={t('oneOnOne.meeting.private-notes.placeholder')}/>
                                </div>
                            </div>
                            : 
                            null
                          }
                          {attritionRiskOpen ?
                            <div className="flex flex-col items-start w-full px-3 mt-8">
                                <label className="flex flex-row items-center block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white">
                                    {t('oneOnOne.meeting.attrition-risk')}:
                                    <div className='group ml-2 cursor-pointer'>
                                        <AiOutlineInfoCircle />

                                        <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50">
                                          <NoneditableEditor
                                              content={t('oneOnOne.meeting.attrition-risk-tooltip')}
                                              />
                                        </div>
                                    </div>
                                </label>
                                
                                  {/* Div with 5 options to choose */}
                                  <div className='flex flex-row space-x-1'>
                                    <Tooltip content={t('oneOnOne.meeting.attrition-risk-1')} >
                                    <div onClick={() => setAttritionRisk(1)} className={'secondary-button  shadow  group ml-2 cursor-pointer ' + (attritionRisk === 1 ?  ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 '  )}>
                                        🟢
                                    </div>
                                    </Tooltip>
                                    <Tooltip content={t('oneOnOne.meeting.attrition-risk-2')} >
                                    <div onClick={() => setAttritionRisk(2)} className={'secondary-button shadow  group ml-2 cursor-pointer  ' + (attritionRisk === 2 ?  ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 '  )}>
                                        🟡 
                                    </div>
                                    </Tooltip>
                                    <Tooltip content={t('oneOnOne.meeting.attrition-risk-3')} >
                                    <div onClick={() => setAttritionRisk(3)} className={'secondary-button  shadow  group ml-2 cursor-pointer ' + (attritionRisk === 3 ?  ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 '  )}>
                                      🟠 
                                    </div>
                                    </Tooltip>
                                    <Tooltip content={t('oneOnOne.meeting.attrition-risk-4')} >
                                    <div onClick={() => setAttritionRisk(4)} className={'secondary-button  shadow  group ml-2 cursor-pointer ' + (attritionRisk === 4 ?  ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 '  )}>
                                      🔴 
                                    </div>
                                    </Tooltip>
                                    <Tooltip content={t('oneOnOne.meeting.attrition-risk-5')} >
                                    <div onClick={() => setAttritionRisk(5)} className={'secondary-button shadow group ml-2 cursor-pointer ' + (attritionRisk === 5 ? ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 ' )}>
                                      ⚫ 
                                    </div>
                                    </Tooltip>
                                  </div>  
                            </div>
                            : 
                            null
                          }
                        </React.Fragment>
                      }
                        
                        <div className="flex flex-col items-start w-full px-3 mt-8">
                            <label className="flex flex-row items-center block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white">
                                {t('shared-notes')}:
                                <div className='group ml-2 cursor-pointer'>
                                    <AiOutlineInfoCircle />

                                    <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50">
                                      <NoneditableEditor
                                          content={t('oneOnOne.meeting.initial-content')}
                                          />
                                    </div>
                                </div>
                            </label>
                            <div className='flex flex-col items-start w-full input-field'>
                              <Editor className="w-full" setContent={setMeetingContent} content={meetingContent} initialContent={initialContent} 
                                placeholder={t('oneOnOne.meeting.content.placeholder')}/>
                            </div>
                            
                        </div>

                        <div className="flex justify-center md:justify-end w-full px-4 mt-2">
                            <button className="save-button flex bg-green-200 my-4 shadow-xl" onClick={(e) => save(e)}>
                              {t('save')}
                            </button>
                        </div>
                </form>
            </div>

            <SectionSeparator />

            {/* History section of the Space */}
            {isMeetingsDataLoading ?
              <Loader />
            :

            currentRows && currentRows?.length > 0 ? 

                <div className='flex flex-col mb-20 '>
                    <h2 className="section-title mb-8">
                        {t('one-on-one.history')}
                    </h2>

                    {currentRows?.map((meeting, index) => (
                        <div>
                            {((securityLevel?.includes(Configs.securityLevelManager)) && (parseInt(params?.managerID) === parseInt(employeeID))) ?
                              // Manager
                                    meeting?.type === Configs.oneOnOneTypeDevelopmentPlan ?
                                    // Development plan
                                      <CollapsibleMeetingItem title={new Date(meeting?.meetingDate).getMonth() + "/" + new Date(meeting?.meetingDate).getFullYear() +
                                               " - " + t('oneOnOne.types.development-plan') + " - " + params?.employeeName + " " + params?.employeeSurname} content={meeting.content} collapsed={index === 0 ? false : true}  
                                               meetingID={meeting._id} meetingType={meeting?.type}/>
                                    :
                                    meeting?.type === Configs.oneOnOneTypeCareerConversation ?
                                    // Career conversation plan
                                    <CollapsibleMeetingItem title={t('oneOnOne.types.career-conversation') + " - " + meeting?.title} content={meeting?.content} collapsed={index === 0 ? false : true}  
                                                 meetingID={meeting?._id} meetingType={meeting?.type}/>
                                    :
                                      // Regular meeting
                                      <CollapsibleMeetingItem title={meeting?.title} content={meeting?.content} collapsed={index === 0 ? false : true} 
                                      deleteMeeting={deleteMeeting} meetingID={meeting?._id} meetingType={meeting?.type}
                                      privateNotes={meeting?.privateNotes} attritionRisk={meeting?.attritionRisk}
                                      managerPermissions={true}        
                                      editURL={
                                        "../oneonone/meeting/" +
                                        encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                                            employeeID: meeting.employeeID,
                                            companyID: meeting.companyID,
                                            managerID: meeting.managerID,
                                            meetingID: meeting._id 
                                            }),Configs.privateKey).toString())
                                    }/>
                              :
                              // Individual contributor
                                  meeting?.type === Configs.oneOnOneTypeDevelopmentPlan ?
                                  // Development plan
                                    <CollapsibleMeetingItem title={new Date(meeting?.meetingDate).getMonth() + "/" + new Date(meeting?.meetingDate).getFullYear() +
                                               " - " + t('oneOnOne.types.development-plan') + " - " + params?.employeeName + " " + params?.employeeSurname} content={meeting.content} collapsed={index === 0 ? false : true}  
                                               meetingID={meeting._id} meetingType={meeting?.type}/>
                                  :
                                  meeting?.type === Configs.oneOnOneTypeCareerConversation ?
                                  // Career conversation plan
                                  <CollapsibleMeetingItem title={t('oneOnOne.types.career-conversation') + " - " + meeting?.title} content={meeting?.content} collapsed={index === 0 ? false : true}  
                                               meetingID={meeting?._id} meetingType={meeting?.type}/>
                                  :
                                    // Regular meeting
                                    <CollapsibleMeetingItem title={meeting?.title} content={meeting?.content} collapsed={index === 0 ? false : true} meetingType={meeting?.type}
                                      privateNotes={meeting?.privateNotes} attritionRisk={meeting?.attritionRisk}
                                      managerPermissions={false}    
                                    editURL={
                                            "../oneonone/meeting/" +
                                            encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                                                employeeID: meeting.employeeID,
                                                companyID: meeting.companyID,
                                                managerID: meeting.managerID,
                                                meetingID: meeting._id 
                                                }),Configs.privateKey).toString())
                                        }/>
                              }
                            
                        </div>
                    ))}

                <Pagination 
                    totalRows={modifiedHistoryData?.length} 
                    rowsPerPage={rowsPerPage} 
                    currentPage={currentPage} 
                    onPageChange={handlePageChange} 
                    handleRowsPerPageChange={handleRowsPerPageChange}/>

                <SectionSeparator />

                </div>


: 
  null
}
        </div>
        </div>
        <div className='right-small-view'>
          {/* Performance */}
          <PersonalPerformance 
            companyID={params?.companyID ? params?.companyID : companyID} 
            employeeID={params?.employeeID ? params?.employeeID : employeeID} />

          {/* Development */}



          {/* Career */}
          
        </div>
      </div>
      </div>
      </React.Fragment>
    );
}

export default OneOnOnePersonal;