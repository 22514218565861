// React
import React, {useState, useEffect} from 'react';

// Configs
import Configs from '../../Configs';
import Tooltip from '../../common/support/tooltip';
import Select from 'react-select';
import ProgressBar from '../progressBar';
import { useData } from '../../common/dataContext';

// 3P
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

// Icons
import { GiPalmTree } from 'react-icons/gi';
import {FaEye} from 'react-icons/fa';
import Loader from '../../common/support/loader';


// ---------------------------------------------------------------
const PersonalTimeOff = ({
    employee,
    setEmployee,
    isEdit,
    isCreate = true,
    employeeConfig,
}) => {
    // Attributes needed
    const {t} = useTranslation();
    const {companyPreferences, companyOrg, isCompanyLoading} = useData();


    // State variables
    const [countryOfWork, setCountryOfWork] = useState(employee?.countryOfWork || null);
    const [regionOfWork, setRegionOfWork] = useState(employee?.regionOfWork || null);
    const [cityOfWork, setCityOfWork] = useState(employee?.cityOfWork || null);
    const [holidaysCalendar, setHolidaysCalendar] = useState(employee?.holidaysCalendar || null);

    // Options
    const [countryOptions, setCountryOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [holidaysCalendarOptions, setHolidaysCalendarOptions] = useState([]);

    if (Configs.devEnvironment) console.log("[personalTimeOff.js] employeeConfig: ", employeeConfig);
    if (Configs.devEnvironment) console.log("[personalTimeOff.js] employee: ", employee);

    if (Configs.devEnvironment) console.log("[personalTimeOff.js] state: ", countryOfWork, regionOfWork, cityOfWork, holidaysCalendar);



    // ---------------------------------------------------------------
    // useEffect
    // ---------------------------------------------------------------
    useEffect(() => {
        getExistingWorkingRegions();
        
    }, [employee]);


    // ---------------------------------------------------------------
    // Data transformation
    // ---------------------------------------------------------------
    async function getExistingWorkingRegions() {
        // Fetch existing templates
        // setIsOnboardingTemplatesLoading(true);
        let countryOptionsAux = [];
        let regionOptionsAux = [];
        let cityOptionsAux = [];
        let holidaysCalendarOptionsAux = [];

        // Use companyOrg to get the existing locations from other employees
        companyOrg?.map((employee) => {
            if (Configs.devEnvironment && employee.name === "Ana") console.log("[personalTimeOff.js][getExistingWorkingRegions()]  employee: ", employee);   

            // Country
            if (employee?.countryOfWork) {
                if (!countryOptionsAux.find((option) => option.value === employee?.countryOfWork)) {
                    countryOptionsAux.push({value: employee?.countryOfWork, label: employee?.countryOfWork});
                }
            }
            // Region
            if (employee?.regionOfWork) {
                if (!regionOptionsAux.find((option) => option.value === employee?.regionOfWork)) {
                    regionOptionsAux.push({value: employee?.regionOfWork, label: employee?.regionOfWork});
                }
            }
            // City
            if (employee?.cityOfWork) {
                if (!cityOptionsAux.find((option) => option.value === employee?.cityOfWork)) {
                    cityOptionsAux.push({value: employee?.cityOfWork, label: employee?.cityOfWork});
                }
            }

            // holidaysCalendar
            if (employee?.holidaysCalendar) {
                if (!holidaysCalendarOptionsAux.find((option) => option.value === employee?.holidaysCalendar)) {
                    holidaysCalendarOptionsAux.push({value: employee?.holidaysCalendar, label: employee?.holidaysCalendar});
                }
            }
        });

        // Set options state
        setCountryOptions([...countryOptionsAux]);
        setRegionOptions([...regionOptionsAux]);
        setCityOptions([...cityOptionsAux]);
        setHolidaysCalendarOptions([...holidaysCalendarOptionsAux]);

        // Update employee object
        updateEmployeeObject();
    }

    async function updateEmployeeObject() {
        if (isEdit || employee) {
            // Fetch existing data
            setCountryOfWork(employee?.countryOfWork || null);
            setRegionOfWork(employee?.regionOfWork || null);
            setCityOfWork(employee?.cityOfWork || null);
            setHolidaysCalendar(employee?.holidaysCalendar || null);
        }
    }

    // ---------------------------------------------------------------
    // Handlers
    // ---------------------------------------------------------------
    const handleFieldChange = (e, type) => {
        // if (Configs.devEnvironment) console.log("[personalTimeOff.js][handleFieldChange()] e.target.value: ", e.value, " type: ", type);

        switch (type) {
            case 'country':
                setCountryOfWork(e.value);
                setEmployee(e, 'timeOff', 'countryOfWork');
                break;
            case 'region':
                setRegionOfWork(e.value);
                setEmployee(e, 'timeOff', 'regionOfWork');
                break;
            case 'city':
                setCityOfWork(e.value);
                setEmployee(e, 'timeOff', 'cityOfWork');
                break;
            case 'holidaysCalendar':
                setHolidaysCalendar(e.value);
                setEmployee(e, 'timeOff', 'holidaysCalendar');
                break;
            default:
                break;
        }
    };


    // ---------------------------------------------------------------
    // Return
    // ---------------------------------------------------------------
    return (
            companyPreferences?.activeModules["Time-off"] && (isEdit || isCreate) ?
                <div className='flex flex-col items-center justify-center text-left space-y-2 bg-white shadow py-4 px-8 rounded-lg border text-gray-800 text-sm'>
                    <div className='flex flex-col items-start justify-center text-left space-y-2 w-full'>
                        <Tooltip content={t('time-off.tooltip')} >
                            <h4 className='card-title flex flex-row items-center'> 
                                <GiPalmTree className='mr-2 '/> {t('Time-off')}
                            </h4>
                        </Tooltip>
                        {isCompanyLoading ?
                                <div className='flex flex-col items-center w-full p-4'>
                                    <Loader />
                                </div>
                        :
                            // Dropown to select template
                            <div className='felx flex-col items-start justify-center text-left space-y-4 w-full'>
                                    {/* // Country */}
                                    <div className='flex flex-col items-start justify-center text-left space-y-2 w-full'>
                                        <div className='flex'>
                                            <label for="level" className="form-label">
                                                {t('country')}
                                                {(employeeConfig?.timeOffFields?.find((field) => field.name === 'countryOfWork')?.required && !employeeConfig?.timeOffFields?.find((field) => field.name === 'countryOfWork')?.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                {(employeeConfig?.timeOffFields?.find((field) => field.name === 'countryOfWork')?.required && employeeConfig?.timeOffFields?.find((field) => field.name === 'countryOfWork')?.missing === true) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                            </label>
                                        </div>
                                        <div className='flex flex-row w-full space-x-4'>
                                            <Select 
                                                placeholder={t(employeeConfig?.timeOffFields?.find((field) => field.name === 'countryOfWork')?.placeholder) || t('select-an-option')} 
                                                value={countryOptions?.find(option => option.value === countryOfWork)}
                                                options={countryOptions}
                                                styles={Configs.formDropdownStyle}
                                                onChange={(e) => handleFieldChange(e, 'country')}
                                                className='text-sm w-full'
                                            />
                                        </div>
                                    </div>
                                    {/* Region */}
                                    <div className='flex flex-col items-start justify-center text-left space-y-2 w-full'>
                                        <div className='flex'>
                                            <label for="level" className="form-label">
                                                {t('region')}
                                                {(employeeConfig?.timeOffFields?.find((field) => field.name === 'regionOfWork')?.required && !employeeConfig?.timeOffFields?.find((field) => field.name === 'regionOfWork')?.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                {(employeeConfig?.timeOffFields?.find((field) => field.name === 'regionOfWork')?.required && employeeConfig?.timeOffFields?.find((field) => field.name === 'regionOfWork')?.missing === true) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                            </label>
                                        </div>
                                        <div className='flex flex-row w-full space-x-4'>
                                            <Select 
                                                placeholder={t(employeeConfig?.timeOffFields?.find((field) => field.name === 'regionOfWork')?.placeholder) || t('select-an-option')} 
                                                value={regionOptions?.find(option => option.value === regionOfWork)}
                                                options={regionOptions}
                                                styles={Configs.formDropdownStyle}
                                                onChange={(e) => handleFieldChange(e, 'region')}
                                                className='text-sm w-full'
                                            />
                                        </div>
                                    </div>
                                    {/* City */}
                                    <div className='flex flex-col items-start justify-center text-left space-y-2 w-full'>
                                        <div className='flex'>
                                            <label for="level" className="form-label">
                                                {t('city')}
                                                {(employeeConfig?.timeOffFields?.find((field) => field.name === 'cityOfWork')?.required && !employeeConfig?.timeOffFields?.find((field) => field.name === 'cityOfWork')?.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                {(employeeConfig?.timeOffFields?.find((field) => field.name === 'cityOfWork')?.required && employeeConfig?.timeOffFields?.find((field) => field.name === 'cityOfWork')?.missing === true) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                            </label>
                                        </div>
                                        <div className='flex flex-row w-full space-x-4'>
                                            <Select 
                                                placeholder={t(employeeConfig?.timeOffFields?.find((field) => field.name === 'cityOfWork')?.placeholder) || t('select-an-option')} 
                                                value={cityOptions?.find(option => option.value === cityOfWork)}
                                                options={cityOptions}
                                                styles={Configs.formDropdownStyle}
                                                onChange={(e) => handleFieldChange(e, 'city')}
                                                className='text-sm w-full'
                                            />
                                        </div>
                                    </div>

                                    {/* Holidays calendar */}
                                    <div className='flex flex-col items-start justify-center text-left space-y-2 w-full'>
                                        <div className='flex'>
                                            <label for="level" className="form-label">
                                                {t('holidays-calendar')}
                                                {(employeeConfig?.timeOffFields?.find((field) => field.name === 'holidaysCalendar')?.required && !employeeConfig?.timeOffFields?.find((field) => field.name === 'holidaysCalendar')?.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                {(employeeConfig?.timeOffFields?.find((field) => field.name === 'holidaysCalendar')?.required && employeeConfig?.timeOffFields?.find((field) => field.name === 'holidaysCalendar')?.missing === true) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                            </label>
                                        </div>
                                        <div className='flex flex-row w-full space-x-4'>
                                            <Select 
                                                placeholder={t(employeeConfig?.timeOffFields?.find((field) => field.name === 'holidaysCalendar')?.placeholder) || t('select-an-option')} 
                                                value={holidaysCalendarOptions?.find(option => option?.value === holidaysCalendar)}
                                                options={holidaysCalendarOptions}
                                                styles={Configs.formDropdownStyle}
                                                onChange={(e) => handleFieldChange(e, 'holidaysCalendar')}
                                                className='text-sm w-full'
                                            />
                                        </div>
                                    </div>
                            </div>
                        }
                    </div>
                </div>
            :
                null
    );
    
};

export default PersonalTimeOff;




