// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import OnboardingHeader from './onboardingHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// Widgets
import DataTable from '../../widgets/dataTable';


// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// React icons
// import {AiOutlineFilter} from 'react-icons/ai';
import {IoPersonAdd} from 'react-icons/io5';

// -----------------------------------------------------------------------
function OnboardingDashboard () {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    // Employee datapoints  
    const {token, companyID, employeeID} = useAuth();
    const {toasterMessage, setToasterMessage} = useData();

    const {onboardingEligibleEmployees, setOnboardingEligibleEmployees, isOnboardingEligibleEmployeesLoading} = useData();
    const {onboardingPlanTemplates, setOnboardingPlanTemplates, isOnboardingPlanTemplatesLoading} = useData();

    // Headers of data table
    const eligibleEmployeesHeaders = [
      {type:'string',id: 'employee', value: 'employee', label: t('employee'), sort: null, filter: null},
      {type:'string',id: 'manager', value: 'manager[0].employee', label: t('manager'), sort: null, filter: null},
      {type:'string',id: 'buddy', value: 'buddy', label: t('buddy'), sort: null, filter: null},
      {type:'date',id: 'hireDate', value: 'hireDate', label: t('hire-date'), sort: null, filter: null},
      {type:'string',id: 'onboardingPlanStatus', value: 'onboardingPlanStatus', label: t('status'), sort: null, filter: null},
      {type:'progressBar',id: 'onboardingPlanProgress', value: 'onboardingPlanProgress', label: t('progress'), sort: null, filter: null},
      {type:'action',id: 'action', value: 'action', label: "", sort: null, filter: null}
    ];

    const templatesHeaders = [
      {type:'string',id: 'title', value: 'title', label: t('title'), sort: null, filter: null},
      {type:'string',id: 'description', value: 'description', label: t('description'), sort: null, filter: null},
      {type:'action',id: 'action', value: 'action', label: "", sort: null, filter: null}
    ];

// -----------------------------------------------------------------------
// Methods to load after page load
// -----------------------------------------------------------------------
useEffect (() => {

  if (onboardingEligibleEmployees?.length > 0) {
    calculateCompletionPercentages([...onboardingEligibleEmployees]);
  }
  
  }, []);

// ---------------------------------------------------------------------------------------------------
// Frontend management methods
// ---------------------------------------------------------------------------------------------------
async function createNewPlan (e) {
  e.preventDefault();

  navigate("/onboarding/plan/")
}

// ---------------------------------------------------------------------------------------------------
// Data table methods
// ---------------------------------------------------------------------------------------------------
async function editMethod (index, row) {
  if (Configs.devEnvironment) console.log ("[onboardingHR.js][editMethod()] index: ", index, row);
  // Naviage to the URL
  navigate("../onboarding/plan/" +
  encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
      employeeID: row?.employeeID,
      companyID: row?.companyID,
      employee: row?.employee,
      employeeName: row?.employeeName,
      employeeSurname: row?.employeeSurname,
      // onboardingPlanID: row?.onboardingPlanID[0], // This should be reviewed
      isEditMode: true
  }),Configs.privateKey).toString()))

}

async function viewMethod (index, row) {
  if (Configs.devEnvironment) console.log ("[onboardingHR.js][viewMethod()] index: ", index, row, onboardingEligibleEmployees?.find(employee => employee?.employeeID === row?.employeeID));

  let employeeAux = onboardingEligibleEmployees?.find(employee => parseInt(employee?.employeeID) === parseInt(row?.employeeID));
  if (employeeAux?.onboardingPlanID && employeeAux?.onboardingPlan?.length > 0) {
    
    // Naviage to the URL
    navigate("../onboarding/plan/" +
    encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
        employeeID: row?.employeeID,
        companyID: row?.companyID,
        employee: row?.employee,
        employeeName: row?.employeeName,
        employeeSurname: row?.employeeSurname,
        onboardingPlanID: row?.onboardingPlanID[0], // This should be reviewed
        isEditMode: false
    }),Configs.privateKey).toString()))

  } else {
    alert(t('onboarding.hr.no-onboarding-plan'));
  }
}

async function deleteMethod (index, row) {
  if (Configs.devEnvironment) console.log ("[onboardingHR.js][deleteMethod()] index: ", index, row);

  // Alert message to be sure
  if (!window.confirm(t('onboarding.hr.delete-confirm'))) return;

  const data = {
    employeeID: employeeID,
    companyID: companyID,
    templateID: row?.onboardingPlanID?.toString()
  }

  // Delete the onboarding plan
  try {

    const response = apiCalls.deleteOnboardingPlan(companyID, employeeID, token, data);

    if (response?.status > 200) {
      setToasterMessage({message: t('onboarding.hr.delete-error'), type: Configs.errorToaster});
    } else {
      setToasterMessage({message: t('onboarding.hr.delete-success'), type: Configs.successToaster});
    }

  } catch (error) {
    console.error("[onboardingHR.js][deleteMethod()] error: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
  }
}

// Templates methods
async function deleteTemplateMethod (index, row) {
  if (Configs.devEnvironment) console.log ("[onboardingHR.js][deleteTemplateMethod()] index: ", index, row);

  // Alert message to be sure
  if (!window.confirm(t('onboarding.hr.template-delete-confirm'))) return;

  const data = {
    templateID: row?._id,
    companyID: companyID,
    employeeID: employeeID
  }

  // Delete the template
  try {

    const result = await apiCalls.deleteOnboardingTemplate(companyID, employeeID, token, data);

    if (result > 200) {
      setToasterMessage({message: t('onboarding.hr.template-delete-error'), type: Configs.errorToaster});
    } else {
      setToasterMessage({message: t('onboarding.hr.template-delete-success'), type: Configs.successToaster});
      setOnboardingPlanTemplates(onboardingPlanTemplates.filter(template => template?._id !== row?._id));
    }

  } catch (error) {
    console.error("[onboardingHR.js][deleteTemplateMethod()] error: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
  }
}


async function viewTemplateMethod (index, row) {
  if (Configs.devEnvironment) console.log ("[onboardingHR.js][viewTemplateMethod()] index: ", index, row, onboardingPlanTemplates?.find(template => template?.onboardingPlanID === row?.onboardingPlanID));

  let templateAux = onboardingPlanTemplates?.find(template => template?._id?.toString() === row?._id?.toString());
  if (templateAux) {
    
    // Naviage to the URL
    navigate("../onboarding/plan/" +
    encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
        isEditMode: true,
        isTemplateMode: true,
        template: row
    }),Configs.privateKey).toString()))

  } else {
    alert(t('onboarding.hr.no-onboarding-plan'));
  }
}

// ---------------------------------------------------------------------------------------------------
// Data management methods
// ---------------------------------------------------------------------------------------------------
async function calculateCompletionPercentages (body) {
  // Body is a set of employees, with their onboarding plans
  // Iterate through the employees to calculate the percentage, and add it to the body object
  var totalEmployees = 0;
  var totalPlans = 0;
  var completedPlans = 0;

  body?.forEach(employee => {
      totalEmployees++;
      if (employee?.onboardingPlan) {
          totalPlans++;
          if (employee?.onboardingPlan?.status === Configs.onboardingPlanStatusCompleted) completedPlans++;

          // Change status string to the correct language
          if (employee?.onboardingPlanStatus) employee.onboardingPlanStatus = t(employee?.onboardingPlanStatus);

          // Iterate through the sections and subsections to calculate the percentage
          var totalSections = 0;
          var totalSubsections = 0;
          var completedSections = 0;
          var completedSubsections = 0;

          employee?.onboardingPlan?.forEach(section => {
              totalSections++;

              section?.subsections?.forEach(subsection => {
                  totalSubsections++;
                  if (subsection.isCompleted) completedSubsections++;
              });
          });

          // Calculate percentage
          var completionPercentage = (completedSubsections / totalSubsections) * 100;
          
          // Add the percentage to the employee
          employee.onboardingPlanProgress = completionPercentage;
      }

  });
  

  if (Configs.devEnvironment) console.log("[onboardingHR.js][calculateCompletionPercentages()] totalEmployees: ", totalEmployees, " totalPlans: ", totalPlans, " completedPlans: ", completedPlans, body);
  
  // Update state
  setOnboardingEligibleEmployees([...body]);
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
};

// -----------------------------------------------------------------------
// Render
// -----------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot/>

        <div className="main">

          <OnboardingHeader />

          <div className="flex flex-col m-2 lg:m-4">

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

          <div className='flex flex-col p-2 lg:p-4 space-y-4'>
            <div className='hidden flex flex-row justify-end items-center'>
                <button className='save-button bg-green-200' onClick={(e) => createNewPlan(e)}>
                 {t('onboarding.hr.create-plan')}
                </button>
            </div>

            {/* Eligible employees */}
            <div className="flex flex-col mt-2 md:ml-2 m-1">
                  <h2 className="flex text-xl text-left mb-4 ">
                      <div className='flex flex-col items-start md:flex-row justify-left'>
                        <Tooltip content={t('onboarding.hr.new-joiners.description')}>
                          <div className='section-title flex flex-row items-center'>
                            <IoPersonAdd className='flex mr-2 text-lg'/> {t('onboarding.hr.new-joiners')}
                          </div>
                        </Tooltip>
                      </div>
                  </h2>
              </div>
            {isOnboardingEligibleEmployeesLoading ?
              <Loader />
            :
              onboardingEligibleEmployees && onboardingEligibleEmployees?.length > 0 ?
                // Show the table with candidates
                <React.Fragment>
                  <DataTable data={onboardingEligibleEmployees} columnNames={eligibleEmployeesHeaders} zerosForDashes={true}
                    editMethod={editMethod} viewMethod={viewMethod}
                    deleteMethod={deleteMethod} 
                  />
                </React.Fragment>
                :
                // Create a new joiner
                <div className='flex flex-col justify-center items-center p-8 '>
                  <span className='title-description italic'>
                    {t('onboarding.hr.no-candidates') + ". "}
                    <Link to="/organigram/employees">
                      <span className='text-blue-500 underline'>{t('onboarding.hr.add-employees')}</span>
                    </Link>
                  </span>
                </div>
              }

              {/* Templates */}
              <SectionSeparator />
              <div className="flex flex-col mt-2 md:ml-2 m-1">
                        <h2 className="flex text-xl text-left mb-4 ">
                            <div className='flex flex-col items-start md:flex-row justify-left'>
                              <Tooltip content={t('onboarding.hr.templates.description')}>
                                <div className='section-title flex flex-row items-center'>
                                📄 {t('onboarding.hr.templates')}
                                </div>
                              </Tooltip>
                            </div>
                        </h2>
              </div>
              {isOnboardingPlanTemplatesLoading ?
                <Loader />
              :
              onboardingPlanTemplates && onboardingPlanTemplates?.length > 0 ?
                <React.Fragment>
                  

                  <DataTable data={onboardingPlanTemplates} columnNames={templatesHeaders} zerosForDashes={true}
                    // editMethod={editMethod} 
                    viewMethod={viewTemplateMethod}
                    deleteMethod={deleteTemplateMethod}
                    /> 
                </React.Fragment>
              :
                <Link to={"../onboarding/plan/" +
                encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                    employeeID: employeeID,
                    companyID: companyID,
                    isEditMode: true,
                    isTemplateMode: true,
                }),Configs.privateKey).toString())}>
                  <span className='title-description italic hover:underline'>{t('onboarding.hr.templates.add')}</span>
                </Link>
              }



            
          </div>

          <SectionSeparator />

          </div>

        </div>
      </React.Fragment>
    );
}

export default OnboardingDashboard;