import React, {useState, useEffect} from "react";
import Configs from '../Configs';
import { useTranslation } from 'react-i18next';

import {AiOutlineInfoCircle} from 'react-icons/ai';

// TapTap text editor
import Editor from './editor';
import NoneditableEditor from './noneditableEditor';
import SectionSeparator from "./sectionSeparator";

/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function DiscussValuesScoringWithComment (props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

// Props
var employeeSelectedValues = props.employeeValues;
var managerSelectedValues = props.managerValues;
var peerSelectedValues = props.peersValues;

const readOnly = props.readOnly;
var result = props.result;

const [incorrectInput, setIncorrectInput] = useState (false);

const [showValuesDescription, setShowValuesDescription] = useState (false);

// Change
if (Configs.devEnvironment) console.log("[discussValuesScoringWithComments.js][main()] - props:", props);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        // calculateResult(selectedValues);
}, []);

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-col mt-8 ml-2 m-1'>
                            <h3 className='flex text-l mb-4 italic font-bold'> 
                                {t('values-selector-scoring.title')}
                            </h3>
                            <span className='title-description'>{t('values-selector-scoring.description')}<b>{t('values-selector-scoring.help')}</b></span>


        
        <div className='flex flex-col'>
        <button className="text-left text-xs ml-1 mt-1 underline" onClick={e => setShowValuesDescription(!showValuesDescription)}>
            <div className="flex flex-row items-center ">
            <AiOutlineInfoCircle />  <span className="ml-1">{t('performance.values.show.values.description')}</span>
            </div>
        </button>
                                    
            {employeeSelectedValues?.map((element, index) => (
                    
                    <div className="flex flex-col border border-gray-200 rounded p-2 ml-0 lg:mr-8 m-1 w-full"> 
                            {/* Value definition and description */}
                            <div className="flex flex-col">
                                    <button className='flex text-lg mb-0 m-2 font-bold' onClick={e => setShowValuesDescription(!showValuesDescription)}>
                                        {element.value}
                                    </button>
                                    <span className={showValuesDescription ? "title-description mt-1 m-2" : "hidden"}>
                                        {element.description}
                                    </span>
                            </div>
                            <div className='flex flex-col lg:flex-row items-center justify-center lg:justify-end'>
                                
                                <div className="flex flex-col lg:flex-row items-center justify-between">
                                    {/* Manager */}
                                    {managerSelectedValues && managerSelectedValues?.length > 0 ? 
                                        <div className='flex flex-col items-end text-l mb-0 m-2 items-center text-center '>
                                            <div> {t('manager')} </div>
                                            <div className='flex flex-row items-end text-l mb-0 m-2 items-center text-center '>
                                                <button className={" flex px-4 py-2 bg-green-100 rounded-full mr-2 " + ((managerSelectedValues[index]?.selected?.toString() === "1") ? " border-4 border-black" :
                                                    null)}
                                                value={1}  disabled={true}> 1 </button>
                                                <button className={" flex px-4 py-2 bg-green-200 rounded-full mr-2 " + ((managerSelectedValues[index]?.selected?.toString() === "2") ? " border-4 border-black" :
                                                    null)}                            
                                                    value={2}  disabled={true}> 2 </button>
                                                <button className={" flex px-4 py-2 bg-green-300 rounded-full mr-2 " + ((managerSelectedValues[index]?.selected?.toString() === "3") ? " border-4 border-black" :
                                                    null)}                            
                                                    value={3}  disabled={true}> 3 </button>
                                                <button className={" flex px-4 py-2 bg-green-400 rounded-full mr-2 " + ((managerSelectedValues[index]?.selected?.toString() === "4") ? " border-4 border-black" :
                                                    null)}                            
                                                    value={4}  disabled={true}> 4 </button>
                                                <button className={" flex px-4 py-2 bg-green-500 rounded-full mr-2 " + ((managerSelectedValues[index]?.selected?.toString() === "5") ? " border-4 border-black" :
                                                    null)}                            
                                                    value={5}  disabled={true}> 5 </button>
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                    {/* Employee */}
                                    {employeeSelectedValues && employeeSelectedValues?.length > 0 ?
                                    <div className='flex flex-col items-end text-l mb-0 m-2 items-center text-center '>
                                        <div> {t('employee')} </div>
                                        <div className='flex flex-row items-end text-l mb-0 m-2 items-center text-center '>
                                            <button className={" flex px-4 py-2 bg-green-100 rounded-full mr-2 " + ((element?.selected?.toString() === "1") ? " border-4 border-black" :
                                                null)}
                                            value={1}  disabled={true}> 1 </button>
                                            <button className={" flex px-4 py-2 bg-green-200 rounded-full mr-2 " + ((element?.selected?.toString() === "2") ? " border-4 border-black" :
                                                null)}                            
                                                value={2}  disabled={true}> 2 </button>
                                            <button className={" flex px-4 py-2 bg-green-300 rounded-full mr-2 " + ((element?.selected?.toString() === "3") ? " border-4 border-black" :
                                                null)}                            
                                                value={3}  disabled={true}> 3 </button>
                                            <button className={" flex px-4 py-2 bg-green-400 rounded-full mr-2 " + ((element?.selected?.toString() === "4") ? " border-4 border-black" :
                                                null)}                            
                                                value={4}  disabled={true}> 4 </button>
                                            <button className={" flex px-4 py-2 bg-green-500 rounded-full mr-2 " + ((element?.selected?.toString() === "5") ? " border-4 border-black" :
                                                null)}                            
                                                value={5}  disabled={true}> 5 </button>
                                        </div>
                                    </div>
                                    :   
                                        null
                                    }
                                    {/* Peers */}
                                    {peerSelectedValues?.length > 0 ? 
                                        <div className='flex flex-col items-end text-l mb-0 m-2 items-center text-center '>
                                            <div> {t('peers')} </div>
                                            <div className='flex flex-row items-end text-l mb-0 m-2 items-center text-center '>
                                                <button className={" flex px-4 py-2 bg-green-100 rounded-full mr-2 " + ((peerSelectedValues[index]?.selected?.toString() === "1") ? " border-4 border-black" :
                                                    null)}
                                                value={1}  disabled={true}> 1 </button>
                                                <button className={" flex px-4 py-2 bg-green-200 rounded-full mr-2 " + ((peerSelectedValues[index]?.selected?.toString() === "2") ? " border-4 border-black" :
                                                    null)}                            
                                                    value={2}  disabled={true}> 2 </button>
                                                <button className={" flex px-4 py-2 bg-green-300 rounded-full mr-2 " + ((peerSelectedValues[index]?.selected?.toString() === "3") ? " border-4 border-black" :
                                                    null)}                            
                                                    value={3}  disabled={true}> 3 </button>
                                                <button className={" flex px-4 py-2 bg-green-400 rounded-full mr-2 " + ((peerSelectedValues[index]?.selected?.toString() === "4") ? " border-4 border-black" :
                                                    null)}                            
                                                    value={4}  disabled={true}> 4 </button>
                                                <button className={" flex px-4 py-2 bg-green-500 rounded-full mr-2 " + ((peerSelectedValues[index]?.selected?.toString() === "5") ? " border-4 border-black" :
                                                    null)}                            
                                                    value={5}  disabled={true}> 5 </button>
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                </ div>

                                
                            </div>
                            <div className='flex flex-col w-full items-start p-2 m-2  '>
                                
                            {managerSelectedValues ?
                                managerSelectedValues[index]?.comment ? 
                                        <React.Fragment>
                                            <div className="mb-4 text-sm">{t('performance.manager-portion.title')}</div>
                    

                                            <div className='border-l-4 border-gray-200'>
                                                <NoneditableEditor content={managerSelectedValues[index]?.comment} placeholder={t('form.fill-details')}
                                                className="text-sm" />
                                                
                                            </div>
                                        </React.Fragment>
                                : 
                                null
                            :
                            null
                            }

                            {element?.comment ? 
                                    <React.Fragment>
                                        <div className="mt-4  mb-4 text-sm">{t('performance.employee-portion.title')}</div>
                
                                        <div className='border-l-4 border-gray-200'>
                                            <NoneditableEditor content={element.comment} placeholder={t('form.fill-details')} 
                                            className="text-sm"/>
                                        </div>
                                    </React.Fragment>
                                :
                                    null
                                }

                            {peerSelectedValues ?
                                peerSelectedValues?.length > 0 && peerSelectedValues[index]?.comment ?
                                        <React.Fragment>
                                            <div className="mt-4  mb-4 text-sm">{t('performance.peer-feedbacks-portion.title')}</div>
                    
                                            <div className='border-l-4 border-gray-200'>
                                                <NoneditableEditor content={peerSelectedValues[index]?.comment} placeholder={t('form.fill-details')}
                                                className="text-sm" />
                                            </div>
                                            
                                        </React.Fragment>
                                    :
                                        null
                                :
                                null
                            }

                            </div>
                    </div>
                ))}

        </div>  
        <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-end mr-6 p-4 text-right ">
            {/** t('values-selector-scoring.result')  */} 
            {props?.managerResult ?
                <div className="p-4 m-4">
                    { t('manager')}
                    <span className="ml-4 text-4xl font-bold ">{props?.managerResult?.toFixed(2)}</span>
                </div>
            : null
            }

            {props?.employeeResult ? 
                <div className="p-4 m-4">
                    { t('employee')}
                    <span className="ml-4 text-4xl font-bold ">{props?.employeeResult?.toFixed(2)}</span>
                </div>
            :
            null}

            {props?.peersResult ? 
                <div className="p-4 m-4">
                    { t('peers')}
                    <span className="ml-4 text-4xl font-bold ">{props?.peersResult?.toFixed(2)}</span>
                </div>
            :
            null
            }
        </div>

        <span className="mr-8 text-red-600 text-right font-bold">
            {incorrectInput ? t('values-selector-scoring.error') : null}
        </span>        

    </div>

  );
}

export default DiscussValuesScoringWithComment;