// React
import React, {useEffect, useState} from 'react';

// Common
import Configs from '../Configs';
import * as apiCalls from '../common/apiCalls';

// 3P
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import hmacSHA512 from 'crypto-js/hmac-sha512';

// import Footer from '../common/footer';

// ---------------------------------------------------------------------------------------------------
function ResetPassword () {
    const [t, i18n] = useTranslation();

    // Variables set up 
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [password, setPassword] = useState('');

    const [wrongPassword, setWrongPassword] = useState(false);
    const [missingField, setMissingField] = useState(false);

    const [passwordErrors, setPasswordErrors] = useState([
        {error: "length", message: t('password.errors.length'), status: false},
        {error: "number", message: t('password.errors.number'), status: false},
        {error: "lowercase", message: t('password.errors.lowercase'), status: false},
        {error: "uppercase", message: t('password.errors.uppercase'), status: false},
        {error: "special", message: t('password.errors.special'), status: false},
    ]);

    // Would we use this?
    const [token, setToken] = useState('');

// ---------------------------------------------------------------------------------------------------
// Data validation methods
// ---------------------------------------------------------------------------------------------------    
useEffect (() => {
    // Extract token from URL params
    const windowUrl = window.location.search;
    const rpt = new URLSearchParams(windowUrl).get('rpt');
    
    // Set Token to be sent
    setToken(rpt);
    if (Configs.devEnvironment) console.log("[resetPassword.js][useEffect()] DEV ONLY log: -- rpt : ", rpt);
    }, []);

// ---------------------------------------------------------------------------------------------------
// Data validation methods
// ---------------------------------------------------------------------------------------------------    
    // Login button was pressed - validate the input
    async function validateInput (event) {
        event.preventDefault();

        // Validate input
        if (passwordRepeat === password && password != '' && passwordRepeat != '') {
            // All okay, proceed to check password
            const errors = validatePassword(password);
            setPasswordErrors(errors);

            // Review if errors are present
            var errorsAux = passwordErrors.filter((error) => error.status === true);

            if (errorsAux?.length === 0) {
              // Submit form
              setWrongPassword(false);
              submitNewPassword();
            } else {
                // State why password is not valid in a nice box with checkmarks
                // setWrongPassword(true);
                return;
            }
            
        } else {
            // missing input
            if (password === '' && passwordRepeat === '') {
                // Password is empty 
                setWrongPassword(false);
                setMissingField(true);       
            } else {
                // Password don't match
                setMissingField(false);
                setWrongPassword (true);
            }
        }
    }

    const validatePassword = (password) => {
        const errors = passwordErrors;
        if (password.length < 8) {
            errors[0].status = true;
        } else {
            errors[0].status = false;
        }

        if (!/\d/.test(password)) {
            errors[1].status = true;
        }  else {
            errors[1].status = false;
        }

        if (!/[a-z]/.test(password)) {
            errors[2].status = true;
        } else {
            errors[2].status = false;
        }

        if (!/[A-Z]/.test(password)) {
            errors[3].status = true;
        }  else {
            errors[3].status = false;
        }

        if (!/[\W]/.test(password)) {
            errors[4].status = true;
        } else {
            errors[4].status = false;
        }

        return errors;
      };

    async function passwordUpdated (password) {

        // Validate password
        const errors = validatePassword(password);
        setPasswordErrors(errors);

        if (Configs.devEnvironment) console.log ("[resetPassword.js][passwordUpdated()] - passwordErrors = ", errors);

        setPassword(password);
    }

// ---------------------------------------------------------------------------------------------------
// API calls 
// ---------------------------------------------------------------------------------------------------        
// Password update
async function submitNewPassword  () {
    // Fake log for password
    // if (Configs.devEnvironment) console.log("[resetPassword.js][useEffect()] - password = ", hmacSHA512("password", Configs.privateKey).toString());

    try {
        const passwordUpdateBody = { 
            token: token,
            password: hmacSHA512(password, Configs.privateKey).toString(),
            timestamp: new Date().toISOString
        };

        const result = await apiCalls.updatePassword(null, null, process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, passwordUpdateBody);

        if (result > 200) {
            console.error("[resetPassword.js][useEffect()] - Error updating password: ", result);
            throw result;
        } else {
            // Password was submitted in the database, redirect to login page
            window.location.href = '../';
        }
    } catch (error) {
        console.error("[resetPassword.js][useEffect()] - Error updating password: ", error);
    }
}
    

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------    
        return (
            <React.Fragment>
             
                <div className="flex flex-col justify-center h-screen bg-gradient-to-b from-green-200 to-white">

                    <div className='flex flex-col justify-center border bg-white border-gray-200 shadow m-2 sm:m-20 p-20 rounded'>

                        <div className='flex flex-col md:flex-row justify-center items-center'>
                            <div className="flex container w-[100px] h-[100px] md:w-[150px] md:h-[150px]">
                                <img className=" object-center object-contain" alt="Kincode-App-Logo" src={Configs.companyLogoLarge}
                                />
                            </div>
                            <div id="kincode-title" className='flex items-start text-2xl font-mono font-bold'>Kincode</div>
                        </div>

                        <div className='flex justify-center'>
                            <div className={wrongPassword ? 'block flex italic text-red-500 mb-4 ' : 'invisible'}>
                                        {wrongPassword ? t('password.reset.incorrect') : null}
                            </div>
                        </div>

                        

                        <form onSubmit={validateInput} className='flex flex-col justify-center text-left items-center w-full'>

                                <div className='text-lg m-4'>
                                    {t('password.reset.explanation')}
                                </div>
                                
                            <div className='flex flex-col justify-center items-start '>
                                <div className='flex flex-col sm:flex-row sm:justify-between m-2 p-2'>
                                    <label className='flex p-2'> 🔑 {t('password')}: </label>
                                    <input type="password" placeholder="****" 
                                        className='input-field'
                                        autoComplete='off'
                                        onChange = {(e) => passwordUpdated(e.target.value)}
                                    ></input>
                                </div>

                                <div className='flex flex-col sm:flex-row sm:justify-between m-2 p-2'>
                                    <label className='flex p-2'> 🔑 {t('password.repeat')}: </label>
                                    <input type="password" placeholder="****" 
                                        className='input-field'
                                        autoComplete='off'
                                        onChange = {(e) => setPasswordRepeat(e.target.value)}
                                    ></input>
                                </div>
                            </div>

                                <div className='save-button w-full lg:w-1/6 m-2 mt-4'>
                                    <button type="submit" className='text-center w-full' > {t('submit')}</button>
                                </div>

                        </form>


                        {/* Password requirements  */}
                        <div className='flex flex-col justify-between text-left items-center w-full text-xs mt-8'>
                            <div className='flex flex-col justify-center items-start'>

                            {passwordErrors?.length > 0 && password !== '' ? 
                                passwordErrors?.map((error, index) => (
                                    <div key={index} className='flex flex-row justify-center items-center'>
                                        <div className='flex p-2'>
                                            {error?.status === true ? "❌ " : "✅ " } {error.message}
                                            </div>
                                    </div>
                                ))
                            :
                                null
                            }
                            </div>

                        </div>

                    </div>
                    
                </div>
                
            </React.Fragment>
        );
    }

export default ResetPassword;