import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// Pages to Route
import NothingThere from '../common/nothingThere';
import Home from '../Home';

// DEVELOP
import RecruitmentHome from "../views/recruitment/recruitmentHome";
import RecruitmentInterviews from "../views/recruitment/recruitmentInterviews";
import RecruitmentCandidates from "../views/recruitment/recruitmentCandidates";
import RecruitmentJobs from "../views/recruitment/recruitmentJobs";
import RecruitmentReports from "../views/recruitment/recruitmentReports";

import OnboardingHR from "../views/onboarding/onboardingHR";
import OnboardingPlan from "../views/onboarding/onboardingPlan";
import OnboardingReporting from "../views/onboarding/onboardingReporting.js";

import CareerHome from "../views/career/careerHome";
import CareerTeam from "../views/career/careerTeam";
import CareerHR from "../views/career/careerHR";
import CareerGuidelineCreate from "../views/career/careerGuidelineCreate";
import CareerControl from "../views/career/careerControl";

// PERFORM
import PerformanceHome from "../views/performance/performanceHome";
import PerformanceStrengths from "../views/performance/performanceStrengths";
import PerformanceGrowth from "../views/performance/performanceGrowth";
import PerformanceReview from "../views/performance/performanceReview";
import PerformanceDiscuss from "../views/performance/performanceDiscuss";
import PerformanceFeedback from "../views/performance/performanceFeedback";

import PerformanceCampaignResult from "../views/performance/performanceCampaignResult";

import PerformanceTeam from "../views/performance/performanceTeam";
import PerformanceTeamFeedback from "../views/performance/performanceTeamFeedback";

import PerformanceHR from "../views/performance/performanceHR";
import PerformanceCampaigns from "../views/performance/performanceCampaigns";
import PerformanceCampaign from "../views/performance/performanceCampaign";
import PerformanceAdmin from "../views/performance/performanceAdmin";

import CalibrationHome from "../views/calibration/calibrationHome";
import CalibrationTalentMap from "../views/calibration/calibrationTalentMap";
import CalibrationHR from "../views/calibration/calibrationHR";

// ENGAGE
import PulseHome from "../views/pulse/pulseHome";
import PulseQuestions from "../views/pulse/pulseQuestions";
import PulseCreateCampaign from "../views/pulse/pulseCreateCampaign";
import PulseCampaigns from "../views/pulse/pulseCampaigns";
import PulseCampaign from "../views/pulse/pulseCampaign";
import PulseReporting from "../views/pulse/pulseReporting";
import PulseSurvey from "../views/survey/pulseSurvey";
import PulseDaily from "../views/survey/pulseDaily";

import RecognitionHome from "../views/recognition/recognitionHome";
import RecognitionCreate from "../views/recognition/recognitionCreate";
import RecognitionLeaderboards from "../views/recognition/recognitionLeaderboards.js";

import OneOnOne from "../views/oneOnOne/oneOnOne";
import OneOnOneMeeting from "../views/oneOnOne/oneOnOneMeeting";
import OneOnOnePersonal from "../views/oneOnOne/oneOnOnePersonal";
import OneOnOneHR from "../views/oneOnOne/oneOnOneHR";

// BASICS
import DaysOffHome from "../views/daysOff/daysoffHome";
import DaysOffRequest from "../views/daysOff/daysoffRequest";
import DaysOffTeam from "../views/daysOff/daysoffTeam";
import DaysOffTeamCalendar from "../views/daysOff/daysoffTeamCalendar.js";
import DaysOffHR from "../views/daysOff/daysoffHR";

import PunchHome from "../views/punch/punchHome";
import PunchTeam from "../views/punch/punchTeam";
import PunchHR from "../views/punch/punchHR";

import Profile from "../views/profile/profile";
import ProfileJob from "../views/profile/profileJob";
import ProfileComp from "../views/profile/profileComp";

import SettingsHome from "../views/settings/settingsHome";
import SettingsCompany from "../views/settings/settingsCompany";

import HRInbox from "../views/HRinbox/HRinbox";
import HRDashboard from "../views/HRinbox/HRDashboard";

import Organigram from "../views/organigram/organigramHome";
import OrganigramList from "../views/organigram/organigramEmployeeList";
import OrganigramEmployeeCreate from "../views/organigram/organigramEmployeeCreate";
import OrganigramReOrganize from "../views/organigram/organigramReorganize";

// SuperAdmin
import SuperAdmin from "../views/admin/superAdminHome";
import CCOaaS from "../views/admin/CCOaaS";
import StyleGuide from "../views/admin/styleguide";
import CompanyAdmin from "../views/admin/companyAdmin.js";
import CompanyCreate from "../views/admin/companyCreate.js";
import CompanyDetail from "../views/admin/companyDetail.js";
import AIExperiments from "../views/admin/aiExperiments.js";

// Move outside
import ResetPassword from '../authentication/resetPassword.js';
import RecognitionPublicSingleRecognition from "../views/recognition/recognitionPublicSingleRecognition.js";

// ---------------------------------------------------------------------------------------------------
function Routing () {
    const location = useLocation();
    const background = location.state && location.state.background;

    return (
    <React.Fragment>
    {/* Pass location to Routes so that Page remains background when ModalVersion is opened */}
        <Routes location={background || location}>
            <Route path="/" element={<Home />} />

            {/* ENGAGE */}
            <Route path="/recognition/" element={<RecognitionHome/>}/>
            <Route path="/recognition/create" element={<RecognitionCreate/>}/>
            {/* <Route path="/recognition/create/:encryptedParams" element={<RecognitionCreate/>}/> */}
            <Route path="/recognition/leaderboard/" element={<RecognitionLeaderboards/>}/>

            <Route path="/oneonone/" element={<OneOnOne/>}/>
            <Route path="/oneonone/team" element={<OneOnOne/>}/>
            <Route path="/oneonone/self" element={<OneOnOne/>}/>
            <Route path="/oneonone/meeting/:encryptedParams" element={<OneOnOneMeeting/>}/>
            <Route path="/oneonone/space/" element={<OneOnOnePersonal/>}/>
            <Route path="/oneonone/space/:encryptedParams" element={<OneOnOnePersonal/>}/>
            <Route path="/oneonone/hr" element={<OneOnOneHR/>}/>

                
            <Route path="/pulse/" element={<PulseHome/>}/>
            <Route path="/pulse/campaigns" element={<PulseCampaigns/>}/>
            <Route path="/pulse/campaigns/:campaignID" element={<PulseCampaign/>}/>
            <Route path="/pulse/campaigns/create" element={<PulseCreateCampaign/>}/>
            <Route path="/pulse/campaigns/edit/:campaignID" element={<PulseCreateCampaign/>}/>
            <Route path="/pulse/questions" element={<PulseQuestions/>}/>
            <Route path="/pulse/reporting" element={<PulseReporting/>}/>

            {/* Externally accessible pages - no auth required  */}
            <Route path="/pulseSurvey" element={<PulseSurvey/>}/>
            <Route path="/pulseSurvey/" element={<PulseSurvey/>}/>
            <Route path="/public/recognition/:id" element={<RecognitionPublicSingleRecognition/>}/>
            {/* <Route path="/pulseDaily/:encryptedParams" element={<PulseDaily/>} /> */}
            <Route path="/pulseDaily/" element={<PulseDaily/>} />


            {/* PERFORM */}
            <Route path="/performance/" element={<PerformanceHome/>}/>
            <Route path="/performance/self" element={<PerformanceHome/>}/>
            <Route path="/performance/self/:notificationID" element={<PerformanceHome/>}/>
            <Route path="/performance/self/strengths" element={<PerformanceStrengths/>}/>
            <Route path="/performance/self/growth" element={<PerformanceGrowth/>}/>
            <Route path="/performance/self/review" element={<PerformanceReview/>}/>
            <Route path="/performance/self/discuss" element={<PerformanceDiscuss/>}/>
            <Route path="/performance/self/campaign/:companyIDparam/:id/:employeeIDparam" element={<PerformanceCampaignResult/>}/>
            
            <Route path="/performance/feedback/:peerEmployeeID/:peerCompanyID/:peerCampaignID/:selfEmail/:notificationID" element={<PerformanceFeedback/>}/>

            <Route path="/performance/team" element={<PerformanceTeam/>}/>
            <Route path="/performance/team/employee/:encryptedParams" element={<PerformanceHome/>}/>
            <Route path="/performance/team/employee/strengths/:encryptedParams" element={<PerformanceStrengths/>}/>
            <Route path="/performance/team/employee/growth/:encryptedParams" element={<PerformanceGrowth/>}/>
            <Route path="/performance/team/employee/review/:encryptedParams" element={<PerformanceReview/>}/>
            <Route path="/performance/team/employee/discuss/:encryptedParams" element={<PerformanceDiscuss/>}/>
            <Route path="/performance/team/employee/campaign/:companyIDparam/:id/:employeeIDparam" element={<PerformanceCampaignResult/>}/>

            <Route path="/performance/campaigns" element={<PerformanceCampaigns/>}/>
            <Route path="/performance/campaigns/create" element={<PerformanceCampaign/>}/>
            <Route path="/performance/campaigns/edit/:encryptedParams" element={<PerformanceCampaign/>}/>
            <Route path="/performance/admin" element={<PerformanceAdmin/>}/>
            <Route path="/performance/hr/" element={<PerformanceHR/>}/>

            <Route path="/calibration/" element={<CalibrationHome />} />
            <Route path="/calibration/talentMap/" element={<CalibrationTalentMap />} />
            <Route path="/calibration/hr/" element={<CalibrationHR />} />

            {/* DEVELOP */}
            <Route path="/recruitment/" element={<RecruitmentHome />}/>
            <Route path="/recruitment/interviews" element={<RecruitmentInterviews />}/>
            <Route path="/recruitment/candidates" element={<RecruitmentCandidates />}/>
            <Route path="/recruitment/jobs" element={<RecruitmentJobs />}/>
            <Route path="/recruitment/reports" element={<RecruitmentReports />}/>

            <Route path="/onboarding/" element={<OnboardingPlan/>}/>
            <Route path="/onboarding/hr" element={<OnboardingHR/>}/>
            <Route path="/onboarding/plan/:encryptedParams" element={<OnboardingPlan/>}/>
            <Route path="/onboarding/plan/" element={<OnboardingPlan/>}/>
            <Route path="/onboarding/reporting" element={<OnboardingReporting/>}/>

            <Route path="/career/" element={<CareerHome/>}/>
            <Route path="/career/team" element={<CareerTeam/>}/>
            <Route path="/career/team/:encryptedParams" element={<CareerHome/>}/>
            <Route path="/career/hr" element={<CareerHR/>}/>
            <Route path="/career/hr/guideline/create" element={<CareerGuidelineCreate/>}/>
            <Route path="/career/hr/guideline/edit/:encryptedParams" element={<CareerGuidelineCreate/>}/>
            <Route path="/career/guideline/:encryptedParams" element={<CareerGuidelineCreate/>}/>
            {/* <Route path="/career/control" element={<CareerControl/>}/> */}

            {/* BASICS */}
            <Route path="/inbox/" element={<HRInbox/>}/>
            <Route path="/inbox/hr" element={<HRDashboard/>}/>

            <Route path="/timeoff/" element={<DaysOffHome/>}/>
            <Route path="/timeoff/request/" element={<DaysOffRequest/>}/>
            <Route path="/timeoff/request/:encryptedParams" element={<DaysOffRequest/>}/>
            <Route path="/timeoff/team/" element={<DaysOffTeam/>}/>
            <Route path="/timeoff/team/:encryptedParams" element={<DaysOffHome/>}/>
            <Route path="/timeoff/team/detailed/" element={<DaysOffTeamCalendar/>}/>
            <Route path="/timeoff/hr/" element={<DaysOffHR/>}/>

            <Route path="/punch/" element={<PunchHome/>}/>
            <Route path="/punch/team/" element={<PunchTeam/>}/>
            <Route path="/punch/team/:encryptedParams" element={<PunchHome/>}/>
            <Route path="/punch/hr/" element={<PunchHR/>}/>

            <Route path="/profile" element={<Profile/>}/>
            <Route path="/profile/:encryptedParams" element={<Profile/>}/>
            <Route path="/profile/job" element={<ProfileJob/>}/>
            <Route path="/profile/comp" element={<ProfileComp/>}/>

            <Route path="/organigram" element={<Organigram/>}/>
            <Route path="/organigram/employees" element={<OrganigramList/>}/>
            <Route path="/organigram/employees/create" element={<OrganigramEmployeeCreate/>}/>
            <Route path="/organigram/employees/edit/:encryptedParams" element={<OrganigramEmployeeCreate/>}/>
            <Route path="/organigram/employees/reorganize" element={<OrganigramReOrganize/>}/>

            <Route path="/settings/" element={<SettingsHome/>}/>
            <Route path="/settings/company" element={<SettingsCompany />} />

            <Route path="/admin/" element={<SuperAdmin/>}/>
            <Route path="/admin/ccoaas/" element={<CCOaaS/>}/>
            <Route path="/admin/style-guide/" element={<StyleGuide/>}/>
            <Route path="/admin/company/" element={<CompanyAdmin/>}/>
            <Route path="/admin/ccoaas/company/:id" element={<CompanyDetail/>}/>
            <Route path="/admin/company/create" element={<CompanyCreate/>}/>
            <Route path="/admin/ai-experiments/" element={<AIExperiments/>}/>

            <Route path="*" element={<NothingThere/>}/>

        </Routes>
        
    {/* Open Modal if state.background is defined */}
    {background && (
        <Routes>
            
        </Routes>
      )}            

    </React.Fragment>
    );

}

export default Routing;

  

  