// React
import React, { useEffect, useState} from 'react';
import {Link, Navigate, useLocation, useNavigate, useParams} from 'react-router-dom';

// Frameworks
import Configs from '../../Configs';
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Headers
import RecognitionHeader from './recognitionHeader';
import Header from '../../common/header';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import Select from 'react-select'; 
import NoneditableEditor from '../../widgets/noneditableEditor';
import Editor from '../../widgets/editor';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineInfoCircle} from 'react-icons/ai';

// ---------------------------------------------------------------------------------------------------
function RecognitionCreate () {
  const navigate = useNavigate();

    const {token, companyID, employeeID} = useAuth();
    const {companyPreferences, companyOrg, getRecognitionDataForCompany, toasterMessage, setToasterMessage} = useData();
    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // Recognition Attributes
    const [recognitionWho, setRecognitionWho] = useState(null);
    const [recognitionWhat, setRecognitionWhat] = useState(null);
    const [recognitionValues, setRecognitionValues] = useState(null);
    
    // Data to populate
    const [peopleOptions, setPeopleOptions] = useState([null]);
    const auxCompanyValues = companyPreferences?.companyValues ? companyPreferences?.companyValues : [null];
    const [companyValuesOptions, setCompanyValuesOptions] = useState(auxCompanyValues);

    const [companyValues, setCompanyValues] = useState([null]);

    // Toaster
    const [isPeopleLoading, setIsPeopleLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][main()] Company preferences - ", companyPreferences);


// ---------------------------------------------------------------------------------------------------
// UseEffect
// ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    useEffect (() => {

      if (auxCompanyValues.length > 1) {
        transformValuesArray(auxCompanyValues);
      }

      // Load people
      if (companyOrg) transformPeopleBody(companyOrg);

      }, [companyOrg, companyPreferences]);

// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------
async function sendRecognition(body) {
    // set Loading
    setIsLoading(true);

    // fetch from server
        fetch(Configs.platformPostRecognitionAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {

        method: 'POST',
        credentials: 'include',
        headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
        },

        body: JSON.stringify(body)
    })
        .then((response) => {
            // if (Configs.devEnvironment) console.log (response);
            if (response.status === 200) {
            setToasterMessage({message: t('success'), type: Configs.successToaster});

            // Clear frontend fields
            setRecognitionValues(null);
            setRecognitionWhat(null);
            setRecognitionWho(null);

            // Reload data
            getRecognitionDataForCompany();

            // Pass toaster message and go back
            navigate(-1, { state: { toasterMessage: {message:(t('success')), type: Configs.successToaster} } });

            } else if (response.status === 400) {
                // Something went wrong - inform user
                setToasterMessage({message: t('warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 404) {
                setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else {
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                throw response;
            }
        })
        .catch((error) => {
            console.error("[recognitionCreate.js][sendRecognition()] error:", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        });
}
    
// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
        };

// ---------------------------------------------------------------------------------------------------
// Frontend methods
// ---------------------------------------------------------------------------------------------------
// Validate inputs Create
async function validateInputsCreate(e) {
    e.preventDefault();

    // Validate inputs
    if (recognitionWho === null || recognitionWho === undefined) {
        setToasterMessage({message: t('recognition.create.error.who'), type: Configs.warningToaster});
        return;
    }

    if (recognitionWhat === null || recognitionWhat === undefined || recognitionWhat === "" || recognitionWhat === "<p></p>") {
        setToasterMessage({message: t('recognition.create.error.what'), type: Configs.warningToaster});
        return;
    }

    if (recognitionValues === null || recognitionValues === undefined || recognitionValues.length === 0) {
        setToasterMessage({message: t('recognition.create.error.values'), type: Configs.warningToaster});
        return;
    }

    // NO SELF RECOGNITIONS - Check that recognitionWho does not contain employeeID
    var auxArray = [];
    recognitionWho.forEach(person => {
        if (parseInt(person.value) === parseInt(employeeID)) {
            auxArray.push(person);
        }
    });

    if (auxArray.length > 0) {
        setToasterMessage({message: t('recognition.create.error.self'), type: Configs.warningToaster});
        return;
    }

    // Create body
    var body = {
        "companyID": parseInt(companyID),
        "employeeID": parseInt(employeeID),
        "who": recognitionWho,
        "what": recognitionWhat,
        "values": recognitionValues,
    }

    if (Configs.devEnvironment) console.log("[recognitionCreate.js][validateInputsCreate()]  ", body )

    // Call API
    sendRecognition(body);
  
}

const handleValuesChange = (selected) => {
    if (selected.length <= 3) {
        setRecognitionValues(selected);
      } else {
        alert(t('recognition.create.error.maxValues'));
        // Or use any other method to notify the user
      }
    
  };

const handlePeopleChange = (selected) => {
    setRecognitionWho(selected);
};


async function transformPeopleBody (body) {
    var auxArray = [];
    body.forEach(person => {
        // create aux element
        var auxElement = {
            value: person.employeeID,
            label: person.name + " " + person.surname
        }
        // Add aux element to array
        auxArray.push(auxElement);
        }
    );

    setPeopleOptions([...auxArray]);
}

async function transformValuesArray (valuesArray) {

    if (Configs.devEnvironment) console.log("[recognitionCreate.js][transformValuesArray()] valuesArray: ", valuesArray);

    var auxArray = [];
    valuesArray.forEach(value => {      
      // create aux element
      var auxElement = {
          value: value.value,
          label: value.value
      }
      // Add aux element to array
      auxArray.push(auxElement);
    });
    // Set campaignQuestions array to update screen
    setCompanyValuesOptions([...auxArray]);
  }

// Cancel button pressed      
async function cancel (e) {
  e.preventDefault();
  navigate(-1);
}

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
        <RecognitionHeader />
            
        <div className='flex flex-row items-start'>
          <div className="left-big-view">
        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


        <div className="flex flex-col text-left">

        <div className="flex flex-col mt-2 md:ml-2 m-1">
                <div className='flex flex-col items-start'>
                    <h2 className="flex text-xl mb-4 ">
                        <div className='flex flex-col items-start md:flex-row justify-left'>
                            <Tooltip content={t('recognition.create.description')} >
                                <div className='section-title' id='recognition-create-title'>
                                    {t('recognition.create.title')}
                                </div>
                            </Tooltip>

                        </div>
                    </h2>
                </div>
                
            </div>

           
                
                    <React.Fragment>

                                    <div className="flex flex-col m-1 md:m-4  ">


                                            {/* Who */}
                                            <div className="flex flex-col mt-6 mb-6">
                                                <div className='text-xl font-medium mb-4'> 👏 {t('recognition.create.who.title')} </div>
                                                { isPeopleLoading ?    
                                                    <Loader />
                                                :
                                                    
                                                    <Select
                                                        isMulti
                                                        isSearchable
                                                        autoFocus
                                                        className='w-full '
                                                        options={peopleOptions}
                                                        id="who"
                                                        value={recognitionWho}
                                                        placeholder={t('recognition.create.who.placeholder')}
                                                        styles={Configs.formDropdownStyle}
                                                        onChange={handlePeopleChange}
                                                    />

                                                }
                                            </div>

                                            {/* What */}
                                            <div className="flex flex-col items-start w-full mt-6 mb-6">
                                                <div className='text-xl font-medium mb-4'> 💡 {t('recognition.create.what.title')} </div>
                                                <div className='flex flex-col items-start w-full input-field '>
                                                    <Editor placeholder={t('recognition.create.what.placeholder')} 
                                                        setContent={setRecognitionWhat}/>
                                                </div>
                                            </div>

                                            {/* Values */}
                                            <div className="flex flex-col items-start w-full  mb-6 ">
                                                <div className='text-xl font-medium mb-4'> 🌟 {t('recognition.create.values.title')} </div>
                                                <div className='flex flex-row w-full space-x-2'>
                                                    <Select
                                                        isMulti
                                                        isSearchable
                                                        className='w-full  '
                                                        options={companyValuesOptions}
                                                        id="companyValues"
                                                        isDisabled={companyValues.length > 3}
                                                        value={recognitionValues}
                                                        placeholder={t('recognition.create.values.placeholder')}
                                                        styles={Configs.formDropdownStyle}
                                                        onChange={handleValuesChange}
                                                        />
                                                        {/* <div className='group relative flex items-center justify-center mr-2 w-max'>
                                                            <AiOutlineInfoCircle className='text-gray-500 hover:cursor-pointer' />  


                                                                <div className="hidden group-hover:block absolute top-7 bg-gray-300 p-1 
                                                                rounded items-center justify-center shadow-2xl w-80
                                                                text-sm h-auto z-40">
                                                                    <NoneditableEditor
                                                                        content={t('pulse.email-subject.info')}
                                                                        />
                                                                </div>
                                                        </div> */}
                                                </div>
                                            </div>
                                    </div>
                            </React.Fragment>
            
        </div>
        
        <div className='flex flex-col m-4 lg:m-2 lg:flex-row items-center justify-end'>
            <button type="button" id="cancel-recognition-button" class="secondary-button " onClick={cancel}> {t('cancel')} </button>
            <button className='save-button ' id="create-recognition-button" onClick={validateInputsCreate}>{t('recognition.create.title')}</button>
        </div>

          <SectionSeparator />

        </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default RecognitionCreate;