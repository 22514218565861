// React
import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';

// Header 
import PerformanceHeader from './performanceHeader';
import Header from '../../common/header';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Common and widgets
import Configs from '../../Configs';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import UserListItem from '../../widgets/userListItem';
import DiscussValuesScoringWithComment from '../../widgets/discussValuesScoringWithComments';
import ValuesScoring from '../../widgets/valuesScoring';
import { useAuth } from '../../common/appContext';

// Toaster & loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';

// Editors
import NonEditableEditor from '../../widgets/noneditableEditor';
import { useData } from '../../common/dataContext';

// Icons
import { IoInformationCircleOutline } from 'react-icons/io5';

// ---------------------------------------------------------------
function PerformanceCampaignResult () {

    // ---------------------------------------------
    // Vars
    // ---------------------------------------------
    // Campaign attributes
    let { id, companyIDparam, employeeIDparam } = useParams();

    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const {token, securityLevel, companyID, employeeID} = useAuth();
    const {companyPreferences, toasterMessage, setToasterMessage} = useData();

    // Campaign
    const [campaign, setCampaign] = useState(null);
    const [performanceReview, setPerformanceReview] = useState(null);

    // IC input for values
    const [selectedValues, setSelectedValues] = useState(null);
    const [numOfSelectedValues, setNumOfSelectedValues] = useState(0);
    const [valuesResult, setValuesResult] = useState(0);
    // Manager input for values
    const [managerSelectedValues, setManagerSelectedValues] = useState(null);
    const [managerNumOfSelectedValues, setManagerNumOfSelectedValues] = useState(0);
    const [managerValuesResult, setManagerValuesResult] = useState(0);
    // Peer input for values
    const [peerStrengthValuesResult, setPeerStrengthValuesResult] = useState(0);
    const [peerGrowthValuesResult, setPeerGrowthValuesResult] = useState(0);

    // Peer feedbacks
    const [peerFeedbackStrengthsQuestionnaire, setPeerFeedbackStrengthsQuestionnaire] = useState([null]);
    const [peerFeedbackStrengthsValues, setPeerFeedbackStrengthsValues] = useState([null]);
    const [peerFeedbackGrowthQuestionnaire, setPeerFeedbackGrowthQuestionnaire] = useState([null]);
    const [peerFeedbackGrowthValues, setPeerFeedbackGrowthValues] = useState([null]);

    // Action plan
    const [actionPlan, setActionPlan] = useState(null);

    // Loader and Toaster
    const [isLoading, setIsLoading] = useState(true);

    if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][main()] - Params: ", companyIDparam, employeeIDparam, id);

    // ---------------------------------------------
    // Method to run after page load
    // ---------------------------------------------
    useEffect(() => {

        // Get campaign
        getCampaignResults();

    }, []);

    // ---------------------------------------------
    // API calls
    // ---------------------------------------------
    async function getCampaignResults () {
        setIsLoading(true);

        // Get campaign
        // fetch from server      
      fetch(Configs.platformGetSpecificPerformanceReviewAPI + "?employeeID=" + employeeIDparam + "&companyID=" + companyID + "&campaignID=" + id, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        }})
          .then((response) => {
            setIsLoading(false);  
            if (response.status === 200) {
              // Found a 'completed' performance review
              response.json()
              .then (body => {
                if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][getCampaignResults()] - getCampaignResults: ", body);
                if (body) {
                  
                    // setToasterMessage({message: t('success'), type: Configs.successToaster});
                    setPerformanceReview(body);
                    updateFrontendData(body);
                    updatePeerFeedback(body[0]?.peerFeedback);
                    setActionPlan(body[0]?.developmentPlan[0]?.developmentPlan);
                }
                
                })
              .catch(error => {
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                console.error("[performanceCampaignResult.js][getCampaignResults()] Error parsing response JSON", error)
              });
            } else if (response.status === 400) {
                  // Something went wrong - inform user
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 401) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage?.clear();
                  window?.location?.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
            } else if (response.status === 404) {
                  setToasterMessage({message: t('not-found'), type: Configs.successToaster});
            } else {
                  //
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
                  throw response;
            }
          })
    
          .catch((error) => {
            setIsLoading(false);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            console.error("[performanceCampaignResult.js][getCampaignResults()] Error fetching data: ", error);
          });
    }


    async function shareReviewWithEmployee (e) {
        e.preventDefault();

        if (! (securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
          return;
        }
        
        // Ask for confirmation in an alert
        if (!window.confirm(t('performance.home.info.send-to-employee-confirm'))) {
          return;
        }

        setIsLoading(true);

        // HTTP POST request options
        const requestOptions = {
          method: 'POST',
          credentials: 'include',
          headers: { 
            'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // notice the Bearer before your token
               },
          body: JSON.stringify({ 
            employeeID: employeeIDparam,
            companyID: companyIDparam,
            campaignID: id,
            managerID: performanceReview[0]?.managerFeedback[0]?.managerID, 
            status: "sent"
          })
        };
    
        // Post to server      
        fetch(Configs.platformSubmitManagerFeedbackAPI, requestOptions)
            .then((response) => {
              setIsLoading(false);

              if (response.status === 200) {
                // All good
                response.json()
                .then (body => {
                    // console.log(body)
                    setToasterMessage({message: t('success'), type: Configs.successToaster});
                    getCampaignResults();
    
                  })
                .catch(error => {
                  console.error("[performanceCampaignResult.js][submitManagerFeedback()] Error parsing response JSON", error);
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
                });
              } else if (response.status === 400) {
                  // Something went wrong - inform user
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
              } else if (response.status === 401) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
              } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
              } else if (response.status === 404) {
                  // setToasterMessage({message: t('error'), type: Configs.warningToaster});
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
              } else {
                  //
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
                  throw response;
              }
            })
      
            .catch((error) => {
              setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
              console.error("[performanceCampaignResult.js][submitManagerFeedback()]  Error fetching data: ", error);
            });
    
    }
    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
        setToasterMessage(null);
      };

    // ---------------------------------------------------------------------------------------------------
    // Data management methods
    // ---------------------------------------------------------------------------------------------------
    async function updateFrontendData (body) {
        var numOfValuesSelectedAux = 0;
        var managerNumOfSelectedValues = 0;
  
        if (body) {
          body?.map((element, index) => {
            if (Configs.devEnvironment)  console.log("[performanceCampaignResult.js][updateFrontendData()] - element: ", element);
                if (element.type === "strength") {
                    // process strengths
                    setSelectedValues(element?.values);
                    setManagerSelectedValues(element?.managerFeedback[0]?.values);

  
                    if (body[0]?.campaign?.valuesSelectorType === "score" ||
                    body[0]?.campaign?.valuesSelectorType === "scoreWithComments") {
                      calculateValuesResult(element?.values);
                      calculateManagerValuesResult(element?.managerFeedback[0]?.values);
                    }
    
                    element.values?.map((value, index) => {
                        if (value.selected === true) {
                            numOfValuesSelectedAux += 1;
                        }
                    })
                    setNumOfSelectedValues(numOfValuesSelectedAux);
  
                    element?.managerFeedback[0]?.values?.map((value, index) => {
                      if (value.selected === true) {
                        managerNumOfSelectedValues += 1;
                      }
                  })
                  setManagerNumOfSelectedValues(managerNumOfSelectedValues);
  
  
                } else if (element.type === "growth") {
                    // process growth areas
                    
                } else {
                  // do nothing
                }
            })
          }
      }
  
      async function calculateValuesResult (inputGrades) {
        if (Configs.devEnvironment) console.log("[performanceCampaignResult.js][calculateValuesResult()] - inputGrades: ", inputGrades);
            // Calculate result for input
            var count = 0;
            var auxResult = 0;
            inputGrades?.forEach(element => {
                count = count + 1;
                auxResult = parseInt(element?.selected) + parseInt(auxResult);
            });
            setValuesResult(auxResult / count);
        
      }
  
      async function calculateManagerValuesResult (inputGrades) { 
        if (Configs.devEnvironment) console.log("[performanceCampaignResult.js][calculateValuesResult()] - inputGrades: ", inputGrades);

            // Calculate result for input
            var count = 0;
            var auxResult = 0;
            inputGrades?.forEach(element => {
                count += 1;
                auxResult = parseInt(auxResult) + parseInt(element?.selected);
            });
  
              setManagerValuesResult(auxResult / count);   
        
      }
  
      async function calculatePeerValuesResult (inputGrades) { 
        if (Configs.devEnvironment) console.log("[performanceCampaignResult.js][calculateValuesResult()] - inputGrades: ", inputGrades);

        // Calculate result for input
        var count = 0;
        var auxResult = 0;
        inputGrades?.forEach(element => {
            count += 1;
            auxResult = parseInt(auxResult) + parseInt(element?.sum / element?.count);
  
        });
  
          setPeerStrengthValuesResult(auxResult / count);   
    
      }
  
      async function getSelectedValues (valuesSelected, feedbackType) {
        // Update from Values widget
        if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][getSelectedValues()] - ", valuesSelected, feedbackType);
          if (feedbackType === "growth") {
            // setSelectedValues([...valuesSelected]);
          } else if (feedbackType === "strength") {
              // IC input
              setSelectedValues([...valuesSelected]);
          
          
        } else {
          // do nothing
        }
      }
  
      async function getManagerSelectedValues (valuesSelected, feedbackType) {
        // Update from Values widget
        if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][getSelectedValues()] - ", valuesSelected, feedbackType);
        if (feedbackType === "growth") {
          // setSelectedValues([...valuesSelected]);
        } else if (feedbackType === "strength") {
            // Manager portion
            setManagerSelectedValues([...valuesSelected]);
  
          
        } else {
          // do nothing
        }
      }
  
      async function updatePeerFeedback (peerFeedback) {
        // Update peer feedback
        if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][updatePeerFeedback()] - ", peerFeedback);
  
        // create empty arrays to fill in for each peer
        var strengthAnswersQuestionnaire = [];
        var growthAnswersQuestionnaire = [];
        var strengthsAnswersValues = [];
        var growthAnswersValues = [];
  
        if (peerFeedback && peerFeedback.length > 0) {
          peerFeedback?.forEach((peerFeedback, index) => {
              // STRENGTHS - Review each questionnaire and add to the right type of feedbacks
              peerFeedback?.strengthAnswersQuestionnaire?.forEach((answer, index) => {
                // Each line is an aswers to the questions, therefore a full array
                if (Configs.devEnvironment) console.log ("[performanceStrengths.js][updatePeerFeedback()] - Peer strengths questionnaire ", answer.type, answer.answer);
                if (answer.type === "strength") {
                  // strength answer
                  strengthAnswersQuestionnaire.push(answer.answer);
                }
              });
              // GROWTH - Review each questionnaire and add to the right type of feedbacks
              peerFeedback?.growthAnswersQuestionnaire?.forEach((answer, index) => {
                // Each line is an aswers to the questions, therefore a full array
                if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][updatePeerFeedback()] - Peer growth questionnaire ", answer.type, answer.answer);
                if (answer.type === "growth") {
                  // strength answer
                  growthAnswersQuestionnaire.push(answer.answer);
                }
              });
  
              // STRENGTH - Review values
              peerFeedback?.strengthAnswersValues?.forEach((values, index) => {
                // Each line is an aswers to the questions, therefore a full array
                if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][updatePeerFeedback()] - Peer strengths values: ", values);
                
                  // strength answer
                  values.forEach( (answer, index) => {
                    if (strengthsAnswersValues[index] === undefined) {
                      // first time
                      var auxObject = {
                        value: answer.value,
                        description: answer.description,
                        sum: parseInt(answer.selected),
                        count: 1,
                        selected: parseInt(answer.selected),
                        comment: answer.comment
                      }
                      // apply to index
                      strengthsAnswersValues[index] = auxObject;
                    } else {
                      if (strengthsAnswersValues[index].value === answer.value) {
                        var auxObject = {
                          value: answer.value,
                          description: answer.description,
                          sum: strengthsAnswersValues[index].sum + parseInt(answer.selected),
                          count: strengthsAnswersValues[index].count + 1,
                          selected: parseInt((strengthsAnswersValues[index].sum + parseInt(answer.selected)) / (strengthsAnswersValues[index].count + 1)),
                          comment: strengthsAnswersValues[index].comment + " \n \n " + (answer.comment ? answer.comment : "")
                        }
                        // apply to index
                        strengthsAnswersValues[index] = auxObject;
                      } else {
                        // something is wrong
                        console.error("[performanceCampaignResult.js][updatePeerFeedback()] - Something is wrong with the values array");
                      }
                    }
                  });
                  
              });
              
              // GROWTH - Review values
              peerFeedback?.growthAnswersValues?.forEach((values) => {
                // Each line is an aswers to the questions, therefore a full array
                if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][updatePeerFeedback()] - Peer growth values:", values);
  
                  // strength answer
                  values.forEach( (answer, index) => {
                    if (growthAnswersValues[index] === undefined) {
                      // first time
                      var auxObject = {
                        value: answer.value,
                        description: answer.description,
                        sum: parseInt(answer.selected),
                        count: 1,
                        selected: parseInt(answer.selected),
                        comment: answer.comment
                      }
                      // apply to index
                      growthAnswersValues[index] = auxObject;
                    } else {
                      if (growthAnswersValues[index].value === answer.value) {
                        var auxObject = {
                          value: answer.value,
                          description: answer.description,
                          sum: growthAnswersValues[index].sum + parseInt(answer.selected),
                          count: growthAnswersValues[index].count + 1,
                          selected: parseInt((growthAnswersValues[index].sum + parseInt(answer.selected)) / (growthAnswersValues[index].count + 1)),
                          comment: growthAnswersValues[index].comment + " \n \n " + (answer.comment ? answer.comment : "")
                        }
                        // apply to index
                        growthAnswersValues[index] = auxObject;
                      } else {
                        // something is wrong
                        console.error("[performanceCampaignResult.js][updatePeerFeedback()] - Something is wrong with the values array");
                      }
                    }
                  });
              });
  
              // Logging
              if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][updatePeerFeedback()] - Strength values Array: ", strengthAnswersQuestionnaire, " - Growth values array:", growthAnswersQuestionnaire);
              if (Configs.devEnvironment) console.log ("[performanceCampaignResult.js][updatePeerFeedback()] - Strength values Array: ", strengthsAnswersValues, " - Growth values array:", growthAnswersValues);
  
              // Update state variables
              setPeerFeedbackStrengthsQuestionnaire([...strengthAnswersQuestionnaire]);
              setPeerFeedbackGrowthQuestionnaire([...growthAnswersQuestionnaire]);
              setPeerFeedbackStrengthsValues([...strengthsAnswersValues]);
              setPeerFeedbackGrowthValues([...growthAnswersValues]);
              calculatePeerValuesResult(strengthsAnswersValues);
          })
        } else {
          // set to null
          setPeerFeedbackStrengthsQuestionnaire(null);
          setPeerFeedbackGrowthQuestionnaire(null);
          setPeerFeedbackStrengthsValues(null);
          setPeerFeedbackGrowthValues(null);
        }
      }

    // ---------------------------------------------
    // Render method
    // ---------------------------------------------
    if (!(securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))
    && (performanceReview?.length > 0 && performanceReview[0]?.status !== "sent")
    || (parseInt(employeeID) === parseInt(employeeIDparam) && (performanceReview?.length > 0 && performanceReview[0]?.status !== "sent"))) {
    // Is IC, and campaign has not been shared with employee
      return (
        <React.Fragment>
            <Header/>
            <Bot />

            <div className="flex flex-col lg:ml-[215px]">
                <PerformanceHeader />

                { isLoading ?
                    <Loader />
                :
                    <React.Fragment>
                        <Header />
                        <Bot />
                
                        
                        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                
                        <div className="flex flex-col m-2 lg:m-4 text-left ">
                        <div className="flex flex-col mt-2 ml-2 m-1">
                               
                            {isLoading ? 
                              <Loader />
                            :
                            performanceReview ?
                            
                                  <React.Fragment>
                                      {/* Campaign name and title */}
                                      <div className="flex flex-col items-center mt-2 ml-2 m-1">
                                          <h2 className="flex text-2xl font-bold m-4 ">
                                          {performanceReview[0]?.campaign?.name}
                                          </h2>
                                          <span className="title-description">
                                              {new Date(performanceReview[0]?.campaign?.startDate).getDate() + "/" 
                                                  + (new Date(performanceReview[0]?.campaign?.startDate).getMonth() + 1) + "/"
                                                  + new Date(performanceReview[0]?.campaign?.startDate).getFullYear()

                                                  + " - " +

                                              new Date(performanceReview[0]?.campaign?.endDate).getDate() + "/" 
                                                  + (new Date(performanceReview[0]?.campaign?.endDate).getMonth() + 1) + "/"
                                                  + new Date(performanceReview[0]?.campaign?.endDate).getFullYear()
                                                  }                                        
                                          </span>
                                      </div>

                                      {performanceReview[0]?.status !== "sent" ?
                                        <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                                          <span className="title-description italic">🔎 {" " + t('performance.home.manager-has-not-sent')}{" "}</span>
                                        </div>
                                      :
                                        null
                                      }

                                  </React.Fragment>
                                :
                                  <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                                    <span className="title-description italic">🔎 {" " + t('performance.campaign-result.empty')}{" "}</span>
                                  </div>
                            }
                            </div>
                          </div>

                        </React.Fragment>
                  }
              </div>

          </React.Fragment>
      )
    } else {
      
    

    return (
        <React.Fragment>
            <Header/>
            <Bot />

            <div className="flex flex-col lg:ml-[215px]">
                <PerformanceHeader />

                { isLoading ?
                    <Loader />
                :
                    <React.Fragment>
                        <Header />
                        <Bot />
                
                        
                        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                
                        <div className="flex flex-col m-2 lg:m-4 text-left ">
                        <div className="flex flex-col mt-2 ml-2 m-1">
                               
                            {isLoading ? 
                              <Loader />
                            :
                            performanceReview ?
                            
                                <React.Fragment>
                                    {/* Campaign name and title */}
                                    <div className="flex flex-col items-center mt-2 ml-2 m-1">
                                        <h2 className="flex text-2xl font-bold m-4 ">
                                        {performanceReview[0]?.campaign?.name}
                                        </h2>
                                        <span className="title-description">
                                            {new Date(performanceReview[0]?.campaign?.startDate).getDate() + "/" 
                                                + (new Date(performanceReview[0]?.campaign?.startDate).getMonth() + 1) + "/"
                                                + new Date(performanceReview[0]?.campaign?.startDate).getFullYear()

                                                + " - " +

                                            new Date(performanceReview[0]?.campaign?.endDate).getDate() + "/" 
                                                + (new Date(performanceReview[0]?.campaign?.endDate).getMonth() + 1) + "/"
                                                + new Date(performanceReview[0]?.campaign?.endDate).getFullYear()
                                                }                                        
                                        </span>
                                    </div>

                                    
                                    {/* Employee card */}
                                    <UserListItem user={performanceReview[0]?.employee[0]?.name + " " + performanceReview[0]?.employee[0]?.surname} 
                                    initials={performanceReview[0]?.employee[0]?.name?.slice(0,1) + performanceReview[0]?.employee[0]?.surname?.slice(0,1)} 
                                    role={performanceReview[0]?.employee[0]?.role} level={performanceReview[0]?.employee[0]?.level} />

                                    {/* Info - send to employee if !== sent */}
                                    {performanceReview[0].status !== "sent" && (parseInt(employeeID) !== parseInt(employeeIDparam)) ?
                                        <div>
                                          {/* Send warning */}                                        
                                              <div className='flex flex-col bg-blue-100 px-8 py-4 md:mr-8 m-2 text-sm items-start space-y-4 rounded-lg'>
                                                <div className='flex flex-row items-center'>
                                                <div className='mr-4'><IoInformationCircleOutline /></div>
                                                {t('performance.home.info.send-to-employee')}
                                                </div>

                                                <div className='flex flex-row justify-end w-full px-8'>
                                                <button className='save-button ml-4' onClick={(e) => {shareReviewWithEmployee(e)}}> {t('performance.home.send-to-employee')} </button>
                                                </div>
                                              </div>
                                        </div>
                                      :
                                      null
                                    }

                                    {/* Values section */}
                                    <div className="flex flex-col mt-4 ml-2 m-1">
                                        <h3 className='flex text-l mt-8 italic font-bold'> 
                                        {t('performance.values-section.title')} 
                                        </h3>
                                        
                                    {performanceReview ? 
                                        <React.Fragment>
                                                {(performanceReview[0]?.campaign?.valuesSelectorType === "score") ?
                                                    <React.Fragment>
                                                        <ValuesScoring values={managerSelectedValues} selectedValues={getManagerSelectedValues} 
                                                        result={managerValuesResult} setValuesResult={calculateManagerValuesResult}
                                                                readOnly={true} type="strength"/>
                                                    </React.Fragment>
                
                                                :
                                                (performanceReview[0]?.campaign?.valuesSelectorType === "scoreWithComments") ?
                
                
                                                    <React.Fragment>
                
                                                        <DiscussValuesScoringWithComment 
                                                            managerValues={managerSelectedValues} 
                                                            managerSelectedValues={getManagerSelectedValues} 
                                                            managerResult={managerValuesResult} 
                                                            setManagerValuesResult={calculateManagerValuesResult}
                                                            employeeValues={selectedValues} 
                                                            employeeSelectedValues={getSelectedValues} 
                                                            employeeResult={valuesResult} 
                                                            setEmployeeValuesResult={calculateValuesResult}
                                                            peersValues={peerFeedbackStrengthsValues}
                                                            peersResult={peerStrengthValuesResult}
                                                            readOnly={true} type="strength"/>
                
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <h3 className="flex text-l mb-4 italic font-bold">
                                                        {" "}
                                                        What are the values you demonstrate when at your best?
                                                        </h3>
                
                                                        {performanceReview?.map((element, index) => (
                
                                                        element.type === "strength" ? 
                                                            element?.values?.map ((value, index) => (
                                                                value.selected === true ? 
                                                                <div className="flex flex-col">
                                                                        <div className='flex flex-col border border-gray-200 rounded p-2 ml-0 md:mr-8 m-1'>
                                                                            <span className="text-l mb-0 m-2 font-bold">
                                                                            {value.value}
                                                                            </span>
                                                                            <span className="title-description">
                                                                            {value.description}
                                                                            </span>
                                                                        </div>
                                                                </div>
                                                            : null
                                                            ))
                                                            
                                                        : null
                                                        ))} 
                                                    </React.Fragment>
                                                }
                
                                                
                
                                        <SectionSeparator />
                
                                        </React.Fragment>  
                                        : 
                                        null
                                        }
                                        
                                    </div>
                
                
                                    {/* Comments and Feedback section */}
                                    <div className="flex flex-col mt-8 ml-2 m-1">
                                        <h3 className='flex text-l mt-8 italic font-bold'> 
                                        {t('performance.strengths.title')}
                                        </h3>
                                    </div>
                
                                        
                                            <React.Fragment>
                                            <h3 className='flex text-l mt-8 italic font-bold'> 
                                            {t('performance.manager-portion.title')}
                                            </h3>
                                            
                
                                            {performanceReview?.map((element, index) => (
                                            // Strengths section - Manager
                                                element.type === "strength" ?
                                                    element?.managerFeedback?.map ((managerFeedback, index) => (
                                                    managerFeedback?.type === "strength" ? 
                                                        managerFeedback?.questionnaire?.map ((question, index) => (
                                                        question?.type === "strength" && question?.answer !== "" && question?.answer !== "<p></<p>" 
                                                        && question?.answer !== " " && question?.answer ?
                                                            <div className="flex flex-col mt-8 ml-2 m-1">
                                                                <h3 className="mb-4">
                                                                    {question.title}
                                                                </h3>
                                                                <div className='border-l-4 border-gray-200'>
                                                                    <NonEditableEditor content={question.answer} className="text-sm"/>
                                                                </div>
                                                            </div>
                                                        :
                                                        null
                                                        
                                                        ))  
                                                    : null
                                                    ))
                
                                                :
                                                null
                                            ))}
                                            </React.Fragment>
                                        
                
                                    <h3 className='flex text-l mt-8 italic font-bold'> 
                                        {t('performance.employee-portion.title')}
                                    </h3>
                                    
                
                                    {performanceReview?.map((element, index) => (
                                        // Strengths section - Self review
                                        element.type === "strength" ?                       
                                        
                                            element?.questionnaire?.map ((question, index) => (
                                                question?.type === "strength" && question?.answer !== "" && question?.answer !== "<p></<p>" 
                                                && question?.answer !== " " && question?.answer ? 
                                                    <div className="flex flex-col mt-8 ml-2 m-1">
                                                        <h3 className="mb-4">
                                                            {question.title}
                                                        </h3>
                                                        <div className='border-l-4 border-gray-200'>
                                                            <NonEditableEditor content={question.answer}/>
                                                            </div>
                                                    </div>
                                            : null
                                            ))
                                            
                                            : null
                                    ))} 
                
                
                                    {peerFeedbackStrengthsQuestionnaire?.length > 0 
                                    && peerFeedbackStrengthsQuestionnaire[0] !== '' ?
                                            <React.Fragment>
                                                <h3 className='flex text-l mt-8 italic font-bold'> 
                                                {t('performance.peer-feedbacks-portion.title')}
                                                </h3>
                                                {peerFeedbackStrengthsQuestionnaire?.map((element, index) => (
                                                // Strengths section - Self review                          
                                                element !== "" && element !== "<p></p>" && element !== " " && element ?
                                                                <div className="flex flex-col mt-8 ml-2 m-1">
                                                                    <div className='border-l-4 border-gray-200'>
                                                                        <NonEditableEditor content={element}/>
                                                                    </div>
                                                                </div>
                                                : null
                            
                                                ))} 
                                            </React.Fragment>
                                        :
                                            null
                                        }
                
                                    <SectionSeparator />
                
                                    
                                    {/* Action plan */}
                                    <div className="flex flex-col mt-8 ml-2 m-1">
                                        <h3 className='flex text-l mt-8 italic font-bold'> 
                                        {t('performance.discuss.action-plan.title')}
                                        </h3>
                                        <span className='title-description'>{t('performance.discuss.action-plan.description')}</span>
                
                                    
                                        <React.Fragment>
                                        <div className='flex flex-col items-start'>
                                            {
                                            (!actionPlan) ?
                                            <div className='border-l-4 border-gray-200'>
                                                <NonEditableEditor content={t('performance.discuss.action-plan.empty')}/>
                                            </div>
                                            : 
                                            <div className='border-l-4 border-gray-200'>
                                                <NonEditableEditor content={actionPlan}/>
                                            </div>
                                            }
                                        
                                        </div>
                                        </React.Fragment>
                                        
                
                                        <SectionSeparator />
                                    </div>
                
                
                                    </React.Fragment>
                                    
                                : 
                                
                                <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                                <span className="title-description italic">🔎 {" " + t('performance.campaign-result.empty')}{" "}
                                </span>
                                </div>
                            }
                            
                            </div>
                        </div>
                    </React.Fragment>
                }

            </div>
            
        </React.Fragment>


    )
  }
}

export default PerformanceCampaignResult;