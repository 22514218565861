// React
import React, { useState, useEffect} from 'react';
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom';

// Header
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';

// 3P
import CryptoJS from 'crypto-js';
import i18next, { t } from 'i18next';

// Common
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';
import {  useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// Widgets
import UserListItem from '../../widgets/userListItem';
import ValuesSelector from '../../widgets/valuesSelector';
import ValuesScoring from '../../widgets/valuesScoring';
import ValuesScoringWithComment from '../../widgets/valuesScoringWithComment'

// Icons
import {IoWarningOutline} from 'react-icons/io5';

// Editor
import NoneditableEditor from '../../widgets/noneditableEditor';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';
import PerformanceHistory from '../../widgets/performance/performanceHistory';

// ---------------------------------------------------------------------------------------------------
function PerformanceReview () {
  // Using params when performance from team members
  let { encryptedParams } = useParams();
  const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

  // To pass location to the Header
  const location = useLocation();
  const navigate = useNavigate();

  const currentScreen = location.pathname;

  const {companyID, employeeID, token} = useAuth();
  const {toasterMessage, setToasterMessage, companyPreferences} = useData();
  const {selectedProfile, setSelectedProfile} = useData();

  const companyValuesAux = companyPreferences ? [...companyPreferences?.companyValues] : [];

  // General
  const {livePerformanceCampaign, setLivePerformanceCampaign, performanceReviewCampaigns} = useData();

    // Personal
    const [performanceReviewDraft, setPerformanceReviewDraft] = useState(null);
    const [isPerformanceReviewDraftLoading, setIsPerformanceReviewDraftLoading] = useState(false);
    const [performanceReviewManagerDraft, setPerformanceReviewManagerDraft] = useState(null);
    const [isPerformanceReviewManagerDraftLoading, setIsPerformanceReviewManagerDraftLoading] = useState(false);
  
  // Questionnaire that is shown in FrontEnd
   const [questionnaire, setQuestionnaire] = useState(performanceReviewCampaigns ? performanceReviewCampaigns[0]?.questionnaire : null);
   const [managerQuestionnaire, setManagerQuestionnaire] = useState(performanceReviewCampaigns ? performanceReviewCampaigns[0]?.managerQuestionnaire : null);
   
   // IC input for values
   const [selectedValues, setSelectedValues] = useState([...companyValuesAux]);
   const [numOfSelectedValues, setNumOfSelectedValues] = useState(0);
   const [valuesResult, setValuesResult] = useState(0);
   // Manager input for values
   const [managerSelectedValues, setManagerSelectedValues] = useState([...companyValuesAux]);
   const [managerNumOfSelectedValues, setManagerNumOfSelectedValues] = useState(0);
   const [managerValuesResult, setManagerValuesResult] = useState(0);
  
  
    // Logging only in Dev
    if (Configs.devEnvironment) console.log ("[performanceReview.js][main()] - Campaigns: ", performanceReviewCampaigns);
    if (Configs.devEnvironment) console.log ("[performanceReview.js][main()] - Existing employee draft: ", performanceReviewDraft, typeof(performanceReviewDraft));
    if (Configs.devEnvironment) console.log ("[performanceReview.js][main()] - Existing manager draft: ", performanceReviewManagerDraft, typeof(performanceReviewManagerDraft));

  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------
  useEffect (() => {
    // Fix selectedProfile
    if (!params && selectedProfile) {
      // IC
      setSelectedProfile(null); 
    } else if (params && !selectedProfile) {
      // Navigate back to team
      navigate("/performance/team");
      console.error("[performanceReview.js][useEffect()] - No selectedProfile found");
    }
  }, [params, selectedProfile]);

  useEffect (() => {
    // Get drafts
    async function getDrafts() {
        if (!params) {
          // IC
          try {
            setIsPerformanceReviewDraftLoading(true);
            
            const response = await apiCalls.getPerformanceReviewDraft(companyID, employeeID, token, performanceReviewCampaigns[0]?._id)

            setIsPerformanceReviewDraftLoading(false);

            if (response?.length > 0) {
              setPerformanceReviewDraft(response);
              updateFrontendData(response);
            } else {
              setPerformanceReviewDraft(null);
              updateFrontendData(null);
            }
            return true;

          } catch (error) {
            setPerformanceReviewDraft(error);
            return false;
          }

        } else {
          // Manager
          try {
            setIsPerformanceReviewManagerDraftLoading(true);

            const response = await apiCalls.getPerformanceReviewManagerDraft(companyID, params?.employeeID, token, performanceReviewCampaigns[0]?._id, params.managerID);

            setIsPerformanceReviewManagerDraftLoading(false);

            if (response?.length > 0) {
              setPerformanceReviewManagerDraft(response);
              updateManagerFrontendData(response);
            } else {
              if (response === 204) {
                setPerformanceReviewManagerDraft(null);
                updateManagerFrontendData(null);
              }
            }

            // Employee draft
            const employeeDraft = await apiCalls.getPerformanceReviewDraft(companyID, params?.employeeID, token, performanceReviewCampaigns[0]?._id)
            if (employeeDraft?.length > 0) {
              setPerformanceReviewDraft(employeeDraft);
              updateFrontendData(employeeDraft);
            }

            return true;

          } catch (error) {
            setPerformanceReviewManagerDraft(error);
            return false;
          }
        }
      }

      getDrafts();

  }, [companyID, employeeID, token, performanceReviewCampaigns]);

  useEffect (() => {
    // NAVIGATION
    // Navigate to the right screen, because there is a draft found
    if (!params) {
      // IC
      if ((performanceReviewDraft) 
        && (performanceReviewDraft[0]?.status === "completed" || performanceReviewDraft[0]?.status === "submitted" ||performanceReviewDraft[0]?.status === "sent")) {
            // We have a draft that has been either submitted by IC, or even completed by Manager
            setLivePerformanceCampaign(false);
            navigate("/performance/self/discuss");
      } else {
        setLivePerformanceCampaign(true);
      }
    }
  }, [performanceReviewDraft]);

  useEffect (() => {
      // Team - Manager
      if ((performanceReviewManagerDraft) 
          && (performanceReviewManagerDraft[0]?.status === "completed" || performanceReviewManagerDraft[0]?.status === "sent")) {
              // We have a draft that has been completed by Manager
                navigate("/performance/team/employee/discuss/" +
                encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                    employeeID: params?.employeeID,
                    employee: params?.employee,
                    companyID: companyID,
                    managerID: employeeID
                    }),Configs.privateKey).toString()));
                    setLivePerformanceCampaign(false);
      } else {
        setLivePerformanceCampaign(true);
      }
  
  }, [performanceReviewManagerDraft]);

  // ---------------------------------------------------------------------------------------------------
  // Data transformation methods
  // ---------------------------------------------------------------------------------------------------
  async function updateFrontendData (body) {
    // Get information to populate dashboard
    var numOfValuesSelectedAux = 0;
    if (body && body?.length > 0) {
      const newQuestionnaire = [];
      const newValues = [];

      body?.forEach(element => {
            if (element.type === "strength") {
                newQuestionnaire.push(...element?.questionnaire);
                newValues.push(...element?.values); 

                if (performanceReviewCampaigns[0]?.valuesSelectorType === "score" ||performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
                  calculateValuesResult([...element?.values]);
                }

                element.values?.forEach((value) => {
                    if (value.selected === true) {
                        numOfValuesSelectedAux += 1;
                    }
                });
              }
            });
                
            // Set state
            setQuestionnaire(newQuestionnaire);
            setSelectedValues(newValues);
            setNumOfSelectedValues(numOfValuesSelectedAux);

      } else {
        // If empty, set the default values
        if (performanceReviewCampaigns && companyValuesAux) {
          setQuestionnaire([...performanceReviewCampaigns[0]?.questionnaire]);
          setSelectedValues([...companyPreferences?.companyValues]);
        }
      }
  }

  async function updateManagerFrontendData (body) {
    // Get information to populate dashboard
    var numOfValuesSelectedAux = 0;
    if (body && body?.length > 0) {
      const newManagerQuestionnaire = [];
      const newManagerValues = [];
      
      body.forEach((element) => {
            if (element.type === "strength") {
                newManagerQuestionnaire.push(...element.questionnaire);
                newManagerValues.push(...element.values);

                if (performanceReviewCampaigns[0]?.valuesSelectorType === "score" || performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
                  calculateManagerValuesResult([...element.values]);
                }

                element?.values?.forEach(element => {
                    if (element.selected === true) {
                        numOfValuesSelectedAux += 1;
                    }
                });
              }
            });
          
      setManagerQuestionnaire(newManagerQuestionnaire);
      setManagerSelectedValues(newManagerValues);
      setManagerNumOfSelectedValues(numOfValuesSelectedAux);
    } else {
      // If empty, set the default values
      if (performanceReviewCampaigns && companyValuesAux) {
        setManagerQuestionnaire([...performanceReviewCampaigns[0]?.managerQuestionnaire]);
        setManagerSelectedValues([...companyValuesAux]);
      }
    }
  }


  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------
  
  async function submitDraft () {
        // HTTP POST request options
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 
          'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` // notice the Bearer before your token
             },
        body: JSON.stringify({ 
          employeeID: employeeID,
          companyID: companyID,
          campaignID: performanceReviewCampaigns[0]._id,
          status: "submitted"
        })
      };

      // Post to server      
      fetch(Configs.platformSubmitSelfFeedbackAPI, requestOptions)
          .then((response) => {
            console.log (response);
            if (response.status === 200) {
              // All good
              navigate("/performance/self/discuss");
              setLivePerformanceCampaign(false);
              setToasterMessage({message: t('performance.review.self.success'), type: Configs.successToaster});

              response.json()
              .then (body => {
                  // console.log(body)
                })
              .catch(error => {
                console.error("[performanceReview.js][submitDraft()]Error parsing response JSON", error);
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
              });
            } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  // setToasterMessage({message: t('error'), type: Configs.warningToaster});
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
          })
    
          .catch((error) => {
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            console.error("[performanceReview.js][submitDraft()]  Error fetching data: ", error);
          });
    }

    async function submitDraftManager () {
      // HTTP POST request options
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 
        'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
           },
      body: JSON.stringify({ 
        employeeID: params?.employeeID,
        companyID: companyID,
        campaignID: performanceReviewCampaigns[0]._id,
        managerID: employeeID,
        status: "completed"
      })
    };

    // Post to server      
    fetch(Configs.platformSubmitManagerFeedbackAPI, requestOptions)
        .then((response) => {
          // console.log (response);
          if (response.status === 200) {
            setLivePerformanceCampaign(false);
            navigate("/performance/team/employee/discuss/"+
              encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                  employeeID: params.employeeID,
                  employee: params.employee,
                  companyID: companyID,
                  managerID: employeeID
                  }),Configs.privateKey).toString()));

            setToasterMessage({message: t('success'), type: Configs.successToaster});

            // All good
            response.json()
            .then (body => {
                // console.log(body)
                setToasterMessage({message: t('success'), type: Configs.successToaster});

              })
            .catch(error => {
              console.error("[performanceReview.js][submitManagerFeedback()] Error parsing response JSON", error);
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
            });
          } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
          } else if (response.status === 401) {
              // Force logout
              setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
              sessionStorage.clear();
              window.location.reload();
          } else if (response.status === 403) {
              // Force logout
              setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
              sessionStorage.clear();
              window.location.reload();
          } else if (response.status === 404) {
              // setToasterMessage({message: t('error'), type: Configs.warningToaster});
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
          } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
          }
        })
  
        .catch((error) => {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          console.error("[performanceReview.js][submitManagerFeedback()]  Error fetching data: ", error);
        });
  }

// ---------------------------------------------------------------------------------------------------
// Data validation methods
// ---------------------------------------------------------------------------------------------------
async function validateDraft () {
  // Review if all IC data inputs are filled, and allow submission.
  // If not, alert the user that they are not allowed to submit the draft.
  var allInputsFilled = true;


  // Check if there is an existingDraft otherwise, alert the user that they are not allowed
  if (performanceReviewDraft === null ||performanceReviewDraft === undefined ||performanceReviewDraft?.length < 1) {
    setToasterMessage({message: t('error'), type: Configs.errorToaster});
    return false;

  } else {
    // Check if questionnaire is filled
    performanceReviewDraft[0]?.questionnaire?.forEach(question => {
      if (question.answer === "" || question.answer === null || question.answer === undefined) {
        allInputsFilled = false;
      }
    });

    // Check if all values are seleted
    performanceReviewDraft[0]?.values?.forEach(value => {
      if (value.selected === "" || value.selected === null || value.selected === undefined) {
        allInputsFilled = false;
      }
    });

    // check if all comments are filled for 'scoreWithComments' type
    if (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
      performanceReviewDraft[0]?.values?.forEach(value => {
        if (value.comment === "" || value.comment === "<p></p>" || value.comment === null || value.comment === undefined || !value.comment) {
          allInputsFilled = false;
        }
      });
    }

    // Submit the draft for manager review
    if (allInputsFilled) {
      submitDraft();
      return true;
    } else {
      setToasterMessage({message: t('field.missing'), type: Configs.errorToaster});
      return false;
    }
  }
}

async function validateManagerDraft () {
  // If we are not in a manager view, return false
  if (!params) {
    return false;
  }

  // Review if all Manager data inputs are filled, including a submitted draft of the IC, and allow Manager submission.
  // If not, alert the user that they are not allowed to submit the draft.
  var allInputsFilled = true;

  // IC submitted draft
  if (performanceReviewDraft === null ||performanceReviewDraft === undefined ||performanceReviewDraft?.length < 1) {
    setToasterMessage({message: t('performance.review.missing-employee-portion'), type: Configs.errorToaster});
    allInputsFilled = false;
    return false;
  } else if (performanceReviewDraft[0]?.status !== "completed" &&performanceReviewDraft[0]?.status !== "submitted") {
    setToasterMessage({message: t('performance.review.missing-employee-portion'), type: Configs.errorToaster});
    allInputsFilled = false;
    return false;
  }

  // managerExistingDraft
  // Check if questionnaire is filled
  performanceReviewManagerDraft[0]?.questionnaire?.forEach(question => {
    if (question.answer === "" || question.answer === null || question.answer === undefined) {
      allInputsFilled = false;
    }
  });

  // managerSelectedValues with all selected
  performanceReviewManagerDraft[0]?.values?.forEach(value => {
    if (value.selected === "" || value.selected === null || value.selected === undefined) {
      allInputsFilled = false;
    }
  });

  // if "scoreWithComments" type, all comments filled
  if (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
    performanceReviewManagerDraft[0]?.values?.forEach(value => {
      if (value.comment === "" || value.comment === "<p></p>" || value.comment === null || value.comment === undefined || !value.comment) {
        allInputsFilled = false;
      }
    });
  }

  if (allInputsFilled) {
    submitDraftManager();
  } else {
    setToasterMessage({message: t('field.missing'), type: Configs.errorToaster});
  }
}
// ---------------------------------------------------------------------------------------------------
// Data management methods
// ---------------------------------------------------------------------------------------------------
    async function calculateValuesResult (inputGrades) { 
      if (performanceReviewCampaigns[0]?.valuesSelectorType === "score" || performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
          // Calculate result for input
          var count = 0;
          var auxResult = 0;
          inputGrades.forEach(element => {
              count = count + 1;
              auxResult = parseInt(element.selected) + parseInt(auxResult);
          });
          setValuesResult(auxResult / count);
      }
    }

    async function calculateManagerValuesResult (inputGrades) { 
      if (performanceReviewCampaigns[0]?.valuesSelectorType === "score" ||performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
          // Calculate result for input
          var count = 0;
          var auxResult = 0;
          inputGrades.forEach(element => {
              count += 1;
              auxResult = parseInt(auxResult) + parseInt(element.selected);
          });
    
            setManagerValuesResult(auxResult / count);   
      }
    }

    async function getSelectedValues (valuesSelected, feedbackType) {
      // Update from Values widget
      if (Configs.devEnvironment) console.log ("[performanceStrengths.js][getSelectedValues()] - ", valuesSelected, feedbackType);
        if (feedbackType === "growth") {
          // setSelectedValues([...valuesSelected]);
        } else if (feedbackType === "strength") {
            // IC input
            setSelectedValues([...valuesSelected]);
        
        
      } else {
        // do nothing
      }
    }
    async function getManagerSelectedValues (valuesSelected, feedbackType) {
      // Update from Values widget
      if (Configs.devEnvironment) console.log ("[performanceStrengths.js][getSelectedValues()] - ", valuesSelected, feedbackType);
      if (feedbackType === "growth") {
        // setSelectedValues([...valuesSelected]);
      } else if (feedbackType === "strength") {
          // Manager portion
          setManagerSelectedValues([...valuesSelected]);
    
        
      } else {
        // do nothing
      }
    }


// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
};

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">        

        {params ? 
          /* Manager */
              <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen}  params={params}/>
            :
            /* IC */
              <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen} />          
            }

        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


        <div className='flex flex-row items-start text-left'>
          <div className="left-big-view">
          <div className="flex flex-col m-2">
            {params ?
                  /* Manager Title & Description*/ 
                      <div className="flex flex-col text-left items-start space-y-4">
                        <UserListItem user={params.employee} initials={params.employee.slice(0,1)} role={params.role} level={params.level}
                        profilePicture={params?.profilePicture} />
                        <Tooltip content={"👀 " + t('performance.review.title')}>
                          <h2 className="section-title"> {t('performance.home.team-member') + " " + params?.employee} </h2>
                        </Tooltip>
                    </div>
                : 
                    /* IC Title & Description */
                    <div className="flex flex-col text-left items-start">
                      <Tooltip content={t('performance.review.description')}>
                        <h2 className="section-title"> 
                        {t('performance.review.title')}
                        </h2>
                      </Tooltip>
                    </div>
            }
          </div>

          {params ? 
                    /* Manager portion */  
                      <React.Fragment>
                        <div className='subsection-title mt-3 border border-white border-b-gray-200 p-2 '>
                            {t('performance.manager-portion.title')}
                        </div>

                        <div className='flex flex-col mt-8 ml-2 m-1'>
                        {/* <h3 className='flex text-l mb-4 italic font-bold'> {t('performance.strengths.values.title')}
                        </h3>
                        <span className='title-description'> {t('performance.strengths.values.description')} <b>{t('performance.strengths.values.help')}</b></span> */}

                            <div className='flex flex-col'>

                                  { isPerformanceReviewManagerDraftLoading ? 

                                    <Loader />
                                  :
                                  
                                  performanceReviewCampaigns?.length > 0 ||performanceReviewCampaigns !== null ? 
                                    
                                        (performanceReviewCampaigns[0]?.valuesSelectorType === "score") ? 
                                    
                                            <ValuesScoring values={managerSelectedValues} selectedValues={getManagerSelectedValues} result={managerValuesResult} setValuesResult={calculateManagerValuesResult}
                                            readOnly={true} type="strength"/>
                                        :
                                        
                                        (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ? 
                                            <ValuesScoringWithComment values={managerSelectedValues} selectedValues={getManagerSelectedValues} result={managerValuesResult} setValuesResult={calculateManagerValuesResult}
                                            readOnly={true} type="strength"/>
                                        :
                                            // Default - ValuesSelector
                                            <ValuesSelector values={managerSelectedValues} selectedValues={getManagerSelectedValues} 
                                            readOnly={true} type="strength"/>
                                    : 
                                      null
                                  }
                            </div>
                        </div>

                        
                          {managerQuestionnaire?.map((element, index) => (

                            element.type === "strength" && element.answer ? 
                                
                                <div className='flex flex-col mt-8 ml-2 m-1'>
                                    <h3 className='flex text-l mb-4 font-bold'> {element.title}
                                    </h3>
                                    <span className='title-description'>{element.description}</span>
        
                                    <div className='border-l-4 border-gray-200'>
                                      <NoneditableEditor content={element.answer}/> 
                                    </div>        
                                </div>
        
                            : null
        
                          ))}

                      
                      </React.Fragment>
                        
                        :
                        /* IC - no manager input */ 
                        null
                    }

          {params ?  
          // Only for Managers: Employee portion title - Strengths
          <React.Fragment>
            
            <SectionSeparator />

              <div className='subsection-title mt-3 border border-white border-b-gray-200 p-2 '>
                {t('performance.employee-portion.title')}
              </div>
            </React.Fragment>
          :
            null
          }

          { isPerformanceReviewDraftLoading ? 
            <Loader />
          :
          // Changed this for Fio's requirement Taxdown
            !params && performanceReviewDraft?.length > 0  ? 
                  <React.Fragment>
                    <div className="flex flex-col mt-4 ml-2 m-1">
                            {performanceReviewCampaigns ?
                            // Employee portion values 
                              <React.Fragment>
                                
                                      {(performanceReviewCampaigns[0]?.valuesSelectorType === "score") ?
                                                <ValuesScoring values={selectedValues} selectedValues={getSelectedValues} 
                                                result={valuesResult} setValuesResult={calculateValuesResult}
                                                      readOnly={true} type="strength"/>
                                      :
                                      (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ?
                                                <ValuesScoringWithComment values={selectedValues} selectedValues={getSelectedValues} 
                                                result={valuesResult} setValuesResult={calculateValuesResult}
                                                      readOnly={true} type="strength"/>
                                          :
                                              // Default - ValuesSelector
                                              <ValuesSelector values={selectedValues} selectedValues={getSelectedValues} 
                                              readOnly={true} type="strength"/>
                                      }
                              </React.Fragment>  
                              : 
                                null
                              }
                        </div>

                    {performanceReviewDraft?.map((element, index) => (
                       // Employee portion strengths Questionnaire
                        element?.type === "strength" ? 
                            element?.questionnaire?.map ((question, index) => (
                              question?.type === "strength" ? 
                                <div className="flex flex-col mt-2 ml-2 m-1 w-full">
                                    <h3 className="flex text-l mb-4 italic font-bold">
                                      {question?.title}
                                    </h3>
                                    <div className='border-l-4 border-gray-200 w-full'>
                                      <NoneditableEditor content={question?.answer}/> 
                                    </div>

                                </div>
                            : null
                            ))
                            
                          : null
                        ))} 
                    </React.Fragment>  
            : 
                    <React.Fragment>
                      {params ? 
                        <div className="flex flex-col mt-2 ml-2 m-1">
                          {t('performance.review.missing-employee-portion')}
                        </div>
                      :
                        <div className="flex flex-col mt-2 ml-2 m-1">
                          {t('performance.review.missing-your-portion')}
                        </div>
                      }
                      
                    </React.Fragment>
          }

          {/* Growth - TO-DO CHANGE THIS hidden*/  }
          <div className="flex flex-col mt-8 ml-2 m-1 hidden">
            <h2 className="flex text-xl mb-4 underline font-bold"> {t('performance.growth.title')} </h2>
          </div>

          {params ? 
                    /* Manager portion - Growth - TO-DO CHANGE THIS hidden*/  
                      <React.Fragment>
                        <div className='m-2 mt-6 border border-white border-b-gray-200 p-2 mb-0 hidden'>
                          {t('performance.manager-portion.title')}
                        </div>


                          {isPerformanceReviewManagerDraftLoading ?

                              <Loader />

                            :

                            performanceReviewManagerDraft?.length > 0  ?

                                managerQuestionnaire?.map((element, index) => (
                                    // Manager questionnaire - Growth
                                    element.type === "growth" && element.answer ?  
                                      <div className='flex flex-col mt-8 ml-2 m-1'>
                                          <h3 className='flex text-l mb-4 italic font-bold'> {element.title}
                                          </h3>
                                          <span className='title-description'>{element.description}</span>
              
                                          <div className='border-l-4 border-gray-200'>
                                            <NoneditableEditor content={element.answer}/> 
                                          </div>        
                                      </div>
                
                                    : 
                                      null
                                ))

                            : 
                            
                                null
                            
                          }

                          <div className='flex flex-col mt-8 ml-2 m-1'>
                            

                            {performanceReviewCampaigns?.length > 0 ?
                            (performanceReviewCampaigns[0]?.valuesSelectorType === "score" ||performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ? 
                            // Manager values - Growth
                                          null
                                      :
                                      <React.Fragment>
                                        <h3 className='flex text-l mb-4 italic font-bold'> {t('performance.growth.values.title')}
                                        </h3>
                                        <span className='text-sm mb-4'> {t('performance.growth.values.description')} <b>{t('performance.growth.values.help')}</b></span>
                                
                                        <div className='flex flex-col '>
          
                                                <React.Fragment>
                                                    
                                                    {(performanceReviewCampaigns[0]?.valuesSelectorType === "score") ?
                                                              <ValuesScoring values={managerSelectedValues} selectedValues={getManagerSelectedValues} 
                                                              result={managerValuesResult} setValuesResult={calculateManagerValuesResult}
                                                                    readOnly={true} type="growth"/>

                                                    :
                                                    (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ?
                                                              <ValuesScoringWithComment values={managerSelectedValues} selectedValues={getManagerSelectedValues} 
                                                              result={managerValuesResult} setValuesResult={calculateManagerValuesResult}
                                                                    readOnly={true} type="growth"/>
                                                        :
                                                            // Default - ValuesSelector
                                                            <ValuesSelector values={managerSelectedValues} selectedValues={getManagerSelectedValues} 
                                                            readOnly={true} type="growth"/>
                                                    }
                                                </React.Fragment>                                               

                                        </div>
                                      </React.Fragment>
                                      :
                                        null
                                      }          
                          </div>
                      </React.Fragment>
                        :
                        /* IC - no manager */ 
                        null
                    }
          
          {params ? 
            // For Manager: employee portion Growth 
            <div className='m-2 mt-6 border border-white border-b-gray-200 p-2 hidden'>
              {t('performance.employee-portion.title')}
            </div>
          :
            null
          }

          {performanceReviewDraft?.length > 0 ? 
          
                    <React.Fragment>

                      {performanceReviewDraft?.map((element, index) => (
                        // Employee questionnaire - Growth
                        element?.type === "growth" ? 
                            element?.questionnaire?.map ((question, index) => (
                              question?.type === "growth" ? 
                                <div className="flex flex-col mt-2 ml-2 m-1 w-full">
                                    <h3 className="flex text-l mb-4 italic font-bold">
                                      {question.title}
                                    </h3>
                                    <span className='title-description'>{question.description}</span>
                                    <div className='border-l-4 border-gray-200 w-full'>
                                      <NoneditableEditor content={question.answer}/> 
                                    </div>
                                </div>
                            : null
                            ))
                            
                          : null
                        ))} 


                        { performanceReviewCampaigns ? 
                              
                          (performanceReviewCampaigns[0]?.valuesSelectorType === "score" ||performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ?
                          // Employee portion - Growth - Values    
                          null
                          :
                                <div className='flex flex-col mt-8 ml-2 m-1'>
                                    <h3 className='flex text-l mb-4 italic font-bold'> Which values would you like to improve?
                                    </h3>
                                    <div className='flex flex-col'>
                                        
                                        <React.Fragment>
                                                
                                                {(performanceReviewCampaigns[0]?.valuesSelectorType === "score") ?
                                                          <ValuesScoring values={selectedValues} selectedValues={getSelectedValues} 
                                                          result={valuesResult} setValuesResult={calculateValuesResult}
                                                                readOnly={true} type="growth"/>

                                                :
                                                (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ?
                                                          <ValuesScoringWithComment values={selectedValues} selectedValues={getSelectedValues} 
                                                          result={valuesResult} setValuesResult={calculateValuesResult}
                                                                readOnly={true} type="growth"/>
                                                    :
                                                        // Default - ValuesSelector
                                                        <ValuesSelector values={selectedValues} selectedValues={getSelectedValues} 
                                                        readOnly={true} type="growth"/>
                                                }
                                        </React.Fragment> 

                                    </div>

                                </div>
                            :
                              null

                            }
                          </React.Fragment>
                : 
                          // Unhide when we have growth section well defined
                          <React.Fragment>
                                <div className="flex flex-col mt-2 ml-2 m-1 hidden">
                                  {t('performance.review.missing-employee-portion')}
                                </div>
                          </React.Fragment>
                }

                <div className="flex flex-col w-auto mb-12 mt-8">

                {params ?
                
                    /* Manager */
                    <div className='flex flex-row bg-yellow-100 p-2 md:mr-8 m-2 text-sm items-center'>
                      <div className='mr-4'><IoWarningOutline /></div>
                      {t('performance.review.complete-by-manager.warning')}
                    </div>
                  :
                    /* IC */
                    <div className='flex flex-row bg-yellow-100 p-2 md:mr-8 m-2 text-sm items-center'>
                      <div className='mr-4'><IoWarningOutline /></div>
                      {t('performance.review.send-to-manager.warning')}
                    </div>
                }

                  <div className="flex flex-row justify-center m-2 md:justify-end w-auto mb-12">
                  {params ?
                  /* Manager - only available if employee has submitted also their portion */
                          <React.Fragment>
                            {performanceReviewDraft?.length > 0 && performanceReviewManagerDraft && performanceReviewManagerDraft != null && performanceReviewManagerDraft?.length > 0 ? 
                                  <button className="save-button m-4" 
                                  onClick={validateManagerDraft}
                                  disabled={(isPerformanceReviewManagerDraftLoading || !performanceReviewManagerDraft || performanceReviewManagerDraft === undefined || performanceReviewManagerDraft?.length < 1 || performanceReviewManagerDraft === null)  ? true : false}>
                                    {t('performance.review.close')}
                                  </button>
                              //{/* </Link> */}
                            :
                              <Tooltip content={t('performance.review.missing-employee-portion')}>
                                <button disabled={true} className="save-button">
                                    {t('performance.review.close')}
                                </button>
                              </Tooltip>
                              
                              }
                          </React.Fragment>

                        :
                        /* IC */  
                        //<Link to="/performance/self/discuss">
                            <button className={" p-2 pl-8 pr-8 rounded-lg align-right m-4 " + (
                            (!performanceReviewDraft || performanceReviewDraft === undefined || performanceReviewDraft?.length < 1 ||performanceReviewDraft === null)  ? " bg-gray-200 " : " save-button ")
                            } 
                            onClick={validateDraft}
                            disabled={(isPerformanceReviewDraftLoading || !performanceReviewDraft || performanceReviewDraft === undefined || performanceReviewDraft?.length < 1 || performanceReviewDraft === null)  ? true : false}>
                              {t('performance.review.send-to-manager')}
                            </button>
                        // </Link>
                    }
                  </div>
                </div>
            </div>
            <div className='right-small-view'>
              <PerformanceHistory companyIDinput={companyID} employeeIDinput={params ? params?.employeeID : employeeID}/>
            </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default PerformanceReview;