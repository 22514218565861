// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common and Widgets
import Configs from '../../Configs.js';
import Header from '../../common/header.js';
import DaysOffHeader from '../../common/daysOffHeader.js';
import SectionSeparator from '../../widgets/sectionSeparator.js';
import Bot from '../../common/support/bot.js';
import { useAuth } from '../../common/appContext.js';
import { useData } from '../../common/dataContext.js';
import Forbidden from '../../common/forbidden.js';
import Pagination from '../../widgets/pagination.js';

// Widgets
import UserListItem from '../../widgets/userListItem.js';
import FilterWidget from '../../widgets/data/filterWidget.js';
import CalendarWidget from '../../widgets/calendarView.js'
import RequestListItem from '../../widgets/daysOff/requestListItem.js';
import FeedbackPopup from '../../widgets/feedback/feedbackPopUp.js';
import CalendarDetailedView from '../../widgets/calendarDetailedView.js';

// Toaster & Loader
import Toaster from '../../common/support/toaster.js';
import Loader from '../../common/support/loader.js';
import Tooltip from '../../common/support/tooltip.js';
// import Tooltip from '../../common/support/tooltip';

// --------------------------------------------------------------
function DaysOffTeamCalendar () {
    const {t} = useTranslation();
    // const navigate = useNavigate();

    const employeesHolidays = [
      { employeeID: 1, daysOffApproved: ["2024-05-01", "2024-05-19"], daysOffPending: ["2024-05-02", "2024-05-19"] },
      { employeeID: 2, daysOffApproved: ["2024-04-20"] , daysOffPending: ["2024-05-02", "2024-05-19"] },
      { employeeID: 3, daysOffApproved: [], daysOffPending: []},
      // More team members
    ];

    const {token, employeeID, companyID} = useAuth();
    const {teamDirects, teamOrg,  companyOrg, toasterMessage, setToasterMessage} = useData();
    const {holidaysTeamPendingRequests} = useData();
    
    // Team calendar format
    // const teamMembers = [
    //   { employeeID: 1, daysOffApproved: ["2024-05-01", "2024-05-19"], daysOffPending: ["2024-05-02", "2024-05-19"] },
    //   { employeeID: 2, daysOffApproved: ["2024-04-20"] , daysOffPending: ["2024-05-02", "2024-05-19"] },
    //   { employeeID: 3, daysOffApproved: [], daysOffPending []},
    //   // More team members
    // ];
    // Team calendar
    const [teamCalendar, setTeamCalendar] = useState(employeesHolidays);

    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

    const [startDate, setStartDate] = useState(new Date()); // Default to current week  

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

// ---------------------------------------------------------------------------------------------------
// Page start methods
// --------------------------------------------------------------------------------------------------- 
useEffect (() => {
        
    }, [teamDirects, teamOrg, companyOrg]);

// ---------------------------------------------------------------------------------------------------
// Data transformation methods
// ---------------------------------------------------------------------------------------------------
function transformTeamCalendar (calendarAux) {
  // Check if there are events  
  if (calendarAux.length > 0) {

      // Aux arrays
      var auxCalendar = [];
      var auxApproved = [];
      var auxPending = [];

      // Iterate across requests, and add dates between startDate and endDate
      calendarAux?.map(request => {

          request?.request?.map(date => {
            var auxStartDate = new Date(date?.startDate).setHours(0,0,0,0);
            var auxEndDate = new Date(date?.endDate).setHours(0,0,0,0);
            var currentDate = auxStartDate;

            // Iterate across dates
            while (currentDate <= auxEndDate) {
              if (Configs.devEnvironment) console.log("[daysOffTeam.js][transformTeamCalendar()] Current date: ", currentDate);

              // Add to calendar
              auxCalendar.push({
                employee: request?.employee?.employee,
                leaveType: date?.leaveType,
                date: currentDate,
                status: request?.status
              });

              // Add to approved/pending
              if (date?.status === Configs.daysOffStatusApproved) {
                auxApproved.push(currentDate);
              } else if (date?.status === Configs.daysOffStatusPending) {
                auxPending.push(currentDate);
              }

              // Move to next day
              currentDate = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1)).setHours(0,0,0,0);
            }
          });
      });


      // Set state
      setTeamCalendar([...auxCalendar]);

    } else {
      setTeamCalendar([]);
    }

  }

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
        setToasterMessage(null);
    };
      
// ---------------------------------------------------------------------------------------------------
// Filter methods
// ---------------------------------------------------------------------------------------------------   
    const handleFilterChange = (filteredDataInput) => {
        if (Configs.devEnvironment) console.log("[daysOffTeamCalendar.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);

            matchSelectedTeamCalendar(filteredDataInput);
      };

// ---------------------------------------------------------------------------------------------------
// Match selected team calendar
// ---------------------------------------------------------------------------------------------------
function matchSelectedTeamCalendar (filteredDataInput) {
  if (Configs.devEnvironment) console.log("[daysOffTeamCalendar.js][matchSelectedTeamCalendar()] filteredDataInput: ", filteredDataInput);

  // Itearate across team members from filtered data, and create the team calendar view
  var auxTeamCalendar = [];

  // Get a unique list of employeeIDs from filteredDataInput
  const uniqueEmployeeIDs = new Set(filteredDataInput?.map(member => member?.employeeID));

  uniqueEmployeeIDs?.forEach(employeeID => {

    // Check if the member has any days off from 
    employeesHolidays?.map(member => {
      if (member?.employeeID === employeeID) {
        if (Configs.devEnvironment) console.log("[daysOffTeamCalendar.js][matchSelectedTeamCalendar()] member: ", member);

        // Add approved days off and pending

      }
    });

  });
  
  // Set team calendar
  setTeamCalendar(auxTeamCalendar);

}


// ------------------------------------------------------------
// Pagination methods
// ------------------------------------------------------------
 // Calculate the indices of the first and last row on the current page
 const indexOfLastRow = currentPage * rowsPerPage;
 const indexOfFirstRow = indexOfLastRow - rowsPerPage;
 const currentRows = holidaysTeamPendingRequests?.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        if (Configs.devEnvironment) console.log("[recognitionHome.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page since the number of pages will change
    };

// ------------------------------------------------------
// Return
// ------------------------------------------------------
return (
    <React.Fragment>
        <Header />
        <Bot/>

        <div className="main">
          <DaysOffHeader />

            {/* Main screen */}
            {/* <div className='flex flex-row items-start'> */}
              {/* <div className="left-big-view">               */}
              <div className='flex flex-col m-2 lg:mb-8 lg:mx-8 '>

                  {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                  <FeedbackPopup showPopup={showFeedbackPopup} showFeedbackChance={Configs.feedbackPopupChance} 
                  onFeedbackSubmitted={() => setShowFeedbackPopup(false)}/>

                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                        <h2 className="flex text-xl text-left mb-4 ">
                          <div className='flex flex-col md:flex-row justify-left'>
                            <Tooltip content={t('time-off.team.description')}>
                              <div className='section-title'>
                              📅 {t('time-off.team.detailed.title')}
                              </div>
                            </Tooltip>
                          </div>
                        </h2>
                        <span className="title-description">
                          {/* Section description */}
                          

                          {/* Self-service resources section */}
                          {/* {i18next.language === 'es' ? 
                                <a href="https://resources.kincode.app/es/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                    <b className='ml-1 hover:underline'>{t('oneOnOne.home.help')} </b>
                                </a>
                                  
                                  : 

                                <a href="https://resources.kincode.app/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                  <b className='ml-1 hover:underline'>{t('oneOnOne.home.help')} </b>
                                </a>
                            } */}
                          
                        </span>
                    </div>

                    {/* Filter */}
                    <FilterWidget 
                      data={teamOrg} 
                      onFilterChange={handleFilterChange}
                      teamTypeFilter={true} defaultTeamTypeFilter={"team"}
                      managerFilter={true}
                      employeeFilter={true}
                      areaFilter={true} 
                      subareaFilter={true}
                    />

                    {/* Calendar View */}
                    <CalendarDetailedView 
                      teamMembers={teamCalendar} 
                      startDate={startDate} />

                  <SectionSeparator />

              {/* </div> */}

              {/* Help side */}
              {/* <div className='right-small-view'>
               
              </div> */}
             </div> 
        </div>
    </React.Fragment>
);
}

export default DaysOffTeamCalendar;