// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link} from "react-router-dom";

// Common and widgets
import Header from '../../common/header';
import SuperAdminHeader from './superAdminHeader';
import Configs from "../../Configs";
import Bot from '../../common/support/bot'; 
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import Pagination from '../../widgets/pagination';
import FilterWidget from '../../widgets/data/filterWidget';

// 3P
import { useTranslation } from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';


function SuperAdminHome () {
  const {t} = useTranslation();

  const {token, employeeID, companyID} = useAuth();
  const {toasterMessage, setToasterMessage} = useData();

  const [activity, setActivity] = useState(null);

  // Toaster and Loader variables
  const [isLoading, setIsLoading] = useState(false);

  // Variables 
  const [modifiedData, setModifiedData] = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default number of rows per p

  // ---------------------------------------------------------------------------------------------------
  // useEffects
  // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      // Get information to populate dashboard
      if (activity === null) getUserActivity();
    }, []);

  // ---------------------------------------------------------------------------------------------------
  // API
  // ---------------------------------------------------------------------------------------------------

    async function getUserActivity () {
      setIsLoading(true);

      // fetch from server      
      fetch(Configs.platformAdminGetUserActivityAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        }
      })
          .then((response) => {
            setIsLoading(false);
            // console.log (response);
            if (response.status === 200) {
              // Username and password mathched in the database - get Token and save it in the session
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log("[superAdminHome.js][getUserActivity] body from request: ", body)
                    // Sort body by timestamp in descending order
                    body.sort((a, b) => (a.timestamp < b.timestamp) ? -1 : 1);
                    
                    setActivity([...body]);
                    setModifiedData([...body]);
                })
              .catch(error => {
                console.error("[superAdminHome.js][getUserActivity()] Error parsing response JSON", error);
              });
            } else if (response.status === 401) {
                // Force logout
                sessionStorage.clear();
                window.location.href = Configs.devEnvironment ? "http://localhost:3000" : "https://platform.kincode.app";
            } else if (response.status === 403) {
                // Force logout
                sessionStorage.clear();
                window.location.href = Configs.devEnvironment ? "http://localhost:3000" : "https://platform.kincode.app";
            } else if (response.status === 404) {
                // Force logout

            } else {
              // There is an error - delete info
              // sessionStorage.removeItem('performanceCampaigns');
              // sessionStorage.setItem('livePerformanceCampaign', false);
              throw response;
            }
          })
    
          .catch((error) => {
            setIsLoading(false);
            console.error("[superAdminHome.js][getUserActivity()] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
          });
  }

// ------------------------------------------------------------
// Pagination methods
// ------------------------------------------------------------
 // Calculate the indices of the first and last row on the current page
 const indexOfLastRow = currentPage * rowsPerPage;
 const indexOfFirstRow = indexOfLastRow - rowsPerPage;
 const currentRows = modifiedData?.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        if (Configs.devEnvironment) console.log("[recognitionHome.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page since the number of pages will change
    };

// ---------------------------------------------------------------------------------------------------
// Filter methods
// ---------------------------------------------------------------------------------------------------   
const handleFilterChange = (filteredDataInput) => {
  if (Configs.devEnvironment) console.log("[oneOnOne.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
  setModifiedData([...filteredDataInput]);
};

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
  
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
          <SuperAdminHeader />
            
            <div className="flex flex-col m-2 lg:m-4">
        

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


          <div className="flex flex-col text-left ">

            { isLoading ?
              <Loader />
              :
              activity ? 
                <div className='flex flex-col'>
                  <FilterWidget data={activity} 
                  onFilterChange={handleFilterChange} 
                    teamTypeFilter={false}
                    managerFilter={false}
                    employeeFilter={false}
                    areaFilter={false} 
                    subareaFilter={false}
                    typeFilter={true}
                    companyIDFilter={true}
                    />


                    {currentRows?.map((item, index) => (
                      <div className={" flex flex-col mt-2 md:ml-2 m-1 border border-gray rounded-lg shadow p-4 " +
                      ((item.type === Configs.auditLogTypeNastyCrossCompanyRequest 
                        || item.type === Configs.auditLogTypeLoginAttemptWOUsernameOrPassword 
                        || item.type === Configs.auditLogTypeLoginNonExistingUsername
                        || item.type === Configs.auditLogTypeLoginIncorrectPassword
                        || item.type === Configs.auditLogTypePasswordResetNonexistingUsername 
                    ) ? " bg-red-100 " : " bg-white ")}>
                        <span className='flex flex-col text-left mb-2 items-start text-sm font-medium'>
                            {item?.comment}
                        </span>
                        <span className='text-left text-xs text-gray-600'>
                            {new Date(item?.timestamp).toDateString() + " - " + new Date(item?.timestamp).toLocaleTimeString()}
                        </span>

                      </div>
                    ))}

                    <Pagination
                        totalRows={activity?.length} 
                        rowsPerPage={rowsPerPage} 
                        currentPage={currentPage} 
                        onPageChange={handlePageChange} 
                        handleRowsPerPageChange={handleRowsPerPageChange}/>
                </div>
            :
              null
            }            
          </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default SuperAdminHome;