import { t } from "i18next";
import React from "react";

const Pagination = ({ totalRows, rowsPerPage, currentPage, onPageChange, handleRowsPerPageChange }) => {
    // ---------------------------------------------------------------
    // Variables
    const pageCount = Math.ceil(totalRows / rowsPerPage);
    
    const pages = [];
    for (let i = 1; i <= pageCount; i++) {
      pages.push(i);
    }
  

// ---------------------------------------------------------------
// Render
// ---------------------------------------------------------------
if (pageCount === 1 || pageCount === 0) return null;

return (
      <div className="flex flex-row justify-between text-xs text-gray-600 p-2">
            {/* Page X of Y */}
            <div className="page-count">
                {t('page')} {currentPage} {t('of')} {pageCount}
            </div>
            {/* Pages */}
            <div className="pagination ">
                    {pages.map(page => (
                    <button
                        key={page}
                        onClick={() => onPageChange(page)}
                        className={page === currentPage ? ' ml-1 bg-green-200 rounded-full px-3 py-2 ' : ' ml-1 hover:bg-gray-200 px-3 py-2 rounded-full'}
                    >
                        {page}
                    </button>
                    ))}
            </div>
            {/* Rows per page */}
            <div className="rows-per-page">
                <label htmlFor="rows-per-page">{t('pagination.rows-per-page')}:</label>
                <select id="rows-per-page" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                {[5, 10, 20, 50].map((number) => (
                    <option key={number} value={number}>
                        {number}
                    </option>
                ))}
                </select>
            </div>
        </div>
    );
  };


// export
export default Pagination;