// React imports
import React, { useState, useEffect, useTransition} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Imports from 3rd party libraries
import CryptoJS from 'crypto-js';
import {XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineSeries, VerticalBarSeries, VerticalBarSeriesCanvas, MarkSeries, HeatmapSeries} from 'react-vis';
import Select from 'react-select';

// Common
import Configs from "../../Configs";
import Header from '../../common/header';
import PulseHeader from './pulseHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import FilterWidget from '../../widgets/data/filterWidget.js';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';


import Heatmap from '../../widgets/heatmap';

// Icons
import {AiOutlineDownload, AiOutlineFilter} from 'react-icons/ai';
import {IoIosHelpCircleOutline} from 'react-icons/io';
import { FcHeatMap } from "react-icons/fc";
import { FaTableCells } from "react-icons/fa6";


// i18n
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';


// ---------------------------------------------------------------------------------------------------
function PulseReporting () {
  // Navigate
  const navigate = useNavigate();
  const {t} = useTranslation();

  // Employee Info
  const { token, companyID, employeeID, securityLevel } = useAuth();
  const { companyPrefereneces, pulseReportingData, isPulseReportingDataLoading } = useData();
  const {teamDirects, companyOrg, teamOrg} = useData();
  const {toasterMessage, setToasterMessage} = useData();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default number of rows per page

  if (Configs.devEnvironment) console.log('[pulseReporting.js][main()] pulseReportingData:', pulseReportingData);

  const [data, setData] =  useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const [selectedRow, setSelectedRow] = useState('manager');
  const [selectedColumn, setSelectedColumn] = useState('category');


// ---------------------------------------------------------------------------------------------------
// After loading the view
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Check security level
        if (!(securityLevel.includes(Configs.securityLevelHR) || securityLevel.includes(Configs.securityLevelExec))) {
          navigate('/pulse/');
        }

        if (pulseReportingData && pulseReportingData?.length > 0) {
            // transformPulseReportingData();
            const aggregatedDataAux = aggregateData(pulseReportingData, selectedRow, selectedColumn);
            if (aggregatedDataAux?.length > 0) {
              setData(aggregatedDataAux);
              setFilteredData(aggregatedDataAux);
            }
        }

    }, [pulseReportingData]);

// ---------------------------------------------------------------------------------------------------
// Data transform functions
// ---------------------------------------------------------------------------------------------------
  function aggregateData(records, rowDimension, columnDimension, includeDirectReports = true) {
    if (Configs.devEnvironment) console.log('[pulseReporting.js][aggregateData()] starting data aggregation');
    const aggregatedData = {};

    records?.forEach(record => {
        // Extract row and column keys based on the selected dimensions
        // if (Configs.devEnvironment) console.log('[pulseReporting.js][aggregateData()] record:', record);

        const rowKey = record[rowDimension];
        const columnKey = record[columnDimension];

        if (!aggregatedData[rowKey]) {
            aggregatedData[rowKey] = {};
        }

        if (!aggregatedData[rowKey][columnKey]) {
            aggregatedData[rowKey][columnKey] = {
                totalScore: 0,
                count: 0,
                companyBenchmarkScore: 0
            };
        }

        // Update aggregated values
        aggregatedData[rowKey][columnKey].totalScore += record.answerSum;
        aggregatedData[rowKey][columnKey].count += record.answerCount;
        aggregatedData[rowKey][columnKey].companyBenchmarkScore = record.companyBenchmarkScore;
    });

    if (Configs.devEnvironment) console.log('[pulseReporting.js][aggregateData()] aggregateData:', aggregatedData);

    // Calculate average scores and vsBenchmark average
    for (const rowKey in aggregatedData) {
        for (const columnKey in aggregatedData[rowKey]) {
            const entry = aggregatedData[rowKey][columnKey];
            entry.averageScore = entry.totalScore / entry.count;
            entry.companyBenchmarkScore = entry.companyBenchmarkScore;
        }
    }

    // Convert to array format suitable for heatmap or data table
    const result = [];
    for (const rowKey in aggregatedData) {
        const row = { [rowDimension]: rowKey };
        for (const columnKey in aggregatedData[rowKey]) {
            row[columnKey] = aggregatedData[rowKey][columnKey];
        }
        result.push(row);
    }

    // if (Configs.devEnvironment) console.log('[pulseReporting.js][aggregateData()] result:', result);

    return result;
}
// ---------------------------------------------------------------------------------------------------
// Filter methods
// ---------------------------------------------------------------------------------------------------   
const handleFilterChange = (filteredDataInput) => {
  if (Configs.devEnvironment) console.log("[pulseReporting.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
  const aggregatedFilteredData = aggregateData(filteredDataInput, selectedRow, selectedColumn);
  setFilteredData(aggregatedFilteredData);
};

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
    
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="main">

        <PulseHeader />
            
        <div className='flex flex-row items-start'>
        <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

            {/* Categories per manager */}
            <div className="flex flex-col text-left items-start py-2">
              <div className='flex flex-row items-center justify-between'>
                <Tooltip content={t('pulse.reporting.drivers.description')}>
                    <h1 className='section-title'>
                        {t('pulse.reporting.drivers.title')} <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>
                    </h1>
                </Tooltip>
                </div>

                {isPulseReportingDataLoading ?
                    <Loader />
                :
                  <div className='flex flex-col space-y-2 w-full'>
                        {/* Filter & data view options*/}
                        <div className='flex flex-row items-center justify-between'>
                          <FilterWidget data={pulseReportingData} 
                            onFilterChange={handleFilterChange}
                            teamTypeFilter={false} defaultTeamTypeFilter={"company"}
                            managerFilter={false}
                            employeeFilter={true}
                            areaFilter={true} 
                            subareaFilter={true}
                          />

                          <div className='hidden flex flex-row items-center space-x-2'>
                            <div className='flex flex-row items-center space-x-2 text-gray-600 border rounded-lg bg-white hover:bg-gray-200 hover:cursor-pointer px-2 py-1'>
                              <FcHeatMap className='text-xl'/>
                              <span className='text-xs font-medium'>{t('heatmap')}</span> 
                            </div>

                            <div className='flex flex-row items-center space-x-2 text-gray-600 border rounded-lg bg-white hover:bg-gray-200 hover:cursor-pointer px-2 py-1'>
                              <FaTableCells className='text-xl'/>
                              <span className='text-xs font-medium'>{t('table')}</span> 
                            </div>

                          </div>
                        </div>
                        <div className='flex flex-col w-full justify-between'>

                        {/* Heatmap */}
                        <div className='flex flex-col w-full bg-white shadow-xl rounded-lg p-4 mt-4'>
                          {(data?.length > 0) ?
                            <Heatmap data={filteredData} rowDimension={"manager"} columnDimension={"category"} />

                            :
                            <div className='flex flex-col w-full items-center justify-center'>
                              <p className='text-gray-600 text-sm'>{t('pulse.reporting.drivers.empty')}</p>
                            </div>
                          
                          }
                        </div>
                      </div>
                    </div>
                }
            </div>



            {/* Questions */}
            {/* <div className="flex flex-col text-left ">
                <Tooltip content={t('pulse.reporting.questions.tooltip')}>
                    <h1 className='section-title'>
                        {t('pulse.reporting.questions.title')}
                    </h1>
                </Tooltip>

                {isPulseReportingDataLoading ?
                    <Loader />
                :
                    <div className='flex flex-row items-start justify-between'>
                            <PulseFilterWidget 
                            onFilterChange={() => setCurrentPage(1)}

                            />
                    </div>
                }
            
            </div> */}
        </div>
         <div className='right-small-view'>

        </div>

      </div>
      </div> 
    </React.Fragment>
    );
}

export default PulseReporting;