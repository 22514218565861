// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

// Configs
import Configs from '../../Configs';
import Tooltip from '../../common/support/tooltip';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import SpiderPerformance from '../../widgets/spiderPerformance';

// 3P
import { useTranslation } from 'react-i18next';

// Icons
import {CgPerformance} from 'react-icons/cg';
import Loader from '../../common/support/loader';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdOpenInNew } from "react-icons/md";


// ---------------------------------------------------------------
function PerformanceHistory ({companyIDinput, employeeIDinput}) {
    const {companyID, employeeID, token, securityLevel} = useAuth();
    const {selectedProfile, companyPreferences, performanceReviewHistory, isPerformanceReviewHistoryLoading} = useData();

// ---------------------------------------------------------------
// Hooks
// ---------------------------------------------------------------
    const {t} = useTranslation();

// ---------------------------------------------------------------
// useEffect
// ---------------------------------------------------------------
    useEffect(() => {
        // Get the performance data
        // getPersonalPerformanceData(companyID, employeeID, token);
    }, []);

    
// ---------------------------------------------------------------
// Return
// ---------------------------------------------------------------
    return (
        <React.Fragment>
            {companyPreferences?.activeModules["Performance-Review"] === true && !isPerformanceReviewHistoryLoading &&
            (performanceReviewHistory?.length > 0) ?
                <div className='flex flex-col items-start justify-center text-left space-y-2 bg-white shadow  rounded-lg border text-gray-800 text-sm'>
                <h4 className='card-title flex flex-row items-center py-4 px-8'> {"📜 " }
                    {t('widgets.performance-history.title')}
                    <Tooltip content={t('widgets.performance-history.tooltip')} >
                        <AiOutlineInfoCircle className='flex text-gray-500 hover:cursor-pointer ml-2' />  
                    </Tooltip>
                </h4>
                <div className='flex flex-col items-center justify-center text-left p-2 w-full h-full'>
                    {isPerformanceReviewHistoryLoading ? 
                        <div className='pb-12'>
                            <Loader />
                        </div>  
                    :
                    (performanceReviewHistory && performanceReviewHistory?.length > 0) ?
                        <div className='flex flex-col text-left items-start justify-start'>
                            {performanceReviewHistory.map((performance, index) => (
                                <div className='flex flex-row justify-start items-end text-left hover:underline'>
                                    <Link 
                                        className='flex flex-row items-center text-left justify-start text-xs text-gray-800 '
                                        to={`/performance/team/employee/campaign/${companyIDinput ? companyIDinput : companyID}/${performance?.campaign?._id}/${employeeIDinput ? employeeIDinput : employeeID}`} 
                                        target="_blank" rel="noopener noreferrer">
                                        { performance?.campaign?.name}
                                        <MdOpenInNew className='text-gray-500 hover:underline' />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    :
                        <span className='title-description text-gray-400 italic mb-4 px-4'> {t('widgets.performance-history.not-found')} </span>
                    }
                </div>
            </div>
            :
                null
            }
        </React.Fragment>
    );

}

// ---------------------------------------------------------------
// export the component
export default PerformanceHistory;