import { t } from 'i18next';
import React from 'react';
import {BiSad} from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Header from './header';


const NothingThere = () => (
    <React.Fragment>
        {/* <Header/> */}
        <div className='flex flex-col justify-center h-screen pl-32 bg-gradient-to-b from-green-200 to-white'>
            <h2 className='flex flex-row text-4xl font-bold  m-32'>
                <span className='text-green-600 text-xl'>error 404</span>
                <span className='ml-4 mr-4 text-6xl'>|</span>
                <span>{t('error.404')}  </span> 
                <BiSad className='ml-4'/>
            </h2>

            <span className='flex m-32'>
                 <Link to="/" className='underline font-bold italic'> {t('error.404.description')} </Link>
            </span>
            
        </div>
</React.Fragment>
);

export default NothingThere;