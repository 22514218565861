import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Common
import Breadcrumbs from '../support/breadcrumbs';
import HeaderEnd from '../support/headerEnd';
import { useAuth } from '../appContext';
import Configs from '../../Configs';

// ---------------------------------------------------------------
const ModuleHeader = ({ title, Icon, subsections, breadcrumbs, params, livePerformanceCampaign }) => {
  const location = useLocation();
  const [currentScreen, setCurrentScreen] = useState(location.pathname);
  const navigate = useNavigate();
  const { securityLevel } = useAuth(); // Assuming useAuth is a hook to get user data

  // Functions
  function getCurrentPathWithoutLastPart (string) {
    const pathRgx = /\//g;
    const childroutecount = ((string || '').match(pathRgx) || []).length
    if (Configs.devEnvironment) console.log ("[moduleHeader.js][getCurrentPathWithoutLastPart()]: ", childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string)
    return childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string;
}

  const clickedButton = (e) => {
    e.preventDefault();
    navigate(e.target.pathname);
  };

  // ---------------------------------------------------------------------------------
  // Render
  // ---------------------------------------------------------------------------------
  return (
    <React.Fragment>
        <div className='flex flex-col px-8 pt-20 lg:pt-2 justify-between items-center bg-white shadow space-y-4 '>
            {/* Top header */}
            <div className='flex flex-row w-full items-center lg:pt-2  text-left justify-between '> 
                <h1 className='flex flex-row items-center text-left text-sm font-medium '> 
                    <Icon className='flex mr-4 text-xl'/>
                    {title}
                </h1>

                <HeaderEnd />                    

            </div>

            {/* Sections */}
            <div className='flex flex-row w-full text-sm font-light items-start lg:justify-start justify-center space-x-4 overflow-x-auto'>    
                {subsections.map((element, index) => (
                <React.Fragment key={index}>
                    {// Check if security level array contains any of the elements in element.securityLevel array
                    securityLevel?.some(r => element.securityLevel?.includes(r)) ?
                        <Link id={element.id} to={element.url} className={' py-2 flex px-2 ' + (
                          (currentScreen === element.url) ||
                          // Performance Review
                          (getCurrentPathWithoutLastPart(currentScreen) === element.url && element.url === "/performance/self/") ||
                          (currentScreen === "/performance/" && element.url === "/performance/self/") || 
                          (getCurrentPathWithoutLastPart(currentScreen) === element.url+"employee/") ||
                          (params && element.url==="/performance/team/")
                          // 1:1s
                          // Pending
                            ?
                            ' border-b border-b-green-200 border-b-4 text-gray-800 font-medium '
                            : ' hover:bg-green-200 hover:rounded '
                        )}
                            onClick={(e) => clickedButton(e)}>
                            {element.view}
                        </Link>
                    : 
                        null
                    }
                </React.Fragment>
                ))}
            </div>
      </div>
      {/* Breadcrumbs */}
      {breadcrumbs && <Breadcrumbs />}
      
    </React.Fragment>
  );
};

ModuleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  subsections: PropTypes.arrayOf(
    PropTypes.shape({
      view: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      securityLevel: PropTypes.arrayOf(PropTypes.number).isRequired,
      id: PropTypes.string
    })
  ).isRequired,
  breadcrumbs: PropTypes.bool
};

ModuleHeader.defaultProps = {
  breadcrumbs: true,
  params: null,
  livePerformanceCampaign: false,
};

export default ModuleHeader;
