// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// import Select from 'react-select';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import OneOnOneHeader from './oneOnOneHeader.js';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import Forbidden from '../../common/forbidden';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Widgets
import UserListItem from '../../widgets/userListItem';
// import CollapsibleMeetingItem from '../../widgets/collapsibleMeetingItem';
import FilterWidget from '../../widgets/data/filterWidget.js';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip.js';

// React icons
import { IoIosHelpCircleOutline } from "react-icons/io";

// -------------------------------------------------------------------------------------------
function OneOnOne () {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {token, employeeID, companyID, securityLevel} = useAuth();
    const {profile, teamDirects, teamOrg, companyOrg, isDirectsLoading, isOrgLoading, isCompanyLoading, setSelectedProfile} = useData();

    const oneOnOneHistoryAux = sessionStorage.getItem('oneOnOneHistory') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('oneOnOneHistory'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    
    const [oneOnOneHistory, setOneOnOneHistory] = useState(oneOnOneHistoryAux);

    // Toaster & loader
    const [toasterMessage, setToasterMessage] = useState(null);
    const [is1on1HistoryLoading, setIs1on1HistoryLoading] = useState(false);

    // Team filtering
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filteredManager, setFilteredManager] = useState(null);
    const [managersOptions, setManagersOptions] = useState([]);
    const [filteredManagerTeam, setFilteredManagerTeam] = useState([null]);
    const [filteredData, setFilteredData] = useState([]);

    if (Configs.devEnvironment) console.log("[OneOnOne.js][main()] Team Directs: ", teamDirects);
    if (Configs.devEnvironment) console.log("[OneOnOne.js][main()] Context: ", securityLevel, profile, teamOrg, companyOrg);


  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------      
  useEffect (() => {

      // If IC, navigate to your space directly
      if (securityLevel?.length > 0) {
        if (!securityLevel?.includes(Configs.securityLevelManager)
          && !securityLevel?.includes(Configs.securityLevelHR)
          && !securityLevel?.includes(Configs.securityLevelExec)
          && !securityLevel?.includes(Configs.securityLevelAdmin)) {
          const auxString = "space/" +
          encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
          employeeName:profile?.employeeInfo?.name + " " + profile?.employeeInfo?.surname,
          employeeN:profile?.employeeInfo?.name,
          employeeSurname: profile?.employeeInfo?.surname,
          employeeLevel: profile?.employeeInfo?.level,
          employeeRole: profile?.employeeInfo?.role,
          employeeArea: profile?.employeeInfo?.area,
          employeeSubarea: profile?.employeeInfo?.subarea,
          employeeID: profile?.employeeInfo?.employeeID,
          companyID: profile?.employeeInfo?.companyID,
          managerID: profile?.employeeInfo?.managerID,
          }),Configs.privateKey).toString());
          
          navigate(auxString);
        }
      }

      if (teamDirects) {
        setSelectedFilter("directs");
        setFilteredData(teamDirects);
      //  populateManagersOptions(teamDirects);
      }
  
      if (teamOrg) populateManagersOptions();
          
      // Get information to populate dashboard
      // get1on1History();
      }, [profile, securityLevel, teamDirects, teamOrg, companyOrg]);
  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------    
    // Get 1on1 History
    async function get1on1History () {
      setIs1on1HistoryLoading(true);

        // fetch from server      
        fetch(Configs.platformGetOneOnOneHistoryManagerAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          }
        })
            .then((response) => {
              setIs1on1HistoryLoading(false);

              // console.log (response);
              if (response.status === 200) {
                // Username and password mathched in the database - get Token and save it in the session
                response.json()
                .then ( body => {
                  if (body.length > 0) {
                    sessionStorage.setItem('oneOnOneHistory', CryptoJS.AES.encrypt(JSON.stringify(body),Configs.privateKey).toString());
                    setOneOnOneHistory(body);
                  }

                  // if (Configs.devEnvironment) console.log("Profile from getProfile(): ", body);
                  })
                .catch(error => {
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
                  console.error("[OneOnOne.js][get1on1History()] Error parsing response JSON", error)});
              } else {
                //
                setToasterMessage({message: t('error'), type: Configs.errorToaster});

                throw response;
              }
            })
      
            .catch((error) => {
              setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
              setIs1on1HistoryLoading(false);
              console.error("[OneOnOne.js][getMyDirectTeam()] Error fetching data: ", error);
            });
    }

    async function deleteMeeting (meetingID) {
      // fetch from server only if we have meetingID
        // HTTP POST request options
          const requestOptions = {
              method: 'DELETE',
              credentials: 'include',
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
              body: JSON.stringify({ 
                companyID: companyID,
                employeeID: employeeID,
                meetingID: meetingID,
              })
          };
  
    // Post to server      
    fetch(Configs.platformDeleteMeetingAPI, requestOptions)
      .then((response) => {
        // console.log (response);
        if (response.status === 200) {
          // All good
          // navigate('/oneonone/');
          // sessionStorage.removeItem('performanceReviewDraft');
          if (Configs.devEnvironment) console.log("[oneOnOnePersonal.js][deleteMeeting()] - Meeting deleted successfully");
            get1on1History();
            setToasterMessage({message: t('success'), type: Configs.successToaster});
  
        } else if (response.status === 403) {
            // Inform that something went wrong
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
  
        } else if (response.status === 401) {
          // Force logout - forbidden
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
  
            sessionStorage.clear();
            window.location.reload();
        } else {
          // There is an error - delete info
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }
      })
  
      .catch((error) => {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
        console.log("[oneOnOnePersonal.js][saveMeeting()] Error fetching data: ", error);
      });
  
  }

  // ---------------------------------------------------------------------------------------------------
    // Frontend control methods
    // ---------------------------------------------------------------------------------------------------
    async function populateManagersOptions (body) {
      if(body !== null && body !== undefined && body?.length > 0) {
        var auxArray = [];
  
        body?.forEach(manager => {
          if (manager?.directs > 0) {
            auxArray.push({value: manager?.employeeID, label: manager?.name + " " + manager?.surname});
          }
          
        });
  
        setManagersOptions([...auxArray]);
      } else {
        var auxArray = [];
  
        teamOrg?.forEach(manager => {
          if (manager?.directs > 0) {
            auxArray.push({value: manager?.employeeID, label: manager?.name + " " + manager?.surname});
          }
        });
  
        setManagersOptions([...auxArray]);
      }
    }
      
    // ---------------------------------------------------------------------------------------------------
    // Navigation
    // ---------------------------------------------------------------------------------------------------
    async function goToProfile (employeeIDaux) {

      const employeeAux = companyOrg.find( (element) => element?.employeeID === employeeIDaux);

      if (employeeIDaux === employeeID) {
          setSelectedProfile(null);
      } else {
          setSelectedProfile(employeeAux);
      }
      
      navigate("space/" +
      encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
        employee:employeeAux?.employee,
        employeeName: employeeAux?.name,
        employeeSurname: employeeAux?.surname,
        employeeLevel: employeeAux?.level,
        employeeRole: employeeAux?.role,
        employeeArea: employeeAux?.area,
        employeeSubarea: employeeAux?.subarea,
        employeeID: employeeAux?.employeeID,
        employeeProfilePicture: employeeAux?.profilePicture,
        companyID: employeeAux?.companyID,
        managerID: employeeAux?.managerID 
      }),Configs.privateKey).toString()));
    }

    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
      };
    
    // ---------------------------------------------------------------------------------------------------
    // Filter methods
    // ---------------------------------------------------------------------------------------------------   
    const handleFilterChange = (filteredDataInput) => {
      if (Configs.devEnvironment) console.log("[oneOnOne.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
      setFilteredData(filteredDataInput);
    };


    // ---------------------------------------------------------------------------------------------------
    // Render methods
    // ---------------------------------------------------------------------------------------------------       
    return (
      <React.Fragment>
      <Header />
      < Bot/>

      <div className="main">

        <OneOnOneHeader />


      {/* Roles check */}
      {securityLevel?.includes(Configs.securityLevelManager)
        || securityLevel?.includes(Configs.securityLevelHR)
        || securityLevel?.includes(Configs.securityLevelExec)
        || securityLevel?.includes(Configs.securityLevelAdmin)
        ?
   
          <div className='flex flex-row items-start'>
          <div className="left-big-view">
              {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                      <h2 className="flex text-xl text-left mb-4 ">
                        <div className='flex flex-col md:flex-row justify-left'>
                            <div className='flex flex-row items-center'>
                            <Tooltip content={t('oneOnOne.home.description')}>
                              🗒️ <span className='section-title'>{t('oneOnOne.home.title-manager')}</span> 
                            </Tooltip>
                            {i18next.language === 'es' ? 
                              <a href="https://resources.kincode.app/es/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                              </a>
                            : 
                              <a href="https://resources.kincode.app/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                              </a>
                            }
                            </div>
                        </div>
                      </h2>                        
                  </div>

                  
                   
                    <React.Fragment>
                        <div className='flex flex-col'>
                          {isDirectsLoading || isOrgLoading || isCompanyLoading ? 
                              <Loader />
                            :
                          
                            teamDirects || teamOrg || companyOrg ? 
                                <div className='flex flex-col items-left'>
                                  {/* Filters widget */}
                                  <div className=''>
                                      <FilterWidget data={(securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) ? companyOrg : teamOrg} 
                                      onFilterChange={handleFilterChange}
                                      teamTypeFilter={true} defaultTeamTypeFilter={teamDirects && teamDirects?.length > 0 ? "team" : "company"}
                                      managerFilter={true}
                                      employeeFilter={true}
                                      areaFilter={true} 
                                      subareaFilter={true}
                                      />
                                  </div>
                                  {/* Filtered data */}
                                  <div className=''>
                                    {filteredData?.map((employee, index) => (
                                                      <button className='flex flex-row w-full hover:bg-gray-200 rounded-lg' key={index}
                                                      id={"direct_"+index} onClick={() => {goToProfile(employee?.employeeID)}}
                                                      >

                                                          <UserListItem user={employee?.name + " " + employee?.surname} 
                                                          initials={employee?.name.slice(0,1)+employee?.surname.slice(0,1)} 
                                                          role={employee?.role} level={employee?.level}
                                                          profilePicture = {employee?.profilePicture} />
                                                      </button>

                                                    ))
                                              }
                                  </div>
                                </div>
                              :
                                <div >
                                  No team data
                                </div>
                          }
                        </div>
                      </React.Fragment>

              <SectionSeparator />

            </div>
            <div className='right-small-view'>
                  {/* Test */}
            </div>      
            </div>

          : 
              <Forbidden />
            }
        </div>
      </React.Fragment>
    );
}

export default OneOnOne;