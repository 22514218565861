// React
import React, {useState, useEffect} from "react";

// Configs & Widgets 
import Configs from '../Configs';
import Tooltip from '../common/support/tooltip';
import { useData } from "../common/dataContext";

// 3P
import {XYPlot, XAxis, YAxis, HorizontalGridLines, DiscreteColorLegend, AreaSeries,  LineMarkSeries, LabelSeries, Hint} from 'react-vis';
import '../../node_modules/react-vis/dist/style.css';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";

// Icons
import {AiOutlineInfoCircle} from 'react-icons/ai';
import NoneditableEditor from './noneditableEditor';

/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function KeyIndicatorItem(props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t} = useTranslation();

// Props
// const [collapsed, setCollapsed] = useState (props.collapsed);
const indicator = props?.indicator;
const data = props?.data;
const question = props?.question;

// console.log(question);

const [graphData, setGraphData] = useState ([]);
const [graphBenchmarkData, setGraphBenchmarkData] = useState ([]);
const [xAxisValues, setxAxisValues] = useState ([]);
const [labelsSeries, setLabelsSeries] = useState ([]);
const [showHint, setShowHint] = useState(false);

// Company personalization
const {companyPrefereneces} = useData();

// Line colors
// const scoreColor = companyPrefereneces?.personalization?.emailsHeaderColor ? companyPrefereneces?.personalization?.emailsHeaderColor : Configs.companyColor;
// const benchmarkColor = companyPrefereneces?.personalization?.emailsHeaderSecondaryColor ? companyPrefereneces?.personalization?.emailsHeaderSecondaryColor : Configs.companyTerciaryColor;
const scoreColor = "#4F46E5";
const benchmarkColor = "#FFA500";

// Label element offset
const labelOffsetX = 6;
const labelOffsetY = 30;
const fontSize = 12;
const fontBold = 600;
const maxData = 5;

if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][main()] Props: ", data);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate graphs
        if (data?.length) graphDataFromArray([...data]);
}, [data]);

// ------------------------------------------------------------------------------------------
// Frontend support methods
// ------------------------------------------------------------------------------------------
async function graphDataFromArray (inputData) {
    if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Input data: ", inputData);

    if (!inputData) return;

    const inputDataAux = inputData?.slice(0, maxData);

    var dataAux = [];
    var benchmarkDatAux = [];
    var xAxisAux = [];
    var labelsSeriesAux= [];

    if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Input data for graph: ", inputData);

 
    // For each input data, update Aux
    inputDataAux?.forEach((answer, index) => {
      // Calculating where to place it in the X graph axis
      var xAux = 0;
      if (inputDataAux?.length <= 1) { 
        xAux = 0
      } else if (inputDataAux.length === 2) {
        xAux = maxData - index - 1;
      } else {
        xAux = maxData - index; 
      }
      
      // Create data object
      var objectAux = {
        x: xAux,
        date: answer?.questionMonth + "/" + answer?.questionYear,
        y: answer?.answerScore ? Number(answer?.answerScore).toFixed(1) : 0,
      };

      // Create benchmark data object
      var benchmarkObjectAux = {
        x: xAux,
        label: answer?.questionMonth + "/" + answer?.questionYear,
        y: answer?.companyBenchmarkScore ? Number(answer?.companyBenchmarkScore).toFixed(1) : 0
      };

      // Object for labels
      var labelObjectAux = {
        x: xAux,
        date: answer?.questionMonth + "/" + answer?.questionYear,
        y: answer?.answerScore ? Number(answer?.answerScore).toFixed(1) : 0,
        label: answer?.answerScore ? Number(answer?.answerScore).toFixed(1) : 0,
        xOffset: labelOffsetX,
        yOffset: (answer?.answerScore === 0 ? -10 : labelOffsetY),
        style: {fontSize: fontSize, fontWeight: fontBold},
      }

      // push to Arrays
      dataAux.push(objectAux);
      benchmarkDatAux.push(benchmarkObjectAux);
      xAxisAux.push(objectAux.date);
      labelsSeriesAux.push(labelObjectAux);
    });
    
    if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Graph data: ", dataAux);
    if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Benchmark: ", benchmarkDatAux);
    if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][graphDataFromArray()] Axis data: ", xAxisAux);
  
    // Retun data
    setGraphData([...dataAux]);
    setGraphBenchmarkData([...benchmarkDatAux]);
    setLabelsSeries([...labelsSeriesAux]);
    // Reverse axis data to fit the graph
    setxAxisValues([...xAxisAux.reverse()]);

  }
  

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    
    <div className='flex flex-col border p-4 items-center justify-between space-y-1 shadow-xl rounded-lg bg-white'> 
          
          {/* KPI title */}
          <div className="flex flex-row items-center justify-between w-full">
                  <h3 className="flex text-center items-center mb-4">
                    <span className="font-bold text-sm">{indicator}</span>

                      <Tooltip 
                      content={t('pulse.home.key-indicator.question-info') +
                       " " + (i18next.language === 'es' ? question?.question_ESP : (question.question ? question.question : question.question_ESP))} >
                        <div className=' ml-2 text-xs '>
                            <AiOutlineInfoCircle />
                        </div>
                      </Tooltip>
                    </h3>

                  <div className="flex flex-col items-end">
                          <div className="flex flex-row items-end">
                              <span className='text-3xl font-black'>{Number(data[0]?.answerScore).toFixed(1)}</span>

                              {Math.abs(data[0]?.answerScore - data[1]?.answerScore) >= 0 ?
                                <div className="">
                                      {(data[0]?.answerScore - data[1]?.answerScore > 0) ? 
                                            // Positive MoM
                                            Number(data[0]?.answerScore/data[1]?.answerScore - 1) === Infinity ?
                                            // If it is infinite, then do not show improvement
                                            <span className="text-sm text-right text-green-600 ml-2 ">n.a.</span>
                                            :
                                            <span className="text-sm text-right text-green-600 ml-2 ">+{Number((data[0]?.answerScore/data[1]?.answerScore - 1) * 100).toFixed(1)}% ⬆</span>
                                            
                                      :
                                        (data[0]?.answerScore - data[1]?.answerScore === 0) ?
                                            // No change
                                            <span className="text-sm text-right text-gray-600 ml-2">0.0%</span>

                                      :
                                            // Negative MoM
                                            <span className="text-sm text-right text-red-600 ml-2">{Number((data[0]?.answerScore/data[1]?.answerScore - 1) * 100).toFixed(1)}% ⬇</span>
                                      }
                                </div>
                                :
                                null
                              }
                                
                          </div>

                        
                  </div>

                  
                  
          </div>

          {/* Company Benchmark */}
          {data[0]?.companyBenchmarkScore ? 
            <div className="flex flex-row items-end w-full justify-end">
                <span className='text-xs text-gray-600'>
                  {t('company-benchmark')}: {Number(data[0]?.companyBenchmarkScore).toFixed(1) + " (" + ((data[0]?.companyBenchmarkScore/data[1]?.companyBenchmarkScore - 1) >= 0 ? "+" : "") + Number((data[0]?.companyBenchmarkScore/data[1]?.companyBenchmarkScore - 1) * 100).toFixed(1) + "%)"} 
                </span>
            </div>

            :
              null 
            }


          <div className="flex flex-row items-center">
                    <div className='flex flex-col rounded items-center m-4 text-sm m-1 w-full'>
                          <div className='flex flex-col lg:flex-row overflow-x-auto'>
                            <XYPlot 
                              height={150} 
                              width={300}
                              margin={{left: 20, right:20, top:25,bottom:25}}
                              xDomain={[1, maxData]}
                              yDomain={[0, 5]}
                              >
                              

                            <XAxis tickFormat={index => xAxisValues[index-1]} />
                            
                            <HorizontalGridLines tickValues={[1,2,3,4,5]} />

                                <AreaSeries
                                        className=""
                                        stroke={benchmarkColor}
                                        opacity={0.1}
                                        curve="curveNatural"
                                        data={graphBenchmarkData}
                                        fill={benchmarkColor}
                                      />
                                      <AreaSeries
                                        className=""
                                        stroke={scoreColor}
                                        opacity={0.1}
                                        curve="curveNatural"
                                        data={graphData}
                                        fill={scoreColor}
                                      />
                                <LineMarkSeries
                                                className="mark-series-example"
                                                curve={'curveNatural'}
                                                strokeWidth={3}
                                                opacity={0.9}
                                                color = {benchmarkColor}
                                                data={graphBenchmarkData}

                                                onValueClick={(datapoint, event)=>{
                                                    // does something on click
                                                    // you can access the value of the event
                                                    // console.log(datapoint, event)
                                                  }}
                                                onValueMouseOver={(datapoint, event)=>{
                                                // does something on click
                                                // you can access the value of the event
                                                    setShowHint(datapoint);

                                                }}
                                                onValueMouseOut={() => setShowHint(false)}
                                                />
                                  <LineMarkSeries
                                              className="mark-series-example"
                                              curve={'curveNatural'}
                                              strokeWidth={2}
                                              opacity={1}
                                              color = {scoreColor}
                                              data={graphData}

                                              onValueMouseOver={(datapoint, event)=>{
                                              // does something on click
                                              // you can access the value of the event
                                                  setShowHint(datapoint);

                                              }}
                                              onValueMouseOut={() => setShowHint(false)}
                                              />
                                
                                    {/* <LabelSeries 
                                      allowOffsetToBeReversed
                                      animated
                                      data={labelsSeries} 
                                      getLabel={d => d.label}
                                      /> */}
                                      
                                    

                                    {showHint ? <Hint className='rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50' value={showHint} /> : null}


                            </XYPlot>

                          </div>


                        </div>
            </div>
        </div>

  );
}

export default KeyIndicatorItem;

