// React
import React, { useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

// Frameworks
import Configs from '../../Configs';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Headers
import OrganigramHeader from './organigramHeader';
import Header from '../../common/header';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import FileInput from '../../widgets/files/fileInput';
import FilterWidget from '../../widgets/data/filterWidget';
import DataTable from '../../widgets/dataTable';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineInfoCircle, AiOutlineDownload} from 'react-icons/ai';
// import PersonalOnboarding from '../../widgets/personal/personalOnboarding';
import PersonalTimeOff from '../../widgets/personal/personalTimeOff';
import { BsLadder, BsPersonBadgeFill } from 'react-icons/bs';
import { GiOrganigram } from 'react-icons/gi';


function OrganigramEmployeesCreate () {
    // Params for edition
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    const navigate = useNavigate();
    const {t} = useTranslation();

    const {token, securityLevel, employeeID, companyID} = useAuth();
    const {companyOrg, getMyDirectTeam, getMyCompanyOrg, getMyTeamOrg, getOrganigramData, companyPreferences, toasterMessage, setToasterMessage} = useData();

    const {isReorganizationHistoryLoading, reorganizationHistoryData} = useData();

    // Toaster & Loader
    const [modifiedData, setModifiedData] = useState(null);
    const [reorgFileID, setReorgFileID] = useState(null);
    const [responseData, setResponseData] = useState(null);

    // Columns
    const responseColumns = [
    {type:'string',id: 'employeeID', value: 'employeeID', label: t('ID'), sort: null, filter: null},
    {type:'string',id: 'employee', value: 'employee', label: t('employee'), sort: null, filter: null},
    {type:'string',id: 'result', value: 'result', label: t('result'), sort: null, filter: null},
    {type:'string',id: 'message', value: 'message', label: t('comment'), sort: null, filter: null},
  
    // Actions
    // {type: 'action', id: 'action', value: 'action', label: t('action'), sort: null, filter: null}
  ];


    // Loaders
    const [isCSVLoading, setIsCSVLoading] = useState(false);
    const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);

// ---------------------------------------------------------------------------------------------------
// Methods to load when page is loaded
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // 

      }, []);      



// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------
async function downloadOrganigramCSV () {
    try {
        // Set loading state
        setIsCSVLoading(true);

        const response = await apiCalls.getOrganigramCSV(companyID, employeeID, token);
        if (Configs.devEnvironment) console.log ('[organigramReorganize.js][downloadOrganigramCSV] response:', response?.status);
        
        // Resolve loading state
        setIsCSVLoading(false);

        if (!parseInt(response.status) === 200) {
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
        }
    } catch (error) {
        setToasterMessage({message: t('error'), type: Configs.errorToaster});
    }
}

async function submitReOrg () {
    try {
        // Set loading state
        setIsSubmissionLoading(true);

        const response = await apiCalls.postOrganigramReorganization(companyID, employeeID, token, reorgFileID);
        if (Configs.devEnvironment) console.log ('[organigramReorganize.js][submitReOrg] response:', response);
        
        // Resolve loading state
        setIsSubmissionLoading(false);

        if (parseInt(response.status) === 200 || response) {
            setToasterMessage({message: t('organigram.reorg.submit.success'), type: Configs.successToaster});

            // Process and show response
            processSubmission(response);

            // Clean-up
            setReorgFileID(null);

            // update the organigram
            getMyCompanyOrg();
            getMyDirectTeam();
            getMyTeamOrg();
            getOrganigramData();

        } else {
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
        }

    } catch (error) {
        console.error('[organigramReorganize.js][submitReOrg] error:', error);
        setToasterMessage({message: t('error'), type: Configs.errorToaster});
    }
}


// ---------------------------------------------------------------------------------------------------
// Process the response
// ---------------------------------------------------------------------------------------------------
async function processSubmission (response) {
    if (Configs.devEnvironment) console.log("[organigramReorganize.js][processSubmission()] - START");

    response?.addedRecords?.forEach(record => {
        const employeeInfo = companyOrg?.find(employee => parseInt(employee?.employeeID) === parseInt(record?.employeeID));

        if (employeeInfo) {
            record.name = employeeInfo?.name;
            record.surname = employeeInfo?.surname;
            record.employee = employeeInfo?.name + " " + employeeInfo?.surname;
            record.result = "✅";

            // Check if there was an error - managerID or emloyeeID
            response?.employeeIDErrors?.forEach(error => {
                if (parseInt(error?.employeeID) === parseInt(record?.employeeID)) {
                    record.result = "❌";
                    record.message = (record.message || '') + "employeeID duplicated in file";
                }
            });

            response?.managerIDErrors?.forEach(error => {
                if (parseInt(error?.employeeID) === parseInt(record?.employeeID)) {
                    record.result = "❌";
                    record.message = (record.message || '') + "ManagerID error";
                }
            });
        } else {
            record.employee = record?.employeeID;
            record.result = "✅";
        }
    });

    // Sort by employeeID
    response?.addedRecords?.sort((a, b) => (parseInt(a.employeeID) > parseInt(b.employeeID)) ? 1 : -1);

    // Set state
    setResponseData(response?.addedRecords);


}

// ---------------------------------------------------------------------------------------------------
// Form management methods
// ---------------------------------------------------------------------------------------------------  
async function cancel () {
    // Go back to previous page
    navigate(-1);
}

async function validateInputs () {
    if (Configs.devEnvironment) console.log("[organigramReorganize.js][validateInputs()] - START");

    // Check if the file was updated
    if (!reorgFileID) {
        setToasterMessage({message: t('organigram.reorg.submit.no-file'), type: Configs.warningToaster});
        return;
    }

    // if there is a fileID, we can submit the reorganization
    submitReOrg();
    
}

// ---------------------------------------------------------------------------------------------------
// Filter management methods
// ---------------------------------------------------------------------------------------------------
const handleFilterChange = (filteredDataInput) => {
    if (Configs.devEnvironment) console.log("[organigramReorganize.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
    setModifiedData(filteredDataInput);
  };

// ---------------------------------------------------------------------------------------------------
// File input
// ---------------------------------------------------------------------------------------------------
const handleFileSelect = (fileId, index) => {
    if (Configs.devEnvironment) console.log('[organigramReorganize.js][handleFileSelect()] Selected file:', fileId, index);

    // Update request
    setReorgFileID(fileId);

    // Recalculate the data - # of records, etc. Allow submission

};


// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
};

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
return (
      <React.Fragment>
      <Header />

      <Bot/>

      <div className="main">

        <OrganigramHeader />

        <div className='flex flex-row items-start'>
              
            <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
            
            <div className="flex flex-col mt-2 md:ml-2 m-1">
                    <div className='flex flex-col'>
                        <h2 className="flex text-xl mb-4 ">
                            <div className='flex flex-col items-start md:flex-row justify-left'>
                               
                                    <Tooltip content={t('organigram.reorg.description')} >
                                        <div id="title" className='section-title'>
                                            {t('organigram.reorg.title')}
                                        </div>
                                    </Tooltip>
                                
                            </div>
                        </h2>
                    </div>
                </div>
                       
                <React.Fragment>
                    
                    <div className="flex flex-col mt-2 lg:ml-2 m-1 space-y-8 w-full text-xs">

                        {/* 1. Filter specifics */}
                        <div className='hidden flex flex-col items-start text-left space-y-2'>
                            <Tooltip content={t('organigram.reorg.filter.description')} >
                                <div className='text-xl font-medium mb-4'> 🔍 {t('organigram.reorg.filter.title')} </div>
                            </Tooltip>

                            <FilterWidget data={companyOrg} onFilterChange={handleFilterChange}
                                teamTypeFilter={false} defaultTeamTypeFilter={"company"}
                                managerFilter={true}
                                areaFilter={true} 
                                subareaFilter={true}
                            />
                        </div>


                        {/* 2. Download current state */}
                        <div className='flex flex-col items-start text-left space-y-2'>
                            <Tooltip content={t('organigram.reorg.download.description')} >
                                <div className='text-xl font-medium mb-4'> 💾 {t('organigram.reorg.download.title')} </div>
                            </Tooltip>
                                                
                            {isCSVLoading ? 
                                <div className='flex flex-row items-center space-x-2'>
                                    <Loader />
                                </div>  
                            :   
                                <button className='save-button flex flex-row items-center space-x-2' onClick={downloadOrganigramCSV}>
                                    <AiOutlineDownload className='text-xl mr-2'/> {t('download')} 
                                </button>
                            }

                        </div>

                        {/* 3. Submit/upload the updated file - We have to confirm the user if it was successful, and indicate number of resources, or similar */}
                        
                        <div className='flex flex-col items-start text-left space-y-2'>
                            <Tooltip content={t('organigram.reorg.download.description')} >
                                <div className='text-xl font-medium mb-4'> ✅ {t('organigram.reorg.submit.title')} </div>
                            </Tooltip>
                            {isSubmissionLoading ? 
                                <div className='flex flex-row items-center space-x-2'>
                                    <Loader />
                                </div>
                            :                        
                                <FileInput 
                                    onFileUpload={handleFileSelect}                                                        
                                    maxFileSize={Configs.maxFileSize} 
                                    allowedTypes={Configs.allowedTypesCSV}
                                    index={0}
                                    fileType='reorganization'
                                />        
                            }

                        </div>

                        {/* 3. Submit/upload the updated file - We have to confirm the user if it was successful, and indicate number of resources, or similar */}
                        
                        {responseData && <div className='flex flex-col items-start text-left space-y-2'>
                            <Tooltip content={t('organigram.reorg.response.description')} >
                                <div className='text-xl font-medium mb-4'> 📊 {t('organigram.reorg.response.title')} </div>
                            </Tooltip>
                            {isSubmissionLoading ? 
                                <Loader />
                            :                        
                                <DataTable 
                                    data={responseData} 
                                    columnNames={responseColumns}
                                    defaultPageLimit={20}
                                />
                            }

                        </div>}


                                
                    </div>

                    <SectionSeparator />


                </React.Fragment>
                        
        
                    {securityLevel?.includes(Configs.securityLevelHR) ? 
                        <div className='flex flex-col lg:flex-row items-center justify-end lg:space-x-4 '>
                            <button type="button" id="cancelButton" class="secondary-button" onClick={cancel}> {t('cancel')} </button>

                            {!reorgFileID || isSubmissionLoading ? 
                                <Tooltip content={t('organigram.reorg.submit.disabled')} >
                                    <button type="button" id="submitButton" disabled={true} className="save-button"> {t('submit')} </button>
                                </Tooltip>
                            :
                                <button type="button" id="submitButton" disabled={false} className="save-button" onClick={validateInputs}> {t('submit')} </button>
                            }
                        </div>
                    : 
                        null 
                    }
                
                    <SectionSeparator />
            
            </div>
            <div className='right-small-view'>

                {/* Re.org history - HIDDEN for now */}
                <div className='hidden flex flex-col items-start justify-center text-left space-y-2 bg-white shadow  rounded-lg border text-gray-800 text-sm'>
                    {/* Title */}
                    <h4 className='card-title flex flex-row items-center py-4 px-8'>
                        <BsPersonBadgeFill className='mr-2' /> {t('organigram.reorg.history')}
                    </h4>
                    <div className='flex flex-col items-center justify-center text-left p-2 w-full h-full'>
                    {isReorganizationHistoryLoading ? 
                        <div className='pb-12'>
                            <Loader />
                        </div>  
                    :
                    reorganizationHistoryData ?
                        <div className='flex flex-col items-center justify-center text-left p-2 w-full h-full'>
                                Test
                        </div>
                    :
                        <span className='title-description text-gray-400 italic mb-4 px-4'> {t('organigram.reorg.history.not-found')} </span>
                    }
                </div>

                </div>



            </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default OrganigramEmployeesCreate;