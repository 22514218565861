// React
import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Header
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';

// Common and widgets
import UserListItem from '../../widgets/userListItem';
import Bot from '../../common/support/bot';
import Configs from '../../Configs';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext.js';
import FilterWidget from '../../widgets/data/filterWidget.js';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// ---------------------------------------------------------------------------------------------------
function PerformanceTeam () {
    // To pass location to the Header
    const location = useLocation();
    const navigate = useNavigate();
    const currentScreen = location.pathname;

    const [performanceReviewCampaign, setPerformanceReviewCampaign] = useState(sessionStorage.getItem('performanceCampaigns') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('performanceCampaigns'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null);
    const [livePerformanceCampaign, setLivePerformanceCampaign] = useState(sessionStorage.getItem('livePerformanceCampaign') ? CryptoJS.AES.decrypt(sessionStorage.getItem('livePerformanceCampaign'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : false);

    const {token, companyID, employeeID, securityLevel} = useAuth();
    const {teamDirects, isDirectsLoading, companyOrg, isCompanyLoading, teamOrg, isOrgLoading} = useData();
    const { setSelectedProfile } = useData();
    const {toasterMessage, setToasterMessage} = useData();

    // Team filtering
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredManager, setFilteredManager] = useState(null);
    const [managersOptions, setManagersOptions] = useState([]);
    const [filteredManagerTeam, setFilteredManagerTeam] = useState([null]);

  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------    

    // Methods to load when page is loaded
    useEffect (() => {
        // Get information to populate dashboard
        if (teamDirects) {
          setSelectedFilter("directs");
          setFilteredData(teamDirects);
        }
        if (teamOrg)populateManagersOptions();

        if (!securityLevel?.includes(Configs.securityLevelManager) && !securityLevel?.includes(Configs.securityLevelExec) && !securityLevel?.includes(Configs.securityLevelHR)) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
          navigate("/performance/self");
        }

      }, [companyOrg, teamDirects, teamOrg]);

    
    // ---------------------------------------------------------------------------------------------------
    // Frontend control methods
    // ---------------------------------------------------------------------------------------------------
    async function populateManagersOptions (body) {
      if(body) {
        var auxArray = [];
  
        body?.forEach(manager => {
          if (manager?.directs > 0) {
            auxArray.push({value: manager?.employeeID, label: manager?.name + " " + manager?.surname});
          }
        });
  
        setManagersOptions([...auxArray]);
      } else {
        var auxArray = [];
  
        teamOrg?.forEach(manager => {
          if (manager?.directs > 0) {
            auxArray.push({value: manager?.employeeID, label: manager?.name + " " + manager?.surname});
          }
        });
  
        setManagersOptions([...auxArray]);
      }
    }


   // ---------------------------------------------------------------------------------------------------
    // Navigation methods
    // ---------------------------------------------------------------------------------------------------
    async function goToProfile (employeeIDaux) {

      const employeeAux = companyOrg.find( (element) => element?.employeeID === employeeIDaux);

      if (employeeIDaux === employeeID) {
          setSelectedProfile(null);

      } else {
          setSelectedProfile(employeeAux);

      }
      navigate("/performance/team/employee/" +
      encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
        employeeID: employeeAux?.employeeID,
        employee: employeeAux?.name + " " + employeeAux?.surname,
        profilePicture: employeeAux?.profilePicture,
        companyID: companyID,
        managerID: employeeAux?.managerID
        }),Configs.privateKey).toString()));
    }

  // ---------------------------------------------------------------------------------------------------
  // Filter methods
  // ---------------------------------------------------------------------------------------------------   
  const handleFilterChange = (filteredDataInput) => {
    if (Configs.devEnvironment) console.log("[performanceTeam.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
    setFilteredData([...filteredDataInput]);
  };

  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------
  const closeToast = () => {
    setToasterMessage(null);
  };

   // ---------------------------------------------------------------------------------------------------
   // Render methods
   // ---------------------------------------------------------------------------------------------------
  
        return (
          

          <React.Fragment>
          <Header/>
          <Bot />

          <div className="main">

          <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen}/>
          
          <div className="flex flex-col m-2 lg:m-4 text-left ">

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

            <div className="flex flex-col text-left ">
              <div className="flex flex-col items-start text-left mt-2 md:ml-2 m-1">
                <Tooltip content={t('performance.team.description')}>
                <h2 className="section-title">
                  🫂 {t('performance.team.title')}
                </h2>
                </Tooltip>
              </div>

              {securityLevel?.includes(Configs.securityLevelManager) ? 
                    <React.Fragment>

                        <div className='flex flex-col'>

                          {teamDirects ? 

                            <div className='flex flex-col'>

                              {/* Filters */}
                              { isOrgLoading || isDirectsLoading ? 
                                  <Loader />
                              :
                              isDirectsLoading || teamOrg ? 
                                  <div className='flex flex-col items-left'>
                                      {/* Filters widget */}
                                      <div className=''>
                                          <FilterWidget data={(securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) ? companyOrg : teamOrg} 
                                          onFilterChange={handleFilterChange}
                                            teamTypeFilter={true} defaultTeamTypeFilter={"team"}
                                            managerFilter={true}
                                            employeeFilter={true}
                                            areaFilter={true} 
                                            subareaFilter={true}
                                          />
                                      </div>
                                      {/* Filtered data */}
                                      <div className='flex flex-col'>
                                        {filteredData?.map((employee, index) => (
                                                      <button key={index} id={"direct_"+index} onClick={() => goToProfile(employee?.employeeID)}>
                                                          <UserListItem user={employee?.name + " " + employee?.surname} 
                                                          initials={employee?.name.slice(0,1)+employee?.surname.slice(0,1)} 
                                                          role={employee?.role} level={employee?.level}
                                                          profilePicture = {employee?.profilePicture} />
                                                      </button>

                                                    ))
                                                  }
                                      </div>
                                  </div>

                                  : 
                                  null
                                  }
                            </div>

                            
                          :
                            null
                          }
                          </div>
                          </React.Fragment>

                        : 
                        null
                        }

                      

            </div>
            </div>
            </div>
          </React.Fragment>
        );
}


export default PerformanceTeam;

