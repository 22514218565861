// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

// Common and widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import InboxHeader from './inboxHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import CollapsibleListItem from '../../widgets/collapsibleListItem';
import { useAuth } from '../../common/appContext';

// Toaster & Loader
import Toaster from '../../common/support/toaster';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

import Bot from '../../common/support/bot';
import Tooltip from '../../common/support/tooltip';

function HRDashboard () {

    const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const {token} = useAuth();

    const [hrRequestsHistory, setHRRequesstHistory] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [ticketType, setTicketType] = useState("");
    const [request, setRequest] = useState("");

    // Toaster
    const [toasterMessage, setToasterMessage] = useState(null);

    if (Configs.devEnvironment) console.log ("DEV ONLY - HR Requests History = ", hrRequestsHistory);

// ---------------------------------------------------------------------------------------------------
// Page start methods
// ---------------------------------------------------------------------------------------------------    
    useEffect (() => {
        // Get information to populate dashboard
        if (hrRequestsHistory === null) getHRRequestsHistory();
        }, [ticketType, showForm]);


    async function consultationType (e) {
        e.preventDefault();

        setShowForm(true);
        setTicketType("consultation");
        
    }

    async function claimType (e) {
    e.preventDefault();

    setShowForm(true);
    setTicketType("claim");

    }


        
// ---------------------------------------------------------------------------------------------------
// API methods
// ---------------------------------------------------------------------------------------------------        
async function getHRRequestsHistory () {
    // fetch from server      
    fetch(Configs.platformGetInboxMessagesForCompanyAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      }
    })
        .then((response) => {
          // console.log (response);
          if (response.status === 200) {
            // Username and password mathched in the database - get Token and save it in the session
            response.json()
            .then ( body => {
                setHRRequesstHistory(body);
                if (Configs.devEnvironment) console.log("DEV ONLY log: - History from getHRRequestHistory(): ", body);
              })
            .catch(error => {console.error("Error parsing response JSON", error)});
          } else if (response.status === 401) {
            // Force logout
              sessionStorage.clear();
              window.location.reload();
          } else {
            //
            throw response;
          }
        })
  
        .catch((error) => {
          console.log("Error fetching data: ", error, " - function: getHRRequestsHistory()");
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
        });
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
  };

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="main">
            <InboxHeader />
        <div className='flex flex-row items-start'>
            <div className="left-big-view">

                    {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

                    <div className='ml-4 text-left'>
                        <Tooltip content={t('inbox.hr.view.description')} >
                            <h2 className="section-title">{t('inbox.hr.view')}</h2>
                        </Tooltip>
                    </div>

                    {/** Section with metrics to track -- Hidden for now */}
                    <div className='hidden flex flex-col lg:flex-row overflow-hidden lg:overflow-x-auto justify-around mt-8'>
                        
                        <button className='flex bg-gray-100 m-4 px-10 py-10 rounded shadow'>
                            <div className='flex flex-row lg:flex-col items-center'>
                                <div className='flex'>
                                    🤔 Consultas nuevas
                                </div>
                                <div className='flex text-2xl m-2 font-bold'>
                                    15
                                </div>
                            </div>
                            
                            <p></p>
                            
                        </button>

                    </div>



                    {/** Section with opened cases and status */}
                    <div className='flex flex-col m-4'>
                        {hrRequestsHistory != null && hrRequestsHistory?.length > 0 ? 
                            <React.Fragment>
        
                            <div className='flex flex-col items-start space-y-4'>
                                    <h2 className="section-title">
                                        {t('inbox.history')}
                                    </h2>

                                    <div className='flex flex-col w-full'>
                                            {hrRequestsHistory?.map((request, index) => (
                                                    <div>    
                                                        <CollapsibleListItem 
                                                        title={" " + (request.claimType === "consultation" ? "🤔" : "🚩") + 
                                                            "[" + request.status + "] " +
                                                        new Date(request.timestamp).getUTCDate() + "/" + (new Date(request.timestamp).getUTCMonth() + 1) + "/" + new Date(request.timestamp).getUTCFullYear() + " " +
                                                        (request.claimType === "consultation" ? " - " + request.employee?.employee : "") +
                                                        (request.title ? request.title : "")} content={request.message} collapsed={true} />
                                                    </div>
                                            ))}
                                    </div>
                            </div>
                            </React.Fragment>
                        : 
                            null
                        }
                    </div>
                </div>
                <div className='right-small-view'>

                </div>
            </div>
        </div>
      </React.Fragment>
    );
}

export default HRDashboard;