import React from 'react';

const ProgressBar = ({ percentage }) => {
    // Ensure percentages over 100 are not applied
    const width = Math.min(100, Math.max(0, percentage));

    return (
        <div className="flex flex-row items-center w-full bg-gray-200 rounded-full dark:bg-gray-700 overflow-hidden">
            <div 
                className={`transition-all ease-out duration-500 bg-green-200 text-xs font-medium text-green-900 text-center p-0.5 leading-none rounded-l-full`} 
                style={{ width: `${width}%` }}
            > {Number(percentage).toFixed(0)}% </div>
        </div>
    );
};

export default ProgressBar;