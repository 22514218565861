// React
import React, {useEffect, useState} from 'react';

// Common
import Configs from '../../Configs';

// 3P
import { useTranslation } from 'react-i18next';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import LoadingModal from '../../common/support/loadingModal';

// Icons
import {BiSad} from 'react-icons/bi';


function PulseSurvey () {
    const [t] = useTranslation();
  
    // Get params from URL
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);

    const campaignID = params.get("campaignID");
    const companyID = params.get("companyID");
    const employeeID = params.get("employeeID");
    const questionDate = params.get("questionDate");
    const token = params.get("token") ;
    // survey.type === 'eNPS-followup'
    const answerId = params.get("answerId");

    // Toaster & loader
    const [toasterMessage, setToasterMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Campaign object
    const [campaign, setCampaign] = useState(null);

    if (Configs.devEnvironment) console.log("[pulseSurvey.js][main()] campaign: ", campaign);
    if (Configs.devEnvironment) console.log("[pulseSurvey.js][main()] answerId: ", answerId);

// ---------------------------------------------------------------------------------------------------
// Data validation methods
// ---------------------------------------------------------------------------------------------------    
useEffect (() => {
    // Request to backend Survey to be shown
    if (campaign === null) requestSurvey();
    
    }, []);

// ---------------------------------------------------------------------------------------------------
// Data validation methods
// ---------------------------------------------------------------------------------------------------    
    // Login button was pressed - validate the input
    function validateInput (event) {
        event.preventDefault();
        // Validate input
        const formData = new FormData(event.target);

        // To check for nulls in survey answers
        var anyNull = false;
        // To create answers array
        var auxArray = [];

        campaign?.questions.forEach( (question, index) => {
          const answer = formData.get(index);
          // If any answer is missing, inform user
          if (answer === null || answer === "" || answer === undefined || answer === " ") {
            anyNull = true;
            return;
          } else {
            // Create Single Answer object
            const auxAnswer = {
              questionID: question.question._id,
              answerID: answer
            }
            
            // push answer to array
            auxArray.push(auxAnswer);
          };
        });

        if (Configs.devEnvironment) console.log ("[pulserSurvey.js][validateInput()] auxArray: ", auxArray);

        // Create answer object
        const surveyAnswer = {
          campaignID: campaign._id,
          companyID: campaign.companyID,
          employeeID: employeeID,
          answers: auxArray
        }

        // Submit answers to BackEnd
        if (anyNull)setToasterMessage({message: t('pulse.survey.missing.answers'), type: Configs.warningToaster})
        if (!anyNull) submitAnswers(surveyAnswer);
        
    }
// ---------------------------------------------------------------------------------------------------
// API calls 
// ---------------------------------------------------------------------------------------------------        
     // Request survey to show
     async function requestSurvey  () {
      // Set loading
      setIsLoading(true);
        
      if (Configs.devEnvironment) console.log("[pulseSurvey.js][requestSurvey()] - Requesting survey");

        // fetch from server      
        fetch(Configs.platformUnauthenticatedPulseGetSurveyAPI + "?campaignID=" + campaignID + "&companyID=" + companyID + "&employeeID=" + employeeID,{
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`, // notice the Bearer before your token
            }
          })
          .then((response) => {
            // Set loading
            setIsLoading(false);

            // console.log (response);
            if (response.status === 200) {
              // Username and password mathched in the database - get Token and save it in the session
              response.json()
              .then ( body => {
                if (body.length > 0) {
                  // If answer, set up body of survey
                  setCampaign(body[0]);
                  if (Configs.devEnvironment) console.log("[pulseSurvey.js][requestSurvey()] body: ", body);

                  // body[0].type === 'eNPS-followup'
                  if (body[0]?.type === 'eNPS-followup'){
                    // Send response to eNPS from e-mail and show the rest of the questions

                    // Show toaster or thank you message on screen

                  }
                }
                })
              .catch(error => {console.error("[pulseSurvey.js][requestSurvey()] Error parsing response JSON", error)});
            } else {
              //
              throw response;
            }
          })
          .catch((error) => {
            console.error("[pulseSurvey.js][requestSurvey()] Error fetching data: ", error);
            setCampaign([null]);
            setIsLoading(false);
          });
      
    }

    // Submit answers of survey
    async function submitAnswers  (answers) {
        if (Configs.devEnvironment) console.log("[pulseSurvey.js][submitAnswers()]  Submitting answers");

        // fetch from server      
        fetch(Configs.pulsePostSurveyAnswerAPI + "?campaignID=" + campaignID + "&companyID=" + companyID + "&employeeID=" + employeeID + "&questionDate=" + questionDate, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              // 'Authorization': `Bearer ${token}`, // notice the Bearer before your token
            },
            body: JSON.stringify({ answers })
          })
              .then((response) => {
                // console.log (response);
                if (response.status === 200) {
                  // All answers were successfully submitted
                  setSuccessMessage({message: t('pulse.survey.success'), type: Configs.successToaster});
                  
                  // Reload page
                  const timer = setTimeout(() => {
                    window.location.href = '../';
                  }, Configs.toasterTimeout);
                  
                } else {
                  //
                  setToasterMessage({message: t('pulse.survey.error'), type: Configs.errorToaster});
                  throw response;
                }
              })
        
              .catch((error) => {
                console.error("[pulseSurvey.js][submitAnswers()] Error fetching data: ", error, " - function: submit Survey answers");
              });
      
    }

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
    };

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------    
  if (!campaign  || campaign?.length === [null].length || campaign === null || !employeeID || !companyID || !campaignID) {
    // There is no campaign
    return (
      <React.Fragment>
          <div className="flex flex-col justify-center bg-gradient-to-b from-green-200 to-white p-4 lg:p-20">

            <div className='flex flex-col justify-center h-full ml-32'>
              { isLoading ? 
                    <Loader />
                :
                  <React.Fragment>
                    <h2 className='flex flex-row text-4xl font-bold  m-32'>
                        <span className='text-green-600 text-xl'>error 404</span>
                        <span className='ml-4 mr-4 text-6xl'>|</span>
                        <span>{t('error.404')}  </span> 
                        <BiSad className='ml-4'/>
                    </h2>

                    <span className='flex m-32'>
                        <a href="https://platform.kincode.app" className='underline font-bold italic'> {t('error.404.description')} </a>
                    </span>
                  </React.Fragment>
                    
                }
                
            </div>
          </div>
      </React.Fragment>
    );

  } else {
    // There is a campaign to share  
    return (
              <React.Fragment>
              <div className="flex flex-col justify-center bg-gradient-to-b from-green-200 to-white p-4 lg:p-20">

                {isLoading ? 

                <Loader />

                :
                
                    

                      <div className="flex flex-col justify-center w-full bg-white shadow-xl rounded">
                        
                        <div className="flex flex-col lg:flex-row justify-center items-center">

                          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                          {successMessage && <LoadingModal timeout={Configs.toasterTimeout} onClose={closeToast} message={successMessage}/>}

                          <div className="flex container w-[100px] h-[100px] lg:w-[150px] lg:h-[150px]">
                            <img
                              className=" object-center object-contain"
                              alt="Company Logo"
                              src={
                                campaign.companyPreferences?.personalization?.logoLarge ? campaign.companyPreferences?.personalization?.logoLarge : "/Kincode-logo512.png"
                              }
                            />
                          </div>
                        </div>

                        <div className="flex flex-col justify-center items-center">
                          <div className="flex text-center m-4">
                            {t('pulse.survey.title') + " - " + campaign?.campaignName} 
                          </div>
                          {campaign?.type !== 'eNPS-followup' &&
                          // Dates only for type === "survey"
                            <div className="flex flex-row text-center m-4 title-description">
                              {t('pulse.survey.from')} {new Date(campaign.startDate).toLocaleDateString()}<b className='ml-1'>{t('pulse.survey.to')} {new Date(campaign.endDate).toLocaleDateString()}</b>
                            </div>
                          }
                          
                          <div className="flex items-start title-description hover:underline">
                            <a href={campaign.companyPreferences?.personalization?.pulseEmailsHelpURL} target="_blank">
                              {t('pulse.survey.why')}
                            </a>
                          </div>
                        </div>

                        <SectionSeparator />
                        <div className="flex flex-row justify-center text-sm text-center italic text-gray-600 m-4">
                            {t('pulse.survey.anonymous')}
                        </div>

                        <form
                          onSubmit={validateInput}
                          className="flex flex-col justify-center text-left items-center lg:p-2 w-full ">
                          {campaign?.questions?.map((question, index) => (
                              <fieldset key={index} className="flex w-full m-4 mb-8">
                                <div className="flex flex-col justify-center w-full ml-2 mr-2 pl-4 pr-4 rounded">
                                  <div className='text-sm text-left'>{index+1}/{campaign?.questions.length}</div>

                                  <div className="mb-4 font-bold text-xl">
                                    {question?.question?.Question_ESP}
                                  </div>

                                  {question?.question?.type === "open" ?
                                      <React.Fragment>
                                          <textarea className='mt-2 border rounded p-4 checked:bg-green-200' id={index} name={index} />
                                      </React.Fragment>

                                  :

                                  question?.question?.type === "standard" ?
                                      
                                      <React.Fragment>
                                          {question?.question?.Resp1_String_ESP?.toString().length > 0 ? 
                                          <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                          <input type="radio" className='mr-2 ' name={index} value="5" />
                                            {question?.question?.Resp1_String_ESP}
                                          </label>
                                          : null}
                                          {question?.question?.Resp2_String_ESP?.toString().length > 0 ?
                                          <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                          <input type="radio" className='mr-2 ' name={index} value="4" />{" "}
                                            {question?.question?.Resp2_String_ESP}
                                          </label>
                                          : null}
                                          {question?.question?.Resp3_String_ESP?.toString().length > 0 ?
                                          <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                          <input type="radio" className='mr-2 ' name={index} value="3" />{" "}
                                            {question?.question?.Resp3_String_ESP}
                                          </label>
                                          : null}
                                          {question?.question?.Resp4_String_ESP?.toString().length > 0 ?
                                          <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                          <input type="radio" className='mr-2 ' name={index} value="2" />{" "}
                                            {question?.question?.Resp4_String_ESP}
                                          </label>
                                          : null }
                                          {question?.question?.Resp5_String_ESP?.toString().length > 0 ?
                                          <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                          <input type="radio" className='mr-2 ' name={index} value="1" />{" "}
                                            {question?.question?.Resp5_String_ESP}
                                          </label>
                                          :null}
                                          {question?.question?.Rather_Not_Answer === 0 || question?.question?.Rather_Not_Answer === null ?
                                            null
                                            :
                                            <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                              <input type="radio" className='mr-2 ' name={index} value="rna" />{" "}
                                                {t('pulse.survey.rna')}
                                              </label>
                                          }
                                      </React.Fragment>
                                    :

                                    null
                                  }
                                </div>
                              </fieldset>
                          ))}
                          <div className="flex flex-row justify-center text-sm text-center italic text-gray-600 m-4">
                            {t('pulse.survey.anonymous')}
                          </div>
                          <button className='save-button mb-12 bg-green-200' formAction='submit'>
                            {t('submit')}
                          </button>
                        </form>
                      </div>
                      }
                    </div>
              </React.Fragment>
            );
  }
}

export default PulseSurvey;