// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// 3P
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import OnboardingHeader from './onboardingHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// Widgets
import DataTable from '../../widgets/dataTable';
import PulseFilterWidget from '../../widgets/data/pulseFilterWidget';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// React icons
// import {AiOutlineFilter} from 'react-icons/ai';
import {IoPersonAdd} from 'react-icons/io5';
import { BsStars } from 'react-icons/bs';

function OnboardingReporting () {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    // Employee datapoints  
    const {token, companyID, employeeID, securityLevel} = useAuth();
    const { onboardingReportingData, getOnboardingReportingData, isOnboardingReportingDataLoading, toasterMessage, setToasterMessage, companyOrg } = useData();

    const [modifiedData, setModifiedData] = useState(null);

    const [pulseCampaignAISummary, setPulseCampaignAISummary] = useState(null);
    const [isAISummaryLoading, setIsAISummaryLoading] = useState(false);

    if (Configs.devEnvironment) console.log("[onboardingReporting.js] - onboardingReportingData: ", onboardingReportingData);
    if (Configs.devEnvironment) console.log("[onboardingReporting.js] - modifiedData: ", modifiedData);


    // Headers of data table
    const eligibleEmployeesHeaders = [
      {type:'string',id: 'employee', value: 'employee', label: t('employee'), sort: null, filter: null},
      {type:'string',id: 'manager', value: 'manager[0].employee', label: t('manager'), sort: null, filter: null},
      {type:'string',id: 'buddy', value: 'buddy', label: t('buddy'), sort: null, filter: null},
      {type:'date',id: 'hireDate', value: 'hireDate', label: t('hire-date'), sort: null, filter: null},
      {type:'string',id: 'onboardingPlanStatus', value: 'onboardingPlanStatus', label: t('status'), sort: null, filter: null},
      {type:'progressBar',id: 'onboardingPlanProgress', value: 'onboardingPlanProgress', label: t('progress'), sort: null, filter: null},
      {type:'action',id: 'action', value: 'action', label: "", sort: null, filter: null}
    ];


// -----------------------------------------------------------------------
// Methods to load after page load
// -----------------------------------------------------------------------
useEffect (() => {
    if (onboardingReportingData === null) {
        getOnboardingReportingData();
    } else {
        // setModifiedData(handleFilterChange(onboardingReportingData));
    }

    if (onboardingReportingData !== null) getOnboardingSurveysAISummary();
  
  }, []);

  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------
  const getOnboardingSurveysAISummary = async () => {
    if (Configs.devEnvironment) console.log("[onboardingReporting.js][getOnboardingSurveysAISummary()] - getOnboardingSurveysAISummary - companyID: ", companyID);

    try {
        setIsAISummaryLoading(true);

        const body = {
            companyID: companyID,
            employeeID: employeeID,
            onboardingData: trimOnboardingReportingData(onboardingReportingData)
        }

        let response = await apiCalls.getOnboardingSurveysAISummary(companyID, employeeID, token, body);

        setIsAISummaryLoading(false);

        setPulseCampaignAISummary(JSON.parse(response));

    } catch (error) {
        console.error('[onboardingReporting.js][getOnboardingSurveysAISummary()] We could not reach the server:', error);
    }
}

function trimOnboardingReportingData(data) {
    let trimmedData = [];

    data?.forEach(item => {

        var trimmedQuestions = [];

        item?.questions?.forEach(question => {
            var answer = "";
            if (parseInt(question?.answer) === 5) {
                answer = question?.question?.Resp1_String_ESP;
            } else if (parseInt(question?.answer) === 4) {
                answer = question?.question?.Resp2_String_ESP;
            } else if (parseInt(question?.answer) === 3) {
                answer = question?.question?.Resp3_String_ESP;
            } else if (parseInt(question?.answer) === 2) {
                answer = question?.question?.Resp4_String_ESP;
            } else if (parseInt(question?.answer) === 1) {
                answer = question?.question?.Resp5_String_ESP;
            } else {
                answer = question?.answer;
            }
            
            let trimmedQuestion = {
                questionID: question?.questionID,
                question: question?.question?.Question,
                questionESP: question?.question?.Question_ESP,
                answer: answer
            }
            trimmedQuestions.push(trimmedQuestion);
        });

        let trimmedItem = {
            companyID: item.companyID,
            manager: item.managerName + " " + item.managerSurname,
            managerRole: item.managerRole,
            managerArea: item.managerArea,
            managerSubarea: item.managerSubarea,
            questions: trimmedQuestions
        }

        trimmedData.push(trimmedItem);
    })

    return trimmedData;
}

// ---------------------------------------------------------------------------------------------------
// Filter methods
// ---------------------------------------------------------------------------------------------------
const handleFilterChange = (filteredDataInput) => {
    if (Configs.devEnvironment) console.log("[onboardingReporting.js][handleFilterChange()] - handleFilterChange: ", filteredDataInput);

    let filteredDataAux = [...filteredDataInput];

    // Group data by manager, and then within each manager, by surveyName
    // This will allow us to show the data in a more structured way

    // 1. Group by manager
    let groupedData = [];
    let managers = [...new Set(filteredDataAux?.map(item => item.managerID))];
    managers?.forEach(managerID => {
        // Create a subset only with managerID data
        let managerData = filteredDataAux?.filter(item => item.managerID === managerID);
        let managerAux = {
            companyID: managerData[0].companyID,
            managerID: managerID,
            managerName: managerData[0].managerName,
            managerSurname: managerData[0].managerSurname,
            managerArea: managerData[0].managerArea,
            managerSubarea: managerData[0].managerSubarea,
            area: managerData[0].area,
            subarea: managerData[0].subarea,
            surveys: []
        };

        // 2. Group by surveyName
        let surveys = [...new Set(managerData?.map(item => item.surveyName))];
        surveys?.forEach(surveyName => {
            let surveyData = managerData?.filter(item => item.surveyName === surveyName);

            let surveyAux = {
                surveyName: surveyName,
                questions: []
            };
            // 3. Group by question
            var questionIDs = [];
            surveyData?.forEach(item => {
                item.questions?.forEach(question => {
                    questionIDs.push(question.questionID);
                });
            });
            let uniqueQuestionIDs = [...new Set(questionIDs)];

            uniqueQuestionIDs.forEach(questionID => {
                let questionAux = {};

                // Iterate through questions
                surveyData?.forEach(item => {
                    item.questions?.forEach(question => {
                        if (question?.questionID === questionID) {
                            questionAux = {
                                    questionID: questionID,
                                    question: question?.question,
                                    answers: question?.answer
                                };
                        }
                    });
                });
                surveyAux.questions.push(questionAux);
            });
            managerAux.surveys.push(surveyAux);
        });
        groupedData.push(managerAux);
    });

    if (Configs.devEnvironment) console.log("[onboardingReporting.js][handleFilterChange()] - groupedData: ", groupedData);

    // Set state
    setModifiedData([...groupedData]);
  }
  

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
};

// -----------------------------------------------------------------------
// Render
// -----------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot/>

        <div className="main">


          <OnboardingHeader />
          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        <div className='flex flex-row items-start'>
          <div className='left-big-view'>

            <div className='flex flex-col p-2 lg:p-4 space-y-4'>
                {/* Questions */}
                <div className="flex flex-col mt-2 md:ml-2 m-1">
                    <h2 className="flex text-xl text-left mb-4 ">
                        <div className='flex flex-col items-start md:flex-row justify-left'>
                            <Tooltip content={t('onboarding.reporting.description')}>                                           
                            <div className='section-title flex flex-row items-center'>
                                <IoPersonAdd className='flex mr-2 text-lg'/> {t('onboarding.reporting.title')}
                                <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>
                            </div>
                            </Tooltip>
                        </div>
                    </h2>
                </div>
                {isOnboardingReportingDataLoading ?
                <Loader />
                :
                onboardingReportingData ?
                        // Show the table with candidates
                        <div className='flex flex-col justify-between items-start space-y-2'>

                            <PulseFilterWidget 
                                orgData={onboardingReportingData}
                                onFilterChange={handleFilterChange}
                                // Org Data Filters
                                areaFilter={true}
                                subareaFilter={true}
                                managerFilter={true}
                                // categoryFilter={true}
                                // subcategoryFilter={true}
                            />
                            {modifiedData?.length > 0 ?
                                <div className='flex flex-col w-full space-y-8'>
                                    {modifiedData?.map((manager, index) => (
                                        <div className='flex flex-col w-full items-start' key={index}>
                                            {manager.managerName + " " + manager.managerSurname + " (" + manager.area + " - " + manager.subarea + ")"}

                                            <div className='flex flex-col w-full items-start text-xs'>
                                                {manager?.surveys?.map((survey, index) => (
                                                    <div className='flex flex-col w-full space-y-4 ' key={index}>
                                                        <span className='text-left font-semibold mt-4'>{survey?.surveyName}</span>
                                                        {survey?.questions?.map((question, index) => (
                                                            <div className='flex flex-row w-full items-start' key={index}>
                                                                <span className='mr-2'>{question?.question?.Question_ESP}</span>
                                                                <span>
                                                                    {parseInt(question?.answers) === 5 ?
                                                                        <span className='text-green-500'>{question?.question?.Resp1_String_ESP}</span>
                                                                    :
                                                                    parseInt(question?.answers) === 4 ?
                                                                        <span className='text-green-300'>{question?.question?.Resp2_String_ESP}</span>
                                                                    :
                                                                    parseInt(question?.answers) === 3 ?
                                                                        <span className='text-yellow-300'>{question?.question?.Resp3_String_ESP}</span>
                                                                    :
                                                                    parseInt(question?.answers) === 2 ?
                                                                        <span className='text-yellow-500'>{question?.question?.Resp4_String_ESP}</span>
                                                                    :
                                                                    parseInt(question?.answers) === 1 ?
                                                                        <span className='text-red-500'>{question?.question?.Resp5_String_ESP}</span>
                                                                    :
                                                                        <span className='text-gray-500'>{question?.answers}</span>
                                                                    }

                                                            </span>
                                                            </div>
                                                        ))}
                                                        </div>
                                                    
                                                ))}

                                            </div>
                                        </div>

                                    ))}
                                </div>
                            :
                                // no data
                                <div className='flex flex-col justify-center items-center p-8 '>
                                    <span className='title-description italic'>
                                        {t('onboarding.reporting.no-data') + ". "}
                                    </span>
                                </div>
                            }

                        </div>
                    :
                        // no data
                        <div className='flex flex-col justify-center items-center p-8 '>
                        <span className='title-description italic'>
                            {t('onboarding.reporting.no-data') + ". "}
                        </span>
                        </div>   
                }
                
            </div>

            <SectionSeparator />

        </div>

        <div className='right-small-view'>
            <div className='flex flex-col items-start space-y-4 p-2 lg:p-4'>
                    <div className="flex flex-col mt-2 md:ml-2 m-1">
                            <h2 className="flex text-xl text-left mb-4 ">
                                <div className='flex flex-col items-start md:flex-row justify-left'>
                                    <Tooltip content={t('onboarding.reporting.ai.description')}>
                                    <div className='section-title flex flex-row items-center'>
                                        <BsStars className='mr-2 text-violet-800'/> {t('onboarding.reporting.ai.title')}
                                        <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>
                                    </div>
                                    </Tooltip>
                                </div>
                            </h2>
                        </div>

                  {isAISummaryLoading ?
                    <div className='flex flex-col items-center mt-8 md:ml-2 m-1'>
                        <Loader />
                    </div>
                  :
                    pulseCampaignAISummary !== null ?
                    <div className='flex flex-col items-center space-y-4'>
                        {/* Key insights */}
                      <div className="flex flex-col items-start bg-white shadow border border-gray-200 rounded-lg py-8 px-8 space-y-2">
                        <h3 className='text-left text-lg font-bold mb-4' >🔍 {t('pulse.campaign.ai.key-insights')}</h3>
                        {pulseCampaignAISummary["keyPoints"]?.map((item, index) => (
                          <div className="flex flex-row items-start space-x-2" key={index}>
                            <span className='flex px-3 py-1.5 bg-gray-200 rounded-full text-sm'>{index+1}</span>
                            <span className="flex title-description"><p className='text-xs'>{item}</p></span>
                          </div>
                        ))}
                      </div>

                        {/* Action items  */}
                      <div className='flex flex-col items-start bg-white shadow border border-gray-200 rounded-lg py-8 px-8 space-y-2'>
                        <h3 className='text-left text-lg font-bold mb-4'>✅ {t('pulse.campaign.ai.action-items')}</h3>
                        {pulseCampaignAISummary["actionItems"]?.map((item, index) => (
                          <div className="flex flex-row items-start space-x-2" key={index}>
                            <span className='flex px-3 py-1.5 bg-gray-200 rounded-full text-sm'>{index+1}</span>
                            <span className="flex title-description"><p className='text-xs'>{item}</p></span>
                          </div>
                        ))}
                        
                      </div>
                    </div>
                    :
                    <div className='flex flex-col items-center mt-8 md:ml-2 m-1'>
                      <span className="title-description italic">📊 {t('pulse.campaign.ai-summary-empty')}{" "}
                      </span>
                    </div>
                }
                </div>
              </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default OnboardingReporting;