// React
import React, { useState } from 'react';

// Common and Configs
import Configs from '../../Configs';
import { useAuth } from '../../common/appContext';

// 3P
import { useTranslation } from 'react-i18next';

// Loader, Toaster, Tooltip
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';


// --------------------------------------------------------------------   
// FileInput component
// --------------------------------------------------------------------
const FileInput = ({ 
    onFileUpload,
     index,
     allowedTypes = [], // e.g.: ['application/pdf', 'image/jpeg']
     maxFileSize = null, // e.g.: 5 MB = 5 * 1024 * 1024
     imageDimensions = null, // e.g.: {width: 1024, height: 768}
     fileType = 'other',
     required = false
     }) => {

// --------------------------------------------------------------------   
// Variables
// --------------------------------------------------------------------
    // Translations
    const {t} = useTranslation();

    // state variable
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    // employee context
    const { employeeID, companyID, token } = useAuth();    

    const readableAllowedTypes = allowedTypes.map((type) => type.split('/')[1]).join(', ');


// --------------------------------------------------------------------
// API calls 
// --------------------------------------------------------------------
const handleSubmit = async (event, file) => {
    setIsLoading(true);

    event.preventDefault();
    if (!file && !selectedFile) {
      alert(t('widgets.fileInput.noFileSelected'));
      return;
    }

    // Prepare FormData
    const formData = new FormData();
    formData.append('file', file ? file : selectedFile);

    // Submit file
    try {
      const response = await fetch(Configs.platformPostUploadFile + "?companyID=" + companyID + "&employeeID=" + employeeID + "&type=" + fileType, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: formData,
        // Note: When using FormData, do NOT set 'Content-Type' header
        // headers: { 'Content-Type': 'multipart/form-data' }, // Do not include this line
      });

        // stop loader
        setIsLoading(false);

      if (response.ok) {
        const result = await response.json();
        // Process result...
        if (Configs.devEnvironment) console.log ('[fileInput.js][handleSubmit] result:', result);

        // Optionally, call a function passed as a prop when a file is uploaded
        if (result?.fileId && onFileUpload) {
          onFileUpload(result?.fileId, index);
        }

      } else {
        alert(t('widgets.fileInput.errorUploadingFile'));
      }
    } catch (error) {
        setIsLoading(false);
        console.error('Error uploading file:', error);
        alert(t('widgets.fileInput.errorUploadingFile'));
    }
  };

// --------------------------------------------------------------------
// Validations
// --------------------------------------------------------------------
const validateFile = (file) => {
    // Reset error
    setError('');

    // Validate MIME type
    if (allowedTypes.length && !allowedTypes.includes(file.type)) {
      setError(`${t('widgets.fileInput.allowedFileTypes')}${readableAllowedTypes}`);
      return false;
    }

    // Validate file size
    if (maxFileSize && file.size > maxFileSize) {
      setError(`${t('widgets.fileInput.maxFileSize')} ${maxFileSize / 1024 / 1024}MB`);
      return false;
    }

    // Further validation for image dimensions can be performed after file selection
    return true;
  };

// --------------------------------------------------------------------   
// Event Handlers
// --------------------------------------------------------------------
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && validateFile(file)) {
        setSelectedFile(file);

        // call API to upload file
        handleSubmit(event, file);
    } 
  };

  
// --------------------------------------------------------------------
// Render
// --------------------------------------------------------------------
return (
    isLoading ? 
        <div  className='flex flex-row items-center justify-center'> 
          <Loader /> 
        </div>
    : 
        <form onSubmit={handleSubmit} className="flex flex-row items-center space-x-2 lg:space-x-0 lg:flex-col space-y-0 lg:items-end lg:space-y-2">
          <div className="flex items-center space-x-2">
              <label className="flex flex-row block">
                <span className="sr-only">Choose profile photo</span>
                {required ? 
                  <span className="text-red-500">*</span>
                  : null

                }
                <input type="file" 
                required={required}
                className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-green-50 file:text-green-700
                hover:file:bg-green-100
                " onChange={handleFileChange}
                />
              </label>
              {selectedFile && <p className="text-xs text-gray-500"> {t('widgets.fileUpload.file-selected') + selectedFile?.name?.slice(0, 20)}</p>}
              {error && <p className="text-red-500">{error}</p>}
          </div>
          {/* <div className='flex flex-row items-center justify-end'>
              <button type="submit" className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700">Submit File</button>
          </div> */}
        </form>
  );
};

export default FileInput;