// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

// Common and widgets
import Configs from "../../Configs";
import NoneditableEditor from '../../widgets/noneditableEditor';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// 3P
import { useTranslation } from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// ---------------------------------------------------------------------------------------------------
function RecognitionPublicSingleRecognition () {
    const {t} = useTranslation();
        
    const {toasterMessage, setToasterMessage} = useData();

    var recognitionID = null;
    const {id} = useParams();

    if (!id) {
        const url = window.location.href;
        recognitionID = url.split('/').pop(); // Assuming ID is at the end of the URL
    } else {
        recognitionID = id;
    }

    // Recognition 
    const [recognition, setRecognition] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

// ---------------------------------------------------------------------------------------------------
// After loading the view
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {

        async function fetchData () {
            try {
                setIsLoading(true);

                const recognition = await apiCalls.getPublicSingleRecognition(recognitionID);

                if (Configs.devEnvironment) console.log("[recognitionPublicSingleRecognition.js][fetchData()] recognition", recognition);

                setIsLoading(false);

                if (recognition?.length > 0) {
                    setRecognition(recognition[0]);

                    // // Add open graph meta tags
                    // // Title
                    // document.title = t('recognition.public.title');
                    // const metaTitle = document.createElement('meta');
                    // metaTitle.setAttribute('property', 'og:title');
                    // metaTitle.content = t('recognition.public.title');
                    // document.head.appendChild(metaTitle);

                    // // Description
                    // const metaDescription = document.createElement('meta');
                    // metaDescription.setAttribute('property', 'og:description');
                    // metaDescription.content = recognition[0]?.recognition;
                    // document.head.appendChild(metaDescription);

                    // // og:url
                    // const metaUrl = document.createElement('meta');
                    // metaUrl.setAttribute('property', 'og:url');
                    // metaUrl.content = window.location.href;
                    // document.head.appendChild(metaUrl);

                }

            } catch (error) {
                setIsLoading(false);
                setToasterMessage({type: Configs.errorToaster, message: t('error')});
            }
        }

        // Call the async function
        fetchData();

    }, []);


// ---------------------------------------------------------------------------------------------------
// Navigation methods
// ---------------------------------------------------------------------------------------------------
async function navigateToMarketingSite () {
  window.open("https://www.kincode.app/product/engage/recognition/", '_blank');
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
    
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>

        <div className="flex flex-col lg:p-20 p-4 h-screen bg-gradient-to-b from-green-200 to-white ">
            {/* Description of the award  - For LinkedIn */}

            { isLoading ? 
            // Loading
             <Loader /> 
             :

             recognition ?

                <div className="flex flex-col mt-2 md:ml-2 m-1 border bg-white rounded-lg shadow p-8">
                        <h2 className="flex flex-wrap text-lg"> 🥇
                            <span className='flex font-bold text-green-800 mr-1'>
                                {
                                recognition?.recognizedEmployees?.map((employee, index) => (
                                    index === recognition?.recognizedEmployees?.length - 1 ? 
                                        <div className='' >{employee?.name + " " + employee?.surname}</div>
                                    :
                                    <div className=' mx-1'>{employee?.name + " " + employee?.surname + ", "}</div>
                                ))} 
                            </span>
                            {" " + t('recognition.card.public.after-names') + " " } 
                        </h2>
                        <span className="flex title-description ml-5"> {new Date(recognition?.timestamp).getDate() + "/" + (new Date(recognition?.timestamp).getMonth() + 1) + "/" + new Date(recognition?.timestamp).getFullYear()}</span>

                        <div className='flex flex-col justify-left space-y-4'>
                            {/* Recognition - what? */}
                            <div className='flex text-sm'>
                                <NoneditableEditor content={recognition?.recognition} />
                            </div>

                            {/* Values */}
                            <div className='flex flex-col items-start  ml-4 title-description'>
                                {t('recognition.card.public.demonstrating-values')}
                                {recognition?.values?.length > 0 ?
                                    <div className='flex flex-wrap space-x-2 px-4 py-4'>
                                        {recognition?.values?.map((value, index) => (
                                            <div key={index} id={index} 
                                            className={' bg-green-200  text-sm  font-bold rounded-lg px-3 py-2'}>
                                                {value}
                                            </div>
                                        ))}
                                    </div>
                                :
                                null
                                }
                            </div>

                        </div>
                </div>
            : 
            null
        
        }
            
        </div>

    </React.Fragment>
    );
}

export default RecognitionPublicSingleRecognition;