// React
import React, { useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

// Frameworks
import Configs from '../../Configs';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Headers
import OrganigramHeader from './organigramHeader';
import Header from '../../common/header';
import Bot from '../../common/support/bot';
import UserListItem from '../../widgets/userListItem';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiService from '../../common/apiCalls';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import NoneditableEditor from '../../widgets/noneditableEditor';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineInfoCircle} from 'react-icons/ai';
// import PersonalOnboarding from '../../widgets/personal/personalOnboarding';
import PersonalTimeOff from '../../widgets/personal/personalTimeOff';
import { BsLadder, BsPersonBadgeFill } from 'react-icons/bs';
import { GiOrganigram } from 'react-icons/gi';

// ---------------------------------------------------------------------------------------------------
function OrganigramEmployeesCreate () {
    // Params for edition
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    const navigate = useNavigate();
    const {t} = useTranslation();

    const {token, securityLevel, employeeID, companyID} = useAuth();
    const {companyOrg, getMyDirectTeam, getMyCompanyOrg, getMyTeamOrg, companyPreferences, toasterMessage, setToasterMessage} = useData();

    // Toaster & Loader
    const [isEmployeeLoading, setIsEmployeeLoading] = useState(false);

    const [employee, setEmployee] = useState(null);

    const employeeConfigAux = {
        personalFields: [
            {name: 'name', label: 'name', type: 'text', required: true, placeholder:  'organigram.employee.name.placeholder'},
            {name: 'surname', label: 'surname', type: 'text', required: true, placeholder:  'organigram.employee.surname.placeholder'},
            {name: 'email', label: 'email', type: 'text', required: true, placeholder:  'organigram.employee.email.placeholder', tooltip:  'organigram.employee.email.info'},
            {name: 'hireDate', label: 'hire-date', type: 'date', required: false},
        ],
        organigramFields: [
            {name: 'managerID', label:  'manager', type: 'select', required: true, placeholder:  'organigram.employee.manager.placeholder', tooltip:  'organigram.employee.manager.info'},
            {name: 'area', label:  'area', type: 'select', required: false, placeholder:  'organigram.employee.area.placeholder'},
            {name: 'subarea', label:  'subarea', type: 'select', required: false, placeholder:  'organigram.employee.subarea.placeholder'},
        ],
        careerFields: [
            {name: 'role', label:  'role', type: 'select', required: false, placeholder:  'organigram.employee.role.placeholder'},
            {name: 'level', label:  'level', type: 'select', required: false, placeholder:  'organigram.employee.level.placeholder'},
        ],
        onboardingFields: [
            {name: 'onboarding', label:  'onboarding', type: 'text', required: false, placeholder:  'organigram.employee.onboarding.placeholder'},
        ],
        timeOffFields: [
            {name: 'country', label:  'country', type: 'text', required: false, placeholder:  'organigram.employee.timeoff.placeholder'},
            {name: 'region', label:  'region', type: 'text', required: false, placeholder:  'organigram.employee.timeoff.placeholder'},
            {name: 'city', label:  'city', type: 'text', required: false, placeholder:  'organigram.employee.timeoff.placeholder'},
        ],
    }

    const employeeConfig = companyPreferences?.modulesSettings?.employeeConfig ? companyPreferences?.modulesSettings?.employeeConfig : employeeConfigAux;

    // Form options
    // Data to populate
    // Organigram infor
    const [managers, setManagers] = useState([null]);
    const [areas, setAreas] = useState([null]);
    const [subAreas, setSubAreas] = useState([null]);
    const [places, setPlaces] = useState([null]);
    const [roles, setRoles] = useState([null]);
    const [levels, setLevels] = useState([null]);
    const [contractTypes, setContractTypes] = useState([null]);

    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js] params: ", params);
    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js] context: ", employeeConfig);

// ---------------------------------------------------------------------------------------------------
// Methods to load when page is loaded
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        if (params) {
            getProfile(params.employeeID); 
        } else {
            setEmployee({});
        }

        // Populate all dropdowns
        populateDropdowns();
      }, [companyOrg]);      



// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------
// Get profile
async function getProfile (auxEmployeeID) {
    // Set loading state  
    setIsEmployeeLoading(true);

      // fetch from server      
      fetch(Configs.platformGetProfileAPI + "?employeeID=" + (auxEmployeeID ? auxEmployeeID : employeeID) + "&companyID=" + companyID, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        }
      })
          .then((response) => {
            setIsEmployeeLoading(false);

            if (response.status === 200) {
              response.json()
              .then(body => {
                  populateEmployeeInfo(body?.employeeInfo);
                  if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][getProfile()] Profile from getProfile(): ", body);
                })
              .catch(error => {
                console.error("[organigramEmployeeCreate.js][getProfile()] Error parsing response JSON", error)
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
              });
            } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                navigate(-1);
            } else if (response.status === 404) {
                setToasterMessage({message: t('warning'), type: Configs.warningToaster});
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
          })
          .catch((error) => {
            setIsEmployeeLoading(false);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            console.error("[organigramEmployeeCreate.js][getProfile()] Error fetching data: ", error);
          });
  }

async function postNewEmployee (employeeObject) {
        // Post new employee
        // Indicate loading status
        setIsEmployeeLoading(true);

        // Create data object
        const data = {
            companyID: companyID,
            employeeID: employeeID,
            newEmployee: employeeObject
        }

        // fetch from server
        try {
            const response = await apiService.postNewEmployee(companyID, employeeID, token, data);

            setIsEmployeeLoading(false);

            // Review response status
            if (response === 500) {
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            } else if (response === 422) {
                setToasterMessage({message: t('error.data-validation-error'), type: Configs.errorToaster});
            } else if (response === 404) {
                setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
            } else if (response === 403) {
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            } else if (response === 401) {
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response === 400) {
                setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
            } else if (response === 209) {
                // Employee already exists
                setToasterMessage({message: t('organigram.error.employee-already-exists'), type: Configs.errorToaster});
            } else {
                if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][postNewEmployee()] response: ", response);

                // Success
                setToasterMessage({message: t('organigram.employee.create.success') + employeeObject?.employee, type: Configs.successToaster});

                // Clear frontend fields
                setEmployee(null);
    
                // Clear "cachés" to ensure re-load of employees
                getMyDirectTeam();
                getMyTeamOrg();
                getMyCompanyOrg();
                        
                // Navigate back
                navigate(-1);
            }

        } catch (error) {
            console.error('[organigramEmployeeCreate.js][postNewEmployee()] Posting new employee failed:', error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            setIsEmployeeLoading(false);
            // throw error;
        }
}

    async function editEmployee (employeeObject) {
        // Post new employee
        // Indicate loading status
        setIsEmployeeLoading(true);

        // Create data object
        const data = {
            companyID: companyID,
            employeeID: employeeID,
            employeeToEdit: employeeObject
        }

        // fetch from server
        try {
            const response = await apiService.putEditEmployee(companyID, employeeID, token, data);

            setIsEmployeeLoading(false);

            // Review response status
            if (response === 500) {
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            } else if (response === 422) {
                setToasterMessage({message: t('error.data-validation-error'), type: Configs.errorToaster});
            } else if (response === 404) {
                setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
            } else if (response === 403) {
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            } else if (response === 401) {
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response === 400) {
                setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
            } else if (response === 209) {
                // Employee already exists
                setToasterMessage({message: t('organigram.error.employee-already-exists'), type: Configs.errorToaster});
            } else {
                if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][editEmployee()] response: ", response);

                // Success
                setToasterMessage({message: t('organigram.employee.create.success') + employeeObject?.employee, type: Configs.successToaster});

                // Clear frontend fields
                setEmployee(null);
    
                // Clear "cachés" to ensure re-load of employees
                getMyDirectTeam();
                getMyTeamOrg();
                getMyCompanyOrg();
                        
                // Navigate back
                navigate(-1);
            }
        } catch (error) {
            console.error('[organigramEmployeeCreate.js][editEmployee()] Posting new employee failed:', error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            setIsEmployeeLoading(false);
            // throw error;
        }      
        
    }

    // ---------------------------------------------------------------------------------------------------
// Methods to populate fields
// ---------------------------------------------------------------------------------------------------
async function populateEmployeeInfo (employeeInfo) {
    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][populateEmployeeInfo()] employeeInfo: ", employeeInfo);
    employeeInfo.email = employeeInfo?.emails[0] ? employeeInfo?.emails[0] : null;

    setEmployee(employeeInfo);

}

// ---------------------------------------------------------------------------------------------------
// Form management methods
// ---------------------------------------------------------------------------------------------------  
async function cancel () {
    // Go back to previous page
    navigate(-1);
}

async function validateInputsCreate () {
    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][validateInputsCreate()] - START");
    
    // Check if all required === true from employeeConfig are filled
    var missingFields = [];

    // Iterate through entire employeeConfig object and check if all required fields are filled
    employeeConfig?.personalFields?.forEach((field) => {
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    employeeConfig?.organigramFields?.forEach((field) => {  
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    employeeConfig?.careerFields?.forEach((field) => {
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    employeeConfig?.onboardingFields?.forEach((field) => {
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    employeeConfig?.timeOffFields?.forEach((field) => {
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    if (missingFields.length > 0) {
        if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][validateInputsCreate()] Missing fields: ", missingFields);
        setToasterMessage({type: Configs.warningToaster, message: t('field.missing')});
        return false;
    }

    
    // Create auxObject to be uploaded to the database
    var auxNewEmployee = employee;
    auxNewEmployee.companyID = companyID;

    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][validateInputsCreate()] auxNewEmployee: ", auxNewEmployee);

    // Post employee
    postNewEmployee(auxNewEmployee);
}

async function validateInputsEdit  () {
    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][validateInputsEdit()] - START");

    // Check if all required === true from employeeConfig are filled
    var missingFields = [];

    // Iterate through entire employeeConfig object and check if all required fields are filled
    employeeConfig?.personalFields?.forEach((field) => {
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    employeeConfig?.organigramFields?.forEach((field) => {  
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    employeeConfig?.careerFields?.forEach((field) => {
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    employeeConfig?.onboardingFields?.forEach((field) => {
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    employeeConfig?.timeOffFields?.forEach((field) => {
        if (field.required) {
            if (!employee[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    if (missingFields.length > 0) {
        if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][validateInputsCreate()] Missing fields: ", missingFields);
        setToasterMessage({type: Configs.warningToaster, message: t('field.missing')});
        return false;
    }

    // Create auxObject to be uploaded to the database
    var auxEmployee = employee;
    auxEmployee.companyID = params?.companyID;
    auxEmployee.employeeID = params?.employeeID;

    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][validateInputsEdit()] auxEmployee: ", auxEmployee);

    // Put employee
    editEmployee(auxEmployee);
}

async function populateDropdowns () {
    // From the list of employees in people, extract all the employees and list them alphabetycally
    let peopleAux = [];
    let areasAux = [];
    let addedAreas = [];
    let subAreasAux = [];
    let addedSubAreas = [];
    let placesAux = [];
    let addedPlaces = [];
    let rolesAux = [];
    let addedRoles = [];
    let levelsAux = [];
    let addedLevels = [];
    let contractTypes = [];
    let addedContractTypes = [];

    // Iterate through all employees
    companyOrg.map((person) => {
        peopleAux.push({value: person?.employeeID, label: person?.name + ' ' + person?.surname});

        // Check if it has already been added
        if (! addedAreas?.includes(person?.area)) {
            areasAux.push({value: person?.area, label: person?.area});
            addedAreas.push(person?.area);
        }
  
        // Check if it has already been added
        if (! addedSubAreas?.includes(person?.subarea)) {
            subAreasAux.push({value: person?.subarea, label: person?.subarea});
            addedSubAreas.push(person?.subarea);
        }

        // Place of work
        if (! addedPlaces?.includes(person?.placeOfWork)) {
            placesAux.push({value: person?.placeOfWork, label: person?.placeOfWork});
            addedPlaces.push(person?.placeOfWork);
        }

        // Check if it has already been added
        if (! addedRoles?.includes(person?.role)) {
            rolesAux.push({value: person?.role, label: person?.role});
            addedRoles.push(person?.role);
        }

        // Check if it has already been added
        if (! addedLevels?.includes(person?.level)) {
            levelsAux.push({value: person?.level, label: person?.level});
            addedLevels.push(person?.level);
        }

        // Check if it has already been added
        if (! addedContractTypes?.includes(person?.contractType)) {
            contractTypes.push({value: person?.contractType, label: person?.contractType});
            addedContractTypes.push(person?.contractType);
        }

    });
    
    // Order alphabetically
    areasAux.sort((a, b) => (a.label > b.label) ? 1 : -1);
    subAreasAux.sort((a, b) => (a.label > b.label) ? 1 : -1);
    placesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);
    levelsAux.sort((a, b) => (a.label > b.label) ? 1 : -1);
    rolesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);
    contractTypes.sort((a, b) => (a.label > b.label) ? 1 : -1);

    // Update state
    setManagers([...peopleAux]);
    setAreas([...areasAux]);
    setSubAreas([...subAreasAux]);
    setPlaces([...placesAux]);
    setLevels([...levelsAux]);
    setRoles([...rolesAux]);    
    setContractTypes([...contractTypes]);
}

async function createOption (e, type) {
    if (Configs.devEnvironment) console.log("[organigramEmployeesCreate.js][createOption()] Creating option:", e, type);

    switch (type) {
        case 'area':
            // Add area to current list of subareas
            let areasAux = areas;
            areasAux.push({value: e, label: e});
            
            // Order alphabetically
            areasAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setAreas([...areasAux]);

            // Set area
            var employeeAux = employee;
            employeeAux.area = e;
            setEmployee(employeeAux);
            
            break;
        case 'subarea':
            // Add subarea to current list of subareas
            let subAreasAux = subAreas;
            subAreasAux.push({value: e, label: e});
            
            // Order alphabetically
            subAreasAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setSubAreas([...subAreasAux]);
            // Set subarea
            var employeeAux = employee;
            employeeAux.subarea = e;
            setEmployee(employeeAux);
            break;
        case 'placeOfWork':
            // Add place to current list of places
            let placesAux = places;
            placesAux.push({value: e, label: e});

            // Order alphabetically
            placesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setPlaces([...placesAux]);
            // Set place
            var employeeAux = employee;
            employeeAux.placeOfWork = e;
            setEmployee(employeeAux);
            break;
        case 'role':
            // Add role to current list of roles
            let rolesAux = roles;
            rolesAux.push({value: e, label: e});

            // Order alphabetically
            rolesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setRoles([...rolesAux]);
            // Set role
            var employeeAux = employee;
            employeeAux.role = e;
            setEmployee(employeeAux);
            break;
        case 'level':
            // Add level to current list of levels
            let levelsAux = levels;
            levelsAux.push({value: e, label: e});

            // Order alphabetically
            levelsAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setLevels([...levelsAux]);
            // Set subarea
            var employeeAux = employee;
            employeeAux.level = e;
            setEmployee(employeeAux);
            break;
        case 'contractType':
            // Add contract type to current list of contract types
            let contractTypesAux = contractTypes;
            contractTypesAux.push({value: e, label: e});
            
            // Order alphabetically
            contractTypesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setContractTypes([...contractTypesAux]);
            // Set contract type
            var employeeAux = employee;
            employeeAux.contractType = e;
            setEmployee(employeeAux);
            break;
        default:
            break;

    }
}

// ---------------------------------------------------------------------------------------------------
// Field management methods
// ---------------------------------------------------------------------------------------------------
const handleFieldChange = (e, section, field) => {
    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][handleFieldChange()] e:", e, "section:", section, "field:", field);

    var employeeAux = JSON.parse(JSON.stringify(employee));
    let fieldConfig = null;

    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][handleFieldChange()] employeeAux (before):", employeeAux);

    switch (section) {
        case 'personal':
            // Look for the field config in the employeeConfig 
            fieldConfig = employeeConfig.personalFields?.find((element) => element.name === field);

            if (fieldConfig?.type === 'date') {
                employeeAux[field] = e.target.value;
            } else if (fieldConfig?.type === 'select') {
                employeeAux[field] = e;
            } else {
                employeeAux[field] = e.target.value;
            }
            break;
        case 'organigram':
            fieldConfig = employeeConfig.organigramFields?.find((element) => element.name === field);

            if (fieldConfig?.type === 'date') {
                employeeAux[field] = e.target.value;
            } else if (fieldConfig?.type === 'select') {
                employeeAux[field] = e.value?.toString();
            } else {
                employeeAux[field] = e.target.value;
            }
            break;
        case 'career':
            fieldConfig = employeeConfig.careerFields?.find((element) => element.name === field);
            if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][handleFieldChange()] fieldConfig:", fieldConfig);

            if (fieldConfig?.type === 'date') {
                employeeAux[field] = e;
            } else if (fieldConfig?.type === 'select') {
                employeeAux[field] = e.value?.toString();
            } else {
                employeeAux[field] = e.target.value;
            }
            break;
        case 'onboarding':
            fieldConfig = employeeConfig.onboardingFields?.find((element) => element.name === field);

            if (fieldConfig?.type === 'date') {
                employeeAux[field] = e;
            } else if (fieldConfig?.type === 'select') {
                employeeAux[field] = e.value?.toString();
            } else {
                employeeAux[field] = e.target.value;
            }
            break;
        case 'timeOff':
            fieldConfig = employeeConfig.timeOffFields?.find((element) => element.name === field);

            if (fieldConfig?.type === 'date') {
                employeeAux[field] = e;
            } else if (fieldConfig?.type === 'select') {
                employeeAux[field] = e.value?.toString();
            } else {
                employeeAux[field] = e.target.value;
            }
            break;
        default:
            break;
    }

    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][handleFieldChange()] employeeAux (after):", employeeAux);

    if (Configs.devEnvironment) console.log("[organigramEmployeeCreate.js][handleFieldChange()] fieldConfig:", fieldConfig, e.value, areas.find((option) => option?.value === employeeAux?.area));


    // Set State
    setEmployee(employeeAux);

}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
};

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
return (
      <React.Fragment>
      <Header />

      <Bot/>

      <div className="main">

        <OrganigramHeader />

        <div className='flex flex-row items-start'>
              
            <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
            
            <div className="flex flex-col mt-2 md:ml-2 m-1">
                    <div className='flex flex-col'>
                        <h2 className="flex text-xl mb-4 ">
                            <div className='flex flex-col items-start md:flex-row justify-left'>
                                
                                {params ? 
                                    <Tooltip content={t('organigram.employee.edit.description')} >
                                        <div id="title" className='section-title'>
                                            {t('organigram.employee.edit.title')}
                                        </div>
                                    </Tooltip>
                                    :
                                    <Tooltip content={t('organigram.employee.create.description')} >
                                        <div id="title" className='section-title'>
                                            {t('organigram.employee.create.title')}
                                        </div>
                                    </Tooltip>
                                    
                                }
                                
                            </div>
                        </h2>
                    </div>
                </div>
                       
                <React.Fragment>
                    
                            <div className="flex flex-col mt-2 lg:ml-2 m-1 space-y-8 w-full text-xs">

                                {isEmployeeLoading ?
                                <Loader/>
                                :
                                employeeConfig && employee ?
                                <React.Fragment>
                                    {/* Section title - Personal */}
                                    <div className='flex flex-col w-full space-y-2 bg-white shadow px-2 py-4 rounded-lg border border-gray-200'> 
                                        <div className='flex flex-col items-start text-left space-y-2 pb-2 border-b border-gray-400'>
                                            <h2 className='flex flex-row items-center space-x-2 subsection-title'>
                                                {/* <BsPersonBadgeFill className='mr-2' />  */}
                                                {t('organigram.employee.personal-information')}
                                            </h2>
                                        </div>

                                        {employeeConfig?.personalFields?.map((field, index) => {
                                            return (
                                                <div class="flex flex-col items-start text-left space-y-1 px-4">
                                                    <div className='flex flex-row items-center'>
                                                        <label for={field.name} className="form-label">
                                                            {t(field.label)}
                                                            {(field.required && !field.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                            {(field.required && field.missing === true) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                                        </label>
                                                            {field.tooltip && <Tooltip content={field.tooltip} >
                                                                <AiOutlineInfoCircle className='flex text-gray-500 hover:cursor-pointer ml-2' />  
                                                            </Tooltip>}
                                                    </div>
                                                    <div className='flex w-full'>
                                                        <input type={field.type} 
                                                            id={field.name} 
                                                            value={employee ? (field?.type === "date" && employee[field?.name] ? new Date(employee[field?.name])?.toISOString()?.slice(0, 10) : employee[field?.name]) : null}
                                                            onChange={(e) => {handleFieldChange(e, "personal", field.name)}}
                                                            className="input-field w-full" 
                                                            placeholder={t(field.placeholder)} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {/* Section title - Organigram */}
                                    <div className='flex flex-col w-full space-y-2 bg-white shadow px-2 py-4 rounded-lg border border-gray-200'> 

                                        <div className='flex flex-col items-start text-left space-y-2 pb-2 border-b border-gray-400'>
                                            <h2 className='flex flex-row items-center space-x-2 subsection-title'>
                                        {       /* <GiOrganigram className='mr-2' />  */}
                                                {t('organigram.employee.organigram-information')}
                                            </h2>
                                        </div>

                                            {employeeConfig?.organigramFields?.map((field, index) => {
                                                return (
                                                <div class="flex flex-col items-start text-left space-y-1 px-4">
                                                    <div className='flex flex-row items-center'>
                                                        <label for={field?.name} className="form-label">
                                                            {t(field?.label)}
                                                            {(field?.required && !field?.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                            {(field?.required && field?.missing) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                                        </label>
                                                            {field.tooltip && <Tooltip content={field?.tooltip} >
                                                                <AiOutlineInfoCircle className='flex text-gray-500 hover:cursor-pointer ml-2' />  
                                                            </Tooltip>}
                                                    </div>
                                                        <div className='flex w-full'>
                                                            <div class={"flex w-full rounded-md shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset " + (field?.missing === true ? " ring-2 ring-red-300  focus-within:ring-red-200 " : " ring-gray-300 focus-within:ring-green-200 ")}>
                                                                <CreatableSelect
                                                                    options={field?.name === 'managerID' ? managers : field?.name === 'area' ? areas : field?.name === "subarea" ? subAreas : places}
                                                                    value= {field?.name === 'managerID' ? managers?.find((option) => option?.value === employee?.managerID) : field?.name === 'area' ? areas?.find((option) => option?.value === employee?.area) : field?.name === "subarea" ? subAreas.find((option) => option?.value === employee?.subarea) : places.find((option) => option?.value === employee?.placeOfWork)}
                                                                    id={field?.name}
                                                                    className='w-full text-xs'
                                                                    isSearchable
                                                                    placeholder={t(field?.placeholder)}
                                                                    styles={Configs.formDropdownStyle}
                                                                    onChange={(e) => handleFieldChange(e, "organigram", field?.name)}
                                                                    onCreateOption={(e) => createOption(e, field?.name)}
                                                                    /> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                        {/* Career information  */}
                                    <div className='flex flex-col w-full space-y-2 bg-white shadow px-2 py-4 rounded-lg border border-gray-200'> 

                                        <div className='flex flex-col items-start text-left space-y-2 pb-2 border-b border-gray-400'>
                                            <h2 className='flex flex-row items-center space-x-2 subsection-title'>
                                                {/* <BsLadder className='mr-2' />  */}
                                                {t('organigram.employee.career')}
                                            </h2>
                                        </div>

                                        {employeeConfig?.careerFields?.map((field, index) => {
                                            return (
                                                <div class="flex flex-col items-start text-left space-y-1 px-4">
                                                    <div className='flex flex-row items-center'>
                                                            <label for={field?.name} className="form-label">
                                                                {t(field?.label)}
                                                                {(field?.required && !field?.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                                {(field?.required && field?.missing === true) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                                            </label>
                                                                {field?.tooltip && <Tooltip content={field?.tooltip} >
                                                                    <AiOutlineInfoCircle className='flex text-gray-500 hover:cursor-pointer ml-2' />  
                                                                </Tooltip>}
                                                    </div>
                                                    <div className='flex w-full'>

                                                        <div class="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 ">
                                                            <CreatableSelect
                                                                options={field?.name === 'role' ? roles : field?.name === "level" ? levels : contractTypes}
                                                                value={field?.name === 'role' ? roles.find((option) => option?.value === employee?.role) : field.name === "level" ? levels.find((option) => option?.value === employee?.level) : contractTypes.find((option) => option?.value === employee?.contractType)}    
                                                                id={field?.name}
                                                                className='w-full text-xs'
                                                                isSearchable
                                                                placeholder={t(field?.placeholder)}
                                                                styles={Configs.formDropdownStyle}
                                                                onChange={(e) => handleFieldChange(e, "career", field?.name)}
                                                                onCreateOption={(e) => createOption(e, field?.name)}
                                                                />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {/* Holidays */}
                                    <PersonalTimeOff isCreate={params ? false : true} isEdit={params ? true : false}
                                        employee={employee} setEmployee={handleFieldChange} employeeConfig={employeeConfig}
                                        />
                                        
                                        
                                    </React.Fragment>
                                    :
                                    null
                                }


                            </div>



                    </React.Fragment>
                        
        
                    {securityLevel?.includes(Configs.securityLevelHR) ? 
                        <div className='flex flex-col lg:flex-row items-center justify-end lg:space-x-4 mt-8'>

                            <button type="button" id="cancelButton" class="secondary-button" onClick={cancel}> {t('cancel')} </button>
                            {params ? 
                                <button id="editButton" className='save-button' onClick={validateInputsEdit}>{t('organigram.employee.edit.title')}</button>
                            :
                                <button id="createButton" className='save-button' onClick={validateInputsCreate}>{t('organigram.employee.create.title')}</button>
                            }

                        </div>
                    : 
                        null 
                    }
                
                    <SectionSeparator />
            
            </div>
            <div className='right-small-view'>
{/* 
                <UserListItem 
                    user={ (employeeName) ? (employeeName + " " + employeeSurname) : "Name Surname"}
                    area={ area ? area : "Area"}
                    subarea={subArea ? subArea : "Subarea"}
                    level={level ? level : "0"}
                    role={ role ? role : "Role"}
                    initials={(employeeName && employeeSurname) ? (employeeName?.slice(0,1) + employeeSurname?.slice(0,1)) : "NS"}/> */}
                <div className='flex flex-col space-y-4 text-left'>
                    {/* Title */}
                    {/* <div className='section-title'>
                        {t('organigram.employee.create.title')}
                    </div> */}


                    {/* Onboarding */}
                    {/* <PersonalOnboarding isCreate={params ? false : true} isEdit={params ? true : false}
                        companyID={companyID} employeeID={params ? params.employeeID : null}
                        /> */}


 


                    {/* Role History */}
                </div>


            </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default OrganigramEmployeesCreate;