import React from "react";



function SectionSeparator () {

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-row'>
        <div className="m-8 md:m-12 border-b border-gray-100 w-full"></div>
    </div>

  );
}

export default SectionSeparator;