// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Common
import Configs from '../../Configs';
import Header from '../../common/header';
import CalibrationHeader from './calibrationHeader';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';


// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common
import Tooltip from '../../common/support/tooltip';

// Icons
import { IoIosHelpCircleOutline } from 'react-icons/io';

function CalibrationHR () {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {token, companyID, employeeID} = useAuth();

    const [companyPreferences, setCompanyPreferences] = useState(sessionStorage.getItem('companyPreferences') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyPreferences'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null);

    const calibrationReportAux = sessionStorage.getItem('calibrationReportHistory') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('calibrationReportHistory'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const [calibrationReport, setCalibrationReport] = useState(calibrationReportAux);
    const securityLevel = sessionStorage.getItem('securityLevel') ? CryptoJS.AES.decrypt(sessionStorage.getItem('securityLevel'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;

    if (Configs.devEnvironment) console.log ("[calibrationHR.js][main()] calibration: ", calibrationReport);


  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------    

  useEffect (() => {     
    // Get information to populate dashboard
    if (!calibrationReport) getCalibrationsReport();
      }, []);
  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------    

    // Get 1on1 Summary
    async function getCalibrationsReport () {
        // fetch from server      
        fetch(Configs.platformGetCalibrationsReportAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          }
        })
            .then((response) => {
              if (response.status === 200) {
                response.json()
                .then ( body => {
                  if (body.length > 0) {
                    if (Configs.devEnvironment) console.log ("[calibrationHR.js][getCalibrationsReport()]:", body);
                        sessionStorage.setItem('calibrationReportHistory', CryptoJS.AES.encrypt(JSON.stringify(body),Configs.privateKey).toString());
                        setCalibrationReport(body);
                    } else {
                        setCalibrationReport(null);
                        sessionStorage.removeItem('calibrationReportHistory');
                    }
                  })
                .catch(error => {
                    console.error("[calibrationHR.js][getCalibrationsReport()] Error parsing response JSON", error);
                    sessionStorage.removeItem('calibrationReportHistory');
                    setCalibrationReport(null);
                });
              } else {
                //
                throw response;
              }
            })
      
            .catch((error) => {
              console.error("[calibrationHR.js][getCalibrationsReport()] Error fetching data: ", error);
            });
    }

  // ---------------------------------------------------------------------------------------------------
  // Page render methods
  // ---------------------------------------------------------------------------------------------------    
    return (
      <React.Fragment>
      <Header/>
          <Bot />

          <div className="main">
          <CalibrationHeader />
            
            <div className="flex flex-col m-2 lg:m-4">            



                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                      <h2 className="flex text-xl text-left mb-4 ">
                        {" "}
                        <div className='flex flex-row items-center md:flex-row justify-left'>
                          <Tooltip content={t('calibration.hr.company-status.description')}>
                            <div className='section-title'>
                            📈 {t('calibration.hr.company-status.title')}
                            </div>
                          </Tooltip>
                          <a href={companyPreferences?.personalization?.pulseEmailsHelpURL + "#calibration"} target="_blank" rel="noreferrer">
                          <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                           </a>  

                        </div>
                      </h2>
                      
                  </div>

                  <SectionSeparator />

                  {securityLevel?.includes(Configs.securityLevelHR) ? 

                    <React.Fragment>

                        {calibrationReport ? 

                              <React.Fragment>
                                <div className='flex flex-col'>
                                      <h2 className="m-2 text-xl">
                                          {t('calibration.hr.company-status')}
                                      </h2>

                                      <div className='flex flex-col'>
                                            <table className=" w-full mr-2 text-sm text-center mb-4">
                                              <thead>
                                              <tr className="border-b border-gray-200">
                                                  <th className=" p-2"> {t('area')} </th>
                                                  <th className=" p-2"> {t('subarea')} </th>
                                                  <th className=" p-2"> {t('manager')} </th>
                                                  <th className=" p-2"> {t('employee')} </th>
                                                  <th className=" p-2">{t('calibration.hr.last-calibration')}</th>

                                              </tr>
                                              </thead>
                                              <tbody>
                                                      {calibrationReport?.map((calibration, index) => (
                                                                <tr className='border-b border-gray-200 p-4'>
                                                                    <td className=" p-4 text-sm">
                                                                    {calibration?.area}
                                                                    </td>
                                                                    <td className=" p-4 text-sm">
                                                                    {calibration?.subarea}
                                                                    </td>
                                                                    <td className=" p-4 text-sm">
                                                                        {calibration?.managerID === 0 ?
                                                                          ""
                                                                        :
                                                                          calibration?.manager_employee
                                                                        }
                                                                    </td>
                                                                    <td className=" p-4 text-sm">
                                                                        {calibration?.employee_employee}
                                                                    </td>
                                                                    <td className="p-4 text-sm">
                                                                        
                                                                        { calibration.last_calibration === null ?
                                                                          
                                                                          null
                                                                          
                                                                          :

                                                                          new Date(calibration.last_calibration).getDate() + "/" + (new Date(calibration.last_calibration).getMonth() + 1) + "/" + new Date(calibration.last_calibration).getFullYear()
                                                                        }
                                                                    </td>
                                                              </tr> 
                                                      ))}
                                              </tbody>
                                          </table>            

                                      </div>
                                </div>
                              </React.Fragment>
                            : 
                                null
                          }
                    </React.Fragment>
            : 

              <div>
                  
              </div>
            
            }
            </div>
            </div>
      </React.Fragment>
    );
}

export default CalibrationHR;