// React
import React, { useState, useEffect, useRef } from 'react';

// Configs
import Configs from '../../Configs';

// ----------------------------------------------------------------------
const Tooltip = ({ children, content }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);

  // State to store tooltip dimensions
  const [tooltipDimensions, setTooltipDimensions] = useState(null);

  useEffect(() => {
    if (isTooltipVisible && tooltipRef.current) {
      
      const { innerWidth, innerHeight } = window;
      const rect = tooltipRef.current.getBoundingClientRect();

      let { width, height } = rect;
      
      let initialWidth = width;
      let initialHeight = height;
    
      if (Configs.devEnvironment) console.log (`1. Tooltip before: width: ${width}, innerWidth: ${innerWidth}, rect.x: ${rect.x}, rect.width: ${rect.width}, height: ${height}`);
      
      // Define aux variables to calculate the width of the tooltip checking both sides of the viewport
      var widthAuxLeft = width;
      var widthAuxRight = width;

      // Calculate adjustments for width overflowing left side
      if (rect.x < 0) {
        var widthAuxLeft = width  - (Math.abs(rect.x)) - 20; // 5px padding from the right edge of the viewport
        if (Configs.devEnvironment) console.log (`A Tooltip Left: width adjusted: ${widthAuxLeft}`);
      } 
      // Calculate adjustments for width overflowing right side
      if (rect.x > innerWidth) {
        var widthAuxRight = innerWidth.x - (rect.x - innerWidth) - 20; // 5px padding from the right edge of the viewport
        if (Configs.devEnvironment) console.log (`B Tooltip Right: width adjusted: ${widthAuxRight}`);
      }

      // Pick the smallest width
      if (widthAuxLeft && widthAuxRight) {
        width = Math.min(widthAuxLeft, widthAuxRight);
        if (Configs.devEnvironment) console.log (`2. Tooltip Final: width adjusted: ${width}`);
      }

      // New height adjusted after adjusting width if overflow-x solved
      if (initialWidth !== width ) height = rect.height * (initialWidth / width);
      if (Configs.devEnvironment) console.log (`3. Tooltip Final: height adjusted: ${height}`);


      // Calculate adjustments for height
      if (rect.bottom > innerHeight) {
        height = innerHeight - rect.top - 20; // 20px padding from the bottom edge of the viewport
      } else {
        
      }

      if (Configs.devEnvironment) console.log (`Tooltip after: width: ${width}, innerWidth: ${innerWidth}, rect.x: ${rect.x}, rect.width: ${rect.width}, widthAuxLeft: ${widthAuxLeft}, withAuxRight: ${widthAuxRight}, height: ${height}`);

        // Only update state if it has changed
      if (initialHeight !== height || initialWidth !== width) {
        setTooltipDimensions({ width: `${width}px`, height: `${height}px` });
      }
    }
  }, [isTooltipVisible, tooltipDimensions]);



  return (
    <div className="relative flex items-center group">
      <div
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        onFocus={() => setIsTooltipVisible(true)}
        onBlur={() => setIsTooltipVisible(false)}
        tabIndex="0" // Make the element focusable
        aria-haspopup="true"
        aria-expanded={isTooltipVisible}
        className=""
      >
        {children}
      </div>
      {isTooltipVisible && (
        <div 
        ref={tooltipRef}
        className="tooltip-content text-shrink"
        role="tooltip"
        style={{
           transitionDelay: '100ms', // Delay appearance of tooltip
           width: tooltipDimensions?.width ? tooltipDimensions?.width : '',
           height: tooltipDimensions?.height ? tooltipDimensions?.height : '',
           // overflow: 'auto', // Allow scroll if content overflows
           }} 
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;