// React
import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

//3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common and widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import Select from 'react-select'; 
import KPIStaticCard from '../../widgets/keyIndicatorItemStatic';
import DataTable from '../../widgets/dataTable';
import { useAuth } from '../../common/appContext';
import * as apiCalls from '../../common/apiCalls';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineDownload, AiOutlineFilter} from 'react-icons/ai';
import { useData } from '../../common/dataContext';

// ---------------------------------------------------------------------------------------------------
// PerformanceHR
// ---------------------------------------------------------------------------------------------------
function PerformanceHR () {
    const {t} = useTranslation();
    const navigate = useNavigate();

    // To pass location to the Header
    const location = useLocation();
    const [currentScreen, setCurrentScreen] = useState(location.pathname);

    const {token, companyID, employeeID, securityLevel} = useAuth();
    const {companyPreferences} = useData();
    const {toasterMessage, setToasterMessage} = useData();
    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // Live performance review
    const performanceReviewStatusAux = sessionStorage.getItem('performanceReviewCompanyStatus') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('performanceReviewCompanyStatus'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const [performanceReviewStatus, setPerformanceReviewStatus] = useState(performanceReviewStatusAux);

    // Reporting data
    const [performanceCampaignReportingInfo, setPerformanceCampaignReportingInfo] = useState(null);
    const [defaultperformanceCampaignReportingInfo, setDefaultPerformanceCampaignReportingInfo] = useState(null);
    const [valuesReportingInfo, setValuesReportingInfo] = useState(null);
    // const [valuesManagerReportingInfo, setValuesManagerReportingInfo] = useState(null);
    // const [valuesTeamReportingInfo, setValuesTeamReportingInfo] = useState(null);
    const [defaultValuesReportingInfo, setDefaultValuesReportingInfo] = useState(null);
    const [responseRate, setResponseRate] = useState(null);
    const [globalScore, setGlobalScore] = useState(null);
    const [globalManagerScore, setGlobalManagerScore] = useState(null);
    const [globalEmployeeScore, setGlobalEmployeeScore] = useState(null);
    const [globalTeamScore, setGlobalTeamScore] = useState(null);
    
      // Global scores - to be used in the KPIs and for the benchmark
      const [defaultResponseRate, setDefaultResponseRate] = useState(null);
      const [defaultGlobalScore, setDefaultGlobalScore] = useState(null);
      const [defaultGlobalManagerScore, setDefaultGlobalManagerScore] = useState(null);
      const [defaultGlobalEmployeeScore, setDefaultGlobalEmployeeScore] = useState(null);
      const [defaultGlobalTeamScore, setDefaultGlobalTeamScore] = useState(null);
      
    // Filters and sorting
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [selectedSubarea, setSelectedSubarea] = useState(null);
    const [selectedManager, setSelectedManager] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const [selectedValuesSummary, setSelectedValuesSummary] = useState(null);
    
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [subareaOptions, setSubareaOptions] = useState([]);
    const [managerOptions, setManagerOptions] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);
      
    // Toaster and Loader
    const [isStatusByCompanyLoading, setIsStatusByCompanyLoading] = useState(false);
    const [isCampaignsListLoading, setIsCampaignsListLoading] = useState(false);
    const [isCampaignReportingLoading, setIsCampaignReportingLoading] = useState(false);
      
    if (Configs.devEnvironment) console.log ("[performanceHR.js][main()] performanceReviewStatus: ", performanceReviewStatus);
    if (Configs.devEnvironment) console.log ("[performanceHR.js][main()] companyPreferences: ", companyPreferences);

    // Tables columns for TableData Widget
    let columnNamesEmployeesTable = [
      {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
      {type:'string',id: 'subare',value: 'subarea', label: t('subarea'), sort: null, filter: null},
      {type:'string',id: 'employee',value: 'employee', label: t('employee'), sort: null, filter: null},
      {type:'string',id: 'manager', value: 'manager', label: t('manager'), sort: null, filter: null},
      {type:'number',id: 'score', value: 'overallScore', label: t('performance.hr-dashboard.overall-score'), sort: null, filter: null},
      {type:'number',id: 'managerScore',value: 'managerScore', label: t('performance.hr-dashboard.manager-score'), sort: null, filter: null},
      {type:'number',id: 'selfScore',value: 'employeeScore', label: t('performance.hr-dashboard.self-score'), sort: null, filter: null},
      {type:'number',id: 'employeeToManagerScore',value: 'teamScore', label: t('performance.hr-dashboard.employee-manager-score'), sort: null, filter: null},
    ];

    let columnNamesValuesTable = [
      {type:'string',id: 'value', value: 'value', label: companyPreferences?.personalization?.valuesBehaviours === "behaviours" ?  t('behaviours') : t('values'), sort: null, filter: null},
      {type:'number',id: 'globalScore', value: 'globalScore', label: t('performance.hr-dashboard.overall-score'), sort: null, filter: null},
      {type:'number',id: 'managerScore',value: 'managerScore', label: t('performance.hr-dashboard.manager-score'), sort: null, filter: null},
      {type:'number',id: 'selfScore',value: 'employeeScore', label: t('performance.hr-dashboard.self-score'), sort: null, filter: null},
      {type:'number',id: 'teamScore',value: 'teamScore', label: t('performance.hr-dashboard.employee-manager-score'), sort: null, filter: null},
    ];

    // let columnNamesManagerValuesTable = [
    //   {type:'string',id: 'manager', value: 'manager', label: t('manager'), sort: null, filter: null},
    //   {type:'string',id: 'value', value: 'value', label: companyPreferences?.personalization?.valuesBehaviours === "behaviours" ?  t('behaviours') : t('values'), sort: null, filter: null},
    //   {type:'number',id: 'globalScore', value: 'globalScore', label: t('performance.hr-dashboard.overall-score'), sort: null, filter: null},
    //   {type:'number',id: 'managerScore',value: 'managerScore', label: t('performance.hr-dashboard.manager-score'), sort: null, filter: null},
    //   {type:'number',id: 'selfScore',value: 'employeeScore', label: t('performance.hr-dashboard.self-score'), sort: null, filter: null},
    //   {type:'number',id: 'teamScore',value: 'teamScore', label: t('performance.hr-dashboard.employee-manager-score'), sort: null, filter: null},
    // ];

    // let columnNamesTeamValuesTable = [
    //   {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
    //   {type:'string',id: 'value', value: 'value', label: companyPreferences?.personalization?.valuesBehaviours === "behaviours" ?  t('behaviours') : t('values'), sort: null, filter: null},
    //   {type:'number',id: 'globalScore', value: 'globalScore', label: t('performance.hr-dashboard.overall-score'), sort: null, filter: null},
    //   {type:'number',id: 'managerScore',value: 'managerScore', label: t('performance.hr-dashboard.manager-score'), sort: null, filter: null},
    //   {type:'number',id: 'selfScore',value: 'employeeScore', label: t('performance.hr-dashboard.self-score'), sort: null, filter: null},
    //   {type:'number',id: 'teamScore',value: 'teamScore', label: t('performance.hr-dashboard.employee-manager-score'), sort: null, filter: null},
    // ];

    let livePerfReviewStatusColumns = [
      {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
      {type:'string',id: 'subarea', value: 'subarea', label: t('subarea'), sort: null, filter: null},
      {type: 'string', id: 'manager', value: 'manager', label: t('manager'), sort: null, filter: null},
      {type:'string',id: 'employee', value: 'employee', label: t('employee'), sort: null, filter: null},
      {type:'string',id: 'self', value: 'self', label: t('performance.home.title.self'), sort: null, filter: null},
      {type:'string',id: 'employeeManagerStrength', value: 'employeeManagerStrength', label: t('performance.home.title.employee-manager'), sort: null, filter: null},
      {type:'string',id: 'managerEmployeeStrength', value: 'managerEmployeeStrength', label: t('performance.home.title.manager-employee'), sort: null, filter: null},
    ];


  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------    

  useEffect (() => {     
    // Get information to populate dashboard
    if (!performanceReviewStatus) getPerformanceReviewStatusByCompany();

    getAllPerformanceCampaigns();

      }, []);
  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------    
    async function getPerformanceReviewStatusByCompany () {
      setIsStatusByCompanyLoading(true);

      try {
        const result = await apiCalls.getPerformanceReviewStatusByCompany(employeeID, companyID, token);

        setIsStatusByCompanyLoading(false);

        if (result.status === 500) {
          setPerformanceReviewStatus(null);
          sessionStorage.removeItem('performanceReviewCompanyStatus');
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        } else if (result.status === 403) {
          // Force logout
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
        } else if (result.status === 401) {
          // Force logout
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
        } else if (result.status === 400) {
          setPerformanceReviewStatus(null);
          sessionStorage.removeItem('performanceReviewCompanyStatus');
        } else {
          // 200 or 204
          if (result?.length > 0) {
            // Save to session storage
            if (Configs.devEnvironment) console.log ("[performanceHR.js][getPerformanceReviewStatusByCompany()]:", result);
              
            sessionStorage.setItem('performanceReviewCompanyStatus', CryptoJS.AES.encrypt(JSON.stringify(result),Configs.privateKey).toString());
            setPerformanceReviewStatus(result);
          } else {
            // Empty result
            setPerformanceReviewStatus(null);
            sessionStorage.removeItem('performanceReviewCompanyStatus');
          }
        }

      } catch (error) {
        console.error("[performanceHR.js][getPerformanceReviewStatusByCompany()]: Error fetching data: ", error);
        setPerformanceReviewStatus(null);
        setIsStatusByCompanyLoading(false);
        sessionStorage.removeItem('performanceReviewCompanyStatus');
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
      }
    }

    async function getAllPerformanceCampaigns () {
      // set Loader
      setIsCampaignsListLoading(true);

      // fetch from server      
      fetch(Configs.platformGetPerformanceReviewCampaignsByCompanyAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        }})
          .then((response) => {
            setIsCampaignsListLoading(false);

            if (response.status === 200) {
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log("[performanceHR.js][getAllPerformanceCampaigns()] Response from request: ", body);
                  // getPerformanceCampaignReportingData(body[0]._id);
                  populateCampaignsOption(body);
                })
              .catch(error => {
                console.error("[performanceHR.js][getAllPerformanceCampaigns()] Error parsing response JSON", error);
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
              });
            } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                    sessionStorage.clear();
                    window.location.reload();
            } else if (response.status === 404) {
                // No results
                // setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else {
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
          })
    
          .catch((error) => {
            console.error("[performanceHR.js][getAllPerformanceCampaigns()] Error fetching data: ", error);
            setIsCampaignsListLoading(false);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          });
  }


    async function getPerformanceCampaignReportingData (campaignIDInput) {
      // set Loader
      setIsCampaignReportingLoading(true);

      // fetch from server      
      fetch(Configs.platformGetPerformanceReviewReportingDataPerCampaignAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignIDInput, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        }})
          .then((response) => {
            setIsCampaignReportingLoading(false);

            if (response.status === 200) {
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log("[performanceHR.js][getPerformanceCampaignReportingData()] Response from request: ", body);
                  populateAreaSubAreaManagerOptions(body);
                  populatePerformanceCampaignReportingData(body);
                })
              .catch(error => {
                console.error("[performanceHR.js][getPerformanceCampaignReportingData()] Error parsing response JSON", error);
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
              });
            } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                    sessionStorage.clear();
                    window.location.reload();
            } else if (response.status === 404) {
                // No results
                // setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else {
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
          })
    
          .catch((error) => {
            console.error("[performanceHR.js][getPerformanceCampaignReportingData()] Error fetching data: ", error);
            setIsCampaignReportingLoading(false);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          });
  }

  async function downloadCampaign (e) {
    e.preventDefault();
  
    // fetch from server      
    fetch(Configs.platformGetCSVDownloadPerformanceCampaignAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + selectedCampaign , {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      }})
        .then((response) => {
          if (response.status === 200) {
              return response.blob();
          } else if (response.status === 400) {
            // Something went wrong - inform user
            setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
  
          } else if (response.status === 401) {
              // Force logout
              setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
          } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
          } else {
            // There is an error - delete info
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
            throw response;
          }
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = companyInfo?.companyName + ' - ' + t('Performance') + ' - ' + campaignOptions?.find(campaign => campaign.value === selectedCampaign).name + '.csv';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
        })
  
        .catch((error) => {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
          console.error("[pulseHome.js][getPulseKeyIndicators()] Error fetching data: ", error);
        });
  }

// ---------------------------------------------------------------------------------------------------------
// Populating data
// ---------------------------------------------------------------------------------------------------------
  async function populatePerformanceCampaignReportingData (body) {
    if (body) {
      if (Configs.devEnvironment) console.log("[performanceHR.js][populatePerformanceCampaignReportingData()] body: ", body);
      reCalculateKPIs(body, "default");
    }
  }

  async function populateCampaignsOption (body) {
    if(body) {
      // Set campaigns options
      var auxArray = [];

      body?.forEach(campaign => {
        auxArray.push({value: campaign._id, label: campaign.name + ": " + campaign.description});
      });

      setCampaignOptions([...auxArray]);
    }
  }
  
  async function populateAreaSubAreaManagerOptions (body) {
    if(body) {
      var auxArrayArea = [];
      var auxArraySubarea = [];
      var auxArrayManager = [];
      var auxArrayEmployee = [];

      // Populate aux arrays with the full info
      body?.forEach(performanceReview => {
        // Add area, subarea, or manager
        auxArrayArea.push(performanceReview?.area);
        auxArraySubarea.push(performanceReview?.subarea);
        if (performanceReview?.managerID !== 0 && performanceReview?.managerID !== null) auxArrayManager.push(performanceReview?.managerID);
        auxArrayEmployee.push(performanceReview?.employeeID);
      });

      // Remove duplicates
      auxArrayArea = [...new Set(auxArrayArea)];
      auxArraySubarea = [...new Set(auxArraySubarea)];
      auxArrayManager = [...new Set(auxArrayManager)];
      auxArrayEmployee = [...new Set(auxArrayEmployee)];

      // Populate aux arrays without duplicates
      var auxArrayArea2 = [];
      var auxArraySubarea2 = [];
      var auxArrayManager2 = [];
      var auxArrayEmployee2 = [];

      auxArrayArea.forEach(area => {
        auxArrayArea2.push({value: area, label: area});
      });
      auxArraySubarea.forEach(subarea => {
        auxArraySubarea2.push({value: subarea, label: subarea});
      });
      auxArrayManager.forEach(manager => {
        auxArrayManager2.push({value: manager, label: body.find(performanceReview => performanceReview?.managerID === manager)?.manager});
      });
      auxArrayEmployee.forEach(employee => {
        auxArrayEmployee2.push({value: employee, label: body.find(performanceReview => performanceReview?.employeeID === employee)?.employee});
      });

      // Sort alphabetically
      auxArrayArea2.sort((a, b) => (a.label > b.label) ? 1 : -1);
      auxArraySubarea2.sort((a, b) => (a.label > b.label) ? 1 : -1);
      auxArrayManager2.sort((a, b) => (a.label > b.label) ? 1 : -1);
      auxArrayEmployee2.sort((a, b) => (a.label > b.label) ? 1 : -1);

      // Set state
      setAreaOptions([...auxArrayArea2]);
      setSubareaOptions([...auxArraySubarea2]);
      setManagerOptions([...auxArrayManager2]);
      setEmployeeOptions([...auxArrayEmployee2]);
    }
  }

  

  // Transform Values input into array readable by tableData
  function transformValuesArray (array) {
    if (Configs.devEnvironment) console.log("[performanceHR.js][transformValuesArray()] array: ", array);

    var auxArray = [];

    // Review entire array, and extract only the values
    array?.forEach(value => {
      auxArray.push(value?.value);
    });

    // Remove duplicates
    auxArray = [...new Set(auxArray)];

    // Order alphabetically
    auxArray.sort((a, b) => (a > b) ? 1 : -1);

    // Iterate through values, and create the final array including: self score, manager score, team score and global score
    var finalArray = [];
    auxArray?.forEach(value => {
      var selfScore = 0;
      var selfScoreCount = 0;
      var managerScore = 0;
      var managerScoreCount = 0;
      var teamScore = 0;
      var teamScoreCount = 0;
      var globalScore = 0;

      // Compare with each input in the input Array
      array?.forEach(input => {
        // Take only values for that value
        if(input?.value === value) {
          switch (input?.type) {
            case "selfScore":
              selfScore += input?.score ? Number(input?.score) : 0;
              selfScoreCount++;
              break;
            case "managerScore":
              managerScore += input?.score ? Number(input?.score) : 0;
              managerScoreCount++;
              break;
            case "teamScore":
              if (Configs.devEnvironment) console.log("[performanceHR.js][transformValuesArray()] TeamScore - 1. input: ", input);
              teamScore += input?.score ? Number(input?.score) : 0;
              teamScoreCount++;
              if (Configs.devEnvironment) console.log("[performanceHR.js][transformValuesArray()] TeamScore - 2. teamScore: ", teamScore, teamScoreCount);

              break;
            default:
              break;
          }
        }
      })

      // Calculate scores
      selfScore = isNaN((selfScore / selfScoreCount)) ? 0 : (selfScore / selfScoreCount);
      managerScore = isNaN((managerScore / managerScoreCount)) ? 0 : (managerScore / managerScoreCount);
      teamScore = isNaN((teamScore / teamScoreCount)) ? 0 : (teamScore / teamScoreCount);
      var globalScoreCount = isNaN((selfScoreCount > 0 ? 1 : 0) + (managerScoreCount > 0 ? 1 : 0) + (teamScoreCount > 0 ? 1 : 0)) ? 0 : ((selfScoreCount > 0 ? 1 : 0) + (managerScoreCount > 0 ? 1 : 0) + (teamScoreCount > 0 ? 1 : 0));
      globalScore = (isNaN(((selfScore + managerScore  + teamScore)/ globalScoreCount))) ? 0 : ((selfScore + managerScore  + teamScore)/ globalScoreCount);
      
      if (Configs.devEnvironment) console.log("[performanceHR.js][transformValuesArray()] Scores: ", selfScore, managerScore, teamScore, globalScoreCount, globalScore);

      // Insert object
      finalArray.push({id:value, label:value, value: value, selfScore: selfScore, managerScore: managerScore, teamScore: teamScore, globalScore: globalScore});

    });

    // Log result
    if (Configs.devEnvironment) console.log("[performanceHR.js][transformValuesArray()] finalArray: ", finalArray);

    // Return final array
    return finalArray;
  }

  // Transform Values input into array readable by tableData
  function transformEmployeeValuesArray (array) {
    if (Configs.devEnvironment) console.log("[performanceHR.js][transformEmployeeValuesArray()] array: ", array);

    var auxArray = [];

    // Review entire array, and extract only the values
    array?.forEach(value => {
      auxArray.push(value?.value);
    });

    // Remove duplicates
    auxArray = [...new Set(auxArray)];

    // Order alphabetically
    auxArray.sort((a, b) => (a > b) ? 1 : -1);

    // Iterate through values, and create the final array including: self score, manager score, team score and global score
    var finalArray = [];
    auxArray?.forEach(value => {
      var selfScore = 0;
      var selfScoreCount = 0;
      var managerScore = 0;
      var managerScoreCount = 0;
      var teamScore = 0;
      var teamScoreCount = 0;
      var globalScore = 0;

      // Compare with each input in the input Array
      array?.forEach(input => {
        // Take only values for that value
        if(input?.value === value) {
          switch (input?.type) {
            case "selfScore":
              selfScore += input?.score ? Number(input?.score) : 0;
              selfScoreCount++;
              break;
            case "managerScore":
              managerScore += input?.score ? Number(input?.score) : 0;
              managerScoreCount++;
              break;
            case "teamScore":
              if (Configs.devEnvironment) console.log("[performanceHR.js][transformEmployeeValuesArray()] TeamScore - 1. input: ", input);
              teamScore += input?.score ? Number(input?.score) : 0;
              teamScoreCount++;
              if (Configs.devEnvironment) console.log("[performanceHR.js][transformEmployeeValuesArray()] TeamScore - 2. teamScore: ", teamScore, teamScoreCount);

              break;
            default:
              break;
          }
        }
      })

      // Calculate scores
      selfScore = isNaN((selfScore / selfScoreCount)) ? 0 : (selfScore / selfScoreCount);
      managerScore = isNaN((managerScore / managerScoreCount)) ? 0 : (managerScore / managerScoreCount);
      teamScore = isNaN((teamScore / teamScoreCount)) ? 0 : (teamScore / teamScoreCount);
      var globalScoreCount = isNaN((selfScoreCount > 0 ? 1 : 0) + (managerScoreCount > 0 ? 1 : 0) + (teamScoreCount > 0 ? 1 : 0)) ? 0 : ((selfScoreCount > 0 ? 1 : 0) + (managerScoreCount > 0 ? 1 : 0) + (teamScoreCount > 0 ? 1 : 0));
      globalScore = (isNaN(((selfScore + managerScore  + teamScore)/ globalScoreCount))) ? 0 : ((selfScore + managerScore  + teamScore)/ globalScoreCount);
      
      if (Configs.devEnvironment) console.log("[performanceHR.js][transformEmployeeValuesArray()] Scores: ", selfScore, managerScore, teamScore, globalScoreCount, globalScore);

      // Insert object
      finalArray.push({id:value, label:value, value: value, selfScore: selfScore, managerScore: managerScore, teamScore: teamScore, globalScore: globalScore});

    });

    // Log result
    if (Configs.devEnvironment) console.log("[performanceHR.js][transformEmployeeValuesArray()] finalArray: ", finalArray);

    // Return final array
    return finalArray;
  }


  /**
     * 
     * @param {*} inputArray - array with all performance campaigns
     * @param {*} type - "default" = first time; or "refresh" = when a filter is changed
     */
   async function reCalculateKPIs (inputArray, type) {
    if (inputArray) {
      if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] input: ", inputArray);

      // Remove weird manager names
      inputArray?.forEach((performanceReview, index) => {
        if (performanceReview?.managerID === 0 || performanceReview?.managerID === null) inputArray[index].manager = " - ";
      });

      // In this part we will calculate the scores for each employee
      var auxArray = inputArray;
      var auxArrayValues = [];
      var numOfResponses = 0;
      const numOfEmployees = auxArray?.length;
      var globalScore = 0;
      var globalNumOfScores = 0;
      var globalScoreSelf = 0;
      var globalNumOfScoresSelf = 0;
      var globalScoreManager = 0;
      var globalNumOfScoresManager = 0;
      var globalScoreEmployeeToManager = 0;
      var globalNumOfScoresEmployeeToManager = 0;

      // SELF ASSESSED SCORE
      // Iterate through each of the employees = performance reviews of the campaign
      inputArray?.forEach((performanceReview, index) => {
        // Aux variable to calculate
        var scoreAux = 0;
        var scoreAuxSelf = 0;
        var scoreAuxManager = 0;
        var scoreAuxEmployeeToManager = 0;
        // To create a view of values per employee
        var auxArraySelfValues = [];

        // SELF ASSESSED SCORE
        // Calculate the score for each value in the selfData array
        if (performanceReview?.self[0] === "completed" || performanceReview?.self[0] === "sent") {

          performanceReview?.selfData[0]?.values?.forEach(value => {
            // Calculate Score
            scoreAuxSelf += value?.selected ? parseInt(value?.selected) : 0;

            // Add score to each value in the array
            auxArrayValues.push({
              type: "selfScore",
              value: value?.value,
              description: value?.description,
              score: value?.selected ? parseInt(value?.selected) : 0
            });

            // Add score to self values array
            auxArraySelfValues.push({
              type: "selfScore",
              value: value?.value,
              description: value?.description,
              score: value?.selected ? parseInt(value?.selected) : 0
            });

          });

          scoreAuxSelf = (performanceReview?.selfData[0]?.values?.length !== 0 && performanceReview?.selfData[0]?.values?.length ) ? scoreAuxSelf / performanceReview?.selfData[0]?.values?.length : 0;
          if (scoreAuxSelf > 0) globalNumOfScoresSelf++;
        }

        // SCORE THAT THE MANAGER HAS RATED TO THE EMPLOYEE
        // Calculate the score for each value in the managerEmployeeData
        if (performanceReview?.managerEmployeeStrength[0] === "completed" || performanceReview?.managerEmployeeStrength[0] === "sent") {

          performanceReview?.managerEmployeeData[0]?.values?.forEach(value => {
            // Calculate Score
            scoreAuxManager += parseInt(value?.selected);

            // Add score to each value in the array
            auxArrayValues.push({
              type: "managerScore",
              value: value?.value,
              description: value?.description,
              score: value?.selected ? parseInt(value?.selected) : 0
            });

            // Add score to self values array
            auxArraySelfValues.push({
              type: "managerScore",
              value: value?.value,
              description: value?.description,
              score: value?.selected ? parseInt(value?.selected) : 0
            });
          });

          scoreAuxManager = (performanceReview?.managerEmployeeData[0]?.values?.length !== 0 && performanceReview?.managerEmployeeData[0]?.values?.length) ? scoreAuxManager / performanceReview?.managerEmployeeData[0]?.values?.length : 0;
          if (scoreAuxManager > 0) globalNumOfScoresManager++;

        }

        // SCORE THAT EMPLOYEES RATE THE MANAGER - SEVERAL EMPLOYEES FOR 1 MANAGER
        // Calculate the score for each feedback provider, then average it
        if (performanceReview?.feedbacksOfEmployee?.length > 0) {
          var countOfFeedbackProviders = 0;
          var scoreOfFeedbackProviders = 0;
          // review all feedbacks
          performanceReview?.feedbacksOfEmployee?.forEach((feedback) => {
              // Variables to calculate score
                var scoreOfProvider = 0;
                var countOfValues = 0;

                // If completed, add score to calculation
                if (feedback?.strengthFeedback?.status === "completed" || feedback?.strengthFeedback?.status === "sent") {
                  if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] TeamScore - 1.Completed feedback: ", feedback);                 

                  var scoreOfFeedback = 0;
                  // Add to count of feedback providers
                  countOfFeedbackProviders++;

                  // Calculate score for each value
                  feedback?.strengthFeedback?.values?.forEach(value => {
                    scoreOfFeedback += parseInt(value?.selected);
                    // scoreAuxEmployeeToManager += parseInt(value?.selected);
                    countOfValues++;

                    // Add score to each value in the array
                    auxArrayValues.push({
                      type: "teamScore",
                      value: value?.value,
                      description: value?.description,
                      score: value?.selected ? parseInt(value?.selected) : 0
                    });

                    // Add score to self values array
                    auxArraySelfValues.push({
                      type: "teamScore",
                      value: value?.value,
                      description: value?.description,
                      score: value?.selected ? parseInt(value?.selected) : 0
                    });
                  });

                  // Calculate score
                  scoreOfProvider = countOfValues > 0 ? scoreOfFeedback / countOfValues : 0;
                  
                  // Add score to total score
                  scoreOfFeedbackProviders += Number(scoreOfProvider);

                  // Logging
                  if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] TeamScore - 3.scoreOfFeedback: ", scoreOfFeedback);
                  if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] TeamScore - 3.countOfValues: ", countOfValues);
                  if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] TeamScore - 3.scoreOfProvider = scoreOfFeedbackProviders: ", scoreOfProvider, scoreOfFeedbackProviders);

                  // Total score of feedback providers
                  if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] TeamScore - 4.countOfFeedbackProviders: ", countOfFeedbackProviders);
                }
          });
          
          // Total score of feedback providers
          //if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] HERE 4 - scoreOfFeedbackProviders: ", scoreOfFeedbackProviders);
          //if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] HERE 4 - countOfFeedbackProviders: ", countOfFeedbackProviders);
          scoreAuxEmployeeToManager = (countOfFeedbackProviders > 0) ? (scoreOfFeedbackProviders / countOfFeedbackProviders) : 0;
          if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] TeamScore - 4.scoreAuxEmployeeToManager: ", scoreAuxEmployeeToManager);
        }

          if (countOfFeedbackProviders > 0) globalNumOfScoresEmployeeToManager++;


        // Self scores
        auxArray[index].selfScore = !isNaN(scoreAuxSelf)? scoreAuxSelf : 0;
        auxArray[index].managerScore = !isNaN(scoreAuxManager) ? scoreAuxManager : 0;
        auxArray[index].employeeToManagerScore = !isNaN(scoreAuxEmployeeToManager) ? scoreAuxEmployeeToManager : 0;
        auxArray[index].values = transformEmployeeValuesArray(auxArraySelfValues);
        
        // Total score
        var totalScores = (auxArray[index].selfScore !== 0 ? 1 : 0) + (auxArray[index].managerScore !== 0 ? 1 : 0) + (auxArray[index].employeeToManagerScore !== 0 ? 1 : 0);
        auxArray[index].score = (!isNaN(scoreAux) && totalScores !== 0) ? ((auxArray[index].selfScore + auxArray[index].managerScore + auxArray[index].employeeToManagerScore) / totalScores) : 0;
        if (totalScores !== 0) globalNumOfScores++;

        // Add to global scores only if the employee has score > 0
        if (auxArray[index].score > 0) globalScore += auxArray[index].score;
        if (auxArray[index].selfScore > 0) globalScoreSelf += auxArray[index].selfScore;
        if (auxArray[index].managerScore > 0) globalScoreManager += auxArray[index].managerScore;
        if (auxArray[index].employeeToManagerScore > 0)globalScoreEmployeeToManager += auxArray[index].employeeToManagerScore;
        
        if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] auxArray[index]: ", auxArray[index]);

        // Calculate the number of responses for Response Rate
        if ((auxArray[index].self[0] === "completed" || auxArray[index].self[0] === "sent") &&  (performanceReview?.managerEmployeeStrength[0] === "completed" || performanceReview?.managerEmployeeStrength[0] === "sent") && performanceReview.managerID !== 0) numOfResponses++;
      });

      if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] globalNumOfScore: ", globalNumOfScores, " - globalNumOFScoresSelf: ", globalNumOfScoresSelf, " - globalNumOfScoresManager: ", globalNumOfScoresManager, " - globalNumOfScoresEmployeeToManager: ", globalNumOfScoresEmployeeToManager);

      // Set both initial state and default state
      setPerformanceCampaignReportingInfo([...auxArray]);

      if (Configs.devEnvironment) console.log("[performanceHR.js][reCalculateKPIs()] RAW: ", auxArray);
      
      // Update response rate
      setResponseRate(((numOfResponses / numOfEmployees)*100).toPrecision(2));
      
      // Update scores
      setGlobalScore((globalScore / globalNumOfScores).toPrecision(2));
      setGlobalEmployeeScore((globalScoreSelf / globalNumOfScoresSelf).toPrecision(2));
      setGlobalManagerScore((globalScoreManager / globalNumOfScoresManager).toPrecision(2));
      setGlobalTeamScore((globalNumOfScoresEmployeeToManager && globalScoreEmployeeToManager) ? (globalScoreEmployeeToManager / globalNumOfScoresEmployeeToManager).toPrecision(2) : " - ");
      // Update values part
      setValuesReportingInfo(transformValuesArray(auxArrayValues));

      // Default state - all company
      if (type === "default") {
          setDefaultPerformanceCampaignReportingInfo([...auxArray]);
          setDefaultResponseRate(((numOfResponses / numOfEmployees)*100).toPrecision(2));
          setDefaultGlobalScore((globalScore / globalNumOfScores).toPrecision(2));
          setDefaultGlobalEmployeeScore((globalScoreSelf / globalNumOfScoresSelf).toPrecision(2));
          setDefaultGlobalManagerScore((globalScoreManager / globalNumOfScoresManager).toPrecision(2));
          setDefaultGlobalTeamScore((globalNumOfScoresEmployeeToManager && globalScoreEmployeeToManager) ? (globalScoreEmployeeToManager / globalNumOfScoresEmployeeToManager).toPrecision(2) : " - ");
          setDefaultValuesReportingInfo(transformValuesArray(auxArrayValues));
      }
    }
  }

// ---------------------------------------------------------------------------------------------------
// Filtering and sorting methods
// ---------------------------------------------------------------------------------------------------
    async function handleCampaignSelectorChange (e) {
      // Set the selected manager information in the array of managers
      if (Configs.devEnvironment) console.log("[performanceHR.js][handleCampaignSelectorChange()]:", e.value);
      
      // API request to populate the rest of the fields
      getPerformanceCampaignReportingData(e.value);

      // Populate selected campaign for UX
      setSelectedCampaign(e.value);

      // Clean up all rest of filters
      setSelectedArea(null);
      setSelectedSubarea(null);
      setSelectedManager(null);
    
    }

    async function handleAreaSelectorChange (e) {
      if (Configs.devEnvironment) console.log("[performanceHR.js][handleAreaSelectorChange()]:", e);
      // Update state
      setSelectedArea(e);

      // If empty set default
      if (e?.length === 0) {
        setSelectedArea(null);
      } else {
        setSelectedArea(e);
      }

      // Filter data
      updateFilteredData("area", e);
    }

    async function handleSubareaSelectorChange (e) {
      if (Configs.devEnvironment) console.log("[performanceHR.js][handleSubareaSelectorChange()]:", e);

      // Update state
      setSelectedSubarea(e);

      // If empty set default
      if (e?.length === 0) {
        setSelectedSubarea(null);
      } else {
        setSelectedSubarea(e);
      }

      // Filter data
      updateFilteredData("subarea", e);
    }

    async function handleManagerSelectorChange (e) {
      if (Configs.devEnvironment) console.log("[performanceHR.js][handleManagerSelectorChange()]:", e);

      // Update state
      setSelectedManager(e);

      // If empty set default
      if (e?.length === 0) {
        setSelectedManager(null);
      } else {
        setSelectedManager(e);
      }

      // Filter data
      updateFilteredData("manager", e);
    }

    async function handleEmployeeSelectorChange (e) {
      if (Configs.devEnvironment) console.log("[performanceHR.js][handleEmployeeSelectorChange()]:", e);

      // Update state
      setSelectedEmployee(e);

      // If empty set default
      if (e?.length === 0) {
        setSelectedEmployee(null);
      } else {
        setSelectedEmployee(e);
      }

      // Filter data
      updateFilteredData("employee", e);
    }


    async function updateFilteredData (filter, value) {
      // Loading state
      setIsCampaignReportingLoading(true);

      // Aux data - base on default
      var auxArray = defaultperformanceCampaignReportingInfo;

      // If area is filtered, filter data
      if (selectedArea?.length > 0 && filter !== "area") {
        // Aux data
        var auxArray2 = [];

        // Translate filter array to strings
        selectedArea?.forEach(filter => {
          auxArray2.push(filter?.value);
        });

        // Filter data
        auxArray = auxArray.filter(item => auxArray2.includes(item.area));
      }

      // If subarea is filtered, filter data
      if (selectedSubarea?.length > 0 && filter !== "subarea") {
        // Aux data
        var auxArray2 = [];

        // Translate filter array to strings
        selectedSubarea?.forEach(filter => {
          auxArray2.push(filter?.value);
        });

        // Filter data
        auxArray = auxArray.filter(item => auxArray2.includes(item.subarea));
      }

      // If manager is filtered, filter data
      if (selectedManager?.length > 0 && filter !== "manager") {
        // Aux data
        var auxArray2 = [];

        // Translate filter array to strings
        selectedManager?.forEach(filter => {
          auxArray2.push(filter?.value);
        });

        // Filter data
        auxArray = auxArray.filter(item => auxArray2.includes(item.managerID));
      }

      // Employee is filtered, filter data
      if (selectedEmployee?.length > 0 && filter !== "employee") {
        // Aux data
        var auxArray2 = [];

        // Translate filter array to strings
        selectedEmployee?.forEach(filter => {
          auxArray2.push(filter?.value);
        });

        // Filter data
        auxArray = auxArray.filter(item => auxArray2.includes(item.employeeID));
      }

      // If filter was selected with some value
      if (value.length > 0) {

          // Update filtered data depending on the filter
          switch (filter) {
            case "area":
              // Aux data
              var auxArray2 = [];

              // Translate filter array to strings
              value?.forEach(filter => {
                auxArray2.push(filter?.value);
              });

              // Filter data
              auxArray = auxArray?.filter(item => auxArray2.includes(item.area));

              break;
            case "subarea":
              // Aux data
              var auxArray2 = [];

              // Translate filter array to strings
              value?.forEach(filter => {
                auxArray2.push(filter?.value);
              });

              // Filter data
              auxArray = auxArray?.filter(item => auxArray2.includes(item.subarea));
              break;

            case "manager":
              // Aux data
              var auxArray2 = [];

              // Translate filter array to strings
              value?.forEach(filter => {
                auxArray2.push(filter?.value);
              });

              // Filter data
              auxArray = auxArray?.filter(item => auxArray2.includes(item.managerID));

              // Update state
              setPerformanceCampaignReportingInfo([...auxArray]);
              reCalculateKPIs(auxArray);
              break;

            case "employee":
              // Aux data
              var auxArray2 = [];

              // Translate filter array to strings
              value?.forEach(filter => {
                auxArray2.push(filter?.value);
              });

              // Filter data
              auxArray = auxArray?.filter(item => auxArray2.includes(item.employeeID));

              break;

            default:

              break;
            }

      } 

      // Update state
      // Employees summary
      setPerformanceCampaignReportingInfo([...auxArray]);
      reCalculateKPIs(auxArray);

      // Loading state
      setIsCampaignReportingLoading(false);
    }

    async function changeSelectedValuesSummary (e,value) {
      e.preventDefault();

      setSelectedValuesSummary(value);

    }

  // ---------------------------------------------------------------------------------------------------
  // Download data as CSV
  // ---------------------------------------------------------------------------------------------------
  async function downloadDataAsCSV (e,data, type) {
    e.preventDefault();

    const csvData = convertToCSV(data, type);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'download.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }


  // Convert data to CSV
  const convertToCSV = (data, type) => {
    if (Configs.devEnvironment) console.log("[performanceHR.js][convertToCSV()]:", data, type);

    if (data.length === 0) return;

    const csvRows = [];

    // Convert depending on the data
    switch (type) {
      case "RAWEmployees":
        // Extract headers
        var headers = Object.keys(data[0]);

        // Select specific headers. For example: "employee"
        headers = headers.filter(header => {
            return header === 'employee' || header === 'manager' || header === 'area' || header === 'subarea' 
            || header == 'score' || header === 'selfScore' || header === 'managerScore' || header === 'peerScore' || header === 'employeeToManagerScore';
        });

        // Add headers
        csvRows.push(headers.join(','));

        // Add rows
        for (const row of data) {
            const values = headers?.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

      break;


      case "RAWValues":
        // Extract headers
        var headers = Object.keys(data[0]);

        // Select specific headers. For example: "employee"
        headers = headers.filter(header => {
            return header === 'employee' || header === 'manager' || header === 'area' || header === 'subarea';
        });

        // Additional headers
        headers.push('value');
        headers.push('scoreSelf');
        headers.push('teamScore');
        headers.push('managerScore');
        // headers.push('peersScore');

        // Add headers
        csvRows.push(headers.join(','));

        // Add rows
        data?.forEach (employee => {
          if (Array.isArray(employee.values)) {
            employee?.values.forEach (value => {

              // Define headers per row
              const values = headers?.map(header => {
                  // If it's the nested array field, return the value from the nested item
                  if (header === 'value') {
                    return `"${value.value}"`; // Replace with the actual property you want from the nested item
                  } else if (header === 'scoreSelf') {
                    return `"${value.selfScore}"`; // Replace with the actual property you want from the nested item
                  } else if (header === 'teamScore') {
                    return `"${value.teamScore}"`; // Replace with the actual property you want from the nested item
                  } else if (header=== 'managerScore') {
                    return `"${value.managerScore}"`; // Replace with the actual property you want from the nested item
                  } else {
                    const escaped = ('' + employee[header]).replace(/"/g, '\\"');
                    return `"${escaped}"`;
                }
              });
              csvRows.push(values.join(','));
            })
          }

        });
      break;
      default:
        break;  
      }

    return csvRows.join('\n');

    
  }

  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
  };

  // ---------------------------------------------------------------------------------------------------
  // Page render methods
  // ---------------------------------------------------------------------------------------------------    
    return (
      <React.Fragment>
      <Header/>
      <Bot />

          <div className="main">
            
                <PerformanceHeader livePerformanceCampaign={false} currentScreen={currentScreen} />

                <div className="flex flex-col m-2 lg:m-4 text-left ">

                {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


                      <div className="flex flex-col items-start md:mt-2 md:ml-2 m-1 md:mb-4">
                        <Tooltip content={t('performance.hr-dashboard.description')}>
                              <h2 id="values-title" className="section-title">
                                📈 {t('performance.hr-dashboard.title')}
                              </h2> 
                        </Tooltip>
                      </div>
                      

                  {securityLevel?.includes(Configs.securityLevelHR) 
                  || securityLevel?.includes(Configs.securityLevelAdmin)  ? 

                    <React.Fragment>
                        


                      {/* Reporting for HR */}
                      {isCampaignReportingLoading ?
                            <Loader />
                        :
                          <React.Fragment>
                          {isCampaignReportingLoading ?
                              <Loader />
                            :
                              <div className='flex flex-col space-y-4 m-4'>

                                    {/* Campaing selector */}
                                    <div className='flex flex-col md:flex-row justify-between items-center'>
                                      {isCampaignsListLoading ? 
                                          <Loader />
                                          :
                                          <Select
                                              isSearchable
                                              className='filter-selector'
                                              options={campaignOptions}
                                              id="campaignSelector"
                                              value={campaignOptions?.find(campaign => campaign.value === selectedCampaign)}
                                              placeholder={t('performance.hr-dashboard.select-campaign')}
                                              styles={Configs.formDropdownStyle}
                                              onChange={handleCampaignSelectorChange}
                                          />
                                        }
                                    </div>


                                    {/* Filters */}
                                    {selectedCampaign ?
                                      <div className='flex flex-col lg:flex-row justify-left items-center space-y-2 lg:space-y-0 lg:space-x-4'>
                                        <span className='flex text-2xl'> <AiOutlineFilter /></span>
                                        {isCampaignReportingLoading ? 
                                            <Loader />
                                          :
                                          <React.Fragment>
                                              {areaOptions?.length > 0 ? 
                                              <Select
                                                  isMulti
                                                  isSearchable
                                                  className='filter-selector '
                                                  options={areaOptions}
                                                  id="areaSelector"
                                                  placeholder={t('area')}
                                                  styles={Configs.formDropdownStyle}
                                                  onChange={handleAreaSelectorChange}
                                              />
                                              :
                                              null
                                              }
                                              
                                              {subareaOptions?.length > 0 ?
                                              <Select
                                                  isMulti
                                                  isSearchable
                                                  className='filter-selector'
                                                  options={subareaOptions}
                                                  id="subareaSelector"
                                                  placeholder={t('subarea')}
                                                  styles={Configs.formDropdownStyle}
                                                  onChange={handleSubareaSelectorChange}
                                              />
                                              :
                                              null
                                              }

                                              {managerOptions?.length > 0 ? 
                                              <Select
                                                  isMulti
                                                  isSearchable
                                                  className='filter-selector'
                                                  options={managerOptions}
                                                  id="managerSelector"
                                                  placeholder={t('manager')}
                                                  styles={Configs.formDropdownStyle}
                                                  onChange={handleManagerSelectorChange}
                                              />
                                              :
                                              null
                                              }
                                              
                                              {employeeOptions?.length > 0 ?
                                               <Select
                                                  isMulti
                                                  isSearchable
                                                  className='filter-selector'
                                                  options={employeeOptions}
                                                  id="employeeSelector"
                                                  placeholder={t('employee')}
                                                  styles={Configs.formDropdownStyle}
                                                  onChange={handleEmployeeSelectorChange}
                                              />
                                              :
                                              null
                                              }
                                          </React.Fragment>                             
                                        }
                                        
                                      </div>
                                    :
                                      null
                                    }

                                    {selectedCampaign && performanceCampaignReportingInfo ? 
                                    <React.Fragment>
                                      {/* Response Rate */}
                                      <div className='flex flex-col lg:flex-row items-start justify-between'>
                                        <div className='flex flex-wrap items-end'>
                                          {responseRate >0 ? <KPIStaticCard graph={Configs.KPIGraphAccelerator} indicator={t('response-rate')} data={responseRate} benchmark={defaultResponseRate} type="Percentage" hint={t('performance.hr-dashboard.response-rate.description')}/> : null }
                                          {globalScore>0 ? <KPIStaticCard indicator={t('performance.hr-dashboard.overall-score')} data={globalScore} benchmark={defaultGlobalScore} hint={t('performance.hr-dashboard.overall-score.description')}/> : null }
                                          {globalManagerScore >0 ? <KPIStaticCard indicator={t('performance.hr-dashboard.manager-score')} data={globalManagerScore} benchmark={defaultGlobalManagerScore} hint={t('performance.hr-dashboard.manager-score.description')}/> : null }
                                          {globalEmployeeScore > 0 ? <KPIStaticCard indicator={t('performance.hr-dashboard.self-score')} data={globalEmployeeScore} benchmark={defaultGlobalEmployeeScore} hint={t('performance.hr-dashboard.self-score.description')}/> : null }
                                          {globalTeamScore > 0  ? <KPIStaticCard indicator={t('performance.hr-dashboard.employee-manager-score')} data={globalTeamScore} benchmark={defaultGlobalTeamScore} hint={t('performance.hr-dashboard.employee-manager-score')}/> : null }
                                        </div>
                                        <div className='flex flex-col items-end hidden'>
                                          <button className='flex flex-row items-center bg-green-200  save-button mt-2 ' onClick={(e) => downloadCampaign(e)}>
                                          <span className='text-xl'><AiOutlineDownload /></span>
                                          </button>
                                        </div>
                                      </div>


                                      {/* Table with sorting capabilities per team */}
                                      <div className='flex flex-col'>
                                        <div className=' flex flex-row items-center justify-between '>
                                          <div className='flex flex-row items-center justify-start'>
                                            <h3 className={'flex text-lg text-left m-4 '} 
                                            // onClick={(e) => changeSelectedValuesSummary(e, "global")}
                                            >
                                                {t('performance.hr-dashboard.values-summary')}
                                            </h3>
                                            {/* <h3 className={'flex text-lg text-left m-4 cursor-pointer ' + (selectedValuesSummary === "manager" ? ' border-b-4 rounded border-b-green-200  ' : null)}
                                            onClick={(e) => changeSelectedValuesSummary(e, "manager")}>
                                                {t('performance.hr-dashboard.values-manager-summary')}
                                            </h3>
                                            <h3 className={'flex text-lg text-left m-4 cursor-pointer ' + (selectedValuesSummary === "team" ? ' border-b-4 rounded border-b-green-200 ' : null)}
                                            onClick={(e) => changeSelectedValuesSummary(e, "team")}>
                                                {t('performance.hr-dashboard.values-team-summary')}
                                            </h3> */}
                                          </div>
                                          <div className='flex flex-col items-end'>
                                            <button className='flex flex-row items-center bg-green-200  save-button mt-2 ' onClick={(e) => downloadDataAsCSV(e, performanceCampaignReportingInfo, "RAWValues")}>
                                              <span className='text-xl'><AiOutlineDownload /></span>
                                            </button>
                                          </div>
                                        </div>
                                        { valuesReportingInfo?.length > 0 ? 
                                          <React.Fragment>
                                            {selectedValuesSummary === "global" || selectedValuesSummary === null ?
                                              <DataTable data={valuesReportingInfo} columnNames={columnNamesValuesTable} zerosForDashes={true}/>
                                            :
                                            //   selectedValuesSummary === "manager" ?
                                            //   <DataTable data={valuesManagerReportingInfo} columnNames={columnNamesManagerValuesTable} zerosForDashes={true}/>
                                            // : 
                                            //   selectedValuesSummary === "team" ?
                                            //   <DataTable data={valuesTeamReportingInfo} columnNames={columnNamesTeamValuesTable} zerosForDashes={true}/>
                                            // :
                                              <span className="title-description ">🔍  <span className='italic'> {t('performance.hr-dashboard.no-results-for-filter')}{" "} </span>
                                              </span>
                                            }
                                          </React.Fragment>
                                        :
                                            <div className="flex flex-col items-center w-full lg:ml-4">
                                              <span className="title-description ">🔍  <span className='italic'> {t('performance.hr-dashboard.no-results-for-filter')}{" "} </span>
                                              </span>
                                            </div>
                                        }

                                        
                                        <div className=' flex flex-row items-center justify-between '>
                                          <div className='flex flex-row items-center justify-start'>
                                            <h3 className='text-lg text-left m-4'>
                                              {t('performance.hr-dashboard.employee-summary')}
                                            </h3> 
                                          </div>
                                          <div className='flex flex-col items-end'>
                                            <button className='flex flex-row items-center bg-green-200  save-button mt-2 ' onClick={(e) => downloadDataAsCSV(e, performanceCampaignReportingInfo, "RAWEmployees")}>
                                              <span className='text-xl'><AiOutlineDownload /></span>
                                            </button>
                                          </div>
                                        </div>

                                        { performanceCampaignReportingInfo?.length > 0 ?
                                          <React.Fragment>
                                                           
                                            <DataTable data={performanceCampaignReportingInfo} columnNames={columnNamesEmployeesTable} zerosForDashes={true}/>
                                          </React.Fragment>
                                        :
                                        <div className="flex flex-col items-center w-full lg:ml-4">
                                        <span className="title-description ">🔍  <span className='italic'> {t('performance.hr-dashboard.no-results-for-filter')}{" "} </span>
                                        </span>
                                      </div>
                                        }
                                      </div>

                                    </React.Fragment>
                                    :
                                    null
                                  }
                              </div>
                            }
                            </React.Fragment>
                          }
                        
                      {/* Live campaign status by employee */}
                      {isStatusByCompanyLoading ? 
                          <Loader />
                        :  

                          <React.Fragment>

                            { performanceReviewStatus ?  
                              <div className='flex flex-col space-y-4'>
                                  <SectionSeparator />
                                  <div className="flex flex-col items-start md:mt-2 md:ml-2 m-1 md:mb-4">
                                    <Tooltip content={t('performance.hr.live-campaign-status.description')}>
                                          <h2 id="values-title" className="section-title">
                                          🟢 {t('performance.hr.live-campaign-status.title')}
                                          </h2> 
                                    </Tooltip>
                                  </div>

                                  <div className='flex flex-col'>
                                      <DataTable data={performanceReviewStatus} columnNames={livePerfReviewStatusColumns} zerosForDashes={true}/>
                                  </div>
                              </div>
                            : 
                            null
                            }
                          </React.Fragment>

                        }
                    </React.Fragment>
            : 
            null      
            }

            <SectionSeparator />

            </div>
            </div>

      </React.Fragment>
    );
}

export default PerformanceHR;