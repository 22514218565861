// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Configs
import Configs from '../../Configs';
import * as apiCalls from '../../common/apiCalls';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import NoneditableEditor from '../../widgets/noneditableEditor';


// 3P
import { useTranslation } from 'react-i18next';

// Icons
import { FaLinkedin } from 'react-icons/fa';
import { PiHandsClapping } from "react-icons/pi";


// ---------------------------------------------------------------
const RecognitionItem = ({
    recognition,
    index,

}) => {
    // Attributes needed
    const {t} = useTranslation();
    const navigate = useNavigate();

    const { token, employeeID, companyID } = useAuth();
    const { companyPreferences } = useData();

    // Company preferences color
    const headerColor = companyPreferences?.personalization?.color ? companyPreferences?.personalization?.color.toString() : Configs.headerColor;
    const theme = companyPreferences?.personalization?.theme ? companyPreferences?.personalization?.theme.toString() : "light";

    const { setToasterMessage} = useData();

    const { companyOrg, setSelectedProfile } = useData();

    // state updates
    const [numOfLikes, setNumOfLikes] = useState(0);

    if (Configs.devEnvironment) console.log("[recognitionItem.js] - recognition: ", recognition);


// ---------------------------------------------------------------
// useEffect
// ---------------------------------------------------------------
useEffect(() => {
    if (recognition?.likes?.length) {
        setNumOfLikes(recognition.likes.length);
    }
    
}, [recognition]);

// ---------------------------------------------------------------
// Buttons interaction
// ---------------------------------------------------------------

const shareOnLinkedIn = (recognitionText, recognizedPerson, recognitionID) => {      
    if (Configs.devEnvironment) console.log("[recognitionItem.js][shareOnLinkedIn()] - recognitionText: ", recognitionText, recognizedPerson, recognitionID);
    // Get the current hostname and protocol (http or https)
    const { protocol, hostname } = window.location;
    const port = window.location.port ? `:${window.location.port}` : ''; // If there's a port, include it

    // Construct the base URL dynamically
    const baseUrl = `${protocol}//${hostname}${port}`;
    
    // Prepare urls
    const kincodePublicUrl = `${baseUrl}/public/recognition/${recognitionID}`;
    if (Configs.devEnvironment) console.log("[recognitionHome.js][shareOnLinkedIn()] - kincodePublicUrl: ", kincodePublicUrl);

    // Prepare the linkedIn text
    recognitionText = "I feel proud to announce that I have been recognised for my contributions in my company. \n Powered by <a href='#'>Kincode App</>";

    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${kincodePublicUrl}`;

    // Open in a new tab
    window.open(linkedInUrl, '_blank');
};

async function plus1 (recognition, index) {
    if (Configs.devEnvironment) console.log("[recognitionItem.js][plus1()] - recognition: ", recognition, index);

    // Post the +1
    const recognitionID = recognition?.recognitionID;

    const body = {
    companyID: companyID,
    employeeID: employeeID,
    recognitionID: recognitionID
    }

    try {
    const result = await apiCalls.postPlus1Recognition(companyID, employeeID, token, recognitionID, body);

    // Update state accordingly
    if (Configs.devEnvironment) console.log("[recognitionHome.js][plus1()] - result: ", result);

    if (result === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (result === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (result === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (result === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (result === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else if (result === 209) {
        setToasterMessage({message: t('recognition.home.plus1-existing'), type: Configs.warningToaster});
    } else {
        // Toaster 
        setToasterMessage({message: t('recognition.home.plus1-success'), type: Configs.successToaster});
        // Update state
        setNumOfLikes(prevNumOfLikes => prevNumOfLikes + 1);  // Correctly update likes count
    }

    } catch (error) {
        if (Configs.devEnvironment) console.log("[recognitionItem.js][plus1()] - error: ", error);
        setToasterMessage({type: Configs.errorToaster, message: t('recognition.home.plus1-error')});
    }


};

// ---------------------------------------------------------------------------------------------------
// Navigation methods
// ---------------------------------------------------------------------------------------------------
async function navigateToProfile(employeeAux) {
    const employee = companyOrg?.find( (element) => parseInt(element?.employeeID) === parseInt(employeeAux?.employeeID));
  
    if (Configs.devEnvironment) console.log("[recognitionItem.js][navigateToProfile()] - employee: ", employee, companyOrg);
    if (parseInt(employee?.employeeID) === parseInt(employeeID)) {
      setSelectedProfile(null);
      navigate("../profile/");
    } else {
      setSelectedProfile(employee);
      navigate("../profile/");
    } 
  }
  
// ---------------------------------------------------------------
// Return
// ---------------------------------------------------------------
return (
        <div className="flex flex-col mt-2 md:ml-2 m-1 border bg-white rounded-lg shadow px-8 pt-8 pb-4" key={index} id={'recognition-'+index}>
                <h2 className="flex flex-wrap text-lg"> 🥇
                    <span className='flex font-bold text-green-800 mr-1'>
                        {
                        recognition?.recognizedEmployees?.map((employee, index) => (
                            index === recognition?.recognizedEmployees?.length - 1 ? 
                                <div className='hover:underline hover:cursor-pointer' onClick={() => navigateToProfile(employee)}>{employee?.name + " " + employee?.surname}</div>
                            :
                            <div className='hover:underline hover:cursor-pointer mx-1' onClick={() => navigateToProfile(employee)}>{employee?.name + " " + employee?.surname + ", "}</div>
                        ))} 
                    </span>
                    {" " + t('recognition.card.between-names') + " " } 
                    <span className='flex font-bold text-green-800 ml-1 hover:underline hover:cursor-pointer' onClick={() => navigateToProfile(recognition)}>{recognition?.name + " " + recognition?.surname}</span>
                </h2>
                <span className="flex title-description ml-5"> {new Date(recognition?.timestamp).getDate() + "/" + (new Date(recognition?.timestamp).getMonth() + 1) + "/" + new Date(recognition?.timestamp).getFullYear()}</span>

                <div className='flex flex-col justify-left space-y-4'>
                    {/* Recognition - what? */}
                    <div className='flex text-sm'>
                        <NoneditableEditor content={recognition?.recognition} />
                    </div>

                    {/* Values */}
                    <div className='flex flex-wrap space-x-2 px-4'>
                        {recognition?.values?.map((value, index) => (
                            <div key={index} id={index} 
                            className={headerColor + ' text-sm  font-bold rounded-lg px-3 py-2'
                            + (theme === "dark" ? " text-white  " : " text-gray-800 "  )}>
                                {value}
                            </div>
                        ))}
                    </div>

                    {/* Sharing summary */}
                    <div className='flex flex-wrap space-x-2 px-4'>
                        {numOfLikes > 0 ?
                            <div className='flex flex-row items-center title-description'>
                                {numOfLikes} <PiHandsClapping className='ml-1 text-lg' />
                            </div>
                        :
                            <div className='flex flex-row items-center title-description'>
                            0 <PiHandsClapping className='ml-1 text-lg' />
                            </div>
                        }
                    </div>

                    {/* Buttons */}
                    <div className='flex flex-row justify-between space-x-4 border-t pt-4'>
                    <div className='flex flex-row space-x-4 border-r w-1/2'>
                        <button className='flex flex-row items-center justify-center w-full secondary-button' id='recognition-+1-button'
                        onClick={() => plus1(recognition, index)}>
                            {recognition?.likes?.filter(like => like.employeeID === employeeID).length > 0 ?
                                <span className='flex flex-row items-center text-green-600'>
                                    <PiHandsClapping className='mr-2 text-lg' /> +1
                                </span>
                            :
                                <span className='flex flex-row items-center'>
                                    <PiHandsClapping className='mr-2 text-lg' /> +1
                                </span>
                        }
                            
                        </button>
                    </div>
                    <div className='flex flex-row space-x-4 w-1/2'>
                        <button className='flex flex-row items-center justify-center w-full secondary-button ' id='share-on-li-button' 
                        onClick={() => shareOnLinkedIn(recognition?.recognition, recognition?.name + recognition?.surname, recognition?.recognitionID)}>
                        <FaLinkedin className='mr-2 text-lg'/> {t('recognition.home.share.linkedin')}
                        </button>
                    </div>
                    

                    </div>
                </div>

                
            </div>
    );
    
};

export default RecognitionItem;