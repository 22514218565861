import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Context to store and share history
const HistoryContext = createContext([]);

export const HistoryProvider = ({ children }) => {
  const [locationHistory, setLocationHistory] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // Update the history state, maintaining only the last 5 paths
    setLocationHistory(prevHistory => {

      var updatedHistory = [];

      if (prevHistory[prevHistory.length - 1] === location.pathname) {
        // Make sure not to add the same path twice
        updatedHistory = [...prevHistory];
      } else {
        // If path is already contained in the history, remove it, and add it to the end
        if ( prevHistory?.includes(location.pathname)) {
          updatedHistory = prevHistory.filter(path => path !== location.pathname);
          updatedHistory = [...updatedHistory, location.pathname];
        } else {
          // If path is not in the history, just add it
          updatedHistory = [...prevHistory, location?.pathname !== '/' && location ? location.pathname : prevHistory[prevHistory.length - 1]];
          updatedHistory.slice(-5); // Keep only last 5 entries
        }
      }

      // clean up duplicates from the history
      updatedHistory = updatedHistory.filter((path, index) => updatedHistory.indexOf(path) === index);

      return updatedHistory;

    });
  }, [location]);

  return (
    <HistoryContext.Provider value={{locationHistory}}>
      {children}
    </HistoryContext.Provider>
  );
};

// Custom hook to use the history context
export const useHistoryContext = () => useContext(HistoryContext);