// React
import React, {useState, useEffect} from 'react';
import {Link, useParams, useNavigate, useLocation} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Common
import CareerHeader from './careerHeader';
import Header from '../../common/header';
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import UserListItem from '../../widgets/userListItem';
import NonEditableEditor from '../../widgets/noneditableEditor';
import Editor from '../../widgets/editor';
import CollapsibleMeetingItem from '../../widgets/collapsibleMeetingItem';
import Pagination from '../../widgets/pagination';

// Loader and Toaster
import Loader from '../../common/support/loader';
import Toaster from '../../common/support/toaster';
import Tooltip from '../../common/support/tooltip';

// Icons
import { FaEdit } from 'react-icons/fa';
import {AiOutlineInfoCircle} from 'react-icons/ai';


// ---------------------------------------------------------------------------------------------------
function CareerHome () {
    const {t} = useTranslation();
    const navigate = useNavigate();
    let location = useLocation();

    // Get params from URL
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // User Info
    const { employeeID, companyID, securityLevel} = useAuth();
    const {profile, selectedProfile, setSelectedProfile, companyPreferences, isProfileLoading, isSelectedProfileLoading, companyOrg} = useData(); 
    const {toasterMessage, setToasterMessage} = useData();
    const {jobLevellingGuidelinesData, isJobLevellingGuidelinesDataLoading, postNewCareerPathForEmployee, isCareerPathLoading} = useData();
    const {postCareerConversation, isCareerConversationLoading} = useData();
    
    // Conversations
    const {meetingsData, isMeetingsDataLoading} = useData();
    const [careerMeetingsData, setCareerMeetingsData] = useState(meetingsData);

    const [profileData, setProfileData] = useState(null);

    const [currentRole, setCurrentRole] = useState(null);
    const [nextRole, setNextRole] = useState(null);
    const [isNextRoleEditable, setIsNextRoleEditable] = useState(false);

    const [dropdownOptions, setDropdownOptions] = useState([]);

    const [isJobLevellingCollapsed, setIsJobLevellingCollapsed] = useState(true);

    // Conversations
    const initialContent = t('career.home.conversations.initialContent.blank');
    const [meetingContent, setMeetingContent] = useState(initialContent);


    const jobGuidelinesConfigAux = {
      jobGuidelines: [
          {name: 'jobFamily', label: 'jobFamily', type: 'select', required: true, placeholder:  'career.guideline.create.jobFamily.placeholder'},
          {name: 'role', label: 'role', type: 'select', required: true, placeholder: 'career.guideline.create.role.placeholder'},
          {name: 'level', label: 'level', type: 'select', required: true, placeholder: 'career.guideline.create.level.placeholder'},
      ],
      jobGuidelinesDetails: [
          {name: 'ambiguity', label: 'ambiguity', type: 'textarea', required: true, placeholder: 'career.guideline.create.ambiguity.placeholder'},
          {name: 'scope', label: 'scope', type: 'textarea', required: true, placeholder: 'career.guideline.create.scope.placeholder'},
          {name: 'influence', label: 'influence', type: 'textarea', required: true, placeholder: 'career.guideline.create.influence.placeholder'},
          {name: 'execution', label: 'execution', type: 'textarea', required: true, placeholder: 'career.guideline.create.execution.placeholder'},
          {name: 'communication', label: 'communication', type: 'textarea', required: true, placeholder: 'career.guideline.create.communication.placeholder'},
          {name: 'impact', label: 'impact', type: 'textarea', required: true, placeholder: 'career.guideline.create.impact.placeholder'},
          {name: 'processImprovement', label: 'processImprovement', type: 'textarea', required: true, placeholder: 'career.guideline.create.processImprovement.placeholder'},
          {name: 'experience', label: 'experience', type: 'textarea', required: true, placeholder: 'career.guideline.create.experience.placeholder'},            
      ]
  }

  const guidelinesConfig = companyPreferences?.modulesSettings?.jobGuidelines ? companyPreferences?.modulesSettings?.jobGuidelines : jobGuidelinesConfigAux;

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Default number of rows per page

    if (Configs.devEnvironment) console.log ("[careerHome.js]][main()] Profile = ", profile, selectedProfile);
    if (Configs.devEnvironment) console.log ("[careerHome.js]][main()] params = ", params, jobLevellingGuidelinesData);

// ---------------------------------------------------------------------------------------------------
// useEffect
// ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    useEffect (() => {
      if (params && selectedProfile) {
        setProfileData(selectedProfile);
        updateRoles(selectedProfile);
      } else if (params && selectedProfile === null) {
        const employee = companyOrg?.find(employee => employee?.employeeID === params?.employeeID);
        // setSelectedProfile(employee);
        setProfileData(employee);
        updateRoles(employee);
      } else {
        setProfileData(profile);
        // setSelectedProfile(null);
        updateRoles(profile);
      }

      if (meetingsData) transformCareerMeetingsData(meetingsData);

      if (jobLevellingGuidelinesData) populateDropdowns();
  
      }, [companyOrg, selectedProfile, location.pathname, jobLevellingGuidelinesData, meetingsData]);


  // ---------------------------------------------------------------------------------------------------
  // Data transformation 
  // ---------------------------------------------------------------------------------------------------
  async function populateDropdowns () {
    if (Configs.devEnvironment) console.log ("[careerHome.js][populateDropdowns()] jobLevellingGuidelinesData = ", jobLevellingGuidelinesData);

    if (jobLevellingGuidelinesData) {
      const path = jobLevellingGuidelinesData?.map((guideline) => {
        return {
          value: guideline?.jobFamily + " - " + guideline?.role + " - " + guideline?.level,
          label: guideline?.jobFamily + " - " + guideline?.role + " - " + guideline?.level
        }
      });

      setDropdownOptions(path);
    }

  }

async function transformCareerMeetingsData (meetingsDataAux) {
  if (Configs.devEnvironment) console.log ("[careerHome.js][transformCareerMeetingsData()] meetingsDataAux = ", meetingsDataAux);

  var auxMeetings = [];

  meetingsDataAux?.map((meeting) => {
    if (meeting?.type === Configs.oneOnOneTypeCareerConversation) {
      auxMeetings.push(meeting);
    }
  });

  // set state
  setCareerMeetingsData(auxMeetings);

}

  // ---------------------------------------------------------------------------------------------------
  // Form methods
  // ---------------------------------------------------------------------------------------------------
  async function handleFieldChange (e, field) {
    if (Configs.devEnvironment) console.log ("[careerHome.js][handleFieldChange()] e = ", e, field);

    switch (field) {
      case "nextRole":
        const nextRoleAux = jobLevellingGuidelinesData?.find(guideline => guideline?.jobFamily + " - " + guideline?.role + " - " + guideline?.level === e?.value);
        if (Configs.devEnvironment) console.log ("[careerHome.js][handleFieldChange()] nextRoleAux = ", nextRoleAux);
        setNextRole(nextRoleAux);

      break;
      case "currentRole":
        // Disabled - not selectable here --> Organigram edit employee
        break;
      default:
        break;
    }
  }

  async function updateRoles (profileDataAux) {
    if (Configs.devEnvironment) console.log ("[careerHome.js][updateRoles()] currentRole = ", currentRole, nextRole, profileDataAux, jobLevellingGuidelinesData);

    var currentRoleAux = null;
    var nextRoleAux = null;

    if (profileDataAux?.employeeInfo?.role && profileDataAux?.employeeInfo?.jobFamily && profileDataAux?.employeeInfo?.level) {
      currentRoleAux = jobLevellingGuidelinesData?.find(guideline => guideline?.role === profileDataAux?.employeeInfo?.role && guideline?.jobFamily === profileDataAux?.employeeInfo?.jobFamily && guideline?.level?.toString() === profileDataAux?.employeeInfo?.level?.toString());
      setCurrentRole(currentRoleAux);
    }

    if (profileDataAux?.role && profileDataAux?.jobFamily && profileDataAux?.level) {
      currentRoleAux = jobLevellingGuidelinesData?.find(guideline => guideline?.role === profileDataAux?.role && guideline?.jobFamily === profileDataAux?.jobFamily && guideline?.level === profileDataAux?.level);
      setCurrentRole(currentRoleAux);
    }

    if (profileDataAux?.careerInfo && profileDataAux?.careerInfo?.length > 0) {
      nextRoleAux = jobLevellingGuidelinesData?.find(guideline => guideline?.role === profileDataAux?.careerInfo[0]?.role && guideline?.jobFamily === profileDataAux?.careerInfo[0]?.jobFamily && guideline?.level === profileDataAux?.careerInfo[0]?.level);
      setNextRole(nextRoleAux);
    }

    if (Configs.devEnvironment) console.log ("[careerHome.js][updateRoles()] final mapping: ", currentRoleAux, nextRoleAux, profileDataAux?.careerInfo);

  }

  // ---------------------------------------------------------------------------------------------------
  // Navigation
  // ---------------------------------------------------------------------------------------------------
  async function navigateToProfile () {
    if (Configs.devEnvironment) console.log ("[careerHome.js][navigateToProfile()] Selected Profile = ", selectedProfile);

    const employee = companyOrg?.find(employee => employee?.employeeID === selectedProfile?.employeeID);

    if (selectedProfile?.employeeID === employeeID) {
      setSelectedProfile(null);
      navigate("../profile");
    } else {
      setSelectedProfile(employee);
      navigate("../profile/" + encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                      employeeID: selectedProfile?.employeeID,
                      companyID: selectedProfile?.companyID,
                  }),Configs.privateKey).toString()));
    }
      
  } 
// ---------------------------------------------------------------------------------------------------
// Save methods
// ---------------------------------------------------------------------------------------------------
const saveNextRole = async (e) => {
  if (Configs.devEnvironment) console.log ("[careerHome.js][saveNextRole()] nextRole = ", nextRole);

  e.preventDefault();

  if (!nextRole || !nextRole?.jobFamily || !nextRole?.role || !nextRole?.level) {
    setToasterMessage({message: t('fields.missing'), type: 'warning'});
    return;
  }

  const auxCareerPath = {
    companyID: profileData?.companyID,
    employeeID: profileData?.employeeID,
    jobFamily: nextRole?.jobFamily,
    role: nextRole?.role,
    level: nextRole?.level,
    createdBy: employeeID,
    createdOn: new Date(),
  }

  postNewCareerPathForEmployee (auxCareerPath);
}

const saveMeeting = async (e) => {
  if (Configs.devEnvironment) console.log ("[careerHome.js][save()] meetingContent = ", meetingContent);

  e.preventDefault();

  if (meetingContent === null || meetingContent === "") {
    setToasterMessage({message: t('field.missing'), type: Configs.warningToaster});
    return;
  }

  // Create object to send to server
  const auxConversationObject = {
      companyID: companyID,
      managerID: selectedProfile ? selectedProfile?.managerID : params?.managerID ? params?.managerID : profile?.employeeInfo?.managerID,
      employeeID: selectedProfile ? selectedProfile?.employeeID : (params?.employeeID ? params.employeeID : employeeID),
      title: new Date().toLocaleDateString() + " - " + (selectedProfile ? selectedProfile?.employee :params?.employee ? params.employee : profile?.employeeInfo?.employee),
      content: meetingContent,
      date: new Date(),
  }

  // Send to server
  postCareerConversation(auxConversationObject);

}

// ------------------------------------------------------------
// Pagination methods
// ------------------------------------------------------------
// Calculate the indices of the first and last row on the current page
const indexOfLastRow = currentPage * rowsPerPage;
const indexOfFirstRow = indexOfLastRow - rowsPerPage;
const currentRows = careerMeetingsData?.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        if (Configs.devEnvironment) console.log("[oneOnOnePersonal.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page since the number of pages will change
    };


// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />

      <Bot/>

      <div className="main">
        <CareerHeader />
            
        <div className='flex flex-row items-start justify-between'>
          <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

                {/* Title */}
                <div className="flex flex-col mt-2 md:ml-2 m-1">
                        <h2 className="flex text-xl text-left mb-4 ">
                            <div className='flex flex-col md:flex-row justify-left'>
                                <Tooltip content={t('career.home.description')}>
                                    <div id="title" className='section-title' >
                                         {params ? ("🪜 " + t('career.home.title.team')) : ("🪜 " + t('career.home.title.self'))} <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>
                                    </div>
                                </Tooltip>
                            </div>
                        </h2>

                        {/* Managers only - Employee */}
                        {profileData && (profileData?.employeeID != profile?.employeeID) ?
                            <button className="flex flex-col w-full" id="career-plan-employee-card" onClick={() => navigateToProfile(profileData)}
                                              >
                                  <UserListItem user={profileData?.name + " " + profileData?.surname} initials={profileData?.name?.slice(0,1)+profileData?.surname?.slice(0,1)} 
                                  role={profileData?.role} level={profileData?.level}
                                  area={profileData?.area} subarea={profileData?.subarea}
                                  profilePicture={profileData?.profilePicture ? profileData?.profilePicture : null} />
                              </button> 
                            :
                            null
                        }
                </div>

                {/* Current role */}
                <div className='flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:justify-between items-start m-1 lg:m-4'>
                  <div className='flex flex-col lg:w-1/2 items-start justify-between space-y-2'>
                    <label for="current-role" className='text-sm text-gray-600 font-medium'>{t('career.home.currentRole')}</label>
                    { isJobLevellingGuidelinesDataLoading || isProfileLoading || isSelectedProfileLoading ? 
                      <Loader/>
                      :
                      currentRole ?
                      <div className='flex flex-col w-full items-start space-y-1'>
                        <div className='flex flex-row items-end space-x-2'>
                          <span className='text-base font-bold'>{currentRole?.role}</span>
                          {currentRole?.level && <span className='text-sm font-medium text-gray-700'>{" (" + t('level')+": " + currentRole?.level + ")"}</span>}
                        </div>
                        <span className='text-sm text-gray-600'>{currentRole?.jobFamily}</span>
                        </div>
                      : 
                      <span className='title-description italic'>{t('career.home.currentRole.empty')}</span>
                    }
                  </div>

                  {/* Next expected role - to be filled by Manager */}
                  <div className='flex flex-col lg:w-1/2 items-start justify-between space-y-2 pb-4'>
                    <span className='text-sm text-gray-600 font-medium'>{t('career.home.nextRole')}</span>
                    {isJobLevellingGuidelinesDataLoading || isProfileLoading || isSelectedProfileLoading ? 
                      <Loader/>
                      :
                    securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec) ?
                      // Employee manager
                      profileData?.managerInfo?.employeeID === employeeID || securityLevel?.includes(Configs.securityLevelHR) ?
                        <div className='flex flex-col w-full space-y-2'>
                          <div className='flex flex-row justify-between items-center w-full space-x-4'>
                                <Select 
                                  className='text-left w-full text-xs'
                                  styles={Configs.formDropdownStyle}
                                  value={dropdownOptions?.find(option => option.value === (nextRole?.jobFamily + " - " + nextRole?.role + " - " + nextRole?.level))}
                                  options={dropdownOptions}
                                  id='nextRole'
                                  isSearchable
                                  isDisabled={!isNextRoleEditable} 
                                  placeholder={t('career.home.nextRole.placeholder')}
                                  onChange={(e) => handleFieldChange(e, "nextRole" )}
                                />
                                {profileData?.managerInfo?.employeeID === employeeID || securityLevel?.includes(Configs.securityLevelHR) ? 
                                // Only same manager or HR can click on edit
                                  <div className='flex flex-col'>
                                    <Tooltip content={t('edit')}>
                                      <FaEdit className={' flex text-xl text-gray-400 cursor-pointer ' + (isNextRoleEditable ? ' hidden ' : '  ')} 
                                      onClick={() => setIsNextRoleEditable(!isNextRoleEditable)} />
                                    </Tooltip>
                                  </div>
                                :
                                  null
                                }
                            </div>

                            {profileData?.managerInfo?.employeeID === employeeID || securityLevel?.includes(Configs.securityLevelHR) ?
                            // Only same manager or HR can save next role 
                              <div className='flex flex-row justify-end items-center w-full'>
                                {isCareerPathLoading ? 
                                  <Loader/> 
                                : 
                                  <div className='flex flex-row justify-end items-center w-full mx-4 my-2 '>
                                    <button className={'secondary-button ' + (isNextRoleEditable ? ' ' : ' hidden ')}
                                        onClick={() => setIsNextRoleEditable(!isNextRoleEditable)}>
                                      {t('cancel')}
                                    </button>
                                    <button className={'save-button  ' + (isNextRoleEditable ? ' ' : ' hidden ')}
                                      disabled={isCareerPathLoading}
                                      onClick={(e) => saveNextRole(e)}>
                                      {t('save')}
                                    </button>
                                  </div>  
                                }
                              </div>
                            :
                              null
                            }

                        </div>
                        :
                        // Not same manager
                        profileData?.nextRole ? 
                          <span className='text-base font-bold'>{profileData?.nextRole}</span>
                          :
                          <span className='title-description italic'>{t('career.home.nextRole.empty')}</span>
                        :
                        // self
                        profileData?.nextRole ?
                          <span className='text-base font-bold'>{profileData?.nextRole}</span>
                          :
                          <span className='title-description italic'>{t('career.home.nextRole.empty')}</span>
                    }
                    
                  </div>

                </div>


                {/* Job levelling guidelines */}
                <div className={"flex flex-col md:ml-2 p-2 cursor-pointer rounded-lg hover:bg-gray-100" }
                  onClick={() => setIsJobLevellingCollapsed(!isJobLevellingCollapsed)}>
                        <h2 className="flex flex-row justify-between text-xl text-left mb-4 ">
                            <div className='flex flex-col md:flex-row justify-left'>
                                <Tooltip content={t('career.home.guideline.description')}>
                                    <div id="title" className='section-title' >
                                         {"📏 " + t('career.home.guideline.title')}
                                    </div>
                                </Tooltip>
                            </div>

                            <div className='flex flex-row items-center justify-start mx-8 text-4xl'>
                            {isJobLevellingCollapsed ?
                                      <React.Fragment>
                                          <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                                              <path d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z" fill="currentColor" fill-rule="evenodd">
                                              </path>
                                          </svg>
                                      </React.Fragment>
                                      :
                                      <React.Fragment>
                                          <div>
                                              <svg fill="none" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-up" class="inline h-1.5">
                                                  <path d="M12.085 6.23a1.09 1.09 0 01-.004 1.477 1.093 1.093 0 01-1.605.005l-3.84-4.17a.544.544 0 00-.8 0l-3.84 4.17a1.093 1.093 0 01-1.604-.005A1.093 1.093 0 01.388 6.23L5.436.747a1.088 1.088 0 011.6 0l5.05 5.482z" fill="currentColor" fill-rule="evenodd">
                                                  </path>
                                              </svg>
                                          </div>
                                      </React.Fragment>
                                  }
                              </div>
                        </h2>
                </div>
                {isJobLevellingCollapsed ? 
                  null
                :
                  currentRole || nextRole ?
                  <div className='flex flex-col lg:flex-row items-start m-1 lg:m-4 bg-white shadow-lg border border-gray-200 p-4 rounded-lg' >
                    <table className='w-full text-xs' >
                      <thead className='w-full'>
                        <td className=''> </td>
                        <td className='bg-black text-white w-min-1/3 border border-white p-1'> {t('career.home.currentRole') } </td>
                        <td className='bg-black text-white w-min-1/3 border border-white'> {t('career.home.nextRole') } </td>
                        </thead>
                      <thead className='w-full '>
                        <td className='bg-black text-white w-min-1/3 border border-white p-1'>{t('realm')}</td>
                        <td className='w-min-1/3 border border-gray-400 p-1'> {currentRole ? (currentRole?.role + " - " + currentRole?.level): t('career.home.currentRole.empty') } </td>
                        <td className='w-min-1/3 border border-gray-400 p-1'> {nextRole ? (nextRole?.role + " - " + nextRole?.level) : t('career.home.nextRole.empty') } </td>
                      </thead>
                      <tbody className='w-full border border-gray-400'>
                        {guidelinesConfig?.jobGuidelinesDetails?.map((field) => {
                          return (
                            <tr className='w-full border border-gray-400 text-left'>
                              <td className=' border border-gray-400 p-2'> {t('career.guideline.create.' + field.name)} </td>
                              <td className='w-min-1/3 border border-gray-400'> <NonEditableEditor content={currentRole ? currentRole[field.name] : " - "} /></td> 
                              <td className='w-min-1/3 border border-gray-400'> <NonEditableEditor content={nextRole ? nextRole[field.name] : " - " } /> </td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </table>
                  </div>
                  :
                    <div className='flex flex-row justify-center items-center text-center m-4 bg-white shadow border-gray-100 p-4 rounded-lg' >
                      🤷 <span className='title-description italic mt-4'>{" " + t('career.home.currentRole.empty')}</span>
                    </div>
                }

                <SectionSeparator />

                {/* Career conversations */}
                <div className="flex flex-col mt-2 md:ml-2 m-1">
                        <h2 className="flex text-xl text-left mb-4 ">
                            <div className='flex flex-col md:flex-row justify-left'>
                                <Tooltip content={t('career.home.conversations.description')}>
                                    <div id="title" className='section-title' >
                                         {"🗣️ " + t('career.home.conversations.title')}
                                    </div>
                                </Tooltip>
                            </div>
                        </h2>

                        <div className="flex flex-col items-start w-full px-3 mt-6">

                            <div className="flex flex-col items-start w-full px-3 mt-8">
                            <label className="flex flex-row items-center block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white">
                                {t('shared-notes')}:
                                <div className='group ml-2 cursor-pointer'>
                                    <AiOutlineInfoCircle />

                                    <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50">
                                      <NonEditableEditor
                                          content={t('career.home.conversations.initialContent')}
                                          />
                                    </div>
                                </div>
                            </label>
                            
                            </div>
                            
                            <div className='flex flex-col items-start w-full input-field'>
                              {isCareerConversationLoading ?
                                  <Loader/>
                                :
                                 <Editor setContent={setMeetingContent} content={meetingContent} initialContent={initialContent}/>
                              }
                            </div>

                        </div>

                        <div className='flex flex-col m-4 lg:m-8 lg:flex-row items-center justify-end '>
                            <button id="save" className="flex save-button" onClick={(e) => saveMeeting(e)}>
                              {t('save')}
                            </button>
                        </div>

                  {/* Career conversations history */}
                  <div className='flex flex-col text-left w-full px-3 mt-6'>

                    <h2 className="section-title mb-8">
                          {t('one-on-one.history')}
                    </h2>

                    {isMeetingsDataLoading ?
                      <Loader/>
                      :
                      currentRows && currentRows?.length > 0 ? 
                        currentRows?.map((meeting, index) => {
                          return (
                              // Career conversation plan
                              <CollapsibleMeetingItem title={t('oneOnOne.types.career-conversation') + " - " + meeting?.title} content={meeting?.content} collapsed={index === 0 ? false : true}  
                                          meetingID={meeting?._id} meetingType={meeting?.type}/>
                          )
                        })
                      :
                      <div className='flex flex-row justify-center items-center text-center m-4 bg-white shadow border border-gray-200 p-4 rounded-lg' >
                          <span className='title-description italic'>{t('career.home.conversations.empty')}</span>
                      </div>
                    }
                    <Pagination 
                      totalRows={careerMeetingsData?.length} 
                      rowsPerPage={rowsPerPage} 
                      currentPage={currentPage} 
                      onPageChange={handlePageChange} 
                      handleRowsPerPageChange={handleRowsPerPageChange}/>

                    <SectionSeparator />

                    </div>

                  </div>



          </div>

          <div className="right-small-view">
            
            </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default CareerHome;