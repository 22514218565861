// React
import React, { useState, useEffect } from 'react';

// Configs
import Configs from '../../Configs';
import { useAuth } from '../../common/appContext';
import Tooltip from '../../common/support/tooltip';

// 3P
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// React icons
import {AiOutlineFilter} from 'react-icons/ai';


// --------------------------------------------------------------------------------
// Filter widget
// --------------------------------------------------------------------------------
const FilterWidget = ({ 
    data, onFilterChange, 
    teamTypeFilter, defaultTeamTypeFilter,
    areaFilter, defaultAreaFilter,
    subareaFilter, defaultSubareaFilter,
    managerFilter, defaultManagerFilter,
    employeeFilter, defaultEmployeeFilter,
    // Other filters
    companyIDFilter = false, defaultCompanyIDFilter,
    typeFilter = false, defaultTypeFilter,

    title
      }) => {


  const {t} = useTranslation();

  const {employeeID, companyID, securityLevel} = useAuth();

  const [filteredData, setFilteredData] = useState(data);

  const teamTypeOptionsAux = [
      { value: 'team', label: t('pulse.home.filter.directs') },
      { value: 'org', label: t('pulse.home.filter.org')},
      { value: 'company', label: t('pulse.home.filter.company')  },
    ];
    
    // Filter options states
    const [availableTeamTypeOptions, setAvailableTeamTypeOptions] = useState(teamTypeOptionsAux);
    const [availableManagerOptions, setAvailableManagerOptions] = useState([]);
    const [availableEmployeeOptions, setAvailableEmployeeOptions] = useState([]);
    const [availableAreasOptions, setAvailableAreasOptions] = useState([]);
    const [availableSubareasOptions, setAvailableSubareasOptions] = useState([]);
    // Other
    const [availableCompanyIDOptions, setAvailableCompanyIDOptions] = useState([]);
    const [availableTypeOptions, setAvailableTypeOptions] = useState([]);

    // Filter states
    const [filters, setFilters] = useState({
        teamType: defaultTeamTypeFilter ? defaultTeamTypeFilter : availableTeamTypeOptions[0],
        area: defaultAreaFilter,
        subarea: defaultSubareaFilter,
        manager: defaultManagerFilter,
        employee: defaultEmployeeFilter,
        // Other
        companyID: defaultCompanyIDFilter,
        type: defaultTypeFilter
      });

    const [selectedTeamTypeFilter, setSelectedTeamTypeFilter] = useState(defaultTeamTypeFilter ? defaultTeamTypeFilter : availableTeamTypeOptions[0]);
    const [selectedAreaFilter, setSelectedAreaFilter] = useState(defaultAreaFilter ? defaultAreaFilter : null);
    const [selectedSubareaFilter, setSelectedSubareaFilter] = useState(defaultSubareaFilter ? defaultSubareaFilter : null);
    const [selectedManagerFilter, setSelectedManagerFilter] = useState(defaultManagerFilter ? defaultManagerFilter : null);
    const [selectedEmployeeFilter, setSelectedEmployeeFilter] = useState(defaultEmployeeFilter ? defaultEmployeeFilter : null);
    // Other
    const [selectedCompanyIDFilter, setSelectedCompanyIDFilter] = useState(defaultCompanyIDFilter ? defaultCompanyIDFilter : null);
    const [selectedTypeFilter, setSelectedTypeFilter] = useState(defaultTypeFilter ? defaultTypeFilter : null);
    
  // --------------------------------------------------------------------------------
  // Prepare filter options based on data
  // --------------------------------------------------------------------------------
  useEffect(() => {
    if(Configs.devEnvironment) console.log ("[filterWidget.js][useEffect()] data: ", data);

    const availableFilters = (data?.length > 0 && data[0] !== undefined && data !== null) ? Object?.keys(data[0]) : [];

    // Set the initial filter to the first available option
    if (availableFilters?.length > 0) {
        // setSelectedFilter(availableFilters[0]);
        if (Configs.devEnvironment)  console.log ("[filterWidget.js][useEffect()] availableFilter: ", availableFilters);
    }

    // Default filter values
    const dataAux = reApplyAllFilters();
    onFilterChange(dataAux);

  }, [filters, data]);

// --------------------------------------------------------------------------------
// Prepare filter options based on data
// --------------------------------------------------------------------------------
async function populateManagerOptions (auxFilteredData) {
    
    // Get the manager names
    const availableManagerOptionsAux = [];
    
    if (auxFilteredData === undefined || auxFilteredData === null ) {
        
        // Get the unique managers
        const uniqueManagerIDs = [...new Set(data?.map(employee => employee?.managerID))];

        uniqueManagerIDs.forEach(managerID => {
            const manager = data?.find(manager => parseInt(manager?.employeeID) === parseInt(managerID));
            const managerNameAndSurname = manager?.name + " " + manager?.surname;
            if (manager && managerNameAndSurname) availableManagerOptionsAux.push({value: managerID, label: managerNameAndSurname});
        });
    } else {
        // There are already some other filters applied
        const uniqueManagerIDs = [...new Set(auxFilteredData?.map(employee => employee?.managerID))];

        uniqueManagerIDs.forEach(managerID => {
            const manager = data?.find(managerAux2 => parseInt(managerAux2?.employeeID) === parseInt(managerID));
            const managerNameAndSurname = manager?.name + " " + manager?.surname;
            if (manager && managerNameAndSurname) availableManagerOptionsAux.push({value: manager?.employeeID, label: managerNameAndSurname});
        });
    }

    // Sort the managers by name
    availableManagerOptionsAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

    // Update state
    setAvailableManagerOptions([...availableManagerOptionsAux]);
}

async function populateEmployeeOptions (auxFilteredData) {
    // Get the employee names
    const availableEmployeeOptionsAux = [];

    if (auxFilteredData === undefined || auxFilteredData === null ) {
        // Get the unique employees
        const uniqueEmployeeIDs = [...new Set(data?.map(employee => employee?.employeeID))];

        uniqueEmployeeIDs.forEach(employeeID => {
            const employee = data?.find(employee => parseInt(employee?.employeeID) === parseInt(employeeID));
            const employeeNameAndSurname = employee?.name + " " + employee?.surname;
            if (employee && employeeNameAndSurname) availableEmployeeOptionsAux.push({value: employeeID, label: employeeNameAndSurname});
        });
    } else {
        // There are already some other filters applied
        const uniqueEmployeeIDs = [...new Set(auxFilteredData?.map(employee => employee?.employeeID))];

        uniqueEmployeeIDs.forEach(employeeID => {
            const employee = data?.find(employeeAux2 => parseInt(employeeAux2?.employeeID) === parseInt(employeeID));
            const employeeNameAndSurname = employee?.name + " " + employee?.surname;
            if (employee && employeeNameAndSurname) availableEmployeeOptionsAux.push({value: employee?.employeeID, label: employeeNameAndSurname});
        });
    }

    // Sort the employees by name
    availableEmployeeOptionsAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

    // Update state
    setAvailableEmployeeOptions([...availableEmployeeOptionsAux]);   
}

async function populateTeamTypeOptions () {
    // Aux object to populate options
    var teamTypeOptionsAux2 = [
        { value: 'team', label: t('pulse.home.filter.directs') },
      ]; 

    // Review the number of managerIDs in the data
    const uniqueManagerIDs = [...new Set(data?.map(employee => employee?.managerID))];

    // If number of uniqueManagerIDs is 1, then only direct team is available
    if (uniqueManagerIDs?.length === 1) {
        // Do nothing
    } else {
        // Add org option
        teamTypeOptionsAux2.push({ value: 'org', label: t('pulse.home.filter.org')});
    }

    // Review employee permissions
    if (securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec) || securityLevel?.includes(Configs.securityLevelAdmin) ) {
        // Add company option
        teamTypeOptionsAux2.push({ value: 'company', label: t('pulse.home.filter.company')  });
    }

    // Update state
    setAvailableTeamTypeOptions([...teamTypeOptionsAux2]);   
}

async function populateAreaOptions (dataInput) {
    let dataAux = dataInput ? dataInput : [...data];

    // Aux object to populate options
    var areaOptionsAux2 = [];

    // Get the unique areas
    const uniqueAreas = [...new Set(dataAux?.map(employee => employee?.area))];

    uniqueAreas.forEach(area => {
        if (area) areaOptionsAux2.push({value: area, label: area});
    });

    // Sort
    areaOptionsAux2.sort((a, b) => (a.label > b.label) ? 1 : -1);

    if (Configs.devEnvironment) console.log ("[filterWidget.js][populateAreaOptions()] areaOptionsAux2: ", areaOptionsAux2);

    // Update state
    setAvailableAreasOptions([...areaOptionsAux2]);
}

async function populateSubareaOptions (dataInput) {
    let dataAux = dataInput ? dataInput : [...data];

    // Aux object to populate options
    var subareaOptionsAux2 = [];

    // Get the unique subareas
    const uniqueSubareas = [...new Set(dataAux?.map(employee => employee?.subarea))];

    uniqueSubareas.forEach(subarea => {
        if (subarea) subareaOptionsAux2.push({value: subarea, label: subarea});
    });

    // sort
    subareaOptionsAux2.sort((a, b) => (a.label > b.label) ? 1 : -1);

    if (Configs.devEnvironment) console.log ("[filterWidget.js][populateSubareaOptions()] subareaOptionsAux2: ", subareaOptionsAux2);

    // Update state
    setAvailableSubareasOptions([...subareaOptionsAux2]);
}

async function populateCompanyIDOptions (dataInput) {
    let dataAux = dataInput ? dataInput : [...data];

    // Aux object to populate options
    var companyIDOptionsAux2 = [];

    // Get the unique companyIDs
    const uniqueCompanyIDs = [...new Set(dataAux?.map(employee => employee?.companyID))];
    uniqueCompanyIDs.forEach(companyID => {
        if (companyID) companyIDOptionsAux2.push({value: companyID, label: companyID});
    });

    // sort
    companyIDOptionsAux2.sort((a, b) => (a.value > b.value) ? 1 : -1);

    if (Configs.devEnvironment) console.log ("[filterWidget.js][populateCompanyIDOptions()] companyIDOptionsAux2: ", companyIDOptionsAux2);

    // Update state
    setAvailableCompanyIDOptions([...companyIDOptionsAux2]);
}

async function populateTypeOptions (dataInput) {
    let dataAux = dataInput ? dataInput : [...data];

    // Aux object to populate options
    var typeOptionsAux2 = [];

    // Get the unique types
    const uniqueTypes = [...new Set(dataAux?.map(employee => employee?.type))];
    uniqueTypes.forEach(type => {
        if (type) typeOptionsAux2.push({value: type, label: type});
    });

    // sort
    typeOptionsAux2.sort((a, b) => (a.value > b.value) ? 1 : -1);

    if (Configs.devEnvironment) console.log ("[filterWidget.js][populateTypeOptions()] typeOptionsAux2: ", typeOptionsAux2);

    // Update state
    setAvailableTypeOptions([...typeOptionsAux2]);
}


// --------------------------------------------------------------------------------
// Handle filter value change
// --------------------------------------------------------------------------------
  const handleFilterValueChange = (e, filterField) => {
    if (Configs.devEnvironment) console.log ("[filterWidget.js][handleFilterValueChange()] e: ", e, filterField);

    let filterAux = filters;

    // Filter the data
    switch (filterField?.toString()) {
        case "teamType":
            // Check what is the type selected
            // Set Select state to the appropriate value
            filterAux.teamType = e?.value?.toString();
            setSelectedTeamTypeFilter(e?.value?.toString());
            break;
        case "area":
            // Set Select state to the appropriate value
            setSelectedAreaFilter(e?.length > 0 ? e : null);
            filterAux.area = e?.length > 0 ? e : null;
            break;
        case "subarea":
            // Set Select state to the appropriate value
            setSelectedSubareaFilter(e?.length > 0 ? e : null);
            filterAux.subarea = e?.length > 0 ? e : null;
            break;
        case "manager":
            setSelectedManagerFilter(e?.length > 0 ? e : null);
            filterAux.manager = e?.length > 0 ? e : null;
            break;
        case "employee":
            // Set Select state to the appropriate value
            setSelectedEmployeeFilter(e?.length > 0 ? e : null);
            filterAux.employee = e?.length > 0 ? e : null;
            break;

        // Other
        case "companyID":
            // Set Select state to the appropriate value
            setSelectedCompanyIDFilter(e?.length > 0 ? e : null);
            filterAux.companyID = e?.length > 0 ? e : null;
            break;
        case "type":
            // Set Select state to the appropriate value
            setSelectedTypeFilter(e?.length > 0 ? e : null);
            filterAux.type = e?.length > 0 ? e : null;
            break;
        default:
            break;
    }

    // Update state
    setFilters(filterAux);

    // Re-apply all filters
    const filteredDataAux = reApplyAllFilters(filterAux);
    onFilterChange(filteredDataAux);

  };

  function reApplyAllFilters (filterAux) {
      
      // Get data variable
      let dataAux = data?.length > 0 ? [...data] : [];
      let filterDataAux = filterAux ? {...filterAux} : {...filters};
      
      if(Configs.devEnvironment) console.log ("[filterWidget.js][reApplyAllFilters()] data: ", data, 
                                filterDataAux.teamType, filterDataAux.area, filterDataAux.subarea, filterDataAux.manager, filterDataAux.employee);
      
    // Re-apply all filters 
    // TEAM FILTER
    if (filterDataAux.teamType === "team") {
        // Filter by directs
        dataAux = dataAux?.filter((employee) => parseInt(employee?.managerID) === parseInt(employeeID));
    }

    if (filterDataAux.teamType === "org") {
        // Filter by org - Not working for HR + Exec
        dataAux = dataAux?.filter((employee) => parseInt(employee?.companyID) === parseInt(companyID));
    }

    if (filterDataAux.teamType === "company") {
        // Filter by company
        dataAux = dataAux?.filter((employee) => parseInt(employee?.companyID) === parseInt(companyID));
    }

    // AREA FILTER
    if (areaFilter && filterDataAux.area?.length > 0) {
        // Set with all areas
        const areasAux = new Set(filterDataAux.area?.map((areas) => areas?.value?.toString()));

        // Look for areas selected in the list of employees
        dataAux = dataAux?.filter((employee) => areasAux.has(employee?.area?.toString()));
    }

    // SUBAREA FILTER
    if (subareaFilter && filterDataAux.subarea?.length > 0) {
        // create a set for subareas in the e array
        const subAreasAux = new Set(filterDataAux.subarea?.map((subAreasAux) => subAreasAux?.value?.toString()));

        // Look for subareas in the list of employees
        dataAux = dataAux?.filter((employee) => subAreasAux.has(employee?.subarea?.toString()));
    }

    // MANAGER FILTER
    if (managerFilter && filterDataAux.manager?.length > 0) {
        // create a set for managerIDs in the e array
        const managerIDs = new Set(filterDataAux.manager?.map((manager) => parseInt(manager?.value)));

        // Look for managers in the list of employees
        dataAux = dataAux?.filter((employee) => managerIDs.has(parseInt(employee?.managerID)));
    }

    // EMPLOYEE FILTER
    if (employeeFilter && filterDataAux?.employee?.length > 0 ) {
        // create a set for employeeIDs in the e array
        const employeeIDs = new Set(filterDataAux.employee?.map((employee) => parseInt(employee?.value)));

        // Look for managers in the list of employees
        dataAux = dataAux?.filter((employee) => employeeIDs.has(parseInt(employee?.employeeID)));
    }

    // COMPANY ID FILTER
    if (companyIDFilter && filterDataAux?.companyID?.length > 0 ) {
        // create a set for companyIDs in the e array
        const companyIDs = new Set(filterDataAux.companyID?.map((company) => parseInt(company?.value)));
        
        // Look for companyIDs in the list
        dataAux = dataAux?.filter((employee) => companyIDs.has(parseInt(employee?.companyID)));
    }

    // TYPE FILTER
    if (typeFilter && filterDataAux?.type?.length > 0 ) {
        // create a set for types in the e array
        const types = new Set(filterDataAux.type?.map((type) => type?.value?.toString()));
        
        // Look for types in the list
        dataAux = dataAux?.filter((employee) => types.has(employee?.type?.toString()));
    }

    // Sort filteredData by name
    dataAux?.sort((a, b) => (a?.name > b?.name) ? 1 : -1);

    // Populate dropdowns accordingly
    // Populate filter options if it is enabled
    if (teamTypeFilter) populateTeamTypeOptions(dataAux);
    if (managerFilter) populateManagerOptions(dataAux);
    if (employeeFilter) populateEmployeeOptions(dataAux);
    if (areaFilter) populateAreaOptions(dataAux);
    if (subareaFilter) populateSubareaOptions(dataAux);
    // Other
    if (companyIDFilter) populateCompanyIDOptions(dataAux);
    if (typeFilter) populateTypeOptions(dataAux);
    
    // Pass the data result to the parent component
    if(Configs.devEnvironment) console.log ("[filterWidget.js][reApplyAllFilters()] dataAux: ", dataAux);

    return dataAux;
    // onFilterChange(dataAux);
  }

// --------------------------------------------------------------------------------
// Render
// --------------------------------------------------------------------------------
if (availableTeamTypeOptions?.length > 0 || availableManagerOptions?.length > 0 
    || availableAreasOptions?.length > 0 || availableSubareasOptions?.length > 0 || availableEmployeeOptions?.length > 0
    || availableCompanyIDOptions?.length > 0 || availableTypeOptions?.length > 0)  {
    return (
        <div className="flex flex-wrap items-center space-x-1 m-4">
            {/* Filter icon */}
            <div className='flex flex-col items-center'>
                <Tooltip content={t('widgets.filter.tooltip')}>
                    <AiOutlineFilter className="flex text-lg text-gray-500 mr-2" />
                </Tooltip>
            </div>
            {/* Filter title */}
            {title && <span className="text-xs font-semibold text-gray-600">{title + ": "}</span>}
    
            {/* Direct team or Org filter or Entire company filter */}
            {teamTypeFilter && 
                    <Select
                    className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                    styles={Configs.widgetDropdownStyle}

                        options={availableTeamTypeOptions}
                        defaultValue={defaultTeamTypeFilter ? availableTeamTypeOptions?.find(option => option?.value === defaultTeamTypeFilter) : null}
                        value={availableTeamTypeOptions?.find(option => option?.value === selectedTeamTypeFilter)}
                        placeholder={t('widgets.filter.select-team')}
                        onChange={(e) => handleFilterValueChange(e, "teamType")}
                    />
            }
    
            {/* Area filter */}
            {areaFilter && availableAreasOptions?.length > 0 && availableAreasOptions[0]?.value !== "" &&
                    <Select
                    className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                    styles={Configs.widgetDropdownStyle}

                        options={availableAreasOptions}
                        isMulti={true}
                        placeholder={t('widgets.filter.select-area')}
                        onChange={(e) => handleFilterValueChange(e, "area")}
                    />
            } 
    
             {/* Subarea filter */}
             {subareaFilter && availableSubareasOptions?.length > 0 && availableSubareasOptions[0]?.value !== "" &&
                    <Select
                        className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                        styles={Configs.widgetDropdownStyle}

                        options={availableSubareasOptions}
                        isMulti={true}
                        placeholder={t('widgets.filter.select-subarea')}
                        onChange={(e) => handleFilterValueChange(e, "subarea")}
                    />
            }

            {/* Manager filter */}
            {managerFilter && availableManagerOptions?.length > 0 &&
                    <Select
                    className=" rounded-lg hover:bg-gray-200 text-xs text-left"
    
                    styles={Configs.widgetDropdownStyle}

                        options={availableManagerOptions}
                        placeholder={t('widgets.filter.select-manager')}
                        isMulti={true}
                        onChange={(e) => handleFilterValueChange(e, "manager")}
                    />
            }

            {/* Employee filter */}
            {employeeFilter && availableEmployeeOptions?.length > 0 &&  
                <Select
                className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                styles={Configs.widgetDropdownStyle}

                    options={availableEmployeeOptions}
                    placeholder={t('widgets.filter.select-employee')}
                    isMulti={true}
                    onChange={(e) => handleFilterValueChange(e, "employee")}
                />
            }

            {/* CompanyID filter */}
            {companyIDFilter && availableCompanyIDOptions?.length > 0 &&  
                <Select
                className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                styles={Configs.widgetDropdownStyle}

                    options={availableCompanyIDOptions}
                    placeholder={t('widgets.filter.select-company')}
                    isMulti={true}
                    onChange={(e) => handleFilterValueChange(e, "companyID")}
                />
            }

            {/* Type filter */}
            {typeFilter && availableTypeOptions?.length > 0 &&  
                <Select
                className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                styles={Configs.widgetDropdownStyle}

                    options={availableTypeOptions}
                    placeholder={t('widgets.filter.select-type')}
                    isMulti={true}
                    onChange={(e) => handleFilterValueChange(e, "type")}
                />
            }
            
        </div>
      );

} else {
    return null;
}

};

export default FilterWidget;