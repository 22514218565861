// React
import React, {useState, useCallback} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Common
import Configs from '../Configs';
import {headersInfo} from '../constants/headersInfo';
import { useAuth } from './appContext';
import { useData } from './dataContext';
import * as apiCalls from './apiCalls';

// Icons
import {IoLogOut, IoPersonAdd, IoPulseSharp, IoSettings } from "react-icons/io5";
import {MdOutlinePunchClock} from "react-icons/md";
import {BsHouseFill, BsPersonBadgeFill, BsFillInboxFill, BsLadder} from "react-icons/bs";
import {FaBalanceScale, FaRegBell } from "react-icons/fa";
import {CgPerformance} from "react-icons/cg";
import {SiCrowdsource} from "react-icons/si";
import {GiArcheryTarget, GiPalmTree, GiOrganigram, GiPlantSeed, GiPoliceOfficerHead} from 'react-icons/gi';
import {FaPeopleArrows, FaAward} from 'react-icons/fa';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import {PiPlantBold} from 'react-icons/pi';

// ---------------------------------------------------------------------------------------------------
const Header = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);

  // Employee and company info
  const {employeeID, companyID, token, securityLevel, setAuthenticated, setIsAzureAuthenticated, setIsGoogleAuthenticated} = useAuth();
  const {companyPreferences, setToasterMessage, logoPublicURL} = useData();
  const {pendingToDos} = useData();
  
  // To control access to features
  const maxSecurityLevel = securityLevel ? Math.max(...securityLevel) : null;
  const activeModules = companyPreferences ? companyPreferences?.activeModules : null;
  const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
  
  // To control personalization
  const [headerColor, setHeaderColor] = useState(companyPreferences?.personalization?.color ? companyPreferences?.personalization?.color.toString() : Configs.headerColor)
  const [secondaryColor, setSecondaryColor] = useState(companyPreferences?.personalization?.secondaryColor ? companyPreferences?.personalization?.secondaryColor.toString() : Configs.secondaryColor);
  const theme = companyPreferences?.personalization?.theme ? companyPreferences?.personalization?.theme.toString() : "light";

  // To control mobile navBar
  const [navbarOpen, setNavbarOpen] = useState(false);

//   if (Configs.devEnvironment) console.log ("[header.js][main()] Header - Active modules: ", activeModules);
//   if (Configs.devEnvironment) console.log ("[header.js][main()] Header - SecurityLevel: ", maxSecurityLevel, securityLevel);
//   if (Configs.devEnvironment) console.log ("[header.js][main()] Header - Company Preferences: ", companyPreferences);
//   if (Configs.devEnvironment) console.log ("[header.js][main()] Header - location: ", getPathParts(location.pathname));

 // ---------------------------------------------------------------------------------------------------
 // Page start methods
 // ---------------------------------------------------------------------------------------------------    
  const hasNotifications = (pendingToDos?.length > 0 && pendingToDos?.find(element => element.status === Configs.notificationStatusUnread)) ? true : false;

  const renderIcon = useCallback((element) => {
    switch (element) {
        case "Home":
        return (
            <div className='flex flex-row items-start relative'>
                {hasNotifications && <span className="h-2 w-2 -top-1 absolute right-2 bg-red-500 text-xs rounded-full mr-1 animate-pulse" />}
                <BsHouseFill />
            </div>
          );
        //   Develop
          case "Recruitment":
            return (
              <SiCrowdsource />
            );
          case "Onboarding":
        return (
            <IoPersonAdd />
        );
        case "Development":
        return (
            <PiPlantBold />
        );
        case "Career":
        return (
            <BsLadder />
        );
        // Perform
        case "Performance-Review":
        return (
            <CgPerformance />
        );
        case "Calibration":
          return (
              <FaBalanceScale />
          );
          case "Goals":
        return (
            <GiArcheryTarget />
        );
        
        // Engage
        case "1on1":
        return (
            <FaPeopleArrows />
        );
        case "Recognition":
          return (
              <FaAward />
          );
        case "Pulse":
        return (
            <IoPulseSharp />
        );
        // Basics
        case "Time-off":
        return (
          <GiPalmTree />
        );
        case "PunchInOut":
        return (
            <MdOutlinePunchClock />
        );
        case "Profile":
        return (
           <BsPersonBadgeFill />
        );
        case "Inbox":
        return (
            <BsFillInboxFill />  
        );
        case "Organigram":
        return (
            <GiOrganigram />
        );
        case "Admin":
        return (
            <GiPoliceOfficerHead/>
        );
    }
}, []);

    
    const sideHeaderData = headersInfo;

    // ---------------------------------------------------------------------------------------------------
    // Page main function
    // ---------------------------------------------------------------------------------------------------
    async function logout() {
        try {
            const response = await apiCalls.logout(companyID, employeeID, token);
            if (response?.status === 200) {
                setAuthenticated(false);
                setIsGoogleAuthenticated(false);
                setIsAzureAuthenticated(false);
                sessionStorage.clear();
                navigate('/');
                setToasterMessage({message:t('logout.success'), type:Configs.successToaster});
                // window.location.href = Configs.devEnvironment ? "http://localhost:3000" : "https://platform.kincode.app";
            } else {
                console.error('[header.js][logout()] We could not log out');
                setToasterMessage({message:t('error'), type:Configs.errorToaster});
                sessionStorage.clear();
                window.location.href = Configs.devEnvironment ? "http://localhost:3000" : "https://platform.kincode.app";
            }
        } catch (error) {
            console.error('[header.js][logout()] We could not log out:', error);
        }
    }

    // function getCurrentPathWithoutLastPart (string) {
    //     const pathRgx = /\//g;
    //     const childroutecount = ((string || '').match(pathRgx) || []).length
    //     // console.log (childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string)
    //     return childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string;
    // }

    function getPathParts(string) {
        const parts = string.split('/');
        return parts;
    }

    // ---------------------------------------------------------------------------------------------------
    // Page return methods
    // ---------------------------------------------------------------------------------------------------
    if (companyPreferences != null) {

                return (
                    <React.Fragment>
                        {/* DESKTOP HEADER */}
                            {/* <nav className={"flex flex-col justify-between fixed left-0 top-0 min-h-screen h-screen z-50 w-[16.5rem] p-2 rounded-tr-4xl rounded-br-4xl overflow-y-auto " + */}
                        <nav className={"flex flex-col justify-between fixed left-0 top-0 min-h-screen h-screen z-50 w-[215px] p-2 rounded-tr-4xl rounded-br-4xl overflow-y-auto shadow " +
                            " hidden lg:block " + headerColor }>
                                                    <div className='flex flex-col w-full py-6 items-center'>
                                                            {/* COMPANY LOGO */}
                                                            <div className="flex justify-center ">
                                                                <div className="flex container-sm w-[150px] h-[50px] justify-center">
                                                                    <img className="object-scale-down max-h-[75px]"
                                                                        alt={companyInfo?.companyName + " logo"}
                                                                        src={logoPublicURL ? logoPublicURL : Configs.companyLogoSmall}
                                                                        />
                                                                </div>
                                                            </div>
                                                

                                                            {/* MODULES SECTION */}
                                                            <ul className="flex flex-col items-start w-full px-3 py-8 space-y-2 ">

                                                                {sideHeaderData?.map((element, index) => (
                                                                    // If the user does not have permission to access the element, don't show it
                                                                    (securityLevel?.includes(element?.securityLevel) || maxSecurityLevel >= element?.securityLevel) ? 

                                                                        !activeModules[element.icon] ? null :
                                                                            <Link className="w-full items-start text-left " to={element.url}>
                                                                                    <li key={index}
                                                                                    className={("/" + getPathParts(location.pathname)[1] + "/") === element.url 
                                                                                                || ("/" + getPathParts(location.pathname)[1]) === element.url 
                                                                                                || ("/" + getPathParts(location.pathname)[1] + "/" + getPathParts(location.pathname)[2] + "/") === element.url
                                                                                                || ("/" + getPathParts(location.pathname)[1] + "/" + getPathParts(location.pathname)[2] ) === element.url
                                                                                                || (currentRoute === element.icon) ?
                                                                                                    // Selected item
                                                                                                    (" flex flex-row items-center w-full px-4 py-3 bg-gray-200 rounded border-l-4 font-bold  "
                                                                                                    + (theme === "dark" ? " text-gray-800 border-gray-800 hover:text-gray-800 " : " text-gray-800 border-gray-800 hover:text-gray-800 "  )
                                                                                                    ) 
                                                                                                :
                                                                                                    // Unselected items
                                                                                                    ("flex flex-row items-center w-full px-4 py-3 rounded "
                                                                                                    + (theme === "dark" ? " text-gray-100 border-gray-100 hover:text-gray-800 hover:bg-gray-100  " : " text-gray-800 hover:text-gray-800  hover:bg-gray-200 "  )
                                                                                                    )
                                                                                    }
                                                                                    onClick={() => setCurrentRoute(element.icon)}
                                                                                    >
                                                                                            <div className='text-lg '>
                                                                                                {renderIcon(element.icon)} 
                                                                                            </div>
                                                                                            <div className={"text-sm font-medium ml-4 w-full"}>
                                                                                                {t(element.icon)}
                                                                                                {/* Beta */}
                                                                                                {element.icon === "Recruitment" && <span className="text-xs bg-yellow-400 text-xs px-2 py-1 rounded-full mx-1 font-semibold">BETA</span>}
                                                                                                {element.icon === "Development" && <span className="text-xs bg-yellow-400 text-xs px-2 py-1 rounded-full mx-1 font-semibold">BETA</span>}
                                                                                                {element.icon === "Goals" && <span className="text-xs bg-yellow-400 text-xs px-2 py-1 rounded-full mx-1 font-semibold">BETA</span>}
                                                                                                {element.icon === "PunchInOut" && <span className="text-xs bg-yellow-400 text-xs px-2 py-1 rounded-full mx-1 font-semibold">BETA</span>}

                                                                                            </div>
                                                                                    </li>
                                                                            </Link>
                                                                    : null
                                                                    
                                                                ))}
                                                            </ul>
                                                        </div>

                                                    <div className="flex flex-col w-full text-gray-800 p-4 space-y-2">
                                                        {/* Separation bar */}
                                                            <div className="h-0.5 w-full bg-gray-400 text-lg mb-12 " />

                                                        {/* Settings and Logout */}
                                                            <Link className="w-full items-start text-left"  to="/settings/">
                                                                <div className={("/" + getPathParts(location.pathname)[1] + "/") === "/settings/" ?
                                                                    "flex flex-row px-4 py-3 items-center hover:bg-gray-200 hover:rounded text-lg bg-gray-200 rounded border-l-4 font-bold border-gray-800"
                                                                    :
                                                                ("flex flex-row px-4 py-3 items-center hover:bg-gray-200 hover:rounded text-lg " 
                                                                    + (theme === "dark" ? " text-gray-100 border-gray-100 hover:text-gray-800 hover:bg-gray-100 " : " text-gray-800 hover:text-gray-800  hover:bg-gray-200 " )) }
                                                                 >
                                                                    <div className="flex text-xl cursor-pointer">
                                                                        <span className='text-lg'>
                                                                            <IoSettings />
                                                                        </span>
                                                                        <div className={"text-sm ml-4 w-full font-medium"}>
                                                                                    {t('settings')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <button className={"flex px-4 py-3 w-full items-start text-left hover:bg-gray-200 hover:rounded" 
                                                            + (theme === "dark" ? " text-gray-100 border-gray-100 hover:text-gray-800 hover:bg-gray-100 " : " text-gray-800 hover:text-gray-800  hover:bg-gray-200 " )}
                                                            onClick={logout}>
                                                                <div className='flex flex-row w-full items-center '>
                                                                    <span className='text-lg'>
                                                                        <IoLogOut />
                                                                    </span>
                                                                    <div className={"text-sm ml-4 w-full font-medium"}>
                                                                            {t('logout')}
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                            </nav>
                            {/* MOBILE HEADER */}
                                <nav className={"flex flex-row left-0 top-0 z-50 max-h-16 fixed w-full justify-between items-center py-2 rounded-tr-4xl rounded-br-4xl shadow " + "block lg:hidden " + headerColor}>
                                        
                                                            <div className="flex flex-row items-center">
                                                                <Link to="/">
                                                                    <div
                                                                        className="flex container-sm ml-2 lg:ml-8 w-[150px] max-h-[75px]
                                                                                    lg:w-[150px] lg:h-[75px]">
                                                                        <img
                                                                            className="flex object-scale-down"
                                                                            alt={companyInfo?.companyName + " logo"}
                                                                            src={logoPublicURL ? logoPublicURL : Configs.companyLogoSmall}
                                                                        />
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                            <div className="flex flex-row items-center justify-between">
                                                                <button 
                                                                    onClick={() => setNavbarOpen(!navbarOpen)} 
                                                                    className={"flex items-center mr-4 px-3 py-2 text-2xl "
                                                                    + (theme === "dark" ? " text-white hover:text-gray-800 " : " text-gray-800 hover:text-gray-400 " )}>
                                                                        { navbarOpen ?
                                                                            <AiOutlineClose />
                                                                        :
                                                                            <AiOutlineMenu />
                                                                        }
                                                                </button>
                                                            </div>

                                                            <div className={ navbarOpen ? " flex flex-col items-end fixed inset-0 h-auto overflow-y-auto max-h-full py-4 " + headerColor : " hidden "}>
                    
                                                                                <button 
                                                                                    onClick={() => setNavbarOpen(!navbarOpen)} 
                                                                                    className={"flex items-center mr-4 px-3 py-2 text-2xl "
                                                                                    + (theme === "dark" ? " text-white hover:text-gray-800 " : " text-gray-800 hover:text-gray-400 " )}>
                                                                                    { navbarOpen ?
                                                                                        <AiOutlineClose />
                                                                                    :
                                                                                        <AiOutlineMenu />
                                                                                    }
                                                                                </button>

                                                                                <div className="flex flex-row w-full items-center">
                                                                                        <div className='flex flex-col py-6 items-center w-full'>
                                                                                                <div className='flex flex-row items-center text-gray-800'>
                                                                                                    <div className="flex text-4xl ">
                                                                                                        <div className="container-sm w-[50px] h-[50px]">
                                                                                                            <img
                                                                                                                className="object-scale-down"
                                                                                                                alt={companyInfo?.companyName + " logo"}
                                                                                                                src={logoPublicURL ? logoPublicURL : Configs.companyLogoSmall}
                                                                                                                />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='hidden text-sm ml-4 font-bold'>
                                                                                                            {companyInfo?.companyName}
                                                                                                    </div>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>


                                                                                <div className="h-0.5 w-full bg-gray-400 text-lg" />
                                                                                
                                                                                {/* All modules */}
                                                                                <ul className="flex flex-col items-start w-full px-3 py-8 space-y-2">
                                                                                {sideHeaderData?.map((element, index) => (
                                                                                    // If the user does not have permission to access the element, don't show it
                                                                                    (securityLevel?.includes(element?.securityLevel) || maxSecurityLevel >= element?.securityLevel) ? 

                                                                                        !activeModules[element.icon] ? null :

                                                                                        <Link className="w-full items-start text-left " to={element.url}>
                                                                                            <li key={index}
                                                                                            className={("/" + getPathParts(location.pathname)[1] + "/") === element.url 
                                                                                                        || ("/" + getPathParts(location.pathname)[1]) === element.url 
                                                                                                        || ("/" + getPathParts(location.pathname)[1] + "/" + getPathParts(location.pathname)[2] + "/") === element.url
                                                                                                        || ("/" + getPathParts(location.pathname)[1] + "/" + getPathParts(location.pathname)[2] ) === element.url
                                                                                                        || (currentRoute === element.icon) ?
                                                                                                            // Selected item
                                                                                                            (" flex flex-row items-center w-full px-4 py-3 bg-gray-200 rounded border-l-4 font-bold  "
                                                                                                            + (theme === "dark" ? " text-gray-800 border-gray-800 hover:text-gray-800 " : " text-gray-800 border-gray-800 hover:text-gray-800 "  )
                                                                                                            ) 
                                                                                                        :
                                                                                                            // Unselected items
                                                                                                            ("flex flex-row items-center w-full px-4 py-3 rounded "
                                                                                                            + (theme === "dark" ? " text-gray-100 border-gray-100 hover:text-gray-800 hover:bg-gray-100  " : " text-gray-800 hover:text-gray-800  hover:bg-gray-200 "  )
                                                                                                            )
                                                                                            }
                                                                                            onClick={() => setCurrentRoute(element.icon)}
                                                                                            >
                                                                                                    <div className='text-lg '>
                                                                                                        {renderIcon(element.icon)} 
                                                                                                    </div>
                                                                                                    <div className={"text-sm font-medium ml-4 w-full"}>
                                                                                                        {t(element.icon)}
                                                                                                    {element.icon === "PunchInOut" && <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>}
                                                                                                    </div>
                                                                                            </li>
                                                                                    </Link>
                                                                                    : null
                                                                                    
                                                                                ))}
                                                                                </ul>

                                                                                <div className="flex flex-col w-full text-gray-800 p-4 space-y-2">
                                                                                    {/* Separation bar */}
                                                                                        <div className="h-0.5 w-full bg-gray-400 text-lg mb-12 " />

                                                                                    {/* Settings and Logout */}
                                                                                        <Link className="w-full items-start text-left"  to="/settings/">
                                                                                            <div className={("/" + getPathParts(location.pathname)[1] + "/") === "/settings/" ?
                                                                                                "flex flex-row px-4 py-3 items-center hover:bg-gray-200 hover:rounded text-lg bg-gray-200 rounded border-l-4 font-bold border-gray-800"
                                                                                                :
                                                                                            ("flex flex-row px-4 py-3 items-center hover:bg-gray-200 hover:rounded text-lg " 
                                                                                                + (theme === "dark" ? " text-gray-100 border-gray-100 hover:text-gray-800 hover:bg-gray-100 " : " text-gray-800 hover:text-gray-800  hover:bg-gray-200 " )) }
                                                                                            >
                                                                                                <div className="flex text-xl cursor-pointer">
                                                                                                    <span className='text-lg'>
                                                                                                        <IoSettings />
                                                                                                    </span>
                                                                                                    <div className={"text-sm ml-4 w-full font-medium"}>
                                                                                                                {t('settings')}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                        <button className={"flex px-4 py-3 w-full items-start text-left hover:bg-gray-200 hover:rounded" 
                                                                                        + (theme === "dark" ? " text-gray-100 border-gray-100 hover:text-gray-800 hover:bg-gray-100 " : " text-gray-800 hover:text-gray-800  hover:bg-gray-200 " )}
                                                                                        onClick={logout}>
                                                                                            <div className='flex flex-row w-full items-center '>
                                                                                                <span className='text-lg'>
                                                                                                    <IoLogOut />
                                                                                                </span>
                                                                                                <div className={"text-sm ml-4 w-full font-medium"}>
                                                                                                        {t('logout')}
                                                                                                </div>
                                                                                            </div>
                                                                                        </button>
                                                                                    </div>
                                                            </div>
                                </nav>
                </React.Fragment>
            );
    } else {
        return (
            <React.Fragment>
                    Something is wrong
            </React.Fragment>
        );
    }
    


}

export default Header;