// React
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// 3P
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

// Configs
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import Configs from '../../Configs';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';
import * as apiCalls from '../../common/apiCalls';

// Icons
import { MdOutlinePunchClock, MdPlayArrow, MdPause, MdStop } from "react-icons/md";


// ----------------------------------------------------------------------
//  Punch In/Out Widget
// ----------------------------------------------------------------------
const PunchWidget = () => {
    const {t} = useTranslation();
    const {employeeID, companyID, token} = useAuth();

    const {companyPreferences, setToasterMessage} = useData();

    // Loader
    const [punchInOutLoading, setPunchInOutLoading] = useState(false);

    // Variables
    const [isPunchedIn, setIsPunchedIn] = useState(false);
    const [isOnHold, setIsOnHold] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);

// --------------------------------------------------------------------
//  UseEffect
// --------------------------------------------------------------------
  useEffect(() => {
    if (startTime === null) getCurrentPunchStaus();

    // Time update
    let interval = null;
    if (isPunchedIn) {
        interval = setInterval(() => {
          setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
      }, 1000); // Updates every second
    } else if (!isPunchedIn && startTime !== null) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isPunchedIn, startTime]);

// --------------------------------------------------------------------
// API calls 
// --------------------------------------------------------------------
async function getCurrentPunchStaus () {
  try {
      setPunchInOutLoading(true);

      const response = await apiCalls.getPunchStatusForEmployee(companyID, employeeID, token);

      setPunchInOutLoading(false);

      if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[punchInOut.js][getCurrentPunchStaus()] response: ", response);

        if (response.length > 0){
          // Check if the user has punched either in or out today
          let today = new Date();
          today.setHours(0,0,0,0);

          // format of new Date() is month/day/year
          let punchDate = new Date(response[0]?.month + "/" + response[0]?.day + "/" + response[0]?.year);                
          punchDate.setHours(0,0,0,0);

          if (today.getDate() === punchDate.getDate() && today.getMonth() === punchDate.getMonth() && today.getFullYear() === punchDate.getFullYear()){
            // The employee has punched today
            if (Configs.devEnvironment) console.log ("[punchInOut.js][getCurrentPunchStaus()] Employee has punched today");

            // Recalculate elapsed time
            if (Configs.devEnvironment) console.log ("[punchInOut.js][getCurrentPunchStaus()] Total seconds", response[0]?.totalSeconds, " - Start time", response[0]?.punches[response[0]?.punches?.length-1]?.date);

            
            setStartTime(new Date(response[0]?.punches[response[0]?.punches?.length-1]?.date));

            // Set the state according to the type
            if (response[0]?.punches[0].type === "in") {
              if (Configs.devEnvironment) console.log("[punchInOut.js][getCurrentPunchStaus()] Employee is punched in: ", new Date(response[0]?.punches[0].date));
              setIsPunchedIn(true);

              // Calculate elapsed time as totalSeconds + time since last punch
              let diff = Math.abs(new Date() - new Date(response[0]?.punches[0]?.date));
              let seconds = Math.floor(diff / 1000);
              setElapsedTime(response[0]?.totalSeconds + seconds);
            } else {
              setIsPunchedIn(false);
              setElapsedTime(response[0]?.totalSeconds);
            }
          } else {
            // The user did not punch today, therefore they are not punched in
            if (Configs.devEnvironment) console.log ("[punchInOut.js][getCurrentPunchStaus()] Employee has NOT punched today");
            setIsPunchedIn(false);
          }
        }
      }

    } catch (error) {
      setPunchInOutLoading(false);
      console.error("[punchInOut.js][getCurrentPunchStaus()] Error fetching data: ", error);
    }
    
  }

async function postPunch () {
    // The backend should handle the logic of punching in or out - we will just tell it to punch and the current frontend time and day
    // HTTP POST request options
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        employeeID: employeeID,
        companyID: companyID,
        date: new Date(),
      })
    };

    // Post to server      
    fetch(Configs.platformPostTimeOffPunchAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, requestOptions)
      .then((response) => {
        if (response.status === 200) {

          response.json()
          .then ( body => {
            // All good
            if (Configs.devEnvironment) console.log("[punchInOut.js][postPunch()] - Punched in/out successfully - status:", body?.type);
            if (body?.type === "in") {
              setIsPunchedIn(true);
              setStartTime(new Date());
            } else {
              setIsPunchedIn(false);
            }


          })
          .catch(error => {
            console.error("[punchInOut.js][postPunch()] Error parsing response JSON", error);
          });
        } else if (response.status === 400) {
          // Force logout

        } else {
          // There is an error - delete info
          // throw response;
        }
      })

      .catch((error) => {
        console.log("[punchInOut.js][postPunch()] Error fetching data: ", error);
      });


}

// --------------------------------------------------------------------
//  Handlers
// --------------------------------------------------------------------
  const handlePunchIn = () => {
    setIsPunchedIn(true);
    setStartTime(new Date());

    // Call API to punch in
    postPunch();
  };

  const handlePunchOut = () => {
    setIsPunchedIn(false);
    setStartTime(null);
    // setElapsedTime(0); // Reset or handle accumulated time as needed

    // Call API to punch in
    postPunch();
  };

  // Format elapsed time into hours, minutes, and seconds
  const formatElapsedTime = () => {
    const hours = Math.floor(elapsedTime / 3600);
    const minutes = Math.floor((elapsedTime % 3600) / 60);
    const seconds = Math.floor(elapsedTime % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

// --------------------------------------------------------------------
//  Render
// --------------------------------------------------------------------
  if (companyPreferences?.activeModules?.PunchInOut === true) {
      
      return (
        <div className="flex flex-col items-center justify-center bg-white shadow-lg border border-gray-200 rounded-lg p-4 lg:p-8 mb-4 lg:my-8 w-full">
          {/* Widget title */}
          <h3 className='flex flex-row items-center justify-start text-left card-title w-full mb-4'>
            <MdOutlinePunchClock className='text-xl lg:text-2xl mr-2'/> 
            <Link to="/punch/" className='hover:underline hover:font-bold'>
              {t('punch-in-out.widget.title')} 
            </Link>
          </h3>
    
          {punchInOutLoading ?
            <Loader />
          :
            <React.Fragment>
                    {/* Widget data */}
                    <div className="flex flex-row lg:flex-col items-center w-full">
                        <span className='flex flex-row items-center justify-start text-left w-full title-description '>
                            {t('punch-in-out.widget.elapsedTime')}
                        </span>
                        <div className='flex flex-row items-center justify-end lg:justify-center space-x-4 w-full m-4'>
                            {isPunchedIn ?
                            // Punched in
                              <span className='text-base lg:text-xl  font-bold text-gray-600'>
                                {formatElapsedTime()}
                              </span>
                            :
                              // Not punched in but there is elapsed time
                              elapsedTime > 0 ?
                                <span className='text-base lg:text-xl font-bold text-gray-600'>
                                  {formatElapsedTime()}
                                </span>
                              :
                                // Not punched in and no elapsed time
                                <span className='title-description italic'>
                                    {t('punch-in-out.widget.not-started')}
                                </span>
                            }
                            {/* <span className='text-2xl lg:text-4xl'>
                                {isPunchedIn ? <MdPlayArrow className='text-green-400'/> : <MdStop className='text-red-500'/>}
                            </span> */}
                        </div>
                      </div>
    
                    {/* Widget actions */}
                    <div className='flex flex-row items-center lg:justify-end justify-center w-full'>
                        <Tooltip content={isPunchedIn ? t('pinch-in-out.widget.punch-out') : t('pinch-in-out.widget.punch-in')}>
                          <button onClick={isPunchedIn ? handlePunchOut : handlePunchIn} className={isPunchedIn ? " remove-button " : " save-button  "}>
                            {!isPunchedIn ? <MdPlayArrow className='lg:text-xl text-lg text-green-600 mx-6 lg:mx-auto '/> : <MdStop className='lg:text-xl text-lg text-red-200 mx-6 lg:mx-auto'/>}
                          </button>
                        </Tooltip>
                    </div>
                </React.Fragment>
            }
        </div>
      );

  } else {
      return null;
  }

};

export default PunchWidget;
