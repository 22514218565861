// React
import React, {useState, useLocation, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";

// Common and Header
import Header from '../../common/header';
import SettingsHeader from './settingsHeader';
import Bot from '../../common/support/bot';
import Configs from "../../Configs";
import { useAuth } from '../../common/appContext';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Widgets
import UserListItem from '../../widgets/userListItem';
import SectionSeparator from '../../widgets/sectionSeparator';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';

// Icons
import { IoPersonAdd, IoPulseSharp } from "react-icons/io5";
import { BsPersonBadgeFill, BsFillInboxFill} from "react-icons/bs";
import {FaBalanceScale } from "react-icons/fa";
import {CgPerformance} from "react-icons/cg";
import {SiCrowdsource} from "react-icons/si";
import {GiArcheryTarget, GiOrganigram, GiPalmTree} from 'react-icons/gi';
import {PiPlantBold} from 'react-icons/pi';
import {BsLadder} from 'react-icons/bs';
import {FaPeopleArrows, FaAward} from 'react-icons/fa';
import {BsToggleOn, BsToggleOff} from 'react-icons/bs';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useData } from '../../common/dataContext';
import Tooltip from '../../common/support/tooltip';

function SettingsCompany () {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const {token, companyID, employeeID, securityLevel} = useAuth();
  const {companyPreferences, logoPublicURL, companyBackgroundImageURL} = useData();
  const {toasterMessage, setToasterMessage} = useData();
  const employeeInfo = sessionStorage.getItem('employeeInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('employeeInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null ;

  // Toaster and loader variables
  const [isLoading, setIsLoading] = useState(false);

  const frequencyDropdownOptions = [
    { value: 'weekly', label: t('weekly') },
    { value: 'fortnightly', label: t('fortnightly') },
    { value: 'monthly', label: t('monthly') },
  ]

  const [updatedCompanyPreferences, setUpdatedCompanyPreferences] = useState(companyPreferences);

  // All preferences object
  if (Configs.devEnvironment) console.log("[settingsCompany.js][main()] - companyPreferences: ", companyPreferences);

  // State objects
  // Active modules
  const [oneOnOnes, setOneOnOnes] = useState(companyPreferences?.activeModules["1on1"] ? companyPreferences.activeModules["1on1"] : false);
  const [pulse, setPulse] = useState(companyPreferences?.activeModules?.Pulse ? companyPreferences.activeModules.Pulse : false);
  const [recognition, setRecognition] = useState(companyPreferences?.activeModules?.Recognition ? companyPreferences.activeModules.Recognition : false);
  const [performance, setPerformance] = useState(companyPreferences?.activeModules["Performance-Review"] ? companyPreferences.activeModules["Performance-Review"] : false);
  const [calibration, setCalibration] = useState(companyPreferences?.activeModules?.Calibration ? companyPreferences.activeModules.Calibration : false);
  const [goals, setGoals] = useState(companyPreferences?.activeModules?.Goals ? companyPreferences.activeModules.Goals : false);
  const [recruitment, setRecruitment] = useState(companyPreferences?.activeModules?.Recruitment ? companyPreferences.activeModules.Recruitment : false);
  const [onboarding, setOnboarding] = useState(companyPreferences?.activeModules?.Onboarding ? companyPreferences.activeModules.Onboarding : false);
  const [career, setCareer] = useState(companyPreferences?.activeModules?.Career ? companyPreferences.activeModules.Career : false);
  const [development, setDevelopment] = useState(companyPreferences?.activeModules?.Development ? companyPreferences.activeModules.Development : false);
  const [organigram, setOrganigram] = useState(companyPreferences?.activeModules?.Organigram ? companyPreferences.activeModules.Organigram : false);
  const [inbox, setInbox] = useState(companyPreferences?.activeModules?.Inbox ? companyPreferences.activeModules.Inbox : false);
  const [profile, setProfile] = useState(companyPreferences?.activeModules?.Profile ? companyPreferences.activeModules.Profile : false);
  const [timeOff, setTimeOff] = useState(companyPreferences?.activeModules["Time-off"] ? companyPreferences.activeModules["Time-off"] : false);
  const [punch, setPunch] = useState(companyPreferences?.activeModules.PunchInOut ? companyPreferences.activeModules.PunchInOut : false);
  // HR POCs
  const [hrPOCs, setHrPOCs] = useState(companyPreferences?.HRPOCs ? companyPreferences.HRPOCs : [null]);
  // Company values
  const [companyValues, setCompanyValues] = useState(companyPreferences?.companyValues ? companyPreferences.companyValues : [null]);
  // Personalization
  const [companyLogo, setCompanyLogo] = useState(companyPreferences?.personalization?.logoLarge ? companyPreferences.personalization.logoLarge : null);
  const [backgroundImageURL, setBackgroundImageURL] = useState(companyPreferences?.personalization?.backgroundImageURL ? companyPreferences.personalization.backgroundImageURL : null);
  const [companyColor, setCompanyColor] = useState(companyPreferences?.personalization?.color ? companyPreferences.personalization.color : null);
  const [companyEmailsHeader, setCompanyEmailsHeader] = useState(companyPreferences?.personalization?.emailsHeaderColor ? companyPreferences.personalization.emailsHeaderColor : null);
  const [companyEmailHelpURL, setCompanyEmailHelpURL] = useState(companyPreferences?.personalization?.pulseEmailsHelpURL ? companyPreferences.personalization.pulseEmailsHelpURL : null);
  // Modules settings
  const [oneOnOneReminders, setOneOnOneReminders] = useState(companyPreferences?.modulesSettings?.oneOnOneReminders ? companyPreferences?.modulesSettings?.oneOnOneReminders : false);
  const [oneOnOneRemindersFrequency, setOneOnOneRemindersFrequency] = useState(companyPreferences?.modulesSettings?.oneOnOneRemindersFrequency ? companyPreferences?.modulesSettings?.oneOnOneRemindersFrequency : "weekly");
  const [recognitionNewsletters, setRecognitionNewsletters] = useState(companyPreferences?.modulesSettings?.recognitionNewsletters ? companyPreferences?.modulesSettings?.recognitionNewsletters : false);
  const [recognitionNewslettersFrequency, setRecognitionNewslettersFrequency] = useState(companyPreferences?.modulesSettings?.recognitionNewslettersFrequency ? companyPreferences?.modulesSettings?.recognitionNewslettersFrequency : "monthly");
  const [recognitionReminders, setRecognitionReminders] = useState(companyPreferences?.modulesSettings?.recognitionReminders ? companyPreferences?.modulesSettings?.recognitionReminders : false);
  const [recognitionRemindersFrequency, setRecognitionRemindersFrequency] = useState(companyPreferences?.modulesSettings?.recognitionRemindersFrequency ? companyPreferences?.modulesSettings?.recognitionRemindersFrequency : "monthly");
  const [pulseReporting, setPulseReporting] = useState(companyPreferences?.modulesSettings?.pulseReporting ? companyPreferences?.modulesSettings?.pulseReporting : false);
  const [pulseMonthlyManagerReport, setPulseMonthlyManagerReport] = useState(companyPreferences?.modulesSettings?.pulseMonthlyManagerReport ? companyPreferences?.modulesSettings?.pulseMonthlyManagerReport : false);
  const [pulseKPIs, setPulseKPIs] = useState(companyPreferences?.modulesSettings?.pulseKPIs ? companyPreferences?.modulesSettings?.pulseKPIs : false);
  const [employeeConfig, setEmployeeConfig] = useState(companyPreferences?.modulesSettings?.employeeConfig ? companyPreferences?.modulesSettings?.employeeConfig : false);

  // ---------------------------------------------------------------------------------------------------
  // After render methods
  // ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    useEffect (() => {
          // Add event listeners for when the user wants to leave the page 
    const handleBeforeUnload = (event) => {
      // Perform actions before the user leaves the page
      event.preventDefault();
      event.returnValue = ''; // Some browsers require a return value to display a prompt
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

      // Get information to populate dashboard
      if (companyPreferences === null) getCompanySettings();

      // Clean-up before leving view
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        // clearInterval(interval);
      };

    }, []);

// ---------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------

  async function getCompanySettings () {
    setIsLoading(true);

    // fetch from server      
    fetch(Configs.platformGetCompanyPreferencesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      }
    })
        .then((response) => {
          setIsLoading(false);
          // console.log (response);
          if (response.status === 200) {
            response.json()
            .then (body => {
                if (Configs.devEnvironment) console.log("[settingsCompany.js][getCompanySettings()] - body from request: ", body[0]);
                setUpdatedCompanyPreferences(body[0]);
                sessionStorage.setItem("companyPreferences", CryptoJS.AES.encrypt(JSON.stringify(body[0]), Configs.privateKey).toString());
              })
            .catch(error => {
              console.error("[settingsCompany.js][getCompanySettings()] Error parsing response JSON", error);
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              setIsLoading(false);
            });
          } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('warning'), type: Configs.warningToaster});
          } else if (response.status === 401) {
              // Force logout
              setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
              sessionStorage.clear();
              window.location.reload();
          } else if (response.status === 403) {
              // Force logout
              setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
              sessionStorage.clear();
              window.location.reload();
          } else if (response.status === 404) {
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
          } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
          }
        })
        .catch((error) => {
          console.error("[settingsCompany.js][getCompanySettings()]Error fetching data: ", error);
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
          setIsLoading(false);
        });
}

  async function saveSettings () {
    // set loading
    setIsLoading(true);

    if (Configs.devEnvironment) console.log("[settingsCompany.js][saveSettings()] - updatedCompanyPreferences: ", updatedCompanyPreferences);

    fetch(Configs.platformPostSaveSettingsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      },      
      body: JSON.stringify({
        companyID: companyID,
        employeeID: employeeID,
        companyPreferences: updatedCompanyPreferences
      }),})
        .then((response) => {
          // if (Configs.devEnvironment) console.log (response);
          setIsLoading(false);

          if (response.status === 200) {
            setToasterMessage({message: t('success'), type: Configs.successToaster});

            // remove session settings
            sessionStorage.removeItem("companyPreferences");
            // get new settings
            getCompanySettings();

            // Pass toaster message and go back
            setToasterMessage({message: t('success'), type: Configs.successToaster});

          } else if (response.status === 400) {
            // Something went wrong - inform user
            setToasterMessage({message: t('warning'), type: Configs.warningToaster});
          } else if (response.status === 401) {
              // Force logout
              setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
          } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
          } else if (response.status === 404) {
                setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
          } else {
            //
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
            throw response;
          }
        })
  
        .catch((error) => {
          console.error("[settingsCompany.js][saveSettings()] error:", error);
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
          setIsLoading(false);
        });
  }

  // ---------------------------------------------------------------------------------------------------
  // Frontend management 
  // ---------------------------------------------------------------------------------------------------
  async function updatePreferences (e, setting) {
    if (Configs.devEnvironment) console.log("[settingsCompany.js][updatePreferences()] - setting: ", setting, e);

    // Aux variable
    const auxPreferences = updatedCompanyPreferences;
    console.log("[settingsCompany.js][updatePreferences()] - 1. auxPreferences: ", auxPreferences);


    switch (setting) {
      // Active modules
      // ENGAGE
      case "activeModules.oneOnOnes":
        // Toggle value
        auxPreferences.activeModules["1on1"] = e;
        setOneOnOnes(e);
        break;

      case "activeModules.pulse":
        // Toggle value
        auxPreferences.activeModules.Pulse = e;
        setPulse(e);
        break;

      case "activeModules.recognition":
        // Toggle value
        auxPreferences.activeModules.Recognition = e;
        setRecognition(e);
        break;

      // PERFORM
      case "activeModules.performance":
        // Toggle value
        auxPreferences.activeModules["Performance-Review"] = e;
        setPerformance(e);
        break;

      case "activeModules.calibration":
        // Toggle value
        auxPreferences.activeModules.Calibration = e;
        setCalibration(e);
        break;

      case "activeModules.goals":
        // Toggle value
        auxPreferences.activeModules.Goals = e;
        setGoals(e);
        break;
      
      // DEVELOP
      case "activeModules.recruitment":
        // Toggle value
        auxPreferences.activeModules.Recruitment = e;
        setRecruitment(e);
        break;
      case "activeModules.onboarding":
        // Toggle value
        auxPreferences.activeModules.Onboarding = e;
        setOnboarding(e);
        break;
      case "activeModules.career":
        // Toggle value
        auxPreferences.activeModules.Career = e;
        setCareer(e);
        break;
      case "activeModules.development":
        // Toggle value
        auxPreferences.activeModules.Development = e;
        setDevelopment(e);
        break;
  
      // BASICS
      case "activeModules.organigram":
        // Toggle value
        auxPreferences.activeModules.Organigram = e;
        setOrganigram(e);
        break;

      case "activeModules.inbox":
        // Toggle value
        auxPreferences.activeModules.Inbox = e;
        setInbox(e);
        break;

      case "activeModules.profile":
        // Toggle value
        auxPreferences.activeModules.Profile = e;
        setProfile(e);
        break;

      case "activeModules.timeOff":
        // Toggle value
        auxPreferences.activeModules["Time-off"] = e;
        setTimeOff(e);
        break;

      // -------------------
      // Personalization platform
      case "personalization.logo":
        // Update value
        auxPreferences.personalization.logoLarge = e.target.value;
        setCompanyLogo(e.target.value);
        break;
      
      case "personalization.platform.color":
        // Update value
        auxPreferences.personalization.color = e.target.value;
        setCompanyColor(e.target.value);
        break;

      case "personalization.emailsHeaderColor":
        // Update value
        auxPreferences.personalization.emailsHeaderColor = e.target.value;
        setCompanyEmailsHeader(e.target.value);
        break;

      case "personalization.pulseEmailsHelpURL":
        // Update value
        auxPreferences.personalization.pulseEmailsHelpURL = e.target.value;
        setCompanyEmailHelpURL(e.target.value);
        break;
      
      

      // -------------------  
      // Modules settings
      case "oneOnOneReminders":
        // Toggle value
        setOneOnOneReminders(e);
        auxPreferences.modulesSettings.oneOnOneReminders = e;
        break;

      case "oneOnOneRemindersFrequency":
        // Update value
        setOneOnOneRemindersFrequency(e.value);
        auxPreferences.modulesSettings.oneOnOneRemindersFrequency = e.value;
        break;

      case "recognitionNewsletters":
        // Update value
        setRecognitionNewsletters(e);
        auxPreferences.modulesSettings.recognitionNewsletters = e;
        break;

      case "recognitionNewslettersFrequency":
        // Update value
        setRecognitionNewslettersFrequency(e.value);
        auxPreferences.modulesSettings.recognitionNewslettersFrequency = e.value;
        break;

      case "recognitionReminders":
        // Update value
        setRecognitionReminders(e);
        auxPreferences.modulesSettings.recognitionReminders = e;
        break;
      case "recognitionRemindersFrequency":
        // Update value
        setRecognitionRemindersFrequency(e.value);
        auxPreferences.modulesSettings.recognitionRemindersFrequency = e.value;
        break;
      
      case "pulseReporting":
        // Update value
        setPulseReporting(e);
        auxPreferences.modulesSettings.pulseReporting = e;
        break;

      case "pulseMonthlyManagerReport":
        // Update value
        setPulseMonthlyManagerReport(e);
        auxPreferences.modulesSettings.pulseMonthlyManagerReport = e;
        break;

      default:
        break;
    }
    // Update state
    if (Configs.devEnvironment) console.log("[settingsCompany.js][updatePreferences()] - auxPreferences: ", auxPreferences);
    setUpdatedCompanyPreferences(auxPreferences);

  }

  // ---------------------------------------------------------------------------------------------------
  // HR POCs
  function updatePOCs (index, e) {
    // console.log ("[settingsCompany.js][updatePOCs()] - e: ", e.target.value, index);

    // Aux variable
    const auxHRPOCs = companyPreferences?.HRPOCs ? companyPreferences.HRPOCs : [null];
    const auxPreferences = companyPreferences;

    // Update value
    auxHRPOCs[index] = e.target.value;
    auxPreferences.HRPOCs = auxHRPOCs;

    // Update state
    setHrPOCs([...auxHRPOCs]);
    setUpdatedCompanyPreferences(auxPreferences);

  }

  function addHRPOCElement () {
    // Aux variable
    const auxHRPOCs = hrPOCs;

    // Update state
    setHrPOCs([...auxHRPOCs, null]);

  }

  function deleteElement (e, index) {
    const auxHRPOCs = hrPOCs;
    auxHRPOCs.splice(index, 1);
    // setArrayQuestions([...auxArray]);
    setHrPOCs([...auxHRPOCs]);
}

// ---------------------------------------------------------------------------------------------------
// Company values
function updateCompanyValue (e, index) {
  // console.log ("[settingsCompany.js][updateCompanyValue()] - e: ", e.target.value, index);

  // auxValue
  const auxValues = [...companyValues];
  const auxPreferences = companyPreferences;

  // Update value
  auxValues[index] = e.target.value;
  auxPreferences.companyValues = auxValues;

  // Update state
  setCompanyValues([...auxValues]);
  setUpdatedCompanyPreferences(auxPreferences);

}

function addCompanyValue () {
  // Aux variable
  const auxValues = companyValues;
  auxValues.push(null);

  // Update state
  setCompanyValues([...auxValues]);
}

function deleteValue (e, index) {
  const auxValues = companyValues;
  auxValues.splice(index, 1);

  setCompanyValues([...auxValues]);
}

function validateSettings () {
  console.log ("[settingsCompany.js][saveSettings()] - companyPreferences: ", companyPreferences);

  saveSettings();

}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
      };

// Cancel button pressed      
async function cancel (e) {
  e.preventDefault();

  if (!window.confirm(t('leavePage.warning'))) {    
    // Stay
  } else {
    // Navigate
    navigate(-1);
  }
}

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
        <SettingsHeader />

            
        <div className='flex flex-row items-start'>
              
            <div className="left-big-view">

        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        {isLoading ? 
        
            <Loader />

          :

            <div className="flex flex-col text-left ">
              <div className="flex flex-col items-start mt-2 md:ml-2 m-1">
                <Tooltip content={t('settings.company.description')} >
                  <h2 className="section-title mb-4 ">
                    {"⚙️ " + t('settings.company')}
                  </h2>
                </Tooltip>

              </div>


            {/*  Active modules  */}
            {/* ENGAGE */}
            <h2 className="subsection-title mt-2 md:ml-2 m-1">{t('settings.company.active-modules')}</h2>
            <div className="flex flex-col mt-2 md:ml-2 m-1 p-4 bg-white shadow-xl rounded-lg border">
                <div className='flex flex-col text-left mb-4 lg:ml-4 items-left pt-2'>
                  <h3 className='flex text-lg font-bold'>{t('settings.company.active-modules.engage')}</h3>
                    <ul className='w-full '>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><FaPeopleArrows /></span>
                              {t('1on1')}                    
                            </div>
                            {oneOnOnes ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.oneOnOnes')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.oneOnOnes')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><IoPulseSharp /></span>
                              {t('Pulse')}                    
                            </div>
                            {pulse ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.pulse')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.pulse')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><FaAward /></span>
                              {t('Recognition')}                    
                            </div>
                            {recognition ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.recognition')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.recognition')}/> 
                                }
                          </div>
                        </li>
                    </ul>
                </div>
                {/* PERFORM */}
                <div className='flex flex-col text-left mb-4 lg:ml-4 items-left border-t pt-2'>
                  <h3 className='flex text-lg font-bold'>{t('settings.company.active-modules.perform')}</h3>
                    <ul className='w-full '>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><CgPerformance /></span>
                              {t('Performance-Review')}                    
                            </div>
                            {performance ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.performance')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.performance')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><FaBalanceScale /></span>
                              {t('Calibration')}                    
                            </div>
                            {calibration ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.calibration')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.calibration')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><GiArcheryTarget /></span>
                              {t('Goals')}                    
                            </div>
                            {goals ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.goals')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.goals')}/> 
                                }
                          </div>
                        </li>
                      </ul>
                  </div>

                  {/* DEVELOP */}
                  <div className='flex flex-col text-left mb-4 lg:ml-4 items-left border-t pt-2'>
                    <h3 className='flex text-lg font-bold'>{t('settings.company.active-modules.develop')}</h3> 
                    <ul className='w-full'>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><SiCrowdsource /></span>
                              {t('Recruitment')}                    
                            </div>
                            {recruitment ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.recruitment')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.recruitment')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><IoPersonAdd /></span>
                              {t('Onboarding')}                    
                            </div>
                            {onboarding ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.onboarding')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.onboarding')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><BsLadder /></span>
                              {t('Career')}                    
                            </div>
                            {career? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.career')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.career')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><PiPlantBold /></span>
                              {t('Development')}                    
                            </div>
                            {development? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.development')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.development')}/> 
                                }
                          </div>
                        </li>
                      </ul>
                  </div>
                  {/* BASICS */}
                  <div className='flex flex-col text-left mb-4 lg:ml-4 items-left border-t pt-2'>
                      <h3 className='flex text-lg font-bold'>{t('settings.company.active-modules.basics')}</h3> 
                      <ul className='w-full'> 
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><GiOrganigram /></span>
                              {t('Organigram')}                    
                            </div>
                            {organigram ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.organigram')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.organigram')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><BsFillInboxFill /></span>
                              {t('Inbox')}                    
                            </div>
                            {inbox ? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.inbox')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.inbox')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><BsPersonBadgeFill /></span>
                              {t('Profile')}                    
                            </div>
                            {profile? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.profile')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.profile')}/> 
                                }
                          </div>
                        </li>
                        <li className='flex flex-row items-center mt-2 mb-2 p-2 hover:bg-gray-100 rounded'> 
                          <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row items-center'>
                              <span className='mr-6 text-xl'><GiPalmTree /></span>
                              {t('Time-off')}                  
                            </div>
                            {timeOff? 
                                <BsToggleOn className='lg:text-2xl text-xl cursor-pointer text-green-600' 
                                        onClick={() => updatePreferences(false, 'activeModules.timeOff')} /> 
                                : 
                                <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                  onClick={() => updatePreferences(true, 'activeModules.timeOff')}/> 
                                }
                          </div>
                        </li>
                    </ul>
                </div>
              </div>

              <SectionSeparator />
            
            {/*  Points of Contact  */}
            <h2 className="subsection-title mt-2 md:ml-2 m-1">{t('settings.company.personalization.POCs')}</h2>

            <div className="flex flex-col mt-2 md:ml-2 m-1 p-4 bg-white shadow-xl rounded-lg border">
                <div className='flex flex-col text-left mb-4 ml-4 items-left lg:items-left'>
                <ul className='text-left'>
                
                    {hrPOCs?.map((element, index) => (              
                        // Existing POCs                           
                          <div className='flex flex-row items-center justify-between p-2 space-x-4 rounded'>
                                { index === hrPOCs.length-1 ?
                                              <button
                                              id="addElement"
                                              className="save-button"
                                              onClick={addHRPOCElement}
                                          >
                                              +
                                          </button>
                                    : 
                                          <button
                                          id="addElement"
                                          className="save-button invisible"> +
                                          </button> 
                                    }        
                              <div className='flex flex-row items-center w-full space-x-2'>
                                <span className=''>{index+1}. </span>
                                <input className="input-field w-full" type="text" name="logoLarge" 
                                    placeholder={t('settings.company.hrpocs.empty.placeholder')}
                                    value={element ? element : null} 
                                    onChange={(e) => updatePOCs(index, e)}/> 
                              </div>
                                  
                              <div className='flex flex-row ml-2'>
                                { (hrPOCs.length > 1) ?
                                    <button id="removeElement" className={"remove-button"} onClick={(e) => deleteElement(e, index)} >
                                      -
                                    </button>
                                    :
                                    null 
                              }                                   
                            </div>  
                          </div> 
                    ))}
                    </ul>
                </div>
              </div>
              <SectionSeparator />

            {/*  Company values  */}
            <h2 className="subsection-title mt-2 md:ml-2 m-1">{t('company-values')}</h2>
            <div className="flex flex-col mt-2 md:ml-2 m-1 p-4 bg-white shadow-xl rounded-lg border">
                <div className='flex flex-col text-left mb-4 ml-4 items-left lg:items-left'>
                  <div className='flex flex-row items-center justify-between font-bold p-2 rounded '>
                    <div className='flex flex-col items-center w-1/3 space-x-4'>
                      {t('value')}
                    </div>
                    <div className='flex flex-col items-center w-2/3 space-x-4'>
                      {t('description')}
                    </div>
                  </div>
                              
                    {companyValues?.map((value, index) => (
                          <div className='flex flex-row text-left w-full items-start mt-2 space-x-2'>
                              { index === companyValues.length-1 ?
                                          <button id="addElement" className="save-button" onClick={addCompanyValue}>
                                            +
                                          </button>
                                    : 
                                          <button id="addElement" className="save-button invisible" onClick={addCompanyValue}>
                                          +
                                          </button>
                                    }        
                              <input type="text" name="value" key={"value_"+ index} value={value?.value} className=" input-field w-1/3 mr-4" onChange={(e) => updateCompanyValue(e, index)} />
                              <textarea name="value" key={"description_"+ index} value={value?.description} className=" text-xs input-field  w-2/3 mr-4" onChange={(e) => updateCompanyValue(e, index)} />
                              { (companyValues.length > 1) ?
                                      <button id="removeElement" className="remove-button" onClick={(e) => deleteValue(e, index)} >
                                            -
                                      </button>
                                      :
                                      null 
                                }  
                          </div>                      
                    ))}
                </div>

                
              </div>
              <SectionSeparator />

            

            {/*  Personalization */}
            <h2 className="subsection-title mt-2 md:ml-2 m-1">{t('settings.company.design')}</h2>
            <div className="flex flex-col mt-2 md:ml-2 m-1 p-4 bg-white shadow-xl rounded-lg border">
                    <div className='flex flex-col text-left mb-4 lg:ml-4 items-left space-y-4'>
                        <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-top'>
                          <label htmlFor="emailsHeaderColor" className="lg:w-1/4 text-left">{t('settings.company.logo')}:</label>
                          <div className='flex flex-col items-start lg:justify-start lg:w-3/4'>
                            <img className='m-2 w-1/2 lg:w-1/4 pt-2'
                            src={logoPublicURL} />
                          </div>
                        </div>
                        <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-top'>
                          <label htmlFor="emailsHeaderColor" className="lg:w-1/4 text-left">{t('settings.company.background-logo')}:</label>
                          <div className='flex flex-col items-start lg:justify-start lg:w-3/4'>
                            <img className='m-2 w-1/2 lg:w-1/4 pt-2'
                            src={companyBackgroundImageURL} />
                          </div>
                        </div>
                        <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center'>
                          <label htmlFor="emailsHeaderColor" className="lg:w-1/4 text-left">{t('settings.company.platform-color')}:</label>
                          <div className='flex flex-row items-center lg:w-3/4'>
                            <span className={" mr-2 rounded px-4 py-4 " + companyPreferences?.personalization?.color?.toString() } ></ span >
                            <input className="input-field w-full mr-2 " type="text" name="logoLarge" 
                              value={companyPreferences?.personalization?.color?.toString()}
                              onChange={(e) => updatePreferences(e, "personalization.platform.color")}/>
                          </div>
                        </div>
                    </div>

                  <div className='flex flex-col text-left mb-4 lg:ml-4 items-left space-y-4'>
                      <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center'>
                        <label htmlFor="emailsHeaderColor" className="lg:w-1/4 text-left">{t('settings.company.emails-header-color')}:</label>
                        <div className='flex flex-row items-center lg:w-3/4'>
                          <span className=" mr-2 rounded px-4 py-4"
                            style={{backgroundColor: companyPreferences?.personalization?.emailsHeaderColor}}  ></ span >
                          <input className="input-field w-full mr-2 " type="text" name="logoLarge" 
                            value={companyPreferences?.personalization?.emailsHeaderColor?.toString()}
                            onChange={(e) => updatePreferences(e, "personalization.emailsHeaderColor")}/> 
                        </div>
                      </div>
                      <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center'>
                        <label htmlFor="emailsHeaderColor" className="lg:w-1/4 text-left">{t('settings.company.pulse-emails-URL')}:</label>
                        <input className="input-field lg:w-3/4 mr-2 " type="text" name="logoLarge" 
                        value={companyPreferences?.personalization?.pulseEmailsHelpURL} 
                        onChange={(e) => updatePreferences(e, "personalization.pulseEmailsHelpURL")}/>
                      </div>
                  </div>
              </div>

              <SectionSeparator />

              {/*  Modules personalization */}
              <h2 className="subsection-title mt-2 md:ml-2 m-1">{t('company-values')}</h2>
              {companyPreferences?.activeModules["1on1"] || companyPreferences?.activeModules?.Onboarding ?       
                  <div className="flex flex-col mt-2 md:ml-2 m-1 p-4 bg-white shadow-xl rounded-lg border">
                      {oneOnOnes ? 
                        <div className=''>
                          <h2 className="flex items-center space-x-4 text-lg mb-4 font-bold"><FaPeopleArrows className='mr-2'/> {t('1on1')}</h2>
                          <div className='flex flex-col text-left mb-4 lg:ml-4 items-left space-y-4'>
                              <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center'>
                                <label htmlFor="oneOnOneReminders" className="lg:w-1/4 text-left">{t('settings.company.oneOnOne.reminders')}:</label>
                                {oneOnOneReminders === true ? 
                                      <BsToggleOn className='lg:text-2xl text-xl text-green-600 cursor-pointer' 
                                        onClick={() => updatePreferences(false, 'oneOnOneReminders')}/> 
                                      : 
                                      <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                        onClick={() => updatePreferences(true, 'oneOnOneReminders')}/> 
                                  }
                              </div>
                              {oneOnOneReminders ?  
                                  <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center w-full'>
                                    <label htmlFor="oneOnOneReminders.frequency" className="lg:w-1/4 text-left">{t('settings.company.oneOnOne.reminders.frequency')}:</label>
                                      <Select
                                            options={frequencyDropdownOptions}
                                            id="oneOnOneReminders.frequency"
                                            key="oneOnOneReminders.frequency"
                                            value={frequencyDropdownOptions?.find(object => object.value === oneOnOneRemindersFrequency)}
                                            placeholder={t('select-an-option')}
                                            styles={Configs.formDropdownStyle}
                                            className="lg:w-3/4 text-sm"
                                            onChange={(e) => updatePreferences(e, 'oneOnOneRemindersFrequency')}
                                            />
                                  </div>
                                :
                                null
                                }
                          </div>
                        </div>
                        :
                          null
                        }

                      {recognition ? 
                        <div className=''>
                          <h2 className="flex items-center space-x-4 text-lg mb-4 font-bold"><FaAward className='mr-2'/> {t('Recognition')}</h2>
                          <div className='flex flex-col text-left mb-4 lg:ml-4 items-left space-y-4'>
                              <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center'>
                                <label htmlFor="recognitionNewsletters" className="lg:w-1/4 text-left">{t('settings.company.recognition.newsletters')}:</label>
                                {recognitionNewsletters === true ? 
                                      <BsToggleOn className='lg:text-2xl text-xl text-green-600 cursor-pointer' 
                                        onClick={() => updatePreferences(false, 'recognitionNewsletters')}/> 
                                      : 
                                      <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                        onClick={() => updatePreferences(true, 'recognitionNewsletters')}/> 
                                  }
                              </div>
                              {recognitionNewsletters && 
                                <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center w-full'>
                                  <label htmlFor="recognition.frequency" className="lg:w-1/4 text-left">{t('settings.company.recognition.newsletters.frequency')}:</label>
                                    <Select
                                          options={frequencyDropdownOptions}
                                          id="recognition.frequency"
                                          key="recognition.frequency"
                                          value={frequencyDropdownOptions?.find(object => object.value === recognitionNewslettersFrequency)}
                                          placeholder={t('select-an-option')}
                                          styles={Configs.formDropdownStyle}
                                          className="lg:w-3/4 text-sm"
                                          onChange={(e) => updatePreferences(e, 'recognitionNewslettersFrequency')}
                                          />
                                </div>}
                                <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center'>
                                <label htmlFor="recognitionNewsletters" className="lg:w-1/4 text-left">{t('settings.company.recognition.reminders')}:</label>
                                {recognitionReminders === true ? 
                                      <BsToggleOn className='lg:text-2xl text-xl text-green-600 cursor-pointer' 
                                        onClick={() => updatePreferences(false, 'recognitionReminders')}/> 
                                      : 
                                      <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                        onClick={() => updatePreferences(true, 'recognitionReminders')}/> 
                                  }
                              </div>
                                {recognitionReminders && 
                                <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center w-full'>
                                  <label htmlFor="recognition.frequency" className="lg:w-1/4 text-left">{t('settings.company.recognition.reminders.frequency')}:</label>
                                    <Select
                                          options={frequencyDropdownOptions}
                                          id="recognition.frequency"
                                          key="recognition.frequency"
                                          value={frequencyDropdownOptions?.find(object => object.value === recognitionRemindersFrequency)}
                                          placeholder={t('select-an-option')}
                                          styles={Configs.formDropdownStyle}
                                          className="lg:w-3/4 text-sm"
                                          onChange={(e) => updatePreferences(e, 'recognitionRemindersFrequency')}
                                          />
                                </div>}
                          </div>
                        </div>
                        :
                          null
                        }

                      {pulse && 
                        <div className=''>
                          <h2 className="flex items-center space-x-4 text-lg mb-4 font-bold"><IoPulseSharp className='mr-2'/> {t('Pulse')}</h2>
                          <div className='flex flex-col text-left mb-4 lg:ml-4 items-left space-y-4'>
                              <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center'>
                                <label htmlFor="pulseReporting" className="lg:w-1/4 text-left">{t('settings.company.pulse.reporting')}:</label>
                                {pulseReporting === true ? 
                                      <BsToggleOn className='lg:text-2xl text-xl text-green-600 cursor-pointer' 
                                        onClick={() => updatePreferences(false, 'pulseReporting')}/> 
                                      : 
                                      <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                        onClick={() => updatePreferences(true, 'pulseReporting')}/> 
                                  }
                              </div>
                              {pulseReporting && 
                                 <div className='flex flex-col lg:flex-row mb-4 md:mb-0 lg:items-center'>
                                 <label htmlFor="pulseMonthlyManagerReport" className="lg:w-1/4 text-left">{t('settings.company.pulse.monthlyManagerReporting')}:</label>
                                 {pulseMonthlyManagerReport === true ? 
                                       <BsToggleOn className='lg:text-2xl text-xl text-green-600 cursor-pointer' 
                                         onClick={() => updatePreferences(false, 'pulseMonthlyManagerReport')}/> 
                                       : 
                                       <BsToggleOff className='lg:text-2xl text-xl cursor-pointer' 
                                         onClick={() => updatePreferences(true, 'pulseMonthlyManagerReport')}/> 
                                   }
                                 </div> 
                                }
                          </div>
                        </div>}

                        {null && organigram && 
                        <div className=''>
                          <h2 className="flex items-center space-x-4 text-lg mb-4 font-bold"><GiOrganigram className='mr-2'/> {t('Organigram')}</h2>
                          <div className='flex flex-col text-left mb-4 lg:ml-4 items-left space-y-4'>
                              <div className='flex flex-col mb-4 md:mb-0 w-full items-start'>
                                <label htmlFor="employeeConfig" className="text-left">{t('settings.company.organigram.employeeConfig')}</label>

                                <div className='flex flex-col mb-4 md:mb-0 items-start'>
                                  {/* Organigram fields */}
                                  <div className='flex flex-col mb-4 md:mb-0 items-start'>
                                    <label htmlFor="organigramFields" className="text-left">{t('settings.company.organigram.fields')}:</label>

                                    {employeeConfig?.organigramFields?.map((attribute, index) => (
                                      <div className='flex flex-row items-center justify-between p-2 space-x-4 rounded w-full'>
                                        <label htmlFor="organigramFields" className="text-left">{attribute?.label}</label>
                                        <input type="text" name="value" key={attribute + "_value_"+ index} value={attribute?.name} className=" input-field w-1/3 mr-4"  />
                                        <input type="text" name="value" key={"description_"+ index} value={attribute?.placeholder} className=" text-xs input-field  w-2/3 mr-4"/>
                                        <input type="text" name="value" key={"description_"+ index} value={attribute?.tooltip} className=" text-xs input-field  w-2/3 mr-4"/>
                                        <input type="text" name="value" key={"description_"+ index} value={attribute?.type} className=" text-xs input-field  w-2/3 mr-4"/>
                                        <input type="checkbox" name="value" key={"description_"+ index} value={attribute?.required} className=" text-xs input-field"/>
                                        { (employeeConfig?.organigramFields?.length > 1) ?
                                            <button id="removeElement" className="remove-button"  >
                                                  -
                                            </button>
                                            :
                                            null
                                        }
                                    </div>
                                    ))}
                                  </div>
                                </div>
                              </div> 
                          </div>
                        </div>}

                    </div>
                :
                null
                }
              <SectionSeparator />

              {/* Save settings button */}
              {securityLevel?.includes(Configs.securityLevelHR) ? 

                <div className="flex flex-col m-4 lg:m-4 lg:flex-row items-center justify-end ">
                    <button id="cancel" className="secondary-button" onClick={cancel}> {t('cancel')} </button>
                    <button id="save" className="save-button" onClick={validateSettings}>{t('save')}</button>
                </div>

              :
                null
              }

              
            </div>
          }
      </div>
      </div>
      </div>
      </React.Fragment>
    );
}

export default SettingsCompany;