// React
import React, { useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

// Frameworks
import Configs from '../../Configs';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';

// Headers
import CareerHeader from './careerHeader';
import Header from '../../common/header';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import Editor from '../../widgets/editor';
import NonEditableEditor from '../../widgets/noneditableEditor';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineInfoCircle} from 'react-icons/ai';


// ---------------------------------------------------------------------------------------------------
function CareerGuidelineCreate () {
    // Params for edition
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    const navigate = useNavigate();
    const {t} = useTranslation();

    const {securityLevel, employeeID, companyID} = useAuth();
    const {companyOrg, companyPreferences, toasterMessage, setToasterMessage} = useData();
    const {jobLevellingGuidelinesData, isJobLevellingGuidelinesDataLoading, selectedJobLevellingGuideline} = useData();
    const {postJobLevellingGuideline, isJobLevellingGuidelineLoading} = useData();

    // Guideline object
    const [guideline, setGuideline] = useState(null);

    const jobGuidelinesConfigAux = {
        jobGuidelines: [
            {name: 'jobFamily', label: 'jobFamily', type: 'select', required: true, placeholder:  'career.guideline.create.jobFamily.placeholder'},
            {name: 'role', label: 'role', type: 'select', required: true, placeholder: 'career.guideline.create.role.placeholder'},
            {name: 'level', label: 'level', type: 'select', required: true, placeholder: 'career.guideline.create.level.placeholder'},
        ],
        jobGuidelinesDetails: [
            {name: 'ambiguity', label: 'ambiguity', type: 'textarea', required: true, placeholder: 'career.guideline.create.ambiguity.placeholder'},
            {name: 'scope', label: 'scope', type: 'textarea', required: true, placeholder: 'career.guideline.create.scope.placeholder'},
            // {name: 'influence', label: 'influence', type: 'textarea', required: true, placeholder: 'career.guideline.create.influence.placeholder'},
            {name: 'execution', label: 'execution', type: 'textarea', required: true, placeholder: 'career.guideline.create.execution.placeholder'},
            {name: 'communication', label: 'communication', type: 'textarea', required: true, placeholder: 'career.guideline.create.communication.placeholder'},
            {name: 'impact', label: 'impact', type: 'textarea', required: true, placeholder: 'career.guideline.create.impact.placeholder'},
            {name: 'processImprovement', label: 'processImprovement', type: 'textarea', required: true, placeholder: 'career.guideline.create.processImprovement.placeholder'},
            {name: 'experience', label: 'experience', type: 'textarea', required: true, placeholder: 'career.guideline.create.experience.placeholder'},            
        ]
    }

    const guidelinesConfig = companyPreferences?.modulesSettings?.jobGuidelines ? companyPreferences?.modulesSettings?.jobGuidelines : jobGuidelinesConfigAux;

    // Form options
    // Data to populate
    // Organigram infor
    const [jobFamilies, setJobFamilies] = useState([null]);
    const [roles, setRoles] = useState([null]);
    const [levels, setLevels] = useState([null]);

    if (Configs.devEnvironment) console.log("[careerGuidelinesCreate.js] params: ", params, selectedJobLevellingGuideline);
    if (Configs.devEnvironment) console.log("[careerGuidelinesCreate.js] context: ", guidelinesConfig, jobLevellingGuidelinesData);

// ---------------------------------------------------------------------------------------------------
// Methods to load when page is loaded
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        if (params && selectedJobLevellingGuideline) {
            // getProfile(params.employeeID); 
            setGuideline(selectedJobLevellingGuideline);
        } else {
            setGuideline({});
        }

        // Populate all dropdowns
        populateDropdowns();
      }, [companyOrg, selectedJobLevellingGuideline]);      

// ---------------------------------------------------------------------------------------------------
// Form management methods
// ---------------------------------------------------------------------------------------------------  
async function cancel () {
    // Go back to previous page
    navigate(-1);
}

async function validateInputsCreate () {
    if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][validateInputsCreate()] - START");
    
    // Check if all required === true from employeeConfig are filled
    var missingFields = [];

    // Iterate through entire employeeConfig object and check if all required fields are filled
    guidelinesConfig?.jobGuidelines?.forEach((field) => {
        if (field.required) {
            if (!guideline[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    guidelinesConfig?.jobGuidelinesDetails?.forEach((field) => {
        if (field.required) {
            if (!guideline[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    if (missingFields.length > 0) {
        if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][validateInputsCreate()] Missing fields: ", missingFields);
        setToasterMessage({type: Configs.warningToaster, message: t('field.missing')});
        return false;
    }

    
    // Create auxObject to be uploaded to the database
    var auxGuideline = guideline;
    auxGuideline.companyID = companyID;
    auxGuideline.employeeID = employeeID;

    if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][validateInputsCreate()] auxGuideline: ", auxGuideline);

    // Post employee
    postJobLevellingGuideline(auxGuideline);
}

async function validateInputsEdit  () {
    if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][validateInputsEdit()] - START");

    // Check if all required === true from employeeConfig are filled
    var missingFields = [];

    // Iterate through entire employeeConfig object and check if all required fields are filled
    guidelinesConfig?.jobGuidelines?.forEach((field) => {
        if (field.required) {
            if (!guideline[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });

    guidelinesConfig?.jobGuidelinesDetails?.forEach((field) => {  
        if (field.required) {
            if (!guideline[field.name]) {
                missingFields.push(field.name);
                field.missing = true;
            }
        }
    });


    if (missingFields.length > 0) {
        if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][validateInputsCreate()] Missing fields: ", missingFields);
        setToasterMessage({type: Configs.warningToaster, message: t('field.missing')});
        return false;
    }

    // Create auxObject to be uploaded to the database
    var auxGuideline = guideline;
    auxGuideline.companyID = params?.companyID;
    auxGuideline.employeeID = params?.employeeID;

    if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][validateInputsEdit()] auxEmployee: ", auxGuideline);

    // Put employee
    const response = postJobLevellingGuideline(auxGuideline);

    if (response === 200) navigate(-1);
}

async function populateDropdowns () {
    // From the list of employees in people, extract all the employees and list them alphabetycally
    let familiesAux = [];
    let addedFamilies = [];
    let rolesAux = [];
    let addedRoles = [];
    let levelsAux = [];
    let addedLevels = [];

    // Iterate through all employees
    jobLevellingGuidelinesData?.map((guideline) => {

        // Check if it has already been added
        if (! addedFamilies?.includes(guideline?.jobFamily)) {
            familiesAux.push({value: guideline?.jobFamily, label: guideline?.jobFamily});
            addedFamilies.push(guideline?.jobFamily);
        }


        // Check if it has already been added
        if (! addedRoles?.includes(guideline?.role)) {
            rolesAux.push({value: guideline?.role, label: guideline?.role});
            addedRoles.push(guideline?.role);
        }

        // Check if it has already been added
        if (! addedLevels?.includes(guideline?.level)) {
            levelsAux.push({value: guideline?.level, label: guideline?.level});
            addedLevels.push(guideline?.level);
        }


    });
    
    // Order alphabetically
    familiesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);
    rolesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);
    levelsAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

    // Update state
    setJobFamilies([...familiesAux]);
    setLevels([...levelsAux]);
    setRoles([...rolesAux]);    
}

async function createOption (e, type) {
    if (Configs.devEnvironment) console.log("[careerGuidelinesCreate.js][createOption()] Creating option:", e, type);

    switch (type) {
        case 'jobFamily':
            // Add area to current list of subareas
            let jobFamiliesAux = jobFamilies;
            jobFamiliesAux.push({value: e, label: e});
            
            // Order alphabetically
            jobFamiliesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setJobFamilies([...jobFamiliesAux]);

            // Set area
            var guidelineAux = guideline;
            guidelineAux.jobFamily = e;
            setGuideline(guidelineAux);
            
            break;
        case 'role':
            // Add role to current list of roles
            let rolesAux = roles;
            rolesAux.push({value: e, label: e});

            // Order alphabetically
            rolesAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setRoles([...rolesAux]);
            // Set role
            var guidelineAux = guideline;
            guidelineAux.role = e;
            setGuideline(guidelineAux);
            break;
        case 'level':
            // Add level to current list of levels
            let levelsAux = levels;
            levelsAux.push({value: e, label: e});

            // Order alphabetically
            levelsAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

            setLevels([...levelsAux]);
            // Set subarea
            var guidelineAux = guideline;
            guidelineAux.level = e;
            setGuideline(guidelineAux);
            break;
        default:
            break;
    }

    if (Configs.devEnvironment) console.log("[careerGuidelinesCreate.js][createOption()] guideline:", guidelineAux);
}

// ---------------------------------------------------------------------------------------------------
// Field management methods
// ---------------------------------------------------------------------------------------------------
const handleFieldChange = (e, section, field) => {
    if (Configs.devEnvironment) console.log("[careerGuidelinesCreate.js][handleFieldChange()] e:", e, "section:", section, "field:", field);

    var guidelineAux = JSON.parse(JSON.stringify(guideline));
    let fieldConfig = null;

    if (Configs.devEnvironment) console.log("[careerGuidelinesCreate.js][handleFieldChange()] employeeAux (before):", guidelineAux);

    switch (section) {
        case 'title':
            // Look for the field config in the employeeConfig 
            fieldConfig = guidelinesConfig.jobGuidelines?.find((element) => element?.name === field);

            if (fieldConfig?.type === 'date') {
                guidelineAux[field] = e.target.value;
            } else if (fieldConfig?.type === 'select') {
                guidelineAux[field] = e.value?.toString();
            } else {
                guidelineAux[field] = e.target.value;
            }
            break;
        case 'details':
            fieldConfig = guidelinesConfig.jobGuidelinesDetails?.find((element) => element.name === field);

            if (fieldConfig?.type === 'date') {
                guidelineAux[field] = e.target.value;
            } else if (fieldConfig?.type === 'select') {
                guidelineAux[field] = e.value?.toString();
            } else {
                guidelineAux[field] = e.target.value;
            }
            break;
        default:
            break;
    }

    if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][handleFieldChange()] employeeAux (after):", guidelineAux);

    if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][handleFieldChange()] fieldConfig:", fieldConfig);


    // Set State
    setGuideline(guidelineAux);

}

const handleEditorContentChange = (e, field) => {
    if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][handleEditorContentChange()] e:", e, "field:", field);

    var guidelineAux = JSON.parse(JSON.stringify(guideline));

    guidelineAux[field] = e;

    if (Configs.devEnvironment) console.log("[careerGuidelineCreate.js][handleEditorContentChange()] guidelineAux:", guidelineAux);

    // Set State
    setGuideline(guidelineAux);

}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
};

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
return (
      <React.Fragment>
      <Header />

      <Bot/>

      <div className="main">

        <CareerHeader />

        <div className='flex flex-row items-start'>
              
            <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
            
            <div className="flex flex-col mt-2 md:ml-2 m-1">
                    <div className='flex flex-col'>
                        <h2 className="flex text-xl mb-4 ">
                            <div className='flex flex-col items-start md:flex-row justify-left'>
                                
                                {params ?
                                    // View
                                    params?.isViewOnly ?
                                        <div id="title" className='section-title'>
                                            {guideline?.jobFamily} - {guideline?.role} - {guideline?.level}
                                        </div>
                                    :
                                    // Edit
                                    params?.isEdit ? 
                                        <Tooltip content={t('career.hr.guideline.edit.description')} >
                                            <div id="title" className='section-title'>
                                                {t('career.hr.guideline.edit.title')}
                                            </div>
                                        </Tooltip>
                                    :
                                    null
                                    :
                                    // Create
                                    <Tooltip content={t('career.hr.guideline.create.description')} >
                                        <div id="title" className='section-title'>
                                            {t('career.hr.guideline.create.title')}
                                        </div>
                                    </Tooltip>
                                    
                                }
                                <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>
                            </div>
                        </h2>
                    </div>
                </div>
                       
                <React.Fragment>
                    
                            <div className="flex flex-col mt-2 lg:ml-2 m-1 space-y-8 w-full text-xs">

                                {isJobLevellingGuidelineLoading || isJobLevellingGuidelinesDataLoading ?
                                    <Loader/>
                                :
                                guidelinesConfig && guideline ?
                                <React.Fragment>
                                    {/* Section title - Main */}
                                    {!params?.isViewOnly && 
                                    <div className='flex flex-col items-start text-left space-y-2'>
                                        <h2 className='flex flex-row items-center space-x-2 subsection-title'>
                                            {t('career.hr.guideline.main')}
                                        </h2>
                                    </div>
                                    }

                                    {isJobLevellingGuidelinesDataLoading ? 
                                        <Loader />
                                    :
                                    !params?.isViewOnly ?
                                    guidelinesConfig?.jobGuidelines?.map((field, index) => {
                                        return (
                                            <div class="flex flex-col items-start text-left space-y-1" key={index}>
                                                <div className='flex flex-row items-center'>
                                                    <label for={field.name} className="form-label">
                                                        {t(field.label)}
                                                        {(field.required && !field.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                        {(field.required && field.missing === true) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                                    </label>
                                                        {field.tooltip && <Tooltip content={field.tooltip} >
                                                            <AiOutlineInfoCircle className='flex text-gray-500 hover:cursor-pointer ml-2' />  
                                                        </Tooltip>}
                                                </div>
                                                <div className='flex w-full'>
                                                    <CreatableSelect
                                                        options={field?.name === 'jobFamily' ? jobFamilies : field?.name === 'role' ? roles : levels}
                                                        value= {field?.name === 'jobFamily' ? jobFamilies?.find((option) => option?.value?.toString() === guideline?.jobFamily?.toString()) : field?.name === 'role' ? roles?.find((option) => option?.value === guideline?.role) : levels.find((option) => option?.value === guideline?.level)}
                                                        id={field?.name}
                                                        className='w-full text-xs'
                                                        isDisabled={params?.isViewOnly}
                                                        isSearchable
                                                        placeholder={t(field?.placeholder)}
                                                        styles={Configs.formDropdownStyle}
                                                        onChange={(e) => handleFieldChange(e, "title", field?.name)}
                                                        onCreateOption={(e) => createOption(e, field?.name)}
                                                        /> 
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    null
                                    }

                                    {/* Section title - Details */}
                                    <div className='flex flex-col items-start text-left space-y-2'>
                                        <h2 className='flex flex-row items-center space-x-2 subsection-title'>
                                            {t('career.hr.guideline.details')}
                                        </h2>
                                    </div>

                                        {guidelinesConfig?.jobGuidelinesDetails?.map((details, index) => {
                                            return (
                                            <div class="flex flex-col items-start text-left space-y-1" key={index}>
                                                <div className='flex flex-row items-center'>
                                                    <label for={details?.name} className="form-label">
                                                        {t('career.guideline.create.' + details?.label)}
                                                        {(details?.required && !details?.missing) ? <span className='text-xs ml-1 font-bold'>*</span> : ''}
                                                        {(details?.required && details?.missing) ? <span className='text-xs ml-1 text-red-500 font-bold'>*</span> : ''}
                                                    </label>
                                                        {details.tooltip && <Tooltip content={details?.tooltip} >
                                                            <AiOutlineInfoCircle className='flex text-gray-500 hover:cursor-pointer ml-2' />  
                                                        </Tooltip>}
                                                </div>
                                                    <div className='flex w-full'>
                                                        <div class={"flex w-full rounded-md shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset " + (details?.missing === true ? " ring-2 ring-red-300  focus-within:ring-red-200 " : " ring-gray-300 focus-within:ring-green-200 ")}>
                                                        {   details?.type === "textarea" ?
                                                        
                                                            <div className='flex flex-col items-start w-full input-field'>
                                                                {params?.isViewOnly ?
                                                                    <NonEditableEditor content={guideline[details?.name]} />
                                                                :
                                                                    <Editor 
                                                                        className="w-full" setContent={(e) => {handleEditorContentChange(e, details?.name)} } content={guideline[details?.name]} initialContent={guideline[details?.name] ? guideline[details?.name] : null} 
                                                                        placeholder={t('career.guideline.create.realm.placeholder')}
                                                                    />
                                                                }
                                                            </div>

                                                            :
                                                            <input type={details.type} 
                                                            id={details.name} 
                                                            value={guideline ? (details?.type === "date" && guideline[details?.name] ? new Date(guideline[details?.name])?.toISOString()?.slice(0, 10) : guideline[details?.name]) : null}
                                                            onChange={(e) => {handleFieldChange(e, "personal", details.name)}}
                                                            className="input-field w-full" 
                                                            placeholder={t(details.placeholder)} />
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        
                                    </React.Fragment>
                                    :
                                    null
                                }
                            </div>

                            <SectionSeparator />


                    </React.Fragment>
                        
        
                    {securityLevel?.includes(Configs.securityLevelHR) ? 
                        <div className='flex flex-col lg:flex-row items-center justify-end lg:space-x-4 '>

                            {params && params?.isViewOnly ? 
                                <button type="button" id="cancelButton" class="secondary-button" onClick={cancel}> {t('back')} </button>
                            :
                                <button type="button" id="cancelButton" class="secondary-button" onClick={cancel}> {t('cancel')} </button>
                            }
                            {params ?
                            // View
                                params?.isViewOnly ?
                                    null
                                : 
                            // Edit
                                <button id="editButton" className='save-button' onClick={validateInputsEdit}>{t('career.hr.guideline.edit.title')}</button>
                            :
                            // Create   
                                <button id="createButton" className='save-button' onClick={validateInputsCreate}>{t('career.hr.guideline.create.title')}</button>
                            }

                        </div>
                    : 
                        null 
                    }
                
                    <SectionSeparator />
            
            </div>
            <div className='right-small-view'>



            </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default CareerGuidelineCreate;