// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import DaysOffHeader from '../../common/daysOffHeader';

import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import UserListItem from '../../widgets/userListItem';

// Widgets
import DataTable from '../../widgets/dataTable';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import CalendarWidget from '../../widgets/calendarView';
import Modal from '../../widgets/daysOff/requestModalView';
import Tooltip from '../../common/support/tooltip';

// Tooltip

// React icons
// import {AiOutlineFilter} from 'react-icons/ai';

// -------------------------------------------------------------------------------------------------------
function DaysOffHome () {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // Variables
    // var companyPolicy = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyPreferences'), Configs.privateKey).toString(CryptoJS.enc.Utf8));

    const [holidaysHistory, setHolidaysHistory] = useState(null);
    const [bankHolidays, setBankHolidays] = useState(null); // To highlight on calendar bank holidays
    const [listOfDatesHolidayHistory, setListOfDatesHolidayHistory] = useState(null); // To highlight on calendar approved holidays
    const [listOfPendingHolidays, setListOfPendingHolidays] = useState(null); // To highlight on calendar pending holidays
    const [listOfBankHolidays, setListOfBankHolidays] = useState(null); // To highlight on calendar bank holidays
    const [holidaysHistoryBody, setHolidaysHistoryBody] = useState(null);
    const [policy, setPolicy] = useState(null);

    // Employee credentials  
    const {token, employeeID, companyID, securityLevel} = useAuth();
    const {companyOrg} = useData();
    const {toasterMessage, setToasterMessage} = useData();

    // Toaster & loader
    const [isLoading, setIsLoading] = useState(false);

    // Delete modal
    const [requestToDelete, setRequestToDelete] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    // DataTable columns
    let columnNamesValuesTable = [
      {type:'string',id: 'leaveType', value: 'leaveType', label: t('time-off.home.leave-type'), sort: null, filter: null, translate: true},
      {type:'date',id: 'startDate',value: 'startDate', label: t('start-date'), sort: null, filter: null},
      {type:'date',id: 'endDate',value: 'endDate', label: t('end-date'), sort: null, filter: null},
      {type:'number',id: 'daysOffCount',value: 'daysOffCount', label: t('time-off.home.days-count'), sort: null, filter: null},
      {type:'string',id: 'status',value: 'status', label: t('status'), sort: null, filter: null, translate: true},
      {type:'action',id: 'action', value: 'action', label: "", sort: null, filter: null}
    ];

    if (Configs.devEnvironment) console.log ("[DaysOffHome.js][main()] - params: ", params, holidaysHistoryBody);


    // --------------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    // --------------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        if ((holidaysHistory === null && policy === null)|| (params === null && holidaysHistoryBody[0]?.employeeID !== employeeID)) {
          getHolidays();
        } 
        // if (holidaysHistory === null || (params === null && holidaysHistoryBody[0]?.employeeID !== employeeID)) {
        //   getHolidays();
        // } 
          
      }, [location.pathname, companyOrg]);

    // --------------------------------------------------------------------------------------------------------
    // API calls
    // --------------------------------------------------------------------------------------------------------
    async function getHolidays () {
      // Set loading
      setIsLoading(true);

        // fetch from server      
        fetch(Configs.platformGetTimeOffAPI + "?employeeID=" + (params ? params?.employeeID : employeeID) + 
                                              "&companyID=" + (params ? params?.companyID : companyID), {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
          }})
            .then((response) => {
              // if (Configs.devEnvironment) console.log("[DaysOffHome.js][getHolidays] - response: ", response);
              setIsLoading(false);
              
              if (response.status === 200) {
                response?.json()
                .then (body => {
                  if (Configs.devEnvironment) console.log ("[DaysOffHome.js][getHolidays] - body: ", body);
                  
                  // Days policy
                  if (body?.policy?.length > 0) {
                    // Store in state and session (encrypted)
                    setPolicy(body?.policy);
                    sessionStorage.setItem('daysOffPolicy', CryptoJS.AES.encrypt(JSON.stringify(body?.policy), Configs.privateKey).toString());
                  }

                  // Bank holidays
                  if (body?.bankHolidays?.length > 0 ) transformBankHolidays(body?.bankHolidays);

                  // Holidays requested
                  if (body?.holidays?.length > 0) {
                    transformHolidaysHistory(body?.holidays);
                    setHolidaysHistoryBody(body?.holidays);
                  } else {
                    // Empty holiday requests
                    setHolidaysHistory(null);
                    setHolidaysHistoryBody(null);
                  }
                  // Calculate time off balance
                  calculateTimeOffBalance(body);
                    
                })
                .catch(error => {
                  console.error("[DaysOffHome.js][getHolidays] Error parsing response JSON: ", error);
                  setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});

                });

              } else if (response.status === 204 ) {
                // Empty holiday requests
                setHolidaysHistory(null);
                setHolidaysHistoryBody(null);
              } else if (response.status === 401) {
                // Force logout
                sessionStorage.clear();
                window.location.reload();
              } else if (response.status === 403) {
                // Force logout
                sessionStorage.clear();
                window.location.reload();
              } else {
                //
                throw response;
              }
            })
            .catch((error) => {
              console.error("[DaysOffHome.js][getHolidays] Error fetching data: ", error);
              setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
              setIsLoading(false);
            });
    }

    async function deleteRequestAPI () {
      if(Configs.devEnvironment) console.log ("[DaysOffHome.js][deleteRequestAPI()] - requestToDelete: ", requestToDelete?._id?.toString());

      // Set loading
      setIsLoading(true);

      // fetch from server
      fetch(Configs.platformDeleteTimeOffAPI + "?companyID="+ (params ? params?.companyID : companyID) +
         "&employeeID=" + (params ? params?.employeeID : employeeID)
       + "&requestID=" + requestToDelete?._id?.toString(), {
        method: 'delete',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        },
        body: JSON.stringify({
          companyID: params ? params?.companyID : companyID,
          employeeID: params ? params?.employeeID : employeeID,
          requestID: requestToDelete?._id?.toString()})
        })
          .then((response) => {
            // if (Configs.devEnvironment) console.log("[DaysOffHome.js][deleteRequestAPI] - response: ", response);
            setIsLoading(false);
            
            if (response.status === 200) {
              response?.json()
              .then (body => {
                if (Configs.devEnvironment) console.log ("[DaysOffHome.js][deleteRequestAPI] - body: ", body);
                setIsDeleteModalOpen(false);
                getHolidays();
                // setToasterMessage({message: t('time-off.home.history.delete.success'), type: Configs.successToaster});
              })
              .catch(error => {
                console.error("[DaysOffHome.js][deleteRequestAPI] Error parsing response JSON: ", error);
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
              });

            } else if (response.status === 401) {
              // Force logout
                sessionStorage.clear();
                window.location.reload();
            } else {
              //
              throw response;
            }
          })
          .catch((error) => {
            console.error("[DaysOffHome.js][deleteRequestAPI] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            setIsLoading(false);
          });
    }

    async function downloadAttachmentAPI (fileID) {
      if(Configs.devEnvironment) console.log ("[DaysOffHome.js][downloadAttachmentAPI()] - fileID: ", fileID);

      // Set loading
      setIsLoading(true);

      // fetch from server
      fetch(Configs.platformGetDownloadFileAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&fileID=" + fileID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        }
        })
          .then((response) => {
            // if (Configs.devEnvironment) console.log("[DaysOffHome.js][downloadAttachmentAPI] - response: ", response);
            setIsLoading(false);

            if (response.status === 200) {
              response?.blob()
              .then (blob => {
                if (Configs.devEnvironment) console.log ("[DaysOffHome.js][downloadAttachmentAPI] - blob: ", blob);

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);
                // Create a temporary anchor element and trigger a download
                const a = document.createElement('a');
                a.href = url;
                if (params) {
                  a.download = params?.employeeName + "_Sick_" + fileID; // Provide a file name here
                } else {
                  a.download = "Sick_" + fileID; // Provide a file name here
                }
                document.body.appendChild(a); // Append to the document
                a.click(); // Trigger the download
                a.remove(); // Clean up
                window.URL.revokeObjectURL(url); // Revoke the blob URL
                })
              .catch(error => {
                console.error("[DaysOffHome.js][downloadAttachmentAPI] Error parsing response BLOB: ", error);
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
              });
            } else if (response.status === 401) {
              // Force logout

              sessionStorage.clear(); 
              window.location.reload();
            } else {
              //
              throw response;
            }
          })
          .catch((error) => {
            console.error("[DaysOffHome.js][downloadAttachmentAPI] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            setIsLoading(false);
          });
    }

    // --------------------------------------------------------------------------------------------------------
    // Methods
    // --------------------------------------------------------------------------------------------------------
    function transformHolidaysHistory(holidays) {
      var auxHolidaysHistory = [];

      holidays?.forEach (holidayRequest => {
        // Review each slot in the request
        holidayRequest?.request?.forEach (request => {

          // Push to table
          let objectAux = {
            leaveType: request?.leaveType,
            startDate: request?.startDate,
            endDate: request?.endDate,
            daysOffCount: request?.daysCount,
            status: holidayRequest?.status,
            fileId: request?.fileId ? request?.fileId : null,
            id: holidayRequest?._id
          };

          auxHolidaysHistory.push(objectAux);
        });
      });

      // Set state
      setHolidaysHistory([...auxHolidaysHistory]);

      // Transform list of intervals into list of days for Calendar
      transformHighlightedDates([...auxHolidaysHistory]);
      transformPendingDates([...auxHolidaysHistory]);
    }

    function transformHighlightedDates  (highlightedDates) {
      // Transform list of intervals into list of days
      if (Configs.devEnvironment) console.log('[daysOffHome.js][transformHighlightedDates()] highlightedDates:', highlightedDates);
      let auxHighlightedDates = JSON.parse(JSON.stringify([...highlightedDates]));

      // Transform list of intervals into list of days - Approved holidays
      let transformedDates = auxHighlightedDates?.map((date) => {
        if (date?.status === Configs.daysOffStatusApproved) {
          const startDate = new Date(date?.startDate);
          const endDate = new Date(date?.endDate);
          const days = [];
          const currentDate = new Date(startDate);
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
      }).flat();

      if (Configs.devEnvironment) console.log('[daysOffHome.js][transformHighlightedDates()] transformHighlightedDates:', transformedDates);

      setListOfDatesHolidayHistory([...transformedDates]);
    }

    function transformPendingDates  (pendingDates) {
      // Transform list of intervals into list of days
      if (Configs.devEnvironment) console.log('[daysOffHome.js][transformPendingDates()] pendingDates:', pendingDates);
      let auxPendingDates = JSON.parse(JSON.stringify([...pendingDates]));

      // Transform list of intervals into list of days - Pending holidays
      let transformedDates = auxPendingDates?.map((date) => {
        if (date?.status === Configs.daysOffStatusPending) {
          const startDate = new Date(date?.startDate);
          const endDate = new Date(date?.endDate);
          const days = [];
          const currentDate = new Date(startDate);
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
      }).flat();

      if (Configs.devEnvironment) console.log('[daysOffHome.js][transformHighlightedDates()] transformHighlightedDates:', transformedDates);

      setListOfPendingHolidays([...transformedDates]);
    }

    function transformBankHolidays (bankHolidays) {
      if (Configs.devEnvironment) console.log('[daysOffHome.js][transformBankHolidays()] bankHolidays:', bankHolidays);
      let auxBankHolidays = JSON.parse(JSON.stringify(bankHolidays));

      // Transform list of intervals into list of days
      let transformedDates = auxBankHolidays?.map((date) => {
        let dateAux = new Date(date?.date);
        return dateAux;
      }
      ).flat();

      if (Configs.devEnvironment) console.log('[daysOffHome.js][transformBankHolidays()] transformedDates:', transformedDates);

      setBankHolidays([...bankHolidays]);
      setListOfBankHolidays([...transformedDates]);

    }

    // ---------------------------------------------------------------------------------------------------
    // CRUD methods
    // --------------------------------------------------------------------------------------------------- 
    function editMethod (index, row) {
      if (Configs.devEnvironment) console.log ("[DaysOffHome.js][editMethod()] - editMethod", index, row);

      // if request is in the past, and already approved, do not allow to modify
      const dateDiff = Math.ceil((new Date(row?.endDate) - new Date(row?.startDate)) / (1000 * 60 * 60 * 24));
      if (((row?.status === Configs.daysOffStatusApproved) && dateDiff )|| row?.status === Configs.daysOffStatusRejected) {
        alert (t('time-off.home.history.delete-past.error'));
        return;
      }

      // Get the right request
      var auxRequest = holidaysHistoryBody?.filter(request => request?._id?.toString() === row?.id?.toString());

      // navigate to request page
      navigate("request/" + encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
        id: row?.id,                        
        request: auxRequest[0]?.request,
        comment: auxRequest[0]?.comment,
        employeeID: params ? params?.employeeID : employeeID,
        companyID: params ? params?.companyID : companyID,
      }),Configs.privateKey).toString()));
    }

    function deleteMethod (index, row) {
      if (Configs.devEnvironment) console.log ("[DaysOffHome.js][deleteMethod()] - deleteMethod", index, row);

      // if request is in the past, and already approved, do not allow to delete
      const dateDiff = Math.ceil((new Date(row?.endDate) - new Date(row?.startDate)) / (1000 * 60 * 60 * 24));
      if ((row?.status === Configs.daysOffStatusApproved && dateDiff) 
        || (row?.status === Configs.daysOffStatusRejected)) {
        if (!securityLevel?.includes(Configs.securityLevelHR)) {
          alert (t('time-off.home.history.delete-past.error'));
          return;
        }

        // Confirm alert
        if (!window.confirm(t('time-off.home.history.delete-past.confirm'))) {
          return;
        }

      }

      // Show a modal view, asking for confirmation to delete the full request not only the row selected --> from there the API might be called
      var auxRequestToDelete = holidaysHistoryBody?.filter(request => request?._id?.toString() === row?.id?.toString());
      setRequestToDelete(auxRequestToDelete[0]);
      setIsDeleteModalOpen(true);
    }

    async function viewAttachmentMethod (index, row) {
      if (Configs.devEnvironment) console.log ("[DaysOffHome.js][viewAttachmentMethod()] - viewAttachmentMethod", index, row);

      downloadAttachmentAPI(row?.fileId);

    }

    // ---------------------------------------------------------------------------------------------------
    async function calculateTimeOffBalance (body) {
    if (Configs.devEnvironment) console.log ("[DaysOffHome.js][calculateTimeOffBalance()] - 1. input: ", body);

      try {
        var requests = body?.holidays;
        var policy = body?.policy;

        // If no policy return
        if (policy?.length === 0) return;

        // Calculate time off balance
        var timeOffBalance = [];

        // Iterate over each policy type
        for (var i = 0; i < policy?.length; i++) {
          var policyType = policy[i];
          var available = policyType?.max;
          var total = policyType?.max;

          // Calculate available days based on holiday requests
          for (var j = 0; j < requests?.length; j++) {
            var request = requests[j];
            if (Configs.devEnvironment) console.log ("[DaysOffHome.js][calculateTimeOffBalance()] - request: ", request);

              // Iterate through each requested slot
              for (var k = 0; k < request?.request?.length; k++) {                
                var holiday = request?.request[k];
                if (Configs.devEnvironment) console.log ("[DaysOffHome.js][calculateTimeOffBalance()] - holiday: ", holiday);

                if (holiday?.leaveType === policyType?.leaveType && request?.status === Configs.daysOffStatusApproved) {
                  if (Configs.devEnvironment) console.log ("[DaysOffHome.js][calculateTimeOffBalance()] - holiday: ", holiday);
                  available -= holiday?.daysCount;
                }
              }
          }

          // Add to array
          timeOffBalance.push({
            leaveType: policyType?.leaveType,
            available: available,
            max: total
          });
        }

        // Set state
        setPolicy(timeOffBalance);

        // output has leaveType, available, and max

      } catch (e) {
        console.error ("[DaysOffHome.js][calculateTimeOffBalance()] Error in try-catch: ", e);
      }

    }
    
    
    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
    };


    // --------------------------------------------------------------------------------------------------------
    // Render methods
    // --------------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
        <DaysOffHeader />

        <div className='flex flex-row items-start'>
          <div className="left-big-view">

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
            {/* Title */}
            <div className="flex flex-col mt-2 md:ml-2 m-1">
                          <h2 className="flex text-xl text-left mb-4 ">
                              <div className='flex flex-col md:flex-row justify-left'>
                                <Tooltip content= {t('time-off.home.description')} >
                                <div className='section-title'>
                                  {t('time-off.home.title')}
                                </div>
                                </Tooltip>
                              </div>
                          </h2>
              </div>
              {/* Employee card in case of reviwing other's vacations */}
              {params ? 
                      <UserListItem user={params?.employeeName + " " + params?.employeeSurname} initials={params?.employeeName?.slice(0,1)+params?.employeeSurname?.slice(0,1)} 
                      role={params?.employeeRole} level={params?.employeeLevel}
                      area={params?.employeeArea} subarea={params?.employeeSubarea}
                      profilePicture={params?.employeeProfilePicture ? params?.employeeProfilePicture : null} />
              : null
              }

              {isLoading ?  
                <Loader/>
              :
                <React.Fragment>
                {/* KPIs */}
                <div className='flex flex-row justify-between space-x-4 m-4'>
                  {policy?.map((type, index) => (
                    type?.leaveType === Configs.daysOffLeaveTypeVacation ?
                      <div className='flex flex-col space-y-2 p-8 border rounded-lg shadow-lg bg-white my-2' key={index}>
                          <div className='flex flex-row items-center justify-start font-medium'>
                            {t(type?.leaveType)}
                          </div>
                          <div className='flex flex-row items-end justify-center'>
                            <span className='text-4xl font-bold'>
                              {type?.available}
                            </span>
                            <span className='text-gray-600 ml-1'>
                              / {type?.max}
                            </span>
                          </div>
                      </div>
                    :
                      null
                  ))}

                  {!params ?
                    // Only for self-employees
                      <div className='flex flex-row justify-end my-2 mx-4'>
                        <Link to="request">
                          <button className='save-button'> {t('time-off.home.request')} </button>
                        </Link>
                      </div>
                    :
                      // If HR or Manager, show 'add extra days' button
                      ((securityLevel?.includes(Configs.securityLevelManager) && (companyOrg?.find(employee => employee?.employeeID === params?.employeeID)?.managerID === employeeID)) 
                       || Configs.securityLevelHR ) ?
                        <div className='flex flex-row justify-end my-2 mx-4'>
                          {/* <Link to="request">
                            <button className='save-button'> {t('time-off.home.award-extra-days')} </button>
                          </Link> */}
                        </div>
                      :
                        null
                    }

                </div>

                {/* Past holidays */}
                <div className="flex flex-col mt-8 md:ml-2 m-1">
                    {/* Table with history */}
                    <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} request={requestToDelete} confirmDelete={deleteRequestAPI}/>
  
                      {holidaysHistory === null ?
                        <div className="flex flex-row items-center justify-center bg-white rounded-lg border pt-6 p-4 shadow">
                          <span className='title-description italic'> 
                          {"🔎 " + t('time-off.home.history.empty')} 
                          </span>
                        </div>

                      :
                        <div className="flex flex-col  mt-2 md:ml-2 m-1 p-4 bg-white rounded-lg shadow border">
                          {params ? 
                            // HR or Manager view
                            (securityLevel?.includes(Configs.securityLevelHR) ||  securityLevel?.includes(Configs.securityLevelExec))  ?
                                  <DataTable data={holidaysHistory} 
                                  columnNames={columnNamesValuesTable} 
                                  zerosForDashes={true}  
                                  title={t('time-off.home.history.title')}
                                  deleteMethod={deleteMethod}  
                                  viewAttachmentMethod={viewAttachmentMethod}
                                />
                              :
                                  <DataTable data={holidaysHistory} 
                                  columnNames={columnNamesValuesTable}
                                  zerosForDashes={true}  
                                  title={t('time-off.home.history.title')}
                                  viewAttachmentMethod={viewAttachmentMethod}
                                />

                          :
                          // IC - Self view
                          <DataTable data={holidaysHistory} columnNames={columnNamesValuesTable} zerosForDashes={true}  title={t('time-off.home.history.title')}
                            deleteMethod={deleteMethod} 
                            // editMethod={editMethod}
                            viewAttachmentMethod={viewAttachmentMethod}
                          />
                          }
                        </div>
                      }

                  </div>

                  </React.Fragment>
                  }
              
              <SectionSeparator />

          </div>

          {/* Calendar view */}
          <div className="right-small-view">
            <CalendarWidget 
              weekends={true}  
              highlightedDates={listOfDatesHolidayHistory}
              pendingDates={listOfPendingHolidays}
              events={bankHolidays}
            />
          </div>

          </div>
        </div>
      </React.Fragment>
    );

    
}

export default DaysOffHome;