// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link} from "react-router-dom";

// Common components
import Configs from '../../Configs';
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';


function PerformanceAdmin () {
    const [performanceReviewCampaign, setPerformanceReviewCampaign] = useState(sessionStorage.getItem('performanceCampaigns') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('performanceCampaigns'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null);

    const [livePerformanceCampaign, setLivePerformanceCampaign] = useState(sessionStorage.getItem('livePerformanceCampaign') ? CryptoJS.AES.decrypt(sessionStorage.getItem('livePerformanceCampaign'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : false);

    // var auxFeedbackProviders = performanceReviewCampaign ? performanceReviewCampaign[0].feedbackProviders.feedbackProviders : null;
    const [feedbackProviders, setFeedbackProviders] = useState(null);

    const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const { token } = useAuth();

    const [feedbackProvider, setFeedbackProvider] = useState("");
    const [providerAdded, setProviderAdded] = useState(false);
    const [existingProvider, setExistingProvider] = useState(false);
    const [incorrectFormat, setIncorrectFormat] = useState(false);

    if (Configs.devEnvironment) console.log ("DEV ONLY log - Campaigns: ", performanceReviewCampaign);


    // Methods to load when page is loaded
    useEffect (() => {
        // Get information to populate dashboard
        getLiveCampaigns();
      }, []);

    
  // ---------------------------------------------------------------------------------------------------
  // API calls methods
  // ---------------------------------------------------------------------------------------------------

  function getLiveCampaigns () {
      // fetch from server      
      fetch(Configs.platformGetLivePerformanceCampaignsAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
              // Username and password mathched in the database - save Live campaigns
              response.json()
              .then (body => {
                  console.log(body)
                  if (body.length > 0) {
                    sessionStorage.setItem('performanceCampaigns', CryptoJS.AES.encrypt(JSON.stringify(body),Configs.privateKey).toString());
                    sessionStorage.setItem('livePerformanceCampaign', CryptoJS.AES.encrypt("true",Configs.privateKey).toString());
                    setLivePerformanceCampaign(true);
                    setPerformanceReviewCampaign(body);
                    getFeedbackProviders(body[0]._id);
                    getDraft(body[0]._id);
                  } else {
                    setLivePerformanceCampaign(false);
                  }
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 403) {
                  sessionStorage.removeItem('performanceCampaigns');
                  sessionStorage.setItem('livePerformanceCampaign', CryptoJS.AES.encrypt(false,Configs.privateKey).toString());
                  setLivePerformanceCampaign(false);

            } else if (response.status === 400) {
              // Force logout
                sessionStorage.clear();
                window.location.reload();
            } else {
              // There is an error - delete info
              sessionStorage.removeItem('performanceCampaigns');
              sessionStorage.setItem('livePerformanceCampaign', CryptoJS.AES.encrypt("false",Configs.privateKey).toString());
              setLivePerformanceCampaign(false);

              throw response;
            }
          })
    
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
          });
    }

    async function getFeedbackProviders (campaignID) {
      // fetch from server      
      fetch(Configs.platformGetPerformanceFeedbackProvidersAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID)
          .then((response) => {
            if (response.status === 200) {
              // Username and password mathched in the database - get Token and save it in the session
              response.json()
              .then (body => {
                  setFeedbackProviders(body.feedbackProviders);
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 403) {
                  setFeedbackProviders(null);
            } else if (response.status === 400) {
              // Force logout
                sessionStorage.clear();
                window.location.reload();
            } else {
              // There is an error - delete info
              setFeedbackProviders(null);
              throw response;
            }
          })
    
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
          });
    }

    async function getDraft(campaignID) {
      // fetch from server      
      fetch(Configs.platformGetPerformanceDraftsAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID)
          .then((response) => {
            if (response.status === 200) {
              // Found a draft
              response.json()
              .then (body => {
                 // if (Configs.devEnvironment) console.log ("DEV ONLY log - draft: ", body);
                  sessionStorage.setItem('performanceReviewDraft', CryptoJS.AES.encrypt(JSON.stringify(body),Configs.privateKey).toString());
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 403) {
                 sessionStorage.removeItem('performanceReviewDraft');
            } else if (response.status === 400) {
              // Force logout
                sessionStorage.clear();
                window.location.reload();
            } else {
              // There is an error - delete info
              sessionStorage.removeItem('performanceReviewDraft');
              throw response;
            }
          })
    
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
          });
    }

    async function submitFeedbackProviders (providers) {
      // HTTP POST request options
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          employeeID: employeeID,
          companyID: companyID,
          campaignID: performanceReviewCampaign[0]._id,
          providers: providers,
        })
      };

      // Post to server      
      fetch(Configs.platformPostFeedbackProvidersAPI, requestOptions)
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
              // All good
              getLiveCampaigns();
              response.json()
              .then (body => {
                  // console.log(body)
                  
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 403) {
                  
            } else if (response.status === 400) {
              // Force logout
                sessionStorage.clear();
                window.location.reload();
            } else {
              // There is an error - delete info
              throw response;
            }
          })
    
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
          });
    }

    // ---------------------------------------------------------------------------------------------------
    // Frontend control methods
    // ---------------------------------------------------------------------------------------------------

    async function addFeedbackProvider (e) {
      e.preventDefault();

      // Reset settings
      setProviderAdded(false);
      setIncorrectFormat(false);
      setExistingProvider(false);

      // Review if provider is already added
      var existingEmail = false;
      feedbackProviders?.forEach(element => {
        if (element.email === feedbackProvider){
          // Existing address
          existingEmail = true;
          setExistingProvider(true)
        } 
      });

      // Review if format is correct
      if (feedbackProvider === undefined || feedbackProvider === "") {
        setIncorrectFormat(true);
      } else {
        var emailFormat = feedbackProvider.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (!emailFormat) {
          setIncorrectFormat(true);
        }
      }

      // Add 
      if (!existingEmail && emailFormat && feedbackProvider!== "") {
        // Set status
        setExistingProvider(false);
        setIncorrectFormat(false);

        // Get existing feedbackProviders
        var aux = feedbackProviders;
        var auxDate = new Date().toLocaleDateString();

        // Create object
        const newProvider ={
          email: feedbackProvider,
          requestedTime: auxDate
        }

        // Add new Feedback provider and update variable
        if (feedbackProviders) {
          aux.push(newProvider);
        } else {
          aux = [newProvider];
        }

        
        setProviderAdded(true);
        setFeedbackProviders([...aux]);
        submitFeedbackProviders(aux);

        // Clean-up fields
        setFeedbackProvider("");
      }
    }

    function deleteProvider(index) {
      // Get existing feedbackProviders
      var aux = feedbackProviders;

      // Delete the one selected
      aux.splice(index, 1);

      // Update array
      setFeedbackProviders([...aux]);
      submitFeedbackProviders(aux);
    }

   // ---------------------------------------------------------------------------------------------------
   // Render methods
   // ---------------------------------------------------------------------------------------------------
    console.log ("HERE", livePerformanceCampaign);

    if (livePerformanceCampaign === null || livePerformanceCampaign === false) {
      return (
      
          <React.Fragment>
            <Header />
            <Bot />

          <div className="flex flex-col lg:ml-[17rem]">
        

            <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign}/>
    
            <div className="flex flex-col text-left ">
              <div className="flex flex-col mt-2 md:ml-2 m-1">
                <h2 className="flex text-xl mb-4 font-bold">
                  There are currently no live performance review campaigns.
                </h2>
                <span className="text-left mb-4">
                  Here you will find live performance review campaigns. 
                  Your manager or HR can define performance review campaigns.
                  You will get feedback from peers and stakeholders you decide.
                  <b> How does the performance review process work? </b>
                </span>
              </div>
    
              </div>
            </div>
          </React.Fragment>
        );    
    } else {

        return (
          
          <React.Fragment>
          <Header/>
          <Bot />

          <div className="flex flex-col m-6 lg:ml-[14.5rem]">
        

            <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign}/>

            <div className="flex flex-col text-left ">
              <div className="flex flex-col mt-2 md:ml-2 m-1">
                <h2 className="flex text-xl mb-4 font-bold">
                  {" "}
                  🫂 {t('performance.admin.title')}
                </h2>
                <span className="text-left mb-4">
                  {t('performance.admin.description')}
                </span>
              </div>

              <div className=''>
                    
              </div>
                      

            
            </div>
            </div>
          </React.Fragment>
        );
  }
}

export default PerformanceAdmin;

