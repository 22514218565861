import React, {useLocation, useEffect} from 'react';
import Header from '../../common/header';
import { useState } from 'react';
import RecruitmentHeader from './recruitmentHeader';

// import Footer from '../common/footer';
import {Link} from "react-router-dom";

function RecruitmentReports () {


    

    return (
      <React.Fragment>
        <Header />

        <div className="main">
        <RecruitmentHeader />

          <div className="left-big-view">

            <div className="flex flex-col m-2 text-left ">
              <div className="flex flex-col mt-2 md:ml-2 m-1">
                <h2 className="section-title">
                  {" "}
                  For ad-hoc reports contact your Kincode represenative.
                </h2>
                <span className="text-left mb-4">
                  Here you can find predefined reports for Interviers or Candidate status.
                  <b> Who is my contact? </b>
                </span>
              </div>

            
            </div>
            </div>
        </div>
      </React.Fragment>
    );
}

export default RecruitmentReports;