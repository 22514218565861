// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader'; 

// Icons
import { GiOrganigram } from 'react-icons/gi';

// -----------------------------------------------------------------
function OrganigramHeader () {
    
    const screens = [
        {view:t('organigram.header.organigram'), url:"/organigram/", securityLevel:[Configs.securityLevelIC], id:"organigram.header.organigram"},
        {view:t('organigram.header.list'), url:"/organigram/employees", securityLevel:[Configs.securityLevelIC], id:"organigram.header.list"},
        {view:t('organigram.header.reorganize'), url:"/organigram/employees/reorganize", securityLevel:[Configs.securityLevelHR], id:"organigram.header.reorganize"},

    ];
    
   // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('Organigram')} Icon={GiOrganigram} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default OrganigramHeader;