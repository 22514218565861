import React, {useState} from "react";
import Configs from '../Configs';
import { useTranslation } from 'react-i18next';



/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function ValuesSelector(props) {
// Values that will be used 
if (Configs.devEnvironment) console.log(props);
// Translation object
const {t, i18n} = useTranslation();

const [numOfSelectedValues, setNumOfSelectedValues] = useState(0);
// Props
const [selectedValues, setSelectedValues] = useState(props.values);
const [type, setType] = useState(props.type);
const [readOnly, setReadOnly] = useState(props.readOnly);

// ------------------------------------------------------------------------------------------
// Form management methods
// ------------------------------------------------------------------------------------------
    function changedValue (e, value, description, index) {
        if (numOfSelectedValues <= 2) {
            // 3 or less values are selected
            if (e.target.checked === true) {
                setNumOfSelectedValues(numOfSelectedValues + 1);
            } else {
                setNumOfSelectedValues(numOfSelectedValues - 1);
            }
    
                // Create the object to update
                var selected = e.target.checked;
                var objectAnswer = {
                    value: value,
                    description: description,
                    selected: selected
                }
    
                // Get an auxiliary array to update
                var auxSelectedValues = selectedValues;
                auxSelectedValues[index] = objectAnswer;
                
                // Update state values
                setSelectedValues([...auxSelectedValues]);
                props.selectedValues([...auxSelectedValues], props.type);
                
        } else {
            // Too many values are selected
            if (e.target.checked === false) {
                // Unselect
                setNumOfSelectedValues(numOfSelectedValues-1);
    
                // Create the object to update
                var selected = e.target.checked;
                var objectAnswer = {
                    value: value,
                    description: description,
                    selected: selected
                }
    
                // Get an auxiliary array to update
                var auxSelectedValues = selectedValues;
                auxSelectedValues[index] = objectAnswer;
                
                // Update state values
                setSelectedValues([...auxSelectedValues]);
                props.selectedValues([...auxSelectedValues], props.type);
            }
        }
    }
// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-col mt-8 ml-2 m-1'>
                            <h3 className='flex text-l mb-4 italic font-bold'> 
                                {t('values-selector.title')}
                            </h3>
                            <span className='text-sm mb-4'>{t('values-selector.description')}<b>{t('values-selector.help')}</b></span>

    <div className='flex flex-col'>
                                
        {selectedValues?.map((element, index) => (
                <div className='flex flex-col border border-black p-2 ml-0 md:mr-8 m-1'>
                    <span className='text-l mb-0 m-2 font-bold'>
                        {(numOfSelectedValues >= 3) ?
                                (element.selected === true) ?
                                    <input type="checkbox" className='m-2 border border-black' checked disabled={readOnly ? true : false} onChange={(e) => changedValue(e, element.value, element.description, index)}/>
                                    :
                                    <input type="checkbox" className='m-2 border border-black' disabled onChange={(e) => changedValue(e, element.value, element.description, index)}/>
                            :

                                (element.selected === true) ?
                                <input type="checkbox" className='m-2 border border-black' checked disabled={readOnly ? true : false} 
                                    onChange={(e) => changedValue(e, element.value, element.description, index)}/>
                                : 
                                <input type="checkbox" className='m-2 border border-black' disabled={readOnly ? true : false}
                                onChange={(e) => changedValue(e, element.value, element.description, index)}/>
                        }
                        {element.value}
                    </span>
                    <span className='text-sm mt-1 m-2'>{element.description}</span>

                </div>
            ))}

    </div>    
    </div>

  );
}

export default ValuesSelector;