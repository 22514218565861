// React
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';

// Widgets
import NoneditableEditor from './noneditableEditor';

// Icons
import {AiOutlineInfoCircle} from 'react-icons/ai';

function CronGenerator(props) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [frequency, setFrequency] = useState(null); // ['Weekly', 'Monthly'
//   const [dayOfMonth, setDayOfMonth] = useState('*');
//   const [month, setMonth] = useState('*');
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const hour = props?.hour ? props?.hour : '09';
  const minute = props?.minute ? props?.minute : '00';
  const amPm = props?.amPm ? props?.amPm : 'AM';
  const weekdays = [ t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday')];
  const frequencies = [t('weekly'), t('monthly')];


// ---------------------------------------------------------------------------------------------------
// useEffect
// ---------------------------------------------------------------------------------------------------
 // Methods to load when page is loaded
 useEffect (() => {

    // Populating days of week
    if(props?.dayOfWeek !== undefined && props?.dayOfWeek !== null && props?.dayOfWeek !== "" && props?.dayOfWeek !== "*"){
        const days = props?.dayOfWeek.split(',');
        const newDays = days.map(day => weekdays[day-1]);
        console.log("[cronGenerator.js][useEffect()] Edit mode - translated days: ", newDays)
        setDaysOfWeek(newDays);
    }
    }, []);

// ---------------------------------------------------------------------------------------------------
// Frontend methods
// ---------------------------------------------------------------------------------------------------
  const toggleDay = (day) => {
    const newDays = daysOfWeek.includes(day) 
      ? daysOfWeek.filter(d => d !== day) 
      : [...daysOfWeek, day];
    setDaysOfWeek(newDays);

    props.setDayOfWeek( newDays.map(day => weekdays.indexOf(day)+1).join(','));
  };

   // Helper function to format numbers with leading zeros
   const formatNumber = (num) => {
    return num < 10 ? `0${num}` : `${num}`;
  };

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
  return (
    <div className="text-sm space-y-4">


        {props?.dayOfMonthSelector === true ? 
            <div className="space-y-2 ">
                <label className="block font-medium">The survey will run:</label>
                <div className="flex space-x-2">
                {frequencies.map(freq => (
                    <button
                    key={freq}
                    className={`py-1 px-2 rounded ${frequency === freq ? 'bg-green-200 text-black' : 'bg-gray-200'}`}
                    onClick={() => setFrequency(freq)}
                    >
                    {t(freq)}
                    </button>
                ))}
                </div>
            </div>
        :
            null
        }


        {props?.timeSelector === true  ?

                <div className="space-y-2">
                    <div className='flex flex-row space-x-2'>
                        <label className="block font-medium"> {t('crongenerator.which-time')}  </label>
                        <div className='group relative flex items-center justify-center mr-2 w-max'>
                            <AiOutlineInfoCircle className='text-gray-500 hover:cursor-pointer' />  


                                <div className="hidden group-hover:block absolute top-7 bg-gray-300 p-1 
                                rounded items-center justify-center shadow-2xl w-80
                                text-sm h-auto z-50">
                                    <NoneditableEditor
                                        content={t('crongenerator.which-time.tooltip')}
                                        />
                                </div>
                        </div>
                    </div>
                    
                    <div className='flex flex-row space-x-2'>
                        <div className='flex flex-row space-x-2'>
                        <select 
                            value={hour} 
                            onChange={e => props?.setHour(e.target.value)} 
                            className="border p-2 rounded w-full"
                            >
                                    {Array.from({ length: 12 }, (_, i) => formatNumber(i+1)).map((num) => (
                                        <option key={num} value={num}>
                                            {num}
                                        </option>
                                        ))}
                        </select>
                        <span> : </span>
                        <select 
                            value={minute} 
                            onChange={e => props?.setMinute(e.target.value)} 
                            className="border p-2 rounded w-full"
                            >
                                    {Array.from({ length: 60 }, (_, i) => formatNumber(i)).map((num) => (
                                        <option key={num} value={num}>
                                            {num}
                                        </option>
                                        ))}
                        </select>
                        <select 
                            value={amPm} 
                            onChange={e => props?.setAmPm(e.target.value)} 
                            className="border p-2 rounded w-full"
                            >
                                        <option key={"AM"} value={'AM'}>AM</option>
                                        <option key={"PM"} value={'PM'}>PM</option>
                        </select>
                        </div>
                    </div>
                </div>
        :
            null
        }
  

        {props?.dayOfWeekSelector === true  ?
            <div className="space-y-2">
                <div className='flex flex-row space-x-2'>
                    <label className="block font-medium"> {t('crongenerator.which-weekdays')} </label>
                    <div className='group relative flex items-center justify-center mr-2 w-max'>
                    <AiOutlineInfoCircle className='text-gray-500 hover:cursor-pointer' />  


                        <div className="hidden group-hover:block absolute top-7 bg-gray-300 p-1 
                        rounded items-center justify-center shadow-2xl w-80
                        text-sm h-auto z-50">
                            <NoneditableEditor
                                content={t('crongenerator.which-weekdays.tooltip')}
                                />
                        </div>
                </div>

                </div>
                <div className="flex space-x-2">
                {weekdays.map(day => (
                    <button
                    key={day}
                    className={`py-1 px-2 rounded ${daysOfWeek.includes(day) ? 'bg-green-200 text-black' : 'bg-gray-200'}`}
                    onClick={() => toggleDay(day)}
                    >
                    {day.slice(0, 3)}
                    </button>
                ))}
                </div>
            </div>

        :
            null
        }                    
                    

      <div className='hidden'>
        <strong className="font-medium">Generated Cron Expression:</strong>
        {`  ${minute + " "} ${(hour)}  * * ${daysOfWeek.map(day => weekdays.indexOf(day)+1).join(',')}`}
      </div>
    </div>

  );
}

export default CronGenerator;