// React
import React, {useState, useEffect} from 'react';

// 3P
import {RadarChart} from 'react-vis';
import { CircularGridLines } from 'react-vis';
import { useTranslation } from 'react-i18next';


// Configs and utils
import Configs from '../Configs';

// ----------------------------------------------------------
// This is a sample component to display a spider chart.
// ----------------------------------------------------------    
const SpiderPerformance = ({
            // Props
            performanceData,
        }) => {


    // 3P 
    const {t} = useTranslation();


    // Sample data
    const [data, setData] = useState([performanceData] || [
        {
        customerObsession: 4,
        strongerTogether: 4.7,
        biasForAction: 5,
        frugality: 2,
        innovation: 3.2,
        }
    ]);
    
    
    const [domain, setDomain] = useState([
        {name: t('customerObsession'), domain: [0, 5], getValue: d => d.customerObsession},
        {name: t('strongerTogether'), domain: [0, 5], getValue: d => d.strongerTogether},
        {name: t('biasForAction'), domain: [0, 5], getValue: d => d.biasForAction},
        {name: t('frugality'), domain: [0, 5], getValue: d => d.frugality},
        {name: t('innovation'), domain: [0, 5], getValue: d => d.innovation}
    ]);
// ----------------------------------------------------------
// useEffect
// ----------------------------------------------------------
    useEffect(() => {
        // When performanceData changes, we generate new data and domain
        generateData();
        
    }, [performanceData]);

  // ----------------------------------------------------------
  function generateData() {
        // With every change in performanceData, we generate a new DOMAIN and DATA
        setData([performanceData]);

        let newDomain = [];

        // Iterate through performanceData object
        for (let key in performanceData) {
            newDomain.push({name: key, domain: [0, 5], getValue: d => d[key]});
        }

        if (Configs.devEnvironment) console.log ("[spiderPerformance.js][generateData()] newDomain, data: ", newDomain, performanceData);

        setDomain(newDomain);
  }

// ----------------------------------------------------------
// Return methods
// ----------------------------------------------------------

  return (
    <RadarChart
      data={data}
      tickFormat={t => Math.round(t)}
      startingAngle={180}
      domains={domain}
      width={250}
      height={250}
      style={{
        labels: { fontSize: 10 }, // Adjust label font size if necessary
        textAnchor: 'middle', // Ensures the text is centered
        polygons: {
            strokeWidth: 0.5,
            strokeOpacity: 1,
            fillOpacity: 0.1
          },
          axes: {
            line: {},
            ticks: {},
            text: {}
          },
        
      }}
      hideInnerMostValues={true}

    >
        <CircularGridLines
            tickValues={[...new Array(11)].map((v, i) => i / 10 - 1)}
        />
    </RadarChart>
  );
}

export default SpiderPerformance;