// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common and widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import OneOnOneHeader from './oneOnOneHeader.js';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';


// TapTap text editor
import Editor from '../../widgets/editor';
import NoneditableEditor from '../../widgets/noneditableEditor';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineInfoCircle } from 'react-icons/ai';


function OneOnOneMeeting () {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // var currentProfile = JSON.parse(sessionStorage.getItem('profileInfo'));
    // var [profile, setProfile] = useState(currentProfile ? currentProfile : null);
    const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const {token} = useAuth();

    // States for meeting status
    const [meetingTitle, setMeetingTitle] = useState("");
    const [meetingContent, setMeetingContent] = useState("");
    const [initialContent, setInitialContent] = useState("");
    const [privateNotesContent, setPrivateNotesContent] = useState(null);
    const [attritionRisk, setAttritionRisk] = useState(null);
    const [meetingEmployee, setMeetingEmployee] = useState("");
    const [readableDate, setReadableDate] = useState(new Date());
    const [existingDraft, setExistingDraft] = useState(null);

    // Toaster & Loader
    const [toasterMessage, setToasterMessage] = useState(null);
    const [isLoading, setisLoading] = useState(false);

    if (Configs.devEnvironment) console.log ("[oneOnOneMeeting.js][main()] Params are: ", params);

  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // --------------------------------------------------------------------------------------------------- 
    // Methods to load when page is loaded
    useEffect (() => {
        // Get information to populate meeting
        getMeeting();
        
      }, []);

  // ---------------------------------------------------------------------------------------------------
  // API methods
  // --------------------------------------------------------------------------------------------------- 
    async function getMeeting () {
        setisLoading(true);

        // fetch from server only if we have meetingID
        const auxString = "?companyID=" + companyID + "&employeeID=" + params.employeeID + "&managerID=" + (params ? params.managerID : employeeID) + "&meetingID=" + params.meetingID

        if (params.meetingID) {
            fetch(Configs.platformGetMeetingAPI + auxString, {
              method: 'get',
              credentials: 'include',
              headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`, // notice the Bearer before your token
              }
            })
                .then((response) => {
                  setisLoading(false);

                  if (response.status === 200) {
                    // Meeting exists and has a draft
                    response.json()
                    .then ( body => {
                      setExistingDraft(body);
                      // setMeetingContent(body[0].content);
                      setInitialContent(body[0].content);
                      if (Configs.devEnvironment) console.log("[oneOnOneMeeting.js][getMeeting()] meeting:", body[0]);
                      // setToasterMessage({message: t('oneOnOne.load.success'), type: Configs.successToaster});
                      if (body[0].privateNotes) setPrivateNotesContent(body[0]?.privateNotes);
                      if (body[0].attritionRisk) setAttritionRisk(body[0]?.attritionRisk);
                      })
                    .catch(error => {
                      setToasterMessage({message: t('error'), type: Configs.successToaster});
                      console.error("[oneOnOneMeeting.js][getMeeting()]  Error parsing response JSON", error)
                    });
                  } else if (response.status === 400) {
                      // Something went wrong - inform user
                      setToasterMessage({message: t('warning'), type: Configs.warningToaster});
                  } else if (response.status === 401) {
                      // Force logout
                      setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                      sessionStorage.clear();
                      window.location.reload();
                  } else if (response.status === 403) {
                      // Force logout
                      setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                      sessionStorage.clear();
                      window.location.reload();
                  } else if (response.status === 404) {
                      setToasterMessage({message: t('warning'), type: Configs.warningToaster});
                  } else {
                      //
                      setToasterMessage({message: t('error'), type: Configs.errorToaster});
                      throw response;
                  }
                })
                .catch((error) => {
                  setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
                  setisLoading(false);
                  console.error("[oneOnOneMeeting.js][getMeeting()]  Error fetching data: ", error);
                });
        }
    }

    async function saveMeeting () {
      // Create object
      const auxObject = { 
        companyID: existingDraft ? existingDraft[0].companyID : companyID,
        managerID: existingDraft ?  existingDraft[0].managerID : (params ? params.managerID : employeeID),
        employeeID: existingDraft ?  existingDraft[0].employeeID : params.employeeID,
        title: existingDraft ? existingDraft[0].title : (readableDate?.toDateString() + " - " + params?.employeeName + " - " + meetingTitle),
        content: meetingContent === "" ? initialContent : meetingContent,
        date: existingDraft ? existingDraft[0].date : readableDate,
      }

      if (privateNotesContent) auxObject.privateNotes = privateNotesContent;
      if (attritionRisk) auxObject.attritionRisk = attritionRisk;
      
        // HTTP POST request options
          const requestOptions = {
              method: 'POST',
              credentials: 'include',
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
              body: JSON.stringify(auxObject)
          };

    // Post to server      
    fetch(Configs.platformPostMeetingAPI + "?companyID="+companyID + "&employeeID=" + employeeID, requestOptions)
      .then((response) => {
        // console.log (response);
        if (response.status === 200) {
          // All good
          if (Configs.devEnvironment) console.log("[oneOnOneMeeting.js][saveMeeting()] meeting saved successfully");
          setToasterMessage({message: t('oneOnOne.save.success'), type: Configs.successToaster});
          // Navigate back with success message
          navigate(-1, { state: { toasterMessage: {message:(t('success')), type: Configs.successToaster} } });

        } else if (response.status === 400) {
            // Something went wrong - inform user
            setToasterMessage({message: t('warning'), type: Configs.warningToaster});
        } else if (response.status === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            sessionStorage.clear();
            window.location.reload();
        } else if (response.status === 403) {
            // Force logout
            setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            sessionStorage.clear();
            window.location.reload();
        } else if (response.status === 409) {
            setToasterMessage({message: t('warning'), type: Configs.warningToaster});
        } else {
            //
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
            throw response;
        }
      })

      .catch((error) => {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})

        console.error("[oneOnOneMeeting.js][saveMeeting()] Error fetching data: ", error);
      });

  }


  // ---------------------------------------------------------------------------------------------------
  // Frontend control methods
  // --------------------------------------------------------------------------------------------------- 
    async function save (e) {
        // Save 1:1 to database
        e.preventDefault();

        // API request
        saveMeeting();
    }

    async function cancel () {
      // Go back to previous page
      navigate(-1);
  }

  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
    };
  
  // ---------------------------------------------------------------------------------------------------
  // Render methods
  // ---------------------------------------------------------------------------------------------------    

    return (
      <React.Fragment>
      <Header />
      <Bot/>

      <div className="main">
        <OneOnOneHeader />
            
        <div className='flex flex-row items-start'>
        <div className="left-big-view">

        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        {isLoading ? 
          <Loader />
        :
        
          

            <div className="flex flex-col m-2 text-left ">
                <div className="flex flex-col mt-2 md:ml-2 m-1">
                    <h2 className="flex text-xl mb-4 mt-4 md:mt-0">              
                        <div className='flex flex-col md:flex-row justify-left'>
                            <div className='flex font-bold'>
                            {existingDraft ?
                                <span className='section-title'>
                                  {existingDraft[0].title}
                                </span>
                              :
                                <span className='section-title'>
                                  {t('oneOnOne.meeting.title') } - "{readableDate + " - " + params.employeeName + " - " + meetingTitle}"
                                </span>
                              }  
                            
                            </div>
                        </div>
                    </h2>
                    
                    <form className="w-full text-justify mt-4">
                      {/* Confidential fields only available for manager of employee */}
                      {parseInt(params.managerID) === parseInt(employeeID) &&  parseInt(params.companyID) === parseInt(companyID) ?
                        
                        <React.Fragment>
                            { privateNotesContent || attritionRisk ?
                              <div className="flex flex-col items-start w-full px-3 mt-8">
                                <label className='flex flex-row items-center block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white'>
                                  {t('confidential-fields')}:
                                </label>
                              </div>
                            :
                              null
                            }
                            
                            { privateNotesContent ?
                              <div className="flex flex-col items-start w-full px-3 mt-8">
                                  <label className="flex flex-row items-center block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white">
                                      {t('private-notes')}:
                                      <div className='group ml-2 cursor-pointer'>
                                          <AiOutlineInfoCircle />

                                          <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50">
                                            <NoneditableEditor
                                                content={t('oneOnOne.meeting.private-notes-placeholder')}
                                                />
                                          </div>
                                      </div>
                                  </label>
                                  <div className='flex flex-col items-start w-full border bg-white rounded-lg shadow'>
                                    <Editor setContent={setPrivateNotesContent} content={privateNotesContent} initialContent={privateNotesContent} placeholder={privateNotesContent}/>
                                  </div>
                              </div>
                            :
                              null
                            }
           
                            { attritionRisk ? 
                              <div className="flex flex-col items-start w-full px-3 mt-8">
                                  <label className="flex flex-row items-center block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white">
                                      {t('oneOnOne.meeting.attrition-risk')}:
                                      <div className='group ml-2 cursor-pointer'>
                                          <AiOutlineInfoCircle />

                                          <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50">
                                            <NoneditableEditor
                                                content={t('oneOnOne.meeting.attrition-risk-tooltip')}
                                                />
                                          </div>
                                      </div>
                                  </label>
                                  
                                    {/* Div with 5 options to choose */}
                                    <div className='flex flex-row space-x-1'>
                                      <Tooltip content={t('oneOnOne.meeting.attrition-risk-1')} >
                                      <div onClick={() => setAttritionRisk(1)} className={'secondary-button  shadow  group ml-2 cursor-pointer ' + (attritionRisk === 1 ?  ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 '  )}>
                                          🟢
                                      </div>
                                      </Tooltip>
                                      <Tooltip content={t('oneOnOne.meeting.attrition-risk-2')} >
                                      <div onClick={() => setAttritionRisk(2)} className={'secondary-button shadow  group ml-2 cursor-pointer  ' + (attritionRisk === 2 ?  ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 '  )}>
                                          🟡 
                                      </div>
                                      </Tooltip>
                                      <Tooltip content={t('oneOnOne.meeting.attrition-risk-3')} >
                                      <div onClick={() => setAttritionRisk(3)} className={'secondary-button  shadow  group ml-2 cursor-pointer ' + (attritionRisk === 3 ?  ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 '  )}>
                                        🟠 
                                      </div>
                                      </Tooltip>
                                      <Tooltip content={t('oneOnOne.meeting.attrition-risk-4')} >
                                      <div onClick={() => setAttritionRisk(4)} className={'secondary-button  shadow  group ml-2 cursor-pointer ' + (attritionRisk === 4 ?  ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 '  )}>
                                        🔴 
                                      </div>
                                      </Tooltip>
                                      <Tooltip content={t('oneOnOne.meeting.attrition-risk-5')} >
                                      <div onClick={() => setAttritionRisk(5)} className={'secondary-button shadow group ml-2 cursor-pointer ' + (attritionRisk === 5 ? ' bg-green-200 ' : ' hover:bg-green-200 bg-gray-100 ' )}>
                                        ⚫ 
                                      </div>
                                      </Tooltip>
                                  </div>    
                              </div>
                            :
                              null
                            }


                          </React.Fragment>
                        :
                          // Not manager of employee
                          null
                        
                      }
                    
                  {existingDraft ?

                    null
                  :
                        <div className="flex flex-col lg:flex-row items-start w-full px-3 mb-6">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 md:w-1/12 text-left dark:text-white">
                            {t('title')}:
                            </label>
                            <input
                            className=" w-full input-field"
                            id="title"
                            type="text"
                            placeholder={t('meeting-title')}
                            onChange = {(e) => setMeetingTitle(e.target.value)} value = {meetingContent}
                            />
                        </div>
                  }

                        <div className="flex flex-col items-start w-full px-3 mt-6">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2 md:mr-2 lg:w-4/12 text-left dark:text-white">
                                {t('shared-notes')}:
                            </label>
                            
                            <div className='flex flex-col items-start w-full input-field'>
                              <Editor setContent={setMeetingContent} content={meetingContent} initialContent={initialContent}/>
                            </div>

                        </div>

                        <div className='flex flex-col m-4 lg:m-8 lg:flex-row items-center justify-end '>
                            <button type="button" id="cancelButton" class="secondary-button " onClick={cancel}> {t('cancel')} </button>

                            <button id="save" className="flex save-button" onClick={(e) => save(e)}>
                              {t('save')}
                            </button>
                        </div>
                        
                    </form>


                </div>

                <SectionSeparator />
            </div>
        }
        </div>
        <div className='right-small-view'>
          
          </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default OneOnOneMeeting;