// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Common
import Configs from '../../Configs';
import Header from '../../common/header';
import DaysOffHeader from '../../common/daysOffHeader.js';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext.js';
import DataTable from '../../widgets/dataTable';
import FilterWidget from '../../widgets/data/filterWidget.js';
import KPIStaticCard from '../../widgets/keyIndicatorItemStatic';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip.js';

// ---------------------------------------------------------------------------------------------------
function DaysOffHR () {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {employeeID, companyID, token, securityLevel} = useAuth();
    const {toasterMessage, setToasterMessage} = useData();

    
    const [daysOffReport, setDaysOffReport] = useState(null);
    const [filteredReport, setFilteredReport] = useState(null);

    const employeeInfo = sessionStorage.getItem('employeeInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('employeeInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // Toaster & Loader
    const [isLoading, setIsLoading] = useState(false);

    // Reporting variables
    const [compliance, setCompliance] = useState(0);
    const [companyCompliance, setCompanyCompliance] = useState(0);

    // Data tables
    const [daysOffReportColumns, setDaysOffReportColumns] = useState([
      {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
      {type:'string',id: 'subarea', value: 'subarea', label: t('subarea'), sort: null, filter: null},
      {type:'string',id: 'manager',value: 'manager', label: t('manager'), sort: null, filter: null},
      {type:'string',id: 'employee',value: 'employee', label: t('employee'), sort: null, filter: null},
    ]);

  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------    

  useEffect (() => {     
    // Get information to populate dashboard
    if (daysOffReport === null) getDaysOffReport();
      }, []);
  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------    
    // Get Days off summary
    async function getDaysOffReport () {
      setIsLoading(true);

        // fetch from server      
        fetch(Configs.platformGetDaysOffHRReportAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          }
        })
            .then((response) => {
              setIsLoading(false);

              if (response.status === 200) {
                response.json()
                .then ( body => {
                  if (body.length > 0) {
                    if (Configs.devEnvironment) console.log ("[daysoffHR.js][getDaysOffReport()]:", body);
                    // setDaysOffReport(body);
                    // setFilteredReport(body);
                    transformData(body);
                    calculateDaysOffCompliance(body);
                  }
                })
                .catch(error => {
                  console.error("[daysoffHR.js][getDaysOffReport]  Error parsing response JSON", error);
                });
              } else {
                //
                throw response;
              }
            })
            .catch((error) => {
              console.log("[daysoffHR.js][getDaysOffReport] Error fetching data: ", error);
              setIsLoading(false);
              setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            });
    }

  // ---------------------------------------------------------------------------------------------------
  // Data calculations
  // ---------------------------------------------------------------------------------------------------
  async function transformData (body) {
    if (Configs.devEnvironment) console.log("[daysoffHR.js][transformData()] body: ", body);

    let transformedData = [];
    let daysOffReportColumnsAux = [...daysOffReportColumns];

    // Transform data
    body?.forEach( (employee) => {
      // Employee object
      let transformedRecord = {
        area: employee?.area,
        subarea: employee?.subarea,
        managerID: employee?.managerID,
        manager: employee?.managerEmployee,
        employee: employee?.employee,
        name: employee?.name,
        surname: employee?.surname,
        employeeID: employee?.employeeID,
        companyID: employee?.companyID,
      };

      // Employee policy
      let employeePolicy = employee?.timeOffPolicy[0];

      // Calculate days off per leaveType
      var daysOff = [];

      // Include types of leaves from policy (typically: vacation, sick, personal, carry over, etc.)
      employeePolicy?.leaves?.forEach( (leaveType) => {
        daysOff.push({
          leaveType: leaveType?.leaveType,
          max: leaveType?.max,
          taken: 0,
          remaining: leaveType?.max
        });
      });

      // Calculate days off taken
      if (employee?.timeOffRequested?.length > 0) {
        employee?.timeOffRequested?.forEach( (timeOff) => {

          // every timeOff request can have multiple sub-requests
          timeOff?.request?.forEach( (request) => {
            // Find leaveType
            let leaveType = daysOff.find( (leave) => leave?.leaveType?.toString() === request?.leaveType?.toString());

            if (leaveType) {
              // Update taken days
              leaveType.taken += request?.daysCount;

              // Update remaining days
              leaveType.remaining = leaveType?.max - leaveType?.taken;
            } else {
              // Add leaveType to daysOff
              daysOff.push({
                leaveType: request?.leaveType,
                max: 0,
                taken: request?.daysCount,
                remaining: 0
              });
            }
          });
        });

      } else {
        // Set remaining days to max
        daysOff.forEach( (leaveType) => {
          leaveType.remaining = leaveType?.max;
        });
      }

      // Add leaveTypes dynamically as columns, also taken, remaining and max
      daysOff.forEach( (leaveType) => {
        transformedRecord[leaveType.leaveType+"_remaining"] = parseInt(leaveType.remaining);
        transformedRecord[leaveType.leaveType + "_taken"] = parseInt(leaveType.taken);
        transformedRecord[leaveType.leaveType + "_max"] = parseInt(leaveType.max);

      // add leaveType to columns
        let leaveTypeColumnMax = {
          type:'number',
          id: leaveType.leaveType + "_max",
          value: leaveType.leaveType + "_max",
          label: t(leaveType.leaveType) + " " + t('max'),
          sort: null,
          filter: null
        };

        let leaveTypeColumnRemaining = {
          type:'number',
          id: leaveType.leaveType + "_remaining",
          value: leaveType.leaveType + "_remaining",
          label: t(leaveType.leaveType) + " " + t('remaining'),
          sort: null,
          filter: null
        };

        let leaveTypeColumnTaken = {
            type:'number',
            id: leaveType.leaveType + "_taken",
            value: leaveType.leaveType + "_taken",
            label: t(leaveType.leaveType) + " " + t('taken'),
            sort: null,
            filter: null
          };


        // Add leaveType to columns - Max
        if (!daysOffReportColumnsAux.find( (column) => column.id === leaveTypeColumnMax.id)) {
          daysOffReportColumnsAux = [...daysOffReportColumnsAux, leaveTypeColumnMax];
        }

        // Add leaveType to columns - Taken
        if (!daysOffReportColumnsAux.find( (column) => column.id === leaveTypeColumnTaken.id)) {
          daysOffReportColumnsAux = [...daysOffReportColumnsAux, leaveTypeColumnTaken];
        }

        // Add leaveType to columns - Remaining
        if (!daysOffReportColumnsAux.find( (column) => column.id === leaveTypeColumnRemaining.id)) {
          daysOffReportColumnsAux = [...daysOffReportColumnsAux, leaveTypeColumnRemaining];
        }



      });

      // Add record to transformedData
      transformedData.push(transformedRecord);

    });

    // Sort transformedData by employee
    transformedData.sort((a, b) => (a.employee > b.employee) ? 1 : -1);

    if (Configs.devEnvironment) console.log("[daysoffHR.js][transformData()] transformedData: ", transformedData);

    // Add action column
    daysOffReportColumnsAux.push(
      {type:'action',id: 'action',value: 'action', label: t('action'), sort: null, filter: null},
    );

    setDaysOffReportColumns([...daysOffReportColumnsAux]);


    setDaysOffReport(transformedData);
    setFilteredReport(transformedData);

  }

  async function calculateDaysOffCompliance (filteredDataInput) {
    let complianceAux = 0;
    let total = 0;
    let completed = 0;

    // Calculate total number of 1on1s
    total = filteredDataInput.length;

    // Calculate number of completed 1on1s in the last 30 days
    completed = filteredDataInput.filter( (meeting) => meeting.last_oneOnOne !== null && new Date(meeting.last_oneOnOne) >= new Date(new Date().setDate(new Date().getDate()-30))).length;

    // Calculate compliance
    complianceAux = total > 0 ? Math.round((completed / total) * 100) : 0 ;

    // Set compliance
    setCompliance(complianceAux);
  }

  async function calculateDaysOffCompliance (body) {
    if (Configs.devEnvironment) console.log("[daysoffHR.js][calculateDaysOffCompliance()] body: ", body);

    let companyComplianceAux = 0;

    // Calculate total number of 1on1s in the last 30 days
    let total = body.length;

    // Calculate number of completed 1on1s in the last 30 days
    let completed = body.filter( (meeting) => meeting.last_oneOnOne !== null && new Date(meeting.last_oneOnOne) >= new Date(new Date().setDate(new Date().getDate()-30))).length;

    // Calculate compliance
    companyComplianceAux = total > 0 ? Math.round((completed / total) * 100) : 0;

    if (Configs.devEnvironment) console.log("[daysoffHR.js][calculateDaysOffCompliance()] companyComplianceAux: ", companyComplianceAux, " completed: ", completed, " total: ", total);

    setCompliance(companyComplianceAux);
  }
    
  
  // ---------------------------------------------------------------------------------------------------
  // Filter handlers
  // ---------------------------------------------------------------------------------------------------  
  const handleFilterChange = (filteredDataInput) => {
    if (Configs.devEnvironment) console.log("[daysoffHR.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
    setFilteredReport(filteredDataInput);

    // Calculate compliance
    calculateDaysOffCompliance(filteredDataInput);
  };

  // ---------------------------------------------------------------------------------------------------
  // Event handlers
  // ---------------------------------------------------------------------------------------------------
  async function viewEmployeeDaysOff (index, row) {
    // find employeeID and companyID in filteredReport  
    let employee = filteredReport?.find( (employee) => (employee?.employeeID === row?.employeeID && employee?.companyID === row?.companyID));

    if (Configs.devEnvironment) console.log("[daysoffHR.js][viewEmployeeDaysOff()] employee: ", employee, index, row);

    navigate("/timeoff/team/" +
    encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
      employee:employee?.employee,
      employeeName: employee?.name,
      employeeSurname: employee?.surname,
      employeeArea: employee?.area,
      employeeSubarea: employee?.subarea,
      employeeID: employee?.employeeID,
      employeeProfilePicture: employee?.profilePicture,
      companyID: employee?.companyID,
      managerID: employee?.managerID 
    }),Configs.privateKey).toString()));

  }



  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
    };


  // ---------------------------------------------------------------------------------------------------
  // Page render methods
  // ---------------------------------------------------------------------------------------------------    
    return (
      <React.Fragment>
      <Header />
      < Bot/>

      <div className="main">
        <DaysOffHeader />
            
        <div className="flex flex-col m-2 lg:m-4">
              

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                      <h2 className="flex text-xl text-left mb-4 ">
                        {" "}
                        <div className='flex flex-col items-start md:flex-row justify-left'>
                          <Tooltip content={t('time-off.hr.description')}>
                            <div className='section-title'>
                              📈 {t('time-off.hr.title')}
                            </div>
                          </Tooltip>
                        </div>
                      </h2>
                  </div>

                  {securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelAdmin) || securityLevel?.includes(Configs.securityLevelExec) ? 
                    <React.Fragment>

                        {daysOffReport ? 
                              <React.Fragment>
                                <div className='flex flex-col'>
                                      {/* <h2 className="m-2 text-xl">
                                          {t('one-on-one.history')}
                                      </h2> */}

                                      {isLoading ? 
                                        <Loader />
                                      :

                                      <div className='flex flex-col'>
                                        {/* FilterWidget */}
                                          <FilterWidget data={daysOffReport} onFilterChange={handleFilterChange} 
                                            teamTypeFilter={true} defaultTeamTypeFilter={"team"}
                                            managerFilter={true}
                                            employeeFilter={true}
                                            areaFilter={true} 
                                            subareaFilter={true}
                                            />

                                        <div className='flex flex-col lg:flex-row items-start justify-between'>
                                          <div className='flex flex-wrap items-end'>
                                          {compliance > 0 ? 
                                          <KPIStaticCard graph={Configs.KPIGraphAccelerator} indicator={t('oneOnOne.hr.kpi.compliance')} 
                                            data={compliance} type="Percentage" 
                                            hint={t('oneOnOne.hr.kpi.compliance.description')}/> 
                                          : 
                                            null 
                                          }
                                          </div>
                                        </div>

                                        <div className=' flex flex-row items-center justify-between '>
                                          <div className='flex flex-row items-center justify-start'>
                                            <h3 className=' text-left m-4'>
                                              {t('performance.hr-dashboard.employee-summary')}
                                            </h3> 
                                          </div>
    
                                        </div>
                                          <DataTable data={filteredReport} columnNames={daysOffReportColumns} zerosForDashes={true} 
                                          // title={t('oneOnOne.hr.kpi.compliance')}
                                          viewMethod={viewEmployeeDaysOff}
                                          />

                                      </div>
                                    }
                                </div>
                              </React.Fragment>
                            : 
                                null
                          }
                    </React.Fragment>
            : 

              <div>
                  
              </div>
            
            }
            </div>
        </div>

      </React.Fragment>
    );
}

export default DaysOffHR;