// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// import Select from 'react-select';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import PunchHeader from './punchHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import Forbidden from '../../common/forbidden';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Widgets
import UserListItem from '../../widgets/userListItem';
// import CollapsibleMeetingItem from '../../widgets/collapsibleMeetingItem';
import FilterWidget from '../../widgets/data/filterWidget.js';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip.js';

// React icons
import { IoIosHelpCircleOutline } from "react-icons/io";

// ---------------------------------------------------------------------------------------------------
function PunchTeam () {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {employeeID, securityLevel} = useAuth();
    const {profile, teamDirects, teamOrg, companyOrg, isDirectsLoading, isOrgLoading, isCompanyLoading, toasterMessage, setToasterMessage} = useData();
    const {setSelectedProfile} = useData();


    const [filteredData, setFilteredData] = useState([]);

    if (Configs.devEnvironment) console.log("[PunchTeam.js][main()] Team Directs: ", teamDirects);
    if (Configs.devEnvironment) console.log("[PunchTeam.js][main()] Context: ", securityLevel, profile, teamOrg, companyOrg);


  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------      
  useEffect (() => {

      // If IC, navigate to your space directly
      if (securityLevel?.length > 0) {
        if (!securityLevel?.includes(Configs.securityLevelManager)
          && !securityLevel?.includes(Configs.securityLevelHR)
          && !securityLevel?.includes(Configs.securityLevelExec)
          && !securityLevel?.includes(Configs.securityLevelAdmin)) {
          
          navigate("punch/");
        }
      }

      if (teamDirects) {
        setFilteredData(teamDirects);
      }
            
      }, [teamDirects, companyOrg]);
  // ---------------------------------------------------------------------------------------------------
  // Navigation methods
  // ---------------------------------------------------------------------------------------------------    
    const navigateToPunch = (employeeIDAux) => {
      if (Configs.devEnvironment) console.log("[PunchTeam.js][navigateToPunch()] employeeID: ", employeeIDAux);

      const employee = companyOrg?.find(employee => employee.employeeID === employeeIDAux);

      const params = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
        employee:employee?.name + " " + employee?.surname,
        employeeName: employee?.name,
        employeeSurname: employee?.surname,
        employeeLevel: employee?.level,
        employeeRole: employee?.role,
        employeeArea: employee?.area,
        employeeSubarea: employee?.subarea,
        employeeID: employee?.employeeID,
        employeeProfilePicture: employee?.profilePicture,
        companyID: employee?.companyID,
        managerID: employee?.managerID 
      }),Configs.privateKey).toString());
      
      if (employeeIDAux === employeeID) {
        setSelectedProfile(null);
        navigate("/punch/");
      } else {
        setSelectedProfile(employee);
        navigate("/punch/team/" + params);
      }      
    }
    
    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
      };
    
    // ---------------------------------------------------------------------------------------------------
    // Filter methods
    // ---------------------------------------------------------------------------------------------------   
    const handleFilterChange = (filteredDataInput) => {
      if (Configs.devEnvironment) console.log("[careerTeam.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
      setFilteredData(filteredDataInput);
    };


    // ---------------------------------------------------------------------------------------------------
    // Render methods
    // ---------------------------------------------------------------------------------------------------       
    return (
      <React.Fragment>
      <Header />
      < Bot/>

      <div className="main">

        <PunchHeader />


      {/* Roles check */}
      {securityLevel?.includes(Configs.securityLevelManager)
        || securityLevel?.includes(Configs.securityLevelHR)
        || securityLevel?.includes(Configs.securityLevelExec)
        || securityLevel?.includes(Configs.securityLevelAdmin)
        ?
   
          <div className='flex flex-row items-start'>
          <div className="left-big-view">
              {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                      <h2 className="flex text-xl text-left mb-4 ">
                        <div className='flex flex-col md:flex-row justify-left'>
                            <div className='flex flex-row items-center'>
                            <Tooltip content={t('punch.team.description')}>
                                🫂 <span className='section-title'>{t('punch.team.title')} <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span></span> 
                            </Tooltip>
                            {i18next.language === 'es' ? 
                              <a href="https://resources.kincode.app/es/manager/time-off.html" target="_blank" rel="noreferrer">
                                <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                              </a>
                            : 
                              <a href="https://resources.kincode.app/manager/time-off.html" target="_blank" rel="noreferrer">
                                <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                              </a>
                            }
                            </div>
                        </div>
                      </h2>                        
                  </div>

                  
                   
                    <React.Fragment>
                        <div className='flex flex-col'>
                          {isDirectsLoading || isOrgLoading ? 
                              <Loader />
                            :
                          
                            teamDirects || teamOrg ? 
                                <div className='flex flex-col items-left'>
                                  {/* Filters widget */}
                                  <div className=''>
                                      <FilterWidget data={(securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) ? companyOrg : teamOrg} onFilterChange={handleFilterChange} companyData={companyOrg}
                                      teamTypeFilter={true} defaultTeamTypeFilter={"team"}
                                      managerFilter={true}
                                      employeeFilter={true}
                                      areaFilter={true} 
                                      subareaFilter={true}
                                      />
                                  </div>
                                  {/* Filtered data */}
                                  <div className=''>
                                    {filteredData?.map((employee, index) => (
                                        <button className="flex flex-row w-full" id={"direct_"+index} onClick={() => navigateToPunch(employee?.employeeID)}>
                                            <UserListItem user={employee?.name + " " + employee?.surname} 
                                            initials={employee?.name.slice(0,1)+employee?.surname.slice(0,1)} 
                                            role={employee?.role} level={employee?.level}
                                            profilePicture = {employee?.profilePicture} />
                                        </button>
                                    ))}
                                  </div>
                                </div>
                              :
                                <div >
                                  No team data
                                </div>
                          }
                        </div>
                      </React.Fragment>

              <SectionSeparator />

            </div>
            <div className='right-small-view'>
                  {/* Test */}
            </div>      
            </div>

          : 
              <Forbidden />
            }
        </div>
      </React.Fragment>
    );
}

export default PunchTeam;