// React
import React, { useState, useEffect } from 'react';

// Configs
import Configs from '../../Configs';
import { useAuth } from '../../common/appContext';
import Tooltip from '../../common/support/tooltip';

// 3P
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CryptoJS from 'crypto-js';

// React icons
import {AiOutlineFilter} from 'react-icons/ai';


// --------------------------------------------------------------------------------
// Filter widget
// --------------------------------------------------------------------------------
const PulseFilterWidget = ({ 
    // Data
    orgData, 
    // Update data
    onFilterChange, 
    // Pulse campaign filter
    pulseCampaignFilter, defaultPulseCampaignFilter,
    // Org Data Filters
    teamTypeFilter, defaultTeamTypeFilter = "company",
    areaFilter, defaultAreaFilter,
    subareaFilter, defaultSubareaFilter,
    managerFilter, defaultManagerFilter,
    // Question data filters
    categoryFilter, defaultCategoryFilter,
    subcategoryFilter, defaultSubcategoryFilter,
    title
      }) => {

  const {t} = useTranslation();

  const {employeeID, companyID} = useAuth();
//   const companyPreferences = sessionStorage.getItem('companyPreferences') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyPreferences'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
  const securityLevel = sessionStorage.getItem('securityLevel') ? CryptoJS.AES.decrypt(sessionStorage.getItem('securityLevel'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;

  // We always start with the teamData (directs)
  const [filteredData, setFilteredData] = useState(orgData ? [...orgData] : null);

  const teamTypeOptionsAux = [
      { value: 'directs', label: t('pulse.home.filter.directs') },
      { value: 'org', label: t('pulse.home.filter.org')},
      { value: 'company', label: t('pulse.home.filter.company')  },
    ];
    
    // Filter options states
    const [availableCampaignsOptions, setAvailableCampaignsOptions] = useState([]);
    const [availableTeamTypeOptions, setAvailableTeamTypeOptions] = useState(teamTypeOptionsAux);
    const [availableManagerOptions, setAvailableManagerOptions] = useState([]);
    const [availableAreasOptions, setAvailableAreasOptions] = useState([]);
    const [availableSubareasOptions, setAvailableSubareasOptions] = useState([]);
    const [availableCategoryOptions, setAvailableCategoryOptions] = useState([]);
    const [availableSubcategoryOptions, setAvailableSubcategoryOptions] = useState([]);

    // Filter states
    const [filters, setFilters] = useState({
        pulseCampaign: defaultPulseCampaignFilter ? defaultPulseCampaignFilter : (orgData ? orgData[0]?.campaignID : null),
        teamType: defaultTeamTypeFilter ? defaultTeamTypeFilter : availableTeamTypeOptions[0]?.value,
        area: defaultAreaFilter,
        subarea: defaultSubareaFilter,
        manager: defaultManagerFilter,
        category: defaultCategoryFilter,
        subcategory: defaultSubcategoryFilter
      });

    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][main()] filters: ", filters);
    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][main()] orgData: ", orgData);

  // --------------------------------------------------------------------------------
  // Prepare filter options based on data
  // --------------------------------------------------------------------------------
  useEffect(() => {
    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][useEffect()] filteredData: ", filteredData, orgData);

    // First view companyData for filters -> then orgData -> then teamData
    const availableFilters = (orgData?.length > 0 && orgData !== undefined && orgData !== null) ? Object?.keys(orgData[0]) : [];

    // Set the initial filter to the first available option
    if (availableFilters?.length > 0) {
        // setSelectedFilter(availableFilters[0]);
        if (Configs.devEnvironment)  console.log ("[pulseFilterWidget.js][useEffect()] availableFilter: ", availableFilters);
    }

    // Populate filter options if it is enabled
    if (pulseCampaignFilter) populateCampaignOptions(filteredData); // only populate on useEffect
    if (teamTypeFilter) populateTeamTypeOptions(filteredData); // only populate on useEffect
    if (managerFilter) populateManagerOptions(filteredData); // only populate on useEffect
    if (areaFilter) populateAreaOptions(filteredData); // only populate on useEffect
    if (subareaFilter) populateSubareaOptions(filteredData); // only populate on useEffect
    if (categoryFilter) populateCategoryOptions(filteredData);
    if (subcategoryFilter) populateSubcategoryOptions(filteredData);

    // Default filter values
    onFilterChange(reApplyAllFilters());

  }, [filters, orgData]);

// --------------------------------------------------------------------------------
// Prepare filter options based on data
// --------------------------------------------------------------------------------
async function populateCampaignOptions (dataInput) {
    let dataAux = dataInput ? dataInput : filteredData;

    // Aux object to populate options
    var campaignOptionsAux2 = [];

    // Get the unique campaigns
    const uniqueCampaigns = [...new Set(dataAux?.map(dataPoint => dataPoint?.campaignID))];

    uniqueCampaigns.forEach(campaign => {
        if (campaign) campaignOptionsAux2.push({value: campaign, label: dataAux?.find(dataPoint => dataPoint?.campaignID === campaign)?.campaignName});
    });

    // Sort
    campaignOptionsAux2.sort((a, b) => (a.label > b.label) ? 1 : -1);

    // Update state
    setAvailableCampaignsOptions([...campaignOptionsAux2]);
}

async function populateManagerOptions (auxFilteredData) {
    
    // Get the manager names
    const availableManagerOptionsAux = [];
    
    if (auxFilteredData === undefined || auxFilteredData === null ) {
        
        // Get the unique managers
        const uniqueManagerIDs = [...new Set(filteredData?.map(dataPoint => dataPoint?.managerID))];

        uniqueManagerIDs.forEach(managerID => {
            const manager = filteredData?.find(dataPoint => parseInt(dataPoint?.managerID) === parseInt(managerID));
            const managerNameAndSurname = manager?.managerName + " " + manager?.managerSurname;
            if (manager && managerNameAndSurname) availableManagerOptionsAux.push({value: managerID, label: managerNameAndSurname});
        });
    } else {
        // Get the unique managers
        const uniqueManagerIDs = [...new Set( auxFilteredData?.map(dataPoint => dataPoint?.managerID))];

        uniqueManagerIDs.forEach(managerID => {
            const manager =  auxFilteredData?.find(dataPoint => parseInt(dataPoint?.managerID) === parseInt(managerID));
            const managerNameAndSurname = manager?.managerName + " " + manager?.managerSurname;
            if (manager && managerNameAndSurname) availableManagerOptionsAux.push({value: managerID, label: managerNameAndSurname});
        });
    }

    // Sort the managers by name
    availableManagerOptionsAux.sort((a, b) => (a.label > b.label) ? 1 : -1);

    // Update state
    setAvailableManagerOptions([...availableManagerOptionsAux]);
}

async function populateTeamTypeOptions () {
    // Aux object to populate options
    var teamTypeOptionsAux2 = [
        { value: 'directs', label: t('pulse.home.filter.directs') },
      ]; 

    // Review the number of managerIDs in the data
    const uniqueManagerIDs = [...new Set(filteredData?.map(employee => employee?.managerID))];

    // If number of uniqueManagerIDs is 1, then only direct team is available
    if (uniqueManagerIDs?.length === 1) {
        // Do nothing
    } else {
        // Add org option
        teamTypeOptionsAux2.push({ value: 'org', label: t('pulse.home.filter.org')});
    }

    // Review employee permissions
    if (securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec) || securityLevel?.includes(Configs.securityLevelAdmin) ) {
        // Add company option
        teamTypeOptionsAux2.push({ value: 'company', label: t('pulse.home.filter.company')  });
    }

    // Update state
    setAvailableTeamTypeOptions([...teamTypeOptionsAux2]);   
}

async function populateAreaOptions (dataInput) {
    let dataAux = dataInput ? dataInput : filteredData;

    // Aux object to populate options
    var areaOptionsAux2 = [];

    // Get the unique areas
    const uniqueAreas = [...new Set(dataAux?.map(employee => employee?.area))];

    uniqueAreas.forEach(area => {
        if (area) areaOptionsAux2.push({value: area, label: area});
    });

    // Sort
    areaOptionsAux2.sort((a, b) => (a.label > b.label) ? 1 : -1);

    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][populateAreaOptions()] areaOptionsAux2: ", areaOptionsAux2);

    // Update state
    if (areaOptionsAux2?.length > 0) setAvailableAreasOptions([...areaOptionsAux2]);
}

async function populateSubareaOptions (dataInput) {
    let dataAux = dataInput ? dataInput : filteredData;

    // Aux object to populate options
    var subareaOptionsAux2 = [];

    // Get the unique subareas
    const uniqueSubareas = [...new Set(dataAux?.map(employee => employee?.subarea))];

    uniqueSubareas.forEach(subarea => {
        if (subarea) subareaOptionsAux2.push({value: subarea, label: subarea});
    });

    // sort
    subareaOptionsAux2.sort((a, b) => (a.label > b.label) ? 1 : -1);

    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][populateSubareaOptions()] subareaOptionsAux2: ", subareaOptionsAux2);

    // Update state
    if (subareaOptionsAux2?.length > 0) setAvailableSubareasOptions([...subareaOptionsAux2]);
}

async function populateCategoryOptions (dataInput) {
    let dataAux = dataInput ? dataInput : filteredData;
    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][populateCategoryOptions()] cateogryOptionsAux2: ", dataInput);

    // Aux object to populate options
    var categoryOptionsAux2 = [];

    // Get the unique categories - by iterating through all questions
    dataAux?.forEach(dataPoint => {
        dataPoint?.questions?.forEach(question => {
            // Add the category to the list
            if (question?.question?.Category) categoryOptionsAux2.push(question?.question?.Category);
        });
    });

    // Get the unique categories
    const uniqueCategories = [...new Set(categoryOptionsAux2)];

    // Create the options
    var categoryOptionsAux3 = [];
    uniqueCategories.forEach(category => {
        if (category) categoryOptionsAux3.push({value: category, label: category});
    });

    // Sort
    categoryOptionsAux3.sort((a, b) => (a.label > b.label) ? 1 : -1);

    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][populateCategoryOptions()] cateogryOptionsAux2: ", categoryOptionsAux3);

    // Update state
    if (categoryOptionsAux3?.length > 0) setAvailableCategoryOptions([...categoryOptionsAux3]);

}

async function populateSubcategoryOptions (dataInput) {
    let dataAux = dataInput ? dataInput : filteredData;

    // Aux object to populate options
    var subcategoryOptionsAux2 = [];

    // Get the unique subcategories - by iterating through all questions
    dataAux?.forEach(dataPoint => {
        dataPoint?.questions?.forEach(question => {
            // Add the subcategory to the list
            if (question?.question?.Topic) subcategoryOptionsAux2.push(question?.question?.Topic);
        });
    });

    // Get the unique subcategories
    const uniqueSubcategories = [...new Set(subcategoryOptionsAux2)];

    // Create the options
    var subcategoryOptionsAux3 = [];
    uniqueSubcategories.forEach(subcategory => {
        if (subcategory) subcategoryOptionsAux3.push({value: subcategory, label: subcategory});
    });

    // Sort
    subcategoryOptionsAux3.sort((a, b) => (a.label > b.label) ? 1 : -1);

    // Update state
    if (subcategoryOptionsAux3?.length > 0)  setAvailableSubcategoryOptions([...subcategoryOptionsAux3]);
}




// --------------------------------------------------------------------------------
// Handle filter value change
// --------------------------------------------------------------------------------
  const handleFilterValueChange = (e, filterField) => {
    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][handleFilterValueChange()] e: ", e, filterField);

    let filterAux = JSON.parse(JSON.stringify(filters));

    // Filter the data
    switch (filterField?.toString()) {
        // Campaign filters
        case "pulseCampaign":
            // Set Select state to the appropriate value
            filterAux.pulseCampaign = e?.value?.toString();
            break;
        // Org filters
        case "teamType":
            // Check what is the type selected
            // Set Select state to the appropriate value
            filterAux.teamType = e?.value?.toString();
            break;
        case "area":
            // Set Select state to the appropriate value
            filterAux.area = e?.length > 0 ? e : null;
            break;
        case "subarea":
            // Set Select state to the appropriate value
            filterAux.subarea = e?.length > 0 ? e : null;
            break;
        case "manager":
            filterAux.manager = e?.length > 0 ? e : null;
            break;
        // Questions filters
        case "category":
            filterAux.category = e?.length > 0 ? e : null;
            break;
        case "subcategory":
            filterAux.subcategory = e?.length > 0 ? e : null;
            break;
        default:
            break;
    }

    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][handleFilterValueChange()] after: ", filterAux);

    // Update state
    setFilters(filterAux);

    // Re-apply all filters
    onFilterChange(reApplyAllFilters(filterAux));

  };

  function reApplyAllFilters (filterAux) {
      
      // Get data variable
      let dataAux = filteredData?.length > 0 ? [...filteredData] : null;
      let filterDataAux = filterAux ? filterAux : filters;
      
      if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][reApplyAllFilters()] orgData: ", filteredData, filterDataAux, filters);
      
    // Re-apply all filters 
    // CAMPAIGN FILTER
    if (pulseCampaignFilter && filterDataAux.pulseCampaign) {
        dataAux = dataAux?.filter((dataPoint) => dataPoint?.campaignID?.toString() === filterDataAux.pulseCampaign?.toString());
    }

    // TEAM FILTER
    if (filterDataAux?.teamType?.toString() === "directs") {
        // Filter by directs
        dataAux = dataAux?.filter((dataPoint) => (parseInt(dataPoint?.managerID) === parseInt(employeeID) && parseInt(dataPoint?.companyID) === parseInt(companyID)));
    } else if (filterDataAux?.teamType?.toString() === "org") {
        // Filter by org - everyone in the company
        dataAux = dataAux?.filter((dataPoint) => parseInt(dataPoint?.companyID) === parseInt(companyID));
    } else if (filterDataAux?.teamType?.toString() === "company") {
        // Filter by company
        dataAux = dataAux?.filter((dataPoint) => parseInt(dataPoint?.companyID) === parseInt(companyID));
    }


    // AREA FILTER
    if (areaFilter && filterDataAux.area?.length > 0) {
        // Set with all areas
        const areasAux = new Set(filterDataAux.area?.map((areas) => areas?.value?.toString()));

        // Look for areas selected in data
        dataAux = dataAux?.filter((dataPoint) => areasAux.has(dataPoint?.managerArea?.toString()));
    }

    // SUBAREA FILTER
    if (subareaFilter && filterDataAux.subarea?.length > 0) {
        // create a set for subareas in the e array
        const subAreasAux = new Set(filterDataAux.subarea?.map((subAreasAux) => subAreasAux?.value?.toString()));

        // Look for subareas in the list of employees
        dataAux = dataAux?.filter((dataPoint) => subAreasAux.has(dataPoint?.managerSubarea?.toString()));
    }

    // MANAGER FILTER
    if (managerFilter && filterDataAux.manager?.length > 0) {
        // create a set for managerIDs in the e array
        const managerIDs = new Set(filterDataAux?.manager?.map((manager) => parseInt(manager?.value)));

        // Look for managers in the list of employees
        dataAux = dataAux?.filter((dataPoint) => managerIDs.has(parseInt(dataPoint?.managerID)));
    }

    // CATEGORY FILTER
    if (categoryFilter && filterDataAux?.category?.length > 0) {
        const categoriesAux = new Set(filterDataAux.category?.map((categories) => categories?.value?.toString()));

        // Look for category in each of the questions
        dataAux = dataAux?.filter((dataPoint) => dataPoint?.questions?.filter(question => categoriesAux.has(question?.question?.Category?.toString())));

    }

    // SUBCATEGORY FILTER
    if (subcategoryFilter && filterDataAux?.subcategory?.length > 0 ) {

        const subcategoriesAux = new Set(filterDataAux.subcategory?.map((subcategories) => subcategories?.value?.toString()));

        // Look for subcategory in each of the questions
        dataAux = dataAux?.filter((dataPoint) => dataPoint?.questions?.filter(question => subcategoriesAux.has(question?.question?.Topic?.toString())));
    }

    // Sort filteredData by name
    // dataAux?.sort((a, b) => (a?.name > b?.name) ? 1 : -1);

    // Populate dropdowns accordingly
    // Populate filter options if it is enabled
    // if (pulseCampaignFilter) populateCampaignOptions(dataAux); // only populate on useEffect
    // if (teamTypeFilter) populateTeamTypeOptions(dataAux); // only populate on useEffect
    // if (managerFilter) populateManagerOptions(dataAux); // only populate on useEffect
    // if (areaFilter) populateAreaOptions(dataAux); // only populate on useEffect
    // if (subareaFilter) populateSubareaOptions(dataAux); // only populate on useEffect 
    if (categoryFilter) populateCategoryOptions(dataAux);
    if (subcategoryFilter) populateSubcategoryOptions(dataAux);
    
    // Pass the data result to the parent component
    if (Configs.devEnvironment) console.log ("[pulseFilterWidget.js][reApplyAllFilters()] dataAux: ", dataAux);

    return dataAux ? [...dataAux] : null;
    // onFilterChange(dataAux);
  }

// --------------------------------------------------------------------------------
// Render
// --------------------------------------------------------------------------------
if (availableTeamTypeOptions?.length > 0 
    || availableManagerOptions?.length > 0 || availableAreasOptions?.length > 0 || availableSubareasOptions?.length > 0 
    || availableCategoryOptions?.length > 0 || availableSubcategoryOptions?.length > 0)  {
    return (
        <div className="flex flex-row items-start space-x-1 m-4">
            {/* Filter icon */}
            <Tooltip content={t('widgets.filter.tooltip')}>
                <div className='flex flex-col items-center'>
                    <AiOutlineFilter className="flex text-lg text-gray-500 mr-2" />
                </div>
            </Tooltip>
            <div className='flex flex-col items-start w-full '>
                {pulseCampaignFilter && <div className="flex flex-row items-center space-x-2">
                    {/* Survey filter */}
                    {<span className="text-xs font-semibold text-gray-600">{t('surveys')}</span>}
            
                    {/* Campaign filter */}
                            <Select
                            className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                            styles={Configs.widgetDropdownStyle}

                                options={availableCampaignsOptions}
                                defaultValue={defaultPulseCampaignFilter ? availableCampaignsOptions?.find(option => option?.value === defaultPulseCampaignFilter) : null}
                                value={availableCampaignsOptions?.find(option => option?.value === filters?.pulseCampaign)}
                                placeholder={t('widgets.filter.select-campaign')}
                                onChange={(e) => handleFilterValueChange(e, "pulseCampaign")}
                            />
                </div>
                }
  
                <div className="flex flex-row items-center space-x-2">
                    {/* Survey filter */}
                    {(teamTypeFilter || areaFilter || subareaFilter || managerFilter) &&
                    <span className="text-xs font-semibold text-gray-600">{t('team')}:</span>}
            
                    {/* Direct team or Org filter or Entire company filter */}
                    {teamTypeFilter && 
                            <Select
                            className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                            styles={Configs.widgetDropdownStyle}

                                options={availableTeamTypeOptions}
                                // defaultValue={defaultTeamTypeFilter ? availableTeamTypeOptions?.find(option => option?.value === defaultTeamTypeFilter) : null}
                                value={availableTeamTypeOptions?.find(option => option?.value?.toString() === filters?.teamType?.toString())}
                                placeholder={t('widgets.filter.select-team')}
                                onChange={(e) => handleFilterValueChange(e, "teamType")}
                            />
                    }
            
                    {/* Area filter */}
                    {areaFilter && availableAreasOptions?.length > 0 && availableAreasOptions[0]?.value !== "" &&
                            <Select
                            className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                            styles={Configs.widgetDropdownStyle}

                                options={availableAreasOptions}
                                isMulti={true}
                                placeholder={t('widgets.filter.select-area')}
                                onChange={(e) => handleFilterValueChange(e, "area")}
                            />
                    } 
            
                    {/* Subarea filter */}
                    {subareaFilter && availableSubareasOptions?.length > 0 && availableSubareasOptions[0]?.value !== "" &&
                            <Select
                                className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                                styles={Configs.widgetDropdownStyle}

                                options={availableSubareasOptions}
                                isMulti={true}
                                placeholder={t('widgets.filter.select-subarea')}
                                onChange={(e) => handleFilterValueChange(e, "subarea")}
                            />
                    }

                    {/* Manager filter */}
                    {managerFilter && availableManagerOptions?.length > 0 &&
                            <Select
                            className=" rounded-lg hover:bg-gray-200 text-xs text-left"
            
                            styles={Configs.widgetDropdownStyle}

                                options={availableManagerOptions}
                                placeholder={t('widgets.filter.select-manager')}
                                isMulti={true}
                                onChange={(e) => handleFilterValueChange(e, "manager")}
                            />
                    }
                </div>

                <div className="flex flex-row items-center space-x-2">
                    {/* Survey filter */}
                    {(categoryFilter || subcategoryFilter) &&
                        <span className="text-xs font-semibold text-gray-600">{t('question')}</span>}
                        {/* Category filter */}
                        {categoryFilter && availableCategoryOptions?.length > 0 &&
                                <Select
                                className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                                isMulti={true}
                                styles={Configs.widgetDropdownStyle}
                                options={availableCategoryOptions}
                                placeholder={t('widgets.filter.select-category')}
                                onChange={(e) => handleFilterValueChange(e, "category")}
                                />
                        }

                        {/* Subcategory filter */}
                        {subcategoryFilter && availableSubcategoryOptions?.length > 0 &&
                                <Select
                                className=" rounded-lg hover:bg-gray-200 text-xs text-left"
                                isMulti={true}
                                styles={Configs.widgetDropdownStyle}
                                    options={availableSubcategoryOptions}
                                    placeholder={t('widgets.filter.select-subcategory')}
                                    onChange={(e) => handleFilterValueChange(e, "subcategory")}
                                />
                        }
                </div>

            </div>
            
        </div>
      );

} else {
    return null;
}

};

export default PulseFilterWidget;