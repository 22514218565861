// React imports
import React, {useState, useEffect} from "react";

// 3P
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Commong and Widgets
import Configs from '../Configs';
import { useAuth } from '.././common/appContext';

// Modal options
import Modal from './questionItem';
import ListOfQuestions from "./listOfQuestions";

// Common components imports
import Loader from "../common/support/loader";
import Toaster from "../common/support/toaster";


function PulseQuestionsList (props) {
// Translation object
const {t} = useTranslation();

// Props
const arrayQuestionsAux = props?.questionsArray;
const questionsDropdownOptionsAux = props?.allPulseQuestions;
const frequencyDropdownOptions = [
        { value: 'weekly', label: t('weekly') },
        { value: 'monthly', label: t('monthly') },
        { value: 'random', label: t('random') },
]

// Variables
const {token, companyID, employeeID} = useAuth();

// Modal views controlling
const [isCreateQuestionModalOpen, setIsCreateQuestionModalOpen] = useState(false);
const [isListQuestionsModalOpen, setIsListQuestionsModalOpen] = useState(false);

const [arrayQuestions, setArrayQuestions] = useState(arrayQuestionsAux);
const [questionsDropdownOptions, setQuestionsDropdownOptions] = useState(questionsDropdownOptionsAux);

// Question create loader
const [isQuestionCreateLoading, setIsQuestionCreateLoading] = useState(false);

// Toaster
const [toasterMessage, setToasterMessage] = useState(null);

if (Configs.devEnvironment) console.log ("[PulseQuestionsList.js][main()] - arrayQuestions ", arrayQuestions);
if (Configs.devEnvironment) console.log ("[PulseQuestionsList.js][main()] - allQuestions ", questionsDropdownOptions);


// ---------------------------------------------------------------------------------------------------
// UseEffect
// ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    useEffect (() => {
        // Populate empty array with empty object
        if (props?.questionsArray?.length < 2) {
            arrayQuestionsAux[0] = {question: null, frequency: null};
        } else {
            setArrayQuestions(props?.questionsArray);
        }

        // Populate allQuestionsArray options
        if (props?.allPulseQuestions?.length > 0 ) {
            updateOptionsDropdown();
        }
        
      }, [props?.questionsArray, props?.allPulseQuestions]);

// ------------------------------------------------------------------------------------------
// API calls
// ------------------------------------------------------------------------------------------
async function createQuestion (questionObject) {
    if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][createQuestion()] - questionObject: ", questionObject);

    // Loader
    setIsQuestionCreateLoading(true);

    // Fecth data from API - Post method
    fetch(Configs.platformPostCreateQuestionAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        },      
        body: JSON.stringify({
            companyID: companyID,
            employeeID: employeeID,
            question: questionObject
        }),
        })
          .then((response) => {
            // if (Configs.devEnvironment) console.log (response);
            setIsQuestionCreateLoading(false);
            setIsCreateQuestionModalOpen(false);

            if (response.status === 200) {
                setToasterMessage({message: t('success'), type: Configs.successToaster});

                response.json()
                .then((data) => {
                    // Add question to dropdown
                    const auxArray = questionsDropdownOptions;
                    auxArray.push({label: questionObject?.Question_ESP, value: data});
                    setQuestionsDropdownOptions([...auxArray]);

                    // Add question to array
                    const auxArray2 = arrayQuestions;
                    auxArray2.push({question: {label: questionObject?.Question_ESP, value: data}, frequency: null});
                    setArrayQuestions([...auxArray2]);
                    props?.setQuestionArray([...auxArray2]);

                    // Update list of questions available in frontend - remove session storage and add new one
                    sessionStorage.removeItem('allPulseQuestions');

                })
                .catch((error) => {
                    console.error("[pulseCreateCampaign.js][createQuestion()] error:", error);
                    setToasterMessage({message: t('error'), type: Configs.errorToaster});
                });
            } else if (response.status === 400) {
              // Something went wrong - inform user
                setToasterMessage({message: t('warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
          })
    
          .catch((error) => {
            console.error("[pulseCreateCampaign.js][createQuestion()] error:", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            setIsQuestionCreateLoading(false);
          });
}


// ------------------------------------------------------------------------------------------
// Frontend methods
// ------------------------------------------------------------------------------------------
async function addElement (e) {
    e.preventDefault();
    const auxArray = arrayQuestions;
    auxArray.push({question: null, frequency: null});
    // setArrayQuestions([...auxArray]);
    props?.setQuestionArray([...auxArray]);
}

async function deleteElement (e, index) {
    e.preventDefault();
    const auxArray = arrayQuestions;
    auxArray.splice(index, 1);
    // setArrayQuestions([...auxArray]);
    props?.setQuestionArray([...auxArray]);
}

async function updateQuestion (e, index) {
    // e.preventDefault();
    const auxArray = arrayQuestions;
    auxArray[index].question = e;

    // setArrayQuestions([...auxArray]);
    props?.setQuestionArray([...auxArray]);
}

async function updateFrequency (e,index){
    // e.preventDefault();
    const auxArray = arrayQuestions;
    auxArray[index].frequency = e;

    // setArrayQuestions([...auxArray]);
    props?.setQuestionArray([...auxArray]);
}

async function openCreateQuestion (e) {
    e.preventDefault();
    setIsCreateQuestionModalOpen(true);
}

async function openListOfQuestions (e) {
    e.preventDefault();
    setIsListQuestionsModalOpen(true);
}

async function updateOptionsDropdown (allPulseQuestionsAux) {
    const auxArray = allPulseQuestionsAux ? allPulseQuestionsAux : props?.allPulseQuestions;
        var auxArray2 = [];
        
        // Iterate through array to add label and value
        auxArray?.forEach(question => {
            // Create auxiliary object to insert in array
            var objectAux = {
                label: question?.Question_ESP,
                value: question?._id
            }

            // Insert object in array
            auxArray2.push(objectAux);
        });
        
        // Fix 
        setQuestionsDropdownOptions([...auxArray2]);
  }

  async function updateQuestionsArray (arrayAux) {
    setArrayQuestions([...arrayAux]);
    props?.setQuestionArray([...arrayAux]);
  }
// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
        };

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
    
    <div className='flex flex-col m-4 w-full mt-6'>
        <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-between mb-6">
            { isQuestionCreateLoading ? 
                <Loader />
                : 
                <React.Fragment>
                    <div className="flex flex-col lg:flex-row space-x-4 space-y-2 lg:space-y-0 w-full">
                            <button className='bg-gray-200 hover:bg-gray-300  px-4 py-2 rounded text-sm' onClick={openCreateQuestion}>{t('pulse.questions.create')}</button>
                            <Modal isOpen={isCreateQuestionModalOpen} onClose={() => setIsCreateQuestionModalOpen(false)}
                                createQuestion={createQuestion} />
                            <button className='save-button' onClick={openListOfQuestions}>{t('pulse.questions.view-all')}</button>
                            <ListOfQuestions isOpen={isListQuestionsModalOpen} onClose={() => setIsListQuestionsModalOpen(false)}
                                allPulseQuestions={props?.allPulseQuestions} 
                                arrayQuestions={props?.questionsArray} setArrayQuestions={updateQuestionsArray}
                                /> 

                    </div>
                    <div className="flex flex-col lg:flex-row items-center lg:justify-end w-full mb-6">
                        <div className="flex flex-row items-center px-4 py-2 rounded text-sm bg-gray-100 rounded  m-2">
                            {t('questions') + ": " + props?.questionsArray?.length}
                        </div>
                    </div> 
                 </React.Fragment> 
            }
            
            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout} onClose={closeToast} />}
        </div>

        
        
        <div className="w-full grid grid-cols-10 gap-x-4 gap-y-2 text-sm">

            <div className='col-span-1'>
                <label for="username" className="block text-sm font-medium leading-6 text-gray-900">{" "}</label>
            </div>
            <div className='col-span-5'>
                <label for="username" className="block text-sm font-medium leading-6 text-gray-900">{t('question')}</label>
            </div>
            <div className='col-span-3'>
                { props?.requestFrequency === true ?
                    <label for="username" className="block text-sm font-medium leading-6 text-gray-900">{t('frequency')}</label>
                :
                    null
                }
            </div>
            <div className='col-span-1'>
                {""}
            </div>

            {arrayQuestions?.map((question, index) => (
            <React.Fragment>
                <div className="col-span-1 ">
                    <div className="mt-2">
                        <div className="flex flex-row w-full items-center text-right justify-end">
                                { (index === 0 && (arrayQuestions.length < 2 || arrayQuestions === null)) ?
                                        <button
                                            id="addElement"
                                            className="save-button"
                                            onClick={addElement}
                                        >
                                            +
                                        </button>
                                    :
                                    <React.Fragment>
                                        { (arrayQuestions.length > 1 && index === arrayQuestions.length-1) ?
                                            <button
                                            id="addElement"
                                            className="save-button"
                                            onClick={addElement}
                                        >
                                            +
                                        </button>
                                        : 
                                            null }
                                    </React.Fragment>
                                }                                                       
                            
                        </div>
                    </div>
                </div>
                <div className=" col-span-5">
                    <div className="mt-2">
                        <div className=" items-center text-left  w-full">

                            <Select
                                options={questionsDropdownOptions}
                                id={"question_" + index}
                                key={"question_" + index}
                                isSearchable
                                placeholder={t('select-an-option')}
                                styles={Configs.formDropdownStyle}
                                onChange={(e) => updateQuestion(e, index)}
                                value={question?.question}
                                />                                                    
                        </div>
                    </div>
                </div>
                { props?.requestFrequency === true ?
                        <div className="col-span-3 ">
                            <div className="mt-2">
                                <div className=" items-center text-left  ">
                                <Select
                                        options={frequencyDropdownOptions}
                                        id={"frequency_" + index}
                                        key={"frequency_" + index}
                                        value={question?.frequency}
                                        placeholder={t('select-an-option')}
                                        styles={Configs.formDropdownStyle}
                                        onChange={(e) => updateFrequency(e, index)}
                                        />                                               
                                </div>
                            </div>
                        </div>
                :
                        <div className="col-span-3 ">
                            <div className="mt-2">
                                <div className=" items-center text-left  ">
                                                                                
                                </div>
                            </div>
                        </div>
                }
                <div className="col-span-1 ">
                    <div className="mt-2">
                        <div className="flex flex-row w-full items-center text-right justify-start">
                                { (arrayQuestions.length > 1) ?
                                        <button
                                        id="removeElement"
                                        className="remove-button"
                                        onClick={(e) => deleteElement(e, index)} >
                                        -
                                    </button>
                                    :
                                    null 
                                }                                       
                        </div>
                    </div>
                </div>
                
            </React.Fragment>

            ))}


        </div>
        
    </div>

    </React.Fragment>
  );
}

export default PulseQuestionsList;