// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link} from "react-router-dom";

// Common and widgets
import Header from '../../common/header';
import SuperAdminHeader from './superAdminHeader';
import Configs from "../../Configs";
import Bot from '../../common/support/bot'; 
import { useAuth } from '../../common/appContext';
import DataTable from '../../widgets/dataTable';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';


function AIExperiments () {
  const {t, i18n} = useTranslation();

  // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
  const {token, employeeID, companyID} = useAuth();
  const securityLevel = sessionStorage.getItem('securityLevel') ? CryptoJS.AES.decrypt(sessionStorage.getItem('securityLevel'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;


  const [experimentResult, setExperimentResult] = useState(null);

  const [forbidden, setForbidden] = useState(false);

  // Toaster and Loader variables
  const [isLoading, setIsLoading] = useState(false);
  const [toasterMessage, setToasterMessage] = useState(null);



  // ---------------------------------------------------------------------------------------------------
  // useEffects
  // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      // Get information to populate dashboard
      runAIPipeline();
    }, []);

  // ---------------------------------------------------------------------------------------------------
  // API
  // ---------------------------------------------------------------------------------------------------

    async function runAIPipeline () {
      setIsLoading(true);

      // fetch from server      
      fetch(Configs.platormAdminAIExperimentsPipelineAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        }
      })
          .then((response) => {
            setIsLoading(false);
            // console.log (response);
            if (response.status === 200) {
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log("[companyAdmin.js][getAllCompaniesData] body from request: ", body)
                  setExperimentResult(body);
                })
              .catch(error => {
                console.error("[companyAdmin.js][getAllCompaniesData] Error parsing response JSON", error)
            });
            } else if (response.status === 401) {
                // Force logout
                sessionStorage.clear();
                window.location.href = Configs.devEnvironment ? "http://localhost:3000" : "https://platform.kincode.app";
            } else if (response.status === 403) {
                // Force logout
                setForbidden(true);
            } else if (response.status === 404) {
                // Force logout

            } else {
              // There is an error - delete info
              // sessionStorage.removeItem('performanceCampaigns');
              // sessionStorage.setItem('livePerformanceCampaign', false);
              throw response;
            }
          })
    
          .catch((error) => {
            setIsLoading(false);
            console.error("[superAdminHome.js][getAllCompaniesData()] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
          });
  }
// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
  
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="flex flex-col lg:ml-[215px]">
          <SuperAdminHeader />
            
            <div className="flex flex-col m-2 lg:m-4">
        

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


          <div className="flex flex-col text-left ">

            {/* Employee actions - Create new employee, or act in bulk */}
            { securityLevel?.includes(Configs.securityLevelSuperAdmin) ?
                isLoading ?
                        <Loader />
                    :

                        <React.Fragment>
                        <div className="flex flex-row justify-center lg:justify-end m-4">
                            {experimentResult && experimentResult.length > 0 ?
                                experimentResult.map((experiment, index) => {
                                    return (
                                        <div key={index} className="flex flex-col m-2 p-2 border-2 border-gray-300 rounded-lg">
                                            <div className="flex flex-row justify-between">
                                                <div className="flex flex-col">
                                                    <div className="text-lg font-bold">{experiment.experimentName}</div>
                                                    <div className="text-sm">{experiment.experimentDescription}</div>
                                                </div>
                                                <div className="flex flex-col">
                                                    <div className="text-sm">{experiment.experimentStatus}</div>
                                                    <div className="text-sm">{experiment.experimentResult}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            :
                                <div className="flex flex-col justify-center m-4 p-4 border-2 border-gray-300 rounded-lg">
                                    <div className="text-lg font-bold">{t('ai.no-experiments')}</div>
                                    <div className="text-sm">{t('ai.no-experiments-description')}</div>
                                </div>
                            }
                        </div>
                        </React.Fragment>

            : 
                null
            }
          </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default AIExperiments;