// React
import React, { useState, useEffect} from 'react';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';

// 3P
import { useTranslation } from 'react-i18next';

// common
import Configs from '../Configs';
import HeaderEnd from './support/headerEnd';
import { useData } from './dataContext';
import { useAuth } from './appContext';

// Icons
import { BsHouseFill } from 'react-icons/bs';

// ------------------------------------------------------------------------
function HomeHeader () {
    const {t, i18n} = useTranslation();

    const {profile} = useData();

    // ------------------------------------------------------------------------
    return (
        <React.Fragment>
             <div className='flex flex-col px-8 py-2 pt-20 lg:pt-2 justify-between items-center bg-white shadow space-y-4 '>
                {/* Top header */}
                <div className='flex flex-row w-full items-center lg:pt-2 text-left justify-between '> 
                      {profile?.employeeInfo ? 
                        <h1 className='flex flex-row items-center text-left text-base font-medium '> 
                            <BsHouseFill className='flex mr-4 text-xl' />
                            {t('welcome') + " " + profile?.employeeInfo?.name + "! 👋 "}
                        </h1>
                      : 
                        <h1 className='flex flex-row items-center text-left text-base font-medium '> 
                            <BsHouseFill className='flex mr-4 text-xl' />
                            {t('welcome') + "👋"}
                        </h1>

                      }

                    <HeaderEnd />                    

                </div>

              
            </div>
            
            
        </React.Fragment>
);

            }

export default HomeHeader;