// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import OnboardingHeader from './onboardingHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// Widgets
import ProgressBar from '../../widgets/progressBar';
import UserListItem from '../../widgets/userListItem';
import OnboardingSectionWidget from '../../widgets/onboardingSectionWidget';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// React icons
import {AiOutlineFilter, AiOutlineDownload, AiOutlinePlus} from 'react-icons/ai';
import { FaEdit } from "react-icons/fa";

// ---------------------------------------------------------------------------------------------------
function OnboardingPlan () {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const {encryptedParams} = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(encryptedParams, Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null; 

    // Employee datapoints  
    const {token, companyID, employeeID, securityLevel} = useAuth();
    const {companyOrg, getMyCompanyOrg} = useData();
    const {toasterMessage, setToasterMessage} = useData();
    const {onboardingPlanTemplates, isOnboardingPlanTemplatesLoading} = useData();
    const {selectedProfile, setSelectedProfile} = useData();

    // Toaster & loader
    const [isPlanLoading, setIsPlanLoading] = useState(false);
    const [isBuddyLoading, setIsBuddyLoading] = useState(false);
    const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [isEmployeeProfileLoading, setIsEmployeeProfileLoading] = useState(false);
    const [isPeopleLoading, setIsPeopleLoading] = useState(false);

    // Control variables
    const [isEditMode, setIsEditMode] = useState(params ? params.isEditMode : false);
    const [isTemplateMode, setIsTemplateMode] = useState(params ? params.isTemplateMode : false);

    // Onboarding plan
    const templateAux = {
        "title": t('onboarding.plan.kincode-template-name'),        
        "sections": [
            {
                "title":"Compañía",
                "description":"Plan de onboarding para new joiners.",
                "subsections": [
                    {
                        "title":"Welcome new joiner!",
                        "description":"Welcome to the company! We are very happy to have you here. We hope you enjoy your time with us. We are sure you will be a great addition to the team.",
                        "content":t('onboarding.plan.kincode-template-content'),
                        "contentType": "text",
                        "contentURL": null,
                        "isCompleted": false,
                        "isTask":true,
                        "taskOwner": 2,
                        "taskDaysEndOffsetType":"after",
                        "taskDaysEndOffset": 15,
                        "taskStartEndOffsetType":"after",
                        "taskDaysStartOffset": 5,
                    }, {
                        "title":"¡Unas palabras de nuestros fundadores!",
                        "description":"Esto es un vídeo de nuestros fundadores",
                        "isCompleted": false,
                        "contentType": "video",
                        "contentURL": "https://www.youtube.com/embed/d_zHVhzzPI4?si=-32tNvOPJRNtdi8X",
                    }, {
                        "title":"Company values",
                        "description":"Estos son nuestros valores.",
                        "isCompleted": false,
                        "contentType": "values",
                        "contentURL": null,
                    }, {
                        "title":"Riesgos Laborales",
                        "description":"¡Hola! Esta información es importante para los riesgos laborales que tenemos en la oficina. Por favor, léela con atención.",
                        "isCompleted": false,
                        "contentType": "text",
                        "content":"<p>Hay que descargar, leer y firmar el siguiente documento: </p>",
                        "contentURL": null,
                        "isTask":true,
                        "taskOwner": "self",
                        "taskDaysEndOffsetType":"after",
                        "taskDaysEndOffset": 15,
                        "taskStartEndOffsetType":"after",
                        "taskDaysStartOffset": 5,
                    } 
                ],
            }, {
                "title":"Equipo",
                "subsections": [
                    {
                        "title":"Welcome new joiner!",
                        "description":"Welcome to the company! We are very happy to have you here. We hope you enjoy your time with us. We are sure you will be a great addition to the team.",
                        "isCompleted": false,
                        "contentType": "text",
                        "contentURL": null,
                    }, {
                        "title":"A few words from the CEO",
                        "description":"Welcome to the company! We are very happy to have you here. We hope you enjoy your time with us. We are sure you will be a great addition to the team.",
                        "isCompleted": false,
                        "contentType": "video",
                        "contentURL": "https://www.dropbox.com/scl/fi/ej6fru81mst49w2hj2nnk/PLG-Miro-onboarding.mov?rlkey=ow8ltdx1gx2rez5nh68qnck1w&dl=0",
                    }
                ],
            }, {
                "title":"Satisfacción",
                "subsections": [
                    {
                        
                        "title":"Encuesta 1ª semana",
                        "description":"Cuéntanos, ¿qué tal tu primera semana?",
                        "isCompleted": false,
                        "contentType": "survey",
                        "surveyQuestions": [
                            {
                                "question": "¿Cómo ha sido tu primera semana?",
                                "type": "standard",
                                "options": ['Muy bien', 'Bien', 'Regular', 'Mal', 'Muy mal'],
                            }, {
                                "question": "¿Qué mejorarías del proceso de onboarding?",
                                "type": "open",
                                "options": null,
                            }
                        ],
                    }
                ],
            }
        ]};
    const [onboardingPlan, setOnboardingPlan] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [selectedSection, setSelectedSection] = useState(onboardingPlan?.sections?.length > 0 ? onboardingPlan?.sections[0]?.title : null);
    const [newJoiner, setNewJoiner] = useState(null);
    const [buddy, setBuddy] = useState(null);
    const [eligibleEmployees, setEligibleEmployees] = useState([]);
    const [peopleOptions, setPeopleOptions] = useState([]);
    const [completedPercentage, setCompletedPercentage] = useState(0);

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][main()] params:", params);
    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][main()] onboardingPlan:", onboardingPlan);
    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][main()] securityLevel:", securityLevel, securityLevel?.length === 1 , securityLevel.includes(Configs.securityLevelIC));

// -----------------------------------------------------------------------
// Methods to load after page load
// -----------------------------------------------------------------------
useEffect (() => {
    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][usEffect()] isEditMode/isTemplateMode/buddy/allEmployees:", 
    isEditMode, isTemplateMode, buddy);
    
    // If IC
    if (securityLevel?.length === 1 && securityLevel.includes(Configs.securityLevelIC)) {
        // IC - get my onboarding plan
        getOnboardingPlanForEmployee();
    } else {
        // Not IC, could be Manager, HR, or else
        // 1. Get onboarding plan - from params
        if (!isTemplateMode) {
            getOnboardingPlanForEmployee();
            getProfile();
        } 

        if (isTemplateMode) {
            setOnboardingPlan(params?.template);
        }
    } 

    if (companyOrg) transformPeopleBody(companyOrg);
    
    if (params?.section && params?.subsection) {
        // Scroll to the right div
        document.getElementById("title-"+params?.subsection)?.scrollIntoView();
    }

    // Make sure that when navigating to plan, the edit mode is off
    if (location?.pathname?.toString() === "/onboarding/") {
        if ((isEditMode || isTemplateMode)) {
            setIsTemplateMode(false);
            setIsEditMode(false);
        }

        // If onboarding plan is not for the employee, get the right one
        if (onboardingPlan?.employeeID !== employeeID) {
            setOnboardingPlan(null);
            getOnboardingPlanForEmployee(employeeID);
            // navigate("/onboarding/");
        }

    }

  }, [companyOrg, location]);

// ---------------------------------------------------------------------------------------------------
// API methods
// ---------------------------------------------------------------------------------------------------
async function getOnboardingPlanForEmployee (employeeIDAux) {
    // set loading status
    setIsPlanLoading(true);

    try {
        const employeeIDtoGet = employeeIDAux ? employeeIDAux : params?.employeeID ? params?.employeeID : onboardingPlan?.employeeID ? onboardingPlan?.employeeID : employeeID;
        const result = await apiCalls.getOnboardingPlanForEmployee(companyID, employeeIDtoGet, token );

        setIsPlanLoading(false);

        if (result === 500) {
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
        } else if (result === 409) {
            setToasterMessage({message: t('error'), type: Configs.warningToaster});
        } else if (result === 403) {
            setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
        } else if (result === 401) {
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            // sessionStorage.clear();
            navigate("/");
        } else if (result === 400) {
            setToasterMessage({message: t('error'), type: Configs.warningToaster});
        } else {
            if (result?.length > 0) {
                setOnboardingPlan(result[0]);
                calculateCompletionPercentage(result[0]);
                setSelectedSection(params?.section ? result[0].sections[params?.section] : result[0].sections[0]);
                setBuddy(result[0].buddy);

                if (params?.section && params?.subsection) {
                    // Scroll to the right div
                    document.getElementById("title-"+params?.subsection)?.scrollIntoView();
                }
            } else {
                // No onboarding plan
                if (!isTemplateMode && !isEditMode &&
                    (securityLevel?.includes(Configs.securityLevelHR) ||
                     securityLevel?.includes(Configs.securityLevelManager) || 
                     securityLevel?.includes(Configs.securityLevelExec))) {
                        // navigate to HR dashboard
                        navigate("../onboarding/hr");
                }
                    
                setOnboardingPlan({
                    "companyID": companyID,
                    "employeeID": employeeID,
                    "buddy": null
                });
            
            }
        }

    } catch (error) {
        console.error("[onboardingPlan.js][getOnboardingPlanForEmployee()] Error fetching data: ", error);
        setIsPlanLoading(false);
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        setOnboardingPlan(null);
    }
}

async function saveOnboardingPlan () {
    if (Configs.devEnvironment) console.log("[onboardingPlan.js][saveOnboardingPlan()]  plan: ", onboardingPlan);

        // Validate fields
        // Buddy
        if (buddy === null || buddy === undefined) {
            setToasterMessage({message: t('onboarding.plan.missing.buddy'), type: Configs.warningToaster});
            return;
        }

        // Number of sections must be greater than 0
        if (onboardingPlan?.sections?.length === 0 || onboardingPlan?.sections === null || onboardingPlan?.sections === undefined) {
            setToasterMessage({message: t('onboarding.plan.missing.sections'), type: Configs.warningToaster});
            return;
        }

        // Number of subsections must be greater than 0
        if (onboardingPlan?.sections?.length > 0) {
            for (let i = 0; i < onboardingPlan?.sections?.length; i++) {
                if (onboardingPlan?.sections[i]?.subsections?.length === 0 || onboardingPlan?.sections[i]?.subsections === null || onboardingPlan?.sections[i]?.subsections === undefined) {
                    setToasterMessage({message: t('onboarding.plan.missing.subsections'), type: Configs.warningToaster});
                    return;
                }
            }
        }

        // Check if there is any section without title
        if (onboardingPlan?.sections?.length > 0) {
            for (let i = 0; i < onboardingPlan?.sections?.length; i++) {
                if (onboardingPlan?.sections[i]?.title === null || onboardingPlan?.sections[i]?.title === undefined || onboardingPlan?.sections[i]?.title === "") {
                    setToasterMessage({message: t('onboarding.plan.missing.section-title'), type: Configs.warningToaster});
                    return;
                }
            }
        }

        // Check if there are tasks, and if there are, check if all of them are complete: have start date, end date and owner
        var missingOwner = false;
        var missingStartDate = false;
        var missingEndDate = false;

        if (onboardingPlan?.sections?.length > 0 && onboardingPlan?.sections !== null && onboardingPlan?.sections !== undefined) {
            onboardingPlan?.sections?.forEach(section => {
                if (section?.subsections?.length > 0 && section?.subsections !== null && section?.subsections !== undefined ) {
                    section?.subsections?.forEach(subsection => {
                        if (subsection?.isTask === true && subsection?.isTask !== null && subsection?.isTask !== undefined) {
                            // Check if it has a task, and if it does, check if it has a start date, end date and owner
                            // subsection.taskOwner
                            if (subsection?.taskOwner === null || subsection?.taskOwner === undefined || subsection?.taskOwner === "") missingOwner = true;
                            
                            // taskDaysStartOffset + taskDaysStartOffsetType
                            if (subsection?.taskDaysStartOffset === null || subsection?.taskDaysStartOffset === undefined || subsection?.taskDaysStartOffset === ""
                            || subsection?.taskDaysStartOffsetType === null || subsection?.taskDaysStartOffsetType === undefined || subsection?.taskDaysStartOffsetType === "") missingStartDate = true;

                            // taskDaysEndOffset + taskDaysEndOffsetType
                            if (subsection?.taskDaysEndOffset === null || subsection?.taskDaysEndOffset === undefined || subsection?.taskDaysEndOffset === ""
                            || subsection?.taskDaysEndOffsetType === null || subsection?.taskDaysEndOffsetType === undefined || subsection?.taskDaysEndOffsetType === "") missingEndDate = true;
                        }
                    });
                }
            });
        }
        
        if (missingOwner) {
            setToasterMessage({message: t('onboarding.plan.missing.task-owner'), type: Configs.warningToaster});
            return;
        }
 
        if (missingStartDate) {
            setToasterMessage({message: t('onboarding.plan.missing.task-start-date'), type: Configs.warningToaster});
            return;
        }
        
        if (missingEndDate) {
            setToasterMessage({message: t('onboarding.plan.missing.task-end-date'), type: Configs.warningToaster});
            return;
        }
        
        // set loading status
        setIsPlanLoading(true);

        // fetch method to get from backend
        fetch(Configs.platformPostOnboardingPlanAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`, // notice the Bearer before your token
            },
            body: JSON.stringify({
                "companyID": companyID,
                "employeeID": params?.employeeID,
                "modifierID": employeeID,
                "onboardingPlan": onboardingPlan,
                "buddyID": buddy ? buddy.employeeID : null,
                "timestamp": new Date(),
            })
        })

        .then((response) => {
            setIsPlanLoading(false);

            if (response.status === 200) {
                if (Configs.devEnvironment) console.log("[onboardingPlan.js][saveOnboardingPlan()] 200 - Success");
                setToasterMessage({message: t('success'), type: Configs.successToaster});
                // setOnboardingPlan(null);
                // setSelectedSection(null);
                // navigate(-1);
              } else if (response.status === 400) {
                setToasterMessage({message: t('error'), type: Configs.warningToaster});
              } else if (response.status === 401) {
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                // sessionStorage.clear();
                navigate("/");
              } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});

              } else if (response.status === 404) {
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                throw response;
              } else {
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
              }

        })
        .catch((error) => {
            setIsPlanLoading(false);
            console.error("[onboardingPlan.js][saveOnboardingPlan()] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        });
}

async function saveTemplate () {
    // set loading status
    setIsSavingTemplate(true);

    // validation of template - missing

    try {
        const body = {
            "companyID": companyID,
            "employeeID": employeeID,
            "template": onboardingPlan,
            "timestamp": new Date(),
          };

        const result = await apiCalls.postOnboardingTemplate(companyID, employeeID, token, body);

        setIsSavingTemplate(false);

        if (result === 500) {
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
        } else if (result === 409) {
            setToasterMessage({message: t('error'), type: Configs.warningToaster});
        } else if (result === 403) {
            setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
        } else if (result === 401) {
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            // sessionStorage.clear();
            navigate("/");
        } else if (result === 400) {
            setToasterMessage({message: t('error'), type: Configs.warningToaster});
        } else {
            // Inform user of success
            setToasterMessage({message: t('onboarding.plan.template-saved-success'), type: Configs.successToaster});

            if (result?.length > 0) {
                setTemplates([...onboardingPlanTemplates, onboardingPlan]);
            }
        }

    } catch (error) {
        console.error("[onboardingPlan.js][saveTemplate()] Error fetching data: ", error);
        setIsSavingTemplate(false);
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    }
}



// Get profile
async function getProfile () {
    // Set loading state  
    setIsEmployeeProfileLoading(true);

    // Get info from companyOrg
    if (companyOrg) {
            var employee = companyOrg?.find(employee => employee.employeeID === (params?.employeeID ? params?.employeeID : employeeID));
            if (employee) {
                setNewJoiner(employee);
            }
            setIsEmployeeProfileLoading(false);
    } else {
        getMyCompanyOrg();
    }
  }

async function markSubsectionAsCompleteAPI (sectionIndex, sectionTitle, subsectionIndex, subsectionTitle, surveyAnswers) {
    if (Configs.devEnvironment) console.log("[onboardingPlan.js][markSubsectionAsCompleteAPI()]  plan: ", onboardingPlan);

        // Validate fields
        if (buddy === null || buddy === undefined) {
            setToasterMessage({message: t('field.missing'), type: Configs.warningToaster});
            return;
        }

        // set loading status
        setIsPlanLoading(true);

        try {
            const data = {
                "companyID": companyID,
                "employeeID": params?.employeeID ? params?.employeeID : employeeID,
                "modifierID": employeeID,
                "onboardingPlanID": onboardingPlan?._id,
                "sectionTitle": sectionTitle,
                "sectionIndex": sectionIndex,
                "subSectionTitle": subsectionTitle,
                "subSectionIndex": subsectionIndex,
                "surveyAnswers": surveyAnswers,
                "timestamp": new Date(),
            };

            const result = await apiCalls.postOnboardingPlanSubsectionComplete(companyID, employeeID, token, data);

            setIsPlanLoading(false);

            if (result === 500) {
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (result === 409) {
                setToasterMessage({message: t('error'), type: Configs.warningToaster});
            } else if (result === 403) {
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            } else if (result === 401) {
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                // sessionStorage.clear();
                navigate("/");
            } else if (result === 400) {
                setToasterMessage({message: t('error'), type: Configs.warningToaster});
            } else {
                // Inform user of success
                setToasterMessage({message: t('success'), type: Configs.successToaster});
            }


        } catch (error) {

            setIsPlanLoading(false);
            console.error("[onboardingPlan.js][markSubsectionAsCompleteAPI()] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        }

}


// ---------------------------------------------------------------------------------------------------
// Frontend management methods
// ---------------------------------------------------------------------------------------------------
function markSubSectionAsCompleted (e, subsectionIndex, subsectionTitle) {
    e.preventDefault();

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][markSectionAsCompleted()] section to delete:", subsectionTitle, subsectionIndex);

    // Aux plan to modify
    const auxOnboardingPlan = JSON.parse(JSON.stringify(onboardingPlan));
    const auxSelectedSection = JSON.parse(JSON.stringify(selectedSection));    

    var sectionIndex = 0;
    var sectionTitle = "";
    
    // Iterate through Onboarding Plan sections and find the right one
    auxOnboardingPlan?.sections?.forEach((section, index) => {
        if (selectedSection?.title === section.title) {
            // Look inside the section's subsection
            // Mark as completed
            section.subsections[subsectionIndex].isCompleted = true;
            auxSelectedSection.subsections[subsectionIndex].isCompleted = true;

            // Get Index and Title
            sectionIndex = index;
            sectionTitle = section.title;
        }
    });

    // Update state
    setOnboardingPlan(auxOnboardingPlan);
    setSelectedSection(auxSelectedSection);

    // Re-calculate completion percentage
    calculateCompletionPercentage(auxOnboardingPlan);

    // Mark as completed in the database
    // Create new method to update the subsection
    markSubsectionAsCompleteAPI(sectionIndex, sectionTitle, subsectionIndex, subsectionTitle);

}

async function calculateCompletionPercentage(auxOnboardingPlan) {
    if (auxOnboardingPlan?.length < 1 || auxOnboardingPlan === null) {
        setCompletedPercentage(0);
        return;
    }

    // Iterate through the sections and subsections to calculate the percentage
    var totalSections = 0;
    var totalSubsections = 0;
    var completedSections = 0;
    var completedSubsections = 0;

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][calculateCompletionPercentage()] auxOnboardingPlan:", auxOnboardingPlan);

    auxOnboardingPlan?.sections?.forEach(section => {
        totalSections++;

        section?.subsections?.forEach(subsection => {
            totalSubsections++;
            if (subsection.isCompleted) completedSubsections++;
        });

    });

    // Calculate percentage
    var completionPercentage = (completedSubsections / totalSubsections) * 100;

    setCompletedPercentage(completionPercentage);
}

function loadTemplate (template) {
    // Update state
    if (onboardingPlan === undefined || onboardingPlan === null) {
        setOnboardingPlan(template);
        setSelectedSection(template?.sections[0]);
    } else {
        // ask for user confirmation to make sure they know the consequences
        if (!window.confirm(t('onboarding.plan.loadTemplate.alert'))) {    
            // Do nothing
        } else {
            // Do actions
            setOnboardingPlan(template);
            setSelectedSection(template?.sections[0]);
        }
    }
    
}

function selectSection (sectionTitle) {
    // Update state
    var selectedSection = onboardingPlan?.sections?.find(section => section.title === sectionTitle);

    // Update state
    setSelectedSection(selectedSection);
}

function addNewSection (e) {
    e.preventDefault();

    // Aux plan to modify
    var auxOnboardingPlan = JSON.parse(JSON.stringify(onboardingPlan));

    if (auxOnboardingPlan === undefined || auxOnboardingPlan === null) {
        var auxOnboardingPlan = {
            sections: [],
        };
    }

    // Add new section to the plan
    var newSection = {
        title: null,
        subsections: [],
    };

    if (auxOnboardingPlan?.sections?.length === 0 || !auxOnboardingPlan?.sections ) auxOnboardingPlan.sections = [];

    // Add new section to the plan
    auxOnboardingPlan?.sections?.push(newSection);

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][addNewSection()] auxOnboardingPlan:", auxOnboardingPlan);

    // Re-calculate completion percentage
    calculateCompletionPercentage(auxOnboardingPlan);

    // Update state
    setSelectedSection(newSection);
    setOnboardingPlan(auxOnboardingPlan);
}

function addNewSubSection (e) {
    e.preventDefault();

    // add a new subsection within the selected section
    var newSubsection = {
        title: null,
        description: null,
        isCompleted: false,
        "contentType": null,
        "contentURL": null,
    };

    // Aux plan to modify
    const auxOnboardingPlan = JSON.parse(JSON.stringify(onboardingPlan));
    const auxSelectedSection = JSON.parse(JSON.stringify(selectedSection));

    // Add new subsection to the plan
    auxOnboardingPlan?.sections?.forEach(section => {
        if(Configs.devEnvironment) console.log("[onboardingPlan.js][addNewSubSection()] section:", section?.title, selectedSection?.title, section)

        if (selectedSection?.title === section.title) {
            section?.subsections?.push(newSubsection);
            auxSelectedSection?.subsections?.push(newSubsection);
        }
    });

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][addNewSubSection()] auxOnboardingPlan:", auxOnboardingPlan);

    // Update state
    setSelectedSection(auxSelectedSection);
    setOnboardingPlan(auxOnboardingPlan);

    // Re-calculate completion percentage
    calculateCompletionPercentage(auxOnboardingPlan);
}

function updateSubSection(e, subsectionIndex, part) {
    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][updateSection()] part:", subsectionIndex, part, e);
    // if (Configs.devEnvironment) console.log ("[onboardingPlan.js][updateSection()] selected:", selectedSection);

    // e?.preventDefault();

    // Aux plan to modify
    const auxOnboardingPlan = JSON.parse(JSON.stringify(onboardingPlan));
    const auxSelectedSection = JSON.parse(JSON.stringify(selectedSection));

    // depending on the selected section, add the new section to the plan
    switch (part) {
        case "title":
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].title = e.target.value;
                    auxSelectedSection.subsections[subsectionIndex].title = e.target.value;
                }
            });
            break;

        case "description":
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].description = e.target.value;
                    auxSelectedSection.subsections[subsectionIndex].description = e.target.value;
                }
            });
            break;

        case "contentType":
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].contentType = e.target.value;
                    auxSelectedSection.subsections[subsectionIndex].contentType = e.target.value;
                }
            });
            break;
        
        case "content":
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].content = e;
                    auxSelectedSection.subsections[subsectionIndex].content = e;
                }
            });
        break;

        case "contentURL":
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].contentURL = e.target.value;
                    auxSelectedSection.subsections[subsectionIndex].contentURL = e.target.value;
                }
            });
        break;
        
        case "surveyQuestions":
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].surveyQuestions = e;
                    auxSelectedSection.subsections[subsectionIndex].surveyQuestions = e;
                }
            });
            break;

        case "surveyAnswers":
            // used to submit answers to backend
            auxOnboardingPlan.sections.forEach((section, index) => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].surveyAnswers = e;
                    auxSelectedSection.subsections[subsectionIndex].surveyAnswers = e;

                    // submit answers to backend
                    markSubsectionAsCompleteAPI(index, selectedSection.title, subsectionIndex, section.subsections[subsectionIndex].title, e);
                }
            });

            

        break;

        // Task specific
        case "isTask":
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].isTask = e.target.checked;
                    auxSelectedSection.subsections[subsectionIndex].isTask = e.target.checked;
                }
            });

        break;

        case "taskOwner":
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].taskOwner = e;
                    auxSelectedSection.subsections[subsectionIndex].taskOwner = e;
                }
            });

        break;
        
        case "taskDaysStartOffset":
            // Populate
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].taskDaysStartOffset = parseInt(e.target.value);
                    auxSelectedSection.subsections[subsectionIndex].taskDaysStartOffset = parseInt(e.target.value);
                }
            });
        break;

        case "taskDaysStartOffsetType":
            // before or after
            // Populate
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].taskDaysStartOffsetType = e.target.value;
                    auxSelectedSection.subsections[subsectionIndex].taskDaysStartOffsetType = e.target.value;
                }
            });
            break;


        case "taskDaysEndOffset":
            // Populate
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].taskDaysEndOffset = parseInt(e.target.value);
                    auxSelectedSection.subsections[subsectionIndex].taskDaysEndOffset = parseInt(e.target.value);
                }
            });
        break;

        case "taskDaysEndOffsetType":
            // before or after
            // Populate
            auxOnboardingPlan.sections.forEach(section => {
                if (section.title === selectedSection.title) {
                    section.subsections[subsectionIndex].taskDaysEndOffsetType = e.target.value;
                    auxSelectedSection.subsections[subsectionIndex].taskDaysEndOffsetType = e.target.value;
                }
            });
            break;

        default:
            break;
    }

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][updateSection()] auxOnboardingPlan:", auxOnboardingPlan);

    // Update state
    setSelectedSection(auxSelectedSection);
    setOnboardingPlan(auxOnboardingPlan);
}

function deleteSubSection (subSectionIndex, subSectionTitle) {

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][deleteSection()] section to delete:", subSectionIndex, subSectionTitle);

    // Aux plan to modify
    const auxOnboardingPlan = JSON.parse(JSON.stringify(onboardingPlan));
    const auxSelectedSection = JSON.parse(JSON.stringify(selectedSection));

    // Iterate through the sections and subsections to find the one to delete
    auxOnboardingPlan?.sections?.forEach(section => {
        if (section.title === selectedSection?.title) {
            section.subsections.splice(subSectionIndex, 1);
            auxSelectedSection.subsections.splice(subSectionIndex, 1);
        }
    });

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][deleteSection()] auxOnboardingPlan:", auxOnboardingPlan);

    // Update state
    setOnboardingPlan(auxOnboardingPlan);

    // Re-calculate completion percentage
    setSelectedSection(auxSelectedSection);
    calculateCompletionPercentage(auxOnboardingPlan);
}

function updateSectionTitle (e) {
    e?.preventDefault();

    // Aux plan to modify
    const auxOnboardingPlan = JSON.parse(JSON.stringify(onboardingPlan));
    const auxSelectedSection = JSON.parse(JSON.stringify(selectedSection));

    // Check if title exists in another section
    var titleExists = false;
    auxOnboardingPlan?.sections?.forEach(section => {
        if (section.title === e.target.value) {
            titleExists = true;
        }
    });

    if (titleExists) {
        setToasterMessage({message: t('onboarding.plan.section-title-exists'), type: Configs.warningToaster});
        return;
    }

    // Update section title
    auxOnboardingPlan?.sections?.forEach(section => {
        if (section.title === selectedSection?.title) {
            section.title = e.target.value;
            auxSelectedSection.title = e.target.value;
        }
    });

    if (Configs.devEnvironment) console.log ("[onboardingPlan.js][updateSectionTitle()] auxOnboardingPlan:", auxOnboardingPlan);

    // Update state
    setSelectedSection(auxSelectedSection);
    setOnboardingPlan(auxOnboardingPlan);
}


async function transformPeopleBody (body) {
    var auxArray = [];

    body.forEach(person => {
        if (person) {
            // create aux element
            var auxElement = {
                value: person?.employeeID,
                label: person?.name + " " + person?.surname
            }
            // Add aux element to array
            auxArray?.push(auxElement);
            }
        }
    );

    setPeopleOptions([...auxArray]);
}

async function handleBuddyChange(selected) {
    if (Configs.devEnvironment) console.log("[onboardingPlan.js][handleBuddyChange()] selected:", selected);

    if (!selected || selected === null) {
        return;
    }

    if (onboardingPlan === null) {
        const auxOnboardingPlan = {
            companyID: companyID,
            employeeID: params?.employeeID,
            buddy: null,
            sections: [{
                title: t('title'),
                subsections: [],
            }]
        }

        // Look into the array of people, and select the one with the same employeeID
        var auxBuddy = null;
        companyOrg?.forEach(employee => {
            if (employee?.employeeID === selected?.value){
                // Add to onboardingPlan
                auxOnboardingPlan.buddy = employee;

                // Add to auxBuddy
                auxBuddy = employee;
            }
        });

        // Update state
        if (auxBuddy !== null ) {
            setBuddy(auxBuddy);
            setOnboardingPlan(auxOnboardingPlan);
            setSelectedSection(auxOnboardingPlan.sections[0]);
        }

    } else {

        // Create a deep copy of onboardingPlan
        const auxOnboardingPlan = JSON.parse(JSON.stringify(onboardingPlan));

        // Look into the array of people, and select the one with the same employeeID
        var auxBuddy = null;
        companyOrg?.forEach(employee => {
            if (employee?.employeeID === selected?.value){
                // Add to onboardingPlan
                auxOnboardingPlan.buddy = employee;

                // Add to auxBuddy
                auxBuddy = employee;
            }
        });


        // Update state
        if (auxBuddy !== null ) {
            setBuddy(auxBuddy);
            setOnboardingPlan(auxOnboardingPlan);
        }
    }

    if (Configs.devEnvironment) console.log("[onboardingPlan.js][handleBuddyChange()] selected:", selected);

        
};
// ---------------------------------------------------------------------------------------------------
// Navigation methods
// ---------------------------------------------------------------------------------------------------
async function navigateToProfile (e, employeeAux) {
    e.preventDefault();

    if (Configs.devEnvironment) console.log("[onboardingPlan.js][navigateToProfile()] employeeID: ", employeeAux);
  
    if (parseInt(employeeAux?.employeeID) === parseInt(employeeID)) {
      setSelectedProfile(null);
      navigate("../profile/");
    } else {
        const employee = companyOrg.find( (element) => parseInt(element?.employeeID) === parseInt(employeeAux?.employeeID));
        setSelectedProfile(employee);
        navigate("../profile/");
    }
  
  }

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
};

// -----------------------------------------------------------------------
// Render
// -----------------------------------------------------------------------
    return (
        <React.Fragment>
        <Header />
        <Bot/>

        <div className="main">

          <OnboardingHeader />

        <div className='flex flex-row'>
          <div className="left-big-view">

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

            <div className='flex flex-col p-4 space-y-2'>

                    <div className="flex flex-col ">
                      <h2 className="flex text-xl text-left mb-4 ">
                        <div className='flex flex-col md:flex-row justify-left'>
                          <div className='section-title'>
                          {isTemplateMode ? ('📄 ' + t('onboarding.plan.template.new')) : ('📚 ' + t('onboarding.plan'))}
                          </div>
                        </div>
                      </h2>
                    </div>

                {isPlanLoading ?
                    <Loader />
                :
                securityLevel?.length === 1 && securityLevel?.includes(Configs.securityLevelIC) && !onboardingPlan ?
                    // IC with no onboarding plan 
                    <div className='flex flex-col items-center'>
                        <span className='title-description italic' id="ic-empty-plan"> {t('onboarding.plan.no-plan.self')}</span>
                    </div>
                :

                    onboardingPlan === null && isEditMode === false  ? 
                        // Empty onboarding plan - and not in edit mode
                        <div className='flex flex-col items-center'>
                            <span className='title-description italic' id="ic-empty-plan-manager"> {t('onboarding.plan.no-plan.manager')}</span>
                        </div>
                        :
                        // Onboarding plan
                        <div className='flex flex-col justify-between space-y-4' id='onboarding-plan'>

                            {/* Employee profile */}
                            { isEmployeeProfileLoading ? 
                                        <Loader />
                                    :
                                        !isTemplateMode ?
                                            newJoiner ?
                                            // We have the newJoiner name already
                                                params && (parseInt(onboardingPlan?.employeeID) !== parseInt(employeeID)) ? 
                                                    // Not the owner of the plan reading the plan - we show the employee name/card
                                                    <div className='flex flex-col items-start space-y-2 w-full'>
                                                        <h3 className='text-left font-medium'>{t('employee')}</h3>
                                                        <button className='flex flow-col w-full' id="onboarding-plan-self-card" 
                                                        onClick={(e) => navigateToProfile(e, newJoiner)}>
                                                            <UserListItem user={newJoiner?.name + " " + newJoiner?.surname} initials={newJoiner?.name?.slice(0,1)+newJoiner?.surname?.slice(0,1)} 
                                                            role={newJoiner?.role} level={newJoiner?.level}
                                                            area={newJoiner?.area} subarea={newJoiner?.subarea}
                                                            profilePicture={newJoiner?.profilePicture ? newJoiner?.profilePicture : null} />
                                                        </button> 
                                                    </div>
                                                :
                                                    // Employee is the same - no need for the card
                                                    null
                                            :
                                                // New joiner name not know, show eligible employees
                                                <div className='flex flex-col items-start space-y-2 w-full'>
                                                    <h3 className='text-left font-medium'>
                                                        {t('employee')}
                                                    </h3>
                                                    {eligibleEmployees.length > 0 && 
                                                    <Select className='flex flex-row' options={eligibleEmployees} />}
                                                </div>
                                        :
                                            // Template mode - Do not show new joiner
                                            null
                                    } 

                                    {/* Buddy profile */}
                                    { isBuddyLoading ? 
                                        <Loader />
                                    :
                                    buddy && !isTemplateMode && !isEditMode ?
                                        <div className='flex flex-col items-start space-y-2 w-full lg:hidden'>
                                            <h3 className='text-left font-medium'>{t('buddy')}</h3>
                                            <button className="flex flex-col w-full" id="onboarding-plan-buddy-card" 
                                            onClick={(e) => navigateToProfile(e, buddy)}>
                                                <UserListItem user={buddy?.name + " " + buddy?.surname} initials={buddy?.name?.slice(0,1)+buddy?.surname?.slice(0,1)} 
                                                role={buddy?.role} level={buddy?.level}
                                                area={buddy?.area} subarea={buddy?.subarea}
                                                profilePicture={buddy?.profilePicture ? buddy?.profilePicture : null} />
                                            </button> 
                                        </div>
                                    :
                                        !isTemplateMode ?
                                        <div className='flex flex-col items-start space-y-2 w-full'>
                                            <h3 className='text-left font-medium'>{t('buddy')}</h3>
                                            <div className='flex flex-row w-full'>
                                                <Select className='flex text-xs w-full' 
                                                    options={peopleOptions} 
                                                    onChange={handleBuddyChange}
                                                    value={peopleOptions.filter(option => option.value === buddy?.employeeID)}
                                                    placeholder={t('onboarding.plan.buddy.placeholder')}
                                                    styles={Configs.formDropdownStyle} />
                                            </div>
                                        </div>
                                        :
                                        null
                                    }  

                            <div className='flex flex-col'>
                                {/* Onboarding plan navBar - 3 sections */}
                                <div className='flex flex-col md:flex-row justify-between border-b w-full p-4 text-xs'>
                                    <div className='flex flex-row space-x-4 overflow-x-auto' id="onboarding-plan-sections-navbar">
                                        {onboardingPlan?.sections?.length > 1 ?
                                            onboardingPlan?.sections?.map((section, index) => (
                                                // Section buttons
                                                <button key={index} 
                                                    className={selectedSection?.title === section?.title ? "border-b-4 border-b-green-200  px-4 py-3 rounded font-bold" : " text-gray-800 px-4 rounded  "} 
                                                    onClick={() => selectSection(section?.title)}>
                                                        {section?.title}
                                                </button>
                                                ))
                                            :
                                            null
                                        }  
                                        {/* Add new section */}
                                        {isEditMode ? 
                                            <button className='flex flex-row items-center save-button ' onClick={(e) => addNewSection(e)}>
                                                <AiOutlinePlus className='mr-1'/> {t('onboarding.plan.add-section')}
                                            </button>
                                            :
                                            null
                                        }
                                    </div>
                                    {/* ProgressBar to show completion percentage */}
                                    {!isEditMode && <div className='flex flex-row justify-center md:w-1/3 m-4 md:m-4' id="onboarding-plan-progressBar">
                                        <ProgressBar percentage={completedPercentage} />
                                    </div>} 
                                    {/* Save and download buttons */}
                                    <div className='flex flex-row space-x-1 justify-between'>
                                        {isEditMode ? 
                                            <div className='flex flex-row'>
                                                {isSavingTemplate ? 
                                                        <Loader /> 
                                                    : 
                                                    <button className='secondary-button' onClick={() => saveTemplate()}>{t('onboarding.plan.template.save')}</button>
                                                }
                                                {!isTemplateMode && <button className=' save-button ' onClick={() => saveOnboardingPlan()}>{"💾 " + t('save')}</button>}
                                            </div>
                                        :
                                            <button className='text-gray-500 hidden'><AiOutlineDownload className='text-xl'/></button>
                                        }
                                    </div>
                                </div>  

                                <div className='flex flex-col text-left p-8 space-y-4 w-full'>
                                        {/* Section title */}
                                        {isEditMode && onboardingPlan?.sections?.length > 0  ?
                                            <div className='flex flex-col w-full border-l-4 border-l-gray-200'>
                                                <input className='border border-gray-200 rounded p-2 m-2 text-xs input-field' autoFocus 
                                                type='text' value={selectedSection?.title} onChange={(e) => updateSectionTitle(e)}
                                                placeholder={t('onboarding.plan.section-title.placeholder')} />
                                                
                                            </div>
                                        
                                        : null}                                


                                        {/* Subsections to show */}
                                        <div className='flex flex-col space-y-4 pt-12'>

                                            {selectedSection?.subsections?.length > 0 ?
                                                selectedSection?.subsections?.map((subsection, index) => (

                                                    <OnboardingSectionWidget title={subsection?.title} description={subsection?.description} 
                                                        contentType={subsection?.contentType} contentURL={subsection?.contentURL}
                                                        dueDate={subsection?.dueDate ? subsection?.dueDate : onboardingPlan.dueDate} index={index}
                                                        isEditMode={isEditMode} isCompleted={subsection?.isCompleted}
                                                        subSection={subsection} section={selectedSection}
                                                        updateSection={updateSubSection} deleteSection={deleteSubSection}
                                                        markAsCompleted={markSubSectionAsCompleted}
                                                        peopleOptions={peopleOptions}
                                                        hireDate={newJoiner?.hireDate}
                                                    />

                                                ))
                                            :
                                                isEditMode ?
                                                    onboardingPlan?.sections?.length > 0  ?
                                                        <div className='flex flex-row justify-center text-center'>
                                                            <span className='title-description text-center italic'>{t('onboarding.plan.no-subsections')}
                                                                <button className='ml-1 underline italic' onClick={(e) => {addNewSubSection(e)}}>
                                                                    {t('onboarding.plan.add-subSection')}
                                                                </button>
                                                            </span>
                                                        </div>
                                                    :
                                                        // Empty - CTA: add new section
                                                        <div className='flex flex-row justify-center text-center'>
                                                            <span className='title-description text-center italic'>{t('onboarding.plan.no-sections')}
                                                                <button className='ml-1 underline italic' onClick={(e) => {addNewSection(e)}}>
                                                                    {t('onboarding.plan.add-section')}
                                                                </button>
                                                            </span>
                                                        </div>
                                                :
                                                 null
                                                }

                                            <SectionSeparator />
                                        </div>
                                    </div>

                                    {/* Add section button */}
                                    {isEditMode ?
                                        <div className='flex flex-row justify-center items-center mb-8'>
                                            <button className='flex flex-row items-center text-gray-800 rounded bg-green-200 px-2 py-3 save-button hover:bg-green-400 ' onClick={(e) => {addNewSubSection(e)}}>
                                                    <AiOutlinePlus className='mr-1' />
                                                    {t('onboarding.plan.add-subSection')}
                                            </button>
                                        </div>
                                    :
                                        null
                                    }

                            </div>
                        </div>
                      
                }

            </div>

            </div>
            <div className='right-small-view'>
                {/* Templates section */}
                { isOnboardingPlanTemplatesLoading ?
                    <Loader />
                :
                    isEditMode && onboardingPlanTemplates && onboardingPlanTemplates.length > 0 ?
                        <div>
                            <div className="flex flex-col items-start mt-2 md:ml-2 m-1">
                                <Tooltip content={t('onboarding.plan.templates.description')}>
                                    <h2 className="flex text-xl text-left mb-4 section-title">
                                        📄  {t('onboarding.plan.templates')}
                                    </h2>
                                </Tooltip>
                                
                            </div>


                            <div className='flex flex-col space-y-2 justify-start w-full p-4'>
                            {onboardingPlanTemplates?.map((template, index) => (
                                <button key={index} className='save-button bg-gray-200 rounded-full' onClick={() => loadTemplate(template)}>
                                    <div className='flex flex-col items-start'>
                                        <span className='text-sm'>
                                            {template.title}
                                        </span>
                                        <span className='text-xs'>
                                            {template.description}
                                        </span>
                                    </div>
                                </button>
                            ))}
                            </div>

                            <SectionSeparator />

                        </div>
                    :
                        null
                }

                {/* Buddy - only in Plan view */}
                {buddy && !isTemplateMode && !isEditMode &&
                 <div className='flex flex-col items-start space-y-2 w-full my-4'>
                        <h3 className='subsection-title'>{t('buddy')}</h3>
                        <button className="flex flex-col w-full" id="onboarding-plan-buddy-card" 
                        onClick={(e) => navigateToProfile(e, buddy)}>
                            <UserListItem user={buddy?.name + " " + buddy?.surname} initials={buddy?.name?.slice(0,1)+buddy?.surname?.slice(0,1)} 
                            role={buddy?.role} level={buddy?.level}
                            area={buddy?.area} subarea={buddy?.subarea}
                            profilePicture={buddy?.profilePicture ? buddy?.profilePicture : null} />
                        </button> 
                    </div>
                }

            </div>
            </div>
        </div>
      </React.Fragment>
    );
}

export default OnboardingPlan;