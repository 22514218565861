// React
import React, { useEffect, useState} from "react";

// Common & Widgets
import Configs from '../Configs';
import UserListItem from "./userListItem";
import PulseQuestionsList from "./pulseQuestionsList";
import { useAuth } from '../common/appContext';
import { useData } from "../common/dataContext";
import Tooltip from "../common/support/tooltip";

// 3P
import { useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";
import Select from 'react-select';

// TapTap text editor
import Editor from './editor';
import NoneditableEditor from './noneditableEditor';

// Icons
import { FaRegStar } from "react-icons/fa";
import {AiOutlineInfoCircle, AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';


/**
 * // ------------------------------------------------------------------------------------------
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function OnboardingSectionWidget(props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

const { companyID, employeeID, token, securityLevel } = useAuth();
const {companyPreferences} = useData();
const {allPulseQuestions, getAllPulseQuestions} = useData();

// Props
const isEditMode = props.isEditMode;
const [title, setTitle] = useState(props.title ? props.title : "");
const [description, setDescription] = useState(props.description ? props.description : "");
const [contentType, setContentType] = useState(props.contentType);
const contentURL = props.contentURL;
const dueDate = new Date(props.dueDate);
const isCompleted = props?.subsection?.isCompleted; 
const [isTask, setIsTask] = useState(props?.subsection?.isTask);
const [taskOwner, setTaskOwner] = useState(props?.subSection?.taskOwner);
const [taskDaysStartOffset, setTaskDaysStartOffset] = useState(props?.subSection?.taskDaysStartOffset);
const [taskDaysEndOffset, setTaskDaysEndOffset] = useState(props?.subSection?.taskDaysEndOffset);
const [taskStart, setTaskStart] = useState(props?.subSection?.start);
const [taskEnd, setTaskEnd] = useState(props?.subSection?.end);
const index = props.index;

// Variables
const [textContent, setTextContent] = useState(props?.subSection?.content);

if (Configs.devEnvironment) console.log("[OnboardingSectionWidget] [main()] props:", props);
if (Configs.devEnvironment) console.log("[OnboardingSectionWidget] [main()] all Pulse Questions:", allPulseQuestions);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        // calculateResult(selectedValues);
        if ((!allPulseQuestions || allPulseQuestions.length <= 1)  && props?.subSection?.contentType === "survey") getAllPulseQuestions();

}, [props]);
// ------------------------------------------------------------------------------------------
// Methods
// ------------------------------------------------------------------------------------------
function setSurveyQuestions(array) {
    if (Configs.devEnvironment) console.log("[onboardingSectionWidget.js][setSurveyQuestions()] array:", array);

    props?.updateSection(array, index, "surveyQuestions");

}

function updateTextContent (content) {
    // Update state
    setTextContent(content);

    // Update plan
    props?.updateSection(content, index, "content");
}


function updateOwner(e) {
    setTaskOwner(e.value);
    props?.updateSection(e.value, index, "taskOwner");
}

function validateDatesOffset(startOffset, startOffsetType, endOffset, endOffsetType) {
    // Offset types are: 'before / 'after'
    if (Configs.devEnvironment) console.log("[onboardingSectionWidget.js][validateDatesOffset()] startOffset:", startOffset);
    if (Configs.devEnvironment) console.log("[onboardingSectionWidget.js][validateDatesOffset()] startOffsetType:", startOffsetType);
    if (Configs.devEnvironment) console.log("[onboardingSectionWidget.js][validateDatesOffset()] endOffset:", endOffset);
    if (Configs.devEnvironment) console.log("[onboardingSectionWidget.js][validateDatesOffset()] endOffsetType:", endOffsetType);

    if (startOffset && startOffsetType && endOffset && endOffsetType) {
        if (startOffsetType === "days" && endOffsetType === "days") {
            if (startOffset <= endOffset) {
                return true;
            }
        }
    }

    return false;
}

function validateSurvey(e) {
    e.preventDefault();

    // Validate input
    const formData = new FormData(e.target);

    // To check for nulls in survey answers
    var anyNull = false;
    // To create answers array
    var auxArray = [];

    props?.subSection?.surveyQuestions?.forEach( (question, index) => {
      const answer = formData.get(index);

      if (Configs.devEnvironment) console.log("[onboardingSectionWidget.js][validateSurvey()] answer, index:", answer, index);

      // If any answer is missing, inform user
      if (answer === null || answer === "" || answer === undefined || answer === " ") {
        anyNull = true;
        return;
      } else {
        // Create Single Answer object
        const auxAnswer = {
          questionID: question._id,
          answerID: answer
        }
        
        // push answer to array
        auxArray.push(auxAnswer);
      };
    });

    if (Configs.devEnvironment) console.log ("[pulserSurvey.js][validateInput()] auxArray: ", auxArray);

    // Submit answers to BackEnd
    if (anyNull) alert(t('pulse.survey.missing.answers'))
    if (!anyNull) props?.updateSection(auxArray, index, "surveyAnswers");
}

function deleteSection(e, index, title) {
    e.preventDefault();

    // alert the user to confirm
    if (window.confirm(t('onboarding.plan.delete-section.confirm'))) {
        // Delete section
        props.deleteSection(index, title);
    }
}

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-col w-full space-y-4 border border-gray-300 p-4 bg-white rounded-lg shadow-xl '>
        {/* Title, Description, Delete, isTask, dueDate, etc. */}
        <div className='flex flex-col justify-between w-full space-y-4 '>
            <div className='flex flex-col w-full items-end space-y-2' id={"title-"+index}>
                {/* Delete button */}
                <Tooltip content={t('delete')} >
                    <div className="flex flex-row justify-end w-full ">
                        {props.isEditMode &&  <button className="ml-2 remove-button" onClick={(e) => deleteSection(e,  props?.index, props?.subSection?.title)}>X</button>}
                    </div>
                </Tooltip>
                {/* Section title */}
                {props.isEditMode?
                <div className="flex flex-row justify-between w-full">
                    <input type="text" className='flex border rounded p-2 w-full text-xs input-field' value={props.subSection.title} placeholder={t('onboarding.plan.subsection-title.placeholder')}
                    onChange={(e) => props?.updateSection(e, index, "title")} />
                </div>
                :
                <h2 className='flex flex-row text-left justify-start w-full font-bold'>
                    {props?.title}
                </h2>
                }
                {/* Section description */}
                <div className='flex flex-row justify-start text-left w-full'>
                    {props.isEditMode?
                        <textarea className='flex border rounded p-2 w-full text-xs input-field' value={props.subSection.description} placeholder={t('onboarding.plan.subsection-description.placeholder')} 
                        onChange={(e) => props?.updateSection(e, index, "description")}/>
                    :
                        <span className="title-description">
                            {props.description}
                        </span>
                    }
                    
                </div>
            </div>

            <div className="flex flex-row items-start lg:justify-end lg:space-x-4 w-full">
                {/* Due date */}
                {props.isEditMode ? 
                    <div className="flex flex-col rounded border-l-4 border-gray-200 p-4 w-full space-y-2">
                        <div className="flex flex-row items-center space-x-2">
                            <input type="checkbox" className="border rounded p-2" checked={props.subSection.isTask} onChange={(e) => props?.updateSection(e, index, "isTask")} />
                            <span className="flex items-center text-xs text-gray-800">
                                {t('onboarding.plan.is-task')}
                                <div className='flex group ml-2 '>
                                    <Tooltip content={t('onboarding.plan.is-task.tooltip')} >
                                        <AiOutlineInfoCircle className="flex "/>
                                    </Tooltip>
                                </div>
                            </span>
                        </div>
                        {props?.subSection?.isTask ? 
                        <div className="flex flex-col items-start justify-between w-full space-y-4 ">
                            <div className="flex flex-row items-center w-full space-x-2">
                                <span className="text-xs">{t('owner')}:</span>
                                <Select className=" p-2 text-xs w-full "
                                    value={props.peopleOptions.filter(option => option.value === props.subSection.taskOwner)[0]}
                                    options={props.peopleOptions}
                                    onChange={(e) => updateOwner(e)}
                                    styles={Configs.formDropdownStyle}  />
                            </div>
                            <div className="flex flex-row items-center w-full space-x-2 text-gray-600">
                                <span className="text-xs">{t('hire-date') + " : "  + new Date(props?.hireDate).toLocaleDateString()}</span>
                            </div>
                            <div className="flex flex-row items-center space-x-2">
                                

                                <span className="text-xs">{t('start-date')}:</span>
                                {/* <AiOutlineMinus /> */}
                                <input type="number" min={"0"} placeholder="30" className="border rounded p-2 w-16 text-xs input-field" 
                                value={props?.subSection?.taskDaysStartOffset} onChange={(e) => props?.updateSection(e, index, "taskDaysStartOffset")} />
                                {/* <AiOutlinePlus /> */}
                                <span className="text-xs"> {t('days')}  </span>
                                <select className='flex border rounded p-2 text-xs input-field' value={props?.subSection?.taskDaysStartOffsetType} onChange={(e) => props.updateSection(e, index, "taskDaysStartOffsetType")}>
                                    <option value="" disabled selected>{t('select-an-option')}</option>
                                    <option value="before" selected={props?.subSection?.taskDaysStartOffsetType === "before"}>{t('before')}</option>
                                    <option value="after" selected={props?.subSection?.taskDaysStartOffsetType === "after"}>{t('after')}</option>
                                </select>
                                <span className="text-xs">
                                {t('onboarding.plan.task.date.last')}
                                </span>
                            </div>
                            <div className="flex flex-row items-center space-x-2">
                                <span className="text-xs">{t('end-date')}:</span>
                                {/* <AiOutlineMinus /> */}
                                <input type="number" min={"0"} placeholder="40" className="border rounded p-2 w-16 text-xs input-field" 
                                value={props?.subSection?.taskDaysEndOffset} onChange={(e) => props?.updateSection(e, index, "taskDaysEndOffset")} />
                                {/* <AiOutlinePlus /> */}
                                <span className="text-xs"> {t('days')}  </span>
                                <select className='flex border rounded p-2 text-xs input-field' value={props?.subSection?.taskDaysEndOffsetType} onChange={(e) => props.updateSection(e, index, "taskDaysEndOffsetType")}>
                                    <option value="" disabled selected>{t('select-an-option')}</option>
                                    <option value="before" selected={props?.subSection?.taskDaysEndOffsetType === "before"}>{t('before')}</option>
                                    <option value="after" selected={props?.subSection?.taskDaysEndOffsetType === "after"}>{t('after')}</option>
                                </select>
                                <span className="text-xs">
                                {t('onboarding.plan.task.date.last')}
                                </span>
                            </div>
                        </div>

                        : 
                            null
                        }

                    </div>
                : 
                    <div className='flex flex-col items-end space-y-2'>
                        {props?.subSection?.isTask &&
                        <span className='text-xs text-gray-800'>{t('onboarding.plan.due-date')}: { props?.subSection?.isTask ? props?.subSection?.taskEndDate ? new Date(props?.subSection?.taskEndDate).toLocaleDateString()
                        : new Date(props?.dueDate).toLocaleDateString() : null}</span>}
                            {props.isCompleted ? 
                                <span className='text-xs text-gray-800'>
                                    {t('onboarding.plan.completed-task')}
                                </span>
                            : 
                                props?.subSection?.contentType !== "survey" && 
                                <div className='text-xs text-gray-800 cursor-pointer hover:underline' onClick={(e) => props.markAsCompleted(e, props?.index, props?.subSection?.title)}>
                                    {t('onboarding.plan.mark-complete')}
                                </div >
                            }
                    </div>
                }
            </div>
                    
        

        
        {/* Section content */}
        <div className='flex flex-col justify-between w-full space-y-4 border-l-4 border-gray-200 p-4'>


                {// Choose content type
                isEditMode &&
                    <div className='flex flex-col items-start space-y-2'>
                        <span className='text-xs text-gray-800'>{t('onboarding.plan.content-type')}</span>
                        <select className='flex border rounded p-2 text-xs input-field' value={props?.subSection?.contentType} onChange={(e) => props.updateSection(e, index, "contentType")}>
                            <option value="text">{t('onboarding.plan.content-type.text')}</option>
                            <option value="video">{t('onboarding.plan.content-type.video')}</option>
                            <option value="url">{t('onboarding.plan.content-type.url')}</option>
                            <option value="survey">{t('onboarding.plan.content-type.survey')}</option>
                            <option value="values">{t('onboarding.plan.content-type.values')}</option>
                            {/* <option value="meeting">{t('onboarding.plan.content-type.meeting')}</option> */}
                        </select>
                    </div>
                }

                {/* Show content */}
                {// Is Exec or HR
                (securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) ||
                // Is Task and you are the owner 
                (props?.subSection?.isTask && props?.subSection?.taskOwner === employeeID) ||
                // It is not a task, and we cannot tell who the owner is
                props?.subSection?.isTask === null || props?.subSection?.isTask === undefined || !props?.subSection?.isTask
                  ?
                
                props.contentType === 'text' ?
                
                    isEditMode ?
                        <div className="flex flex-col items-start w-full">
                            <Editor setContent={updateTextContent} content={props?.subSection?.content}
                                initialContent={props?.subSection?.content}
                                placeholder={t('onboarding.plan.kincode-template-content')}/>
                        </div>
                    :
                        <div className='flex flex-row text-sm text-left p-4 justify-start w-full'>
                            <NoneditableEditor content={props?.subSection?.content} />
                        </div>

                : props.contentType === 'video' ?
                    isEditMode ?
                        <div className='flex flex-col items-start text-left w-full space-y-2'>
                                    <span className='text-xs text-gray-800'>{t('onboarding.plan.content')}</span>
                                    <input type="text" className='flex border rounded p-2 lg:w-1/2 text-xs input-field' value={props?.subSection?.contentURL} onChange={(e) => props.updateSection(e, index, "contentURL")} placeholder={t('onboarding.plan.content.placeholder')}/>
                                    <iframe width="560" height="315" src={props?.subSection?.contentURL} title="{title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        :
                        <div className='flex flex-row text-sm text-left   justify-start w-full'>
                            <iframe width="560" height="315" src={props?.subSection?.contentURL} title="{title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>

                : props?.contentType?.toString() === "url" ?
                    isEditMode ?
                        <div className='flex flex-col items-start text-left w-full space-y-2 '>
                            <span className='text-xs text-gray-800'>{t('onboarding.plan.content')}</span>
                            <input type="text" className='flex border rounded p-2 lg:w-1/2 text-xs input-field' value={props?.subSection?.contentURL} onChange={(e) => props.updateSection(e, index, "contentURL")} placeholder={t('onboarding.plan.content.placeholder')}/>
                        </div>
                    :
                        <div className='flex flex-row text-sm text-left'>
                            <a href={props?.subSection?.contentURL} target="_blank" rel="noreferrer">
                                {props?.subSection?.contentURL}
                            </a>
                        </div>

                : props.contentType === 'meeting' ?

                    <div className='flex flex-row items-start w-full text-sm text-left '>
                    </div>
                :  props.contentType === 'values' ?

                    companyPreferences?.companyValues?.map((value, index) => (
                        <UserListItem  
                            user={value.value} initials={value.value[0]+value.value[1]} role={value.description}/>
                    ))

                :  props.contentType === 'survey' ?
                    <div className='flex flex-col items-start space-y-2 w-full'>
                        {props?.subSection?.surveyQuestions?.length > 0 ?
                            // Existing survey
                            isEditMode ? 
                                <div>
                                    <PulseQuestionsList 
                                    questionsArray={props?.subSection?.surveyQuestions ? props?.subSection?.surveyQuestions : []} 
                                    allPulseQuestions={allPulseQuestions}
                                    setQuestionArray={setSurveyQuestions}
                                    requestFrequency={false}
                                    />
                                </div>
                            :

                                // Not edit mode --> show survey
                                (props?.subSection?.isTask && !props?.subSection?.isCompleted  
                                    && ((new Date(props?.subSection?.taskStartDate).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) <= 0) 
                                    && ((new Date(props?.subSection?.taskEndDate).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) >= 0)
                                    )
                                || !props?.subSection?.isTask && !props?.subSection.isCompleted ?
                                // Check if task is active
                                    <div className='flex flex-col w-full items-center justify-center space-y-2'>
                                        <form onSubmit={validateSurvey} className="flex flex-col items-start justify-center space-y-4 text-left lg:p-2 w-full ">
                                            {props?.subSection?.surveyQuestions?.map((question, index) => (
                                                <fieldset key={index} className="flex flex-col w-full">
                                                    <div className="flex flex-col justify-center w-full rounded text-xs">
                                                        <div className='text-sm text-left'>{index+1}/{props?.subSection?.surveyQuestions?.length}</div>

                                                        <div className="font-bold text-lg">
                                                            {question?.Question_ESP}
                                                        </div>

                                                        {question?.type === "open" ?
                                                            <React.Fragment>
                                                                <textarea className='mt-2 border rounded p-4 checked:bg-green-200' id={index} name={index} />
                                                            </React.Fragment>

                                                        :

                                                        question?.type === "standard" ?
                                                            
                                                            <React.Fragment>
                                                                {question?.Resp1_String_ESP?.toString().length > 0 ? 
                                                                <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                                <input type="radio" className='mr-2 ' name={index} value="5" />
                                                                {question?.Resp1_String_ESP}
                                                                </label>
                                                                : null}
                                                                {question?.Resp2_String_ESP?.toString().length > 0 ?
                                                                <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                                <input type="radio" className='mr-2 ' name={index} value="4" />{" "}
                                                                {question?.Resp2_String_ESP}
                                                                </label>
                                                                : null}
                                                                {question?.Resp3_String_ESP?.toString().length > 0 ?
                                                                <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                                <input type="radio" className='mr-2 ' name={index} value="3" />{" "}
                                                                {question?.Resp3_String_ESP}
                                                                </label>
                                                                : null}
                                                                {question?.Resp4_String_ESP?.toString().length > 0 ?
                                                                <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                                <input type="radio" className='mr-2 ' name={index} value="2" />{" "}
                                                                {question?.Resp4_String_ESP}
                                                                </label>
                                                                : null }
                                                                {question?.Resp5_String_ESP?.toString().length > 0 ?
                                                                <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                                <input type="radio" className='mr-2 ' name={index} value="1" />{" "}
                                                                {question?.Resp5_String_ESP}
                                                                </label>
                                                                :null}
                                                            </React.Fragment>
                                                        :

                                                        null
                                                        }
                                                    </div>
                                                </fieldset>
                                            ))}
                                            <div className="flex flex-row w-full justify-center text-sm text-center italic text-gray-600 m-4">
                                                {t('pulse.survey.anonymous')}
                                            </div>
                                            <div className="flex flex-row w-full justify-center">
                                                <button className='save-button' formAction='submit'>
                                                    {t('submit')}
                                                </button>
                                            </div>
                                        </form>
                                            
                                    </div>
                                    :
                                    // Survey completed
                                    props?.subSection?.isCompleted ?
                                        props?.subSection?.surveyQuestions?.map((question, index) => (
                                            <div key={index} className="flex flex-col w-full">
                                                <div className="flex flex-col justify-center w-full rounded text-xs">
                                                    <div className='text-sm text-left'>{index+1}/{props?.subSection?.surveyQuestions?.length}</div>
                                                    <div className="font-bold text-lg">
                                                        {question?.Question_ESP}
                                                    </div>

                                                    {question?.type === "open" ?
                                                        <React.Fragment>
                                                            <textarea disabled className='mt-2 border rounded p-4 checked:bg-green-200' id={index} name={index} />
                                                        </React.Fragment>
                                                    :
                                                    question?.type === "standard" ?   
                                                        <React.Fragment>
                                                            {question?.Resp1_String_ESP?.toString().length > 0 ? 
                                                            <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                            <input disabled checked={props?.subSection?.surveyAnswers?.find(answer => answer?.questionID?.toString() === question?._id?.toString())?.answerID === "5" ? true : false}  type="radio" className='mr-2 ' name={index} />
                                                            {question?.Resp1_String_ESP}
                                                            </label>
                                                            : null}
                                                            {question?.Resp2_String_ESP?.toString().length > 0 ?
                                                            <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                            <input disabled checked={props?.subSection?.surveyAnswers?.find(answer => answer?.questionID?.toString() === question?._id?.toString())?.answerID === "4" ? true : false}  type="radio" className='mr-2 ' name={index} />
                                                            {question?.Resp2_String_ESP}
                                                            </label>
                                                            : null}
                                                            {question?.Resp3_String_ESP?.toString().length > 0 ?
                                                            <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                            <input disabled checked={props?.subSection?.surveyAnswers?.find(answer => answer?.questionID?.toString() === question?._id?.toString())?.answerID === "3" ? true : false}  type="radio" className='mr-2 ' name={index} />
                                                            {question?.Resp3_String_ESP}
                                                            </label>
                                                            : null}
                                                            {question?.Resp4_String_ESP?.toString().length > 0 ?
                                                            <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green-200'>
                                                            <input disabled checked={props?.subSection?.surveyAnswers?.find(answer => answer?.questionID?.toString() === question?._id?.toString())?.answerID === "2" ? true : false}  type="radio" className='mr-2 ' name={index} />
                                                            {question?.Resp4_String_ESP}
                                                            </label>
                                                            : null }
                                                            {question?.Resp5_String_ESP?.toString().length > 0 ?
                                                            <label className='mt-2 border rounded p-4 hover:bg-gray-200 checked:bg-green'>
                                                            <input disabled checked={props?.subSection?.surveyAnswers?.find(answer => answer?.questionID?.toString() === question?._id?.toString())?.answerID === "1" ? true : false}  type="radio" className='mr-2 ' name={index} />
                                                            {question?.Resp5_String_ESP}
                                                            </label>
                                                            :null}
                                                        </React.Fragment>
                                                    :

                                                    null
                                                    }
                                                </div>
                                            </div>
                                        ))
                                                        

                                    :
                                    // Survey not active
                                    <div className='flex flex-col items-center w-full space-y-2'>
                                        <span className='title-description italic'>{t('onboarding.plan.survey.not-active')}</span>
                                    </div>
                            

                            :
                            // Empty survey and not in Editmode
                            isEditMode ? 
                                <div>
                                    <PulseQuestionsList 
                                    questionsArray={props?.subSection?.surveyQuestions ? props?.subSection?.surveyQuestions : []} 
                                    allPulseQuestions={allPulseQuestions}
                                    setQuestionArray={setSurveyQuestions}
                                    requestFrequency={false}
                                    />
                                </div>
                            :
                                <div className='flex flex-col items-center w-full space-y-2'>
                                    <span className='title-description italic'>{t('onboarding.plan.survey.empty')}</span>
                                </div>
                        
                        }
                    </div>
                :

                    null

                :

                // You are not the owner
                <div className='flex flex-col items-center w-full space-y-2'>
                    <span className='title-description italic'>{t('onboarding.plan.task-not-for-you')}</span>
                </div>
            
                }
            </div>
        </div>


        {/* Section rating */}
        { isEditMode ? 
            null
        :
            <div className=' hidden flex flex-col items-center justify-center space-y-2 mt-4'>
                <h3 className='flex text-xs text-gray-600 italic'>{t('onboarding.plan.rate-content')}</h3>
                <div className='flex flex-row space-x-2'>
                <FaRegStar className='text-gray-400 hover:text-black cursor-pointer' size={20} value={1}/>
                <FaRegStar className='text-gray-400 hover:text-black cursor-pointer' size={20} value={2}/>
                <FaRegStar className='text-gray-400 hover:text-black cursor-pointer' size={20} value={3}/>
                <FaRegStar className='text-gray-400 hover:text-black cursor-pointer' size={20} value={4}/>
                <FaRegStar className='text-gray-400 hover:text-black cursor-pointer' size={20} value={5}/>
                </div>
            </div>
        }

        {/* Mark as completed */}
        {!props?.subSection?.isCompleted && !isEditMode && props?.subSection?.contentType !== "survey" && parseInt(props?.subSection?.taskOwner) === parseInt(employeeID) ?
                <div className="flex flex-row items-center justify-center p-8">
                    <button className="save-button bg-green-200 hover:bg-green-400" onClick={(e) => props.markAsCompleted(e, props?.index, props?.subSection?.title)}>
                        {t('onboarding.plan.mark-complete')}
                    </button>
                </div>
            :
                null
        }

    </div>

  );
}

export default OnboardingSectionWidget;