// React
import React, {useState, useEffect} from "react";

// Common
import Configs from '../Configs';

// 3P
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
import {XYPlot, XAxis, YAxis, VerticalBarSeries} from 'react-vis';
import '../../node_modules/react-vis/dist/style.css';
import NonEditableEditor from "./noneditableEditor";

/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function CollapsibleQuestionListItemNew({
    question,
    collapsed, // default state is true
}) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

// Props

const auxData = [
  {x: 1, y: 0, color: "#A7002D"},
  {x: 2, y: 0, color: "#C40233"},
  {x: 3, y: 0, color: "#BDBABB"},
  {x: 4, y: 0, color: "#127852"},
  {x: 5, y: 0, color: "#00573F"}
];
const [collapsedState, setCollapsedState] = useState (collapsed);
const [dataArray, setDataArray] = useState (auxData);

// if (Configs.devEnvironment) console.log("[questionListItemNew.js][main()] question: ", question);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        graphDataFromJSON (question);
}, [question]);

// ------------------------------------------------------------------------------------------
// Frontend support methods
// ------------------------------------------------------------------------------------------
async function graphDataFromJSON (question) {
    let inputData = question?.answers;
    let questionData = question?.question;
  /**
     * Input data Format:
     * Array of:
     * answerID: ID
     * answerIDCount: num of responses
     * answerIDSum: sum of responses
     */

     var dataAux = [
      {x: 1, y: 0, color: "#A7002D"},
      {x: 2, y: 0, color: "#C40233"},
      {x: 3, y: 0, color: "#BDBABB"},
      {x: 4, y: 0, color: "#127852"},
      {x: 5, y: 0, color: "#00573F"}
    ];

    if (questionData?.type === "standard") {
      // For each input data, update Aux
      inputData?.forEach((answer, index) => {
        // Update answerID in index = datapoint, update
          // dataAux[answer.answerID - 1].x = answer.answerID;
          if (answer?.answerID > 0 && answer?.answerID < 6 && answer?.answerID) {
          dataAux[answer?.answerID - 1].y = answer?.answerIDCount;
          }
        });
    } else if (questionData?.type === "eNPS") {
      // For each input data, update Aux

      if (Configs.devEnvironment) console.log("[questionListItem.js][graphDataFromJSON()] eNPS");
    } else if (questionData?.type === "open") {
      if (Configs.devEnvironment) console.log("[questionListItem.js][graphDataFromJSON()] open");
      // if (Configs.devEnvironment) console.log("[questionListItem.js][graphDataFromJSON()] - open question data: ", inputData, questionData);
    }


    // if (Configs.devEnvironment) console.log ("[questionListItem.js][graphDataFromJSON()] question: ", questionData?._id, dataAux);

    // Retun data
    setDataArray([...dataAux]);
  }
  



// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
if (!question) {
    <React.Fragment>

    </React.Fragment>
} else { 
    return (
        
        <div className='flex flex-col border p-8 m-1 my-2 lg:my-4 lg:ml-6 lg:mr-6 justify-between shadow-xl rounded-lg bg-white'> 
                        <button onClick={() => setCollapsedState(!collapsedState)}>
                            <div className="flex flex-row justify-between items-start w-full">

                            <div className="flex flex-col lg:flex-row items-start justify-between w-full">
                                <div className="flex flex-col mr-4 text-left">
                                    <div className="flex flex-row items-center justify-between">
                                        <h3 className='text-xl font-bold mr-8 lg:mr-0 mb-4'>{i18next.language === 'es' ? (question?.question?.Question_ESP ? question?.question?.Question_ESP : question?.question?.Question) :
                                        (question?.question?.Question ? question?.question?.Question : question?.question?.Question_ESP)}</h3>
                                    
                                        <div className="flex block lg:hidden">
                                        {collapsedState ?
                                            <React.Fragment>
                                                <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                                                    <path d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z" fill="currentColor" fill-rule="evenodd">
                                                    </path>
                                                </svg>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div>
                                                    <svg fill="none" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-up" class="inline h-1.5">
                                                        <path d="M12.085 6.23a1.09 1.09 0 01-.004 1.477 1.093 1.093 0 01-1.605.005l-3.84-4.17a.544.544 0 00-.8 0l-3.84 4.17a1.093 1.093 0 01-1.604-.005A1.093 1.093 0 01.388 6.23L5.436.747a1.088 1.088 0 011.6 0l5.05 5.482z" fill="currentColor" fill-rule="evenodd">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    
                                    </div>

                                    { question.questionDate ? 
                                    <div className="flex text-left">
                                    <span className='m-2 title-description'> {t('date')}: {new Date(question?.questionDate).getUTCDate()}/{new Date(question?.questionDate).getUTCMonth() + 1}/{new Date(question?.questionDate).getUTCFullYear()}</span>
                                    </div>
                                    : null
                                    }
                                </div>
                                
                                <div className="flex flex-col lg:flex-row items-center ml-2">
                                {question?.question?.type !== "open" ?
                                    <div className='flex flex-col bg-gray-100 rounded px-8 py-2 mr-2 mb-4 mt-4 lg:mb-0 lg:mt-0 w-full lg:w-auto'>
                                        <div className="flex flex-row items-end">
                                            <span className='text-sm mr-2'>{t('score')}</span>
                                            <span className={" text-4xl font-bold mr-4 " }>
                                                {Number(question?.answerScore?.toFixed(1)) ? Number(question?.answerScore?.toFixed(1)) : question?.answerScore}
                                            </span>
                                        
                                            <span className='text-sm text-gray-700 mr-2'>{t('benchmark')}</span>
                                            <span className='text-xl text-gray-700'>
                                                {Number(question?.companyScore?.questions?.answerScore?.toFixed(1)) ? Number(question?.companyScore?.questions?.answerScore?.toFixed(1)): question?.companyScore?.questions?.answerScore}
                                            </span>
                                        </div>
                                    </div>
                                : null}
                                    <div className='flex flex-row bg-gray-100 rounded px-8 py-2 mr-4 items-end mb-4 mt-4 lg:mb-0 lg:mt-0 w-full lg:w-auto'>
                                        <span className='text-4xl font-bold'>
                                            {Number(question?.answerCount?.toFixed(1)) ? Number(question?.answerCount?.toFixed(1)) : question?.answerCount}
                                        </span>
                                        <span className='text-sm ml-2'>{t('responses')}</span>
                                    </div>

                                </div>
                            </div>
                            <div className="flex hidden lg:block">
                                {collapsedState ?
                                    <React.Fragment>
                                        <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                                            <path d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z" fill="currentColor" fill-rule="evenodd">
                                            </path>
                                        </svg>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div>
                                            <svg fill="none" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-up" class="inline h-1.5">
                                                <path d="M12.085 6.23a1.09 1.09 0 01-.004 1.477 1.093 1.093 0 01-1.605.005l-3.84-4.17a.544.544 0 00-.8 0l-3.84 4.17a1.093 1.093 0 01-1.604-.005A1.093 1.093 0 01.388 6.23L5.436.747a1.088 1.088 0 011.6 0l5.05 5.482z" fill="currentColor" fill-rule="evenodd">
                                                </path>
                                            </svg>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>

                        </div>
                        </button>

                        <div className={collapsedState ? 
                                "hidden"
                                :
                                " flex flex-col md:flex-row bg-gray-100 rounded display "}>
                                                        
                                <React.Fragment>

                                    {question?.question?.type === "standard" ? 
                                    question?.answerCount <= 2 ? 
                                    <div className="title-description p-4">
                                        🔒 {t('pulse.notEnoughResponses')}
                                    </div>
                                    :
                                    <div className='flex flex-col md:flex-row justify-between w-full '>
                                        <div className='flex flex-col p-4 m-1 text-center w-full '>
                                            <div className='flex flex-col'>
                                            <span className="text-sm mb-4 font-bold underline">
                                                {t('responses')}
                                            </span>
                                            <ul className="text-left text-sm">
                                            <li className="mb-2">
                                                <span className="px-2 py-1 text-white bg-[#00573F] rounded-full mr-1 items-center">5</span> {question?.question?.Resp1_String_ESP ? question.question.Resp1_String_ESP : null} 
                                            </li>
                                            <li className="mb-2">
                                            <span className="px-2 py-1 text-white bg-[#127852] rounded-full mr-1 items-center">4</span> {question?.question?.Resp2_String_ESP ? question.question.Resp2_String_ESP : null} 
                                            </li>
                                            <li className="mb-2">
                                            <span className="px-2 py-1 text-white bg-[#BDBABB] rounded-full mr-1 items-center">3</span> {question?.question?.Resp3_String_ESP ? question.question.Resp3_String_ESP : null} 
                                            </li>
                                            <li className="mb-2">
                                            <span className="px-2 py-1 text-white bg-[#C40233] rounded-full mr-1 items-center">2</span> {question?.question?.Resp4_String_ESP ? question.question.Resp4_String_ESP : null} 
                                            </li>
                                            <li className="mb-2">
                                            <span className="px-2 py-1 text-white bg-[#A7002D] rounded-full mr-1 items-center">1</span> {question?.question?.Resp5_String_ESP ? question.question.Resp5_String_ESP : null} 
                                            </li>
                                            </ul>
                                            </div>
                                        </div>

                                        <div className='flex flex-col rounded items-center p-4 text-sm m-1 w-full'>
                                        <span className='text-sm text-center mb-2 font-bold underline'> {t('result')} </span>
                                        <div className='overflow-x-auto p-8'>
                                            <XYPlot className="" height={150} width={300} colorType="literal">
                                            <VerticalBarSeries  color="#000000" data={dataArray} />
                                            <XAxis title={t('score')} tickValues={[1, 2, 3, 4, 5]} />
                                            <YAxis title={t('votes')} />

                                            </XYPlot>
                                        </div>
                                        </div>
                                    </div>

                                : 
                                
                                question?.question?.type === "open" ?
                                    <div className="flex flex-col p-4 space-y-2">
                                    {question?.answers?.map((answer, index) => (
                                        <div className="flex flex-col border-l-4 border-l-gray-300 text-sm">
                                        <NonEditableEditor content={answer?.answerID} />
                                        </div>
                                    ))}
                                    
                                    </div>
                                :
                                    null
                                }

                            </React.Fragment>
                            
                        </div>
            </div>

    );
    }
}

export default CollapsibleQuestionListItemNew;

