// React
import React, {useEffect, useState} from 'react';

// 3P
import { useTranslation } from 'react-i18next';

// Widgets
import Tooltip from '../../common/support/tooltip';
import DataTable from '../dataTable';
import Loader from '../../common/support/loader';


const RequestListItem = ({
  request, index, 
  handleApprove, handleReject,
  viewAttachmentMethod, 
  collapsed, loading,
  setDetailedCalendarDate 
}) => {
    const {t} = useTranslation();
    const [isCollapsed , setIsCollapsed] = useState(collapsed);

    // DataTable columns
    let columnNamesValuesTable = [
         {type:'string',id: 'leaveType', value: 'leaveType', label: t('time-off.home.leave-type'), sort: null, filter: null, translate: true},
        {type:'date',id: 'startDate',value: 'startDate', label: t('start-date'), sort: null, filter: null},
        {type:'date',id: 'endDate',value: 'endDate', label: t('end-date'), sort: null, filter: null},
        {type:'number',id: 'daysCount',value: 'daysCount', label: t('time-off.home.days-count'), sort: null, filter: null},
        {type: 'action', id: 'action', value: 'action', label: t('time-off.home.action'), sort: null, filter: null, translate: true}
      ];

// ------------------------------------------------------------
// Render method
// ------------------------------------------------------------
    return (
        <div className='flex flex-col w-full bg-white shadow rounded-lg border border-gray-200 p-4'>
                            <div className='flex flex-row w-full items-center justify-between ' key={index}>
                              {/* Employee info: Profile Picture + Name */}
                              <div className='flex flex-row items-center'>
                                {request?.profilePicture ? 
                                    <img className="rounded-full h-[30px] w-[30px] mr-2 border" src={request?.employee?.profilePicture} alt={request?.employee + " profile picture"} />
                                  :
                                    <div className="flex h-[30px] w-[30px] text-xs p-2 mr-2 rounded bg-gray-100 items-center justify-center">
                                      {request?.name?.slice(0,1) + request?.surname?.slice(0,1)}
                                    </div>
                                }
                                <span className='text-sm text-gray-800'>
                                  {request?.employee  + " " + t('time-off.team.has-requested') + " "}<b className='font-bold'> {request?.sumOfDaysCount?.toString() + " " + t('days')}</b>
                                </span>
                                </div>


                                {/* Actions */}
                                {loading  ?
                                  <Loader />
                                :
                                  <div className='flex flex-row space-x-4'>
                                    <button className='hover:underline text-xs' onClick={() => setDetailedCalendarDate(new Date(request?.request[0]?.startDate))}>
                                      {"📅 " + t('time-off.calendar.see-date-details')}
                                    </button>
                                    <button className='hover:underline text-xs' onClick={() => setIsCollapsed(!isCollapsed)}>
                                      {"📜 " + t('more-details')}
                                    </button>
                                    <Tooltip content={t('reject')}>
                                      <button className='remove-button' onClick={(e) => {handleReject(e, index)}}>
                                      👎
                                      </button>
                                    </Tooltip>
                                    <Tooltip content={t('approve')}>
                                      <button className='save-button' onClick={(e) => {handleApprove(e, index)}}>
                                      👍
                                      </button>
                                    </Tooltip>
                                    
                                  </div>
                                }

                            </div>

                            {/* Request details - collapsible */}
                            {!isCollapsed && 
                                    <DataTable data={request?.request} columnNames={columnNamesValuesTable} zerosForDashes={true}
                                    viewAttachmentMethod={viewAttachmentMethod}
                                     />
                            }
                          </div>  
    );
}

export default RequestListItem;