// React
import React from 'react';
import { Link } from 'react-router-dom';

// 3P
import { t } from 'i18next';

// Icons
import {BiSad} from 'react-icons/bi';
import {BsHouseFill} from "react-icons/bs";


// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Forbidden = () => (
    <React.Fragment>
        {/* <Header/> */}
        <div className='flex flex-col justify-center pl-32'>
            <div className='flex flex-col items-center shadow-lg m-20 rounded-lg border border-gray-100'>
                <h2 className='flex flex-row text-4xl font-bold  m-20'>
                    <span className='text-green-600 text-xl'>error</span>
                    <span className='ml-4 mr-4 text-6xl'>|</span>
                    <span>{t('error.unauthorized')}  </span> 
                    <BiSad className='ml-4'/>
                </h2>
                <span className='flex flex-row justify-between items-center text-center m-20'>
                    <Link to="/" className='flex flex-row items-center hover:underline font-bold italic'>
                        <BsHouseFill className='mr-2 italic'/>
                         {t('error.404.description')} 
                    </Link>
                </span>
            </div>

            
            
        </div>
</React.Fragment>
);

export default Forbidden;