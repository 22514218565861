// React
import React, { useState, useEffect} from 'react';
import {Link, useParams, useLocation, useNavigate} from "react-router-dom";

// Common
import Configs from '../../Configs';
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import PerformanceCampaignListItem from '../../widgets/performanceCampaignListItem';
import { useAuth } from '../../common/appContext';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';

function PerformanceCampaign () {
    // Employee Info
    const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const { token } = useAuth();

    // Params
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // To pass location to the Header
    const location = useLocation();
    const navigate = useNavigate();
    const [currentScreen, setCurrentScreen] = useState(location.pathname);

    // View specific variables
    const [performanceReviewCampaign, setPerformanceReviewCampaign] = useState(params ? params.campaign : null);

    // Toaster and Loader
    const [isLoading, setIsLoading] = useState(false);
    const [toasterMessage, setToasterMessage] = useState(null);

    if (Configs.devEnvironment) console.log ("DEV ONLY log - Campaigns: ", performanceReviewCampaign);


    // ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        if (params) getCampaign(params.campaignID);
      }, []);

    
  // ---------------------------------------------------------------------------------------------------
  // API calls methods
  // ---------------------------------------------------------------------------------------------------
  async function getCampaign (campaignID) {
      // fetch from server      
      fetch(Configs.platformGetPerformancePeerFeedbackAPI + "?campaignID=" + campaignID + "&companyID=" + companyID + "&employeeID=" + employeeID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        }})
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
              // Username and password mathched in the database - save Live campaigns
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log("Campaign is: ", body)
                  if (body.length > 0) {
                    setPerformanceReviewCampaign(body[0]);
                  } else {
                    setPerformanceReviewCampaign(null);
                  }
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 403) {
                 

            } else {
              // There is an error - delete info

              throw response;
            }
          })
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          });
    }

    async function postCampaign () {
      // fetch from server      
      fetch(Configs.platformPostPerformanceCampaignAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        },      
        body: JSON.stringify({
          campaign: performanceReviewCampaign
        }),
        })
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
              // Username and password mathched in the database - save Live campaigns
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log(body)
                  if (body.length > 0) {
                    
                  } else {

                  }
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 403) {
                 

            } else {
              // There is an error - delete info
              

              throw response;
            }
          })
    
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          });
    }

    // ---------------------------------------------------------------------------------------------------
    // Frontend control methods
    // ---------------------------------------------------------------------------------------------------
    async function cancel (e) {
      e.preventDefault();

      navigate(-1);
    }
    
    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
      };
    

   // ---------------------------------------------------------------------------------------------------
   // Render methods
   // ---------------------------------------------------------------------------------------------------


    return (
      
      <React.Fragment>
      <Header/>
      <Bot />

      <div className="flex flex-col lg:ml-[215px]">
    

          <PerformanceHeader livePerformanceCampaign={false} currentScreen={currentScreen} />

          <div className="flex flex-col m-2 lg:m-4 text-left ">


            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

              <div className="flex flex-col md:flex-row mt-2 md:ml-2 m-1 items-end justify-between">
                <div className='flex flex-col'>
                  <h2 className="flex text-xl mb-4 font-bold">{t('performance.hr.manage-campaigns')}</h2>
                  <span className="title-description"> 
                    {t('performance.hr.manage-campaigns.description')}
                  </span>
                </div>
                
              </div>

              <div className='flex flex-col'>
                <PerformanceCampaignListItem campaign={performanceReviewCampaign} collapsed={false} editable={true} cancel={cancel}/>
              </div>                  

              <SectionSeparator />
          </div>

        </div>
      </React.Fragment>
    );
}

export default PerformanceCampaign;

