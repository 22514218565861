
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder';


import { useTranslation } from 'react-i18next';
import Configs from '../Configs';

import React from 'react';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';


function NonEditableEditor (props) {
  const {t, i18n} = useTranslation();  

  const editor = useEditor({
    editable: false,
    content: props.content ? `${props.content}` : null,

    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none p-4 bg-white ',
      },
    },
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: (props.placeholder !== undefined ? props.placeholder : t('oneOnOne.meeting.content.placeholder')),
      }),
      Table.configure({
        resizable: true,
        // caption: t('oneOnOne.meeting.content.table.caption'),
      }),
      TableRow,
      TableCell,
      TableHeader,
    ],
    onUpdate: ({editor}) => {
      // Get content
      props.setContent(editor.getHTML());
    }
  }, [props.content]);

  return (
    <React.Fragment>
      <EditorContent className="" editor={editor} id="editor" />
    </ React.Fragment>
  )
}

export default NonEditableEditor;