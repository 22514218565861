// React
import React, {useState, useLocation, useEffect} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";

// Header
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common and widgets
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import ValuesSelector from '../../widgets/valuesSelector';
import ValuesScoring from '../../widgets/valuesScoring';
import ValuesScoringWithComment from '../../widgets/valuesScoringWithComment';
import UserListItem from '../../widgets/userListItem';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Icons
import {IoWarningOutline} from 'react-icons/io5';

// Editos
import Editor from '../../widgets/editor';
import NonEditableEditor from '../../widgets/noneditableEditor';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';

// ---------------------------------------------------------------------------------------------------
function PerformanceFeedback () {
  // Translation objects
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    // Get params from URL
    let { peerCompanyID, peerEmployeeID, peerCampaignID, selfEmail, notificationID} = useParams();

    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const { token } = useAuth();
    const {toasterMessage, setToasterMessage} = useData();

    // Variables needed to populate screen
    const [campaign, setCampaign] = useState(null);
    const [companyValues, setCompanyValues] = useState(null);
   
    // Variables to capture input
    const [growthSelectedValues, setGrowthSelectedValues] = useState(null);
    const [strengthsSelectedValues, setStrengthsSelectedValues] = useState(null);
    const [growthValuesResult, setGrowthValuesResult] = useState(0);
    const [strengthsValuesResult, setStrengthsValuesResult] = useState(0);
    const [existingDraft, setExistingDraft] = useState(false);

    const [feedback, setFeedback] = useState([]); // Same as questionnaire but with answers

    // Toaster
    const [isLoading, setIsLoading] = useState(false);

    // AUTO-SAVE FEATURE
    const [employeeChanged, setEmployeeChanged] = useState(false);

   // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------
  useEffect (() => {
    // Update draft in case it has been updated
    if (!campaign) getPeerFeedbackInformation(peerCompanyID, peerEmployeeID, peerCampaignID);    
  }, []);

  // AUTO-SAVE USE EFFECT
  useEffect (() => {
    const interval = setInterval(() => {
      if (Configs.devEnvironment) console.log ("[performanceStrengths.js][useEffect()] - Auto-save", employeeChanged);

      // If there was an update in feedback, save it
      if (employeeChanged) {
        submitFeedback(false);
      }

    }, 30000); // Auto-save every 30 seconds

    return () => {
      clearInterval(interval);
    }
    
  }, [employeeChanged]);


  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------
  async function getPeerFeedbackInformation (peerCompanyID, peerEmployeeID, peerCampaignID) {
    setIsLoading(true);

    if (Configs.devEnvironment) console.log ("[performanceFeedback.js][getPeerFeedbackInformation()] - request: ", peerCompanyID, peerEmployeeID, peerCampaignID, selfEmail);


    // fetch from server      
    fetch(Configs.platformGetPerformancePeerFeedbackAPI + "?employeeID=" + peerEmployeeID +
       "&companyID=" + peerCompanyID + "&campaignID=" + peerCampaignID + "&feedbackProviderEmail=" + selfEmail, {
      method: 'get',
      // credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      }})
        .then((response) => {
          setIsLoading(false);

          if (response.status === 200) {
            // Found a draft
            response.json()
            .then (body => {
               if (Configs.devEnvironment) console.log ("[performanceFeedback.js][getPeerFeedbackInformation()] - body from getPeerFeedbackInformation: ", body[0]);
                setCampaign (body[0]);

                if (body[0].feedbackProviderDraft?.strengthFeedback && body[0].feedbackProviderDraft?.strengthFeedback?.questionnaire
                  && body[0].feedbackProviderDraft?.strengthFeedback?.values) {
                  // Existing draft
                  if (Configs.devEnvironment) console.log ("[performanceFeedback.js][getPeerFeedbackInformation()] - body from getPeerFeedbackInformation: ", body[0]?.feedbackProviderDraft?.strengthFeedback?.questionnaire);
                  if(body[0]?.feedbackProviderDraft?.strengthFeedback?.questionnaire) setFeedback(body[0]?.feedbackProviderDraft?.strengthFeedback?.questionnaire);
                  if (body[0]?.feedbackProviderDraft?.strengthFeedback?.values) {
                    setStrengthsSelectedValues(body[0]?.feedbackProviderDraft?.strengthFeedback?.values);
                    calculateValuesResult(body[0]?.feedbackProviderDraft?.strengthFeedback?.values);
                  }
                  // setGrowthSelectedValues(body[0]?.feedbackProviderDraft?.growthFeedback?.values);
                  setExistingDraft(true);
                } else {
                  // No draft
                  setCompanyValues(body[0]?.companyPreferences[0]?.companyValues);
                  // setGrowthSelectedValues(body[0]?.companyPreferences[0]?.companyValues);
                  setStrengthsSelectedValues(body[0]?.companyPreferences[0]?.companyValues);
                  setExistingDraft(false);
                }
              })
            .catch(error => {
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              console.error("[performanceFeedback.js][getPeerFeedbackInformation()] Error parsing response JSON", error)
            });
          } else if (response.status === 400) {
                // Something went wrong - inform user
                setCampaign(null);
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
          } else if (response.status === 401) {
              // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                setCampaign(null);
                sessionStorage?.clear();
                window?.location?.reload();
          } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                setCampaign(null);
                sessionStorage.clear();
                window.location.reload();
          } else if (response.status === 404) {
                setCampaign(null);
                setToasterMessage({message: t('not-found'), type: Configs.successToaster});
          } else {
            //
            setCampaign(null);
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
            throw response;
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("[performanceFeedback.js][getPeerFeedbackInformation()] Error fetching data: ", error);
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          setCampaign(null);
        });
  }

  async function submitFeedback (isFinal = true) {
    // HTTP POST request options
    if (Configs.devEnvironment) console.log ("[performanceFeedback.js][submitFeedback()] - strengths: ", strengthsSelectedValues);
    if (Configs.devEnvironment) console.log ("[performanceFeedback.js][submitFeedback()] - feedback: ", feedback);


  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      employeeID: peerEmployeeID,
      companyID: peerCompanyID,
      campaignID: peerCampaignID,
      email: selfEmail,
      notificationID: notificationID,
      isFinal: isFinal,
      values: [{
        type:"strength",
        values:strengthsSelectedValues
      },
      {
        type:"growth",
        values: growthSelectedValues
      }],
      feedback: feedback,
    })
  };

  // Post to server  
  fetch(Configs.platformPostPeerFeedbackAPI, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          // All good
          if (isFinal) {
            setToasterMessage({message: t('success'), type: Configs.successToaster});
            navigate("/");
          }
        } else if (response.status === 400) {
          // Something went wrong - inform user
          setToasterMessage({message: t('error'), type: Configs.errorToaster});

        } else if (response.status === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
              sessionStorage?.clear();
              window?.location?.reload();
        } else if (response.status === 403) {
              // Force logout
              setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
        } else if (response.status === 404) {
              setToasterMessage({message: t('not-found'), type: Configs.successToaster});
        } else {
          //
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }
      })

      .catch((error) => {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
        console.error("[performanceFeedback.js][submitFeedback()] Error fetching data: ", error);
      });
         
}
// ---------------------------------------------------------------------------------------------------
// Inputs validation
// ---------------------------------------------------------------------------------------------------
  async function validateInputs () {
    // Check if feedback is not empty
    var feedbackNotEmpty = false;
    feedback.forEach (element => {
      if (element.answer !== "" && element.answer !== "<p></p>" && element.answer !== "<p><br></p><p><br></p>" && element.answer) {  
        feedbackNotEmpty = true;
      }
    });

    // Check if values are not empty
    var valuesNotEmpty = true;
    // if (strengthsSelectedValues.length > 0 && growthSelectedValues.length > 0) {
    if (strengthsSelectedValues.length > 0) {
      strengthsSelectedValues.forEach (element => {
        if (!element.selected || element.selected === "" || !element.comment || element.comment === "<p></p>") {
            valuesNotEmpty = false;
        }
      });
    }

    if (feedbackNotEmpty && valuesNotEmpty) {
      // All inputs are there, submit feedback
      submitFeedback();
    } else {
      // Some inputs are missing, inform user
      setToasterMessage({message: t('warning.empty-inputs'), type: Configs.warningToaster});
    }

  }


  // ---------------------------------------------------------------------------------------------------
  // Frontend management
  // ---------------------------------------------------------------------------------------------------
    async function answerUpdated (answer, type, index) {
        // Update state for AUTO-SAVE
        setEmployeeChanged(true);

        // Create the object to update
        var objectAnswer = {
            type: type,
            title: campaign.feedbackQuestionnaire[index].title,
            description: campaign.feedbackQuestionnaire[index].description,
            answer: answer
        }

        if (Configs.devEnvironment) console.log (objectAnswer)

        // Get an auxiliary array to update
        var auxQuestionnaire = feedback;
        auxQuestionnaire[index] = objectAnswer;

        // Set feedback back to its state
        setFeedback([...auxQuestionnaire]);
    }

    async function getSelectedValues (valuesSelected, feedbackType) {
      // Update state for AUTO-SAVE
      setEmployeeChanged(true);

      // Update from Values widget
      if (Configs.devEnvironment) console.log ("DEV ONLY - ", valuesSelected, feedbackType);

      if (feedbackType === "growth") {
        setGrowthSelectedValues([...valuesSelected]);
      } else if (feedbackType === "strength") {
        setStrengthsSelectedValues([...valuesSelected]);
      } else {
        // do nothing
      }
    }

    async function calculateValuesResult (inputGrades) { 
      if (campaign?.valuesSelectorType === "score" || campaign?.valuesSelectorType === "scoreWithComments") {
        if (Configs.devEnvironment) console.log ("[performanceFeedback.js][calculateValuesResult()] - inputGrades: ", inputGrades);
          // Calculate result for input
          var count = 0;
          var auxResult = 0;
          inputGrades.forEach(element => {
              count += 1;
              auxResult = parseInt(auxResult) + parseInt(element.selected);
          });

            setStrengthsValuesResult(auxResult / count);
      }
    }
  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
  };

  // ---------------------------------------------------------------------------------------------------
  // Render methods
  // ---------------------------------------------------------------------------------------------------
    

    return (
        <React.Fragment>
          <Header />
          <Bot />


          <div className="main">

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

          <div className='m-6'>
                      
                <div className='flex flex-col mt-2 ml-2 m-1'>
                    <h2 className='section-title text-left mb-4'> 
                      {t('feedback.peer')}
                    </h2>
                    {/* <span className='text-left mb-4 title-description'>
                      {t('feedback.peer.description')}
                        <b> {t('feedback.peer.help')} </b>
                    </span> */}
                    <div className="flex flex-col">
                        <UserListItem user={campaign?.employee?.name + " " + campaign?.employee?.surname} 
                        initials={campaign?.employee?.name?.slice(0,1) + campaign?.employee?.surname?.slice(0,1)} 
                        role={campaign?.employee?.role} level={campaign?.employee?.level} />
                      </div>        

                </div>
                
                { isLoading ?

                  <Loader />

                :
                
                  (!campaign) ? t('feedback.peer.reload-page') :  
                      existingDraft && feedback && strengthsSelectedValues ?
                      // Existing draft
                      feedback?.map((element, index) => (
                        <React.Fragment>
                              <div className='flex flex-col text-left mt-8 ml-2 m-1'>
                                  <h3 className='flex text-l mb-4 font-bold'>
                                    {element.title}
                                  </h3>
                                  <span className='title-description'>
                                    {element.description}
                                  </span>

                                <div className="flex flex-col items-start w-full border bg-white rounded-lg shadow ">
                                  <Editor setContent={answerUpdated} content={element.answer} placeholder={t('form.fill-details')}
                                  editorType={"performanceReviewFeedback"} type={element.type} index={index}/>
                                </div>

                              </div>

                              <div className='flex flex-col mt-4 m-1'>

                                {(element.type === "strength") ?
                                          <React.Fragment>

                                              {(campaign?.valuesSelectorType === "score") ? 
                                                      <ValuesScoring values={strengthsSelectedValues} selectedValues={getSelectedValues} result={strengthsValuesResult} setValuesResult={calculateValuesResult}
                                                      readOnly={false} type={element.type}/>
                                              :

                                              (campaign?.valuesSelectorType === "scoreWithComments") ? 
                                                            <ValuesScoringWithComment values={strengthsSelectedValues} selectedValues={getSelectedValues} result={strengthsValuesResult} setValuesResult={calculateValuesResult}
                                                            readOnly={false} type={element.type}/>
                                              :
                                              (campaign?.valuesSelectorType === "selector") ? 
                                                            <ValuesSelector values={strengthsSelectedValues} selectedValues={getSelectedValues} 
                                                            readOnly={false} type={element.type}/>
                                              :
                                              null
                                              }
                                          </React.Fragment>
                                :
                                null
                                }
                              </div>
                            </React.Fragment>
                      ))
                      :
                      // No draft
                      campaign?.feedbackQuestionnaire?.map((element, index) => (   
                            <React.Fragment>                   
                                    <div className='flex flex-col text-left mt-8 ml-2 m-1'>
                                        <h3 className='flex text-l mb-4 font-bold'>
                                          {element.title}
                                        </h3>
                                        <span className='title-description'>
                                          {element.description}
                                        </span>

                                      <div className="flex flex-col items-start w-full border bg-white rounded-lg shadow ">
                                        <Editor setContent={answerUpdated} content={element.answer} placeholder={t('form.fill-details')}
                                        editorType={"performanceReviewFeedback"} type={element.type} index={index}/>
                                      </div>

                                    </div>

                                    <div className='flex flex-col mt-4 m-1'>

                                      {(element.type === "strength") ?
                                                <React.Fragment>

                                                    {(campaign?.valuesSelectorType === "score") ? 
                                                            <ValuesScoring values={strengthsSelectedValues} selectedValues={getSelectedValues} result={strengthsValuesResult} setValuesResult={calculateValuesResult}
                                                            readOnly={false} type={element.type}/>
                                                    :

                                                    (campaign?.valuesSelectorType === "scoreWithComments") ? 
                                                                  <ValuesScoringWithComment values={strengthsSelectedValues} selectedValues={getSelectedValues} result={strengthsValuesResult} setValuesResult={calculateValuesResult}
                                                                  readOnly={false} type={element.type}/>
                                                    :
                                                    (campaign?.valuesSelectorType === "selector") ? 
                                                                  <ValuesSelector values={strengthsSelectedValues} selectedValues={getSelectedValues} 
                                                                  readOnly={false} type={element.type}/>
                                                    :
                                                    null
                                                    }
                                                </React.Fragment>
                                      :
                                      null
                                      }
                                    </div>
                                
                          </React.Fragment>
                        
                      ))
                    }

                    {/* Warning message */}
                    <div className='flex flex-row bg-yellow-100 p-2 md:mr-8 m-2 text-sm items-center'>
                      <div className='mr-4'><IoWarningOutline /></div>
                      {t('performance.review.send-peer-feedback.warning')}
                    </div>


                {/* Button */}
                <div className="flex flex-col items-center lg:items-end w-auto mb-12 p-8">
                    <button className='save-button' 
                    onClick={validateInputs}
                    disabled={isLoading}> 
                      {t('submit')}
                    </button>
                </div>                           
                
            </div>
            </div>
        </React.Fragment>
    );
}

export default PerformanceFeedback;