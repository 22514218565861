import React from 'react';
import Configs from '../../Configs';

// ---------------------------------------------------------------------------------------------------
// Formatters class
class CustomFormatters {
  static WoWPercentageChangeFormatter = ({ value }) => {
    if (Configs.devEnvironment) console.log("[customFormatters.js][WoWPercentageChangeFormatter()] value:", value);
    const wowChange = value; // assuming `wowChange` is the field with the WoW percentage change
    const formattedChange = `${wowChange > 0 ? '+' : '-'}${wowChange.toFixed(1)}%`;

    const style = {
      color: wowChange > 0 ? 'green' : wowChange < 0 ? 'red' : 'black',
      fontWeight: 'bold',
    };

    return (
      <span style={style}>
        {formattedChange}
      </span>
    );
  };

  // MoM
    static MoMPercentageChangeFormatter = ({ value }) => {
        if (Configs.devEnvironment) console.log("[customFormatters.js][MoMPercentageChangeFormatter()] value:", value);
        const momChange = value; // assuming `momChange` is the field with the MoM percentage change
        const formattedChange = `${momChange > 0 ? '+' : '-'}${momChange.toFixed(1)}%`;

        const style = {
            color: momChange > 0 ? 'green' : momChange < 0 ? 'red' : 'black',
            fontWeight: 'bold',
            };

        return (
            <span style={style}>
            {formattedChange}
            </span>
        );
    }

  // Add more formatters here as needed
  static CurrencyFormatter = ({ row, column }) => {
    const value = row[column.key]; // assuming the field to format is passed in as the column key
    const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

    return (
      <span>
        {formattedValue}
      </span>
    );
  };

  static DateFormatter = ({ row, column }) => {
    const value = new Date(row[column.key]);
    const formattedDate = value.toLocaleDateString('en-US');

    return (
      <span>
        {formattedDate}
      </span>
    );
  };

  // Add other formatters as needed...
}

export default CustomFormatters;