// React
import React, {useState, useEffect} from 'react';
import {Link, useParams, useNavigate, useLocation} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Common
import ProfileHeader from './profileHeader';
import Header from '../../common/header';
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import SpiderPerformance from '../../widgets/spiderPerformance';

// Loader and Toaster
import Loader from '../../common/support/loader';
import Toaster from '../../common/support/toaster';
import Tooltip from '../../common/support/tooltip';

// Widgets
import UserListItem from '../../widgets/userListItem';
import { FaAward } from 'react-icons/fa';
import { CgPerformance } from 'react-icons/cg';

// ---------------------------------------------------------------------------------------------------
function Profile () {
    // Get params from URL
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    const navigate = useNavigate();
    let location = useLocation();

    // User Info
    const {token, employeeID, companyID, securityLevel} = useAuth();
    const {profile, selectedProfile, setSelectedProfile, companyPreferences, getProfile, isProfileLoading, isSelectedProfileLoading} = useData(); 

    const [profileData, setProfileData] = useState(null);
    const [manager, setManager] = useState(selectedProfile ? selectedProfile?.manager : profile?.manager);

    // Recognition Data
    const [recognitionsGiven, setRecognitionsGiven] = useState(null);
    const [recognitionsReceived, setRecognitionsReceived] = useState(null);

    // Performance
    const [performanceData, setPerformanceData] = useState(null);

    // Toaster and Loader
    const [toasterMessage, setToasterMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPerformanceLoading, setIsPerformanceLoading] = useState(false);

    if (Configs.devEnvironment) console.log ("[profile.js]][main()] Profile = ", profile, selectedProfile);
    if (Configs.devEnvironment) console.log ("[profile.js]][main()] params = ", params);

// ---------------------------------------------------------------------------------------------------
// useEffect
// ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    useEffect (() => {
        // Get performance data
        if (securityLevel?.includes(Configs.securityLevelManager) && profile?.managerID === employeeID 
        || securityLevel?.includes(Configs.securityLevelHR
          || securityLevel?.includes(Configs.securityLevelAdmin)
          || securityLevel?.includes(Configs.securityLevelExec))
          ) {
          if (performanceData === null) platformGetPerformanceForEmployee(params?.employeeID ? params?.employeeID : employeeID);
        }

        calculateRecognitionsData(selectedProfile ? selectedProfile?.recognitions : profile?.recognitions, selectedProfile ? selectedProfile.employeeID : profile?.employeeID);
        setManager(selectedProfile ? selectedProfile?.manager : profile?.manager);

        if (selectedProfile && (parseInt(selectedProfile?.employeeID) !== parseInt(employeeID))) {
          setProfileData(selectedProfile);
          setManager(selectedProfile?.managerInfo);
        } else {
          setProfileData(profile?.employeeInfo);
          setManager(profile?.managerInfo);
        }

        return () => {
          setSelectedProfile(null);
        };

      }, [location?.pathname]);

// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------

  async function platformGetPerformanceForEmployee (auxEmployeeID) {
    // Set loading state
    setIsPerformanceLoading(true);

    // fetch from server
    fetch(Configs.platformGetPerformanceCampaignsHistoryAPI + "?employeeID=" + (auxEmployeeID ? auxEmployeeID : employeeID) + "&companyID=" + companyID, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      }
    })
      .then((response) => {
        setIsPerformanceLoading(false);

        if (response.status === 200) {
          response.json()
            .then(body => {
              if (Configs.devEnvironment) console.log("[profile.js][platformGetPerformanceForEmployee()] Performance data from platformGetPerformanceForEmployee(): ", body);
              if (body?.length > 0) {
                transformPerformanceData(body[0]);
              } else {
                setPerformanceData(null);
              }

            })
            .catch(error => {
              console.error("[profile.js][platformGetPerformanceForEmployee()] Error parsing response JSON", error)
              setToasterMessage({ message: t('error'), type: Configs.errorToaster });
            });
        } else if (response.status === 400) {
          // Something went wrong - inform user
        } else if (response.status === 401) {
          // Force logout
          setToasterMessage({ message: t('error.unauthenticated'), type: Configs.errorToaster });
          sessionStorage.clear();
          window.location.reload();
        } else if (response.status === 403) {
          // Navigate back
          setToasterMessage({ message: t('error.unauthorized'), type: Configs.errorToaster });
          navigate(-1);
        } else if (response.status === 404) {
          setPerformanceData(null);
        } else {
          //
          // throw response;
        }
      })
      .catch((error) => {
        setIsPerformanceLoading(false);
        setToasterMessage({ message: t('error.server-unreachable'), type: Configs.errorToaster });
        console.error("[profile.js][platformGetPerformanceForEmployee()] Error fetching data: ", error);
      });
  }

  // ---------------------------------------------------------------------------------------------------
  // Navigation
  // ---------------------------------------------------------------------------------------------------
  async function navigateToManagerProfile () {
      if (profileData?.managerID === employeeID) {
        setSelectedProfile(null);
        navigate("/profile");
      } else {
        setSelectedProfile(profileData?.managerInfo);
        getProfile(profileData?.managerID, companyID);
      }
  } 

  // ---------------------------------------------------------------------------------------------------
  // Calculate recognitions
  // ---------------------------------------------------------------------------------------------------
async function calculateRecognitionsData (recognitions, profileEmployeeID) { 
  // Define variables
  let recognitionsGiven = 0;
  let recognitionsReceived = 0;

  // Look for recognitions given
  // Look for recognitions received
  recognitions?.forEach(recognition => {
    if (recognition?.employeeID === profileEmployeeID) recognitionsGiven++;
    if (recognition?.recognizedEmployees?.find(recognition => recognition === profileEmployeeID)) recognitionsReceived++;
  });

  if (Configs.devEnvironment) console.log ("[profile.js][calculateRecognitionsData()] recognitionsGiven: ", recognitionsGiven, " recognitionsReceived: ", recognitionsReceived);

  // Set state
  setRecognitionsGiven(recognitionsGiven);
  setRecognitionsReceived(recognitionsReceived);
}

async function transformPerformanceData (performanceDataAux) {

  // Iterate through 
  let transformedData = {};

  performanceDataAux?.values?.forEach(value => {
    var valueTrimmed = value?.value.replace(/\s/g, '');

    transformedData[valueTrimmed] = parseFloat(value?.selected);
    
  });

  if (Configs.devEnvironment) console.log ("[profile.js][transformPerformanceData()] transformedData: ", transformedData);

  setPerformanceData(transformedData);

}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />

      <Bot/>

      <div className="main">
        <ProfileHeader />
            
        <div className='flex flex-row items-start justify-between'>
          <div className="left-big-view">

        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        
        {isLoading || isProfileLoading || isSelectedProfileLoading ?
        
          <Loader />
      
        :
        
          <div className="flex flex-col  text-left ">
            {/* <h2 className="flex text-xl mb-2 md:mb-4 font-bold">
              {t('profile.personal-info.home.title')}
            </h2> */}

            {profileData ? 
            

              <div className="flex flex-col my-4 space-y-8">

                {/* Picture, Name, and Title */}
                <div className='flex flex-row items-start justify-between'>
                  <div className='flex flex-row items-center justify-start space-x-4'>
                      {/* Profile picture */}
                      <div className='flex flex-col items-center justify-center' id="profile-picture">
                        {profileData?.profilePicture ? 
                          <img className='w-32 h-32 rounded-full border shadow-lg' src={profileData?.profilePicture ? profileData?.profilePicture : null} alt='Profile picture' /> 
                        : 
                          <span className='flex flex-col w-32 h-32 rounded-full border shadow-lg text-4xl font-bold text-gray-400 items-center text-center justify-center'> {profileData?.name?.slice(0,1)}{profileData?.surname?.slice(0,1)} </span>
                        } 
                      </div>

                      <div className='flex flex-col items-start justify-center text-left space-y-2'>
                        <div className='flex flex-col items-start justify-center text-left'>
                          <span className='title' id="profile-title"> {profileData?.name + " " + profileData?.surname} </span>
                          <span className=''> {profileData?.role} </span>
                        </div>
                        {profileData?.emails?.length > 0 ||profileData?.phoneNumbers?.length > 0 ?
                          <div className='flex flex-row space-x-2 text-gray-600 text-xs'>
                            <Tooltip content={t('send-email')}>
                              {profileData?.emails?.length > 0 && <span className='hover:cursor-pointer hover:bg-green-200 hover:rounded-full p-1' ><a href={"mailto:"+ profileData?.emails}> ✉️ </a></span>}
                            </Tooltip>
                            {profileData?.phoneNumbers?.length > 0 && <span className='hover:cursor-pointer hover:bg-green-200 hover:rounded-full p-1'> <a href={"tel:"+ profileData?.phoneNumbers[0]}> 📱 </a></span>}
                          </div>
                        :
                          null
                        }
                      </div>
                  </div>

                  
                </div>



                {(profileData?.area ||  profileData?.subarea ||profileData?.title ||profileData?.level ||profileData?.directs > 0) &&
                <div className='flex flex-col text-sm text-gray-800 space-y-2 bg-white shadow-lg border rounded-lg p-8' id='profile-role-info'>
                  {/* Job information */}
                  <b className='mb-2'>{t('profile.job-information')} </b>
                  {profileData?.placeOfWork ||profileData?.Placeofwork ? 
                    <div className='flex flex-row space-x-2'>
                    <span>{t('profile.placeOfWork')}: </span>
                      <span> {profileData?.placeOfWork ? profileData?.placeOfWork :profileData?.Placeofwork} </span>
                    </div>
                    : null 
                  }
                  
                  {profileData?.area ? 
                      <div className='flex flex-row space-x-2'>
                        <span>{t('area')}: </span>
                        <span> {profileData?.area} </span>
                      </div>
                      : null 
                    }
                  {profileData?.subarea ? 
                      <div className='flex flex-row space-x-2'>
                      <span>{t('subarea')}: </span>
                        <span> {profileData?.subarea} </span>
                      </div>
                      : null 
                    }                  
                    {profileData?.title ? 
                      <div className='flex flex-row space-x-2'>
                      <span>{t('title')}: </span>
                        <span> {profileData?.title} </span>
                      </div>
                      : null 
                    }
                  {profileData?.level ? 
                      <div className='flex flex-row space-x-2'>
                      <span>{t('profile.level')}: </span>
                        <span> {profileData?.level} </span>
                      </div>
                      : null 
                    }

                  {profileData?.directs && profileData?.directs > 0 ? 
                    <div className='flex flex-row space-x-2'>
                    <span>{t('profile.directs')}: </span>
                      <span> {profileData?.directs} </span>
                    </div>
                    : null 
                  }

                  {profileData?.contractType ? 
                    <div className='flex flex-row space-x-2'>
                    <span>{t('profile.contractType')}: </span>
                      <span> {profileData?.contractType} </span>
                    </div>
                    : null 
                  }

                  
                </div>}

                {/* Manager */}
                { (manager && manager?.managerID !== null  )? 
                  <div className='flex flex-col text-sm text-gray-800 space-y-2 bg-white shadow-lg border border-gray-100 rounded-lg p-8' id='profile-manager-info'>
                    <b className='mb-2'>{t('manager')}</b>
                    <button onClick={() => navigateToManagerProfile()}>
                      <UserListItem user={manager?.name + " " + manager?.surname} initials={manager?.name?.slice(0,1)+manager?.surname?.slice(0,1)} 
                      role={manager?.role} level={manager?.level}
                      area={manager?.area} subarea={manager?.subarea}
                      profilePicture={manager?.profilePicture ? manager?.profilePicture : null} />
                    </button>
                  </div>
                :
                  null
                }




                {/* Personal information - Only visible for Employee, Manager, or HR */}
                {(profileData?.dateOfBirth ||profileData?.countryOfBirth ||profileData?.legalGender ||profileData?.maritalStatus) &&
                  (profileData?.employeeID === employeeID || (securityLevel?.includes(Configs.securityLevelAdmin) || securityLevel?.includes(Configs.securityLevelHR))) &&
                  <div className='flex flex-col text-sm text-gray-800 space-y-2 bg-white shadow-lg border rounded-lg  p-8' id='profile-personal-info'>
                    <b className='mb-2'>{t('profile.personal-info.home.title')}</b>
                  {profileData?.dateOfBirth ?

                    <div className='flex flex-row space-x-2'>
                      <span>{t('profile.dateOfBirth')}:</span>
                      <span>{new Date(profileData?.dateOfBirth).toDateString()} </span>
                    </div>
                  : null }

                  {profileData?.countryOfBirth ?
                    <div className='flex flex-row space-x-2'>
                      <span>{t('profile.countryOfBirth')}: </span>
                      <span> {profileData?.countryOfBirth} </span>
                  </div>
                  : null }

                  {profileData?.legalGender ?
                    <div className='flex flex-row space-x-2'>
                    <span>{t('profile.legalGender')}: </span>
                    <span> {profileData?.legalGender} </span>
                  </div>
                  : null }
                  
                  {profileData?.maritalStatus ?
                    <div className='flex flex-row space-x-2'>
                    <span>{t('profile.maritalStatus')}: </span>
                    <span> {profileData?.maritalStatus} </span>
                  </div>
                : null }

              </div>}

              {/* Contact information - Only visible for Employee, Manager, or HR */}
              {profileData?.phoneNumbers ?
              <div className="flex flex-col m-4">
                <b>Phone number:</b>
                <table>
                    <thead>
                      <tr className="bg-black	text-white">
                        <th className="border border-white p-2">Type </th>
                        <th className="border border-white p-2">Number </th>
                      </tr>
                    </thead>
                    <tbody>
                      {profileData?.phoneNumbers?.map((element, index) => (
                        <tr key={index}>
                          <td className="border border-black p-2 text-sm">
                            {" "}
                            {element.type}{" "}
                          </td>
                          <td className="border border-black p-2 text-sm">
                            {" "}
                            {element.number}{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                </table>
              </div>
              : null }

              {profileData?.emergencyContacts ?
              <div className="flex flex-col m-4">
                <b>Emergency contacts:</b>
                <table>
                    <thead>
                      <tr className="bg-black	text-white">
                        <th className="border border-white p-2">Type </th>
                        <th className="border border-white p-2">Name </th>
                        <th className="border border-white p-2">Number </th>
                      </tr>
                    </thead>
                    <tbody>
                      {profileData?.emergencyContacts?.map((element, index) => (
                        <tr key={index}>
                          <td className="border border-black p-2 text-sm">
                            {" "}
                            {element.type}{" "}
                          </td>
                          <td className="border border-black p-2 text-sm">
                            {" "}
                            {element.name}{" "}
                          </td>
                          <td className="border border-black p-2 text-sm">
                            {" "}
                            {element.number}{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                </table>
              </div>
              : null }
              </div>
            :
              <div className='flex flex-col items-center justify-center text-center'>
                <span className='title-description italic text-gray-400 m-8'>
                  🔎 {t('profile.not-found')}
                </span>

              </div>
            }
          </div>
        }
          <SectionSeparator />
        </div>
        <div className='right-small-view space-y-4'>
          {/* Performance - only for Self, Manager or HR */}
          {companyPreferences?.activeModules["Performance-Review"] === true && 
          (selectedProfile && (securityLevel?.includes(Configs.securityLevelManager) && profileData?.managerID === employeeID) 
          || securityLevel?.includes(Configs.securityLevelHR)
          || securityLevel?.includes(Configs.securityLevelAdmin)
          || securityLevel?.includes(Configs.securityLevelExec)
          ) ?
              <div className='flex flex-col items-start justify-center text-left space-y-2 bg-white shadow  rounded-lg border text-gray-800 text-sm'>
                <h4 className='card-title flex flex-row items-center py-4 px-8'> <CgPerformance className='mr-2 '/> 
                    {t('Performance-Review')}
                </h4>
                <div className='flex flex-col items-center justify-center text-left p-2 w-full h-full'>
                  {isPerformanceLoading ? 
                      <div className='pb-12'>
                        <Loader />
                      </div>  
                    :
                      performanceData ?
                      <SpiderPerformance 
                      performanceURL={null} 
                      performanceData={performanceData} />
                    :
                      <span className='title-description text-gray-400 italic mb-4 px-4'> {t('profile.performance.not-found')} </span>
                  }
                </div>
            </div>
            :
              null
            }

          {/* Recognition */}
          {recognitionsGiven > 0 || recognitionsReceived > 0 ?
              <div className='flex flex-col items-center justify-center text-left space-y-2 bg-white shadow py-4 px-8 rounded-lg border text-gray-800 text-sm'>
                <div className='flex flex-col items-start justify-center text-left space-y-2 w-full'>
                  <h4 className='card-title flex flex-row items-center'> 
                    <FaAward className='mr-2 '/> {t('Recognition')}
                  </h4>
                  <div className='flex flex-row justify-center items-center w-full space-x-8 text-xs'>
                    <Tooltip content={t('recognition.given')}>
                      <div className='flex flex-col items-center justify-center text-center'>
                      <span className='text-sm'>🥇📤 </span>
                        <span className='text-sm font-medium m-2'> {recognitionsGiven} </span>
                      </div>
                    </Tooltip>

                    <Tooltip content={t('recognition.received')}>
                      <div className='flex flex-col items-center justify-center text-center'>
                        <span className='text-sm'>🥇📥 </span>
                        <span className='text-sm font-medium m-2'>  {recognitionsReceived} </span>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            :
              null
            }
          </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default Profile;