// React
import React, { useState, useEffect} from 'react';
import {Link, useParams, useLocation, useNavigate} from "react-router-dom";

// Common & widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import PerformanceCampaignListItem from '../../widgets/performanceCampaignListItem';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// ---------------------------------------------------------------------------------------------------
function PerformanceCampaigns () {
    // Employee Info
    const {token, companyID, employeeID, securityLevel} = useAuth();
    const {toasterMessage, setToasterMessage} = useData();

    // To pass location to the Header
    const location = useLocation();
    const navigate = useNavigate();
    const [currentScreen, setCurrentScreen] = useState(location.pathname);

    // View specific variables
    const [performanceReviewCampaign, setPerformanceReviewCampaign] = useState(sessionStorage.getItem('performanceCampaigns') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('performanceCampaigns'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null);

    // Toaster & Loader
    const [isLoading, setIsLoading] = useState(false);

    if (Configs.devEnvironment) console.log ("DEV ONLY log - Campaigns: ", performanceReviewCampaign);


    // ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        getLiveCampaigns();
      }, []);

    
  // ---------------------------------------------------------------------------------------------------
  // API calls methods
  // ---------------------------------------------------------------------------------------------------

  function getLiveCampaigns () {
      // fetch from server      
      fetch(Configs.platformGetLivePerformanceCampaignsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        }})
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
              // Username and password mathched in the database - save Live campaigns
              response.json()
              .then (body => {
                  console.log(body)
                  if (body.length > 0) {
                    setPerformanceReviewCampaign(body);
                  } else {

                  }
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 403) {
                  sessionStorage.removeItem('performanceCampaigns');
                  sessionStorage.setItem('livePerformanceCampaign', CryptoJS.AES.encrypt(false,Configs.privateKey).toString());

            } else {
              // There is an error - delete info
              sessionStorage.removeItem('performanceCampaigns');
              sessionStorage.setItem('livePerformanceCampaign', CryptoJS.AES.encrypt("false",Configs.privateKey).toString());

              throw response;
            }
          })
    
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          });
    }

  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
  };
 
  // ---------------------------------------------------------------------------------------------------
  // Render methods
  // ---------------------------------------------------------------------------------------------------
    return (
      
      <React.Fragment>
      <Header/>
      <Bot />

      <div className="main">
    

          <PerformanceHeader livePerformanceCampaign={false} currentScreen={currentScreen} />

          <div className='flex flex-row items-start'>

            <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

              <div className="flex flex-col text-left lg:m-2 m-1 space-y-8 justify-between w-full">
                  {/* Title and description */}
                  <div className='flex flex-col space-y-2'>
                      <div className='flex flex-col text-left space-y-2 items-start'>
                        <Tooltip content={t('performance.hr.manage-campaigns.description')} >
                        <h2 className="section-title">{t('performance.hr.manage-campaigns')}</h2>
                        </Tooltip>

                      </div>
                      <div className='flex flex-row items-center justify-end mx-4'>
                        <Link to="/performance/campaigns/create">
                          <button className='save-button'> 
                            {t('performance.hr.manage-campaigns.create')} 
                          </button>
                        </Link>
                      </div>
                  </div>


                  {/* Campaigns list */}
                <div className='flex flex-col space-y-4'>
                  {performanceReviewCampaign?.map((campaign,index) => (
                      <div className='bg-white shadow rounded-lg p-4 border'>
                          <PerformanceCampaignListItem campaign={campaign} index={index} editable={false} collapsed={true}/>
                      </div>
                  ))}

                </div>
              </div>


              <SectionSeparator />
            </div>
            <div className='right-small-view'>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default PerformanceCampaigns;

