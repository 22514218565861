// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';
// Icons
import { BsLadder } from 'react-icons/bs';

// ----------------------------------------------------
function CareerHeader () {
    
    const screens = [
        {view:t('career.header.my-path'), url:"/career/", securityLevel:[Configs.securityLevelIC], id:"career.header.my-path"},
        {view:t('career.header.my-team'), url:"/career/team", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR, Configs.securityLevelExec], id:"career.header.my-team"},
        {view:t('career.header.HR'), url:"/career/hr", securityLevel:[Configs.securityLevelHR, Configs.securityLevelExec], id:"career.header.hr"},
        // {view:t('career.header.config'), url:"/career/control", securityLevel:Configs.securityLevelManager, id:"career.header.configs"},
    ];

    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('career')} Icon={BsLadder} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default CareerHeader;