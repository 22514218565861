// React
import React, { useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// common
import Configs from '../../Configs';
import HeaderEnd from '../../common/support/headerEnd';

import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Icons
import {FaPeopleArrows} from 'react-icons/fa';

// --------------------------------------------
function OneOnOneHeader () {
    const {t, i18n} = useTranslation();

    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    const {securityLevel} = useAuth();
    const {companyOrg, setSelectedProfile, companyPreferences, profile} = useData();

    const location = useLocation();
    const [currentScreen, setCurrentScreen] = useState(location.pathname);
    const navigate = useNavigate();

    const employeeInfo = sessionStorage.getItem('employeeInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('employeeInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    
    const [headerColor, setHeaderColor] = useState(companyPreferences?.personalization?.color ? companyPreferences?.personalization?.color.toString() : Configs.headerColor)
    const [secondaryColor, setSecondaryColor] = useState(companyPreferences?.personalization?.secondaryColor ? companyPreferences?.personalization?.secondaryColor.toString() : Configs.secondaryColor)

    // if (Configs.devEnvironment) console.log ("DEV ONLY log - oneOnOne Header:", getCurrentPathWithoutLastPart(currentScreen), getCurrentPathWithoutLastPart(screens[1].url));

    const screens = [
        {view:t('one-on-one.my-team'), url:"/oneonone/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR, Configs.securityLevelExec], id:"one-on-one.header.my-team"},
        {view:t('one-on-one.mine'), url:"/oneonone/space/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR, Configs.securityLevelExec], id:"one-on-one.header.mine"},
         {view:t('oneOnOne.hr'), url:"/oneonone/hr/", securityLevel:[Configs.securityLevelHR, Configs.securityLevelExec], id:"one-on-one.header.hr"},
    ];

 
    function clickedButton (e) {
        e.preventDefault();
        navigate(e.target.pathname);
    }

    function getCurrentPathWithoutLastPart (string) {
        const pathRgx = /\//g;
        const childroutecount = ((string || '').match(pathRgx) || []).length
        return childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string;
    }

    
    // --------------------------------------------
    // Go to profile
    // --------------------------------------------
    async function navigateTo (elementURL) {
        if (elementURL === "/oneonone/space/") {
            setSelectedProfile(null);
        }          
        navigate(elementURL);
      }

    // --------------------------------------------
    // Return

        return (
            <React.Fragment>
        <div className='flex flex-col px-8 pt-20 lg:pt-2 justify-between items-center bg-white shadow space-y-4 '>
                        {/* Top header */}
                        <div className='flex flex-row w-full items-center lg:pt-2  text-left justify-between '> 
                            <h1 className='flex flex-row items-center text-left text-sm font-medium '> 
                                <FaPeopleArrows className='flex mr-4 text-xl'/>
                                {t('one-on-one')}
                            </h1>

                            <HeaderEnd />                    
                        </div>
                       
            
                        <div className='flex flex-row w-full text-sm font-light items-start lg:justify-start justify-center space-x-4 overflow-x-auto'>    
                            {screens.map((element, index) => (
                                <React.Fragment>
                                    
                                {// Check if security level array contains any of the elements in element.securityLevel array
                                securityLevel?.some(r=> element?.securityLevel?.includes(r)) ?
                                    <button id={element.id} key={index} onClick= {() => navigateTo(element?.url)}>
                                            {(params && params.managerID === employeeInfo.employeeID) ?
                                                <div className={' py-2 flex px-2  ' + (
                                                    ((getCurrentPathWithoutLastPart(element.view) === "/oneonone/space/")) ? 
                                                        ' border-b border-b-green-200 border-b-4 '
                                                        : ' hover:bg-green-200 hover:rounded '
                                                        )}>

                                                        {element.view}
                                                </div>
                                        :
                                                <div className={' py-2 flex px-2  ' + (
                                                    (getCurrentPathWithoutLastPart(currentScreen) === getCurrentPathWithoutLastPart(element.url)) ? 
                                                        ' border-b border-b-green-200 border-b-4 '
                                                        : ' hover:bg-green-200 hover:rounded '
                                                        )}>

                                                        {element.view}
                                                </div>

                                        }
                                    </button>

                                    :
                                    null}
                                </React.Fragment>
                            ))
                        }
                        </div>
                    </div>
                    
                    {/* <Breadcrumbs /> */}
                   
            </React.Fragment>
        );
    

}

export default OneOnOneHeader;