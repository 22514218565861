import React from 'react';

// Configs and common
import Configs from '../../Configs';
import Modal from '../../common/support/modal';


// Dropdowns

// I18N
import { useTranslation } from 'react-i18next';

// Icons
import { GiPalmTree } from 'react-icons/gi';

// --------------------------------------------------
// Modal
function RequestModalView({
    isOpen,
    request,
    onClose,
    confirmDelete,
}) {
    // i18n
    const { t } = useTranslation();
    
    const modalTransitionStyles = {
        transitionProperty: 'opacity, transform', // Specify which properties to transition
        transitionDuration: '150ms', // Duration of the transition
        transitionTimingFunction: 'ease-out', // Timing function for the transition
      };

    if (Configs.devEnvironment) console.log('[requestModalView.js][main()] - request:', request);

// --------------------------------------------------
// Functions
// --------------------------------------------------
// Function to close modal
const closeModal = () => {
    onClose();
  };
  
  // Function to stop propagation for inner modal content clicks
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  

// --------------------------------------------------
// Render
// --------------------------------------------------
    if (!isOpen) return null;

    return (
        
    // <div className={`fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50 backdrop-blur-sm ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity ease-linear duration-300`}
    // onClick={closeModal}>

    //     <div className={`bg-white relative overflow-y-auto transition-transform ease-out duration-300 transform ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`} 
    //     style={modalTransitionStyles}
    //     onClick={stopPropagation} // Prevent clicks within the modal from closing it
    //     >
            <Modal isOpen={isOpen} onClose={closeModal}>

                {/* Header */}
                <div className='flex flex-col'>
                    <div className="flex flex-row justify-between bg-gray-100 p-6 items-center">
                            <h2 className="flex text-2xl font-bold items-center">
                            <GiPalmTree className='mr-4' /> {t('time-off.home.delete-request')}
                            </h2>
                        <button onClick={onClose} className="text-gray-500 font-medium hover:text-gray-700 hover:font-bold text-lg lg:text-xl">
                            &#x2715;
                        </button>
                    </div>
                    <div className='flex flex-row'>
                        <span className='title-description m-4 mt-8 ml-8'>
                             {t('time-off.home.delete-request.description')}
                        </span>
                    </div>
                </div>

                {/* Time off request information */}
                <div className="mx-4 lg:mx-12 my-8 text-sm">
                    {/* Header */}
                    <div className="grid grid-cols-4 gap-6 font-bold p-4 bg-green-200 text-black rounded-t-lg">
                        <div>{t('type')}</div>
                        <div>{t('start-date')}</div>
                        <div>{t('end-date')}</div>
                        <div>{t('status')}</div>
                    </div>
                    {/* Body */}
                    <div className="bg-white shadow-md rounded-b-lg">
                        {request?.request?.map((subRequest, index) => (
                            <div key={index} className="grid grid-cols-4 gap-4 p-4 items-center">
                            <div>{t(subRequest.leaveType)}</div>
                            <div>{new Date(subRequest.startDate).toLocaleDateString()}</div>
                            <div>{new Date(subRequest.endDate).toLocaleDateString()}</div>
                            <div>{t(request.status)}</div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Footer */}
                <div className='flex flex-col'>
                    <div className="flex flex-row justify-end p-6 items-center">
                        <button type="button" id="cancel" className="secondary-button" onClick={onClose}> {t('cancel')} </button>
                        <button id="delete" className='save-button' onClick={confirmDelete}>{t('time-off.home.delete-request.button')}</button>                        
                    </div>
                </div>
            </Modal>

        //     </div>
        // </div>
    );
}

export default RequestModalView;