// React
import React, {useEffect, useState} from 'react';
import { useNavigate, useSearchParams} from 'react-router-dom';

// 3P
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CryptoJS from 'crypto-js';

// Common
import Configs from '../../Configs';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import LoadingModal from '../../common/support/loadingModal';

// ---------------------------------------------------------------------------------------------------
function PulseDaily () {
    const [t] = useTranslation();
    const navigate = useNavigate();
  
    // Get params from URL
    const [searchParams] = useSearchParams();

    // const params = (encryptedParams && encryptedParams !== undefined) ? decryptQueryString(encryptedParams) : null;
    const { token, companyID, employeeID } = useAuth();
    const questionDate = searchParams?.get("questionDate") || new Date();
    questionDate.setHours(0, 0, 0, 0);
  
    // Toaster & loader
    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const {toasterMessage, setToasterMessage, logoPublicURL} = useData();

    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Campaign object
    const [campaign, setCampaign] = useState(null);
    const [question, setQuestion] = useState(null);
    // Answers
    const [userAnswered, setUserAnswered] = useState(false);
    // const [answer, setAnswer] = useState(null);

    if (Configs.devEnvironment) console.log("[pulseDaily.js][main()] question: ", question, searchParams);

// ---------------------------------------------------------------------------------------------------
// Data validation methods
// ---------------------------------------------------------------------------------------------------    
useEffect (() => { 
    // Request to backend the Daily Question to be shown
    if (campaign === null && !isLoading) requestDailyQuestion();
    
    }, []);

// ---------------------------------------------------------------------------------------------------
// API calls 
// ---------------------------------------------------------------------------------------------------        
     // Request survey to show
     async function requestDailyQuestion() {
      // Set loading
      setIsLoading(true);
        
      if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()] - Requesting survey");

      // Create date object
      let date = new Date();
      date.setHours(0, 0, 0, 0);

      try {
        const response = await apiCalls.getPulseDailyQuestion(companyID, employeeID, token, date?.toISOString());

        // Set loading
        setIsLoading(false);

        if (response.status === 500) {
          throw response;
        } else if (response.status === 422) {
          throw response;
        } else if (response.status === 403) {
          throw response;
        } else if (response.status === 401) {
          throw response;
        } else if (response.status === 404) {
          throw response;
        } else {
          console.log("[pulseDaily.js][requestSurvey()] body: ", response);

          if (response?.nextQuestion !== null && response?.nextQuestion !== undefined) {
            // If answer, set up body of survey
            setCampaign(response);
            setQuestion(response?.nextQuestion);

            // Review if it was already answered
            if (response?.answer?.length > 0 ) {
              setUserAnswered(true);

              // Send info to Electron
              if (window.electron) {
                console.log ("[pulseDaily.js][submitAnswer()]  User has already answered");
                window.electron.sendUserAlreadyAnswered();
              } else {
                if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()]  Electron not available");
                // Inform user about existing answer
                setToasterMessage({message: t('pulse.survey.answered'), type: Configs.successToaster});
                
                // redirect to home
                navigate('/');
              }
            } else {
              if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()]  User has not answered");
              // User has not answered
              setUserAnswered(false);

              // Send info to Electron
              if (window.electron) {
                if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()]  Sending to electron: User has not answered");
                console.log ("[pulseDaily.js][submitAnswer()]  Sending to electron: User has not answered");
                window.electron.sendUserHasNotAnswered();
              }
            }
          } else {
            throw response;
          }
        }

      } catch (error) {
        console.error("[pulseDaily.js][requestSurvey()] Error fetching data: ", error);
        setCampaign([null]);
        setUserAnswered(false);
        setIsLoading(false);

        // Inform Electron
        if (window.electron) {
          window?.electron?.sendNoQuestionAvailable();
          console.log ("[pulseDaily.js][requestSurvey()]  No question available");
        }

        // Navigate home
        navigate('/');
      }      
    }

    // // Submit answers of survey
    async function submitAnswer (answer) {
        if (Configs.devEnvironment) console.log("[pulseDaily.js][submitAnswer()]  Submitting answers");

        // Create body object:
        const bodyObject = {
          companyID: companyID,
          employeeID: employeeID,
          campaignID: campaign?.campaignID,
          questionID: question._id,
          questionDate: questionDate?.toISOString(),  
          answerId: answer?.toString()
        }

        // fetch from server      
        fetch(Configs.pulseAnswerAuthenticatedAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`, // notice the Bearer before your token
            },
            body: JSON.stringify(bodyObject)
          })
              .then((response) => {
                // console.log (response);
                if (response.status === 200) {
                  // All answers were successfully submitted
                  // Send info to Electron
                  if (window.electron) {
                    setSuccessMessage({message: t('pulse.survey.success'), type: Configs.successToaster});

                    console.log ("[pulseDaily.js][submitAnswer()]  User has successfully answered");
                    window?.electron?.sendUserSuccessfullyReplied();
                  } else {
                    if (Configs.devEnvironment) console.log("[pulseDaily.js][submitAnswer()]  Electron not available");
                    // Inform user about successful answer
                    // setSuccessMessage({message: t('pulse.survey.success'), type: Configs.successToaster});
                    setToasterMessage({message: t('pulse.survey.success'), type: Configs.successToaster});

                    // redirect to home
                    navigate('/');
                  }

                  // Confirm that user has answered
                  setUserAnswered(true);

                } else {
                  //
                   // Send info to Electron
                   if (window.electron) {
                    window?.electron?.sendUserHasNotAnswered();
                    console.log ("[pulseDaily.js][submitAnswer()]  User has not answered");
                  } 
                  setUserAnswered(false);

                  setToasterMessage({message: t('pulse.survey.error'), type: Configs.errorToaster});
                  throw response;
                }
              })
        
              .catch((error) => {
                console.error("[pulseDaily.js][submitAnswer()] Error fetching data: ", error);
              });
      
    }

// ---------------------------------------------------------------------------------------------------
// Fronted methods
// ---------------------------------------------------------------------------------------------------
async function answerClicked (e, answer) {
  if (Configs.devEnvironment) console.log("[pulseDaily.js][answerClicked()]  Answer clicked: ", answer);

    // Prevent default behaviour
    e.preventDefault();

    // API call to submit answers
    submitAnswer(answer);
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
    };

    const closeSuccess = () => {
      setSuccessMessage(null);
    };

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------    
if (userAnswered) {
  return (
          <React.Fragment>
              <div className="flex flex-col justify-center bg-gradient-to-b from-danone to-white p-4 lg:p-20 h-screen">

                  <div className="flex flex-col justify-center w-full bg-white shadow-2xl rounded border border-gray-100">
                  
                      <div className="flex flex-col lg:flex-row justify-center items-center">

                          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                          {successMessage && <LoadingModal timeout={Configs.toasterTimeout} onClose={closeSuccess} message={successMessage}/>}

                          <div className="flex container w-[100px] h-[100px] lg:w-auto lg:h-[100px]">
                          <img
                              className=" object-center object-contain"
                              alt={companyInfo?.companyName + " logo"} 
                              src={logoPublicURL}
                          />
                          </div>
                      </div>

                      <div className='flex flex-col text-center items-center'>
                          {/* Question */}
                          <div className='flex flex-col text-center p-10'>
                              {i18next.language === 'es' ? 
                                <h1 className='text-xl font-bold '>{question?.Question_ESP ? question?.Question_ESP : question?.Question}</h1>
                              : 
                                <h1 className='text-xl font-bold '>{question?.Question ? question?.Question : question?.Question_ESP}</h1>
                              }  
                          </div>
                          
                          {/* Answers */}
                          <div className='flex flex-col space-y-4 w-1/2'>
                              {/* {campaign?.dailyQuestion?.answers?.map((answers, index) => ( */}
                              <span className='title-description italic'>
                                {t('pulse.survey.answered')}
                              </span>

                          </div>
                      </div>

                      <SectionSeparator />
                  
                  </div>
              </div>
          </React.Fragment>
      );

} else {
  return (
          <React.Fragment>
              <div className="flex flex-col justify-center bg-gradient-to-b from-danone to-white p-8 lg:p-20 h-screen ">

              {isLoading ? 
                  <Loader />
              :

                  <div className="flex flex-col justify-center w-full bg-white shadow-2xl rounded border border-gray-100">
                  
                      <div className="flex flex-col lg:flex-row justify-center items-center">

                          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                          {successMessage && <LoadingModal timeout={Configs.toasterTimeout} onClose={closeToast} message={successMessage}/>}

                          <div className="flex container w-[100px] h-[100px] lg:w-auto lg:h-[100px] py-4">
                          <img
                              className=" object-center object-contain"
                              alt={companyInfo?.companyName + " logo"} 
                              src={logoPublicURL}
                          />
                          </div>
                      </div>

                      <div className='flex flex-col text-center items-center'>
                          {/* Question */}
                          <div className='flex flex-col text-center lg:p-10 p-4'>
                              {i18next.language === 'es' ? 
                                <h1 className='section-title'>{question?.Question_ESP ? question?.Question_ESP : question?.Question}</h1>
                              : 
                                <h1 className='section-title'>{question?.Question ? question?.Question : question?.Question_ESP}</h1>
                              }  
                              {/* Anonymous */}
                              <div className="flex flex-row justify-center text-xs text-center italic text-gray-600 my-4 lg:mx-4">
                                  {t('pulse.survey.anonymous')}
                              </div>
                          </div>
                          
                          {/* Answers */}
                          <div className='flex flex-col space-y-4 w-full p-4 lg:w-1/2'>
                              {question?.Resp1_String?.toString()?.length > 0 || question?.Resp1_String_ESP?.toString()?.length > 0 ? 
                                  <button className='mt-2 border border-gray-300 rounded p-4 hover:bg-gray-200 shadow' onClick={(e) => {answerClicked(e,5)}}>
                                    {i18next.language === 'es' ? 
                                      question?.Resp1_String_ESP ? question?.Resp1_String_ESP : question?.Resp1_String
                                      :
                                      question?.Resp1_String ? question?.Resp1_String : question?.Resp1_String_ESP
                                    }
                                  </button>
                                : null}

                                {question?.Resp2_String?.toString()?.length > 0 || question?.Resp2_String_ESP?.toString()?.length > 0 ?
                                  <button className='mt-2 border border-gray-300 rounded p-4 hover:bg-gray-200 shadow' onClick={(e) => {answerClicked(e,4)}}>
                                      {i18next.language === 'es' ? 
                                        question?.Resp2_String_ESP ? question?.Resp2_String_ESP : question?.Resp2_String
                                        :
                                        question?.Resp2_String ? question?.Resp2_String : question?.Resp2_String_ESP
                                      }
                                  </button>
                                : null}

                                {question?.Resp3_String?.toString()?.length > 0 || question?.Resp3_String_ESP?.toString()?.length > 0 ?
                                  <button className='mt-2 border border-gray-300 rounded p-4 hover:bg-gray-200 shadow' onClick={(e) => {answerClicked(e,3)}}>
                                      {i18next.language === 'es' ?
                                        question?.Resp3_String_ESP ? question?.Resp3_String_ESP : question?.Resp3_String
                                        :
                                        question?.Resp3_String ? question?.Resp3_String : question?.Resp3_String_ESP
                                      }
                                  </button>
                                : null}

                                {question?.Resp4_String?.toString()?.length > 0 || question?.Resp4_String_ESP?.toString()?.length > 0 ?
                                  <button className='mt-2 border border-gray-300 rounded p-4 hover:bg-gray-200 shadow' onClick={(e) => {answerClicked(e,2)}}>
                                    {i18next.language === 'es' ?
                                        question?.Resp4_String_ESP ? question?.Resp4_String_ESP : question?.Resp4_String
                                        :
                                        question?.Resp4_String ? question?.Resp4_String : question?.Resp4_String_ESP
                                      }
                                  </button>
                                :null }

                                {question?.Resp5_String?.toString()?.length > 0 || question?.Resp5_String_ESP?.toString()?.length > 0 ?
                                  <button className='mt-2 border border-gray-300 rounded p-4 hover:bg-gray-200 shadow' onClick={(e) => {answerClicked(e,1)}}>
                                    {i18next.language === 'es' ?
                                        question?.Resp5_String_ESP ? question?.Resp5_String_ESP : question?.Resp5_String
                                        :
                                        question?.Resp5_String ? question?.Resp5_String : question?.Resp5_String_ESP
                                      }
                                  </button>
                                :null}

                                {/* RNA */}
                                {question?.Rather_Not_Answer === 0 || question?.Rather_Not_Answer === null ?
                                    null
                                  :
                                    <button className='mt-2 rounded p-4 hover:underline hover:font-bold text-gray-600' onClick={(e) => {answerClicked(e,"rna")}}>
                                      {t('pulse.survey.rna')}
                                    </button>
                                }
                          </div>
                      </div>
                      <SectionSeparator />
                  </div>
              }
              </div>
          </React.Fragment>
      );
    }
}

export default PulseDaily;