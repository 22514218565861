// React
import React, {useState, useEffect} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common and Widgets
import Configs from '../Configs';
import ValuesScoring from '../widgets/valuesScoring';
import ValuesSelector from '../widgets/valuesSelector';
import ValuesScoringWithComments from '../widgets/valuesScoringWithComment';
import { useAuth } from '.././common/appContext';

// Icons
import {BsToggleOn, BsToggleOff} from 'react-icons/bs';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import {ImBin} from 'react-icons/im';
import {BiCommentAdd} from 'react-icons/bi';




/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function PerformanceCampaignListItem(props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

// Employee Info
const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
// const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
const {token} = useAuth();

// Props
const campaignID = props.campaign?._id;
const name = props.campaign?.name;
const description = props.campaign?.description;
const startDate = props.campaign?.startDate;
const endDate = props.campaign?.endDate;
const [campaign, setCampaign] = useState(props?.campaign ? props?.campaign : null);
const feedbackType = props.campaign?.feedbackType;
// Editing or creating a new campaign
const editable = props?.editable;

const [valuesSelectorCollapsed, setValuesSelectorCollapsed] = useState(props?.collapsed ? props?.collapsed : false);
const [feedbackTypeCollapsed, setFeedbackTypeCollapsed] = useState(props?.collapsed ? props?.collapsed : false);
const [questionnaireCollapsed, setQuestionnaireCollapsed] = useState(props?.collapsed ? props?.collapsed : false);
const [managerQuestionnaireCollapsed, setManagerQuestionnaireCollapsed] = useState(props?.collapsed ? props?.collapsed : false);
const [feedbackQuestionnaireCollapsed, setFeedbackQuestionnaireCollapsed] = useState(props?.collapsed ? props?.collapsed : false);

// Campaign information
const [campaignName, setCampaignName] = useState(null);
const [campaignDescription, setCampaignDescription] = useState(null);
const [campaignStartDate, setCampaignStartDate] = useState(null);
const [campaignEndDate, setCampaignEndDate] = useState(null);
const [campaignRecurrence, setCampaignRecurrence] = useState(null);
const [campaignTarget, setCampaignTarget] = useState(null);

// Values selector
const [valuesSelectorType, setValuesSelectorType] = useState(null);

// Questionnaires
const [questionnaire, setQuestionnaire] = useState(null);
const [managerQuestionnaire, setManagerQuestionnaire] = useState(null);
const [feedbackQuestionnaire, setFeedbackQuestionnaire] = useState(null);
const [peersQuestionnaire, setPeersQuestionnaire] = useState(null);
const [externalQuestionnaire, setExternalQuestionnaire] = useState(null);

// Example information
const exampleValues = [
    {value: "Value 1", description:"This is the example of description of a value"},
    {value: "Value 2", description:"This is the example of description of a value"},

];

// States variables for feedback type
const [selfAssessment, setSelfAssessment] = useState(false);
const [managerEmployeeAssessment, setManagerEmployeeAssessment] = useState(false);
const [employeeManagerAssessment, setEmployeeManagerAssessment] = useState(false);
const [peersAssessment, setPeersAssessment] = useState(false);
const [externalAssessment, setExternalAssessment] = useState(false);

if (Configs.devEnvironment) console.log ("[PerformanceCampaignListItem.js][main()]: campaign: ", campaign);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        // calculateResult(selectedValues);
        if (!campaign) populateTemplate();
}, []);

// ------------------------------------------------------------------------------------------
// Frontend management methods
// ------------------------------------------------------------------------------------------
async function populateTemplate() {
    var auxCampaign = {
        "name": "Evaluación de Desempeño 2023 - TaxDown",
        "companyID": 2,
        "description": "Campaña 2023 de TaxDown",
        "startDate": {
          "$date": "2023-06-06T00:00:00.000Z"
        },
        "endDate": {
          "$date": "2023-06-06T00:00:00.000Z"
        },
        "recurrence": "yearly",
        "target": {
          "type": "team"
        },
        "questionnaire": [
          {
            "type": "strength",
            "description": "Evalúate basándote en situaciones y proyectos concretos.",
            "title": "Este año, ¿cómo has visto tu desempeño?"
          },
          {
            "type": "strength",
            "description": "Añade alguna cosa que te haya faltado.",
            "title": "Comentarios generales"
          }
        ],
        "feedbackQuestionnaire": [
          {
            "type": "strength",
            "description": "Piensa en situaciones concretas de proyectos o similar.",
            "title": "¿Cómo ha sido el desempeño de estx empleadx?"
          }
        ],
        "valuesSelectorType": "scoreWithComments",
        "feedbackType": {
          "self": true,
          "manager-employee": true,
          "employee-manager": true,
          "peers": false,
          "external": false
        },
        "managerQuestionnaire": [
          {
            "description": "Piensa en situaciones concretas y cómo ha desempeñado.",
            "title": "Este año, ¿cómo ha sido el desempeño del empleadx?",
            "type": "strength"
          },
          {
            "type": "strength",
            "description": "Añade alguna cosa que haya faltado.",
            "title": "Comentarios generales"
          }
        ]
      }

      setCampaign(auxCampaign);
}

async function createCampaign(e) {
    e.preventDefault();

    if (campaignName && campaignDescription && campaignStartDate && campaignEndDate && valuesSelectorType &&
         questionnaire && feedbackQuestionnaire && managerQuestionnaire) {

            var auxCampaignObject = {
                        "name": campaignName,
                        "description": campaignDescription,
                        "startDate": campaignStartDate,
                        "endDate": campaignEndDate,
                        // "recurrence": "yearly",
                        // "target": {
                        // "type": "team"
                        // },
                        "questionnaire": questionnaire,
                        "feedbackQuestionnaire": feedbackQuestionnaire,
                        "valuesSelectorType": valuesSelectorType,
                        "feedbackType": {
                            "self": selfAssessment ? selfAssessment : false,
                            "manager-employee": managerEmployeeAssessment ? managerEmployeeAssessment : false,
                            "employee-manager": employeeManagerAssessment ? employeeManagerAssessment : false,
                            "peers": peersAssessment ? peersAssessment : false,
                            "external": externalAssessment ? externalAssessment : false,
                        },
                        "managerQuestionnaire": managerQuestionnaire
                    }

            console.log("[PerformanceCampaignListItem.js][createCampaign()]: auxCampaignObject: ", auxCampaignObject);

            setCampaign(auxCampaignObject);

    } else {
        alert(t('warning.empty-inputs'));
    }
}


// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-row m-1 p-4 justify-left items-center space-y-12'>


        <div className="flex flex-col text-left w-full">  
            {props?.campaign?.name ? 
                <span className="font-bold mb-2">{name}</span>
            :
                <React.Fragment>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-4">
                        <label for="username" class="block text-sm font-medium leading-6 text-gray-900">{t('name')}</label>
                        <div class="mt-2">
                            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 sm:max-w-md">
                            <input type="text" name="name" id="name"  onChange={(e) => setCampaignName(e.target.value)}
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                            placeholder={t('campaign-name')} />
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
            }

                
            {props?.campaign?.description ? 
                <span className="text-gray-600">{description ? description : null}</span>
            :
                <React.Fragment>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-4">
                        <label for="username" class="block text-sm font-medium leading-6 text-gray-900">{t('description')}</label>
                        <div class="mt-2">
                            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 sm:max-w-md">
                            <input type="text" name="description" id="description" onChange={e => setCampaignDescription(e.target.value)}
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                            placeholder={t('campaign-description')} />
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
            }
            <p></p>
            {props?.campaign?.startDate ? 
                <React.Fragment>
                    <span className="text-gray-600">{startDate ?  t('date')+": "+(new Date(startDate).getUTCDate()) + "/" + (new Date(startDate).getUTCMonth() + 1) +"/"+(new Date(startDate).getUTCFullYear()) : null} - {endDate ? (new Date(endDate).getUTCDate()) + "/" + (new Date(endDate).getUTCMonth() + 1) +"/"+(new Date(endDate).getUTCFullYear())  : null} </span>
                    
                </React.Fragment>
            :
                <React.Fragment>
    
                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="sm:col-span-3">
                        <label for="start-date" class="block text-sm font-medium leading-6 text-gray-900">{t('start-date')}</label>
                        <div class="mt-2">
                            <input type="date" name="start-date" id="start-date" onChange={e => setCampaignStartDate(e.target.value)}
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-200 sm:text-sm sm:leading-6" />
                        </div>
                        </div>

                        <div class="sm:col-span-3">
                        <label for="start-date" class="block text-sm font-medium leading-6 text-gray-900">{t('end-date')}</label>
                        <div class="mt-2">
                            <input type="date" name="end-date" id="end-date"  onChange={e => setCampaignEndDate(e.target.value)}
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-200 sm:text-sm sm:leading-6" />
                        </div>
                        </div>
                    </div>
                </React.Fragment>
            }

                {/** Show / Not show edit / delete butttons depending on where the widget is placed */}
                <div className={props.editable ? " hidden " : null }>
                    <Link to={"/performance/campaigns/edit/" +
                            encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                            campaignID: campaignID,
                            }),Configs.privateKey).toString())}>
                        <button className=" mr-2 text-xl">
                            <AiFillEdit />
                        </button>
                    </Link>

                        <button className=" text-xl">
                            <AiFillDelete />
                        </button>

                </div>

            {/** Values selector type */}              
                <div className="flex flex-col w-full mt-10" >
                    <button onClick={event => (setValuesSelectorCollapsed(!valuesSelectorCollapsed))}>
                        <div className="flex flex-row font-bold mb-2" >
                            {valuesSelectorCollapsed ?
                                    <React.Fragment>
                                        <div className="mr-2 ">
                                            <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                                                <path d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z" fill="currentColor" fill-rule="evenodd">
                                                </path>
                                            </svg>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="mr-2 ">
                                            <svg fill="none" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-up" class="inline h-1.5">
                                                <path d="M12.085 6.23a1.09 1.09 0 01-.004 1.477 1.093 1.093 0 01-1.605.005l-3.84-4.17a.544.544 0 00-.8 0l-3.84 4.17a1.093 1.093 0 01-1.604-.005A1.093 1.093 0 01.388 6.23L5.436.747a1.088 1.088 0 011.6 0l5.05 5.482z" fill="currentColor" fill-rule="evenodd">
                                                </path>
                                            </svg>
                                        </div>
                                    </React.Fragment>
                            }
                            <legend class="text-sm font-semibold leading-6 text-gray-900">{t('performance.hr.manage-campaigns.valuesSelector')}</legend>                            
                        </div>
                    </button>


                    {campaign?.valuesSelectorType ?

                        valuesSelectorCollapsed ? null :
                            
                            <React.Fragment>

                            <p class="mt-1 text-sm leading-6 text-gray-600"> {t('performance.hr.manage-campaigns.valuesSelector.description')} </p>

                            <fieldset>

                                <div class="mt-6 space-y-6">
                                    <div class="flex items-center gap-x-3 ">
                                        <input id="valuesSelector" name="valuesSelector" value="selector" type="radio" onChange={(e) => setValuesSelectorType(e.target.value)}
                                        disabled={props?.editable ? false : true}
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-green-200"/>
                                        <label for="valuesSelector" class="block text-sm font-medium leading-6 text-gray-900">{t('performance.hr.manage-campaigns.valuesSelector.valuesSelector')}</label>
                                    </div>
                                    <div class="flex items-center gap-x-3 ">
                                        <input id="valuesScoring" name="valuesSelector" value="score" type="radio" onChange={(e) => setValuesSelectorType(e.target.value)}
                                        disabled={props?.editable ? false : true}
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-green-200"/>
                                        <label for="valuesScoring" class="block text-sm font-medium leading-6 text-gray-900"> {t('performance.hr.manage-campaigns.valuesSelector.valuesScoring')} </label>
                                    </div>
                                    <div class="flex items-center gap-x-3 ">
                                        <input id="valuesScoringWithComments" name="valuesSelector" value="scoreWithComments" type="radio" onChange={(e) => setValuesSelectorType(e.target.value)}
                                        disabled={props?.editable ? false : true}                                        
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-green-200"/>
                                        <label for="valuesScoringWithComments" class="block text-sm font-medium leading-6 text-gray-900"> {t('performance.hr.manage-campaigns.valuesSelector.valuesScoringWithComments')} </label>
                                    </div>
                                </div>

                            </fieldset>

                            <div className="flex border border-gray-200 shadow p-4 m-4">
                            {valuesSelectorType === "score" ?
                                    <React.Fragment>
                                        <ValuesScoring readOnly={true} values={exampleValues}/>
                                    </React.Fragment>
                                :  valuesSelectorType === "selector" ?

                                <React.Fragment>
                                    <ValuesSelector readOnly={true} values={exampleValues}/>
                                </React.Fragment>
                                :  valuesSelectorType === "scoreWithComments" ?
                                    <React.Fragment>
                                        <ValuesScoringWithComments readOnly={true} values={exampleValues}/>
                                    </React.Fragment>
                                : null }
                                </div>

                            </React.Fragment>
                    : null 
                    } 
                </div>
                       
            

            {/** Feedback type */}
                <div className="flex flex-col w-full mt-10 " >
                    <button onClick={event => (setFeedbackTypeCollapsed(!feedbackTypeCollapsed))}>
                        <div className="flex flex-row items-center font-bold mb-2" >
                            {feedbackTypeCollapsed ?
                                <React.Fragment>
                                    <div className="mr-2 ">
                                        <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                                            <path d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z" fill="currentColor" fill-rule="evenodd">
                                            </path>
                                        </svg>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="mr-2 ">
                                        <svg fill="none" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-up" class="inline h-1.5">
                                            <path d="M12.085 6.23a1.09 1.09 0 01-.004 1.477 1.093 1.093 0 01-1.605.005l-3.84-4.17a.544.544 0 00-.8 0l-3.84 4.17a1.093 1.093 0 01-1.604-.005A1.093 1.093 0 01.388 6.23L5.436.747a1.088 1.088 0 011.6 0l5.05 5.482z" fill="currentColor" fill-rule="evenodd">
                                            </path>
                                        </svg>
                                    </div>
                                </React.Fragment>
                            }

                            <legend class="text-sm font-semibold leading-6 text-gray-900">{t('performance.hr.manage-campaigns.feedbackType')}</legend>                            

                        </div>
                    </button>


                    {props?.campaign?.feedbackType ? 
                        <React.Fragment>
                            <div className={"flex flex-row items-center p-2 justify-between hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 mt-6 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.self-assessment')} <BsToggleOn className="text-xl text-green-600" /></div>
                            <div className={"flex flex-row items-center p-2 justify-between hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.manager-employee-assessment')} {campaign.feedbackType === "180" ? <BsToggleOn className="text-xl  text-green-600" /> : <BsToggleOff className="text-xl"/>}</div>
                            <div className={"flex flex-row items-center p-2 justify-between hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.employee-manager-assessment')} {campaign.feedbackType === "180" ? <BsToggleOn className="text-xl  text-green-600"/> : <BsToggleOff className="text-xl"/>}</div>
                            <div className={"flex flex-row items-center p-2 justify-between hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.peer-assessment')} {campaign.feedbackType === "No" ? <BsToggleOff className="text-xl"/> : <BsToggleOn className="text-xl  text-green-600" />}</div>
                            <div className={"flex flex-row items-center p-2 justify-between hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.external-assessment')} {campaign.feedbackType === "No" ? <BsToggleOff className="text-xl"/> : null}</div>
                        </React.Fragment>               
                    :  
                        feedbackTypeCollapsed ? null :
                        <React.Fragment>
                            <p class="mt-1 text-sm leading-6 text-gray-600"> {t('performance.hr.manage-campaigns.feedbackType.description')} </p>

                            <div onClick={() => setSelfAssessment(!selfAssessment) } className={"flex flex-row items-center p-2 justify-between hover:cursor-pointer hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 mt-6 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.self-assessment')}{selfAssessment && campaign?.feedbackType?.self === true ? <BsToggleOn className="text-xl text-green-600" /> : <BsToggleOff className="text-xl"/> }</div>
                            
                            {/** Self assessment and manager-employee questionnaire */}
                                {!feedbackTypeCollapsed &&  (campaign?.questionnaire && selfAssessment) ?  
                                    <div className="flex flex-col w-full mt-4 lg:ml-8 lg:mr-8 mb-8" >

                                        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7 items-center ">
                                            <div class="sm:col-span-3">
                                                <label for="title" class="block text-sm font-medium leading-6 text-gray-900">{t('title')}</label>
                                            </div>
                                            <div class="sm:col-span-3">
                                                <label for="description" class="block text-sm font-medium leading-6 text-gray-900">{t('description')}</label>
                                            </div>
                                            <div class="sm:col-span-1">
                                                <label for="description" class="block text-sm font-medium leading-6 text-gray-900">{t('action')}</label>
                                            </div>
                                        </div>
                                        {questionnaireCollapsed ? null : 
                                            campaign?.questionnaire?.length > 0 ?
                                                // Map de questionnaire normally
                                                campaign?.questionnaire?.map ((question, index) => (
                                                    <div class="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7 items-center ">
                                                        <div class="sm:col-span-3">
                                                            <div class="mt-2">
                                                                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 sm:max-w-md">
                                                                <input type="text" name="title" id="title" index={0}   className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                placeholder={t('question-title')} value={campaign?.questionnaire[index].title}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="sm:col-span-3">
                                                            <div class="mt-2">
                                                                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 sm:max-w-md">
                                                                <input type="text" name="description" id="description" index={0} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                                placeholder={t('question-description')} value={campaign?.questionnaire[index].description}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="sm:col-span-1">
                                                            <div class="mt-2">
                                                                {!(index === campaign?.questionnaire?.length - 1) ? null :
                                                                    <button className="mr-2 text-xl">
                                                                        <BiCommentAdd />                                                               
                                                                    </button>
                                                                }
                                                                {(campaign?.questionnaire?.length === 1) ? null :
                                                                    <button className="ml-2 text-xl">
                                                                        <ImBin />
                                                                    </button> 
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                null     
                                            }
                                    </div>
                                : null }    

                            <div onClick={() => setManagerEmployeeAssessment(!managerEmployeeAssessment) } className={"flex flex-row items-center p-2 justify-between hover:cursor-pointer hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.manager-employee-assessment')} {managerEmployeeAssessment && campaign?.feedbackType["manager-employee"] === true ? <BsToggleOn className="text-xl text-green-600" /> : <BsToggleOff className="text-xl"/> }</div>

                            {/** Manager questionnaire */}
                            {!feedbackTypeCollapsed &&  (campaign?.managerQuestionnaire && managerEmployeeAssessment) ?  
                                    <div className="flex flex-col w-full mt-4 lg:ml-8 lg:mr-8 mb-8" >

                                        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7 items-center ">
                                            <div class="sm:col-span-3">
                                                <label for="title" class="block text-sm font-medium leading-6 text-gray-900">{t('title')}</label>
                                            </div>
                                            <div class="sm:col-span-3">
                                                <label for="description" class="block text-sm font-medium leading-6 text-gray-900">{t('description')}</label>
                                            </div>
                                            <div class="sm:col-span-1">
                                                <label for="description" class="block text-sm font-medium leading-6 text-gray-900">{t('action')}</label>
                                            </div>
                                        </div>
                                        {managerQuestionnaireCollapsed ? null : 
                                            campaign?.managerQuestionnaire?.length > 0 ?
                                                // Map de questionnaire normally
                                                campaign?.managerQuestionnaire?.map ((question, index) => (
                                                    <div class="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7 items-center ">
                                                        <div class="sm:col-span-3">
                                                            <div class="mt-2">
                                                                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 sm:max-w-md">
                                                                <input type="text" name="title" id="title" index={0}  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                placeholder={t('question-title')} value={campaign?.managerQuestionnaire[index].title}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="sm:col-span-3">
                                                            <div class="mt-2">
                                                                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 sm:max-w-md">
                                                                <input type="text" name="description" id="description" index={0}  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                                placeholder={t('question-description')} value={campaign?.managerQuestionnaire[index].description}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="sm:col-span-1">
                                                            <div class="mt-2">
                                                                {!(index === campaign?.managerQuestionnaire?.length - 1) ? null :
                                                                    <button className="mr-2 text-xl">
                                                                        <BiCommentAdd />                                                               
                                                                    </button>
                                                                }
                                                                {(campaign?.managerQuestionnaire?.length === 1) ? null :
                                                                    <button className="ml-2 text-xl">
                                                                        <ImBin />
                                                                    </button> 
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                null    
                                            }
                                    </div>
                                : null }    

                            <div onClick={() => setEmployeeManagerAssessment(!employeeManagerAssessment) } 
                            className={"flex flex-row items-center p-2 justify-between hover:cursor-pointer hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 " + (feedbackTypeCollapsed ? " hidden " : null)}>
                                {t('performance.hr.manage-campaigns.employee-manager-assessment')} {employeeManagerAssessment && campaign?.feedbackType["employee-manager"] === true ? 
                                    <BsToggleOn className="text-xl text-green-600" /> : <BsToggleOff className="text-xl"/> }
                                </div>
                                {/* Peer feedback questionnaire            */} 
                                {!feedbackTypeCollapsed &&  campaign?.feedbackQuestionnaire && employeeManagerAssessment ?  
                                    <div className="flex flex-col w-full mt-4 lg:ml-8 lg:mr-8 mb-8" >

                                        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7 items-center ">
                                            <div class="sm:col-span-3">
                                                <label for="title" class="block text-sm font-medium leading-6 text-gray-900">{t('title')}</label>
                                            </div>
                                            <div class="sm:col-span-3">
                                                <label for="description" class="block text-sm font-medium leading-6 text-gray-900">{t('description')}</label>
                                            </div>
                                            <div class="sm:col-span-1">
                                                <label for="description" class="block text-sm font-medium leading-6 text-gray-900">{t('action')}</label>
                                            </div>
                                        </div>
                                        {feedbackQuestionnaireCollapsed ? null : 
                                            campaign?.feedbackQuestionnaire?.length > 0 ?
                                                // Map de questionnaire normally
                                                campaign?.managerQuestionnaire?.map ((question, index) => (
                                                    <div class="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7 items-center ">
                                                        <div class="sm:col-span-3">
                                                            <div class="mt-2">
                                                                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 sm:max-w-md">
                                                                <input type="text" name="title" id="title" index={0}  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                placeholder={t('question-title')} value={campaign?.managerQuestionnaire[index].title}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="sm:col-span-3">
                                                            <div class="mt-2">
                                                                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-200 sm:max-w-md">
                                                                <input type="text" name="description" id="description" index={0} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" 
                                                                placeholder={t('question-description')} value={campaign?.managerQuestionnaire[index].description}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="sm:col-span-1">
                                                            <div class="mt-2">
                                                                {!(index === campaign?.managerQuestionnaire?.length - 1) ? null :
                                                                    <button className="mr-2 text-xl">
                                                                        <BiCommentAdd />                                                               
                                                                    </button>
                                                                }
                                                                {(campaign?.managerQuestionnaire?.length === 1) ? null :
                                                                    <button className="ml-2 text-xl">
                                                                        <ImBin />
                                                                    </button> 
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                null     
                                            }
                                    </div>
                                : null }    
                            
                            {/*    <div onClick={() => setPeersAssessment(!peersAssessment) } className={"flex flex-row items-center p-2 justify-between hover:cursor-pointer hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.peer-assessment')}{peersAssessment || campaign?.feedbackType?.peers === true ? <BsToggleOn className="text-xl text-green-600" /> : <BsToggleOff className="text-xl"/> }</div>
                             <div onClick={() => setExternalAssessment(!externalAssessment) } className={"flex flex-row items-center p-2 justify-between hover:cursor-pointer hover:bg-gray-100 rounded text-sm font-medium leading-6 text-gray-900 " + (feedbackTypeCollapsed ? " hidden " : null)}>{t('performance.hr.manage-campaigns.external-assessment')} {externalAssessment || campaign?.feedbackType?.external === true ? <BsToggleOn className="text-xl text-green-600" /> : <BsToggleOff className="text-xl"/> }</div> */}

                        </React.Fragment>
                    }
                    </div>



                    <div class="mt-6 flex items-center justify-end gap-x-6">

              <button type="button" class="text-sm font-semibold leading-6 text-gray-900" onClick={props?.cancel}> {t('cancel')} </button>

              {props.campaign ? 
                <button className='flex bg-green-200 rounded px-4 py-2 text-sm mb-2'> {t('performance.hr.manage-campaigns.edit')} </button>
                : 
                <button className='flex bg-green-200 rounded px-4 py-2 text-sm mb-2' onClick={createCampaign}> {t('performance.hr.manage-campaigns.create')} </button>
            }
                
            </div>
        </div>
    </div>

  );
}

export default PerformanceCampaignListItem;