// Reusable Modal Component
import React from 'react';

// --------------------------------------------------
const Modal = ({ 
    isOpen, 
    onClose, 
    children, 
 }) => {

// --------------------------------------------------
// Functions
// --------------------------------------------------
// Prevent event propagation to avoid the modal closing when its content is clicked
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

// --------------------------------------------------
// Render
// --------------------------------------------------
  return (
    <div
      className={`fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50 backdrop-blur-md ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity ease-linear duration-300`}
      onClick={onClose} // Close modal when overlay is clicked
    >
      <div
        className="bg-white lg:rounded-lg overflow-hidden shadow-xl transform transition-all w-full h-full lg:h-auto lg:w-auto "
        onClick={stopPropagation} // Prevent clicks inside the modal from closing it
      >
        <div className="">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;