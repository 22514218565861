import './App.css';

// React
import React, {useEffect, useState} from "react";
import {BrowserRouter} from 'react-router-dom';

// Common & Widgets
import Configs from './Configs';
import Routing from './common/routing';
import Login from './authentication/login';
import { useAuth } from './common/appContext';
import { useData } from './common/dataContext';
import * as apiCalls from './common/apiCalls';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Toaster
import Toaster from './common/support/toaster';
// import Loader from './common/support/loader';

// Azure integration
import { useMsal, useIsAuthenticated  } from "@azure/msal-react";
// import { msalConfig} from "./authConfig";

// Google integration
// import { GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import { GoogleOAuthProvider} from '@react-oauth/google';

// Views that can be accessed without authentication
import ResetPassword from './authentication/resetPassword';
import PulseSurvey from './views/survey/pulseSurvey';
import { HistoryProvider } from './common/historyContext';
import RecognitionPublicSingleRecognition from './views/recognition/recognitionPublicSingleRecognition';
// import PulseDaily from './views/survey/pulseDaily';


// ---------------------------------------------------------------------------------------------------------
function App() {
  // CONTEXTs
  // Employee info
  const {companyID, setCompanyID, employeeID, setEmployeeID, token, setToken} = useAuth();
  const {isAzureAuthenticated, setIsAzureAuthenticated, isGoogleAuthenticated, setIsGoogleAuthenticated, authenticated, setAuthenticated, isAuthenticationLoading, setIsAuthenticationLoading} = useAuth();
  const {setSecurityLevel, securityLevels} = useAuth();
  const {profile, setProfile, getProfile, companyBackgroundImageURL, setCompanyBackgroundImageURL, setCompanyPreferences} = useData();
  const {toasterMessage, setToasterMessage} = useData();

  // Check if user is using Electron
  // const isElectron = navigator.userAgent.toLowerCase().includes('electron'); // alternative
  const isElectron = window.electron ? true : false;
  // console.log ("[App.js][main()] - isElectron: ", isElectron); // TO BE REMOVED
     
  // Azure integrations
  const {instance } = useMsal();

  // ---------------------------------------------------------------------------------------------------------
  // Authentication methods
  // ---------------------------------------------------------------------------------------------------------
  useEffect (() => {
    if (Configs.devEnvironment) console.log ("[App.js] [useEffect()] - params: ", isAzureAuthenticated, isGoogleAuthenticated, authenticated, employeeID, companyID);

    // Background image URL personalization
    // if (companyPreferences?.personalization?.backgroundImageURL) {
    //   setCompanyBackgroundImageURL(companyPreferences?.personalization?.backgroundImageURL);
    // }

    // ---------------------------------------
    // Standard authentication - User & Password
    // ---------------------------------------
    if (!isAzureAuthenticated && !isGoogleAuthenticated) {
      // Check if token is still valid
      authenticateToken();
    }
    
    // ---------------------------------------
    // Google
    // ---------------------------------------
    if (isGoogleAuthenticated) {
      // getKincodeToken("Google", bodyAux);
      authenticateToken();
    }

    // ---------------------------------------
    // Azure
    // ---------------------------------------
    // Solved heroku-22 migration --> Redirect promise
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2796#issuecomment-749258299
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2796
    // ---------------------------------------
    instance.handleRedirectPromise()
      .then((s) => {
        if (Configs.devEnvironment) console.log("[App.js] [useEffect()] - redirect: ", s);
        console.log("[App.js] [useEffect()] - redirect: ", s);
        if (s !== null && s.account != null) {
          //        currentAccount.setCurrentAccount(s.account.username, s.account.homeAccountId, s.account.tenantId);
          let accountsInfo = s?.account;

          if (accountsInfo) {
            // Authenticated
            setIsAzureAuthenticated(true);
            setAuthenticated(true);

            // Build body of request to get Kincode Token
            const bodyAux = {
              azure_oid: accountsInfo?.idTokenClaims?.oid, // unique identifier of user across Apps
              azure_tid: accountsInfo?.idTokenClaims?.tid, // CompanyID tied to this user
              azure_homeAccountId: accountsInfo?.homeAccountId, // 
              azure_tenantId: accountsInfo.tenantId, // similar to CompanyID
              username: accountsInfo.username,
              name: accountsInfo.name,
            }

            // With the body of the request created, let's create the Request for token
            getKincodeToken("Azure", bodyAux);
          } else {
            console.error ("[App.js][useEffect()] Microsoft Azure integration - there are no accounts for this user");
            setIsAuthenticationLoading(false);      
            setAuthenticated(false);
            setIsAzureAuthenticated(false);
          }
        } else {
          setIsAuthenticationLoading(false);      
          // setAuthenticated(false);
          setIsAzureAuthenticated(false);
        }
          
      })
      .catch((a) => {
        console.error("[App.js] [useEffect()] - error on handleRedirectPromise: ", a);
        setIsAuthenticationLoading(false);      
        setAuthenticated(false);
        setIsAzureAuthenticated(false);
        setToasterMessage({message: t('error'), type: Configs.errorToaster});
      });

      if (isAzureAuthenticated) {
        // // Connect with Backend to acquire token with "MS Azure credentials"
        // // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_common.html#accountinfo
        let accountsInfo = instance?.getAllAccounts();
  
        if (accountsInfo.length > 0) {
          if (Configs.devEnvironment) console.log ("[App.js] [useEffect()] Microsoft Azure integration - :", accountsInfo);
          // Build body of request to get Kincode Token
          const bodyAux = {
            azure_oid: accountsInfo[0]?.idTokenClaims?.oid, // unique identifier of user across Apps
            azure_tid: accountsInfo[0]?.idTokenClaims?.tid, // CompanyID tied to this user
            azure_homeAccountId: accountsInfo[0]?.homeAccountId, // 
            azure_tenantId: accountsInfo[0].tenantId, // similar to CompanyID
            username: accountsInfo[0].username,
            name: accountsInfo[0].name,
          }
  
          // With the body of the request created, let's create the Request for token
          console.log ("[App.js][useEffect()] Microsoft Azure integration - getting Token");
  
          getKincodeToken("Azure", bodyAux);
        } else {
          console.error ("[App.js][useEffect()] Microsoft Azure integration - there are no accounts for this user");
        }
      }

      // Add callback for redirect - solved heroku-22 migration
      // https://stackoverflow.com/questions/65613324/getting-state-not-found-during-msal-loginredirect-method
      const callbackId = instance.addEventCallback((message) => {
        console.log("[App.js] [useEffect()] - callbackMessage: ", message)
      })
  
      // Clean up
      return () => {
        if (callbackId) {
            instance.removeEventCallback(callbackId);
        }
      };   

}, []);

  // ---------------------------------------------------------------------------------------------------------
  // Authentication API methods
  // ---------------------------------------------------------------------------------------------------------
function getKincodeToken (identityProvider, bodyInput) {
    setIsAuthenticationLoading(true);
  
    // Authenticated by 3rd party, get a token from Kincode database
    // console.log ("Authenticating token...", token, " - Authenticated: ", authenticated);
    var requestOptions = {};

    switch (identityProvider) {

      case "Azure":
        // Populate body for Azure request
        requestOptions = {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
            'Accept': "application/json",
            // 'Authorization': 'Bearer ' + process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN,
          },      
          body: JSON.stringify({
            authType: "Azure",
            data: bodyInput,
            timestamp: new Date()
          }),
        };
        break;

      case "Google":
        // Populate body for Google request
        requestOptions = {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
            'Accept': "application/json",
            // 'Authorization': 'Bearer ' + process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN,
          },
          body: JSON.stringify({
            authType: "Google",
            data: bodyInput,
            timestamp: new Date()
          }),
        };
        break;

      default:
        break;
    }

    fetch(Configs.getKincodeTokenAPI, requestOptions)
      .then((response) => {
        setIsAuthenticationLoading(false);

        if (response.status === 200) {

          response?.json()
          .then((body) => {
            if (Configs.devEnvironment) console.log ("[App.js][getKincodeToken()] DEV ONLY log - Get Employee Info: ", body);

            if (body) {
              // sessionStorage.setItem("roles", CryptoJS.AES.encrypt(JSON.stringify(body.roles), Configs.privateKey).toString());
              sessionStorage.setItem("employeeID", CryptoJS.AES.encrypt(body.employeeID.toString(), Configs.privateKey).toString());
              setEmployeeID(body.employeeID);
              sessionStorage.setItem("companyID", CryptoJS.AES.encrypt(body.companyID.toString(), Configs.privateKey).toString()); 
              setCompanyID(body.companyID);
              // if (body?.bearerToken) sessionStorage.setItem("token", CryptoJS.AES.encrypt(body.bearerToken.toString(), Configs.privateKey).toString());
              if (body?.bearerToken) setToken(body?.bearerToken);
              if (body?.csrfToken) sessionStorage.setItem("csrfToken", CryptoJS.AES.encrypt(body.csrfToken.toString(), Configs.privateKey).toString());
              if (body?.managerInfo) sessionStorage.setItem("managerInfo", CryptoJS.AES.encrypt(JSON.stringify(body?.managerInfo), Configs.privateKey).toString());
              sessionStorage.setItem("employeeInfo", CryptoJS.AES.encrypt(JSON.stringify(body?.employeeInfo), Configs.privateKey).toString());
              sessionStorage.setItem('employeeProfile', CryptoJS.AES.encrypt(JSON.stringify(body), Configs.privateKey).toString());
              sessionStorage.setItem("companyInfo", CryptoJS.AES.encrypt(JSON.stringify(body?.companyInfo), Configs.privateKey).toString());
              sessionStorage.setItem("companyPreferences", CryptoJS.AES.encrypt(JSON.stringify(body?.companyPreferences), Configs.privateKey).toString());
              sessionStorage.setItem("securityLevel", CryptoJS.AES.encrypt(JSON.stringify(body?.roles?.securityLevels), Configs.privateKey).toString());             
              // sessionStorage.setItem("activeModules", CryptoJS.AES.encrypt(JSON.stringify(body.companyPreferences.activeModules), Configs.privateKey).toString());

              setProfile(body);
              setSecurityLevel(body?.roles?.securityLevels);
              setCompanyPreferences(body?.companyPreferences);
              setCompanyBackgroundImageURL(body?.companyPreferences?.personalization?.backgroundImageURL);

              setAuthenticated(true);
            }
            
          })
          .catch((error) => {
            console.error("[App.js] [authenticateToken()] Error fetching data: ", error);

          });
        } else if (response.status === 400) {
            // Something went wrong - inform user
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            setAuthenticated(false);
            setIsAzureAuthenticated(false);
            setIsGoogleAuthenticated(false);
            sessionStorage.clear();
        } else if (response.status === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            setAuthenticated(false);
            setIsAzureAuthenticated(false);
            setIsGoogleAuthenticated(false);
            sessionStorage.clear();
        } else if (response.status === 403) {
            // Force logout
            setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            setAuthenticated(false);
            setIsAzureAuthenticated(false);
            setIsGoogleAuthenticated(false);
            sessionStorage.clear();
        } else {
          // There is an error - delete info
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }  
      })

      .catch((error) => {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
        console.error("[App.js][getKincodeToken()] Error fetching data: ", error);
        setAuthenticated(false);
        setIsAzureAuthenticated(false);
        setIsGoogleAuthenticated(false);
        setIsAuthenticationLoading(false);
      });
}

  async function getGoogleUserInfo (accessToken) {
    setIsAuthenticationLoading(true);

      // fetch user information from Google server      
      fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          // 'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          'Accept': 'application/json',
        }
      })
      .then(response => {

        if (response.status === 200) {
          // All good
          response.json()
          .then (body => {
              if (Configs.devEnvironment) console.log ("[App.js][getGoogleUserInfo()] Get Employee Info from Google: ", body);
              getKincodeToken("Google", body);
            })
          .catch(error => {
            console.error("[App.js][getGoogleUserInfo()] Error parsing response JSON", error);
            setIsAuthenticationLoading(false);
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          });
        } else {
          // There is an error - delete info
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          setIsAuthenticationLoading(false);
          throw response;
        }
      })
      .catch(error => {
        console.error("[App.js][getGoogleUserInfo()] error: ", error);
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
        setIsAuthenticationLoading(false);
      });
  }

  async function authenticateToken () {    
    // Set Loading status
    setIsAuthenticationLoading(true);

    // Send current token, and check if it still valid
    // define header and HTTP request type
    const response = await apiCalls.authenticateToken(companyID, employeeID, process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN);

    // Set Loading status
    setIsAuthenticationLoading(false);

    // Handle response
    // Review response status
    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        setAuthenticated(false);
        setIsAzureAuthenticated(false);
        setIsGoogleAuthenticated(false);
        setProfile(null);
        setCompanyPreferences(null);
        sessionStorage.clear();

        // Send info to Electron
        if (window.electron) {
          if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()]  Sending to electron: User is not authenticated");
          window.electron.sendUserIsNotAuthenticated();
        }
      } else if (response === 422) {
        setToasterMessage({message: t('error.data-validation-error'), type: Configs.errorToaster});
        setAuthenticated(false);
        setIsAzureAuthenticated(false);
        setIsGoogleAuthenticated(false);
        setProfile(null);
        setCompanyPreferences(null);
        sessionStorage.clear();

        // Send info to Electron
        if (window.electron) {
          if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()]  Sending to electron: User is not authenticated");
          window.electron.sendUserIsNotAuthenticated();
        }

      } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
        setAuthenticated(false);
        setIsAzureAuthenticated(false);
        setIsGoogleAuthenticated(false);
        setProfile(null);
        setCompanyPreferences(null);
        sessionStorage.clear();

        // Send info to Electron
        if (window.electron) {
          if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()]  Sending to electron: User is not authenticated");
          window.electron.sendUserIsNotAuthenticated();
        }
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
        setAuthenticated(false);
        setIsAzureAuthenticated(false);
        setIsGoogleAuthenticated(false);
        setProfile(null);
        setCompanyPreferences(null);
        sessionStorage.clear();
        // Send info to Electron
        if (window.electron) {
          if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()]  Sending to electron: User is not authenticated");
          window.electron.sendUserIsNotAuthenticated();
        }
    } else if (response === 401) {
        // setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        setAuthenticated(false);
        setIsAzureAuthenticated(false);
        setIsGoogleAuthenticated(false);
        setProfile(null);
        setCompanyPreferences(null);

        // Send info to Electron
        if (window.electron) {
          if (Configs.devEnvironment) console.log("[pulseDaily.js][requestSurvey()]  Sending to electron: User is not authenticated");
          window.electron.sendUserIsNotAuthenticated();
        }
        sessionStorage.clear();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      setAuthenticated(true);

      if (Configs.devEnvironment) console.log ("[App.js][authenticateToken()] DEV ONLY log - Get Employee Info: ", response);

      if (response) {
        // sessionStorage.setItem("roles", CryptoJS.AES.encrypt(JSON.stringify(body.roles), Configs.privateKey).toString());

        sessionStorage.setItem("employeeID", CryptoJS.AES.encrypt(response?.employeeID?.toString(), Configs.privateKey).toString());
        setEmployeeID(response.employeeID);
        sessionStorage.setItem("companyID", CryptoJS.AES.encrypt(response?.companyID?.toString(), Configs.privateKey).toString()); 
        setCompanyID(response.companyID);
        if (response?.bearerToken) setToken(response.bearerToken);
        // sessionStorage.setItem("token", CryptoJS.AES.encrypt(body.bearerToken.toString(), Configs.privateKey).toString());
        if (response?.csrfToken) sessionStorage.setItem("csrfToken", CryptoJS.AES.encrypt(response.csrfToken.toString(), Configs.privateKey).toString());
        sessionStorage.setItem("employeeInfo", CryptoJS.AES.encrypt(JSON.stringify(response?.employeeInfo), Configs.privateKey).toString());
        sessionStorage.setItem('employeeProfile', CryptoJS.AES.encrypt(JSON.stringify(response), Configs.privateKey).toString());
        if (response?.managerInfo) sessionStorage.setItem("managerInfo", CryptoJS.AES.encrypt(JSON.stringify(response?.managerInfo), Configs.privateKey).toString());
        sessionStorage.setItem("companyInfo", CryptoJS.AES.encrypt(JSON.stringify(response?.companyInfo), Configs.privateKey).toString());
        sessionStorage.setItem("companyPreferences", CryptoJS.AES.encrypt(JSON.stringify(response?.companyPreferences), Configs.privateKey).toString());
        sessionStorage.setItem("securityLevel", CryptoJS.AES.encrypt(JSON.stringify(response?.roles?.securityLevels), Configs.privateKey).toString());             
        // sessionStorage.setItem("activeModules", CryptoJS.AES.encrypt(JSON.stringify(body.companyPreferences.activeModules), Configs.privateKey).toString());

        setProfile(response);
        setSecurityLevel(response?.roles?.securityLevels);
        setCompanyPreferences(response?.companyPreferences);
        setCompanyBackgroundImageURL(response?.companyPreferences?.personalization?.backgroundImageURL);
      }
    }
  }

  // ---------------------------------------------------------------------------------------------------------
  // Azure AD login methods
  // ---------------------------------------------------------------------------------------------------------  
  function logInAzure () {
    // Loading screen
    setIsAuthenticationLoading(true);

    // Request redirect to login to Azure
    if (Configs.devEnvironment) {
      // Pop up in dev or in Electron
      instance.loginPopup()
      .then(result => {
        if (Configs.devEnvironment) console.log("[App.js][logInAzure()] - result:", result);
        setIsAzureAuthenticated(true);
        setAuthenticated(true);
        setIsAuthenticationLoading(false);
        // setIsAzureAuthenticated(true);
        // Connect with Backend to acquire token with "MS Azure credentials"
        // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_common.html#accountinfo
        let accountsInfo = instance.getAllAccounts();

        if (accountsInfo.length > 0) {
          if (Configs.devEnvironment) console.log ("[App.js] [useEffect()] Microsoft Azure integration - :", accountsInfo);
          // Build body of request to get Kincode Token
          const bodyAux = {
            azure_oid: accountsInfo[0]?.idTokenClaims?.oid, // unique identifier of user across Apps
            azure_tid: accountsInfo[0]?.idTokenClaims?.tid, // CompanyID tied to this user
            azure_homeAccountId: accountsInfo[0]?.homeAccountId, // 
            azure_tenantId: accountsInfo[0].tenantId, // similar to CompanyID
            username: accountsInfo[0].username,
            name: accountsInfo[0].name,
          }

          // With the body of the request created, let's create the Request for token
          getKincodeToken("Azure", bodyAux);
        }

        
      })    
      .catch(e => {
          setIsAuthenticationLoading(false);      
          setAuthenticated(false);
          setIsAzureAuthenticated(false);
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          console.error("[App.js][logInAzure()] - error:", e);
          return;
        });

    } else {
      // PROD - Express.js does not allow popups
      instance.loginRedirect()
      .then( result => {
        if (Configs.devEnvironment) console.log("[App.js][logInAzure()] - result:", result);
        setIsAzureAuthenticated(true);
        setAuthenticated(true);
        // setIsAzureAuthenticated(true);
        // Connect with Backend to acquire token with "MS Azure credentials"
        // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_common.html#accountinfo
        let accountsInfo = instance.getAllAccounts();

        if (accountsInfo.length > 0) {
          if (Configs.devEnvironment) console.log ("[App.js] [useEffect()] Microsoft Azure integration - :", accountsInfo);
          // Build body of request to get Kincode Token
          const bodyAux = {
            azure_oid: accountsInfo[0]?.idTokenClaims?.oid, // unique identifier of user across Apps
            azure_tid: accountsInfo[0]?.idTokenClaims?.tid, // CompanyID tied to this user
            azure_homeAccountId: accountsInfo[0]?.homeAccountId, // 
            azure_tenantId: accountsInfo[0].tenantId, // similar to CompanyID
            username: accountsInfo[0].username,
            name: accountsInfo[0].name,
          }

          // With the body of the request created, let's create the Request for token
          getKincodeToken("Azure", bodyAux);
        }

        
      })    
      .catch(e => {
          setIsAuthenticationLoading(false);      
          setAuthenticated(false);
          setIsAzureAuthenticated(false);
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          console.error("[App.js][logInAzure()] - error:", e);
          return;
        })
    }
    
  }

  function logOutAzure () {
    // Frontend code to initiate the authentication flow with your backend API
    // define header and HTTP request type
    instance.logout()
    .then( result => { 
      setAuthenticated(false);
      setIsAzureAuthenticated(false);
      setIsAuthenticationLoading(false);
      sessionStorage.clear();
      window.location.reload();
    })                
}

// ---------------------------------------------------------------------------------------------------------
// Usr+Pwd + Google login methods
// ---------------------------------------------------------------------------------------------------------
function setAuthenticatedTrue (employeeID, companyID, bearerToken, csrfToken) {
  // Authenticated from Login page
  if (Configs.devEnvironment) console.log ("[App.js][setAuthenticatedTrue()] Login Kincode credentials:", employeeID, companyID, bearerToken, csrfToken);

  // Set state
  setEmployeeID(employeeID);
  setCompanyID(companyID);
  setToken(bearerToken);
  setAuthenticated(true);  

  // Get Profile
  getProfile(employeeID, companyID, bearerToken, csrfToken);
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
};

  // ---------------------------------------------------------------------------------------------------------
  // Rendering App methods
  // ---------------------------------------------------------------------------------------------------------  

  if (token !== null && profile !== null && authenticated) {
    // User is authenticated
    return (
      <React.Fragment>
        <div className="App" style={{
                                      backgroundImage: `url(${companyBackgroundImageURL})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                      backgroundRepeat: 'no-repeat',
                                      width: '100vw',
                                      height: '100vh',
                                    }}>
            <BrowserRouter>
                <HistoryProvider>
                  <Routing/>
                </HistoryProvider>
            </BrowserRouter>
        </div>
      </React.Fragment>
    ); 

  } else {
    // User is not authenticated

    // Check if user is requesting to rest password
    const regExpResetPwd = "^.*resetPassword.*$";
    // Check if user is requesting to rest password
    const regExpSurvey = "^.*pulseSurvey.*$";
    // const regExpDaily = "^.*pulseDaily.*$";
    const regExpDaily = "^.*21435.*$";
    // console.log (window.location.href, regExpSurvey, window.location.href.match(regExpSurvey));
    const regExpPublicRecognition = "^.*public/recognition.*$";

    if (window.location.href.match(regExpResetPwd)) {
      return (
        // Reset password page
        <React.Fragment>
              <ResetPassword />
        </React.Fragment>
      );
    } else if (window.location.href.match(regExpSurvey)) {    
      return (
          // Pulse Survey page
          <React.Fragment>
              <PulseSurvey />
          </React.Fragment>
      );
    } else if (window.location.href.match(regExpDaily)) {
      return (
        // Pulse Daily page
        <React.Fragment>
            <BrowserRouter>
                <Routing/>
            </BrowserRouter>
        </React.Fragment>
      );
    } else if (window.location.href.match(regExpPublicRecognition)) {
      return (
        // Public Recognition page
        <React.Fragment>
            <RecognitionPublicSingleRecognition />
        </React.Fragment>
      );

    } else {
      // User is not authenticated, and is not requesting to change the password nor answering a survey
      return (
        // Login page
        <React.Fragment>
          <div className='flex flex-row justify-center'>
            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
          </div>
          <GoogleOAuthProvider clientId={Configs.googleClientID}>
              <Login setAuthenticatedTrue={setAuthenticatedTrue} 
              logInAzure={logInAzure} logOutAzure={logOutAzure} isAzureAuthenticated={isAzureAuthenticated}
              isGoogleAuthenticated={isGoogleAuthenticated} setIsGoogleAuthenticated={setIsGoogleAuthenticated} getGoogleUserInfo={getGoogleUserInfo}/>
          </GoogleOAuthProvider>
        </React.Fragment>
      );
    }
  }
}

export default App;
