// React
import React, { useState, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";

// Common and widgets
import Header from '../../common/header';
import SuperAdminHeader from './superAdminHeader';
import Configs from "../../Configs";
import Bot from '../../common/support/bot'; 
import { useData } from '../../common/dataContext';
import DataTable from '../../widgets/dataTable';

// 3P
import { useTranslation } from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';


// ---------------------------------------------------------------------------------------------------
function CCOaaS () {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const {toasterMessage, setToasterMessage} = useData
  const {setSelectedCompany} = useData();

  const {CCOaaSReport, isCCOaaSReportLoading} = useData();
  const [report, setReport] = useState(null);

  // COlumn names
  const columnNames = [
    {type:'string',id: 'companyName', value: 'companyName', label: t('company'), sort: null, filter: null},
    {type:'string',id: 'companyID', value: 'companyID', label: t('ID'), sort: null, filter: null},
    {type:'currency',id: 'revenuePotential', value: 'revenuePotential', label: t('admin.ccoaas.metrics.revenue'), sort: null, filter: null},

    // Active employees
    {type:'number',id: 'currentActiveEmployees', value: 'currentActiveEmployees', label: t('admin.ccoaas.metrics.activeEmployees.actuals'), sort: null, filter: null},
    {type:'number',id: 'maxMonthActiveEmployees', value: 'maxMonthActiveEmployees', label: t('admin.ccoaas.metrics.max-month'), sort: null, filter: null},
    // {type:'number',id: 'activeEmployeesLastWeek', value: 'activeEmployeesLastWeek', label: t('admin.ccoaas.metrics.last-week'), sort: null, filter: null},
    {type:'delta-percentage',id: 'activeEmployeesWoW', value: 'activeEmployeesWoW', label: t('admin.ccoaas.metrics.wow'), sort: null, filter: null},
    // {type:'number',id: 'activeEmployeesLastMonth', value: 'activeEmployeesLastMonth', label: t('admin.ccoaas.metrics.last-month'), sort: null, filter: null},
    {type:'delta-percentage',id: 'activeEmployeesMoM', value: 'activeEmployeesMoM', label: t('admin.ccoaas.metrics.mom'), sort: null, filter: null},
    // {type:'number',id: '', value: '', label: t('admin.ccoaas.metrics.yoy'), sort: null, filter: null},
    // Deactivated employees
    {type:'number',id: 'deactivatedEmployeesWeek', value: 'deactivatedEmployeesWeek', label: t('admin.ccoaas.metrics.deactivated.actuals'), sort: null, filter: null},
    // {type:'number',id: 'deactivatedEmployeesLastWeek', value: 'deactivatedEmployeesLastWeek', label: t('admin.ccoaas.metrics.last-week'), sort: null, filter: null},
    {type:'number',id: 'deactivatedEmployeesMonth', value: 'deactivatedEmployeesMonth', label: t('admin.ccoaas.metrics.max-month'), sort: null, filter: null},
    {type:'delta-percentage-inverse',id: 'deactivatedEmployeesWoW', value: 'deactivatedEmployeesWoW', label: t('admin.ccoaas.metrics.wow'), sort: null, filter: null},
    // {type:'number',id: 'deactivatedEmployeesLastMonth', value: 'deactivatedEmployeesLastMonth', label: t('admin.ccoaas.metrics.last-month'), sort: null, filter: null},
    {type:'delta-percentage-inverse',id: 'deactivatedEmployeesMoM', value: 'deactivatedEmployeesMoM', label: t('admin.ccoaas.metrics.mom'), sort: null, filter: null},
    // {type:'number',id: '', value: '', label: t('admin.ccoaas.metrics.yoy'), sort: null, filter: null},
    // rotationRate
    {type:'percentage',id: 'rotationRateWeek', value: 'rotationRateWeek', label: t('admin.ccoaas.metrics.rotationRate.actuals'), sort: null, filter: null},
    // {type:'percentage',id: 'rotationRateLastWeek', value: 'rotationRateLastWeek', label: t('admin.ccoaas.metrics.last-week'), sort: null, filter: null},
    {type:'percentage',id: 'rotationRateMonth', value: 'rotationRateMonth', label: t('admin.ccoaas.metrics.max-month'), sort: null, filter: null},
    {type:'delta-bps-inverse',id: 'rotationRateWoW', value: 'rotationRateWoW', label: t('admin.ccoaas.metrics.wow'), sort: null, filter: null},
    // {type:'percentage',id: 'rotationRateLastMonth', value: 'rotationRateLastMonth', label: t('admin.ccoaas.metrics.last-month'), sort: null, filter: null},
    {type:'delta-bps-inverse',id: 'rotationRateMoM', value: 'rotationRateMoM', label: t('admin.ccoaas.metrics.mom'), sort: null, filter: null},
    // {type:'number',id: '', value: '', label: t('admin.ccoaas.metrics.yoy'), sort: null, filter: null},
    // Attrition risk
    {type:'number',id: 'attritionRiskWeek', value: 'attritionRiskWeek', label: t('admin.ccoaas.metrics.attrition.actuals'), sort: null, filter: null},
    // {type:'number',id: 'attritionRiskLastWeek', value: 'attritionRiskLastWeek', label: t('admin.ccoaas.metrics.last-week'), sort: null, filter: null},
    {type:'number',id: 'attritionRiskMonth', value: 'attritionRiskMonth', label: t('admin.ccoaas.metrics.max-month'), sort: null, filter: null},
    {type:'delta-percentage-inverse',id: 'attritionRiskWoW', value: 'attritionRiskWoW', label: t('admin.ccoaas.metrics.wow'), sort: null, filter: null},
    // {type:'number',id: 'attritionRiskLastMonth', value: 'attritionRiskLastMonth', label: t('admin.ccoaas.metrics.last-month'), sort: null, filter: null},
    {type:'delta-percentage-inverse',id: 'attritionRiskMoM', value: 'attritionRiskMoM', label: t('admin.ccoaas.metrics.mom'), sort: null, filter: null},

    // Satisfaction (Manager, Team, Job, eNPS, inclusion, mental health)
    {type:'number',id: 'managerSatisfactionWeek', value: 'managerSatisfactionWeek', label: t('admin.ccoaas.metrics.pulse.manager-satisfaction'), sort: null, filter: null},
    {type:'number',id: 'managerSatisfactionLastWeek', value: 'managerSatisfactionLastWeek', label: t('admin.ccoaas.metrics.last-week'), sort: null, filter: null},
    {type:'delta-percentage',id: 'managerSatisfactionWoW', value: 'managerSatisfactionWoW', label: t('admin.ccoaas.metrics.wow'), sort: null, filter: null},
    {type:'number',id: 'managerSatisfactionMonth', value: 'managerSatisfactionMonth', label: t('admin.ccoaas.metrics.max-month'), sort: null, filter: null},
    {type:'number',id: 'managerSatisfactionLastMonth', value: 'managerSatisfactionLastMonth', label: t('admin.ccoaas.metrics.last-month'), sort: null, filter: null},
    {type:'delta-percentage',id: 'managerSatisfactionMoM', value: 'managerSatisfactionMoM', label: t('admin.ccoaas.metrics.mom'), sort: null, filter: null},


    // 1:1 Compliance

    // Recognition
    {type:'number',id: 'recognitionWeek', value: 'recognitionWeek', label: t('admin.ccoaas.metrics.recognition.actuals'), sort: null, filter: null},
    {type:'number',id: 'recognitionLastWeek', value: 'recognitionLastWeek', label: t('admin.ccoaas.metrics.last-week'), sort: null, filter: null},
    {type:'delta-percentage',id: 'recognitionWoW', value: 'recognitionWoW', label: t('admin.ccoaas.metrics.wow'), sort: null, filter: null},
    {type:'number',id: 'recognitionMonth', value: 'recognitionMonth', label: t('admin.ccoaas.metrics.max-month'), sort: null, filter: null},
    {type:'number',id: 'recognitionLastMonth', value: 'recognitionLastMonth', label: t('admin.ccoaas.metrics.last-month'), sort: null, filter: null},
    {type:'delta-percentage',id: 'recognitionMoM', value: 'recognitionMoM', label: t('admin.ccoaas.metrics.mom'), sort: null, filter: null},

    // Onboarding compliance

    // Platform satisfaction
    {type:'number',id: 'platformSatisfactionWeek', value: 'platformSatisfactionWeek', label: t('admin.ccoaas.metrics.platformSatisfaction.actuals'), sort: null, filter: null},
    {type:'number',id: 'platformSatisfactionNegativesWeek', value: 'platformSatisfactionNegativesWeek', label: t('admin.ccoaas.metrics.platformSatisfaction-negative.actuals'), sort: null, filter: null},
    {type:'number',id: 'platformSatisfactionPositivesWeek', value: 'platformSatisfactionPositivesWeek', label: t('admin.ccoaas.metrics.platformSatisfaction-positive.actuals'), sort: null, filter: null},

    // Actions
    {type: 'action', id: 'action', value: 'action', label: t('action'), sort: null, filter: null}
  ];

  // ---------------------------------------------------------------------------------------------------
  // useEffects
  // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      // Get information to populate dashboard
      if (CCOaaSReport) setReport(CCOaaSReport);

    }, [CCOaaSReport]);


// ---------------------------------------------------------------------------------------------------
// 
// ---------------------------------------------------------------------------------------------------
const viewCompany = (index, row) => {
  if (Configs.devEnvironment) console.log("[adminCCOaaS.js][viewCompany] index: ", index, "row: ", row);

  setSelectedCompany(row?.companyID);

  navigate('../admin/ccoaas/company/'+ row.companyID);

}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
  
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
          <SuperAdminHeader />            
            <div className='flex flex-row items-start'>
              <div className="left-big-view">
                
                  {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                    <div className='flex flex-col text-left items-start mb-4'>
                      <Tooltip content={t('admin.ccoaas.description')} >
                        <h2 className='section-title'>
                          {t('admin.ccoaas.title')}
                        </h2>
                      </Tooltip>
                    </div>

                  <div className="flex flex-col text-left ">

                    { isCCOaaSReportLoading ?
                      <Loader />
                      :
                      report ? 

                        <DataTable 
                          data={report} 
                          columnNames={columnNames}
                          viewMethod={viewCompany}
                          />
                        :
                        null
                  }

                    
                  </div>
                </div>
                <div className='right-small-view'>

              </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default CCOaaS;