// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

// Configs
import Configs from '../../Configs';
import Tooltip from '../../common/support/tooltip';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import SpiderPerformance from '../../widgets/spiderPerformance';

// 3P
import { useTranslation } from 'react-i18next';

// Icons
import {CgPerformance} from 'react-icons/cg';
import Loader from '../../common/support/loader';
import { AiOutlineInfoCircle } from 'react-icons/ai';

// ---------------------------------------------------------------
function PersonalPerformance ({companyIDinput, employeeIDinput}) {
    const {companyID, employeeID, token, securityLevel} = useAuth();
    const {selectedProfile, companyPreferences, getPersonalPerformanceData, personalPerformanceData, isPersonalPerformanceLoading} = useData();

// ---------------------------------------------------------------
// Hooks
// ---------------------------------------------------------------
    const {t} = useTranslation();

// ---------------------------------------------------------------
// useEffect
// ---------------------------------------------------------------
    useEffect(() => {
        // Get the performance data
        getPersonalPerformanceData(companyID, employeeID, token);
    }, []);

    
// ---------------------------------------------------------------
// Return
// ---------------------------------------------------------------
    return (
        <React.Fragment>
            {companyPreferences?.activeModules["Performance-Review"] === true && 
            ((securityLevel?.includes(Configs.securityLevelManager)) 
            || securityLevel?.includes(Configs.securityLevelHR)
            || securityLevel?.includes(Configs.securityLevelAdmin)
            || securityLevel?.includes(Configs.securityLevelExec)
            ) ?
                <div className='flex flex-col items-start justify-center text-left space-y-2 bg-white shadow  rounded-lg border text-gray-800 text-sm'>
                <h4 className='card-title flex flex-row items-center py-4 px-8'> <CgPerformance className='mr-2 '/> 
                    {t('widgets.personal-performance.title')}
                    <Tooltip content={t('widgets.personal-performance.tooltip')} >
                        <AiOutlineInfoCircle className='flex text-gray-500 hover:cursor-pointer ml-2' />  
                    </Tooltip>
                </h4>
                <div className='flex flex-col items-center justify-center text-left p-2 w-full h-full'>
                    {isPersonalPerformanceLoading ? 
                        <div className='pb-12'>
                            <Loader />
                        </div>  
                    :
                    personalPerformanceData ?
                        <SpiderPerformance 
                            // employee={profile?.name + " " + profile?.surname} 
                            // employeeName={profile?.name} 
                            // employeeSurname={profile?.surname} 
                            performanceURL={null} 
                            performanceData={personalPerformanceData} />
                    :
                        <span className='title-description text-gray-400 italic mb-4 px-4'> {t('profile.performance.not-found')} </span>
                    }
                </div>
            </div>
            :
                null
            }
        </React.Fragment>
    );

}

// ---------------------------------------------------------------
// export the component
export default PersonalPerformance;