// React
import React, {useEffect, useState} from 'react';
import {Link, useParams, useLocation, useNavigate} from "react-router-dom";

// 3P 
import CryptoJS from 'crypto-js';
import i18next, { t } from 'i18next';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Headers
import PerformanceHeader from './performanceHeader';
import Header from '../../common/header';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';

// Common and widgets
import Configs from '../../Configs';
import UserListItem from '../../widgets/userListItem';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiFillEye}  from 'react-icons/ai'; 
import PerformanceHistory from '../../widgets/performance/performanceHistory';

// ---------------------------------------------------------------------------------------------------
function PerformanceHome () {
  // Using params when performance from team members
  // params === undefined when doing self-assessment
  let { encryptedParams } = useParams();
  const params = (encryptedParams && encryptedParams !== undefined) ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

  // To pass location to the Header
  const location = useLocation();
  const navigate = useNavigate();
  const currentScreen = location.pathname;

  const {companyID, employeeID, token} = useAuth();
  const {toasterMessage, setToasterMessage} = useData();

  // Performance data
  const {livePerformanceCampaign, setLivePerformanceCampaign, performanceReviewCampaigns, isPerformanceLiveCampaignsLoading, performanceReviewManagerDraft} = useData();
  
  // Self
  const {personalPerformanceReviewDraft, isPersonalPerformanceReviewDrafLoading} = useData();
  const {personalPerformanceReviewHistory, isPersonalPerformanceReviewHistoryLoading} = useData();
  const {personalPerformanceFeedbackProviders, setPersonalPerformanceFeedbackProviders, isPersonalPerformanceFeedbackProvidersLoading} = useData();
  
  // Team-member
  const {selectedProfile, setSelectedProfile, companyOrg} = useData();
  const {performanceReviewDraft,  isPerformanceReviewDraftLoading} = useData();
  const {performanceFeedbackProviders, setPerformanceFeedbackProviders,  isPerformanceFeedbackProvidersLoading} = useData();
  const {performanceReviewHistory, isPerformanceReviewHistoryLoading} = useData();

  const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

  const [feedbackProvider, setFeedbackProvider] = useState("");
  const [providerAdded, setProviderAdded] = useState(false);
  const [existingProvider, setExistingProvider] = useState(false);
  const [incorrectFormat, setIncorrectFormat] = useState(false);

  // For debugging
  if (Configs.devEnvironment) console.log ("[performanceHome.js][main()] - Campaigns: ", performanceReviewCampaigns);
  if (Configs.devEnvironment) console.log ("[performanceHome.js][main()] - Params: ", params, selectedProfile);
  if (Configs.devEnvironment) console.log ("[performanceHome.js][main()] - currentScreen: ", currentScreen);
  if (Configs.devEnvironment) console.log ("[performanceHome.js][main()] - FeedbackProviders: ", performanceFeedbackProviders);

  // ---------------------------------------------------------------------------------------------------
  // Methods to load when page is loaded
  // ---------------------------------------------------------------------------------------------------
  useEffect (() => {
    // Fix selectedProfile
    if (!params && selectedProfile) {
      // IC
      setSelectedProfile(null); 
    } else if (params && !selectedProfile) {
      // Go back to team view, as we have no selectedProfile
      navigate("/performance/team/");
      console.error("[performanceReview.js][useEffect()] - No selectedProfile found");
    } 

    if (!params) {
      // IC view
      if ((personalPerformanceReviewDraft) 
        && (personalPerformanceReviewDraft[0]?.status === "completed" || personalPerformanceReviewDraft[0]?.status === "submitted" || personalPerformanceReviewDraft[0]?.status === "sent")) {
            // We have a draft that has been either submitted by IC, or even completed by Manager
            // If IC --> navigate to the discuss screen
            navigate("/performance/self/discuss");
            setLivePerformanceCampaign(false);
      } else {
        if (performanceReviewCampaigns) setLivePerformanceCampaign(true);
      }
    } else {
      // Team view - there are params
      if ((performanceReviewManagerDraft) 
          &&(performanceReviewManagerDraft[0]?.status === "completed" || performanceReviewManagerDraft[0]?.status === "submitted" ||performanceReviewManagerDraft[0]?.status === "sent")) {
          // We have a draft that has been either submitted by IC, or even completed by Manager
          if (performanceReviewManagerDraft[0]?.status === "completed" || performanceReviewManagerDraft[0]?.status === "sent") {
            // If Manager --> navigate to the discuss screen
            navigate("/performance/team/employee/discuss/" +
            encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                employeeID: params?.employeeID,
                employee: params?.employee,
                companyID: companyID,
                managerID: employeeID
                }),Configs.privateKey).toString()));
                setLivePerformanceCampaign(false);
          }
      } else {
        if (performanceReviewCampaigns) setLivePerformanceCampaign(true);
      }
    }
    

    }, [location, selectedProfile, performanceReviewCampaigns,
       personalPerformanceReviewDraft, performanceReviewDraft,
       performanceReviewManagerDraft, performanceFeedbackProviders]);
    
  // ---------------------------------------------------------------------------------------------------
  // API calls methods
  // ---------------------------------------------------------------------------------------------------

    // Submit updated feedback providers
    // ---------------------------------------------------------------------------------------------------
    async function submitFeedbackProviders (providers) {
      // HTTP POST request options
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 
          'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          },
        body: JSON.stringify({ 
          employeeID: params ? params?.employeeID : employeeID,
          companyID: companyID,
          campaignID: performanceReviewCampaigns[0]._id,
          providers: providers,
        })
      };

      // Post to server      
      fetch(Configs.platformPostFeedbackProvidersAPI, requestOptions)
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
              // All good
              // getLivePerformanceCampaigns();
              response.json()
              .then (body => {
                  // console.log(body)
                  
                })
              .catch(error => {
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                console.error("[performanceHome.js][submitFeedbackProviders()] Error parsing response JSON", error);
              });
            } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('calibration.load.warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else {
              //
              // setToasterMessage({message: t('error'), type: Configs.errorToaster});
              // throw response;
            }
          })
    
          .catch((error) => {
            console.error("[performanceHome.js][submitFeedbackProviders()] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          });
    }

    
    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
      };
  

    // ---------------------------------------------------------------------------------------------------
    // Frontend control methods
    // ---------------------------------------------------------------------------------------------------

    async function addFeedbackProvider (e) {
      e.preventDefault();

      // Reset settings
      setProviderAdded(false);
      setIncorrectFormat(false);
      setExistingProvider(false);

      // Review if provider is already added
      var existingEmail = false;
      performanceFeedbackProviders?.forEach(element => {
        if (element.email === feedbackProvider){
          // Existing address
          existingEmail = true;
          setExistingProvider(true)
        } 
      });

      // Review if format is correct
      if (feedbackProvider === undefined || feedbackProvider === "") {
        setIncorrectFormat(true);
      } else {
        var emailFormat = feedbackProvider.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (!emailFormat) {
          setIncorrectFormat(true);
        }
      }

      // Add 
      if (!existingEmail && emailFormat && feedbackProvider!== "") {
        // Set status
        setExistingProvider(false);
        setIncorrectFormat(false);

        // Get existing feedbackProviders
        var aux = performanceFeedbackProviders;
        var auxDate = new Date().toLocaleDateString();

        // Create object
        const newProvider ={
          email: feedbackProvider,
          requestedTime: auxDate
        }

        if (companyOrg?.filter(x => x.emails[0] === feedbackProvider).length > 0) {
          // Employee
          newProvider.type = "employee";
          newProvider.employeeID = companyOrg?.find(x => x.emails[0] === feedbackProvider).employeeID;
          newProvider.companyID = companyOrg?.find(x => x.emails[0] === feedbackProvider).companyID;
        } else {
          // External
          newProvider.type = "external";
        }

        // Add new Feedback provider and update variable
        if (performanceFeedbackProviders) {
          aux.push(newProvider);
        } else {
          aux = [newProvider];
        }

        
        setProviderAdded(true);
        setPerformanceFeedbackProviders([...aux]);
        submitFeedbackProviders(aux);

        // Clean-up fields
        setFeedbackProvider("");
      }
    }

    function deleteProvider(index) {
      // Get existing feedbackProviders
      var aux = performanceFeedbackProviders;

      // Delete the one selected
      aux.splice(index, 1);

      // Update array
      setPerformanceFeedbackProviders([...aux]);
      submitFeedbackProviders(aux);
    }

  // ---------------------------------------------------------------------------------------------------
  // Navigation methods
  // ---------------------------------------------------------------------------------------------------
  const nextStepManager = (e) => {
    e.preventDefault();

    if (selectedProfile) {

    // Navigate to the next screen
    navigate("/performance/team/employee/strengths/"+
    encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
        employeeID: selectedProfile?.employeeID,
        employee: selectedProfile?.employee,
        companyID: selectedProfile?.companyID,
        managerID: selectedProfile?.managerID
        }),Configs.privateKey).toString()));
      } 
    }

    const nextStepIC = (e) => {
      e.preventDefault();

      // Navigate to the next screen
      navigate("/performance/self/strengths");
    }

   // ---------------------------------------------------------------------------------------------------
   // Render methods
   // ---------------------------------------------------------------------------------------------------

    if (livePerformanceCampaign === false) {
      return (
          <React.Fragment>
            <Header />
            <Bot />

            <div className="main">

            <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen} />

              <div className='flex flex-row items-start'>
                  <div className="left-big-view">
                    {isPerformanceLiveCampaignsLoading ||isPerformanceReviewDraftLoading ? 
                      <React.Fragment>
                        <Loader />        
                      </React.Fragment>
                      :
                      <React.Fragment>
                        
                        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
                        {params ? 
                            /* Manager */
                                    <div className="flex flex-col">
                                      <UserListItem user={params.employee} initials={params.employee.slice(0,1)} 
                                      role={params.role} level={params.level}
                                      profilePicture={params.profilePicture} />
                                      <span className="text-left text-sm m-2 mb-4">
                                        {t('performance.home.team-member.description')}
                                      </span>
                                    </div>
                            :   
                            null
                          }
              
                        <div className="flex flex-col text-left ">
                          <div className="flex flex-col mt-2 md:ml-2 m-1">
                            <h2 className="flex text-xl mb-4 font-bold">
                              {t('performance.home.empty.title')}
                            </h2>
                            <span className="text-left mb-4 title-description">
                              {t('performance.home.empty.description')}
                              <b> {t('performance.home.empty.help')} </b>
                            </span>
                          </div>

                          <SectionSeparator />

                          <div className="flex flex-col mt-2 md:ml-2 m-1">

                            <h2 className="flex text-xl mb-4 font-bold">
                              {t('performance.home.history')}
                            </h2>

                            <div>
                            { isPerformanceReviewHistoryLoading ? 
                                <Loader />  
                                :
                                <React.Fragment>
                                  {performanceReviewHistory && performanceReviewHistory.length > 0 ?

                                    <div className='flex flex-col items center mt-8 md:ml-2 m-1'>
                                      <table className=" w-full mr-2 text-sm text-center mb-4">
                                                    <thead>
                                                    <tr className="border-b border-gray-200">
                                                        <th className="p-2"> {t('name')} </th>
                                                        <th className="p-2"> {t('start-date')} </th>
                                                        <th className="p-2"> {t('end-date')} </th>
                                                        <th className="p-2"> {t('status')} </th>
                                                        <th className="p-2"> </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                      {performanceReviewHistory?.map((assessment, index) => (
                                                        assessment?.status !== "sent" && assessment?.employeeID === employeeID ?
                                                          <tr key={index} className="border-b border-gray-200 p-4">
                                                              <td className='p-4 text-sm'>
                                                                { assessment?.campaign?.name}{" "}
                                                              </td>
                                                              <td className='p-4 text-sm'>
                                                              { new Date(assessment?.campaign?.startDate).getDate() + "/" 
                                                              + (new Date(assessment?.campaign?.startDate).getMonth() + 1) + "/"
                                                              + new Date(assessment?.campaign?.startDate).getFullYear()}
                                                              </td>
                                                              <td className='p-4 text-sm'>
                                                              { new Date(assessment?.campaign?.endDate).getDate() + "/" 
                                                              + (new Date(assessment?.campaign?.endDate).getMonth() + 1) + "/"
                                                              + new Date(assessment?.campaign?.endDate).getFullYear()}
                                                              </td>
                                                              <td className='p-4 text-sm'>
                                                                {t(assessment?.status)}
                                                                </td>
                                                              <td className='p-4 text-sm'>
                                                                
                                                              </td>
                                                            </tr>

                                                        : 
                                                        assessment?.employeeID !== employeeID ?
                                                          <tr key={index} className="border-b border-gray-200 p-4">
                                                            <td className='p-4 text-sm hover:underline'>
                                                            {params ? 
                                                                <Link to={`/performance/team/employee/campaign/${companyID}/${assessment?.campaign?._id}/${employeeID}`}>
                                                                  { assessment?.campaign?.name}{" "}
                                                                </Link>
                                                                :
                                                                <Link to={`/performance/team/employee/campaign/${companyID}/${assessment?.campaign?._id}/${employeeID}`}>
                                                                  { assessment?.campaign?.name}{" "}
                                                                </Link> 
                                                              } 
                                                            </td>
                                                            <td className='p-4 text-sm'>
                                                            { new Date(assessment?.campaign?.startDate).getDate() + "/" 
                                                            + (new Date(assessment?.campaign?.startDate).getMonth() + 1) + "/"
                                                            + new Date(assessment?.campaign?.startDate).getFullYear()}
                                                            </td>
                                                            <td className='p-4 text-sm'>
                                                            { new Date(assessment?.campaign?.endDate).getDate() + "/" 
                                                            + (new Date(assessment?.campaign?.endDate).getMonth() + 1) + "/"
                                                            + new Date(assessment?.campaign?.endDate).getFullYear()}
                                                            </td>
                                                            <td className='p-4 text-sm'>
                                                              {t(assessment?.status)}
                                                              </td>
                                                            <td className='p-4 text-sm'>
                                                              {params ? 
                                                                <Link to={`/performance/team/employee/campaign/${companyID}/${assessment?.campaign?._id}/${employeeID}`}>
                                                                  <AiFillEye className="text-2xl text-gray-500 hover:text-gray-900 cursor-pointer" />
                                                                </Link>
                                                                :
                                                                <Link to={`/performance/team/employee/campaign/${companyID}/${assessment?.campaign?._id}/${employeeID}`}>
                                                                  <AiFillEye className="text-2xl text-gray-500 hover:text-gray-900 cursor-pointer" />
                                                                </Link>
                                                              
                                                            }
                                                              
                                                            </td>
                                                          </tr>
                                                        :
                                                          null
                                                      ))}
                                                  </tbody>
                                      </table>
                                    </div>

                                  :
                                    <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                                      <span className="title-description italic">🔎 {" " + t('performance.home.empty')}{" "}
                                      </span>
                                    </div>
                                  }
                                                                          

                                </React.Fragment>
                              }

                            </div>


                          </div>
                          
                
                        </div>
                      
                      </React.Fragment>
                    }
                </div>
                <div className="right-small-view">
                    <PerformanceHistory companyIDinput={companyID} employeeIDinput={params ? params?.employeeID : employeeID}/>
                </div>
                </div>
            
            </div>
          </React.Fragment>
        );    
    } else {
        // Live campaigns
        return (
          
          <React.Fragment>
          <Header/>
          <Bot />

          <div className="main">
            
              {params ? 
              /* Manager */
                  <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen}  params={params}/>
                :
                /* IC */
                  <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen} />          
                }

            <div className='flex flex-row items-start'>
                <div className="left-big-view">
                  {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

                  {/* Campaign type */}
                  {isPerformanceLiveCampaignsLoading ?
                    <Loader />
                  :
                  performanceReviewCampaigns?.length > 0 &&
                  <React.Fragment>
                      <div className="flex flex-col text-left ">
                        <div className="flex flex-col mt-2 md:ml-2 m-1">

                          {params ? 
                          /* Manager */
                                  <div className="flex flex-col space-y-4 mb-4">
                                    <UserListItem user={params?.employee} initials={params?.employee?.slice(0,1)} role={params?.role} level={params?.level}
                                    profilePicture={params?.profilePicture} />

                                    <Tooltip content={t('performance.home.team-member.description')}>
                                      <h2 className="section-title">
                                        👋 {t('performance.home.team-member') + " " + params?.employee}
                                      </h2>
                                    </Tooltip>
                                  </div>
                          :   
                                  /* IC */
                                  <div className="flex flex-col items-start mt-2 md:ml-2 m-1">
                                    <Tooltip content={t('performance.home.description')}>
                                      <h2 className="section-title">
                                        {" "}
                                        👋 {t('performance.home.title')}
                                      </h2>
                                    </Tooltip>
                                  </div>
                          }

                          <div className='flex flex-col mt-2 md:ml-2 m-1 space-y-2'>
                            <div className='title-description'>
                              {t('performance.home.description.2')}
                            </div>
                            
                            {performanceReviewCampaigns[0]?.feedbackType?.self === true ?
                              params ? 
                                <div className='flex flex-col lg:flex-row text-sm justify-between p-4 border-gray-200 rounded-lg shadow bg-white'>
                                  <div className='flex font-bold w-1/3'>{t('performance.home.title.self')}</div>
                                  <div className='flex'>{t('performance.home.description.self') + " " + companyInfo?.companyName + "."}  </div>
                                </div>
                                :
                                <div className='flex flex-col lg:flex-row text-sm justify-between p-4 border-green-200 border-4 rounded-lg shadow bg-white'>
                                  <div className='flex font-bold w-1/3'>{t('performance.home.title.self')}</div>
                                  <div className='flex'>{t('performance.home.description.peers') + " " + companyInfo?.companyName + "."}  </div>
                                </div>
                            : null }

                            {performanceReviewCampaigns[0]?.feedbackType?.peers === true ?
                                <div className='flex flex-col lg:flex-row text-sm justify-between p-4 border-gray-200 rounded-lg shadow bg-white'>
                                <div className='flex font-bold w-1/3'>{t('performance.home.title.peers')}</div>
                                  <div className='flex'>  {t('performance.home.description.peers')+ " " + companyInfo?.companyName + "."}   </div>
                                </div>
                            : null }

                            {performanceReviewCampaigns[0]?.feedbackType["employee-manager"] === true ?
                                <div className='flex flex-col lg:flex-row text-sm justify-between p-4 border-gray-200 rounded-lg shadow bg-white'>
                                <div className='flex font-bold w-1/3'>{t('performance.home.title.employee-manager')}</div>
                                  <div className='flex'>{t('performance.home.description.employee-manager')}  </div>
                                </div>
                            : null }

                            {performanceReviewCampaigns[0]?.feedbackType?.["manager-employee"] === true ?

                                params ?  
                                  <div className='flex flex-col lg:flex-row text-sm justify-between p-4 border-green-200 border-4 rounded-lg shadow bg-white'>
                                      <div className='flex font-bold w-1/3'>{t('performance.home.title.manager-employee')}</div>
                                      <div className='flex'>{t('performance.home.description.manager-employee')}  </div>
                                  </div>
                                  :
                                  <div className='flex flex-col lg:flex-row text-sm justify-between p-4 border-gray-200 rounded-lg shadow bg-white'>
                                  <div className='flex font-bold w-1/3'>{t('performance.home.title.manager-employee')}</div>
                                      <div className='flex'>{t('performance.home.description.manager-employee')}  </div>
                                  </div>
                            : null }
                          </div>


                          {/* Feedback section */}
                          {(performanceReviewCampaigns[0]?.feedbackType?.peers === true) || (performanceReviewCampaigns[0]?.feedbackType?.external === true)
                          || (performanceReviewCampaigns[0]?.feedbackType["employee-manager"] === true) ? 
                              <div className=''>

                                <SectionSeparator />

                                {(performanceReviewCampaigns[0]?.feedbackType?.peers === true) || (performanceReviewCampaigns[0]?.feedbackType?.external === true) ? 
                                  isPerformanceFeedbackProvidersLoading ?
                                    <Loader />
                                  :
                                    <React.Fragment>
                                      {/* Request section */}
                                        <div className="flex flex-col mt-2 md:ml-2 m-1">
                                          <h2 className="section-title">{t('performance.home.feedback.title')}</h2>
                                          <span className="title-description">
                                          {t('performance.home.feedback.description')}
                                            <b > {t('performance.home.feedback.help')} </b>
                                          </span>
                                        </div>

                                        <div className="flex flex-col mt-2 ml-2 m-1">
                                          <h3 className="flex text-l mb-4 italic font-bold">
                                          {t('performance.home.feedback.selector.title')}
                                          </h3>
                                            <form className='flex flex-row w-full space-x-4 lg:w-1/2'>
                                              <input
                                                type="text"
                                                className="hidden input-field w-full"
                                                placeholder="Enter e-mail"
                                                onChange = {(e) => setFeedbackProvider(e.target.value)} 
                                                value = {feedbackProvider}
                                              />
                                              {performanceReviewCampaigns[0]?.feedbackType?.external === true ?
                                                // Allow employees and external
                                                <CreatableSelect
                                                  className='w-full text-xs'
                                                  styles={Configs.formDropdownStyle}
                                                  isSearchable
                                                  placeholder={t('enter-email')}
                                                  options={companyOrg?.map((element) => (
                                                    {value: element.emails[0], label: element.employee}
                                                  ))}
                                                  onChange={(e) => setFeedbackProvider(e.value)}
                                                />
                                              :
                                                // Only employees
                                                <Select 
                                                  className='w-full text-xs'
                                                  styles={Configs.formDropdownStyle}
                                                  isSearchable
                                                  placeholder={t('enter-email')}
                                                  options={companyOrg?.map((element) => (
                                                    {value: element.emails[0], label: element.employee}
                                                  ))}
                                                  onChange={(e) => setFeedbackProvider(e.value)}
                                                />
                                            }

                                              <button className="save-button" onClick={(e) => addFeedbackProvider(e)}>
                                                {t('request')}
                                              </button> 
                                            </form>

                                            {incorrectFormat ? <div className='italic text-bold text-red-900'>Incorrect e-mail format</div> : null}
                                            {providerAdded ? <div className='italic text-bold text-green-800'>Feedback provider added correctly</div> : null}
                                            {existingProvider ? <div className='italic text-bold text-red-900'>Provider already exists</div> : null}

                                        </div>
                                    </React.Fragment>
                                    : 
                                      null 
                                    }
                          


                                    {/* List of providers */}
                                    {(performanceReviewCampaigns[0]?.feedbackType?.peers === true) || (performanceReviewCampaigns[0]?.feedbackType?.external === true) 
                                    || (performanceReviewCampaigns[0]?.feedbackType["employee-manager"] === true && performanceFeedbackProviders?.length > 0)  ?
                                    // Peer feedback or external feedback or employee-manager feedback and a manager
                                      <div className="flex flex-col items-start text-left mt-2 ml-2 m-1 mt-12 space-y-4">
                                        <Tooltip content = {t('performance.home.feedback.requested.description')}>
                                          <h2 className="subsection-title"> {t('performance.home.feedback.requested.title')}</h2>
                                        </Tooltip>

                                          { isPerformanceFeedbackProvidersLoading ? 
                                            <Loader />
                                          :
                                            <React.Fragment>
                                              {performanceFeedbackProviders?.length === 0 || performanceFeedbackProviders === null ? 
                                                  //  Empty list of providers
                                                  <div className='text-center title-description italic'> 
                                                    {t('performance.home.feedback.add')} 
                                                  </div>
                                                :
                                                  <div className="flex flex-col w-full">
                                                        {/* List of providers */}
                                                        {/* <button className='underline' onClick={(e, index) => deleteProvider(index)}>{t('delete')}</button> */}
                                                        {performanceFeedbackProviders?.map((element, index) => (
                                                            element?.type === "employee"  ?
                                                              // Existing employee
                                                              <UserListItem user={companyOrg?.find(x => parseInt(x.employeeID) === parseInt(element?.employeeID))?.employee}
                                                                area={companyOrg?.find(x => parseInt(x.employeeID) === parseInt(element?.employeeID))?.area}
                                                                subarea={companyOrg?.find(x => parseInt(x.employeeID) === parseInt(element?.employeeID))?.subarea}
                                                                role={companyOrg?.find(x => parseInt(x.employeeID) === parseInt(element?.employeeID))?.role}
                                                                level={companyOrg?.find(x => parseInt(x.employeeID) === parseInt(element?.employeeID))?.level}
                                                              />
                                                            :
                                                              // External                                     
                                                              <UserListItem user={element?.email} />
                                                        ))}
                                                  </div>
                                              }
                                            </React.Fragment>
                                          }
                                      </div>

                                      :

                                      null 

                                    }
                              </div>

                            : null
                            }
                                      

                            {/** 
                             * Button link changes depending on the type of performance review
                             */}
                            <div className="flex flex-row justify-center md:justify-end w-auto mb-12 mt-4">
                                  {params ?
                                            <button className="save-button" onClick={(e) => nextStepManager(e)}>
                                              {t('continue')}
                                            </button>
                                    :
                                          <button className="save-button" onClick={(e) => nextStepIC(e)}>
                                            {t('continue')}
                                          </button>
                                  }
                            </div>
                            <SectionSeparator />
                      
                        </div>
                      </div>
                      </React.Fragment>
                    }
                  </div>
                <div className='right-small-view'>
                  <PerformanceHistory companyIDinput={companyID} employeeIDinput={params ? params?.employeeID : employeeID}/>
                </div>
                </div>
              </div>
          </React.Fragment>
        );
  }
}

export default PerformanceHome;

