// React
import React, {useLocation, useState, useEffect} from 'react';

// Common & Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import PulseHeader from './pulseHeader';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';

// 3P
import CryptoJS from 'crypto-js';
import i18next, { t } from 'i18next';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';

function PulseQuestions () {
    var currentCampaigns = sessionStorage.getItem('currentCampaigns');
    var [pulseCampaigns, setPulseCampaigns] = useState(currentCampaigns ? CryptoJS.AES.decrypt(currentCampaigns, Configs.privateKey).toString(CryptoJS.enc.Utf8) : null);
    const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const {token} = useAuth();

    // Toaster & Loader
    const [isLoading, setIsLoading] = useState(false);
    const [toaster, setToaster] = useState(null);

    if (Configs.devEnvironment) console.log ("DEV ONLY - Campaigns = ", pulseCampaigns);

    // ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard

      }, []);


    // ---------------------------------------------------------------------------------------------------
    // API calls
    // ---------------------------------------------------------------------------------------------------
    
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
            <Header />
            <Bot />

      <div className="flex flex-col m-6 lg:ml-[14.5rem]">
        <PulseHeader />

        <div className="flex flex-col text-left">
            {pulseCampaigns?.map((campaign, index) => (
                <div className="flex flex-col mt-2 lg:ml-2 m-1">


                    <h2 className="flex text-xl mb-4 font-bold"> {t('pulse.campaign-name')}{campaign.campaignName} </h2>
                    <h2 className="flex text-l mb-4">  {t('pulse.campaign-type')}{campaign.type}</h2>
                    <span className='flex mb-4'>{t('pulse-questions.description')}</span>

                    <h3 className='flex text-sm mb-4'>{t('pulse.next-question')}: {campaign?.nextQuestion[0]?.Question} <br></br>(ID: {campaign?.nextQuestion[0]?._id}) </h3>

                    <table className="mr-2 w-full border border-black text-sm text-left mb-4">
                        <thead>
                        <tr className="bg-black	text-white">
                            <th className="border border-white p-2"># </th>
                            <th className="border border-white p-2">{t('category')} </th>
                            <th className="border border-white p-2">{t('question')} </th>
                            <th className="border border-white p-2">{t('frequency')} </th>
                            <th className="border border-white p-2">{t('last-asked')} </th>

                        </tr>
                        </thead>
                        <tbody>
                        {campaign?.questions?.map((question, index) => (
                            <tr>
                              <td className="border border-black p-2 text-sm">
                                  {index+1}
                               </td>
                               <td className="border border-black p-2 text-sm">
                                  {question?.question[0]?.Category}
                               </td>

                                <td className="border border-black p-2 text-sm">
                                 {i18next.language === 'es' ? question?.question[0]?.Question_ESP : question?.question[0]?.Question}
                                </td>
                                <td className="border border-black p-2 text-sm">
                                {t(question?.frequency)}
                                </td>
                                <td className="border border-black p-2 text-sm">
                                {new Date(question?.last_asked).toDateString()}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <SectionSeparator />
                </div>
            ))}
        </div>
        </div>
      </React.Fragment>
    );
}

export default PulseQuestions;