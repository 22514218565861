// React imports
import React, { useState, useEffect, useTransition} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Imports from 3rd party libraries
import CryptoJS from 'crypto-js';
import { DiscreteColorLegend } from 'react-vis';
import Select from 'react-select';

// Common
import Configs from "../../Configs";
import Header from '../../common/header';
import PulseHeader from './pulseHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import PulseFilterWidget from '../../widgets/data/pulseFilterWidget';
import Pagination from '../../widgets/pagination';
import { useData } from '../../common/dataContext';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

import KeyIndicatorItem from '../../widgets/keyIndicatorItem';
import QuestionListItem from '../../widgets/questionListItemNew';
import SectionSeparator from '../../widgets/sectionSeparator';

// Icons
import {AiOutlineDownload, AiOutlineFilter} from 'react-icons/ai';
import {IoIosHelpCircleOutline} from 'react-icons/io';

// i18n
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';


// ---------------------------------------------------------------------------------------------------
function PulseHome () {
  // Navigate
  const navigate = useNavigate();
  const {t} = useTranslation();

  // Employee Info
  const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
  const { token, companyID, employeeID, securityLevel } = useAuth();
  const {companyPrefereneces, toasterMessage, setToasterMessage} = useData();

  const {pulseKeyIndicators, isPulseKeyIndicatorsLoading, getPulseKeyIndicators} = useData();
  const {pulseHomeGraphFrontendData, isPulseHomeGraphFrontendDataLoading, getPulseHomeGraphFrontendData} = useData();

  // Initialize frontend data variables
  // KPIs
  const [modifiedKPIs, setModifiedKPIs] = useState(pulseKeyIndicators);
  
  // State variables
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [modifiedData, setModifiedData] = useState(null);

  // Line colors
  // const scoreColorLine = companyPrefereneces?.personalization?.emailsHeaderColor ? companyPrefereneces?.personalization?.emailsHeaderColor : Configs.companyColor;
  // const benchmarkColorLine = companyPrefereneces?.personalization?.emailsHeaderSecondaryColor ? companyPrefereneces?.personalization?.emailsHeaderSecondaryColor : Configs.companyTerciaryColor;
  const scoreColorLine = "#4F46E5";
  const benchmarkColorLine = "#FFA500";

  // Legend data
  const LEGENDITEMS = [
    {title: t('score'), color: scoreColorLine},
    {title: t('company-benchmark'), color: benchmarkColorLine}
  ];

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default number of rows per page
  
  if (Configs.devEnvironment) console.log("[pulseHome.js][main()] - securityLevel: ", securityLevel);


// ---------------------------------------------------------------------------------------------------
// After loading the view
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      // Check security level - if only IC redirect Home - YOU SHOULD NOT BE HERE
      if (securityLevel?.length === 1 && securityLevel[0] === Configs.securityLevelIC) navigate("/");

      // Get information to populate dashboard
      if (pulseKeyIndicators === null && !isPulseKeyIndicatorsLoading) {
        getPulseKeyIndicators();
      } else {
        if (pulseKeyIndicators?.length > 0) {
          setModifiedKPIs(pulseKeyIndicators);
          handleKPIsFilterChange(pulseKeyIndicators);
        }
      }
      if (pulseHomeGraphFrontendData === null && !isPulseHomeGraphFrontendDataLoading) {
        getPulseHomeGraphFrontendData();
      } else {
        if(pulseHomeGraphFrontendData?.length > 0) {
          setModifiedData(pulseHomeGraphFrontendData);
          handleQuestionsFilterChange(pulseHomeGraphFrontendData);
        }
      }

    }, []);

// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------
  

// ---------------------------------------------------------------------------------------------------
// Download methods
async function downloadCampaign (campaign, e) {
  e.preventDefault();

  // fetch from server      
  fetch(Configs.platformGetCSVDownloadCampaignAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + selectedCampaign, {
    method: 'get',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${token}` // notice the Bearer before your token
    }})
      .then((response) => {
        if (response.status === 200) {
            return response.blob();
        } else if (response.status === 400) {
          // Something went wrong - inform user
          setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});

        } else if (response.status === 401) {
          // Force logout
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
        } else if (response.status === 403) {
          // Force logout
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
          // sessionStorage.clear();
          navigate("/");
        } else {
          // There is an error - delete info
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = companyInfo?.companyName + ' - ' + t('Pulse') + ' - ' + campaign?.campaignName + '.csv';
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(url);
      })

      .catch((error) => {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        console.error("[pulseHome.js][getPulseKeyIndicators()] Error fetching data: ", error);
      });
}
// ------------------------------------------------------------
// Pagination methods
// ------------------------------------------------------------
 // Calculate the indices of the first and last row on the current page
 const indexOfLastRow = currentPage * rowsPerPage;
 const indexOfFirstRow = indexOfLastRow - rowsPerPage;
 const currentRows = modifiedData?.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        if (Configs.devEnvironment) console.log("[pulseHome.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page since the number of pages will change
    };

// ---------------------------------------------------------------------------------------------------
// Filtering methods
// ---------------------------------------------------------------------------------------------------
const handleQuestionsFilterChange = (filteredDataInput) => {
  
  let auxFilteredData = [...filteredDataInput];
  if (Configs.devEnvironment) console.log("[pulseHome.js][handleQuestionsFilterChange()] filteredDataInput pre-processed: ", auxFilteredData);

  // Choose campaign
  setSelectedCampaign(auxFilteredData[0]?.campaignID);

  // Prepare data --> auxFilteredData has to be processed to add up all questions and answers
  let questionsMap = new Map();

  auxFilteredData?.forEach(dataPoint => {
    dataPoint?.questions?.forEach(question => {
      // Create a key
      let key = `${question?.question?._id?.toString()}-${question?.questionDate}`;

      if (questionsMap.has(key)) {
        // Question exists - add answers to the existing question and recalculate
        let questionAux = {...questionsMap.get(key)};

        // Add answers or sum to existing answers
        question?.answers.forEach(answer => {

          if (answer?.answerID === questionAux?.answers?.find(a => parseInt(a?.answerID) === parseInt(answer?.answerID))?.answerID) {
            // Add count to existing answer            
            // Sum to existing answerIDSum
            questionAux.answers = questionAux.answers.map(a => 
              a.answerID === answer.answerID  ? { ...a, answerIDCount: a.answerIDCount + answer.answerIDCount, answerIDSum: a.answerIDSum + answer.answerIDSum }: a);
            questionAux.answerCount += parseInt(answer?.answerIDCount);
            questionAux.answerSum += parseInt(answer?.answerIDSum);

          } else {
            // Add new answer to array
            questionAux.answers =[...questionAux.answers, answer];
            questionAux.answerSum += parseInt(answer?.answerIDSum);
            questionAux.answerCount += parseInt(answer?.answerIDCount);
          }
        });

        // Update question in Map
        questionsMap.set(key, questionAux);

      } else {
        // Add new question
        // Prepare object to add to Map
        var newQuestion = {
          question: question?.question,
          answers: question?.answers,
          companyScore: question?.companyScore,
          questionDate: question?.questionDate,
          answerCount: parseInt(question?.answerCount),
          answerSum: parseInt(question?.answerSum),
          answerScore: question?.answerScore,
        }
        
        questionsMap.set(key, newQuestion);
      }
    });    
  });

  // Convert Map back to array if needed
  let questionsArray = Array.from(questionsMap.values());

  // Calculate final scores
  questionsArray.forEach(question => {
    question.answerScore = question.answerSum / question.answerCount;
  });

  if (Configs.devEnvironment) console.log("[pulseHome.js][handleQuestionsFilterChange()] filteredDataInput post-processed: ", questionsArray);

  setModifiedData([...questionsArray]);
};

const handleKPIsFilterChange = (filteredDataInput) => {
  if (Configs.devEnvironment) console.log("[pulseHome.js][handleKPIsFilterChange()] - filteredDataInput: ", filteredDataInput);

  let auxFilteredData = [...filteredDataInput];

  if (Configs.devEnvironment) console.log("[pulseHome.js][handleKPIsFilterChange()] - auxFilteredData:", auxFilteredData);


  // Transform body into KPIs to iterate through
    if (Configs.devEnvironment) console.log("[pulseHome.js][handleKPIsFilterChange()] - starting");

    // Create a new object to store the KPIs
      const intermediateResult = Object.values(auxFilteredData?.reduce((acc, obj) => {
        const KPI = obj.KPI;
        
        // Check if the KPI already exists in the accumulator
        if (!acc.hasOwnProperty(KPI)) {
          // If it doesn't exist, create a new subarray for the KPI
          acc[KPI] = [];
        }
        
        // Push the object to the subarray corresponding to its KPI
        acc[KPI].push(obj);
        
        if (Configs.devEnvironment) console.log("[pulseHome.js][handleKPIsFilterChange()] - acc: ", acc);
        return acc;

      }, {}));

      // Iterate through entire intermediateResult and calculate the average of scores for the same questionDate
      // Create a new object to store the KPIs
      var result = [];

      if (Configs.devEnvironment) console.log("[pulseHome.js][handleKPIsFilterChange()] - intermediateResult: ", intermediateResult);

      // Iterate through each KPI
      intermediateResult?.forEach(KPIArray => {
          var KPIarray = [];

          // Within each KPI, we need to calculate the average of scores for the same questionDate - and keep only one entry per questionDate
          var questionDateMap = new Map();

          KPIArray?.forEach(KPI => {
            // Create a key
            let key = KPI?.questionDate;

            if (questionDateMap.has(key)) {
              // QuestionDate exists - add scores to the existing questionDate and recalculate
              let questionDateAux = {...questionDateMap.get(key)};
              questionDateAux.KPI = KPI?.KPI;
              questionDateAux.answerCount += KPI?.answerCount;
              questionDateAux.answerSum += KPI?.answerSum;
              questionDateAux.companyBenchmarkScore = KPI?.companyBenchmarkScore;
              questionDateAux.questionDate = KPI?.questionDate;
              questionDateAux.questionDay = KPI?.questionDay;
              questionDateAux.questionMonth = KPI?.questionMonth;
              questionDateAux.questionYear = KPI?.questionYear;
              questionDateAux.question_ESP = KPI?.question_ESP;
              questionDateAux.question = KPI?.question;
              questionDateMap.set(key, questionDateAux);

            } else {
              // Add new questionDate
              // Prepare object to add to Map
              var newQuestionDate = {
                KPI: KPI?.KPI,
                answerCount: KPI?.answerCount,
                answerSum: KPI?.answerSum,
                answerScore: KPI?.answerScore,
                companyBenchmarkScore: KPI?.companyBenchmarkScore,
                questionDate: KPI?.questionDate,
                questionDay: KPI?.questionDay,
                questionMonth: KPI?.questionMonth,
                questionYear: KPI?.questionYear,
                question_ESP: KPI?.question_ESP,
                question: KPI?.question
              }
              
              questionDateMap.set(key, newQuestionDate);
            }
          });

        // Convert Map back to array if needed
        const resultAux = Array.from(questionDateMap.values());

        // Calculate final scores
        resultAux.forEach(questionDate => {
          questionDate.answerScore = questionDate.answerSum / questionDate.answerCount;
        });

        // Add to KPI array
        KPIarray = [...resultAux];

        // Add to result
        result.push(KPIarray);
      });

      if (Configs.devEnvironment) console.log("[pulseHome.js][handleKPIsFilterChange()] - result: ", result);

      // Set state
      setModifiedKPIs([...result]);
  
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
    
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="main">

        <PulseHeader />
            
        <div className='flex flex-row items-start'>
        <div className="left-big-view">

        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        <div className="flex flex-col text-left ">
          {/* KPIs section */}
          <div className=''>
            <div className="flex flex-row items-center justify-start mt-2 md:ml-2 m-1">
              <Tooltip content={t('pulse.home.satisfaction-indicators.description')}>
                <div className="section-title">
                  {t('pulse.home.satisfaction-indicators.title')}
                </div>
              </Tooltip>

              {i18next.language === 'es' ? 
                  <a href="https://resources.kincode.app/es/manager/pulse.html" target="_blank" rel="noreferrer">
                    <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                  </a>
                : 
                  <a href="https://resources.kincode.app/manager/pulse.html" target="_blank" rel="noreferrer">
                    <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                  </a>
                }
            </div>

            {isPulseKeyIndicatorsLoading ? 
              <Loader />
            :
                <React.Fragment>
                      {pulseKeyIndicators && pulseKeyIndicators?.length > 0 ? 
                        <div className="flex flex-col">
                          {/* Filters */}
                          <div className="flex flex-row items-center justify-start m-1">
                              <PulseFilterWidget
                                  onFilterChange={handleKPIsFilterChange}
                                  orgData={pulseKeyIndicators}
                                  defaultData={pulseKeyIndicators}
                                  defaultTeamTypeFilter={securityLevel?.includes(Configs.securityLevelHR) ? "company" : "directs"}
                                  teamTypeFilter={true}
                                  managerFilter={true}
                                  areaFilter={true}
                                  subareaFilter={true}
                                />
                            </div>
                          <div className="flex flex-wrap justify-left items-start" id="pulse-KPIs">
                              {/* KPI cards */}
                              {modifiedKPIs?.map((KPIArray, index) => (
                                  <div key={index} className='m-2'> 
                                    <KeyIndicatorItem 
                                      indicator={t(KPIArray[0]?.KPI)} 
                                      question={{question_ESP: KPIArray[0]?.question_ESP, question: KPIArray[0]?.question}} 
                                      data={KPIArray} 
                                      collapsed={false}/>
                                  </div>
                                ))}
                                {/* Color Legend */}
                                <div className="flex flex-row justify-center lg:justify-end lg:mr-80 items-center">
                                  <DiscreteColorLegend className="mt-4 text-center border" orientation="horizontal" height={60} width={250} items={LEGENDITEMS}/>
                                </div>
                          </div>
                          
                        </div>
                      : 
                          <React.Fragment >
                            
                            <div className="flex flex-col items-center mt-8 md:ml-2 m-1" id="pulse-KPIs-empty">
                              <span className="title-description italic">📊 {" " + t('pulse.home.pulse-kpis.empty')}{" "}
                              </span>
                            </div>
                          </React.Fragment>
                      }
                </React.Fragment>
              }
          </div>        

          <SectionSeparator />

          {/* Questions section */}
          <div className="flex flex-col items-start md:ml-2 m-1">
            <Tooltip content={t('pulse.home.pulse-campaigns.description')}>
              <h2 className="section-title"> {t('pulse.home.pulse-campaigns.title')} </h2>
            </Tooltip>
          </div>

          {isPulseHomeGraphFrontendDataLoading ? 
              <Loader />
            :
              <React.Fragment>
                    
              {pulseHomeGraphFrontendData ? 
                  <React.Fragment>
                      {/* Filters */}
                      <div className="flex flex-row items-center justify-start m-1">
                          <PulseFilterWidget
                              onFilterChange={handleQuestionsFilterChange}
                              orgData={pulseHomeGraphFrontendData}
                              defaultData={pulseHomeGraphFrontendData}
                              defaultTeamTypeFilter={"directs"}
                              pulseCampaignFilter={true}
                              teamTypeFilter={true}
                              managerFilter={true}
                              areaFilter={true}
                              subareaFilter={true}
                              categoryFilter={false}
                              subcategoryFilter={false}
                            />
                        </div>



                      {/* Questions */}
                      <div className="flex flex-row justify-end mx-1 md:mx-2">
                          <Tooltip content={t('pulse.home.download')}>
                            <button className='save-button px-4 py-2' onClick={(e) => downloadCampaign(selectedCampaign?._id, e)}>
                              <AiOutlineDownload className='text-xl' />
                            </button>
                          </Tooltip>
                      </div>

                        {currentRows?.map((question, index) => (
                            !question ? null : 
                            
                            <div className="flex flex-col" key={index}>
                              {index === 0 ?
                                <QuestionListItem question={question} collapsed={false}/>
                              :
                                <QuestionListItem question={question} collapsed={true}/>
                              }  
                            </div>
                          ))}

                          {/* Pagination */}
                          <Pagination 
                            totalRows={modifiedData?.length} 
                            rowsPerPage={rowsPerPage} 
                            currentPage={currentPage} 
                            onPageChange={handlePageChange} 
                            handleRowsPerPageChange={handleRowsPerPageChange}/>
                            
                    <SectionSeparator />
                  </React.Fragment>
                  :
                  <React.Fragment>
                      <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                        <span className="title-description italic">🗂️ {" " + t('pulse.home.pulse-campaigns.empty')}{" "}
                        {/* <Link to="/pulse/history" className="title-description underline" >{t('pulse.home.pulse-campaigns.empty.cta')} </Link> */}
                        </span>
                      </div>
                  </React.Fragment>


                }

              </React.Fragment>
          }
        </div>
        </div>
        <div className='right-small-view'>
          {/* Test */}
        </div>
      </div>
      </div>
    </React.Fragment>
    );
}

export default PulseHome;