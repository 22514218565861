export const headersInfo = [
    {icon:"Home", url:"/", securityLevel: 1},
    // Develop
    {icon:"Recruitment", url:"/recruitment/interviews", securityLevel:2},
    {icon:"Onboarding", url:"/onboarding/", securityLevel:1},
    {icon:"Career", url:"/career/", securityLevel:1},
    {icon:"Development", url:"/development/", securityLevel:1},
    // {icon:"Learning", url:"/learning/", securityLevel:1},
    // Perform
    {icon:"Performance-Review", url:"/performance/self/", securityLevel:1},
    {icon:"Calibration", url:"/calibration/", securityLevel:3},
    {icon:"Goals", url:"/goals/", securityLevel:1},
    // Engage
    {icon:"Pulse", url:"/pulse/", securityLevel:3},
    {icon:"1on1", url:"/oneonone/", securityLevel:1},
    {icon:"Recognition", url:"/recognition/", securityLevel:1},
    // {icon:"Feedback", url:"/feedback/", securityLevel:1},
    // Basics    
    {icon:"Organigram", url:"/organigram/", securityLevel:1},
    {icon:"Profile", url:"/profile/", securityLevel:1},
    {icon:"Time-off", url:"/timeoff/", securityLevel:1},
    {icon:"PunchInOut", url:"/punch/", securityLevel:1},
    {icon:"Inbox", url:"/inbox/", securityLevel:1},
    {icon:"Admin", url:"/admin/", securityLevel:99999},
  ];
  