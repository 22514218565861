// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';

// Icons
import { BsPersonBadgeFill} from "react-icons/bs";

// -----------------------------------------------------------------
function ProfileHeader () {
    
    const screens = [
        {view:t('profile.personal-info.home.title'), url:"/profile/", securityLevel: [Configs.securityLevelIC], id:"profile.header.personal-info"},
        //{view:"Job info", url:"/profile/job", securityLevel:1},
        //{view:"Compensation", url:"/profile/comp", securityLevel:1},
        //{view:"Time off", url:"/profile/timeoff", securityLevel:1}
    ];
    
    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('Profile')} Icon={BsPersonBadgeFill} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default ProfileHeader;