// React 
import React, { useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Common
import Header from '../../common/header';
import OrganigramHeader from './organigramHeader';
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import FilterWidget from '../../widgets/data/filterWidget';
import DataTable from '../../widgets/dataTable';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// 3P
import CryptoJS from 'crypto-js';
import {useTranslation} from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import { IoPersonAdd } from 'react-icons/io5';
import {GiOrganigram} from 'react-icons/gi';

// ---------------------------------------------------------------------------------------------------
function OrganigramEmployeeList () {
  // 3P
    const navigate = useNavigate();
    const {t} = useTranslation();

    const {token, securityLevel, companyID, employeeID} = useAuth();
    const {companyOrg, getMyCompanyOrg, isCompanyLoading, setSelectedProfile} = useData();
    const {toasterMessage, setToasterMessage} = useData();

    // Data to populate
    const [filteredData, setFilteredData] = useState(null);

    // DataTable 
    // Create column names array 
    let columnNamesValuesTable = [
      {type:'number',id: 'employeeID', value: 'employeeID', label: "#", sort: null, filter: null},
      {type:'string',id: 'name', value: 'name', label: t('name'), sort: null, filter: null},
      {type:'string',id: 'surname',value: 'surname', label: t('surname'), sort: null, filter: null},
      {type:'string',id: 'manager',value: 'manager', label: t('manager'), sort: null, filter: null},
      {type:'string',id: 'area',value: 'area', label: t('area'), sort: null, filter: null},
      {type:'string',id: 'subarea',value: 'subarea', label: t('subarea'), sort: null, filter: null},
      {type:'action',id: 'action',value: 'action', label: t('action'), sort: null, filter: null},
    ];

    if (Configs.devEnvironment) console.log("[organigramEmployeeList.js] companyOrg: ", companyOrg);

    // Methods to load when page is loaded
    useEffect (() => {

      setFilteredData(companyOrg);

      }, []);      


    // ---------------------------------------------------------------------------------------------------
    // API calls
    // ---------------------------------------------------------------------------------------------------
    async function deleteEmployeeAPI(employeeIDToDelete) {
      
        if (Configs.devEnvironment) console.log("[organigramEmployeeList.js][deleteEmployee()] employee to delete: ", employeeIDToDelete);
  
        // fetch from server only if we have meetingID
        // HTTP POST request options
          const requestOptions = {
              // method: 'DELETE',
              method: 'GET',
              credentials: 'include',
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
              // body: JSON.stringify({ 
              //   employeeID: employeeID,
              //   companyID: companyID,
              //   employeeIDToDelete: employeeIDToDelete,
              // })
          };
  
        // Post to server      
        fetch(Configs.platformDeleteEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&employeeIDToDelete=" + employeeIDToDelete, requestOptions)
        .then((response) => {
          // console.log (response);
          if (response.status === 200) {
              // All good
              // navigate('/oneonone/');
              // sessionStorage.removeItem('performanceReviewDraft');
              if (Configs.devEnvironment) console.log("[organigramEmployeeList.js][deleteEmployee()] - Employee deleted successfully");
              setToasterMessage({message: t('success'), type: Configs.successToaster});
              getMyCompanyOrg();
            } else if (response.status === 400) {
                // Something went wrong - inform user
                setToasterMessage({message: t('warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 409) {
                setToasterMessage({message: t('warning'), type: Configs.warningToaster});
            } else {
                //
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                throw response;
            }
        })
        .catch((error) => {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
          console.log("[organigramEmployeeList.js][deleteEmployee()] Error fetching data: ", error);
        });
      

    }

// ---------------------------------------------------------------------------------------------------
// Navigation methods
// ---------------------------------------------------------------------------------------------------
async function navigateToProfile (index, row) {
  if (Configs.devEnvironment) console.log("[organigramEmployeeList.js][navigateToProfile()] employeeID: ", row);

  if (row?.employeeID === employeeID) {
    setSelectedProfile(null);
    navigate("../profile/");
  } else {
      const employee = companyOrg.find( (element) => element.employeeID === row?.employeeID);
      setSelectedProfile(employee);
      navigate("../profile/");
  }

}

async function editEmployee (index, row) {
  if (Configs.devEnvironment) console.log("[organigramEmployeeList.js][editEmployee()] employeeID: ", employeeID);
  // Navigate to edit
  navigate("/organigram/employees/edit/" + encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
      employeeID: row?.employeeID,
      companyID: row?.companyID,
  }),Configs.privateKey).toString()));
}

async function deleteEmployee (index, row) {
  // Log
  if (Configs.devEnvironment) console.log("[organigramEmployeeList.js][deleteEmployee()] employeeID: ", employeeID);
      
  // Confirmation window
  if (!window.confirm(t('organigram.list.delete-employee.confirm'))) {    
    // Stay
    if (Configs.devEnvironment) console.log ("[organigramEmployeeList.js][deleteEmployee()] cancel");
  } else {
    // I'm sure, proceed
    deleteEmployeeAPI(row.employeeID);
  }
}

// ---------------------------------------------------------------------------------------------------
// Filter methods
// ---------------------------------------------------------------------------------------------------   
const handleFilterChange = (filteredDataInput) => {
  if (Configs.devEnvironment) console.log("[organigramEmployeeList.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
  setFilteredData(filteredDataInput);
};

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
setToasterMessage(null);
};

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
return (
      <React.Fragment>
      <Header />

      <Bot/>

     <div className="main">

        <OrganigramHeader />

        <div className="flex flex-col m-2 lg:m-4">

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

          <div className="flex flex-col text-left ">
                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                        <h2 className="flex text-xl text-left mb-4 ">
                          {" "}
                          <div className='flex flex-col md:flex-row justify-left'>
                            <Tooltip content={t('organigram.list.description')} >
                              <div className='section-title'>
                                {t('organigram.list.title')}
                              </div>
                            </Tooltip>
                          </div>
                        </h2>
                  </div>
                  
                  {/* Employee actions - Create new employee, or act in bulk */}
                  { securityLevel?.includes(Configs.securityLevelHR) ?
                          <React.Fragment>
                            <div className="flex flex-row justify-center lg:justify-end m-4 space-x-4">
                                {/* Create employee */}
                                <Link to="/organigram/employees/create">
                                    <button className='save-button flex flex-row items-center'>
                                      <IoPersonAdd className='mr-2' />
                                            {t('organigram.list.create-single-employee')}
                                    </button>
                                </Link>

                                {/* Bulk actions - Re-org */}
                                <Link to="/organigram/employees/reorganize">
                                    <button className='save-button flex flex-row items-center'>
                                      <GiOrganigram className='mr-2' />
                                            {t('organigram.list.re-organize')}
                                    </button>
                                </Link>
                            </div>
                          </React.Fragment>

                      : 
                          null
                  }

                  {isCompanyLoading ?
                          <Loader />
                      :

                        <React.Fragment>
                            <div className='flex flex-col'>
                                  
                                  <FilterWidget data={companyOrg}
                                      onFilterChange={handleFilterChange}
                                      teamTypeFilter={false} defaultTeamTypeFilter={"company"}
                                      managerFilter={true}
                                      employeeFilter={true}
                                      areaFilter={true} 
                                      subareaFilter={true}
                                  />

                                  { // HR can Edit and Delete employees
                                  securityLevel?.includes(Configs.securityLevelHR) ?
                                      <DataTable 
                                      data={filteredData} 
                                      columnNames={columnNamesValuesTable} 
                                      title={null}
                                      zerosForDashes={true}
                                      defaultPageLimit={50}
                                      viewMethod={navigateToProfile}
                                      viewTooltip={t('organigram.list.view-employee')}
                                      editMethod={editEmployee}
                                      editTooltip={t('organigram.list.edit-employee')}
                                      deleteMethod={deleteEmployee}
                                      deleteTooltip={t('organigram.list.delete-employee')}  />
                                  :
                                      // Other users can only view employees
                                      <DataTable 
                                      data={filteredData} 
                                      columnNames={columnNamesValuesTable} 
                                      title={null}
                                      zerosForDashes={true}
                                      defaultPageLimit={50}
                                      viewMethod={navigateToProfile}
                                      viewTooltip={t('organigram.list.view-employee')}
                                      />
                                  }

                                  
                            </div>
                            <SectionSeparator />
                      </React.Fragment>
                  }

          </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default OrganigramEmployeeList;