// React
import React, { useState, useEffect } from 'react';

// commons
import Configs from '../Configs';

// Widgets
import ProgressBar from './progressBar';
import Pagination from './pagination';
import Tooltip from '../common/support/tooltip';

// Icons
import { FaSort, FaSortDown, FaSortUp, FaEllipsisH, FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { CgAttachment } from "react-icons/cg";


// 3P
import { useTranslation } from 'react-i18next';


// This is a simple data table component that takes in data and columns as props
// Sort and Filter info is contained in Column object
// Column object: {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
const DataTable = ({ title, data, columnNames, zerosForDashes, defaultSort, onFilter, 
    // Actions props
    deleteMethod, editMethod, viewMethod, viewAttachmentMethod, 
    deleteTooltip, editTooltip, viewTooltip, viewAttachmentTooltip,
    // Pagination props
    defaultPageLimit }) => {
  // Translation
  const {t} = useTranslation();
  // State variables
  let defaultData = data;
  const [modifiedData, setModifiedData] = useState(data);
  const [columns, setColumns] = useState(columnNames || []);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageLimit ? defaultPageLimit : 10); // Default number of rows per page

  if (Configs.devEnvironment) console.log ("[dataTable.js][main()] - columnNames: ", columnNames);
  if (Configs.devEnvironment) console.log ("[dataTable.js][main()]  - data: ", defaultData);

// ------------------------------------------------------------
// After rendering methods
// ------------------------------------------------------------
  useEffect(() => {
    // sortConfig.forEach(sort => {
    //   sortedData = sortedData.sort((a, b) => {
    //     if (a[sort.key] < b[sort.key]) return sort.direction === 'ascending' ? -1 : 1;
    //     if (a[sort.key] > b[sort.key]) return sort.direction === 'ascending' ? 1 : -1;
    //     return 0;
    //   });
    // });

    setModifiedData(data);

    if (columns) prepareColumns(columns);

    // Prepare columns 
  }, [data]);

// ------------------------------------------------------------
// Prepare data for columns
// ------------------------------------------------------------
const prepareColumns = (columns) => {
    // Remove duplicates
    const uniqueColumns = [...new Set(columns)];

    if(Configs.devEnvironment) console.log("[datTable.js][prepareColumns()] - uniqueColumns: ", uniqueColumns);

    // Set state of columns
    setColumns(uniqueColumns);
}

const sortData = (auxColumns) => {
    // Sort data
    const sortedData = [...defaultData];

    // Sort data
    auxColumns?.forEach(column => {
        if (column?.sort?.key && column?.sort?.direction) {
            if (column.type === 'string') {
                sortedData?.sort((a, b) => {
                    // Normalize values to strings and handle null or undefined values
                    const aValue = a[column.id] || "";
                    const bValue = b[column.id] || "";

                    // Compare strings
                    if (aValue < bValue) return column.sort.direction === 'asc' ? -1 : 1;
                    if (aValue > bValue) return column.sort.direction === 'asc' ? 1 : -1;
                    return 0;
                });
            }  else if (column.type === 'percentage' || column.type === 'number') {
                sortedData?.sort((a, b) => {
                    if (a[column.id] < b[column.id]) return column.sort.direction === 'asc' ? -1 : 1;
                    if (a[column.id] > b[column.id]) return column.sort.direction === 'asc' ? 1 : -1;
                    return 0;
                });
            } else if (column.type === 'date') {
                sortedData?.sort((a, b) => {
                    if (new Date(a[column.id]) < new Date(b[column.id])) return column.sort.direction === 'asc' ? -1 : 1;
                    if (new Date(a[column.id]) > new Date(b[column.id])) return column.sort.direction === 'asc' ? 1 : -1;
                    return 0;
                });
            }
        }
    });

    // Set state of modifiedData
    setModifiedData(sortedData);

}

// ------------------------------------------------------------
// Pagination methods
// ------------------------------------------------------------
 // Calculate the indices of the first and last row on the current page
 const indexOfLastRow = currentPage * rowsPerPage;
 const indexOfFirstRow = indexOfLastRow - rowsPerPage;
 const currentRows = modifiedData?.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        if (Configs.devEnvironment) console.log("[dataTable.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page since the number of pages will change
    };


// ------------------------------------------------------------
// Filterin & sorting methods
// ------------------------------------------------------------
  const handleSort = (id) => {
    if (Configs.devEnvironment) console.log("[dataTable.js][handleSort()] - column id: ", id);
    if (Configs.devEnvironment) console.log("[dataTable.js][handleSort()] - id: ", columns.find(column => column.id === id));
    // Implement the logic for handling multiple column sorting
    // Update the sortConfig state accordingly
    const auxColumns = [...columns];

    auxColumns?.forEach(column => {
        if (column?.id === id) {
            if (!column.sort) {
                // Sort is null yet
                column.sort = {key: column.id, direction: 'asc'};
            } else {
                if (column?.sort?.direction === 'asc') {
                    column.sort.direction = 'desc';
                } else if (column?.sort?.direction === 'desc') {
                    column.sort = null;
                } else {
                    column.sort.direction = 'asc';
                }
            }
        } else {
            // If different column from the one chosen do nothing
            // column.sort = null;
        }
    }
    );

    // Set state of columns
    setColumns(auxColumns);

    // Sort data
    sortData(auxColumns);
  };

  const handleFilter = (key, value) => {
    // Handle the filter logic
    // const newFilteredData = data.filter(row => row[key].includes(value));
    // setFilteredData(newFilteredData);
    // if (onFilter) onFilter(newFilteredData);
  };

// ------------------------------------------------------------
// Data formatting
// ------------------------------------------------------------
function formatNumber(num) {
    num = Number(num);
    let str = num.toString();
    // Check if the number is in scientific notation
    if (str.includes('e')) {
      // Convert the number to a non-exponential form
      str = num.toFixed(20).replace(/\.?0+$/, "");
    }
    // Handle other formatting logic, such as rounding, here if necessary
    return str;
  }
  

// ------------------------------------------------------------
// Rendering method
// ------------------------------------------------------------
  return (
    <React.Fragment>
        
        <div className="flex flex-col overflow-x-auto bg-white rounded-lg shadow px-4 py-2">
        {/* Table title */}
        {title && 
        <div className='flex flex-row items-center justify-start'>
            <h3 className='font-bold text-sm text-gray-800 text-left my-4 mx-2'>
                {title}
            </h3> 
        </div>
        }
    
        <table className="mr-2 text-sm text-center mb-4 ">
            <thead>
                <tr className="border-b border-gray-200">
                {columns?.map(column => (
                    column.type === "action" ?
                    <th key={column?.value} className=" p-2">
                        
                    </th>

                    :

                    <th key={column?.value} className=" p-2" onClick={() => handleSort(column?.id)}> 
                            <div className='flex flex-row items-center cursor-pointer text-gray-600' onClick={() => handleSort(column?.id)}>
                                {column.label} 

                                {column?.sort?.direction === 'asc' ?
                                    <FaSortUp className='ml-2' />
                                : column?.sort?.direction === 'desc' ?
                                    <FaSortDown className='ml-2' />
                                : <FaSort className='ml-2' /> 
                                }
                            </div>
                    {/* Add filtering input for each column */}
                    {/* <input onChange={(e) => handleFilter(column.key, e.target.value)} /> */}
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
                {currentRows?.map((row, index) => (
                <tr key={index} className='border-b border-gray-200 p-4 text-xs '>
                    {columns?.map((column, columnKey) => (
                        <td className="p-2 text-left" key={columnKey}>
                            {   column?.type  === 'string' ?
                                column?.translate === true ?
                                    t(row[column?.id])
                                    :   
                                    row[column?.id]
                            :
                                column?.type ==='number' && !(isNaN(row[column?.id]) || row[column?.id] === null) ?
                                    zerosForDashes && (Number(row[column?.id]) === 0) ?
                                        ' - '
                                    :
                                        Number(row[column?.id]) < 10 ? formatNumber(Number(row[column?.id])?.toPrecision(1)) : formatNumber(Number(row[column?.id])?.toPrecision(2))
                            :
                                column?.type==='currency' && !isNaN(row[column?.id]) ?
                                    zerosForDashes && (Number(row[column?.id]) === 0) ?
                                        ' - '
                                    :
                                        row[column.id]?.toPrecision(2) !== undefined ? ('€ ' + formatNumber(row[column.id]?.toPrecision(2))) : ' - '
                            :                            
                                

                                column?.type==='percentage' && !isNaN(row[column?.id]) ?
                                    zerosForDashes && (Number(row[column?.id]) === 0) ?
                                        ' - '
                                    :
                                        row[column.id]?.toPrecision(2) !== undefined ? (formatNumber(row[column.id]?.toPrecision(2)) + '%') : ' - '
                            :
                                column?.type==='date' && row[column?.id] ?
                                new Date(row[column?.id])?.toLocaleDateString()
                            :
                                column.type==='progressBar' && row[column.id] ?
                                <div className='flex flex-row items-center justify-start w-auto'>
                                    <ProgressBar percentage={row[column?.id]} />
                                </div>
                            :
                                column.type === 'delta-percentage' && row[column.id] ?
                                    row[column?.id]?.toPrecision(2) > 0 ?
                                        <div className='flex flex-row items-center justify-start w-auto'>
                                            <span className='text-xs text-green-500'>{formatNumber(row[column?.id]?.toPrecision(2)) + '%'}</span>
                                        </div>
                                    :
                                        <div className='flex flex-row items-center justify-start w-auto'>
                                            <span className='text-xs text-red-500'>{formatNumber(row[column?.id]?.toPrecision(2)) + '%'}</span>
                                        </div>
                            :
                                column.type === 'delta-percentage-inverse' && row[column.id] ?
                                    row[column?.id]?.toPrecision(2) > 0 ?
                                        <div className='flex flex-row items-center justify-start w-auto'>
                                            <span className='text-xs text-red-500'>{formatNumber(row[column?.id]?.toPrecision(2)) + '%'}</span>
                                        </div>
                                    :
                                        <div className='flex flex-row items-center justify-start w-auto'>
                                            <span className='text-xs text-green-500'>{formatNumber(row[column?.id]?.toPrecision(2)) + '%'}</span>
                                        </div>
                            :
                                column.type === 'delta-bps' && row[column.id] ?
                                    row[column?.id]?.toPrecision(2) > 0 ?
                                        <div className='flex flex-row items-center justify-start w-auto'>
                                            <span className='text-xs text-green-500'>{formatNumber(row[column?.id]?.toPrecision(2)) + 'bps'}</span>
                                        </div>
                                    :
                                        <div className='flex flex-row items-center justify-start w-auto'>
                                            <span className='text-xs text-red-500'>{formatNumber(row[column?.id]?.toPrecision(2)) + 'bps'}</span>
                                        </div>
                            :
                                column.type === 'delta-bps-inverse' && row[column.id] ?
                                    row[column?.id]?.toPrecision(2) > 0 ?
                                        <div className='flex flex-row items-center justify-start w-auto'>
                                            <span className='text-xs text-red-500'>{formatNumber(row[column?.id]?.toPrecision(2)) + 'bps'}</span>
                                        </div>
                                    :
                                        <div className='flex flex-row items-center justify-start w-auto'>
                                            <span className='text-xs text-green-500'>{formatNumber(row[column?.id]?.toPrecision(2)) + 'bps'}</span>
                                        </div>
                            :
                                column.type=== 'action' ?
                                // Add the typical 3 dots icon for actions
                                // Open a dropdown with the 'View', 'Edit' and 'Delete' actions
                                <div className='flex flex-row items-center justify-start w-auto space-x-2'>
                                    {viewMethod ?  
                                            <Tooltip content={viewTooltip ? viewTooltip : t('view')}>
                                                <FaEye className='text-lg cursor-pointer text-gray-500 hover:text-black' onClick={() => viewMethod(index, row)}/> 
                                            </Tooltip>
                                        : null}
                                    {editMethod ? 
                                            <Tooltip content={editTooltip ? editTooltip : t('edit')}>
                                                <FaEdit className='text-base cursor-pointer text-gray-500 hover:text-black' onClick={() => editMethod(index, row)}/>
                                            </Tooltip>
                                         : null}

                                    {deleteMethod ? 
                                            <Tooltip content={deleteTooltip ? deleteTooltip : t('delete')}>
                                                <FaTrash className='text-sm cursor-pointer text-gray-500 hover:text-black' onClick={() => deleteMethod(index, row)}/> 
                                            </Tooltip>
                                        :null}

                                    {viewAttachmentMethod && row.fileId ?
                                        <Tooltip content={viewAttachmentTooltip ? viewAttachmentTooltip : t('view-attachment')}>
                                            <CgAttachment className='text-lg cursor-pointer text-gray-500 hover:text-black' onClick={() => viewAttachmentMethod(index, row)}/> 
                                        </Tooltip>
                                    : null}
                                </div>
                                
                            :
                                " - "
                            }
                        </td>
                    ))}
                </tr>

                
                ))}
            </tbody>
        </table>
        </div>

        <Pagination
            totalRows={data?.length}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            handleRowsPerPageChange={handleRowsPerPageChange}
        />

    </React.Fragment>
  );
}


export default DataTable;