// React
import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";

// 3P frameworks
import CryptoJS from 'crypto-js';
import Select from 'react-select';
import { t } from 'i18next';

// Common
import Configs from '../../Configs';
import Header from '../../common/header';
import PulseHeader from './pulseHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import QuestionListItem from '../../widgets/questionListItem';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineDownload, AiOutlineFilter} from 'react-icons/ai';
import { BsStars } from 'react-icons/bs';

function PulseCampaign () {
    let { campaignID } = useParams();
    const navigate = useNavigate();

    // Initialize frontend data variables
    // FrontendData will be handling the presented info, and the rest are variables to store the original data. This is done to avoid having to fetch the data again when the user filters the data
    const [pulseCampaign, setPulseCampaign] = useState(null);
    const [pulseHomeDirectsFrontendData, setPulseHomeDirectsFrontendData] = useState(null);
    const [pulseHomeGraphFrontendData, setPulseGraphHomeFrontendData] = useState(null);
    const [pulseHomeOrgFrontendData, setPulseHomeOrgFrontendData] = useState(null);

    const [pulseCampaignAISummary, setPulseCampaignAISummary] = useState(null);

    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const {employeeID, companyID, token, securityLevel} = useAuth();

    const {toasterMessage, setToasterMessage} = useData();

    // Toaster
    const [areCampaignsLoading, setAreCampaignsLoading] = useState(false);
    const [isPulseGraphLoading, setIsPulseGraphLoading ] = useState(false);
    const [isPulseOrgLoading, setIsPulseOrgLoading ] = useState(false);

    const [isAISummaryLoading, setIsAISummaryLoading] = useState(false);

    // Filtering options
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filteredManager, setFilteredManager] = useState(null);
    const [managersOptions, setManagersOptions] = useState([]);

    if (Configs.devEnvironment) console.log ("[pulseCampaign.js][main()] DEV ONLY - Campaigns = ", campaignID);
    if (Configs.devEnvironment) console.log ("[pulseCampaign.js][main()] DEV ONLY - Company Info = ", companyInfo);


// ---------------------------------------------------------------------------------------------------
// After loading the view
// ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    useEffect (() => {
        // Get information to populate dashboard
        if ((securityLevel.includes(Configs.securityLevelManager) 
        || securityLevel.includes(Configs.securityLevelHR) 
        || securityLevel.includes(Configs.securityLevelExec)
        || securityLevel.includes(Configs.securityLevelAdmin))  &&  pulseCampaign === null) getCampaign();
        if ((securityLevel.includes(Configs.securityLevelManager)
        || securityLevel.includes(Configs.securityLevelHR)
        || securityLevel.includes(Configs.securityLevelExec)
        || securityLevel.includes(Configs.securityLevelAdmin)
        )  &&  pulseHomeGraphFrontendData === null) getPulseHomeGraphFrontendData();
        if (pulseHomeOrgFrontendData === null) getPulseHomeOrgFrontendData();

        if (pulseCampaignAISummary === null) getPulseCampaignAISummary();

      }, []);


// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------

    async function getCampaign () {
        // Start loader
        setAreCampaignsLoading(true);

        // fetch from server -- Requires manager permission      
        fetch(Configs.platformGetPulseCampaignIDAnswersDirectsAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
          }})
            .then((response) => {
                // We received a response, stop loading
                setAreCampaignsLoading(false);

              if (response.status === 200) {
                // We have a match
                response.json()
                .then ( body => {
                    if (Configs.devEnvironment) console.log("[pulseCampaign.js][getCampaign()]  Campaigns from getCampaigns(): ", body);
                    if (body?.length > 0) setPulseCampaign(body);
                    setPulseHomeDirectsFrontendData(body);
                    setToasterMessage({message:(t('success')), type: Configs.successToaster});
                  })
                .catch(error => {
                    console.error("[pulseCampaign.js][getCampaign()] Error parsing response JSON", error);
                    setToasterMessage({message: t('error'), type: Configs.errorToaster});
                    setPulseCampaign(null);
                    setPulseHomeDirectsFrontendData(null);
                });
                } else if (response.status === 400) {
                // Something went wrong - inform user
                setPulseHomeDirectsFrontendData(null);
                setToasterMessage({message: t('warning'), type: Configs.warningToaster});
              } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                navigate('/');
              } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                setPulseCampaign(null);
                setPulseHomeDirectsFrontendData(null);
              } else if (response.status === 404) {
                setPulseHomeDirectsFrontendData(null);
                setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
              } else {
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                setPulseHomeDirectsFrontendData(null);
                throw response;
              }
            })
      
            .catch((error) => {
                console.log("[pulseCampaign.js][getCampaign()] Error fetching data: ", error, " - function: GetCampaigns");
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
                // We received a response, stop loading
                setAreCampaignsLoading(false);
                setPulseHomeDirectsFrontendData(null);
            });
    }

    async function getPulseHomeGraphFrontendData () {
      // set Loader
      setIsPulseGraphLoading(true);
  
      // fetch from server -- Requires manager permission
      fetch(Configs.platformGetPulseCampaignIDAnswersManagersAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        }})
          .then((response) => {
            setIsPulseGraphLoading(false);
  
            if (response.status === 200) {
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log("[pulseHome.js][getPulseHomeGraphFrontendData()] Response from request: ", body);
                  populateManagersOptions(body); // populate dropdown options
                  setPulseGraphHomeFrontendData(body);
                  if (body?.length > 0 && pulseCampaign === null) setPulseCampaign(body[0]);
                })
              .catch(error => {
                console.error("[pulseHome.js][getPulseHomeGraphFrontendData()] Error parsing response JSON", error);
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
              });
            } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
              setPulseGraphHomeFrontendData(null);
            } else if (response.status === 401) {
              // Force logout
              setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
              navigate('/');
            } else if (response.status === 403) {
              // Force logout
              setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
              setPulseGraphHomeFrontendData(null);
              // navigate('/');
            } else if (response.status === 404) {
              // No results
              setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
              setPulseGraphHomeFrontendData(null);
            } else {
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              setPulseGraphHomeFrontendData(null);
              throw response;
            }
          })
    
          .catch((error) => {
            console.error("[pulseHome.js][getPulseHomeGraphFrontendData()] Error fetching data: ", error);
            setIsPulseGraphLoading(false);
            setPulseGraphHomeFrontendData(null);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
          });
  }
  
  async function getPulseHomeOrgFrontendData () {
    // set Loader
    setIsPulseOrgLoading(true);
  
    // fetch from server      
    fetch(Configs.platformGetPulseCampaignIDAnswersOrgAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      }})
        .then((response) => {
          setIsPulseOrgLoading(false);
  
          if (response.status === 200) {
            response.json()
            .then (body => {
                console.log("[pulseHome.js][getPulseHomeOrgFrontendData()] Response from request: ", body);
                setPulseHomeOrgFrontendData(body);
                if (body?.length > 0 && pulseCampaign === null) {
                  setPulseCampaign(body);
                  setSelectedFilter("org");
                }
              })
            .catch(error => {
              console.error("[pulseHome.js][getPulseHomeOrgFrontendData()] Error parsing response JSON", error);
              setPulseHomeOrgFrontendData(null);
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
            });
          } else if (response.status === 400) {
            // Something went wrong - inform user
            setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
          } else if (response.status === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            navigate('/');
          } else if (response.status === 403) {
            // Force logout
            setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            setPulseHomeOrgFrontendData(null);
          } else if (response.status === 404) {
            // No results
            setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            setPulseHomeOrgFrontendData(null);
          } else {
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
            setPulseHomeOrgFrontendData(null);
            throw response;
          }
        })
  
        .catch((error) => {
          console.error("[pulseHome.js][getPulseHomeOrgFrontendData()] Error fetching data: ", error);
          setIsPulseOrgLoading(false);
          setPulseHomeOrgFrontendData(null);
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        });
  }

    async function downloadCampaign (campaign, e) {
        e.preventDefault();
      
        // fetch from server      
        fetch(Configs.platformGetCSVDownloadCampaignAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
          }})
            .then((response) => {
              // console.log (response);
              if (response.status === 200) {
                // We have a match
                return response.blob();
              } else if (response.status === 400) {
                // Something went wrong - inform user
                setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
    
              } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                navigate('/');
              } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                // navigate('/');
              } else {
                // There is an error - delete info
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                throw response;
              }
            })
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = companyInfo?.companyName + ' - ' + t('Pulse') + ' - ' + campaign?.campaignName + '.csv';
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(url);
            })
            .catch((error) => {
              setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
              console.error("[pulseCampaign.js][getPulseKeyIndicators()] Error fetching data: ", error);
            });
      }

      async function getPulseCampaignAISummary () {
        // Set loading state
        setIsAISummaryLoading(true);

        // fetch from server
        fetch(Configs.platforGetPulseSurveyAISummaryAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
          }})
            .then((response) => {
              setIsAISummaryLoading(false);
  
              if (response.status === 200) {
                response.json()
                .then (body => {
                    console.log("[pulseCampaign.js][getPulseCampaignAISummary()] Response from request: ", JSON.parse(body));
                    setPulseCampaignAISummary(JSON.parse(body));
                  })
                .catch(error => {
                  console.error("[pulseCampaign.js][getPulseCampaignAISummary()] Error parsing response JSON", error);
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
                });
              } else if (response.status === 400) {
                // Something went wrong - inform user
                // setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
              } else if (response.status === 401) {
                // Force logout
                // setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                // navigate('/');
              } else if (response.status === 403) {
                // Force logout
                // setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                // navigate('/');
              } else {
                // There is an error - delete info
                // setToasterMessage({message: t('error'), type: Configs.errorToaster});
                // throw response;
              }
            })
            .catch((error) => {
              // setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
              console.error("[pulseCampaign.js][getPulseCampaignAISummary()] Error fetching data: ", error);
            });
      }

// ---------------------------------------------------------------------------------------------------
// Frontend data management methods
// ---------------------------------------------------------------------------------------------------
      async function populateManagersOptions (body) {
        if(body) {
          var auxArray = [];
    
          body?.forEach(manager => {
            auxArray.push({value: manager._id.manager._id, label: manager._id.manager.name + " " + manager._id.manager.surname});
          });
    
          setManagersOptions([...auxArray]);
        }
    }

// ---------------------------------------------------------------------------------------------------
// Filtering methods
// ---------------------------------------------------------------------------------------------------
async function handleDirectsFilter (e) {
  e.preventDefault();
  setSelectedFilter("directs");
  setPulseCampaign(pulseHomeDirectsFrontendData);
  setFilteredManager(null);
}

async function handleManagerFiltering (e) {
  // Set the selected manager information in the array of managers
  setFilteredManager(e);

  // Look for the manager information in the body of the request stored in variable pulseHomeGraphFrontendData
  var managerInfo = pulseHomeGraphFrontendData?.find(manager => manager._id.manager._id === e.value);
  setSelectedFilter("manager");
  setPulseCampaign([managerInfo]);
}

async function handleOrgFilter (e) {
  e.preventDefault();
  setSelectedFilter("org");
  setPulseCampaign(pulseHomeOrgFrontendData);
  setFilteredManager(null);
}
    
// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
        };
      
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="main">
        <PulseHeader />

        <div className='flex flex-row items-start'>
          <div className="left-big-view">       

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


          <div className="flex flex-col text-left mt-2 md:ml-2 m-1">

            {areCampaignsLoading ? 
              <Loader />
            :

                <React.Fragment>

                    {(pulseCampaign === null || pulseCampaign?.length  < 1) 
                      && (pulseHomeGraphFrontendData === null || pulseHomeGraphFrontendData?.length < 1) 
                      && (pulseHomeOrgFrontendData === null || pulseHomeOrgFrontendData?.length < 1) ?
                            <React.Fragment >
                                    <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                                        <span className="title-description italic">📊 {" " + t('pulse.campaign.load.success.empty')}{" "}
                                        </span>
                                    </div>
                            </React.Fragment>    

                    :
                
                    <React.Fragment>
                                  {/* Campaign title */}
                                  <h2 className="flex text-xl mb-4 ">
                                                      {" "}
                                      <div className='flex flex-col md:flex-row justify-left'>
                                          <div className='section-title'>
                                          {t('campaign')}: {pulseCampaign !== null ? pulseCampaign[0]?._id?.campaign?.campaignName :
                                          pulseHomeOrgFrontendData !== null ? pulseHomeOrgFrontendData[0]?._id?.campaign?.campaignName :
                                          pulseHomeGraphFrontendData !== null ? pulseHomeGraphFrontendData[0]?._id?.campaign?.campaignName
                                          : null}
                                          </div>
                                      </div>
                                  </h2>
                                  <div className="flex flex-row justify-between mt-2 md:ml-2 m-1 lg:mr-2">
                                    <div className="flex text mb-4">
                                      {/* Filters */}
                                      {pulseHomeGraphFrontendData?.length > 0 || pulseHomeOrgFrontendData?.length > 0 ? 

                                        <div className="flex flex-row items-center justify-start space-x-2 m-4">
                                            <span className='flex text-2xl'> <AiOutlineFilter /></span>
                                            <button className={' filter-selector rounded px-3 py-2 ' + (selectedFilter === "directs" ? ' bg-gray-200 ' :' bg-white border border-gray-300 ')} onClick={(e) => handleDirectsFilter(e)}> {t('pulse.home.filter.directs')} </button>
                                            {isPulseOrgLoading ? 
                                              <Loader />
                                            :
                                              pulseHomeOrgFrontendData?.length > 0 &&
                                              <button className={' filter-selector rounded px-3 py-2 ' + (selectedFilter === "org" ? ' bg-gray-200 ' :' bg-white border border-gray-300 ')} onClick={(e) => handleOrgFilter(e)}>
                                                {t('pulse.home.filter.org')} 
                                              </button>
                                            }
                                            {isPulseGraphLoading ? 
                                              <Loader />
                                            :
                                              pulseHomeGraphFrontendData?.length > 0 &&
                                              <Select
                                                    options={managersOptions}
                                                    id="managerFilter"
                                                    isSearchable
                                                    value={filteredManager}
                                                    placeholder={t('pulse.home.filter-manager')}
                                                    className="filter-selector"
                                                    styles={{
                                                        control: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: '4px',
                                                        borderColor: state.isFocused ? '#BFC6FF' : provided.borderColor,
                                                        boxShadow: state.isFocused ? '0 0 0 3px rgba(191, 198, 255, 0.3)' : provided.boxShadow,
                                                        backgroundColor: filteredManager !== null ? 'rgb(229 231 235)' : 'white',
                                                    }),
                                                    }}
                                                    onChange={(e) => handleManagerFiltering(e)}
                                                    /> 
                                            }
                                        </div>

                                      : 
                                        null
                                      }
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <Tooltip content={t('pulse.home.download')} > 
                                          <button className='save-button px-4 py-2' onClick={(e) => downloadCampaign(campaignID, e)}>
                                            <AiOutlineDownload className='text-xl'/>
                                          </button>
                                        </Tooltip>
                                    </div>
                                  </div>

                                {pulseCampaign === null || pulseCampaign?.length  < 1 ?
                                    <React.Fragment>  
                                        <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                                            <span className="title-description italic">📊 {" " + t('pulse.campaign.use-filters')}{" "}
                                            </span>
                                        </div>
                                    </React.Fragment>

                                    :

                                pulseCampaign?.map((campaign, index) => (
                                            <React.Fragment>
                                                <div className="flex flex-col">
                                                    {campaign?.questions?.map((question, index) => (
                                                        <React.Fragment>
                                                        {index === 0 ?
                                                            <QuestionListItem question={question} collapsed={false}/>
                                                        :
                                                            <QuestionListItem question={question} collapsed={true}/>
                                                        }  
            
                                                        </React.Fragment>
            
                                                    ))}
                                                </div>
                                            <SectionSeparator />
            
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        }
                </React.Fragment>
            }
            
            </div>
            </div>
            <div className='right-small-view'>
              <div className='flex flex-col items-start space-y-4 '>
                <h2 className='section-title flex flex-row items-start'>
                <BsStars className='mr-2 text-violet-800'/> {t('pulse.campaign.ai.summary')}
                <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>
                </h2>
                  {isAISummaryLoading ?
                    <Loader />
                  :
                    pulseCampaignAISummary !== null ?
                    <div className='flex flex-col items-center space-y-4'>
                        {/* Key insights */}
                      <div className="flex flex-col items-start bg-white shadow border border-gray-200 rounded-lg py-8 px-8 space-y-2">
                        <h3 className='text-left text-lg font-bold mb-4' >🔍 {t('pulse.campaign.ai.key-insights')}</h3>
                        {pulseCampaignAISummary["keyPoints"]?.map((item, index) => (
                          <div className="flex flex-row items-start space-x-2" key={index}>
                            <span className='flex px-3 py-1.5 bg-gray-200 rounded-full text-sm'>{index+1}</span>
                            <span className="flex title-description"><p className='text-xs'>{item}</p></span>
                          </div>
                        ))}
                      </div>

                        {/* Action items  */}
                      <div className='flex flex-col items-start bg-white shadow border border-gray-200 rounded-lg py-8 px-8 space-y-2'>
                        <h3 className='text-left text-lg font-bold mb-4'>✅ {t('pulse.campaign.ai.action-items')}</h3>
                        {pulseCampaignAISummary["actionItems"]?.map((item, index) => (
                          <div className="flex flex-row items-start space-x-2" key={index}>
                            <span className='flex px-3 py-1.5 bg-gray-200 rounded-full text-sm'>{index+1}</span>
                            <span className="flex title-description"><p className='text-xs'>{item}</p></span>
                          </div>
                        ))}
                        
                      </div>
                    </div>
                    :
                    <div className='flex flex-col items-center mt-8 md:ml-2 m-1'>
                      <span className="title-description italic">📊 {t('pulse.campaign.ai-summary-empty')}{" "}
                      </span>
                    </div>
                }
                </div>
              </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default PulseCampaign;