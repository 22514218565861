// React
import React, {useState, useEffect, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';

// Common & widgets
import Configs from '../../Configs';
import { useAuth } from '../../common/appContext';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Icons
import { BsQuestion } from 'react-icons/bs';
import {GrAnnounce} from 'react-icons/gr';
import {BsHouseFill} from "react-icons/bs";

// Toaster
import Toaster from '../support/toaster';



// import { BiLogInCircle, BiLogOutCircle } from 'react-icons/bi';

function Bot (props) {
    const {t, i18n} = useTranslation();

    const location = useLocation();
    
    // To understand if chat is open or closed
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    // Variables defining if product feedback or bug report is open
    const [bugOpen, setBugOpen] = useState(false);
    const [bugDescription, setBugDescription] = useState("");
    const [productFeedbackOpen, setProductFeedbackOpen] = useState(false);
    const [productFeedback, setProductFeedback] = useState("");

    // To share the status of the service
    const [lastCheckedStatus, setLastCheckedStatus] = useState (new Date());

    // Employee attributes
    const { token, employeeID, companyID } = useAuth();

    // Toaster
    const [toasterMessage, setToasterMessage] = useState(null);

    const startUp = [
        {
            message: null,
            response: "¡Hola! Mi nombre es Carolina, ¿cómo puedo ayudarte?"
        }
    ]

    // State variables
    const [customerMessage, setCustomerMessage] = useState("");
    const [chatHistory, setChatHistory] = useState(startUp);

    // Draggable
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: window.innerWidth - 100, y: window.innerHeight - 100 });
    const botRef = useRef(null);

    // if (Configs.devEnvironment) console.log ("[Bot.js][main()] location.pathname: ", location.pathname);


// ---------------------------------------------------------------------------------------------------
// Frontend
// ---------------------------------------------------------------------------------------------------    
    
    useEffect (() => {
        // Kincode status
        var today = new Date();
        setLastCheckedStatus(today);

        // addEventListeners();

        // Return the function to remove the event listener
        // return () => removeEventListeners();

      }, [isDragging, botRef, position]);



// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------    
async function submitFormAPI (type, message) {
    // Create a feeling of working on something
    // setLoading(true);

    // define header and HTTP request type
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        },
        body: JSON.stringify({ 
            employeeID: employeeID,
            companyID: companyID,
            location: location.pathname,
            type: type,
            message: message,
        })
    };

    fetch(Configs.platformSubmitFeedbackFormAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, requestOptions)
    .then(response => {
        // We received a response
        setLoading(false);

        if (response.status === 200) {
            setToasterMessage({message: t('success'), type: Configs.successToaster});
        } else if (response.status === 400) {
            // Something went wrong - inform user
            setToasterMessage({message: t('bot.load.warning'), type: Configs.warningToaster});
        } else if (response.status === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            sessionStorage.clear();
            window.location.reload();
        } else if (response.status === 403) {
            // Force logout
            setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
        } else if (response.status === 404) {
            setToasterMessage({message: t('bot.load.warning'), type: Configs.warningToaster});
        } else {
            //
            throw response;
        }
        })
    .catch (error => {
        console.error ("[bot.js][submitFromAPI()] Error fetching data: ", error);
    })
}
// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
    };
    
// ---------------------------------------------------------------------------------------------------
// Frontend management methods
// ---------------------------------------------------------------------------------------------------    
async function openBug (e) {
    e.preventDefault();

    // Set the right state variables
    setBugOpen(true);
    setProductFeedbackOpen(false);
}

async function openProductFeedback (e) {
    e.preventDefault();

    // Set the right state variables
    setBugOpen(false);
    setProductFeedbackOpen(true);
}

async function submitForm (e) {
    e.preventDefault();

    switch (e.target.id) {

        case "productFeedbackSubmitButton":
            if (productFeedback.length > 0) {
                // Send the feedback to the backend
                submitFormAPI ("productFeedback", productFeedback);
                // Close the form
                setProductFeedbackOpen(false);
                setProductFeedback("");
            }
        break;

        case "bugSubmitButton":
            if (bugDescription.length > 0) {
                // Send the feedback to the backend
                submitFormAPI ("bug", bugDescription);
                // Close the form
                setBugOpen(false);
                setBugDescription("");
            }
            
            
        break;

        default:
            break;

    }

}

async function close (e) {
    e.preventDefault();
    setBugOpen(false);
    setProductFeedbackOpen(false);
}


// ---------------------------------------------------------------------------------------------------
// Drag and drop methods
// ---------------------------------------------------------------------------------------------------
const handleDrag = (event) => {
    // Update position
    const clientX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX;
    const clientY = event.type.includes('mouse') ? event.clientY : event.touches[0].clientY;

    // Adjust position ensuring it doesn't go off-screen
    const newX = Math.min(window.innerWidth - botRef?.current?.offsetWidth, Math.max(0, clientX));
    const newY = Math.min(window.innerHeight - botRef?.current?.offsetHeight, Math.max(0, clientY));

    // Update position state
    setPosition({ x: newX, y: newY });
  };

const addEventListeners = () => {
    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('touchmove', handleDrag, { passive: false });
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('touchend', stopDrag);
  };

  const removeEventListeners = () => {
    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('touchmove', handleDrag);
    document.removeEventListener('mouseup', stopDrag);
    document.removeEventListener('touchend', stopDrag);
  };

  const snapToCorner = () => {
    const botWidth = botRef.current?.offsetWidth || 0;
    const botHeight = botRef.current?.offsetHeight || 0;
    const middleX = window.innerWidth / 2;
    const middleY = window.innerHeight / 2;
    const newX = position.x < middleX ? 0 : window.innerWidth - botWidth;
    const newY = position.y < middleY ? 0 : window.innerHeight - botHeight;
    setPosition({ x: newX, y: newY });
  };
  

  const startDrag = (event) => {
    event.preventDefault(); // Prevent default touch action
    // addEventListeners();
  };

  const stopDrag = () => {
    // removeEventListeners();
    if(Configs.devEnvironment) console.log ("[Bot.js][handleDrag()] position:", position);
    snapToCorner();
  };

  const getMenuStyle = () => {
    const style = { position: 'absolute' };
    const botWidth = botRef.current?.offsetWidth || 0;
    const botHeight = botRef.current?.offsetHeight || 40;

    // Adjust horizontal position
    if (position.x > window.innerWidth / 2) {
      // Bot is on the right side of the screen
      style.right = `${botWidth}px`;
    } else {
      // Bot is on the left side of the screen
      style.left = `${botWidth}px`;
    }

    // Adjust vertical position
    if (position.y > window.innerHeight / 2) {
      // Bot is on the bottom of the screen
      style.bottom = `${botHeight}px`;
    } else {
      // Bot is on the top of the screen
      style.top = `${botHeight}px`;
    }
  
    return style;
  };

// ---------------------------------------------------------------------------------------------------
// Page render
// ---------------------------------------------------------------------------------------------------    
    return (
        <React.Fragment>
        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        {/* <div className='fixed bottom-0 right-0 bottom-4 right-4 z-50 '> */}
        <div
            ref={botRef}
            className='hidden fixed  z-50 '
            style={{
                position: 'fixed',
                left: `${position.x}px`,
                top: `${position.y}px`,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onMouseDown={startDrag}
            onTouchStart={startDrag}
            >


{
            /** 
             * Expanded version of the chatbot --> THE CHAT
             */
            }
            {isOpen && (<div style={getMenuStyle()} className={' flex flex-col shadow-2xl w-full h-full sm:w-auto sm:h-auto mb-4 '}>
                    <div className='flex flex-col bg-gradient-to-b from-green-200 to-yellow-200 justify-between items-start h-auto p-4' onClick={(e) => (setIsOpen(!isOpen))}>
                        <div className='flex flex-row justify-between w-full'>
                            <div className='flex p-4 '>

                            </div>
                            <div className='p-4 font-bold'>
                                
                            </div>
                            <div className='p-4 font-bold'>
                                
                            </div>
                        </div>

                        <div>
                            <h3 className='text-2xl text-left font-bold mt-8 pb-4'>
                                <span className='text-gray-500'>¡{t('hello')}! </span>
                                <p>{t('bot.how-to-help')}</p>
                            </h3>
                        </div>
                    </div>

                    <div className='flex flex-col w-full h-full sm:h-auto '>
                        <div className={(bugOpen || productFeedbackOpen) ? ' hidden ' : 
                        ' flex flex-col w-full justify-between h-full sm:w-96 sm:h-80 bg-white overflow-y-auto text-sm '}>
                            
                            <div className=' flex flex-col w-full ' >
                                    <button className='flex flex-row text-left p-2 border border-gray m-2 rounded' onClick={openBug}>
                                    ⚠️ {t('bot.bug')}
                                    </button>

                                    <button className='flex flex-row text-left p-2 border border-gray m-2 rounded ' onClick={openProductFeedback}>
                                    📣  {t('bot.product-feedback')}
                                    
                                    </button>
                            </div>

                            <div className='flex flex-col text-left p-2 border border-gray m-2 rounded '>
                                <div>🔧  {t('bot.service-status')}: 🟢 </div>
                                <div className='text-gray-800 text-xs'>({t('bot.service-status.checked-at')}: {lastCheckedStatus.toDateString()})</div>
                            </div>
                        </div>

                        {/* Open a new bug menu */}
                        <div className={(bugOpen) ? ' flex flex-col w-full justify-between h-full sm:w-96 sm:h-80 bg-white overflow-y-auto text-sm '
                                : ' hidden ' }>
                                    <div className=' flex flex-col w-full ' >

                                        <div id="bugOpenForm" >
                                            <div className='flex flex-col w-full'>
                                                <label className='text-left text-gray-500 text-sm font-bold m-4' htmlFor="bugDescription">
                                                ⚠️ {t('bot.bug.description')}
                                                </label>
                                                <textarea autoFocus={true} className='border-l-4 border-l-gray-300 p-2 m-2' 
                                                id="bugDescription" name="bugDescription" rows="7"  
                                                value={bugDescription} onChange={(e) => setBugDescription(e.target.value)}></textarea>
                                            </div>
                                            <div className='flex flex-row items-center justify-end m-4'>
                                                <button className='flex flex-row text-left p-2 border border-gray m-2 rounded ' onClick={close}>
                                                    {t('cancel')}
                                                </button>
                                                <button id="bugSubmitButton" type='submit' className='flex flex-row text-left px-4 py-2 bg-green-200 m-2 rounded' onClick={submitForm}>
                                                    {t('submit')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                            </div>

                        {/* Open product feedback menu */}
                        <div className={(productFeedbackOpen) ? ' flex flex-col w-full justify-between h-full sm:w-96 sm:h-80 bg-white overflow-y-auto text-sm '
                            : ' hidden ' }>
                                <div className=' flex flex-col w-full ' >
                                    <div className='flex flex-col '>
                                        <div className='flex flex-col w-full '>
                                            <label className='flex text-left text-gray-500 text-sm font-bold m-4' htmlFor="productFeedbackDescription">
                                                📣 {t('bot.product-feedback.description')}
                                                </label>
                                            <textarea autoFocus={true} className='flex border-l-4 border-l-gray-300 p-2 m-2 h-full overflow-y-auto' 
                                            id="productFeedbackDescription" name="productFeedbackDescription" rows="7" 
                                            value={productFeedback} onChange={(e) => setProductFeedback(e.target.value)}></textarea>
                                        </div>
                                        <div className='flex flex-row items-center justify-end m-4'>
                                            <button className='flex flex-row text-left p-2 border border-gray m-2 rounded ' onClick={close}>
                                                {t('cancel')}
                                            </button>
                                            <button id="productFeedbackSubmitButton" type='submit'  className='flex flex-row text-left px-4 py-2  bg-green-200 m-2 rounded' onClick={submitForm}>
                                                {t('submit')}
                                            </button>
                                        </div>
                                    </div>
                                    </div>

                        </div>

                        {/* Chatbot Footer */}
                        <div class="flex flex-row  bg-white border-t-2 text-black border-gray-100 px-4 py-2 text-sm items-center">
                            <div class="flex flex-row justify-around items- w-full">
                                <div className='flex'>
                                    <button class="flex flex-col px-4 py-2 items-center hover:bg-green-200 text-black rounded-lg ">
                                        <BsHouseFill className=' text-xl  mb-2 '/>
                                        {t('Home')}
                                    </button>
                                </div>
                                <div className='hidden flex'>
                                    <button class="flex flex-col px-4 py-2 items-center hover:bg-green-200 text-black rounded-lg ">
                                        <GrAnnounce className=' text-xl mb-2 '/>
                                        {t('news')}
                                    </button>
                                </div>
                                <div className='flex'>
                                    <a href="https://resources.kincode.app" target="#">
                                        <button class="flex flex-col px-4 py-2 items-center hover:bg-green-200 text-black rounded-lg ">
                                            <BsQuestion className=' text-xl  border rounded-full border-black mb-2 '/>
                                            {t('help')}
                                        </button>
                                    </a> 
                                </div>
                                
                            </div>
                        </div>
                    </div>
            </div>
            )}


            {
            /** 
             * Button of chatbot
             */
            }
                
            <button className='bg-gradient-to-b from-green-200 to-yellow-200 hover:bg-green-600 p-1 text-gray-800 rounded-full shadow-xl items-center float-right hover:animate-pulse ' 
            onClick={(e) => (setIsOpen(!isOpen))}>
                {isOpen ?
                    
                    <div className='w-[40px] h-[40px] font-bold items-center text-center text-4xl' >                    
                        <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                            <path d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z" fill="currentColor" fill-rule="evenodd">
                            </path>
                        </svg>    
                    </div>
                : 
                    <BsQuestion className='w-[40px] h-[40px] font-bold items-center text-center' />                    
                }  
            </button>  

            

        </div>


        </React.Fragment>


    );    
}

export default Bot;
