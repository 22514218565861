// React
import React, {useState, useEffect} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';

// 3P
import { useTranslation } from 'react-i18next';

// Configs and common
import Configs from '../Configs';
import NoneditableEditor from './noneditableEditor';
import Tooltip from "../common/support/tooltip";

// Icons
import {FaPeopleArrows,  FaEdit, FaTrash} from 'react-icons/fa';
import {PiPlantBold} from 'react-icons/pi'; 
import { BsLadder } from "react-icons/bs";

/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function CollapsibleListItem(props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

// Props
const [collapsed, setCollapsed] = useState (props?.collapsed);
const title = props?.title;
const content = props?.content;
const managerPermissions = props?.managerPermissions;
const privateNotes = props?.privateNotes;
const attritionRisk = props?.attritionRisk;
const editURL = props?.editURL;
const deleteMeeting = props?.deleteMeeting;
const meetingType = props?.meetingType;

// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        // calculateResult(selectedValues);
}, []);

// ------------------------------------------------------------------------------------------
// Frontend mgmt
// ------------------------------------------------------------------------------------------
async function deleteItem () {
    // Delete meeting
    deleteMeeting(props.meetingID);
}

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className={'flex flex-col m-1 border-2 border-gray-200 rounded-lg bg-white shadow px-4 lg:px-8 py-6 lg:mx-6 justify-between items-center ' + (collapsed ? ' cursor-pointer hover:bg-gray-100 ': null )}>
        <button className="flex flex-row w-full justify-between items-center " onClick={() => setCollapsed(!collapsed)}>
            <div className='flex flex-col items-start'> 
                <span className='text-sm text-gray-600 mb-2'>{t('title')}</span>
                <span className="font-medium">
                 {meetingType === Configs.oneOnOneTypeDevelopmentPlan ?
                    // Development Plan
                        <div className="flex flex-row items-center">
                            <PiPlantBold className="text-xl mr-2 "/> {title} 
                        </div>
                    :
                meetingType === Configs.oneOnOneTypeCareerConversation ?
                    // Career
                        <div className="flex flex-row items-center">
                            <BsLadder className=" text-xl mr-3"/>{title} 
                        </div>
                    :
                    // Rest of meeting types
                        <div className="flex flex-row items-center">
                            <FaPeopleArrows className=" text-xl mr-3"/>{title} 
                        </div>
                    
                }    
                     
                </span>
            </div>
            <div className="flex">
            {collapsed ?
                        <React.Fragment>
                            <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                                <path d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z" fill="currentColor" fill-rule="evenodd">
                                </path>
                            </svg>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div>
                                <svg fill="none" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-up" class="inline h-1.5">
                                    <path d="M12.085 6.23a1.09 1.09 0 01-.004 1.477 1.093 1.093 0 01-1.605.005l-3.84-4.17a.544.544 0 00-.8 0l-3.84 4.17a1.093 1.093 0 01-1.604-.005A1.093 1.093 0 01.388 6.23L5.436.747a1.088 1.088 0 011.6 0l5.05 5.482z" fill="currentColor" fill-rule="evenodd">
                                    </path>
                                </svg>
                            </div>

                        </React.Fragment>
            }
            </div>
        </button>


        <div className={collapsed ? 
            "hidden"
            :
            "flex flex-row items-start text-left w-full mt-8 justify-between"}>

                <div className='flex flex-col items-start w-full'> 
                        <span className='text-sm text-gray-600 mb-2'>{t('shared-notes')}</span>
                            <div className="flex flex-col items-start w-full text-xs"><NoneditableEditor content={content} /> </div>
                    
                    {props?.privateNotes && props?.managerPermissions ? 
                        <div className='flex flex-col items-start w-full'> 
                            <span className='text-sm text-gray-600 mb-2'>{t('private-notes')}</span>
                                <NoneditableEditor content={privateNotes} /> 
                        </div>
                    :
                        null
                    }
                    {props?.attritionRisk && props?.managerPermissions ?
                        <div className='flex flex-col items-start w-full'> 
                            <span className='text-sm text-gray-600 mb-2'>{t('oneOnOne.meeting.attrition-risk')}</span>
                            <div className='flex flex-row space-x-2'>
                                      <div className={'secondary-button  shadow  group ml-2  ' + (props?.attritionRisk === 1 ?  ' bg-green-200 ' : ' bg-gray-100 '  )}>
                                          🟢
                                          <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl h-auto z-50 text-xs">
                                            <NoneditableEditor
                                                content={t('oneOnOne.meeting.attrition-risk-1')}
                                                />
                                          </div>
                                      </div>
                                      <div  className={'secondary-button  shadow  group ml-2   ' + (props?.attritionRisk === 2 ?  ' bg-green-200 ' : ' bg-gray-100 '  )}>
                                        <div className=''>

                                          🟡 
                                          <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl h-auto z-50 text-xs">
                                              <NoneditableEditor
                                                  content={t('oneOnOne.meeting.attrition-risk-2')}
                                                  />
                                            </div>
                                        </div>
                                      </div>
                                      <div className={'secondary-button  shadow   group ml-2 ' + (props?.attritionRisk === 3 ?  ' bg-green-200 ' : ' bg-gray-100 '  )}>

                                        🟠 
                                        <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl h-auto z-50 text-xs">
                                            <NoneditableEditor
                                                content={t('oneOnOne.meeting.attrition-risk-3')}
                                                />
                                          </div>
                                      </div>
                                      <div className={'secondary-button  shadow  group ml-2  ' + (props?.attritionRisk === 4 ?  ' bg-green-200 ' : ' bg-gray-100 '  )}>
                                        🔴 
                                        <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl h-auto z-50 text-xs">
                                            <NoneditableEditor
                                                content={t('oneOnOne.meeting.attrition-risk-4')}
                                                />
                                          </div>
                                      </div>
                                      <div className={'secondary-button  shadow   group ml-2  ' + (props?.attritionRisk === 5 ? ' bg-green-200 ' : '  bg-gray-100 ' )}>
                                        ⚫ 
                                        <div className="hidden group-hover:block absolute bg-gray-300 p-1 rounded items-center justify-center shadow-2xl h-auto z-50 text-xs">
                                            <NoneditableEditor
                                                content={t('oneOnOne.meeting.attrition-risk-5')}
                                                />
                                          </div>
                                      </div>
                                    </div>  
                        </div>
                    :
                        null
                    }
                </div>
                {editURL ? 
                
                    <div className="flex flex-col items-center">
                        {/* <span className='text-sm mr-2'>{t('action')}</span> */}
                        <div className="flex flex-row items-start justify-between m-2 space-x-2">
                        <Link to={editURL}>
                            <Tooltip content={t('edit')}>
                                <FaEdit className='flex text-lg cursor-pointer text-gray-500 hover:text-black'/>
                            </Tooltip>
                        </Link>
                        { deleteMeeting ?

                            <Tooltip content={t('delete')}>
                                <button onClick={deleteItem}>
                                    <FaTrash className='flex text-base cursor-pointer text-gray-500 hover:text-black'/> 
                                </button>
                            </Tooltip>
                            
                            :
                            null
                        }
                        </div>
                        

                    </div>
                :
                    null
                }
        </div>
    </div>

  );
}

export default CollapsibleListItem;