// React
import React, { useState } from 'react';

// Common
import Configs from '../Configs';

// I18N
import {t} from 'i18next'; 

// Icons
import { RiOpenaiFill, RiQuestionAnswerFill } from 'react-icons/ri';

function ListOfQuestions(props) {

    if (Configs.devEnvironment) console.log('[listOfQuestions.js][main()] props:', props);

// --------------------------------------------------
// Functions
// --------------------------------------------------
    // Handlers
    async function handleChange (index, e) {
        var auxArray = [];
        
        if (props?.arrayQuestions === null || props?.arrayQuestions === undefined || props?.arrayQuestions === '' 
        || props?.arrayQuestions === [] || props?.arrayQuestions === {} || props?.arrayQuestions?.length === 0) {
            auxArray = [];
        } else {
            if (props?.arrayQuestions[0]?.question === null && props?.arrayQuestions[0]?.frequency === null) {
                // If array is empty;
                auxArray = [];
            } else {
                // If array is not empty
                auxArray = props?.arrayQuestions;
            }
        }

            if (Configs.devEnvironment) console.log ('[listOfQuestions.js][handleChange()] index:', index, 'e:', e.target.checked);

            // User checked the option - add to array of options
            if (e.target.checked) {
                // Create aux object to insert into array
                const auxObject = {
                    question: {
                        value: props?.allPulseQuestions[index]?._id,
                        label: props?.allPulseQuestions[index]?.Question_ESP
                    },
                    frequency: null
                }

                // Add selected question to array
                auxArray.push(auxObject);
                props?.setArrayQuestions([...auxArray]);
            } else {
                // Remove selected question from array
                const indexAux = auxArray?.findIndex(q => q?.question?.value?.toString() === props?.allPulseQuestions[index]?._id?.toString());
                auxArray.splice(indexAux, 1);
                props?.setArrayQuestions([...auxArray]);
            }
    } 

    // Submit
    async function validateInput () {
        // Update parent view with selected questions
        props?.onClose();
    };

// --------------------------------------------------
// Render
// --------------------------------------------------
    if (!props?.isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-700 flex items-center justify-center z-50 ">
            <div className="bg-white w-full h-full relative overflow-y-auto">
                {/* Header */}
                <div className='flex flex-col'>
                    <div className="flex flex-row justify-between bg-gray-100 p-6 items-center">
                            <h2 className="flex text-2xl font-bold items-center">
                            <RiQuestionAnswerFill className='mr-4' /> {t('pulse.questions.view-all.title')}
                            </h2>
                        <button onClick={props?.onClose} className=" text-gray-700 hover:text-gray-900 font-bold text-2xl">
                            X
                        </button>
                    </div>
                    <div className='flex flex-row'>
                        <span className='title-description m-4 mt-8 ml-8'>
                             {t('pulse.questions.view-all.description')}
                        </span>
                    </div>
                </div>

                {/* Form */}
                <div className="p-8 pt-4  text-sm">
                    {/* Table */}
                    <table className=" w-full mr-2 text-sm text-left mb-4">
                                              <thead>
                                              <tr className="border-b border-gray-200">
                                                  <th className="p-2"> {t('question')} </th>
                                                  <th className="p-2"> {t('pulse.questions.create.category')} </th>
                                                  <th className="p-2"> {t('pulse.questions.create.sub-category')} </th>
                                                  <th className="p-2">  </th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                                      {props?.allPulseQuestions?.map((question, index) => (
                                                            <tr className='border-b border-gray-200 p-4'>

                                                                <td className=" p-4 text-sm">
                                                                    {question?.Question_ESP}
                                                                </td>
                                                                <td className=" p-4 text-sm">
                                                                    {question?.Category}
                                                                </td>
                                                                <td className=" p-4 text-sm">
                                                                    {question?.Topic}
                                                                </td>
                                                                <td className=" p-4 text-sm">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        name="question"
                                                                        key={index} 
                                                                        checked={props?.arrayQuestions?.find(q => q?.question?.value?.toString() === question?._id?.toString()) ? true : false } 
                                                                        onChange={(e) => handleChange(index, e)} />
                                                                </td>
                                                            </tr> 
                                                      ))}
                                              </tbody>
                                          </table>       
                    
                </div>
                {/* Footer */}
                <div className='flex flex-col'>
                    <div className="flex flex-row justify-end p-6 items-center">
                        <button type="button" id="cancel" class="text-sm font-semibold  text-gray-900 m-8 " onClick={props?.onClose}> {t('cancel')} </button>
                        <button id="create" className='bg-green-200 px-4 py-2 rounded text-sm' onClick={validateInput}>{t('pulse.questions.view-all.confirm')}</button>                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListOfQuestions;