
// React 
import React, {useState, useEffect} from 'react';
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom';

// Common & widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import UserListItem from '../../widgets/userListItem';
import SectionSeparator from '../../widgets/sectionSeparator';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// TapTap text editor
import Editor from '../../widgets/editor';
import NoneditableEditor from '../../widgets/noneditableEditor';


function PerformanceGrowth () {
  // Using params when performance from team members
  let { encryptedParams } = useParams();
  const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

  // To pass location to the Header
  const location = useLocation();
  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState(location.pathname);

  // Performance campaigns
  const performanceCampaignAux = sessionStorage.getItem('performanceCampaigns') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('performanceCampaigns'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
  const existingDraftAux = sessionStorage.getItem('performanceReviewDraft') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('performanceReviewDraft'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
  const companyValuesAux = sessionStorage.getItem('companyPreferences') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyPreferences'), Configs.privateKey).toString(CryptoJS.enc.Utf8)).companyValues : null;
  
  const [performanceReviewCampaign, setPerformanceReviewCampaign] = useState(performanceCampaignAux);
  const [livePerformanceCampaign, setLivePerformanceCampaign] = useState(sessionStorage.getItem('livePerformanceCampaign') ? CryptoJS.AES.decrypt(sessionStorage.getItem('livePerformanceCampaign'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : false);

  // Existing drafts in DB
  const [existingDraft, setExistingDraft] = useState(existingDraftAux);
  const [existingManagerDraft, setExistingManagerDraft] = useState (null);

  // Questionnaire that is shown in FrontEnd
  const [questionnaire, setQuestionnaire] = useState(performanceCampaignAux ? performanceCampaignAux[0].questionnaire : null);
  const [managerQuestionnaire, setManagerQuestionnaire] = useState(performanceCampaignAux ? performanceCampaignAux[0].managerQuestionnaire : null);

  // IC input for values
  const [selectedValues, setSelectedValues] = useState(companyValuesAux);
  const [numOfSelectedValues, setNumOfSelectedValues] = useState(0);
  const [valuesResult, setValuesResult] = useState(0);
  
  // Manager input for values
  const [managerSelectedValues, setManagerSelectedValues] = useState(companyValuesAux);
  const [managerNumOfSelectedValues, setManagerNumOfSelectedValues] = useState(0);
  const [managerValuesResult, setManagerValuesResult] = useState(0);

  // Select employeeID depending on 
  var employeeID = '';
  var managerID = '';
   if (params) {
     // If there are params, we are in a team member performance review
     // managerID = employeeID
     employeeID = params.employeeID;
     managerID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8); 
    } else {
      // If no params, we are in self/IC review employeeID = employeeID
      employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    }
    const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const { token } = useAuth();

    // Logging only in Dev
    if (Configs.devEnvironment) console.log ("DEV ONLY log - Campaigns: ", livePerformanceCampaign);
    if (Configs.devEnvironment) console.log ("DEV ONLY log - Existing draft: ", existingDraft);
    if (Configs.devEnvironment) console.log ("DEV ONLY log - Existing manager draft: ", existingManagerDraft);
    if (Configs.devEnvironment) console.log ("DEV ONLY log - Values: ", selectedValues);

   // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------  
  useEffect (() => {
  // IC --> Update draft in case it has been updated
  if (!existingDraft || existingDraft === undefined || existingDraft?.length < 1 || existingDraft === null) {
    // No draft found
    getDraft(performanceReviewCampaign[0]._id);
  } else {
    // Navigate to the right screen, because there is a draft found
    if (existingDraft[0].status === "completed" || existingDraft[0].status === "submitted") {
      if (params && existingDraft[0].status === "completed") {
        // Manager
        navigate("/performance/team/employee/discuss/" +
        encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
            employeeID: params.employeeID,
            employee: params.employee,
            companyID: companyID,
            managerID: employeeID
            }),Configs.privateKey).toString()));
      } else if (!params && (existingDraft[0].status === "submitted" ||  existingDraft[0].status === "completed")) {
        navigate("/performance/self/discuss");
      }
     }
  }
  // Manager --> Update draft in case there is any
  if (params &&
    (!existingManagerDraft || existingManagerDraft === undefined || existingManagerDraft?.length < 1 ||existingManagerDraft === null)) {
      getManagerDraft(performanceReviewCampaign[0]._id);
  }

    updateFrontendData(existingDraft);
    if (params) updateManagerFrontendData (existingManagerDraft);
  }, [performanceReviewCampaign, managerQuestionnaire, managerSelectedValues]);

  function updateFrontendData (body) {
    // Get information to populate dashboard
    var numOfValuesSelectedAux = 0;
    if (body) {
      body.map((element, index) => {
            if (element.type === "growth") {
              if (element.status === "completed") {
                setLivePerformanceCampaign(false);
              }  
              setQuestionnaire(element.questionnaire);
                setSelectedValues(element.values);
                if (performanceReviewCampaign[0]?.valuesSelectorType === "score") calculateValuesResult(element.values);

                element.values?.map((value, index) => {
                    if (value.selected === true) {
                        numOfValuesSelectedAux += 1;
                    }
                })
                setNumOfSelectedValues(numOfValuesSelectedAux);

            } else {
                // do nothing
            }
        })
      }
  }
  function updateManagerFrontendData (body) {
    // Get information to populate dashboard
    var numOfValuesSelectedAux = 0;
    if (body) {
      body.map((element, index) => {
            if (element.type === "growth") {
              if (element.status === "completed") {
                setLivePerformanceCampaign(false);
              }  
              setManagerQuestionnaire(element.questionnaire);
                setManagerSelectedValues(element.values);
                if (performanceReviewCampaign[0]?.valuesSelectorType === "score") calculateValuesResult(element.values);

                element.values?.map((value, index) => {
                    if (value.selected === true) {
                        numOfValuesSelectedAux += 1;
                    }
                })
                setManagerNumOfSelectedValues(numOfValuesSelectedAux);

            } else {
                // do nothing
            }
        })
      }
  }

  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------
  async function getDraft(campaignID) {
    // fetch from server      
    fetch(Configs.platformGetPerformanceDraftsAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      }})
        .then((response) => {
          if (response.status === 200) {
            // Found a draft
            response.json()
            .then (body => {
               // if (Configs.devEnvironment) console.log ("DEV ONLY log - draft: ", body);
                setExistingDraft(body);
                updateFrontendData(body);

                // Navigate to the right screen
                if (body[0].status === "completed" || body[0].status === "submitted") {
                  if (params && body[0].status === "completed") {
                    // Manager
                    navigate("/performance/team/employee/discuss/" +
                    encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                        employeeID: params.employeeID,
                        employee: params.employee,
                        companyID: companyID,
                        managerID: employeeID
                        }),Configs.privateKey).toString()))
                  } else if (!params && (body[0].status === "submitted" || body[0].status === "completed")) {
                    navigate("/performance/self/discuss")
                  }
                }
              })
            .catch(error => {console.error("Error parsing response JSON", error)});
          } else if (response.status === 403) {
          } else if (response.status === 400) {
            // Force logout
              sessionStorage.clear();
              window.location.reload();
          } else {
            // There is an error - delete info
            throw response;
          }
        })
  
        .catch((error) => {
          console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
        });
  }

  async function getManagerDraft (campaignID) {
    // fetch from server      
    fetch(Configs.platformGetManagerPerformanceDraftsAPI + "?employeeID=" + employeeID + "&managerID=" + managerID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      }})
        .then((response) => {
          if (response.status === 200) {
            // Found a draft
            response.json()
            .then (body => {
              if (Configs.devEnvironment) console.log ("DEV ONLY log - Manager draft: ", body);
                // sessionStorage.setItem('performanceReviewDraft', CryptoJS.AES.encrypt(JSON.stringify(body),Configs.privateKey).toString());
                setExistingManagerDraft(body);
                setManagerQuestionnaire(body[0].questionnaire);
                setManagerSelectedValues(body[0].values);
              })
            .catch(error => {console.error("Error parsing response JSON", error)});
          } else if (response.status === 404) {
              setExistingManagerDraft(null);
              // sessionStorage.removeItem('performanceReviewDraft');
          } else if (response.status === 400) {
            // Force logout
             // sessionStorage.clear();
             // window.location.reload();
             setExistingManagerDraft(null);
          } else {
            // There is an error - delete info
            setExistingManagerDraft(null);
            throw response;
          }
        })

        .catch((error) => {
          console.log("Error fetching data: ", error, " - function: getLivePerformanceCampaigns()");
        });
}

  async function submitManagerDraft () {
  // HTTP POST request options
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      },
    body: JSON.stringify({ 
      employeeID: employeeID,
      managerID: managerID,
      companyID: companyID,
      campaignID: performanceReviewCampaign[0]._id,
      type: "growth",
      questionnaire: managerQuestionnaire,
      values: managerSelectedValues
    })
  };

  // Post to server      
  fetch(Configs.platformPostManagerFeedbackAPI, requestOptions)
      .then((response) => {
        // console.log (response);
        if (response.status === 200) {
          // All good
          response.json()
          .then (body => {
              // console.log(body)
            })
          .catch(error => {console.error("Error parsing response JSON", error)});
        } else if (response.status === 403) {
              
        } else {
          // There is an error - delete info
          throw response;
        }
      })

      .catch((error) => {
        console.log("Error fetching data: ", error, " - function: PostSelfFeedback(growth)");
      });
  }

    async function submitDraft () {
      // HTTP POST request options
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 
        'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
          },
      body: JSON.stringify({ 
        employeeID: employeeID,
        companyID: companyID,
        campaignID: performanceReviewCampaign[0]._id,
        type: "growth",
        questionnaire: questionnaire,
        values: selectedValues
      })
    };

  

  // Post to server      
  fetch(Configs.platformPostSelfFeedbackAPI, requestOptions)
      .then((response) => {
        // console.log (response);
        if (response.status === 200) {
          // All good
          response.json()
          .then (body => {
              // console.log(body)
              sessionStorage.removeItem('performanceReviewDraft');
            })
          .catch(error => {console.error("Error parsing response JSON", error)});
        } else if (response.status === 403) {
              
        } else if (response.status === 400) {
          // Force logout
            sessionStorage.clear();
            window.location.reload();
        } else {
          // There is an error - delete info
          throw response;
        }
      })

      .catch((error) => {
        console.log("Error fetching data: ", error, " - function: PostSelfFeedback(growth)");
      });
}


    // ---------------------------------------------------------------------------------------------------
  // Frontend management
  // ---------------------------------------------------------------------------------------------------
function answerUpdated (e, type, title, description, index) {
    // Create the object to update
    var answer = e.target.value;
    var objectAnswer = {
        type: type,
        title: title,
        description: description,
        answer: answer
    }

    // Get an auxiliary array to update
    var auxQuestionnaire = questionnaire;
    auxQuestionnaire[index] = objectAnswer;

    // Set Questionnaire back to its state
    setQuestionnaire([...auxQuestionnaire]);
}

function managerAnswerUpdated (e, type, title, description, index) {
  // Create the object to update
  var answer = e.target.value;
  var objectAnswer = {
      type: type,
      title: title,
      description: description,
      answer: answer
  }

  // Get an auxiliary array to update
  var auxQuestionnaire = managerQuestionnaire;
  auxQuestionnaire[index] = objectAnswer;

  // Set Questionnaire back to its state
  setManagerQuestionnaire([...auxQuestionnaire]);
  
  // Async updload of draft
  // submitManagerDraft();
}

function changedValue (e, value, description, index) {
    if (numOfSelectedValues <= 2) {
        // 3 or less values are selected
        if (e.target.checked === true) {
            setNumOfSelectedValues(numOfSelectedValues + 1);
        } else {
            setNumOfSelectedValues(numOfSelectedValues - 1);
        }

            // Create the object to update
            var selected = e.target.checked;
            var objectAnswer = {
                value: value,
                description: description,
                selected: selected
            }

            // Get an auxiliary array to update
            var auxSelectedValues = selectedValues;
            auxSelectedValues[index] = objectAnswer;
            
            // Update state values
            setSelectedValues([...auxSelectedValues]);
            
    } else {
        // Too many values are selected
        if (e.target.checked === false) {
            // Unselect
            setNumOfSelectedValues(numOfSelectedValues-1);

            // Create the object to update
            var selected = e.target.checked;
            var objectAnswer = {
                value: value,
                description: description,
                selected: selected
            }

            // Get an auxiliary array to update
            var auxSelectedValues = selectedValues;
            auxSelectedValues[index] = objectAnswer;
            
            // Update state values
            setSelectedValues([...auxSelectedValues]);
        }
    }
}

function calculateValuesResult (inputGrades) { 
  if (performanceReviewCampaign[0]?.valuesSelectorType === "score") {
      // Calculate result for input
      var count = 0;
      var auxResult = 0;
      inputGrades.forEach(element => {
          count = count + 1;
          auxResult = parseInt(element.selected) + parseInt(auxResult);
      });
      if (params) {
        // Manager portion
        setManagerValuesResult(auxResult / count);
      } else {
        // IC 
        setValuesResult(auxResult / count);
      }
      
  }
}

  // ---------------------------------------------------------------------------------------------------
  // Render methods
  // ---------------------------------------------------------------------------------------------------
  if (!performanceReviewCampaign || performanceReviewCampaign === null || !performanceCampaignAux[0])  {
    
    <React.Fragment>
      <Header />
      <Bot />
         Nothing here
    </React.Fragment>

  } else {  

    return (
        <React.Fragment>
        <Header />
        <Bot />

        <div className="flex flex-col lg:ml-[215px]">

        {params ? 
          /* Manager */
              <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen}  params={params}/>
            :
            /* IC */
              <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen} />          
            }

          <div className='flex flex-col'>
            <div className="flex flex-col text-left m-2 lg:m-4 ">
            <div className='flex flex-col mt-2 ml-2 m-1'>

              {params ?
              /* Manager */ 
                  <div className="flex flex-col">
                      <UserListItem user={params.employee} initials={params.employee.slice(0,1)} role={params.role} level={params.level} profilePicture={params.profilePicture}/>
                      <div>
                      🌱 
                      <span className="title-description">
                      {t('performance.growth.team-member.description')}
                      </span>
                      </div>
                      
                  </div>
              : 
                      <React.Fragment>
                        <h2 className='flex text-xl mb-4 font-bold'>
                        🌱{t('performance.growth.title')}
                        </h2>
                        <span className='title-description'>
                            {t('performance.growth.description')}
                            <b> {t('performance.growth.help')} </b>
                        </span>
                      </React.Fragment>
              }
            </div>

            <SectionSeparator />


                    {params ? 
                    /* Manager */  
                    <React.Fragment>
                        <div className='m-2 mt-6 border border-white border-b-gray-200 p-2 mb-0'>
                          {t('performance.manager-portion.title')}
                        </div>
                        {managerQuestionnaire?.map((element, index) => (

                          element.type === "growth" ? 
                              
                              <div className='flex flex-col mt-8 ml-2 m-1'>
                                  <h3 className='flex text-l mb-4 font-bold'> {element.title}
                                  </h3>
                                  <span className='title-description'>{element.description}</span>
      
                                  {/* <textarea 
                                  type="textarea" 
                                  className="border border-black lg:mr-8 resize-y p-2	
                              focus:bg-white focus:border-gray-800"
                                  id="perform" 
                                  placeholder={t('form.fill-details')}
                                  value = {element.answer}
                                  disabled={existingManagerDraft ? existingManagerDraft[0]?.status === "submitted" ? true : false : false}
                                  onChange={(e) => managerAnswerUpdated(e, element.type, element.title, element.description, index)}/> */}

                                  {params && existingManagerDraft && (existingManagerDraft[0]?.status === "submitted" ||existingManagerDraft[0]?.status === "completed") ? 
                                    // non-editable Editor
                                    <div className='border-l-4 border-gray-200'>
                                      <NoneditableEditor content={element.answer}/>
                                    </div>
                                  : 
                                    <div className="flex flex-col items-start w-full ">
                                      <Editor setContent={managerAnswerUpdated} content={element.answer} initialContent={element.answer ? element.answer : t('form.fill-details')}
                                       editorType={"performanceReviewFeedback"} type={element.type} index={index}/>
                                    </div>
                                  }
      
                              </div>
      
                          : null
      
                          ))}
                          </React.Fragment>
                        :
                        /* IC - no manager input */ 
                        null
                    }

                

                {params ?  
                  <div className='m-2 mt-6 border border-white border-b-gray-200 p-2 '>
                    {t('performance.employee-portion.title')}
                  </div>
                :
                  null
                }

                {questionnaire?.map((element, index) => (

                element.type === "growth" ? 
                    
                    <div className='flex flex-col mt-8 ml-2 m-1'>
                        <h3 className='flex text-l mb-4 font-bold'> {element.title}
                        </h3>
                        <span className='title-description'>{element.description}</span>

                        {/* <textarea 
                        type="textarea" 
                        className="border border-black lg:mr-8 resize-y p-2	
                    focus:bg-white focus:border-gray-800"
                        id="perform" 
                        placeholder={t('form.fill-details')}
                        value = {element.answer}
                        disabled={params ? true : existingDraft ? existingDraft[0]?.status === "submitted" ? true : false : false}
                        onChange={(e) => answerUpdated(e, element.type, element.title, element.description, index)}/> */}

                            {!params && existingDraft && (existingDraft[0]?.status === "submitted" ||existingDraft[0]?.status === "completed") ? 
                                // non-editable Editor
                                <div className='border-l-4 border-gray-200'>
                                  <NoneditableEditor content={element.answer}/>
                                </div>
                              : 
                                <div className="flex flex-col items-start w-full ">
                                  <Editor setContent={answerUpdated} content={element.answer} initialContent={element.answer ? element.answer : t('form.fill-details')}
                                    editorType={"performanceReviewFeedback"} type={element.type} index={index}/>
                                </div>
                              }

                    </div>

                : null

                ))}

                {
                (performanceReviewCampaign[0]?.valuesSelectorType === "score") ?
                  null 
                :
                  <React.Fragment>
                      <div className='flex flex-col mt-8 ml-2 m-1'>
                          <h3 className='flex text-l mb-4 italic font-bold'> Which values would you like to improve?
                          </h3>
                          <span className='text-sm mb-4'>Select up to 3. <b>Learn more about your company values</b></span>

                          <div className='flex flex-col'>
                              
                          {selectedValues?.map((element, index) => (
                                  <div className='flex flex-col border border-black p-2 ml-0 lg:mr-8 m-1'>
                                      <span className='text-l mb-0 m-2 font-bold'>
                                          {(numOfSelectedValues >= 3) ?
                                                  (element.selected === true) ?
                                                      <input type="checkbox" className='m-2 border border-black' checked onChange={(e) => changedValue(e, element.value, element.description, index)}/>
                                                      :
                                                      <input type="checkbox" className='m-2 border border-black' disabled onChange={(e) => changedValue(e, element.value, element.description, index)}/>
                                              :

                                                  (element.selected === true) ?
                                                  <input type="checkbox" className='m-2 border border-black' checked onChange={(e) => changedValue(e, element.value, element.description, index)}/>
                                                  : 
                                                  <input type="checkbox" className='m-2 border border-black' onChange={(e) => changedValue(e, element.value, element.description, index)}/>
                                          }
                                          {element.value}
                                      </span>
                                      <span className='text-sm mt-1 m-2'>{element.description}</span>

                                  </div>
                              ))}

                          </div>
                          

                      </div>
                  </React.Fragment>
                  }


                <div className="flex flex-row justify-center lg:justify-end w-auto mb-12">

                {params ?
                            <Link to={"/performance/team/employee/review/"+
                            encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                                employeeID: params.employeeID,
                                employee: params.employee,
                                companyID: companyID,
                                managerID: employeeID
                                }),Configs.privateKey).toString())}>
                                <button className="bg-green-200 p-2 pl-8 pr-8 rounded-full align-right m-4" onClick={submitManagerDraft}>
                                  {t('continue')}
                                </button>
                            </Link>
                        :
                                
                            <Link to='/performance/self/review'>
                              <button className="bg-green-200 p-2 pl-8 pr-8 rounded-full align-right m-4" onClick={submitDraft}> 
                                  {t('continue')}
                              </button>
                            </Link>
                  }
                </div>                           
                
            </div>
            </div>
            </div>
        </React.Fragment>
    );
  }
}

export default PerformanceGrowth;