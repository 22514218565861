// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';

// Icons
import { FaAward } from 'react-icons/fa';

// --------------------------------------------
function RecognitionHeader () {    
    const screens = [
        {view:t('recognition.home'), url:"/recognition/", securityLevel:[Configs.securityLevelIC], id:"recognition.header.home"},
        {view:t('recognition.leaderboard'), url:"/recognition/leaderboard/", securityLevel:[Configs.securityLevelIC], id:"recognition.header.leaderboard"},
    ];

    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('Recognition')} Icon={FaAward} breadcrumbs={false}/>
        </React.Fragment>
    );
       
}

export default RecognitionHeader;