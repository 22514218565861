// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';

// Icons
import { MdPunchClock } from 'react-icons/md';

// ------------------------------
function PunchHeader () {
    
    const screens = [
        {view:t('punchInOut'), url:"/punch/", securityLevel: [Configs.securityLevelIC]},
        {view:t('one-on-one.my-team'), url:"/punch/team/", securityLevel:[Configs.securityLevelManager]},
        {view:t('oneOnOne.hr'), url:"/punch/hr/", securityLevel:[Configs.securityLevelHR]},
       // {view:"Time off", url:"/profile/timeoff", securityLevel:1}
    ];
    
    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('PunchInOut')} Icon={MdPunchClock} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default PunchHeader;