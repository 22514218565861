import React, {useState, useEffect} from "react";
import Configs from '../Configs';
import { useTranslation } from 'react-i18next';

import {AiOutlineInfoCircle} from 'react-icons/ai';
import Tooltip from "../common/support/tooltip";

// TapTap text editor
import Editor from './editor';
import NoneditableEditor from './noneditableEditor';

// ------------------------------------------------------------------------------------------
/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function ValuesScoringWithComment(props) {
// Values that will be used 
// Translation object
const {t, i18n} = useTranslation();

// Props
const { values, readOnly, result, setValuesResult, selectedValues } = props;
if (Configs.devEnvironment) console.log ("[valuesScoringWithComment.js][ValuesScoringWithComment()] - props", props);

// State
const [selectedValuesState, setSelectedValuesState] = useState([]);
const [incorrectInput, setIncorrectInput] = useState(false);
const [showValuesDescription, setShowValuesDescription] = useState(false);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Get information to populate dashboard
    if (values) {
        setSelectedValuesState(values);
    } else {
        setSelectedValuesState([]);
    }
}, [values]);

// ------------------------------------------------------------------------------------------
// Form management methods
// ------------------------------------------------------------------------------------------
    async function changedValue (e, value, description, index) {
        e.preventDefault();
        // Create object to submit
        if (Configs.devEnvironment) console.log ("[valuesScoringWithComment.js][changedValue()] - value", e.target.value);
        
        const selected = e.target.value;

        // console.log ("Score is:", e.target.value);
        let isValid = true;
        if (selected < 1 || selected > 5) {
            isValid = false;
            setIncorrectInput(true);
        } else {
            setIncorrectInput(false);
        }

        // Create aux object
        const updatedValue = selectedValuesState[index];
        updatedValue.selected = isValid ? selected : 0;


        // Get an auxiliary array to update
        const updatedValues = [...selectedValuesState];
        updatedValues[index] = updatedValue;
        
        // Update state values
        setSelectedValuesState(updatedValues);
        setValuesResult(updatedValues);
        selectedValues(updatedValues, props.type);

        // Calculate the result
        let count = 0;
        let auxResult = 0;
        let incorrect = false;
        updatedValues.forEach(element => {
            count += 1;
            auxResult = parseInt(element.selected) + parseInt(auxResult);
            if (parseInt(element.selected) > 5 || parseInt(element.selected) < 1) {
                incorrect = true;
            }
        });
        setIncorrectInput(incorrect);
    }

    async function changedComment (answer, index ) {
        // e.preventDefault();
        if (Configs.devEnvironment) console.log ("[valuesScoringWithComment.js][changedComment()] - value", answer);

        // Get an auxiliary array to update
        const updatedValues = [...selectedValuesState];
        updatedValues[index].comment = answer;
        
        // Update state values
        //selectedValues = [...auxSelectedValues];
        setSelectedValuesState(updatedValues);
        setValuesResult(updatedValues);
        selectedValues(updatedValues, props.type);
    }

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-col mt-8 ml-2 m-1 '>
                            {/* <h3 className='flex text-l mb-4 italic font-bold'> 
                                {t('values-selector-scoring.title')}
                            </h3> */}
                            <span className='title-description'>
                                {t('values-selector-scoring.description')}
                            {/* <b>{t('values-selector-scoring.help')}</b> */}
                            </span>
        
        <div className='flex flex-col'>
        <button className="text-left text-xs ml-1 mt-1 underline" onClick={e => setShowValuesDescription(!showValuesDescription)}>
            <div className="flex flex-row items-center ">
            <AiOutlineInfoCircle />  <span className="ml-1">{t('performance.values.show.values.description')}</span>
            </div>
        </button>
                                    
            {selectedValuesState?.map((element, index) => (
                    
                    <div className="flex flex-col p-2 m-1 w-full"> 
                            <div className='flex flex-col lg:flex-row items-center justify-between'>
                                {/* Value and Description */}
                                <div className="flex flex-col">
                                    <button className='flex text-lg mb-0 m-2 font-bold hover:underline' onClick={e => setShowValuesDescription(!showValuesDescription)}>
                                        {element.value}
                                    </button>
                                    <span className={showValuesDescription ? "title-description mt-1 m-2" : "hidden"}>
                                        {element.description}
                                    </span>
                                </div>
                                {/* Rating buttons */}
                                <div className='flex flex-row items-end text-l mb-0 m-2 items-center text-center '>
                                    <Tooltip content={t('widgets.performance.values-selector-scoring.tooltip.1')}>
                                        <button className={" flex px-4 py-2 bg-green-100 rounded-full mr-2 " + ((element?.selected?.toString() === "1") ? " border-4 border-black" :
                                            " hover:shadow-xl hover:border hover:border-gray-300 hover:font-bold ")}
                                        value={1} onClick={(e) => changedValue(e, element.value, element.description, index)} disabled = {readOnly ? true : false}> 1 </button>
                                    </Tooltip>
                                    <Tooltip content={t('widgets.performance.values-selector-scoring.tooltip.2')}>
                                    <button className={" flex px-4 py-2 bg-green-200 rounded-full mr-2  " + ((element?.selected?.toString() === "2") ? " border-4 border-black" :
                                        " hover:shadow-xl hover:border hover:border-gray-300 hover:font-bold ")}                            
                                        value={2} onClick={(e) => changedValue(e, element.value, element.description, index)} disabled = {readOnly ? true : false}> 2 </button>
                                    </Tooltip>
                                    <Tooltip content={t('widgets.performance.values-selector-scoring.tooltip.3')}>
                                    <button className={" flex px-4 py-2 bg-green-300 rounded-full mr-2 " + ((element?.selected?.toString() === "3") ? " border-4 border-black" :
                                        " hover:shadow-xl hover:border hover:border-gray-300 hover:font-bold ")}                            
                                        value={3} onClick={(e) => changedValue(e, element.value, element.description, index)} disabled = {readOnly ? true : false}> 3 </button>
                                    </Tooltip>
                                    <Tooltip content={t('widgets.performance.values-selector-scoring.tooltip.4')}>                                    
                                    <button className={" flex px-4 py-2 bg-green-400 rounded-full mr-2  " + ((element?.selected?.toString() === "4") ? " border-4 border-black" :
                                        " hover:shadow-xl hover:border hover:border-gray-300 hover:font-bold ")}                            
                                        value={4} onClick={(e) => changedValue(e, element.value, element.description, index)} disabled = {readOnly ? true : false}> 4 </button>
                                    </Tooltip>
                                    <Tooltip content={t('widgets.performance.values-selector-scoring.tooltip.5')}>                                    
                                    <button className={" flex px-4 py-2 bg-green-500 rounded-full mr-2 " + ((element?.selected?.toString() === "5") ? " border-4 border-black" :
                                        " hover:shadow-xl hover:border hover:border-gray-300 hover:font-bold ")}                            
                                        value={5} onClick={(e) => changedValue(e, element.value, element.description, index)} disabled = {readOnly ? true : false}> 5 </button>
                                    </Tooltip>
                                </div>
                            </div>
                            {/* Comment */}
                            <div className='flex flex-col w-full items-start p-2 space-y-2 '>
                                <div className="text-sm text-gray-600">{t('comment')}</div>
                                {readOnly ? 
                                     // non-editable Editor
                                    <div className='border-l-4 border-gray-200'>
                                        <NoneditableEditor content={element.comment} placeholder={t('form.fill-details')} />
                                    </div>
                                 : 
                                   <div className="flex flex-col items-start w-full input-field ">
                                     <Editor setContent={changedComment} content={element.comment} initialContent={element.comment} placeholder={t('form.fill-details')} 
                                        editorType={"valuesSelectorWithComments"} index={index}/>
                                   </div>    
                            
                            }
                            </div>
                    </div>
                ))}

        </div>  
        <div className="flex flex-row items-end justify-end mr-6 p-4 text-right ">
            <div className="p-4 ">
            { t('score')}
            <span className="ml-4 text-4xl font-bold ">{isNaN(result) ? 0 : result}</span>
            </div>
        </div>

        <span className="mr-8 text-red-600 text-right font-bold">
            {incorrectInput ? t('values-selector-scoring.error') : null}
        </span>        

    </div>

  );
}

export default ValuesScoringWithComment;