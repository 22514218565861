import Configs from './Configs';

const {LogLevel} = require('@azure/msal-browser');

const loggerOptions = {
  loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    // case LogLevel.Info:		
                    //     console.info(message);		
                    //     return;		
                    // case LogLevel.Verbose:		
                    //     console.debug(message);		
                    //     return;		
                    // case LogLevel.Warning:		
                    //     console.warn(message);		
                    //     return;		
                }	
            }	
        }
};

export const msalConfig = {
    auth: {
      clientId: Configs.azureSandboxClientID,
      // https://learn.microsoft.com/en-us/azure/active-directory/develop/authentication-national-cloud
      authority: Configs.azureAuthorizationEndpoint,
      clientSecret: Configs.azureSandboxSecret,
      redirectUri: Configs.integrationCallbackURL,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	loggerOptions }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    // https://learn.microsoft.com/en-us/graph/deployments
      graphMeEndpoint: Configs.azureGraphEndpoint
  };
