// React
import React, { useEffect, useState} from 'react';
import {Link, Navigate, useLocation, useNavigate, useParams} from 'react-router-dom';

// Frameworks
import Configs from '../../Configs';
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Headers
import PulseHeader from './pulseHeader';
import Header from '../../common/header';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import CronGenerator from '../../widgets/cronGenerator';
import PulseQuestionsList from '../../widgets/pulseQuestionsList';
import NoneditableEditor from '../../widgets/noneditableEditor';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineInfoCircle} from 'react-icons/ai';

// ---------------------------------------------------------------------------------------------------
function PulseCreateCampaign () {
  const navigate = useNavigate();

  // Params for edition
  let { campaignID } = useParams();
  //const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
  const params = campaignID ? campaignID : null;

    const [pulseCampaign, setPulseCampaign] = useState([null]);

    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const { token, companyID, employeeID, securityLevel } = useAuth();
    // Toaster
    const [toasterMessage, setToasterMessage] = useState(null);
    const [isCampaignLoading, setIsCampaignLoading] = useState(false);
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(false);

    // Campaign Attributes
    const [campaignName, setCampaignName] = useState(null);
    const [campaignType, setCampaignType] = useState(null);
    const [campaignStartDate, setCampaignStartDate] = useState(null);
    const [campaignEndDate, setCampaignEndDate] = useState(null);
    const [campaignQuestions, setCampaignQuestions] = useState([null]);
    const [campaignEmailSubject, setCampaignEmailSubject] = useState(null);

    // Cron tab default attributes
    const [minute, setMinute] = useState('00');
    const [hour, setHour] = useState('09');
    const [amPm, setAmPm] = useState('AM');
    const [dayOfMonth, setDayOfMonth] = useState('*');
    const [month, setMonth] = useState('*');
    const [dayOfWeek, setDayOfWeek] = useState('*');

    // All questions
    const allPulseQuestionsAux = sessionStorage.getItem('allPulseQuestions') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('allPulseQuestions'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : [null];
    const [allPulseQuestions, setAllPulseQuestions] = useState(allPulseQuestionsAux);


    if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][main()] DEV ONLY - Campaign = ", pulseCampaign);
    if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][main()] DEV ONLY -  questions ", campaignQuestions);


// ---------------------------------------------------------------------------------------------------
// UseEffect
// ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    useEffect (() => {
      if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][useEffect()] useEffect - params = ", params, allPulseQuestions.length, campaignQuestions);

      if (!allPulseQuestions || allPulseQuestions.length < 2) {
        getAllQuestions();
      } else {
        if (params) {
          getCampaignById(params);
        }
      }

      }, []);

// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------
async function getAllQuestions () {
    setIsQuestionsLoading(true);

    // fetch from server      
    fetch(Configs.pulseGetAllQuestionsAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
      }})
        .then((response) => {
          setIsQuestionsLoading(false);

          if (response.status === 200) {
            // We have a match
            response.json()
            .then ( body => {
                if (Configs.devEnvironment) console.log("[pulseCreateCampaign.js][getAllQuestions()] questions: ", body);
                setAllPulseQuestions(body);
                sessionStorage.setItem('allPulseQuestions', CryptoJS.AES.encrypt(JSON.stringify(body), Configs.privateKey).toString());

                // If we have params, we need to load the campaign - we are in Edit mode
                if (params) {
                  getCampaignById(params, body);
                }
              })
            .catch(error => {
              console.error("[pulseCreateCampaign.js][getAllQuestions()]  Error parsing response JSON", error);
              setAllPulseQuestions(null);
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
            });
          } else if (response.status === 400) {
            // Something went wrong - inform user
            setAllPulseQuestions(null);
            setToasterMessage({message: t('warning'), type: Configs.warningToaster});
          } else if (response.status === 401) {
              // Force logout
              setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
              setAllPulseQuestions(null);
                sessionStorage.clear();
                window.location.reload();
          } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                setAllPulseQuestions(null);
                sessionStorage.clear();
                window.location.reload();
          } else if (response.status === 404) {
              setAllPulseQuestions(null);
              setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
          } else {
            //
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            setAllPulseQuestions(null);
            setIsQuestionsLoading(false);
            throw response;
          }
        })
  
        .catch((error) => {
          console.error("[pulseCreateCampaign.js][getAllQuestions()]  Error fetching data: ", error);
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          setAllPulseQuestions(null);
          setIsQuestionsLoading(false);
        });
}    

async function getCampaignById (campaignID, allPulseQuestionsAux) {
        // Start loader
        setIsCampaignLoading(true);

        // fetch from server      
        fetch(Configs.platformGetPulseCampaignIDAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
          }})
            .then((response) => {
                // We received a response, stop loading
                setIsCampaignLoading(false);

              if (response.status === 200) {
                // We have a match
                response.json()
                .then ( body => {
                    if (Configs.devEnvironment) console.log("[pulseCreateCampaign.js][getCampaignById()]  Campaign (Body): ", body);
                    if (body) {
                      setPulseCampaign(body);
                      transformQuestionsArray(body.questions, allPulseQuestionsAux);    
                      transformCronTabString(body.frequency);
                      transformDateFormat(new Date(body.startDate), "startDate");
                      transformDateFormat(new Date(body.endDate), "endDate");
                      setCampaignName(body.campaignName);
                      setCampaignEmailSubject(body.emailSubject);
                      setCampaignType(body.type);
                      // setToasterMessage({message: (t('success')), type: Configs.successToaster});
                    }
                  })
                .catch(error => {
                    console.error("[pulseCreateCampaign.js][getCampaignById()] Error parsing response JSON", error);
                    setToasterMessage({message: t('error'), type: Configs.errorToaster});
                    setPulseCampaign(null);
                });
                } else if (response.status === 400) {
                    // Something went wrong - inform user
                    setPulseCampaign(null);
                    setToasterMessage({message: t('warning'), type: Configs.warningToaster});
              } else if (response.status === 401) {
                    // Force logout
                    setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                    setPulseCampaign(null);
                    sessionStorage.clear();
                    window.location.reload();
              } else if (response.status === 403) {
                    // Force logout
                    setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                    setPulseCampaign(null);
                    sessionStorage.clear();
                    window.location.reload();
              } else if (response.status === 404) {
                    setPulseCampaign(null);
                    setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
              } else {
                //
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                setPulseCampaign(null);
                throw response;
              }
            })
      
            .catch((error) => {
                console.error("[pulseCreateCampaign.js][getCampaignById()] Error fetching data: ", error, " - function: GetCampaigns");
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
                // We received a response, stop loading
                setIsCampaignLoading(false);
            });
    }

    async function createCampaign (campaignObject) {

      fetch(Configs.platformPostPulseCampaignAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        },      
        body: JSON.stringify({
          companyID: companyID,
          employeeID: employeeID,
          pulseCampaign: campaignObject
        }),
        })
          .then((response) => {
            // if (Configs.devEnvironment) console.log (response);
            if (response.status === 200) {
              setToasterMessage({message: t('success'), type: Configs.successToaster});

              // Clear frontend fields
              setCampaignName(null);
              setCampaignStartDate(null);
              setCampaignEndDate(null);
              setCampaignType(null);
              setCampaignQuestions([null]);
              setCampaignEmailSubject(null);

              // Remove questions so they can be re-fetched
              sessionStorage.removeItem('allPulseQuestions');

              // Pass toaster message and go back
              navigate(-1, { state: { toasterMessage: {message:(t('success')), type: Configs.successToaster} } });

            } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
          })
    
          .catch((error) => {
            console.error("[pulseCreateCampaign.js][createCampaign()] error:", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});

          });
    }

    async function editCampaign (campaignObject, campaignID) {

      fetch(Configs.platformUpdatePulseCampaignAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&campaignID=" + campaignID, {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        },      
        body: JSON.stringify({
          companyID: companyID,
          employeeID: employeeID,
          pulseCampaign: campaignObject
        }),
        })
          .then((response) => {
            // if (Configs.devEnvironment) console.log (response);
            if (response.status === 200) {
              setToasterMessage({message: t('success'), type: Configs.successToaster});

              // Clear frontend fields
              setCampaignName(null);
              setCampaignStartDate(null);
              setCampaignEndDate(null);
              setCampaignType(null);
              setCampaignQuestions([null]);
              setCampaignEmailSubject(null);

              // Pass toaster message and go back
              navigate(-1, { state: { toasterMessage: {message:(t('success')), type: Configs.successToaster} } });

            } else if (response.status === 400) {
              // Something went wrong - inform user
              setToasterMessage({message: t('warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
          })
    
          .catch((error) => {
            console.error("[pulseCreateCampaign.js][createCampaign()] error:", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});

          });
    }

    
// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
        };

// ---------------------------------------------------------------------------------------------------
// Frontend methods
// ---------------------------------------------------------------------------------------------------
// Validate inputs Create
async function validateInputsCreate() {

  // Check for empty fields
  if ((campaignName === null || campaignName === "") || (campaignType === null || campaignType === "")
  || (campaignStartDate === null || campaignStartDate === "") || (campaignEndDate === null || campaignEndDate === "")
  || (campaignQuestions === null  || 
    ((campaignType === "survey" || campaignType === "standard") && campaignQuestions.length < 2)
    || (campaignType === "eNPS" && campaignQuestions.length < 2)
    )) {
      setToasterMessage({message: t('field.missing'), type: Configs.warningToaster});
      return;
  } else {

        // Check for duplicates in selected questions
      var duplicates = [];
      campaignQuestions.forEach(question => {
        // Check if question is already in array
        if (duplicates.includes(question?.question?.value)) {
          // Question already in array - do nothing
        } else {
          // Question not in array - add it
          duplicates.push(question?.question?.value);
        }
      });

      if (duplicates.length !== campaignQuestions.length) {
        if (Configs.devEnvironment) console.log("[pulseCreateCampaign.js][validateInputs()]  duplicates: ", duplicates);
        setToasterMessage({message: t('pulse.warning.duplicates'), type: Configs.warningToaster});
        return;
      }

      // Prepare auxObject for API call
        var auxObject = {
          name: campaignName.toString(),
          companyID: parseInt(companyID),
          type: campaignType.toString(),
          frequencyHour: hour.toString(),
          frequencyMinute: minute.toString(),
          frequencyAMPM: amPm.toString(),
          frequencyDaysOfWeek: dayOfWeek.toString(),
          frequencyDayOfMonth: dayOfMonth.toString(),
          frequencyMonths: month.toString(),
          emailSubject: campaignEmailSubject.toString(),
          startDate: new Date(campaignStartDate),
          endDate: new Date(campaignEndDate),
          questions: campaignQuestions
        }

        if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][validateInputs()]  auxObject: ", auxObject);
        createCampaign(auxObject);

  }
}

// Validate inputs Edit
async function validateInputsEdit() {

  // Check for empty fields
  if ((campaignName === null || campaignName === "") 
  || (campaignStartDate === null || campaignStartDate === "") || (campaignEndDate === null || campaignEndDate === "")
  || (campaignQuestions === null  || 
    ((campaignType === "survey" || campaignType === "standard") && campaignQuestions.length < 2)
    || (campaignType === "eNPS" && campaignQuestions.length < 2)
    )) {
      setToasterMessage({message: t('field.missing'), type: Configs.warningToaster});
      return;
  } else {

        // Check for duplicates in selected questions
      var duplicates = [];
      campaignQuestions.forEach(question => {
        // Check if question is already in array
        if (duplicates.includes(question?.question?.value)) {
          // Question already in array - do nothing
        } else {
          // Question not in array - add it
          duplicates.push(question?.question?.value);
        }
      });

      if (duplicates.length !== campaignQuestions.length) {
        if (Configs.devEnvironment) console.log("[pulseCreateCampaign.js][validateInputs()]  duplicates: ", duplicates);
        setToasterMessage({message: t('pulse.warning.duplicates'), type: Configs.warningToaster});
        return;
      }

      // Prepare auxObject for API call
        var auxObject = {
          _id: pulseCampaign._id,
          name: campaignName.toString(),
          companyID: parseInt(companyID),
          type: campaignType.toString(),
          frequencyHour: hour.toString(),
          frequencyMinute: minute.toString(),
          frequencyAMPM: amPm.toString(),
          frequencyDaysOfWeek: dayOfWeek.toString(),
          frequencyDayOfMonth: dayOfMonth.toString(),
          frequencyMonths: month.toString(),
          emailSubject: campaignEmailSubject.toString(),
          startDate: new Date(campaignStartDate),
          endDate: new Date(campaignEndDate),
          questions: campaignQuestions
        }

        if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][validateInputsEdit()]  auxObject: ", auxObject);
        editCampaign(auxObject, pulseCampaign._id);

  }
}

async function transformQuestionsArray (questionsArray, allPulseQuestionsAux) {
  var auxArray = [];

  questionsArray.forEach(element => {
    // Get question label from array of questions
    var auxLabel = "";
    if (allPulseQuestionsAux) {
      auxLabel = allPulseQuestionsAux?.find(question => question?._id?.toString() === element?.question?.toString());  
    } else {
      auxLabel = allPulseQuestions?.find(question => question?._id?.toString() === element?.question?.toString());
    }
    
    if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][transformQuestionsArray()]  element: ", element, auxLabel, allPulseQuestions?.length);

    // create aux element
    var auxElement = {
      question: {
        value: element?.question,
        label: auxLabel?.Question_ESP,
      },
      frequency: {
        value: element?.frequency,
        label: t(element?.frequency)
      }
    }
    // Add aux element to array
    auxArray.push(auxElement);
    if (Configs.devEnvironment) console.log ("[pulseCreateCampaign.js][transformQuestionsArray()]  auxElement: ", auxElement);
  });
  // Set campaignQuestions array to update screen
  setCampaignQuestions([...auxArray]);
}

async function transformCronTabString (cronTabString) {
  if (!cronTabString) return;

  // Split cronTabString
  var [minuteAux, hourAux, dayOfMonthAux, monthAux, daysOfWeekAux] = cronTabString?.split(" ");

  // Set minute
  setMinute(minuteAux);

  // Translate time from server
  const now = new Date();
  const userTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(hourAux), parseInt(minuteAux));
  const adjustedTime = new Date(userTime.getTime() + 2 * 60 * 60 * 1000);
  hourAux = adjustedTime.getHours();

  // Set amPm
  if (hourAux > 12) {
    setAmPm('PM');
  } else {
    setAmPm('AM');
  }

  // Set hour
  if (hourAux > 12) {
    if ((hourAux - 12) < 10 ) {
      hourAux = "0" + (hourAux - 12).toString();
      setHour(hourAux);

    } else {
      setHour(hourAux - 12);
    }
  } else {
    if (hourAux < 10 ) {
      hourAux = "0" + hourAux.toString();
      setHour(hourAux);

    }
      setHour(hourAux);
  }

  // Set dayOfMonth
  setDayOfMonth(dayOfMonthAux);

  // Set month
  setMonth(monthAux);

  // Set daysOfWeek
  setDayOfWeek(daysOfWeekAux);

}

async function transformDateFormat (date,dateType) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
  const day = date.getDate().toString().padStart(2, '0');

  if (Configs.devEnvironment) console.log(`[pulseCreateCampaign.js][transformDateFormat()]  date: ${year}-${month}-${day}`);

  if (dateType === "startDate") {
    setCampaignStartDate(`${year}-${month}-${day}`);
  } else if (dateType === "endDate") {
    setCampaignEndDate(`${year}-${month}-${day}`);
  }
}

// Refresh allPulseQuestions
async function reloadAllPulseQuestions() {
  const allPulseQuestionsAux = sessionStorage.getItem('allPulseQuestions') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('allPulseQuestions'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : [null];
  setAllPulseQuestions([...allPulseQuestionsAux]);
}

// Cancel button pressed      
async function cancel (e) {
  e.preventDefault();

  navigate(-1);
}

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
            <Header />
            <Bot />

      <div className="main">
            <PulseHeader />
            
      <div className='flex flex-row items-start'>
        <div className="left-big-view">
        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


        <div className="flex flex-col text-left">

        <div className="flex flex-col mt-2 md:ml-2 m-1">
                <div className='flex flex-col'>
                    <h2 className="flex text-xl mb-4 ">
                      <div className='flex flex-col items-start md:flex-row justify-left'>
                            {params ? 
                              <Tooltip content={t('pulse.campaigns.edit.description')}>
                                <div className='section-title'>
                                  {t('pulse.campaigns.edit')}
                                </div>
                              </Tooltip>
                              :
                              <Tooltip content={t('pulse.campaigns.create.description')}>
                                <div className='section-title'>
                                {t('pulse.campaigns.create')  }
                                </div>
                              </Tooltip>
                            }
                      </div>
                    </h2>
                </div>

                
            </div>

            {isCampaignLoading ? 
              <Loader />
            :

                <React.Fragment>

                    {pulseCampaign?.length < 1 || pulseCampaign === null ?
                            <React.Fragment >
                                    
                                    <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                                        <span className="title-description italic">📊 {" " + t('pulse.load.success.empty')}{" "}
                                        </span>
                                    </div>
                            </React.Fragment>    

                    :
                
                    <React.Fragment>

                                    <div className="flex flex-row mt-2 lg:ml-2 m-1">


                                        <div class="mt-10 grid grid-cols-3 gap-x-4 gap-y-4 ">
                                            {/* Campaign name */}
                                            <div className='col-span-1'>
                                              <label for="name" className="block text-sm font-medium leading-6 text-gray-900">{t('pulse.campaign-name')}</label>
                                            </div>
                                            <div className="col-span-2">
                                                    
                                                  <div className="flex sm:max-w-md">
                                                    <input  type="text" id="name" value={campaignName} onChange={(e) => {
                                                      setCampaignName(e.target.value)
                                                    }}
                                                    className="block flex-1 input-field border-0" 
                                                    placeholder={t('pulse.campaign-name.placeholder')} />
                                                  <div className='group relative flex items-center justify-center mr-2 w-max'>
                                                    <AiOutlineInfoCircle className='text-gray-500 hover:cursor-pointer' />  


                                                      <div className="hidden group-hover:block absolute top-7 bg-gray-300 p-1 
                                                      rounded items-center justify-center shadow-2xl w-80
                                                      text-sm h-auto z-40">
                                                          <NoneditableEditor
                                                              content={t('pulse.campaign-name.info')}
                                                              />
                                                      </div>
                                                  </div>
                                                  </div>
                                            </div>

                                            {/* Campaign type */}
                                            <div className='col-span-1'>
                                              <label for="name" className="block text-sm font-medium leading-6 text-gray-900">{t('pulse.campaign-type')}</label>
                                            </div>
                                            <div className='col-span-2'>
                                                <div className="mt-6 space-y-6">

                                                    <div className="flex items-center gap-x-3 col-span-1">
                                                        <input id="type " name="type" value="standard" type="radio" onChange={(e) => setCampaignType(e.target.value)}
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-green-200"
                                                        checked={campaignType === "standard"}
                                                        disabled={params ? true : false}
                                                        />
                                                        <label for="type" className="block text-sm font-medium leading-6 text-gray-900">{t('standard')}</label>
                                                        <div className='group relative flex items-center justify-center mr-2 w-max'>
                                                          <AiOutlineInfoCircle className='text-gray-500 hover:cursor-pointer' />  


                                                            <div className="hidden group-hover:block absolute top-7 bg-gray-300 p-1 
                                                            rounded items-center justify-center shadow-2xl w-80
                                                            text-sm h-auto z-40">
                                                            <NoneditableEditor
                                                                    content={t('pulse.campaign-type.tooltip.standard')}
                                                                    />
                                                            </div>
                                                        </div>
                                                        <p>
                                                          
                                                        </p>
                                                    </div>
                                                    {campaignType === "standard" ? 
                                                          <div className="flex items-center gap-x-3 col-span-1">
                                                                <p className="text-sm text-gray-500">{t('pulse.campaign-type.info.standard')}</p>
                                                          </div>
                                                      :
                                                        null
                                                      }
                                                    <div className="flex items-center gap-x-3 col-span-1">
                                                        <input id="type" name="type" value="survey" type="radio" onChange={(e) => setCampaignType(e.target.value)}
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-green-200"
                                                        checked={campaignType === "survey"}
                                                        disabled={params ? true : false}
                                                        />
                                                        <label for="valuesScoring" className="block text-sm font-medium leading-6 text-gray-900"> {t('survey')} </label>
                                                        <div className='group relative flex items-center justify-center mr-2 w-max'>
                                                          <AiOutlineInfoCircle className='text-gray-500 hover:cursor-pointer' />  


                                                            <div className="hidden group-hover:block absolute top-7 bg-gray-300 p-1 
                                                            rounded items-center justify-center shadow-2xl w-80
                                                            text-sm h-auto z-40">
                                                            <NoneditableEditor
                                                                    content={t('pulse.campaign-type.tooltip.survey')}
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {campaignType === "survey" ? 
                                                          <div className="flex items-center gap-x-3 col-span-1">
                                                                <p className="text-sm text-gray-500">{t('pulse.campaign-type.info.survey')}</p>
                                                          </div>
                                                      :
                                                        null
                                                      }
                                                    <div className="flex items-center gap-x-3 col-span-1">
                                                        <input id="type" name="type" value="eNPS" type="radio" onChange={(e) => setCampaignType(e.target.value)}
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-green-200"
                                                        checked={campaignType === "eNPS"}
                                                        disabled={params ? true : false}
                                                        />
                                                        <label for="valuesScoring" className="block text-sm font-medium leading-6 text-gray-900"> {t('eNPS')} </label>
                                                        <div className='group relative flex items-center justify-center mr-2 w-max'>
                                                          <AiOutlineInfoCircle className='text-gray-500 hover:cursor-pointer' />  


                                                            <div className="hidden group-hover:block absolute top-7 bg-gray-300 p-1 
                                                            rounded items-center justify-center shadow-2xl w-80
                                                            text-sm h-auto z-40">
                                                            <NoneditableEditor
                                                                    content={t('pulse.campaign-type.tooltip.eNPS')}
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {campaignType === "eNPS" ? 
                                                          <div className="flex items-center gap-x-3 col-span-1">
                                                                <p className="text-sm text-gray-500">{t('pulse.campaign-type.info.eNPS')}</p>
                                                          </div>
                                                      :
                                                        null
                                                      }
                                                </div>
                                            </div>

                                            {(campaignType === "standard" || campaignType === "eNPS") ? 
                                                <React.Fragment>
                                                  <div className='mt-4 col-span-1'>
                                                    <label for="name" className="block text-sm font-medium leading-6 text-gray-900">{t('frequency')}</label>
                                                  </div>

                                                  <div className='mt-4 col-span-2'>
                                                      <CronGenerator 
                                                      timeSelector={true} dayOfWeekSelector={true} monthSelector={true} dayOfMonthSelector={false}
                                                      minute={minute} setMinute={setMinute} hour={hour} setHour={setHour} amPm={amPm} setAmPm={setAmPm}
                                                      dayOfMonth={dayOfMonth} setDayOfMonth={setDayOfMonth} month={month} setMonth={setMonth} 
                                                      dayOfWeek ={dayOfWeek} setDayOfWeek={setDayOfWeek} />
                                                  </div>
                                                </React.Fragment>
                                              :

                                              campaignType === "survey" ?
                                                <React.Fragment>
                                                  <div className='mt-4 col-span-1'>
                                                    <label for="name" className="block text-sm font-medium leading-6 text-gray-900">{t('frequency')}</label>
                                                  </div>

                                                  <div className='mt-4 col-span-2'>
                                                    <CronGenerator 
                                                      timeSelector={true} dayOfWeekSelector={false} monthSelector={false} dayOfMonthSelector={false}
                                                      minute={minute} setMinute={setMinute} hour={hour} setHour={setHour} amPm={amPm} setAmPm={setAmPm}
                                                      dayOfMonth={dayOfMonth} setDayOfMonth={setDayOfMonth} month={month} setMonth={setMonth} 
                                                      dayOfWeek ={dayOfWeek} setDayOfWeek={setDayOfWeek} />
                                                  </div>
                                                </React.Fragment>
                                              :
                                                null

                                              }

                                            {/* Campaign dates */}
                                            <div className='mt-6 col-span-1'>
                                              <label for="name" className="block text-sm font-medium leading-6 text-gray-900">{t('start-date')}</label>
                                            </div>
                                            <div className='mt-6 col-span-2'>
                                            <input type="date" id="campaignStartDate" className='input-field ' 
                                                 value={campaignStartDate}
                                                 disabled={params && new Date(campaignStartDate) < new Date() ? true : false}
                                                 onChange={(e) => setCampaignStartDate(e.target.value)}/>
                                            </div>
                                            <div className='mt-6 col-span-1'>
                                              <label for="name" className="block text-sm font-medium leading-6 text-gray-900">{t('end-date')}</label>
                                            </div>
                                            <div className='mt-6 col-span-2'>
                                            <input type="date" id="campaignEndDate" className='input-field' 
                                                 value={campaignEndDate}
                                                //  disabled={new Date(campaignEndDate) <= new Date() ? true : false}
                                                 onChange={(e) => setCampaignEndDate(e.target.value)}/>   
                                            </div>

                                            {/* Email subject */}
                                              <div className='mt-6 col-span-1'>
                                                <label for="name" className="block text-sm font-medium leading-6 text-gray-900">{t('pulse.email-subject')}</label>
                                              </div>

                                              <div className="mt-6 col-span-2">
                                                      
                                                    <div className="flex  sm:max-w-md">
                                                      <input type="text" name="subject" id="subject" value={campaignEmailSubject} onChange={(e) => setCampaignEmailSubject(e.target.value)}
                                                      className="block flex-1 input-field border-0" 
                                                      placeholder={t('pulse.email-subject.placeholder')} />
                                                    <div className='group relative flex items-center justify-center mr-2 w-max'>
                                                      <AiOutlineInfoCircle className='text-gray-500 hover:cursor-pointer' />  


                                                        <div className="hidden group-hover:block absolute top-7 bg-gray-300 p-1 
                                                        rounded items-center justify-center shadow-2xl w-80
                                                        text-sm h-auto z-40">
                                                            <NoneditableEditor
                                                                content={t('pulse.email-subject.info')}
                                                                />
                                                        </div>
                                                    </div>
                                                    </div>
                                              </div>

                                        </div>

                                        

                                    </div>

                                    

                                    {/* Questions */}
                                    
                                    <div className='flex flex-col  text-left space-y-2 ml-2 mt-8'>
                                      <h2 className=' text-sm font-medium'> {t('pulse.questions.title')} </h2>
                                      <div className='flex title-description'> {t('pulse.questions.description')} </div>
                                    </div>

                                    <div className='flex w-full'>  
                                      { isQuestionsLoading ?    
                                            <Loader />
                                          :
                                          campaignType === "standard" ?
                                            <PulseQuestionsList  questionsArray={campaignQuestions} setQuestionArray={setCampaignQuestions}
                                            allPulseQuestions={allPulseQuestions} requestFrequency={true}
                                            reloadDropdown={reloadAllPulseQuestions} />
                                          :
                                            <PulseQuestionsList  questionsArray={campaignQuestions} setQuestionArray={setCampaignQuestions}
                                            allPulseQuestions={allPulseQuestions} requestFrequency={false}
                                            reloadDropdown={reloadAllPulseQuestions} />
                                      }
                                    </div>
                            </React.Fragment>
                        }
                </React.Fragment>
            }
            
        </div>
        {securityLevel?.includes(Configs.securityLevelHR) ? 
                    <div className='flex flex-col m-4 lg:m-2 lg:flex-row items-center justify-end '>

                        <button type="button" id="edit" className="secondary-button " onClick={cancel}> {t('cancel')} </button>

                        {params ? 
                            <button className='save-button' onClick={validateInputsEdit}>{t('pulse.campaigns.edit')}</button>
                        :
                            <button className='save-button' onClick={validateInputsCreate}>{t('pulse.campaigns.create')}</button>
                        }
                        

                    </div>
                : 
                    null 
                }

          <SectionSeparator />

        </div>
        <div className="right-small-view">
              <div className='flex flex-row'>
                  <div className="mt-6 mr-4 space-y-6">
                    {campaignType === "standard" || campaignType === "survey" || campaignType === "eNPS" ?
                      <span className='text-sm'> {t('pulse.campaign-type.employee-view')} </span>
                    : 
                      null
                    }
                    
                    {campaignType === "standard" ?
                      <img className="hover:scale-150 transition duration-200 cursor-pointer border-black-500 border"
                      src="https://www.dropbox.com/scl/fi/rhrct0ln1ffcix1tpkyvu/Kincode-Pulse-1click-example.png?rlkey=y62jn924pe8jjoa5eg3nwjc9j&raw=1"></img>
                      
                      :
                      campaignType === "survey" ?
                      <img className="hover:scale-150 transition duration-200 cursor-pointer border-black-500 border"
                  src="https://www.dropbox.com/scl/fi/aamcu8nrep7a8hsyad648/Kincode-Pulse-Survey-example.png?rlkey=hrksc5qoin4hx1qernjetibvr&raw=1"></img>

                      :
                      campaignType === "eNPS" ?
                      <img className="hover:scale-150 transition duration-200 cursor-pointer border-black-500 border"
                  src="https://www.dropbox.com/scl/fi/ni2g7i4wf1leh3fk0esl9/Kincode-Pulse-eNPS-example.png?rlkey=qu3ut07n9n1txt51b5mijhndl&raw=1"></img>

                      :
                      null
                  }
                  
                  </div>
                </div>
        </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default PulseCreateCampaign;