// React
import React, {useState, useEffect} from 'react';

// Common & widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import ProfileHeader from './profileHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';

// 3P
import CryptoJS from 'crypto-js';

function ProfileJobInfo () {

  var currentJobComp = JSON.parse(sessionStorage.getItem('jobComp'));
    var [jobComp, setJobComp] = useState(currentJobComp ? currentJobComp : null);
    
    const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const {token} = useAuth();


  // Methods to load when page is loaded
  useEffect (() => {
      // Get information to populate dashboard
      if (jobComp === null) getJobCompensation();
    }, []);


  function getJobCompensation () {
      // fetch from server      
      fetch(Configs.platformGetJobCompAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}` // notice the Bearer before your token
        }})
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
              // Username and password mathched in the database - get Token and save it in the session
              response.json()
              .then ( body => {
                  setJobComp(body.profile);
                  sessionStorage.setItem('jobComp', JSON.stringify(body.profile));
                  // console.log(body.profile);
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 400) {
              // Force logout
                sessionStorage.clear();
                window.location.reload();
            } else {
              //
              throw response;
            }
          })
    
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: GetJobCompensation");
          });
  }
  
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="flex flex-col lg:ml-[215px]">
        <ProfileHeader />
            
            
            <div className="flex flex-col m-2 lg:m-4">

            <h2 className='m-2 text-xl text-left'>Compensation information</h2>

            <div className="flex flex-col m-4 border border-black p-4">
                <div className='flex flex-row mt-2 mb-2'>
                    <span>Gross salary: </span>
                    <span> {jobComp?.grossSalary} </span>
                </div>
                <div className='flex flex-row mt-2 mb-2'>
                    <span>Variable Salary: </span>
                    <span> {jobComp?.variableSalary * 100} %</span>
                </div>
                <div className='flex flex-row mt-2 mb-2'>
                    <span>Bonus: </span>
                    <span> {jobComp?.bonus} </span>
                </div>

                <div className='flex flex-row mt-2 mb-2'>
                    <span>Other: </span>
                    <span>{jobComp?.other}</span>
                </div>
            </div>
            </div>
      </div>


        
      </React.Fragment>
    );
}

export default ProfileJobInfo;