// React
import React, {useState, useEffect} from 'react';

// Common & Widgets
import Configs from "../../Configs";
import Header from '../../common/header';
import SettingsHeader from './settingsHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';


function SettingsHome () {
  const {t, i18n} = useTranslation();

  const employeeID = sessionStorage.getItem('employeeID') ? CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
  const companyID = sessionStorage.getItem('companyID') ? CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
  const { token } = useAuth();


    // Methods to load when page is loaded
    useEffect (() => {
      // Get information to populate dashboard
      // getSettingsForEmployeeAndCompany();
    }, []);

  // ---------------------------------------------------------------------------------------------------
  // API
  // ---------------------------------------------------------------------------------------------------

    async function getSettingsForEmployeeAndCompany () {
      // fetch from server      
      fetch(Configs.platformGetPulseHomeFrontendDataAPI + "?employeeID=" + employeeID + "&companyID=" + companyID)
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
              // Username and password mathched in the database - get Token and save it in the session
              response.json()
              .then (body => {
                  // console.log(body)
                  // sessionStorage.setItem('performanceCampaigns', JSON.stringify(body));
                  // sessionStorage.setItem('livePerformanceCampaign', true);
                })
              .catch(error => {console.error("Error parsing response JSON", error)});
            } else if (response.status === 403) {
                  // sessionStorage.removeItem('performanceCampaigns');
                  // sessionStorage.setItem('livePerformanceCampaign', false);
            } else if (response.status === 400) {
              // Force logout
                sessionStorage.clear();
                window.location.reload();
            } else {
              // There is an error - delete info
              // sessionStorage.removeItem('performanceCampaigns');
              // sessionStorage.setItem('livePerformanceCampaign', false);
              throw response;
            }
          })
    
          .catch((error) => {
            console.log("Error fetching data: ", error, " - function: GetPulseHomeFrontendData");
          });
  }

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="flex flex-col lg:ml-[215px]">
        <SettingsHeader />
            
            <div className="flex flex-col m-2 lg:m-4">        

        <div className="flex flex-col text-left ">
          <div className="flex flex-col mt-2 md:ml-2 m-1">
            <h2 className="flex text-xl mb-4 ">
              {" "}
              <div className='flex flex-col md:flex-row justify-left'>
               
                <div className='flex text-sm'>

                    </div>
              </div>
            </h2>
            <span className="text-left mb-4">
              {t('settings.description')}
              </span>
          </div>

          <div className="flex flex-col mt-2 md:ml-2 m-1 border border-gray rounded-lg bg-white shadow-xl p-4">
            <h2 className="flex text-xl mb-4 font-bold">{t('settings.language')}</h2>
            <div className='flex flex-col md:flex-row text-left mb-4 ml-4 items-left md:items-center'>
                <div className='flex flex-row mb-4 md:mb-0 items-center'>
                  <input className="mr-2" type="radio" onClick={() => i18n.changeLanguage('es')} name="gender" /> Español
                </div>
                <div className='flex flex-row items-center'>
                  <input className="md:ml-8 mr-2" type="radio" onClick={() => i18n.changeLanguage('en')} name="gender" /> English
                </div>
            </div>
          </div>
          
        </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default SettingsHome;