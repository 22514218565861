// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';

// Icons
import {IoPersonAdd } from "react-icons/io5";

// ------------------------------------------------------
function OnboardingHeader () {

    const screens = [
        {view:t('onboarding.header.self'), url:"/onboarding/", securityLevel:[Configs.securityLevelIC], id:"onboarding.header.self"},
        {view:t('onboarding.header.HR'), url:"/onboarding/hr", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR, Configs.securityLevelExec], id:"onboarding.header.hr"},
        {view:t('reporting'), url:"/onboarding/reporting", securityLevel:[Configs.securityLevelHR, Configs.securityLevelExec], id:"onboarding.header.reporting"}
    ];

    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('Onboarding')} Icon={IoPersonAdd} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default OnboardingHeader;