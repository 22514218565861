// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';

// Icons
import { FaBalanceScale} from "react-icons/fa";

// ------------------------------------------------------
function CalibrationHeader () {

    const screens = [
        {view:t("calibration.team.title"), url:"/calibration/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR, Configs.securityLevelExec], id: "calibration.header.team"},
        {view:t("calibration.map.title"), url:"/calibration/talentMap/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR, Configs.securityLevelExec], id: "calibration.header.map"},
        {view:t('oneOnOne.hr'), url:"/calibration/hr/", securityLevel:[Configs.securityLevelHR, Configs.securityLevelExec], id:"calibration.header.hr"},
    ];

    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('Calibration')} Icon={FaBalanceScale} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default CalibrationHeader;