// React
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

// Amplitude analytics
import * as amplitude from '@amplitude/analytics-browser';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common
import Configs from './Configs';
import Header from './common/header';
import SectionSeparator from './widgets/sectionSeparator';
import Bot from './common/support/bot';
import HomeHeader from './common/homeHeader';
import Forbidden from './common/forbidden';
import Pagination from './widgets/pagination';
import { useAuth } from './common/appContext';
import { useData } from './common/dataContext';

// Widgets
import UserListItem from './widgets/userListItem';
import KPIGraph from './widgets/KPIgraph';

// Toaster and Loader
import Toaster from './common/support/toaster';
import Loader from './common/support/loader';

// Icons
import {CgPerformance} from "react-icons/cg";
import {IoPerson, IoPulseSharp } from "react-icons/io5";
import {FaPeopleArrows, FaBalanceScale, FaAward} from 'react-icons/fa';
import {PiPlantBold} from 'react-icons/pi'; 
import {IoPersonAdd} from 'react-icons/io5';
import { GiPalmTree } from 'react-icons/gi';
import Tooltip from './common/support/tooltip';
import PunchWidget from './widgets/daysOff/punchInOut';

// ---------------------------------------------------------------------------------------------------
function Home () {
    const {t} = useTranslation();
    const navigate = useNavigate();

    // Forbiden access
    const [forbidden, setForbidden] = useState(false);

    // Basic information
    const {companyID, employeeID, token, setToken, securityLevel} = useAuth();
    const {companyPreferences, toasterMessage, setToasterMessage} = useData();
    const {getPendingToDos, pendingToDos, isPendingToDosLoading} = useData();
    const {keyKPIs, isKeyKPIsLoading, getKeyKPIs} = useData();

    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    const today = new Date();
    today.setHours(0,0,0,0);    

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Default number of rows per page

    if (Configs.devEnvironment) console.log("[Home.js][main()] - token: ", token);

    // ---------------------------------------------------------------------------------------------------
    // Page start methods
    // ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
        useEffect (() => {
          // Req
        if (token && (pendingToDos === null)) getPendingToDos();
        if (token && (keyKPIs === null) 
        && (securityLevel?.includes(Configs.securityLevelAdmin) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))) getKeyKPIs();

        // Amplitude
        // Only in production
        if (amplitude && !Configs.devEnvironment) {
          if (Configs.devEnvironment) console.log ("[Home.js][useEffect()] - Amplitude identified", amplitude);
          // const identifyEvent = new amplitude.Identify();
          // identifyEvent.setGroup('companyID', companyID);
          // identifyEvent.set('employeeID', employeeID);

          // amplitude.identify(identifyEvent);

          if (companyID !== 9) {
            amplitude.setUserId(companyInfo?.companyName+" [" + companyID + "]"+ " - "+employeeID);
          } else {
            amplitude.setUserId("Test company - REMOVE");
          }
        }
      
         }, []);

  // ---------------------------------------------------------------------------------------------------
  // Frontend management
  // --------------------------------------------------------------------------------------------------- 
    async function updateNotificationStatus (event, index) {
      // fetch from server
      event.preventDefault();

      // Amplitude - Log notification click
      if (amplitude) {

        amplitude.logEvent('notification-click', { 
          companyID: companyID,
          employeeID: employeeID,
          notificationType: pendingToDos[index].notificationType,
          notificationStatus: pendingToDos[index].status,
          notificationID: pendingToDos[index]._id
        });
      }

      // API method to update notification status to Read
      // HTTP POST request options
      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          companyID: companyID,
          employeeID: employeeID,
          notificationID: pendingToDos[index]._id,
          entityID: pendingToDos[index].entityID,
          notificationType: pendingToDos[index].notificationType,
          notificationStatus: Configs.notificationStatusRead
        })
    };

      // Post to server      
      fetch(Configs.platformPutUpdateNotificationStatus + "?companyID=" + companyID + "&employeeID=" + employeeID, requestOptions)
      .then((response) => {
        // console.log (response);
        if (response.status === 200) {
          // All good
          if (Configs.devEnvironment) console.log("[Home.js][updateNotificationStatus()] - Notification updated successfully");
            // Navigate to the link
            if (Configs.devEnvironment) console.log ("[Home.js][updateNotificationStatus()] link: ", new URL(pendingToDos[index].linkCTA));
            const url = new URL(pendingToDos[index].linkCTA);

            if (url.search) {
              navigate(url.pathname + url.search + (pendingToDos[index].notificationType === Configs.notificationsPeerFeedback ? "/" + pendingToDos[index]._id : ""));  
            } else {
              navigate(url.pathname + (pendingToDos[index].notificationType === Configs.notificationsPeerFeedback ? "/" + pendingToDos[index]._id : ""));
            } 

            // Update the pending To-Dos
            getPendingToDos();

        } else if (response.status === 400) {
          // Inform that something went wrong
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
        } else if (response.status === 401) {
          // Force logout
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          setToken(null);
        } else if (response.status === 403) {
          // Set Forbidden = true
          setForbidden(true);

          // Force logout
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
          sessionStorage.clear();
          // window.location.reload();
        } else {
          // There is an error - delete info
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }
      })
      .catch((error) => {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        console.error("[Home.js][updateNotificationStatus()] Error fetching data: ", error);
      });
 
    }

  // ------------------------------------------------------------
  // Pagination methods
  // ------------------------------------------------------------
  // Calculate the indices of the first and last row on the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  var currentRowsPendingToDos = [];
  if (pendingToDos?.length > 0) {
    currentRowsPendingToDos = pendingToDos?.slice(indexOfFirstRow, indexOfLastRow);
  } 

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        if (Configs.devEnvironment) console.log("[recognitionHome.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page since the number of pages will change
    };


  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
    };
      
  // ---------------------------------------------------------------------------------------------------
  // Render methods
  // ---------------------------------------------------------------------------------------------------

    return (
              <React.Fragment>
              <Header />
              <Bot />



            <div className="main" >

              <HomeHeader />
              <div className='flex flex-row items-start'>
              
              <div className="left-big-view">

              {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

              {/* Forbidden screen */}
              {forbidden ? <Forbidden /> : 

                <div className="flex flex-col text-left ">
                              {/* Punch in/out widget - mobile only */}
                              {companyPreferences?.activeModules?.PunchInOut === true ?
                                  <div className='flex flex-col items-center lg:hidden'>
                                    <PunchWidget />
                                  </div>
                                  :
                                null
                              }

                              {/* KPIs section */}
                              {(securityLevel?.includes(Configs.securityLevelAdmin) 
                              || securityLevel?.includes(Configs.securityLevelHR)
                              || securityLevel?.includes(Configs.securityLevelExec)) ?

                              
                                  <React.Fragment>
                                    <div className="flex flex-col items-start mt-2 md:ml-2 m-1 mb-4">
                                      <Tooltip content={t('home.kpis.description')}>
                                        <h2 id="kpis-title" className="section-title flex-flex-row items-center">
                                          {t('home.kpis.title')} 
                                        </h2> 
                                      </Tooltip>
                                    </div>
                                    <div className='flex w-full overflow-x-auto'>
                                        {isKeyKPIsLoading ?
                                          <div className="flex flex-row items-center justify-center w-full">
                                            <Loader/>
                                          </div>
                                        :
                                          
                                          (keyKPIs === null || !keyKPIs ||keyKPIs.length === 0) ?

                                              <div className="flex flex-col items-center  w-full lg:ml-4">
                                                <span className="title-description ">📊  <span className='italic'> {t('home.kpis.no-data')}{" "} </span>
                                                </span>
                                              </div>
                                                
                                            :

                                              <div className="flex flex-row m-2 text-sm text-left overflow-x-auto">
                                                  {keyKPIs?.map((KPI, index) => (
                                                      <div className='flex flex-col bg-white shadow rounded-lg p-4 m-2' key={index}>
                                                        <div className='flex flex-row justify-between mx-2 items-center'>
                                                        <span className='font-semibold text-gray-800'>{t('home.kpis.'+ KPI.metricType)}</span>
                                                        <span className='font-black text-4xl text-gray-800'>{KPI?.datapoints[0]?.metricValue}</span>
                                                        </div>

                                                        <KPIGraph
                                                          data={KPI?.datapoints}
                                                          />
                                                         
                                                      
                                                      </div>
                                                  ))}
                                              </div>
                                          }
                                          
                                        
                                        </div>
                                        <SectionSeparator />

                                </React.Fragment>
                              :
                                null
                              }
                            {/* Pending To-Dos section */}

                            <React.Fragment>
                                <div className="flex flex-col items-start md:mt-2 md:ml-2 m-1 md:mb-4">
                                    <Tooltip content={t('pending-to-dos.description')} >
                                      <h2 id="to-dos-title" className="section-title">
                                        {t('pending-to-dos')}
                                      </h2> 
                                    </Tooltip>
                                </div>
                                <div className='flex w-full overflow-x-auto'>
                                    {isPendingToDosLoading ?
                                      <Loader/>
                                    :
                                      
                                      (pendingToDos === null || !pendingToDos || pendingToDos.length === 0 || currentRowsPendingToDos?.length===0) ?

                                          <div className="flex flex-col items-center w-full lg:ml-4">
                                            <span className="title-description ">✅  <span className='italic'> {t('pending-to-dos.nothing-pending')}{" "} </span>
                                            </span>
                                          </div>
                                            
                                        :

                                          <div className="flex flex-col w-full md:m-2 text-sm text-left">
                                              {currentRowsPendingToDos?.map((todo, index) => (
                                                <button className="flex" onClick={(event) => updateNotificationStatus(event, index)}>
                                                  <div className='flex flex-row w-full justify-between border rounded-lg bg-white p-4 m-1 shadow hover:bg-gray-100'>
                                                    
                                                    <div className="flex flex-row space-x-4 items-center p-2 pl-0">
                                                        { todo.status === "Unread" ?
                                                            <div className='ml-2 w-[8px] h-[8px] border-50 bg-blue-500 rounded-full animate-pulse'></div>
                                                          :
                                                            <div className='invisible'>
                                                              <div className=' ml-2 w-[8px] h-[8px] border-50 bg-blue-500 rounded-full animate-pulse'></div>
                                                            </div>
                                                        }
                                                        { todo.notificationType === Configs.notificationsPeerFeedback ?
                                                            <div className='flex flex-row items-center'><CgPerformance className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            todo.notificationType === Configs.notificationsSelfPerformanceReview ?
                                                            <div className='flex flex-row items-center'><CgPerformance className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            todo.notificationType === Configs.notificationsManagerPerformanceReview ?
                                                            <div className='flex flex-row items-center'><CgPerformance className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            todo.notificationType === Configs.notificationsSurvey ?
                                                            <div className='flex flex-row items-center'><IoPulseSharp className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            todo.notificationType === Configs.notificationsOneOnOne ?
                                                            <div className='flex flex-row items-center'><FaPeopleArrows className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            todo.notificationType === Configs.notificationsActionPlanForPerformaceReview ?
                                                            <div className='flex flex-row items-center'><PiPlantBold className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            todo.notificationType === Configs.notificationsCalibrationForManagers ?
                                                            <div className='flex flex-row items-center'><FaBalanceScale className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            todo.notificationType === Configs.notificationsRecognitionForManagers ?
                                                            <div className='flex flex-row items-center'><FaAward className='text-xl mr-4'/>{t(todo.notificationType)} </div> 
                                                            :
                                                            todo.notificationType === Configs.notificationsOnboardingSelfPlan ?
                                                            <div className='flex flex-row items-center'><IoPersonAdd className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            : 
                                                            todo.notificationType === Configs.notificationsOnboardingPlanManager ?
                                                            <div className='flex flex-row items-center'><IoPersonAdd className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            : 
                                                            todo.notificationType === Configs.notificationsOnboardingPlanEmployee3P ?
                                                            <div className='flex flex-row items-center'><IoPersonAdd className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            todo.notificationType === Configs.notificationsTimeOffRequest ?
                                                            <div className='flex flex-row items-center'><GiPalmTree className='text-xl mr-4'/>{t(todo.notificationType)} </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className="flex p-2">
                                                        {(new Date(todo.expiryDate).setHours(0,0,0,0) - today) <= 0 ? 
                                                            <div className='text-red-600 font-bold animate-pulse'>
                                                              {t('last-day')}
                                                            </div>
                                                          :
                                                            Math.ceil(Math.abs(new Date(todo.expiryDate) - new Date()) / (1000*60*60*24))+1 + " " + t('days-left')
                                                        }
                                                    </div>
                                                  </div>
                                                  </button>
                                              ))}
                                              <Pagination 
                                                totalRows={pendingToDos?.length} 
                                                rowsPerPage={rowsPerPage} 
                                                currentPage={currentPage} 
                                                onPageChange={handlePageChange} 
                                                handleRowsPerPageChange={handleRowsPerPageChange}/>
                                          </div>
                                      }
                                      
                                    
                                    </div>
                            </React.Fragment>

                          {/* Company Values section */}
                      <SectionSeparator />
                      <div className=''>
                                <div className="flex flex-col lg:ml-2 ">
                                  {companyPreferences?.personalization?.valuesBehaviours === "behaviours" ? 
                                    <div className="flex flex-col items-start md:mt-2 md:ml-2 m-1 md:mb-4">
                                    <   Tooltip content={t('company.values.description.behaviours')} >
                                          <h2 id="values-title" className="section-title">
                                            {t('company.values.title.behaviours')}
                                          </h2> 
                                        </Tooltip>
                                        {/* <span className="title-description">
                                          {t('company.values.description.behaviours')}
                                        </span> */}
                                    </div>
                                    :
                                    <div className="flex flex-col items-start md:mt-2 md:ml-2 m-1 md:mb-4">
                                        <Tooltip content={t('company.values.description.values')} >
                                          <h2 id="values-title" className="section-title">
                                            {t('company.values.title.values')}
                                          </h2> 
                                        </Tooltip>
                                        {/* <span className="title-description">
                                        {t('company.values.description.values')}
                                        </span> */}
                                    </div>
                                  }

                                  {companyPreferences?.companyValues?.map((value, index) => (
                                      <UserListItem  key={index}
                                      user={value?.value} initials={value?.value[0]+value?.value[1]} role={value?.description}/>
                                  ))}

                                  <SectionSeparator />
                                </div>

                      </div>

                </div>
            }
            </div>
            <div className="right-small-view">
              <PunchWidget />
            </div>
            </div>

          </div>
          </React.Fragment>
          );
    }

export default Home;