// React
import React, { createContext, useState, useContext, useEffect } from 'react';

// 3P
// import { useMsal} from "@azure/msal-react";
import {useIsAuthenticated  } from "@azure/msal-react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // SSO
  const [isAuthenticationLoading, setIsAuthenticationLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);

  // Azure integrations
  // const {instance } = useMsal();
  const [isAzureAuthenticated, setIsAzureAuthenticated] = useState(useIsAuthenticated());

  // Authenticated variables
  const [token, setToken] = useState(null);
  const [employeeID, setEmployeeID] = useState(null);
  const [companyID, setCompanyID] = useState(null);
  const [securityLevel, setSecurityLevel] = useState(null);

  // // Optionally load the token from local storage or an API call here
  useEffect(() => {
    // const storedToken = localStorage.getItem('token');
    // if (storedToken) {
    //   setToken(storedToken);
    // }

    const fetchData = async () => {
      try {
        // if (token) authenticateToken();

      } catch (error) {
        console.error("[dataContext.js][useEffect()] Error fetching data: ", error);
      }

    };

    // fetchData asynchronously
    // fetchData();
  }, []);


// ------------------------------
  return (
    <AuthContext.Provider value={{ 
      isAuthenticationLoading, setIsAuthenticationLoading,
      // SSO
      authenticated, setAuthenticated, 
      // Google
      isGoogleAuthenticated, setIsGoogleAuthenticated, 
      // Azure
      isAzureAuthenticated, setIsAzureAuthenticated,
      // Authenticated variables
      token, setToken, employeeID, setEmployeeID, companyID, setCompanyID, securityLevel, setSecurityLevel
      }}>
      {children}
    </AuthContext.Provider>
  );
};