// React
import React, { useEffect} from "react";

// 3P
import { useTranslation } from 'react-i18next';


/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function UserListItem(props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

// Props
const initials = props.initials;
const user = props.user;
const role = props.role;
const level = props.level;
const area = props.area;
const subarea = props.subarea;
const profilePicture = props.profilePicture;


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        // calculateResult(selectedValues);
}, []);


// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-row m-1 border border-gray p-2 md:p-4 justify-left items-center w-full bg-white rounded-lg shadow'>
            { profilePicture ?
              <img className="rounded-full h-[60px] w-[60px] mr-8 border" src={profilePicture} alt={user + " profile picture"} />
            :
              <div className="flex h-[60px] w-[60px] p-6 mr-8 rounded bg-gray-100 items-center justify-center">
                {initials}
              </div>
            }

        <div className="flex flex-col text-left">  
            <span className="font-bold">{user}</span>

            <div className="flex flex-col text-sm">
              <span className="text-gray-600">{role ? role : null}</span>
              <p></p>
              <span className="text-gray-600">{level ? t('level') + ": " + level : null}</span>
              <span className="text-gray-600">{area ? t('area') + ": " + area : null} {subarea ? " || " + t('subarea') + ": " + subarea : null} </span>
            </div>
        
        </div>

    </div>

  );
}

export default UserListItem;