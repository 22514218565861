// React
import React, { useState, useEffect} from 'react';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';
import Breadcrumbs from '../../common/support/breadcrumbs';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

//Icons
import {CgPerformance} from "react-icons/cg";
import {MdArrowForwardIos} from 'react-icons/md';

// ------------------------------------------------------
function PerformanceHeader (props) {

    const currentScreen = props.currentScreen;
    const {securityLevel} = useAuth();
    const params = props.params;
    const navigate = useNavigate();
    const {setSelectedProfile} = useData();

    const livePerformanceCampaign = props.livePerformanceCampaign;
  
   
    const screens = [
        {view:t("feedback"), url:"/performance/self/", securityLevel:[Configs.securityLevelIC]},
        {view:t("strengths"), url:"/performance/self/strengths", securityLevel:[Configs.securityLevelIC]},
    //    {view:t("growth"), url:"/performance/self/growth", securityLevel:Configs.securityLevelIC},
        {view:t("review"), url:"/performance/self/review", securityLevel:[Configs.securityLevelIC]},
        {view:t("discuss"), url:"/performance/self/discuss", securityLevel:[Configs.securityLevelIC]}
    ];

    // If manager view of performance
    const directReviewScreens = [
        {view:t("feedback"), url:"/performance/team/employee/" +
            encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(props.params),Configs.privateKey).toString()), securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR]},
        {view:t("manager.strengths"), url:"/performance/team/employee/strengths/" +
        encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(props.params),Configs.privateKey).toString()), securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR]},
    // TO DO: Standardize flows for each company
    //    {view:"growth", url:"/performance/team/employee/growth/" +
    //    encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(props.params),Configs.privateKey).toString()), securityLevel:Configs.securityLevelManager},
        {view:t("review"), url:"/performance/team/employee/review/" +
        encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(props.params),Configs.privateKey).toString()), securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR]},
        {view:t("discuss"), url:"/performance/team/employee/discuss/" +
        encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(props.params),Configs.privateKey).toString()), securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR]},
    ];
    const managerScreens = [
        {view:t("performance.header.self"), url:"/performance/self/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR]},
        {view:t("performance.header.team"), url:"/performance/team/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR]},
        {view:t("performance.header.campaigns"), url:"/performance/campaigns/", securityLevel:[Configs.securityLevelHR, Configs.securityLevelExec]},
        {view:t("performance.header.HR"), url:"/performance/hr/", securityLevel:[Configs.securityLevelHR, Configs.securityLevelExec]},
 //       {view:"performance.header.admin", url:"/performance/admin/", securityLevel:Configs.securityLevelAdmin},
    ];

  // ---------------------------------------------------------------------------------------------------
  // First render methods
  // ---------------------------------------------------------------------------------------------------
       // Methods to load when page is loaded
       useEffect (() => {
        // Get information to populate dashboard
        //setLivePerformanceCampaign(sessionStorage.getItem('livePerformanceCampaign'));
      }, []);

  // ---------------------------------------------------------------------------------------------------
  // Front end control methods
  // ---------------------------------------------------------------------------------------------------

    function clickedButton (e) {
        e.preventDefault();
        navigate(e.target.pathname);
    }

    function getCurrentPathWithoutLastPart (string) {
        const pathRgx = /\//g;
        const childroutecount = ((string || '').match(pathRgx) || []).length
        // console.log (childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string)
        return childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string;
    }

    async function navigateTo (elementURL) {
        if (elementURL === "/performance/self/") {
            setSelectedProfile(null);
        }          
        navigate(elementURL);
      }

  // ---------------------------------------------------------------------------------------------------
  // Rendering methods
  // ---------------------------------------------------------------------------------------------------
        return (
            <React.Fragment>
            <div className='bg-white shadow '>
            <ModuleHeader subsections={managerScreens} title={t('performance-review')} Icon={CgPerformance} 
            breadcrumbs={false}
            livePerformanceCampaign={livePerformanceCampaign}
            params={params}
            />

            {/* Sub-menus
                Menu 1: When checking my own performance review (IC)
            */}
            {(!params) && (currentScreen !== "/performance/team/") 
            && (currentScreen !== "/performance/hr/")
            && (currentScreen !== "/performance/campaigns/")
            && (currentScreen !== "/performance/campaigns/create")
            && (currentScreen !== "/performance/campaigns/edit") ?

                        <div className='flex flex-col lg:flex-row lg:justify-between items-center overflow-x-auto px-4 py-2 lg:pt-4'>

                                {(props.livePerformanceCampaign === true || props.livePerformanceCampaign=== "true") ? 
                        
                                        <div className='flex flex-row w-full text-sm items-center justify-center '>
                                            {screens.map((element, index) => (
                                                <React.Fragment>

                                                {
                                                    securityLevel?.some(r => element.securityLevel?.includes(r)) ?     
                                                    <div className='flex flex-row items-center justify-between'>
                                                    <Link to={element.url}>
                                                            <div className={' flex mx-4 p-1 ' + (
                                                                currentScreen === element.url ? 
                                                                    ' border-b border-b-green-200 border-b-4 rounded '
                                                                    : ' hover:bg-green-200 hover:rounded '
                                                                    )}>
                                                                        <div className='flex flex-row items-center'>
                                                                            <span className='flex mx-1'>{index+1}.</span>
                                                                            {
                                                                            
                                                                            element.view === "feedback" ?
                                                                                    "Inicio"
                                                                                        :
                                                                                t(element.view)
                                                                            }
                                                                        </div>
                                                            </div>
                                                        </Link>
                                                        {index < directReviewScreens.length-1 ? 
                                                            <span className='text-lg'> <MdArrowForwardIos/></span>
                                                            : 
                                                            null
                                                        }
                                                    </div>
                                                    : null}
                                                </React.Fragment>
                                            ))
                                        }
                        
                                        </div>
                                : 

                                        <div className='flex flex-row w-full text-sm items-center justify-center '>
                                                <div className={' flex ml-2 mr-4 pl-1 pr-1 pt-2 pb-2 md:p-2 border-b border-b-green-200 border-b-4 rounded'}>
                                                        <div className='flex flex-row items-center'>
                                                            {t('performance.home.history')}
                                                        </div>
                                                </div>
                                        </div>

                                    }
                        </div>
                    :   null
                    }

                {/* Sub-menus
                Menu 1: When checking my team performance review
            */}
            {(params) ?
                        <div className='flex flex-col lg:flex-row lg:justify-between items-center overflow-x-auto px-4 py-2 lg:pt-4'>

                                {(livePerformanceCampaign === true || livePerformanceCampaign=== "true") ? 
                        
                                        <div className='flex flex-row w-full text-sm items-center justify-center '>
                                            {directReviewScreens.map((element, index) => (
                                                <React.Fragment>
                                                {securityLevel?.some(r => element.securityLevel?.includes(r)) ?     
                                                    <div className='flex flex-row items-center justify-between'>
                                                    <Link to={element.url}>
                                                            <div className={' flex ml-2 mr-4 p-1' + (
                                                                (getCurrentPathWithoutLastPart(currentScreen) === getCurrentPathWithoutLastPart(element.url)) ? 
                                                                    ' border-b border-b-green-200 border-b-4 rounded '
                                                                    : ' hover:bg-green-200 hover:rounded '
                                                                    )}>
                                                                        <div className='flex flex-row items-center'>
                                                                            <span className='flex mr-1 md:mr-2'>{index+1}.</span>
                                                                            {
                                                                            
                                                                            element.view === "feedback" ?
                                                                                    "Inicio"
                                                                                        :
                                                                                t(element.view)
                                                                            }
                                                                        </div>
                                                            </div>
                                                        </Link>
                                                        {index < directReviewScreens.length-1 ? 
                                                            <span className='text-lg'> <MdArrowForwardIos/></span>
                                                            : 
                                                            null
                                                        }
                                                    </div>
                                                    : null}
                                                </React.Fragment>
                                            ))
                                        }
                        
                                        </div>
                                : 

                                            <Link to={"/performance/team/employee/discuss/"+
        encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(props.params),Configs.privateKey).toString())}>
                                                <div className={' flex ml-2 mr-4 pl-1 pr-1 pt-2 pb-2 md:p-2 border-b border-b-green-200 border-b-4 rounded '}>
                                                        <div className='flex flex-row items-center text-sm'>
                                                            {t('discuss')}
                                                        </div>
                                                </div>
                                            </Link>
                                    }
                        </div>
                    :   null
                    }
            </div>
            </React.Fragment>
        );

}

export default PerformanceHeader;