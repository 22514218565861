// React
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Common and widgets
import Configs from "../../Configs";
import Bot from '../../common/support/bot';
import Header from '../../common/header';
import RecognitionHeader from './recognitionHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import FilterWidget from '../../widgets/data/filterWidget';
import Pagination from '../../widgets/pagination';

// 3P
import { useTranslation } from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import RecognitionItem from '../../widgets/recognition/recognitionItem';


// ---------------------------------------------------------------------------------------------------
function RecognitionHome () {
    // Employee Info
    const {t} = useTranslation();
    const navigate = useNavigate();
        
    const { token, employeeID, companyID } = useAuth();
    const { companyPreferences, companyRecognitionData, getRecognitionDataForCompany, isCompanyRecognitionDataLoading } = useData();
    const { toasterMessage, setToasterMessage} = useData();

    // Recognition variables
    const [modifiedData, setModifiedData] = useState(companyRecognitionData ? companyRecognitionData : null);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Default number of rows per page

    if (Configs.devEnvironment) console.log("[recognitionHome.js] companyRecognitionData: ", companyRecognitionData);

// ---------------------------------------------------------------------------------------------------
// After loading the view
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      if (companyRecognitionData && companyRecognitionData?.length > 0) {
        setModifiedData(companyRecognitionData);
      }

    }, [companyRecognitionData]);

// ------------------------------------------------------------
// Pagination methods
// ------------------------------------------------------------
 // Calculate the indices of the first and last row on the current page
 const indexOfLastRow = currentPage * rowsPerPage;
 const indexOfFirstRow = indexOfLastRow - rowsPerPage;
 const currentRows = modifiedData?.length > 0 ? modifiedData?.slice(indexOfFirstRow, indexOfLastRow) : null;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        if (Configs.devEnvironment) console.log("[recognitionHome.js][handleRowsPerPageChange()] - event.target.value: ", event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page since the number of pages will change
    };

// ---------------------------------------------------------------------------------------------------
// Filter methods
// ---------------------------------------------------------------------------------------------------   
const handleFilterChange = (filteredDataInput) => {
  if (Configs.devEnvironment) console.log("[recognitionHome.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
  setModifiedData(filteredDataInput);
};

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
    
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="main">
        <RecognitionHeader />
            
        <div className='flex flex-row items-start'>
          <div className="left-big-view">

        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        <div className="flex flex-col text-left ">

          <div className="flex flex-col mt-2 md:ml-2 m-1">
            <h2 className="flex text-xl mb-4 ">
              <div className='flex flex-col items-start md:flex-row justify-left' id='recognition-title'>
                <Tooltip content={t('recognition.home.description')} >
                  <div className='section-title' >
                    {t('recognition.home.title')}
                  </div>
                </Tooltip>
              </div>
            </h2>
          </div>

          {companyRecognitionData && companyRecognitionData?.length > 0 ?
            <div className='flex flex-row items-start justify-end px-8 py-4'>
                  <Link to="/recognition/create">
                      <button className='save-button bg-green-200 rounded-lg shadow-xl' id='create-recognition-button'>
                        {t('recognition.home.cta')}
                      </button>
                  </Link>
            </div>
          :
            null
          }


          {isCompanyRecognitionDataLoading ? 
              <Loader />
            :
              <React.Fragment>

                {currentRows && currentRows?.length > 0 ?

                  <React.Fragment>

                  <FilterWidget data={companyRecognitionData} onFilterChange={handleFilterChange}
                          teamTypeFilter={false} defaultTeamTypeFilter={"company"}
                          managerFilter={false}
                          employeeFilter={true}
                          areaFilter={true} 
                          subareaFilter={true}
                          title={t('recognition.home.filter-title.recogniser')}
                          />

                    {currentRows?.map((recognition, index) => (

                        <RecognitionItem recognition={recognition} key={index} index={index} />

                    ))}

                    <Pagination
                        totalRows={modifiedData?.length}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                          />

                  </React.Fragment>
                    
                :
                    <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                        <span className="title-description italic" id="recognition-home-empty">{t('recognition.home.empty')}</span>
                        <Link to="/recognition/create">
                            <button className='save-button' id='create-recognition-button'>
                              {t('recognition.home.cta')}
                            </button>
                        </Link>
                    </div>
                }      

                <SectionSeparator />

              </React.Fragment>
          }
        </div>
      </div>
      </div>
      </div>
    </React.Fragment>
    );
}

export default RecognitionHome;