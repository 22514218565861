// React
import React, {useState, useEffect} from 'react';
import {useLocation, useParams, useNavigate} from 'react-router-dom';

// Header 
import PerformanceHeader from './performanceHeader';
import Header from '../../common/header';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Common and widgets
import Configs from '../../Configs';
import UserListItem from '../../widgets/userListItem';
import ValuesScoring from '../../widgets/valuesScoring';
// import ValuesScoringWithComment from '../../widgets/valuesScoringWithComment';
import DiscussValuesScoringWithComment from '../../widgets/discussValuesScoringWithComments';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import {useAuth} from '../../common/appContext';
import { useData } from '../../common/dataContext';

// Toaster & loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Editors
import Editor from '../../widgets/editor';
import NonEditableEditor from '../../widgets/noneditableEditor';
import PerformanceHistory from '../../widgets/performance/performanceHistory';

// Icons
import {IoInformationCircleOutline} from 'react-icons/io5';

// ---------------------------------------------------------------------------------------------------
function PerformanceDiscuss () {
    // Using params when performance from team members
    const navigate = useNavigate();
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    
    // To pass location to the Header
    const location = useLocation();
    const currentScreen = location.pathname;

    const {token, companyID, securityLevel} = useAuth();
    const {livePerformanceCampaign, setLivePerformanceCampaign, performanceReviewCampaigns} = useData();
    const {toasterMessage, setToasterMessage} = useData();
    
    const {selectedProfile, setSelectedProfile} = useData();

    const [performanceReview, setPerformanceReview] = useState(null);

    // Select employeeID depending on 
    var employeeID = '';
    var managerID = '';
    if (params) {
      // If there are params, we are in a team member performance review
      // managerID = employeeID
      employeeID = params.employeeID;
      managerID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8); 
    } else {
      // If no params, we are in self/IC review employeeID = employeeID
      employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    }

    // Questionnaire that is shown in FrontEnd
   const [questionnaire, setQuestionnaire] = useState([null]);
   const [managerQuestionnaire, setManagerQuestionnaire] = useState([null]);

    // IC input for values
    const [selectedValues, setSelectedValues] = useState(null);
    const [numOfSelectedValues, setNumOfSelectedValues] = useState(0);
    const [valuesResult, setValuesResult] = useState(0);
    // Manager input for values
    const [managerSelectedValues, setManagerSelectedValues] = useState(null);
    const [managerNumOfSelectedValues, setManagerNumOfSelectedValues] = useState(0);
    const [managerValuesResult, setManagerValuesResult] = useState(0);
    // Peer input for values
    const [peerStrengthValuesResult, setPeerStrengthValuesResult] = useState(0);
    const [peerGrowthValuesResult, setPeerGrowthValuesResult] = useState(0);

    // Peer feedbacks
    const [peerFeedbackStrengthsQuestionnaire, setPeerFeedbackStrengthsQuestionnaire] = useState([null]);
    const [peerFeedbackStrengthsValues, setPeerFeedbackStrengthsValues] = useState([null]);
    const [peerFeedbackGrowthQuestionnaire, setPeerFeedbackGrowthQuestionnaire] = useState([null]);
    const [peerFeedbackGrowthValues, setPeerFeedbackGrowthValues] = useState([null]);

    // Action plan
    const [actionPlan, setActionPlan] = useState(null);
    const [actionPlanPlaceholder, setActionPlanPlaceholder] = useState(null);

    // Toaster
    const [isLoading, setIsLoading] = useState(false);

    // Logging only in Dev
    if (Configs.devEnvironment) console.log ("[performanceDiscuss.js][main()] Performance Review: ", performanceReview);
      
    // ---------------------------------------------------------------------------------------------------
    // Page start methods
    // ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    useEffect (() => {
      // Fix selectedProfile
      if (!params && selectedProfile) {
        // IC
        setSelectedProfile(null); 
      } else if (params && !selectedProfile) {
        // Team - navigate to team
        navigate('/performance/team');
        console.error("[performanceReview.js][useEffect()] - No selectedProfile found");
      }

        if (performanceReview === null || performanceReview.length < 1) {
          getSentPerformanceReview();
        }

    }, [location, selectedProfile]);

    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
    };
    
    // ---------------------------------------------------------------------------------------------------
    // API methods
    // ---------------------------------------------------------------------------------------------------
    async function getSentPerformanceReview () {

      setIsLoading(true);

        try {
          const response = await fetch(Configs.platformGetPerformanceReviewByStatusAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&status=" + "sent", {
                            method: 'get',
                            credentials: 'include',
                            headers: {
                              'Content-type': 'application/json',
                              'Authorization': `Bearer ${token}`, // notice the Bearer before your token
                            }})
          setIsLoading(false);  

            if (response.status === 200) {
              // Found a 'completed' performance review
              const body = await response.json()
                if (Configs.devEnvironment) console.log ("[performanceDiscuss.js][getSentPerformanceReview()] - getSentPerformanceReview: ", body);
                if (body) {
                  setPerformanceReview(body);
                  updateFrontendData(body);
                  updatePeerFeedback(body[0]?.peerFeedback);

                  // Get action plan
                  if (actionPlan === null || actionPlan.length < 1) getDevelopmentPlan(body[0]);
                } else {
                  // No SENT performance review found
                  // Look for completed
                  getCompletedPerformanceReview();
                }
            } else if (response.status === 204){
              // No SENT performance review found
              if (Configs.devEnvironment) console.log ("[performanceDiscuss.js][getSentPerformanceReview()] - no sent found: ", null);
              // Look for completed
              getCompletedPerformanceReview();
            } else if (response.status === 400) {
                  // Something went wrong - inform user
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 401) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage?.clear();
                  window?.location?.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  // setToasterMessage({message: t('not-found'), type: Configs.successToaster});
            } else if (response.status === 500){
                  //
                  // setToasterMessage({message: t('error'), type: Configs.errorToaster});
                  // throw response;
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
      
          } catch (error) {
            setIsLoading(false);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            console.error("[performanceDiscuss.js][getSentPerformanceReview()] Error fetching data: ", error);
          }
    }

    async function getCompletedPerformanceReview () {

      setIsLoading(true);

        try {
          const response = await fetch(Configs.platformGetPerformanceReviewByStatusAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&status=" + "completed", {
                            method: 'get',
                            credentials: 'include',
                            headers: {
                              'Content-type': 'application/json',
                              'Authorization': `Bearer ${token}`, // notice the Bearer before your token
                            }})
            setIsLoading(false);  

            if (response.status === 200) {
              // Found a 'completed' performance review
              const body = await response.json()
                if (Configs.devEnvironment) console.log ("[performanceDiscuss.js][getCompletedPerformanceReview()] - getCompletedPerformanceReview: ", body);
                if (body) {
                  setPerformanceReview(body);
                  updateFrontendData(body);
                  updatePeerFeedback(body[0]?.peerFeedback);

                  // Get action plan
                  if (actionPlan === null || actionPlan.length < 1) getDevelopmentPlan(body[0]);
                } else {
                  // No Completed performance review found
                  // Look for submitted
                  getSubmittedPerformanceReview();
                }
            } else if (response.status === 204){
              // No Completed performance review found
              if (Configs.devEnvironment) console.log ("[performanceDiscuss.js][getSentPerformanceReview()] - no completed found: ", null);
                // Look for submitted
                getSubmittedPerformanceReview();
            } else if (response.status === 400) {
                  // Something went wrong - inform user
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 401) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage?.clear();
                  window?.location?.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  setToasterMessage({message: t('not-found'), type: Configs.successToaster});
            } else if (response.status === 500){
                  //
                  // setToasterMessage({message: t('error'), type: Configs.errorToaster});
                  // throw response;
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
      
          } catch (error) {
            setIsLoading(false);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            console.error("[performanceDiscuss.js][getCompletedPerformanceReview()] Error fetching data: ", error);
          }
      
    }

    async function getSubmittedPerformanceReview () {

      setIsLoading(true);

        try {
          const response = await fetch(Configs.platformGetPerformanceReviewByStatusAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&status=" + "submitted", {
                            method: 'get',
                            credentials: 'include',
                            headers: {
                              'Content-type': 'application/json',
                              'Authorization': `Bearer ${token}`, // notice the Bearer before your token
                            }})
            setIsLoading(false);  

            if (response.status === 200) {
              // Found a 'completed' performance review
              const body = await response.json()
                if (Configs.devEnvironment) console.log ("[performanceDiscuss.js][getSubmittedPerformanceReview()] - getSubmittedPerformanceReview: ", body);
                if (body) {
                  setPerformanceReview(body);
                  updateFrontendData(body);
                  updatePeerFeedback(body[0]?.peerFeedback);

                  // Get action plan
                  if (actionPlan === null || actionPlan.length < 1) getDevelopmentPlan(body[0]);
                } else {
                  // No submitted performance review found, check if live
                  if (performanceReviewCampaigns && performanceReviewCampaigns.length > 0) {
                    setLivePerformanceCampaign(true);
                  }
                }
            } else if (response.status === 204){
              // No submitted performance review found
              if (performanceReviewCampaigns && performanceReviewCampaigns.length > 0) {
                setLivePerformanceCampaign(true);
              }
            } else if (response.status === 400) {
                  // Something went wrong - inform user
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 401) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage?.clear();
                  window?.location?.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  setToasterMessage({message: t('not-found'), type: Configs.successToaster});
            } else if (response.status === 500){
                  //
                  // setToasterMessage({message: t('error'), type: Configs.errorToaster});
                  // throw response;
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
      
          } catch (error) {
            setIsLoading(false);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            console.error("[performanceDiscuss.js][getSubmittedPerformanceReview()] Error fetching data: ", error);
          }
      
    }

    
    async function getDevelopmentPlan (performanceReview) {
      // fetch from server      
      fetch(Configs.platformGetDevelopmentPlanAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + performanceReview?.campaignID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        }})
          .then((response) => {
            if (response.status === 200) {
              // Found an action plan
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log ("[performanceDiscuss.js][getDevelopmentPlan()] - getDevelopmentPlan: ", body);
                  if (body) {
                    setActionPlan(body.developmentPlan);
                    setActionPlanPlaceholder(body.developmentPlan);
                    setToasterMessage({message: t('success'), type: Configs.successToaster});
                  }
                
                })
              .catch(error => {
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                console.error("[performanceDiscuss.js][getDevelopmentPlan()] Error parsing response JSON", error)
              });
            } else if (response.status === 204) {
              // No development plan found
              // setToasterMessage({message: t('error'), type: Configs.errorToaster});

            } else if (response.status === 400) {
              // Something went wrong - inform user
              // setToasterMessage({message: t('error'), type: Configs.errorToaster});

            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage?.clear();
                  window?.location?.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                    sessionStorage.clear();
                    window.location.reload();
            } else if (response.status === 404) {
                // setToasterMessage({message: t('not-found'), type: Configs.successToaster});
            } else {
              //
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
              throw response;
            }
          })
    
          .catch((error) => {
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            console.error("[performanceDiscuss.js][getDevelopmentPlan()] Error fetching data: ", error);
          });
    }

    async function submitActionPlan (e) {
      e.preventDefault();

      if (Configs.devEnvironment) console.log("[performanceDiscuss.js][submitActionPlan()] - Submitting action plan: ",   actionPlan);
      if (Configs.devEnvironment) console.log("[performanceDiscuss.js][submitActionPlan()] - Submitting action plan: ",   performanceReview[0]?.campaignID);


      // HTTP POST request options
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
          },
        body: JSON.stringify({ 
          employeeID: parseInt(employeeID),
          managerID: parseInt(managerID),
          companyID: companyID,
          actionPlan: actionPlan,
          campaignID: performanceReview[0]?.campaignID,
        })
      };

      // Post to server      
      fetch(Configs.platformPostDevelopmentPlanAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, requestOptions)
          .then((response) => {
            // console.log (response);
            if (response.status === 200) {
                  // All good
                  setToasterMessage({message: t('success'), type: Configs.successToaster});
            } else if (response.status === 400) {
                  // Something went wrong - inform user
                  setToasterMessage({message: t('error'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage?.clear();
                  window?.location?.reload();
            } else if (response.status === 403) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                  sessionStorage.clear();
                  window.location.reload();
            } else if (response.status === 404) {
                  setToasterMessage({message: t('error'), type: Configs.warningToaster});
            } else {
                  //
                  setToasterMessage({message: t('error'), type: Configs.errorToaster});
                  throw response;
            }
          })

          .catch((error) => {
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            console.error("[performanceDiscuss.js][submitActionPlan()] Error fetching data: ", error);
          });
    }

    async function shareReviewWithEmployee (e) {
      e.preventDefault();

      if (! (securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
        return;
      }

      if (!params) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
        return;
      }
      
      // Ask for confirmation in an alert
      if (!window.confirm(t('performance.home.info.send-to-employee-confirm'))) {
        return;
      }

      setIsLoading(true);

      // HTTP POST request options
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 
          'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` // notice the Bearer before your token
             },
        body: JSON.stringify({ 
          employeeID: params?.employeeID,
          companyID: companyID,
          campaignID: performanceReview[0]?.campaignID,
          managerID: performanceReview[0]?.managerFeedback[0]?.managerID, 
          status: "sent"
        })
      };
  
      // Post to server      
      fetch(Configs.platformSubmitManagerFeedbackAPI, requestOptions)
          .then((response) => {
            setIsLoading(false);

            if (response.status === 200) {
              // All good
              response.json()
              .then (body => {
                  // console.log(body)
                  setToasterMessage({message: t('success'), type: Configs.successToaster});
                  getSentPerformanceReview();
  
                })
              .catch(error => {
                console.error("[performanceCampaignResult.js][submitManagerFeedback()] Error parsing response JSON", error);
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
              });
            } else if (response.status === 400) {
                // Something went wrong - inform user
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 404) {
                // setToasterMessage({message: t('error'), type: Configs.warningToaster});
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else {
                //
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                throw response;
            }
          })
    
          .catch((error) => {
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            console.error("[performanceCampaignResult.js][submitManagerFeedback()]  Error fetching data: ", error);
          });
  
  }

    // ---------------------------------------------------------------------------------------------------
    // Data management methods
    // ---------------------------------------------------------------------------------------------------
    async function updateFrontendData (body) {
      var numOfValuesSelectedAux = 0;
      var managerNumOfSelectedValues = 0;

      if (body) {
        body?.map((element, index) => {
              if (element.type === "strength") {
                  // process strengths
                  if(element?.questionnaire) setQuestionnaire(element?.questionnaire);
                  if(element?.managerFeedback?.length > 0) setManagerQuestionnaire(element?.managerFeedback[0]?.questionnaire);
                  if (element?.values) setSelectedValues(element?.values);
                  if(element?.managerFeedback?.length > 0) setManagerSelectedValues(element?.managerFeedback[0]?.values);


                  if (body[0]?.campaign?.length > 0) {
                    if (body[0]?.campaign[0]?.valuesSelectorType === "score" ||
                    body[0]?.campaign[0]?.valuesSelectorType === "scoreWithComments") {
                      calculateValuesResult(element?.values);
                      calculateManagerValuesResult(element?.managerFeedback[0]?.values);
                    }
                  }
  
                  element?.values?.map((value, index) => {
                      if (value?.selected === true) {
                          numOfValuesSelectedAux += 1;
                      }
                  })
                  setNumOfSelectedValues(numOfValuesSelectedAux);

                  if (element?.managerFeedback?.length > 0) {
                    element?.managerFeedback[0]?.values?.map((value, index) => {
                      if (value?.selected === true) {
                        managerNumOfSelectedValues += 1;
                      }
                    })
                  }
                setManagerNumOfSelectedValues(managerNumOfSelectedValues);

                  if (element?.status === "completed") {
                    setLivePerformanceCampaign(false);
                  } else if (element?.status === "submitted" && !params) {
                    setLivePerformanceCampaign(false);
                  }

              } else if (element?.type === "growth") {
                  // process growth areas
                  if (element?.status === "completed") {
                    setLivePerformanceCampaign(false);
                  } else if (element?.status === "submitted" && !params) {
                    setLivePerformanceCampaign(false);
                  }
              } else {
                // do nothing
              }
          })
        }
    }

    async function calculateValuesResult (inputGrades) { 
          // Calculate result for input
          var count = 0;
          var auxResult = 0;
          inputGrades?.forEach(element => {
              count = count + 1;
              auxResult = parseInt(element?.selected) + parseInt(auxResult);
          });
          setValuesResult(auxResult / count);
      
    }

    async function calculateManagerValuesResult (inputGrades) { 
          // Calculate result for input
          var count = 0;
          var auxResult = 0;
          inputGrades?.forEach(element => {
              count += 1;
              auxResult = parseInt(auxResult) + parseInt(element?.selected);
          });

            setManagerValuesResult(auxResult / count);   
      
    }

    async function calculatePeerValuesResult (inputGrades) { 
      // Calculate result for input
      var count = 0;
      var auxResult = 0;
      inputGrades?.forEach(element => {
          count += 1;
          auxResult = parseInt(auxResult) + parseInt(element?.sum / element?.count);

      });

        setPeerStrengthValuesResult(auxResult / count);   
  
    }

    async function getSelectedValues (valuesSelected, feedbackType) {
      // Update from Values widget
      if (Configs.devEnvironment) console.log ("[performanceStrengths.js][getSelectedValues()] - ", valuesSelected, feedbackType);
        if (feedbackType === "growth") {
          // setSelectedValues([...valuesSelected]);
        } else if (feedbackType === "strength") {
            // IC input
            setSelectedValues([...valuesSelected]);
        
        
      } else {
        // do nothing
      }
    }

    async function getManagerSelectedValues (valuesSelected, feedbackType) {
      // Update from Values widget
      if (Configs.devEnvironment) console.log ("[performanceStrengths.js][getSelectedValues()] - ", valuesSelected, feedbackType);
      if (feedbackType === "growth") {
        // setSelectedValues([...valuesSelected]);
      } else if (feedbackType === "strength") {
          // Manager portion
          setManagerSelectedValues([...valuesSelected]);

        
      } else {
        // do nothing
      }
    }

    async function updatePeerFeedback (peerFeedback) {
      // Update peer feedback
      if (Configs.devEnvironment) console.log ("[performanceStrengths.js][updatePeerFeedback()] - ", peerFeedback);

      // create empty arrays to fill in for each peer
      var strengthAnswersQuestionnaire = [];
      var growthAnswersQuestionnaire = [];
      var strengthsAnswersValues = [];
      var growthAnswersValues = [];

      if (peerFeedback && peerFeedback.length > 0) {
        peerFeedback?.forEach((peerFeedback, index) => {
            // STRENGTHS - Review each questionnaire and add to the right type of feedbacks
            peerFeedback?.strengthAnswersQuestionnaire?.forEach((answer, index) => {
              // Each line is an aswers to the questions, therefore a full array
              if (Configs.devEnvironment) console.log ("[performanceStrengths.js][updatePeerFeedback()] - Peer strengths questionnaire ", answer.type, answer.answer);
              if (answer.type === "strength") {
                // strength answer
                strengthAnswersQuestionnaire.push(answer.answer);
              }
            });
            // GROWTH - Review each questionnaire and add to the right type of feedbacks
            peerFeedback?.growthAnswersQuestionnaire?.forEach((answer, index) => {
              // Each line is an aswers to the questions, therefore a full array
              if (Configs.devEnvironment) console.log ("[performanceStrengths.js][updatePeerFeedback()] - Peer growth questionnaire ", answer.type, answer.answer);
              if (answer.type === "growth") {
                // strength answer
                growthAnswersQuestionnaire.push(answer.answer);
              }
            });

            // STRENGTH - Review values
            peerFeedback?.strengthAnswersValues?.forEach((values, index) => {
              // Each line is an aswers to the questions, therefore a full array
              if (Configs.devEnvironment) console.log ("[performanceStrengths.js][updatePeerFeedback()] - Peer strengths values: ", values);
              
                // strength answer
                values.forEach( (answer, index) => {
                  if (strengthsAnswersValues[index] === undefined) {
                    // first time
                    var auxObject = {
                      value: answer.value,
                      description: answer.description,
                      sum: parseInt(answer.selected),
                      count: 1,
                      selected: parseInt(answer.selected),
                      comment: answer.comment,
                    }
                    // apply to index
                    strengthsAnswersValues[index] = auxObject;
                  } else {
                    if (strengthsAnswersValues[index].value === answer.value) {
                      var auxObject = {
                        value: answer.value,
                        description: answer.description,
                        sum: strengthsAnswersValues[index].sum + parseInt(answer.selected),
                        count: strengthsAnswersValues[index].count + 1,
                        selected: parseInt((strengthsAnswersValues[index].sum + parseInt(answer.selected)) / (strengthsAnswersValues[index].count + 1)),
                        comment: strengthsAnswersValues[index].comment + " \n \n " + (answer.comment ? answer.comment : "")

                      }
                      // apply to index
                      strengthsAnswersValues[index] = auxObject;
                    } else {
                      // something is wrong
                      console.error("[performanceStrengths.js][updatePeerFeedback()] - Something is wrong with the values array");
                    }
                  }
                });
                
            });
            
            // GROWTH - Review values
            peerFeedback?.growthAnswersValues?.forEach((values) => {
              // Each line is an aswers to the questions, therefore a full array
              if (Configs.devEnvironment) console.log ("[performanceStrengths.js][updatePeerFeedback()] - Peer growth values:", values);

                // strength answer
                values.forEach( (answer, index) => {
                  if (growthAnswersValues[index] === undefined) {
                    // first time
                    var auxObject = {
                      value: answer.value,
                      description: answer.description,
                      sum: parseInt(answer.selected),
                      count: 1,
                      selected: parseInt(answer.selected),
                      comment: answer.comment
                    }
                    // apply to index
                    growthAnswersValues[index] = auxObject;
                  } else {
                    if (growthAnswersValues[index].value === answer.value) {
                      var auxObject = {
                        value: answer.value,
                        description: answer.description,
                        sum: growthAnswersValues[index].sum + parseInt(answer.selected),
                        count: growthAnswersValues[index].count + 1,
                        selected: parseInt((growthAnswersValues[index].sum + parseInt(answer.selected)) / (growthAnswersValues[index].count + 1)),
                        comment: growthAnswersValues[index].comment + " \n \n " + (answer.comment ? answer.comment : "")
                      }
                      // apply to index
                      growthAnswersValues[index] = auxObject;
                    } else {
                      // something is wrong
                      console.error("[performanceStrengths.js][updatePeerFeedback()] - Something is wrong with the values array");
                    }
                  }
                });
            });

            // Logging
            if (Configs.devEnvironment) console.log ("[performanceStrengths.js][updatePeerFeedback()] - Strength values Array: ", strengthAnswersQuestionnaire, " - Growth values array:", growthAnswersQuestionnaire);
            if (Configs.devEnvironment) console.log ("[performanceStrengths.js][updatePeerFeedback()] - Strength values Array: ", strengthsAnswersValues, " - Growth values array:", growthAnswersValues);

            // Update state variables
            setPeerFeedbackStrengthsQuestionnaire([...strengthAnswersQuestionnaire]);
            setPeerFeedbackGrowthQuestionnaire([...growthAnswersQuestionnaire]);
            setPeerFeedbackStrengthsValues([...strengthsAnswersValues]);
            setPeerFeedbackGrowthValues([...growthAnswersValues]);
            calculatePeerValuesResult(strengthsAnswersValues);
        })
      } else {
        // set to null
        setPeerFeedbackStrengthsQuestionnaire(null);
        setPeerFeedbackGrowthQuestionnaire(null);
        setPeerFeedbackStrengthsValues(null);
        setPeerFeedbackGrowthValues(null);
      }
    }
  // ---------------------------------------------------------------------------------------------------
  // Render methods
  // ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
        
        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        {params ? 
          /* Manager */
              <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen} params={params}/>
            :
            /* IC */
              <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen} />          
            }

        <div className='flex flex-row items-start text-left'>
          <div className="left-big-view">
            <div className="flex flex-col">
            {params ?
              /* Manager - Show employee card */
              <div className='flex flex-col items-start text-left space-y-4'>
                <UserListItem user={params?.employee} initials={params?.employee?.slice(0,1)} role={params.role} level={params.level} profilePicture={params.profilePicture} />
                <Tooltip content={t('performance.discuss.team-member.description')}>
                  <h2 className='section-title'>
                    💯 {t('performance.discuss.title')}
                  </h2>
                </Tooltip> 
              </div>
              :
              /** IC - Discuss */
              <div className={(livePerformanceCampaign === "true" || livePerformanceCampaign === true) ? "flex flex-col items-start mt-2 ml-2 m-1 " : " hidden"}>
                <Tooltip content={t('performance.discuss.description')}>
                  <h2 className='section-title'>
                    💯 {t('performance.discuss.title')}
                  </h2>
                </Tooltip> 
              </div>
            }
        

            {isLoading ? 

              <Loader />

            :

              performanceReview ? 
                  <React.Fragment>{/* Info - send to employee if !== sent */}
                  {params && performanceReview[0].status === "completed"  ?
                      <div>
                        {/* Send warning */}                                        
                            <div className='flex flex-col bg-blue-100 px-8 py-4 md:mr-8 m-2 text-sm items-start space-y-4 rounded-lg'>
                              <div className='flex flex-row items-center'>
                              <div className='mr-4'><IoInformationCircleOutline /></div>
                              {t('performance.home.info.send-to-employee')}
                              </div>

                              <div className='flex flex-row justify-end w-full px-8'>
                              <button className='save-button ml-4' onClick={(e) => shareReviewWithEmployee(e)}> {t('performance.home.send-to-employee')} </button>
                              </div>
                            </div>
                      </div>
                    :
                    null
                  }

                      {/* Values section */}
                      <div className="flex flex-col mt-8 ml-2 m-1">
                        <h3 className='subsection-title'> 
                          {t('performance.values-section.title')}
                        </h3>
                        
                      {performanceReview ? 
                        <React.Fragment>
                                {(performanceReview[0]?.campaign[0]?.valuesSelectorType === "score") ?
                                    <React.Fragment>
                                          <ValuesScoring values={managerSelectedValues} selectedValues={getManagerSelectedValues} 
                                          result={managerValuesResult} setValuesResult={calculateManagerValuesResult}
                                                readOnly={true} type="strength"/>
                                    </React.Fragment>

                                :
                                (performanceReview[0]?.campaign[0]?.valuesSelectorType === "scoreWithComments") ?

                                              // IC view
                                              !params ?
                                              
                                                performanceReview[0]?.status === "sent" ?

                                                    <DiscussValuesScoringWithComment 
                                                    managerValues={managerSelectedValues} 
                                                    managerSelectedValues={getManagerSelectedValues} 
                                                    managerResult={managerValuesResult} 
                                                    setManagerValuesResult={calculateManagerValuesResult}
                                                    employeeValues={selectedValues} 
                                                    employeeSelectedValues={getSelectedValues} 
                                                    employeeResult={valuesResult} 
                                                    setEmployeeValuesResult={calculateValuesResult}
                                                    peersValues={peerFeedbackStrengthsValues}
                                                    peersResult={peerStrengthValuesResult}
                                                    readOnly={true} type="strength"/>
                                                  :
                                                    <DiscussValuesScoringWithComment 
                                                    employeeValues={selectedValues} 
                                                    employeeSelectedValues={getSelectedValues} 
                                                    employeeResult={valuesResult} 
                                                    setEmployeeValuesResult={calculateValuesResult}
                                                    readOnly={true} type="strength"/>
                                              :
                                                // Params = Manager or HR view
                                              (securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))
                                              ||  (securityLevel?.includes(Configs.securityLevelManager)) ?

                                                  <DiscussValuesScoringWithComment 
                                                        managerValues={managerSelectedValues} 
                                                        managerSelectedValues={getManagerSelectedValues} 
                                                        managerResult={managerValuesResult} 
                                                        setManagerValuesResult={calculateManagerValuesResult}
                                                        employeeValues={selectedValues} 
                                                        employeeSelectedValues={getSelectedValues} 
                                                        employeeResult={valuesResult} 
                                                        setEmployeeValuesResult={calculateValuesResult}
                                                        peersValues={peerFeedbackStrengthsValues}
                                                        peersResult={peerStrengthValuesResult}
                                                        readOnly={true} type="strength"/>

                                                  : 
                                                  // Edge case
                                                  null
                                    :
                                      // Neither score, nor score with comments
                                      null
                                }

                                

                        <SectionSeparator />

                        </React.Fragment>  
                        : 
                          null
                        }
                        
                      </div>


                      {/* Comments and Feedback section */}
                      <div className="flex flex-col">
                        <h3 className='subsection-title'> 
                          {t('performance.strengths.title')}
                        </h3>
                      </div>

                        {!params && performanceReview[0]?.status !== "sent" ? 
                            // Temporary solution - remove manager data
                            null
                          :
                            <React.Fragment>
                            <h3 className='flex text-l mt-8 italic font-bold'> 
                              {t('performance.manager-portion.title')}
                            </h3>
                            

                            {performanceReview?.map((element, index) => (
                              // Strengths section - Manager
                                element.type === "strength" ?
                                    element?.managerFeedback?.map ((managerFeedback, index) => (
                                      managerFeedback?.type === "strength" ? 
                                        managerFeedback?.questionnaire?.map ((question, index) => (
                                          question?.type === "strength" && question?.answer !== "" && question?.answer !== "<p></<p>" 
                                          && question?.answer !== " " && question?.answer  ?
                                              <div className="flex flex-col mt-8 ml-2 m-1">
                                                  <h3 className="mb-4">
                                                    {question.title}
                                                  </h3>
                                                  <div className='border-l-4 border-gray-200'>
                                                    <NonEditableEditor content={question.answer}/>
                                                  </div>
                                              </div>
                                          :
                                          null
                                          
                                        ))  
                                    : null
                                    ))

                            :
                              null
                            ))}
                            </React.Fragment>
                        }

                      <h3 className='flex text-l mt-8 italic font-bold'> 
                        {t('performance.employee-portion.title')}
                      </h3>
                      
                      {performanceReview?.map((element, index) => (
                        // Strengths section - Self review
                          element.type === "strength" ?
                              element?.questionnaire ?

                                element?.questionnaire?.map ((question, index) => (
                                      question.type === "strength" && question?.answer !== "" && question?.answer !== "<p></<p>" 
                                      && question?.answer !== " " && question?.answer  ? 
                                        <div className="flex flex-col mt-8 ml-2 m-1">
                                            <h3 className="mb-4">
                                              {question.title}
                                            </h3>
                                            <div className='border-l-4 border-gray-200'>
                                                <NonEditableEditor content={question.answer}/>
                                              </div>
                                        </div>
                                      : null
                                ))
                                :
                                    <div className='flex flex-col items-center my-4'>
                                      <div className='title-description italic'>
                                        {t('performance.review.missing-employee-portion')}
                                      </div>
                                    </div>
                            : null
                      ))} 


                      {peerFeedbackStrengthsQuestionnaire?.length > 0 
                                    && peerFeedbackStrengthsQuestionnaire[0] !== '' ?
                          <React.Fragment>
                            <h3 className='flex text-l mt-8 italic font-bold'> 
                              {t('performance.peer-feedbacks-portion.title')}
                            </h3>
                            {peerFeedbackStrengthsQuestionnaire?.map((element, index) => (
                              // Strengths section - Self review                          
                              element !== "" && element !== "<p></p>" && element !== " " && element ?
                          
                                            <div className="flex flex-col mt-8 ml-2 m-1">
                                                <div className='border-l-4 border-gray-200'>
                                                    <NonEditableEditor content={element}/>
                                                  </div>
                                            </div>
                                  : 
                                    null
          
                            ))} 
                          </React.Fragment>
                        :
                        null
                        }

                      <SectionSeparator />

                      {/* Growth - erased for now */}
                      { null ? 
                        <React.Fragment>
                          <div className="flex flex-col mt-8 ml-2 m-1">
                            <h2 className="flex text-2xl font-bold">
                              {t('performance.growth.title')}
                            </h2>
                          </div>

                          {performanceReview?.map((element, index) => (
                            // Growth section - Manager
                              element.type === "growth" ?
                                  element?.managerFeedback?.map ((managerFeedback, index) => (
                                    managerFeedback?.type === "growth" ? 
                                      managerFeedback?.questionnaire?.map ((question, index) => (
                                        question?.type === "growth" && question?.answer !== "" && question?.answer !== "<p></<p>" 
                                        && question?.answer !== " " && question?.answer  ?
                                            <div className="flex flex-col mt-8 ml-2 m-1">
                                                <h3 className="mb-4">
                                                  {question.title}
                                                </h3>
                                                <span className="border-l-4 border-gray-200 p-2">
                                                  {question.answer}
                                                </span>
                                            </div>
                                        :
                                        null
                                        
                                      ))  
                                  : null
                                  ))

                          :
                            null
                          ))}

                          {performanceReview?.map((element, index) => (
                            // Strengths section - Self review
                              element.type === "growth" ?                       
                              
                                  element?.questionnaire?.map ((question, index) => (
                                        question.type === "growth" && question?.answer !== "" && question?.answer !== "<p></<p>" 
                                        && question?.answer !== " " && question?.answer  ? 
                                          <div className="flex flex-col mt-8 ml-2 m-1">
                                              <h3 className="mb-4">
                                                {question.title}
                                              </h3>
                                              <span className="border-l-4 border-gray-200 p-2">
                                                {question.answer}
                                              </span>
                                          </div>
                                  : null
                                  ))
                                  
                                : null
                          ))}
                        </React.Fragment>
                        : 
                        null
                      }

                      {/* Action plan */}
                      <div className="flex flex-col mt-8 ml-2 m-1">
                        <h3 className='flex text-l mt-8 italic font-bold'> 
                          {t('performance.discuss.action-plan.title')}
                        </h3>
                        <span className='title-description'>{t('performance.discuss.action-plan.description')}</span>

                      

                        {params ? 
                        <React.Fragment>
                        <div className='flex flex-col items-start w-full input-field'>
                          <Editor content={actionPlan} setContent={setActionPlan} placeholder={t('performance.discuss.action-plan.placeholder')} initialContent={actionPlanPlaceholder}/>
                        </div>
                        <div className='flex flex-col lg:flex-row items-center justify-center lg:justify-end mt-4'>
                          <button className="save-button" onClick={submitActionPlan}>
                                          {t('save')}
                          </button>
                        </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div className='flex flex-col items-start'>
                            {
                              (!actionPlan) ?
                              <div className='border-l-4 border-gray-200'>
                                <NonEditableEditor content={t('performance.discuss.action-plan.empty')}/>
                              </div>
                              : 
                              <div className='border-l-4 border-gray-200'>
                                <NonEditableEditor content={actionPlan}/>
                              </div>
                            }
                          
                        </div>
                        </React.Fragment>
                        }

                        <SectionSeparator />
                      </div>


                    </React.Fragment>
                  :
                  <div className='flex flex-col mt-8 ml-2 m-1'>
                            <h3 className='flex text-l mb-4 font-bold'> 
                                {t('performance.discuss.incomplete')}
                            </h3>
                  </div>
            }
            
          </div>
          </div>
          <div className='right-small-view'>
            <PerformanceHistory companyIDinput={companyID} employeeIDinput={params ? params?.employeeID : employeeID}/>
          </div>
        </div>
      </div>
    </React.Fragment>
                                  
      
    )
}

export default PerformanceDiscuss;