import React, {useState, useEffect} from "react";
import Configs from '../Configs';
import { useTranslation } from 'react-i18next';

import {AiOutlineInfoCircle} from 'react-icons/ai';



/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function ValuesScoring(props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

// Props
var selectedValues = props.values;
const readOnly = props.readOnly;
var result = props.result;

const [incorrectInput, setIncorrectInput] = useState (false);

const [showValuesDescription, setShowValuesDescription] = useState (false);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        // calculateResult(selectedValues);
}, []);

// ------------------------------------------------------------------------------------------
// Form management methods
// ------------------------------------------------------------------------------------------
    function changedValue (e, value, description, index) {
        e.preventDefault();
        // Create object to submit
        
        var selected = e.target.value;
            var objectAnswer = {
                value: value,
                description: description,
                selected: selected
            }

        // console.log ("Score is:", e.target.value);
        if (e.target.value < 1 || e.target.value > 5) {
            // Incorrect input
            setIncorrectInput(true);

            var objectAnswer = {
                value: value,
                description: description,
                selected: 0
            }
        } else {
            // Correct input
        }

        // Get an auxiliary array to update
        var auxSelectedValues = selectedValues;
        auxSelectedValues[index] = objectAnswer;
        
        // Update state values
        selectedValues = [...auxSelectedValues];
        props.setValuesResult(auxSelectedValues);
        props.selectedValues([...auxSelectedValues], props.type);

        // Review all inputs to find incorrect values
        var count = 0;
        var auxResult = 0;
        setIncorrectInput(false);
        auxSelectedValues.forEach(element => {
            count = count + 1;
            auxResult = parseInt(element.selected) + parseInt(auxResult);

            if (parseInt(element.selected) > 5 || parseInt(element.selected) < 1) setIncorrectInput(true);
        });
    }

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-col mt-8 ml-2 m-1'>
                            <h3 className='flex text-l mb-4 italic font-bold'> 
                                {t('values-selector-scoring.title')}
                            </h3>
                            <span className='title-description'>{t('values-selector-scoring.description')}<b>{t('values-selector-scoring.help')}</b></span>


        
        <div className='flex flex-col'>
        <button className="text-left text-xs ml-1 mt-1 underline" onClick={e => setShowValuesDescription(!showValuesDescription)}>
            <div className="flex flex-row items-center ">
            <AiOutlineInfoCircle />  <span className="ml-1">{t('performance.values.show.values.description')}</span>
            </div>
        </button>
                                    
            {selectedValues?.map((element, index) => (
                    <div className='flex flex-col lg:flex-row border border-gray-200 rounded p-2 ml-0 lg:mr-8 m-1 items-center justify-between'>
                        <div className="flex flex-col">
                            <span className='text-lg mb-0 m-2 font-bold' onClick={e => setShowValuesDescription(!showValuesDescription)}>
                                {element?.value}
                            </span>
                            <span className={showValuesDescription ? "title-description mt-1 m-2" : "hidden"}>
                                {element?.description}
                            </span>
                        </div>
                        <div className='flex flex-row items-end text-l mb-0 m-2 items-center text-center '>
                            <button className={" flex px-4 py-2 bg-green-100 rounded-full mr-2 " + ((element?.selected?.toString() === "1") ? " border-4 border-black" :
                             null)}
                            value={1} onClick={(e) => changedValue(e, element?.value, element?.description, index)} disabled = {readOnly ? true : false}> 1 </button>
                            <button className={" flex px-4 py-2 bg-green-200 rounded-full mr-2 " + ((element?.selected?.toString() === "2") ? " border-4 border-black" :
                             null)}                            
                             value={2} onClick={(e) => changedValue(e, element?.value, element?.description, index)} disabled = {readOnly ? true : false}> 2 </button>
                            <button className={" flex px-4 py-2 bg-green-300 rounded-full mr-2 " + ((element?.selected?.toString() === "3") ? " border-4 border-black" :
                             null)}                            
                             value={3} onClick={(e) => changedValue(e, element?.value, element?.description, index)} disabled = {readOnly ? true : false}> 3 </button>
                            <button className={" flex px-4 py-2 bg-green-400 rounded-full mr-2 " + ((element?.selected?.toString() === "4") ? " border-4 border-black" :
                             null)}                            
                             value={4} onClick={(e) => changedValue(e, element?.value, element?.description, index)} disabled = {readOnly ? true : false}> 4 </button>
                            <button className={" flex px-4 py-2 bg-green-500 rounded-full mr-2 " + ((element?.selected?.toString() === "5") ? " border-4 border-black" :
                             null)}                            
                             value={5} onClick={(e) => changedValue(e, element?.value, element?.description, index)} disabled = {readOnly ? true : false}> 5 </button>
                        </div>

                    </div>
                ))}

        </div>  
        <div className="flex flex-row items-end justify-end mr-6 p-4 text-right ">
            {/** t('values-selector-scoring.result')  */} 
            <div className="p-4 ">
            { t('score')}
            <span className="ml-4 text-4xl font-bold ">{result}</span>
            </div>
        </div>

        <span className="mr-8 text-red-600 text-right font-bold">
            {incorrectInput ? t('values-selector-scoring.error') : null}
        </span>        

    </div>

  );
}

export default ValuesScoring;