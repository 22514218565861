// React
import React, {useState, useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";

// Header
import Header from '../../common/header';
import CalibrationHeader from './calibrationHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

import SectionSeparator from '../../widgets/sectionSeparator';
import UserListItemCalibration from '../../widgets/userListItemCalibration';
import UserListItem from '../../widgets/userListItem';

import FilterWidget from '../../widgets/data/filterWidget';

import Configs from '../../Configs';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';
import i18next from 'i18next';
import Select from 'react-select';

// Icons
import {IoWarningOutline} from 'react-icons/io5';
import {AiOutlineDownload, AiOutlineFilter} from 'react-icons/ai';
import {IoIosHelpCircleOutline} from 'react-icons/io';


// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';


// ---------------------------------------------------------------------------------------------------
function CalibrationHome () {
    // To pass location to the Header
    const location = useLocation();

    const {companyID, employeeID, token, securityLevel} = useAuth();
    const {teamDirects, isDirectsLoading, teamOrg, isOrgLoading, companyOrg, isCompanyLoading, companyPreferences} = useData();
    const {calibrationCampaign, isCalibrationCampaignLoading, getCalibrationData, liveCalibrationCampaign, setLiveCalibrationCampaign} = useData();
    const {calibrationCampaignHistory, getCalibrationHistory, isCalibrationCampaignHistoryLoading} = useData();
    const {toasterMessage, setToasterMessage} = useData();

    // Frontend messages
    const [incorrectPerformance, setIncorrectPerformance] = useState(false);
    const [incorrectPotential, setIncorrectPotential] = useState(false);
    const [missingEmployees, setMissingEmployees] = useState(false);

    // Variables to use
    const [calibration, setCalibration] = useState(null);
    const [lastCalibration, setLastCalibration] = useState(null);
    const [totalPerformance, setTotalPerformance] = useState(0);
    const [totalPotential, setTotalPotential] = useState(0);
    // Modify when it is allowed as a company preference
    const [maxPerformance, setMaxPerformance] = useState(4.5);
    const [maxPotential, setMaxPotential] = useState(4.5);

    // Team filtering
    const [filteredData, setFilteredData] = useState([]);

    if (Configs.devEnvironment) console.log ("[calibrationHome.js][main()] DEV ONLY log - teamDirects: ", teamDirects);
    if (Configs.devEnvironment) console.log ("[calibrationHome.js][main()] DEV ONLY log - calibrationCampaign: ", calibrationCampaign);
    if (Configs.devEnvironment) console.log ("[calibrationHome.js][main()] DEV ONLY log - calibrationCampaignsHistory: ", calibrationCampaignHistory);
    if (Configs.devEnvironment) console.log ("[calibrationHome.js][main()] DEV ONLY log - calibration: ", calibration);
    if (Configs.devEnvironment) console.log ("[calibrationHome.js][main()] DEV ONLY log - lastCalibration: ", lastCalibration);


  // --------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------    


    // Methods to load when page is loaded
    useEffect (() => {
        // Get information to populate dashboard
        if (calibrationCampaign) {
            if (calibrationCampaign?.existingCalibration?.length > 0) {
              setIncorrectPerformance(false);
              setIncorrectPotential(false);
              setMissingEmployees(false);
    
              setCalibration(calibrationCampaign?.existingCalibration);
              setLiveCalibrationCampaign(false);
    
              // If there is a team populated, populate the fronted
              if (teamDirects) populateFrontEndWithDraft (calibrationCampaign?.existingCalibration[0]);
            } else {
              // No calibration was made, so all good
              setLiveCalibrationCampaign(true);
              sessionStorage.setItem('liveCalibrationCampaign', CryptoJS.AES.encrypt("true",Configs.privateKey).toString());
            }
        } else {
          setLiveCalibrationCampaign(false);
        }

        if (!calibrationCampaignHistory && !isCalibrationCampaignHistoryLoading) {
          getCalibrationHistory();
        } else {
          // There is an existing history, check if it is necessary to populate the campaign
          if (teamDirects && !liveCalibrationCampaign) {
            populateCampaignWithHistory(calibrationCampaignHistory);
          }
        }

        if (teamDirects) {
          setFilteredData(teamDirects);
        }

      }, [liveCalibrationCampaign, calibrationCampaign, calibrationCampaignHistory]);

    
  // ---------------------------------------------------------------------------------------------------
  // API calls methods
  // ---------------------------------------------------------------------------------------------------

    // Method to submit calibration scores
    async function submitCalibration () {
        // HTTP POST request options
          const requestOptions = {
              method: 'POST',
              credentials: 'include',
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
              body: JSON.stringify({ 
                companyID: companyID,
                employeeID: employeeID,
                calibration: calibration,
                date: new Date(),
              })
          };

    // Post calibration to server      
    fetch(Configs.platformPostCalibrationCampaignAPI + "?employeeID=" + employeeID + "&companyID=" + companyID  + "&campaignID=" + calibrationCampaign?.campaignID, requestOptions)
      .then((response) => {
        // console.log (response);
        if (response.status === 200) {
          // All good
          if (Configs.devEnvironment) console.log("[calibrationHome.js][submitCalibration()] - Saved successfully");
          setToasterMessage({message: t('calibration.submit.success'), type: Configs.successToaster});
          setLiveCalibrationCampaign(false);
          sessionStorage.removeItem('liveCalibrationCampaign');
          getCalibrationData();

        } else if (response.status === 400) {
          // Something went wrong - inform user
          setToasterMessage({message: t('calibration.load.warning'), type: Configs.warningToaster});

        } else if (response.status === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
              sessionStorage.clear();
              window.location.reload();
        } else if (response.status === 403) {
              // Force logout
              setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
        } else {
          // There is an error - delete info
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }
      })

      .catch((error) => {
        console.error("[calibrationHome.js][submitCalibration()] Error fetching data: ", error);
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      });

  }
  

    // ---------------------------------------------------------------------------------------------------
    // Frontend control methods
    // ---------------------------------------------------------------------------------------------------
    async function populateFrontEndWithDraft (draftCalibration ) {
      console.log ("[calibrationHome.js][populateFrontEndWithDraft()] - draftCalibration: ", draftCalibration);

      if (draftCalibration && draftCalibration?.length > 0) {  
        draftCalibration?.forEach((employee, index) => {
          if (employee?.employeeID === teamDirects?.find(employeeAux => employeeAux.employeeID === employee?.employeeID)?.employeeID) {
            calculateCalibrationResults(employee.employeeID, "performance", employee.performance);
            calculateCalibrationResults(employee.employeeID, "potential", employee.potential);
          }
        });
      }
    }

    async function populateCampaignWithHistory (allEmployeesCalibrations) {
      if (Configs.devEnvironment) console.log("[calibrationHome.js][populateCampaignWithHistory()] - allEmployeesCalibrations: ", allEmployeesCalibrations);

      teamDirects?.forEach((direct, directIndex) => {
          allEmployeesCalibrations?.forEach((employee, index) => {
            // Look for the calibration score that matches an employee from teamDirects
              if (employee?.employeeID === direct?.employeeID) {
                calculateCalibrationResults(directIndex, "performance", employee?.calibrations[0].calibration.performance);
                calculateCalibrationResults(directIndex, "potential", employee?.calibrations[0].calibration.potential);
              }
          });
      });

    }
    
    async function calculateCalibrationResults (employeeIDInput, type, value) {
      // Value comes parsed as Integer already
      if (Configs.devEnvironment) console.log("[calibrationHome.js][calculateCalibrationResults()] - calibration input: ", employeeIDInput, type, value, calibration);

      // Aux array
      var auxCalibration = (calibration !== null) ? calibration : (lastCalibration !== null ? lastCalibration : []);

      // Aux object to store the updated employee
      const auxEmployee = {
        employeeID: employeeIDInput,
        companyID: companyID,
        performance: 0,
        potential: 0,
      }

      if (type === "performance") {
        // Update was on performance only
        auxEmployee.performance = value;
        auxEmployee.potential = (auxCalibration !== null) ? auxCalibration?.find(calibration => parseInt(calibration?.employeeID) === parseInt(employeeIDInput))?.potential : 0;
      } else if (type === "potential") {
        // Update was on potential only
        auxEmployee.performance = (auxCalibration !== null) ? auxCalibration?.find(calibration => parseInt(calibration?.employeeID) === parseInt(employeeIDInput))?.performance : 0;
        auxEmployee.potential = value;
      }

      if (Configs.devEnvironment) console.log("[calibrationHome.js][calculateCalibrationResults()] - auxEmployee: ", auxEmployee);

      // Update employee on array, if array is empty, push it
      if (!auxCalibration || auxCalibration.length === 0) {
        auxCalibration.push(auxEmployee);
      } else {
        var existing = false;

        // Update on existing
        auxCalibration?.forEach((employee) => {
          if (parseInt(employee?.employeeID) === parseInt(employeeIDInput)) {
            existing = true;
            if (type === "performance") {
              employee.performance = value;
            } else if (type === "potential") {
              employee.potential = value;
            } else {
            // Idle
            }
          }
        });

        // If employee does not exist, add it to calibration
        if (!existing) {
          auxCalibration.push(auxEmployee);
        }
      }
      
      if (Configs.devEnvironment) console.log("[calibrationHome.js][calculateCalibrationResults()] - auxCalibration: ", auxCalibration);
      // Populate state
      if(liveCalibrationCampaign) {
        setCalibration([...auxCalibration]);  
      } else {
        setLastCalibration([...auxCalibration]);
      }
      

      // Calculate calibration results for selected employees
      var performanceResultAux = 0;
      var potentialResultAux = 0;
      var countOfEmployeesPerformance = 0;
      var countOfEmployeesPotential = 0;

      auxCalibration?.forEach((employee, index) => {
        // if existing performance calibration is not a number do not add
        if (employee?.performance) {
          performanceResultAux += employee?.performance;
          countOfEmployeesPerformance++;
        }
        // if existing potential calibration is not a number do not add
        if (employee?.potential) {
          potentialResultAux += employee?.potential;
          countOfEmployeesPotential++;
        }

      });

      // Calculate scores if performanceResultAux is a number
      if (performanceResultAux) {
        setTotalPerformance ((performanceResultAux / countOfEmployeesPerformance).toFixed(2));
      } else {
        setTotalPerformance (0);
      }
      // Calculate scores if potentialResultAux is a number
      if (potentialResultAux) {
        setTotalPotential ((potentialResultAux / countOfEmployeesPotential).toFixed(2));
      } else {
        setTotalPotential (0);
      }

      if (Configs.devEnvironment) console.log("[calibrationHome.js][calculateCalibrationResults()] - calibration: ", auxCalibration);
    }

    // Validate if inputs are valid and submit calibration
    async function confirmCalibration (e) {
      e.preventDefault();

      // Reset all variables
      var auxMissingEmployees = false;
      var auxIncorrectPerformance = false;
      var auxIncorrectPotential = false;
      setMissingEmployees(false);
      setIncorrectPerformance(false);
      setIncorrectPotential(false);

      // Check if the is input is valid (all employees have a performance and potential score) and it is within the range
      // 1. There is input for all employees in team Directs
      var employeesRated = 0;
      calibration.forEach ((employee, index) => {
        if (employee?.performance === undefined || employee?.potential === undefined) {
          // There is an employee without a score
          setMissingEmployees(true);
          auxMissingEmployees = true;
        }
        employeesRated++;
      });

      if (employeesRated != teamDirects?.length) {
        // There is an employee without a score
        setMissingEmployees(true);
        auxMissingEmployees = true;
      }

      // 2. All inputs are within the range
      if (totalPerformance > maxPerformance || totalPerformance <= 0) {
        setIncorrectPerformance(true);
        auxIncorrectPerformance = true;
      }
      if (totalPotential > maxPotential || totalPotential <= 0) {
        setIncorrectPotential(true);
        auxIncorrectPotential = true;
      }

      if (!auxMissingEmployees && !auxIncorrectPerformance && !auxIncorrectPotential) {
        // All good - submit calibration
        submitCalibration();
      }
    }


    // ---------------------------------------------------------------------------------------------------
    // Filter methods
    // ---------------------------------------------------------------------------------------------------   
    const handleFilterChange = (filteredDataInput) => {
      if (Configs.devEnvironment) console.log("[oneOnOne.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
      setFilteredData(filteredDataInput);
    };


    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
      };
    
    
   // ---------------------------------------------------------------------------------------------------
   // Render methods
   // ---------------------------------------------------------------------------------------------------
  
        return (
          
          <React.Fragment>
          <Header/>
          <Bot />

          <div className="main">

          <CalibrationHeader />
            
          <div className='flex flex-row items-start lg:ml-4'>
          <div className="left-big-view">

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

            <div className="flex flex-col items-start text-left ">
              <div className="flex flex-row items-center mt-2 md:ml-2 m-1">
                <Tooltip content={t('calibration.team.description')}>
                  <h2 className="section-title">
                    🫂 {t('calibration.team.title')}
                  </h2>
                </Tooltip>
                {i18next.language === 'es' ? 
                    <a href={companyPreferences?.personalization?.pulseEmailsHelpURL + "#calibration"} target="_blank" rel="noreferrer">
                      <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                    </a>
                  : 
                    <a href={companyPreferences?.personalization?.pulseEmailsHelpURL + "#calibration"} target="_blank" rel="noreferrer">
                      <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                    </a>
                  }  
              </div>

              {securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) ? 
                    <React.Fragment>

                      {isCalibrationCampaignLoading ?
                          <Loader />

                        :
                        <div className='flex flex-col'>
                          <div className='flex flex-col'>
                            <h2 className="m-2 text-xl">
                                { liveCalibrationCampaign ? (t('Calibration') + ": " + calibrationCampaign?.name) :  null}
                            </h2>
                            <span className='m-2 title-description'>
                                { liveCalibrationCampaign ? t('calibration.team.live.description') : null}
                            </span>
                          </div>

                            {teamDirects ? 

                              <div className='flex flex-col'>

                              {/* Filters */}
                              { isOrgLoading ? 
                                  <Loader />
                              :
                              (securityLevel?.includes(Configs.securityLevelManager) && teamOrg)
                              || (securityLevel?.includes(Configs.securityLevelHR) && companyOrg)
                              || (securityLevel?.includes(Configs.securityLevelExec) && companyOrg)
                               ? 
                                  <div className="flex flex-row items-center justify-start space-x-2 m-4">
                                      {/* Filters widget */}
                                      <div className=''>
                                          <FilterWidget data={(securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) ? companyOrg : teamOrg} 
                                            onFilterChange={handleFilterChange}
                                            teamTypeFilter={true} defaultTeamTypeFilter={"team"}
                                            managerFilter={true}
                                            employeeFilter={true}
                                            areaFilter={true} 
                                            subareaFilter={true}
                                          />
                                      </div>
                                      
                                  </div>
                                : 
                                  null
                                }


                                      {liveCalibrationCampaign ?
                                      // Live calibration campaign
                                          filteredData?.map((direct, index) => (

                                                      <UserListItemCalibration 
                                                      user={direct?.name + " " + direct?.surname}
                                                      initials={direct?.name?.slice(0,1)+direct?.surname?.slice(0,1)}
                                                      role={direct?.role} level={direct?.level}
                                                      area={direct?.area} subarea={direct?.subarea}
                                                      profilePicture={direct?.profilePicture}
                                                      calculateCalibrationResults={calculateCalibrationResults}
                                                      index={index}
                                                      employeeID={direct?.employeeID}
                                                      performanceRate={calibration ? calibration?.find(calibration => calibration?.employeeID === direct?.employeeID)?.performance : null}
                                                      potentialRate={calibration ? calibration?.find(calibration => calibration?.employeeID === direct?.employeeID)?.potential : null}
                                                      readOnly={!(liveCalibrationCampaign && (direct?.managerID === employeeID))}/>
                                          ))

                                        :
                                        // Show latest calibration campaign results
                                        (calibrationCampaignHistory && calibrationCampaignHistory?.length > 0) ? 

                                          filteredData?.map((direct, index) => (

                                                  <UserListItemCalibration
                                                  user={direct?.name + " " + direct?.surname}
                                                  initials={direct?.name?.slice(0,1)+direct?.surname?.slice(0,1)}
                                                  role={direct?.role} level={direct?.level}
                                                  area={direct?.area} subarea={direct?.subarea}
                                                  profilePicture={direct?.profilePicture}
                                                  calculateCalibrationResults={calculateCalibrationResults}
                                                  index={index}
                                                  employeeID={direct?.employeeID}
                                                  performanceRate={lastCalibration ? lastCalibration?.find(calibration => calibration?.employeeID === direct?.employeeID)?.performance : (calibration ? calibration?.find(calibration => calibration?.employeeID === direct?.employeeID)?.performance : null)}
                                                  potentialRate={lastCalibration ? lastCalibration?.find(calibration => calibration?.employeeID === direct?.employeeID)?.potential : (calibration ? calibration?.find(calibration => calibration?.employeeID === direct?.employeeID)?.potential : null)}
                                                  readOnly={!(liveCalibrationCampaign && (direct?.managerID === employeeID))}/>
                                            ))

                                        :
                                        
                                            <div className='flex flex-col items-center'>
                                              <span className='m-2 title-description italic'>
                                              ⚖️ {t('calibration.team.no-calibration')}
                                              </span>
                                            </div>
                                              
                                        }

                                      {liveCalibrationCampaign 
                                        || (calibrationCampaignHistory && calibrationCampaignHistory?.length > 0) ?
                                      <React.Fragment>

                                          <div className='flex flex-col lg:flex-row m-4 items-end justify-between  '>
                                              <div className='flex'>
                                                {t('total')}
                                              </div>
                                              <div className='flex flex-col lg:flex-row text-xl font-bold mr-0 lg:mr-24'>
                                                  <div className={'flex mr-0 lg:mr-20 ' + (incorrectPerformance ? " text-red-600 " : null)}>
                                                  {t("calibration.team.performance")}: <span className='ml-2'>{totalPerformance}</span>
                                                  </div>

                                                  <div className={'flex ml-0 lg:ml-20 ' + (incorrectPotential ? " text-red-600  " : null)}>
                                                  {t("calibration.team.potential")}: <span className='ml-2'>{totalPotential}</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className='flex flex-col lg:flex-row m-4 items-end justify-end'>
                                              {incorrectPerformance ? (t('calibration.team.performance.error') + " " + maxPerformance) : null} 
                                              <br></br>
                                              {incorrectPotential ? (t('calibration.team.potential.error') + " " + maxPotential) : null}
                                              <br></br>
                                              {missingEmployees ? t('calibration.team.missingEmployees.error') : null}
                                          </div>
                                      </React.Fragment>

                                      : 
                                        
                                        null

                                      }
                              </div> 
                            :
                              null
                            }

                            {liveCalibrationCampaign ? 

                              <React.Fragment>
                                <div className='flex flex-row bg-yellow-100 p-2 m-2 text-sm items-center rounded'>
                                  <div className='mr-4'><IoWarningOutline /></div>
                                  {t('calibration.submit.warning')}
                                </div>
                              
                                <div className='flex flex-row text-right justify-end items-end float-right m-2'>                                
                                  <button className="save-button" onClick={confirmCalibration}>
                                    {t('calibration.team.submit')}
                                  </button>
                                </div>
                                </React.Fragment>
                            :
                                null
                            }
                          </div>
                          }
                        </React.Fragment>

                        : 
                        null
                        }

                        <SectionSeparator />

                      

            </div>
            </div>
            <div className='right-small-view'>
              
              </div>
            </div>
            </div>
          </React.Fragment>
        );
}


export default CalibrationHome;

