// React
import React, { useState, useEffect, useRef } from 'react';

// Configs
import Configs from '../Configs';

// 3P
import { XYPlot, XAxis, YAxis, HeatmapSeries, Hint, LabelSeries, VerticalGridLines, HorizontalGridLines } from 'react-vis';
import '../index.css';  // Assume a CSS file for additional styling

// ------------------------------------------------------
const Heatmap = ({ 
        data, // Data to be displayed
        rowDimension, // Row dimension
        columnDimension // Column dimension
    }) => {

    // Data
    const [heatmapData, setHeatmapData] = useState([]);
    const [value, setValue] = useState(null);

    // Dynamic sizes
    const [plotHeight, setPlotHeight] = useState(200 + data?.length * 35);
    // Max number of columns of each of the managers
    const columnsAux = data?.map(manager => Object.keys(manager));
    const columnsMax = columnsAux?.reduce((acc, val) => (val.length > acc.length ? val : acc), []);

    const [plotWidth, setPlotWidth] = useState(250 + columnsMax?.length * 85);
    const [leftMargin, setLeftMargin] = useState(100);

    const containerRef = useRef(null);

    if (Configs.devEnvironment) console.log('[heatmap.js][main()] Heatmap: ', data);

    // ------------------------------------------------------
    // usEffect functions
    // ------------------------------------------------------
    useEffect(() => {
        function handleResize() {
            if (containerRef.current) {
                // setPlotWidth(containerRef.current.offsetWidth);
            }

            // Assuming the longest manager name determines the needed left margin
            const maxLabelLength = Math.max(...data.map(manager => manager.manager.length));
            const leftMarginAux = maxLabelLength * 6;  // Roughly 8 pixels per character
            setLeftMargin(leftMarginAux);
        }

        // Set the initial width
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (data && data?.length > 0) {
            const transformedData = transformDataForHeatmap(data, rowDimension, columnDimension);
            if (Configs.devEnvironment) console.log ('[heatmap.js][transformDataForHeatmap()] Transformed data: ', rowDimension, columnDimension, transformedData);
            setHeatmapData(transformedData);
        }

    }, [data, rowDimension, columnDimension]);

    // ------------------------------------------------------
    // Data transform functions
    // ------------------------------------------------------
    // Convert data to a format suitable for HeatmapSeries and LabelSeries
    const transformDataForHeatmap = (aggregatedData, rowDimension, columnDimension) => {
        const data = [];
        aggregatedData?.forEach(row => {
            for (const col in row) {
                if (col !== rowDimension) {
                    data.push({
                        x: col,
                        y: row[rowDimension],
                        color: row[col]?.averageScore?.toFixed(1),
                        label: row[col]?.averageScore?.toFixed(1)?.toString(),
                    });
                }
            }
        });
        return data;
    };

    // Custom XAxis label component to wrap text
    const CustomXAxisTick = ({ x, y, payload }) => {
        const words = payload.value.split(' ');
        return (
            <g transform={`translate(${x},${y})`}>
                <text style={{ fontSize: '20px' }} textAnchor="middle">
                    {words.map((word, index) => (
                        <tspan key={index} x="0" dy={index === 0 ? '0em' : '1.2em'}>
                            {word}
                        </tspan>
                    ))}
                </text>
            </g>
        );
    };

    // Custom YAxis label component to wrap text
    const CustomYAxisTick = ({ x, y, payload }) => {
        const words = payload.value.split(' ');
        return (
            <g transform={`translate(${x},${y})`}>
                <text style={{ fontSize: '18px' }} textAnchor="end">
                    {words.map((word, index) => (
                        <tspan key={index} x="0" dy={index === 0 ? '0em' : '1.2em'}>
                            {word}
                        </tspan>
                    ))}
                </text>
            </g>
        );
    };

    // ------------------------------------------------------
    // Render component
    // ------------------------------------------------------
    return (
        <div className='flex flex-col w-full overflow-x-auto' ref={containerRef} style={{ width: '100%' }}>
            <XYPlot 
            width={plotWidth}
            height={plotHeight}
            xType="ordinal"
            yType="ordinal"
            colorDomain={[1, 5]}
            colorType="linear"
            colorRange={['#ff0000', '#087f23']} // Use a gradient for a more modern look
            style={{ color: '#fff' }}       
            margin={{ left: leftMargin, bottom: 50, right: 10, top: 50 }}
            >
                <XAxis 
                    orientation='top' 
                    hideLine
                    tickPadding={10}
                    // tickLabelAngle={-45} 
                    tickFormat={d => d} 
                    tickSizeOuter={0}
                    tickComponent={CustomXAxisTick} // Use custom tick component
                    style={{
                        text: { fontSize: '10px' }
                    }}
                />
                <YAxis 
                hideLine
                tickSizeOuter={0}
                tickComponent={CustomYAxisTick} // Use custom tick component
                    style={{
                        text: { fontSize: '10px' }
                    }}
                />
                <HeatmapSeries
                    className="heatmap-series-example"
                    data={heatmapData}
                    // colorRange={['#d3f9d8', '#087f23']} // Use a gradient for a more modern look
                    style={{ stroke: 'white', strokeWidth: '2px', borderRadius: '10px' }} // Adjust border and radius for modern look
                    onValueMouseOver={v => setValue(v)}
                    onValueMouseOut={() => setValue(null)}
                />
                {value && (
                    <Hint value={value}>
                        <div className="hint-box">
                            <p>Manager: {value?.y}</p>
                            <p>Category: {value?.x}</p>
                            <p>Score: {value?.color}</p>
                        </div>
                    </Hint>
                )}
                <LabelSeries
                    data={heatmapData}
                    style={{ fontSize: '12px', fontWeight:'bold', fill: '#fff' }} // Use a contrasting color for labels
                    labelAnchorX="middle"
                    labelAnchorY="middle" 
                    onValueMouseOver={v => setValue(v)}
                    onValueMouseOut={() => setValue(null)}

                    />
            </XYPlot>
        </div>
    );
};

// ------------------------------------------------------
// Export component
// ------------------------------------------------------
export default Heatmap;