// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';

// Icons
import {GiPoliceOfficerHead } from "react-icons/gi";

// ------------------------------------------------------
function SuperAdminHeader () {
    
    const screens = [
        {view:t('CCOaaS'), url:"/admin/ccoaas/", securityLevel:[Configs.securityLevelSuperAdmin], id:"admin.header.ccoaas"},
        {view:t('admin.style-guide'), url:"/admin/style-guide/", securityLevel:[Configs.securityLevelSuperAdmin], id:"admin.header.style-guide"},
        {view:t('admin.companies'), url:"/admin/company/", securityLevel:[Configs.securityLevelSuperAdmin], id:"admin.header.companies"},
        {view:t('admin.ai-experiments'), url:"/admin/ai-experiments/", securityLevel:[Configs.securityLevelSuperAdmin], id:"admin.header.ai-experiments"},
    ];

   // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('super-admin')} Icon={GiPoliceOfficerHead} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default SuperAdminHeader;