// React
import React, { useState, useEffect} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';

// 3P or Frameworks
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Header
import Header from '../../common/header';
import PerformanceHeader from './performanceHeader';
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import {  useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// Common and Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import UserListItem from '../../widgets/userListItem';
import ValuesSelector from '../../widgets/valuesSelector';
import ValuesScoring from '../../widgets/valuesScoring';
import ValuesScoringWithComment from '../../widgets/valuesScoringWithComment';

// TapTap text editor
import Editor from '../../widgets/editor';
import NoneditableEditor from '../../widgets/noneditableEditor';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';
import PerformanceHistory from '../../widgets/performance/performanceHistory';

// ---------------------------------------------------------------------------------------------------
function PerformanceStrengths () {

  // Using params when performance from team members
  let { encryptedParams } = useParams();
  const params = (encryptedParams && encryptedParams !== undefined) ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

  // To pass location to the Header
  const location = useLocation();
  const navigate = useNavigate();
  const currentScreen = location.pathname;

  const {companyID, employeeID, token, securityLevel} = useAuth();
  const {toasterMessage, setToasterMessage, companyPreferences} = useData();

  const {selectedProfile, setSelectedProfile} = useData();

  // General
  const {livePerformanceCampaign, setLivePerformanceCampaign, performanceReviewCampaigns} = useData();

  // Personal
  const [performanceReviewDraft, setPerformanceReviewDraft] = useState(null);
  const [isPerformanceReviewDraftLoading, setIsPerformanceReviewDraftLoading] = useState(false);
  const [performanceReviewManagerDraft, setPerformanceReviewManagerDraft] = useState(null);
  const [isPerformanceReviewManagerDraftLoading, setIsPerformanceReviewManagerDraftLoading] = useState(false);

  const companyValuesAux = companyPreferences ? [...companyPreferences?.companyValues] : [];

  // Questionnaire that is shown in FrontEnd
  const [questionnaire, setQuestionnaire] = useState([]);
  const [managerQuestionnaire, setManagerQuestionnaire] = useState([]);
  const [questionnaireAuxEditor, setQuestionnaireAuxEditor] = useState([]);
  const [managerQuestionnaireAuxEditor, setManagerQuestionnaireAuxEditor] = useState([]);
  
  // IC input for values
  const [selectedValues, setSelectedValues] = useState([...companyValuesAux]);
  const [numOfSelectedValues, setNumOfSelectedValues] = useState(0);
  const [valuesResult, setValuesResult] = useState(0);
  // Manager input for values
  const [managerSelectedValues, setManagerSelectedValues] = useState([...companyValuesAux]);
  const [managerNumOfSelectedValues, setManagerNumOfSelectedValues] = useState(0);
  const [managerValuesResult, setManagerValuesResult] = useState(0);

  if (Configs.devEnvironment) console.log ("[performanceStrengths.js][main()] - values: ", managerSelectedValues, selectedValues);

  // AUTO-SAVE FEATURE
  const [employeeChanged, setEmployeeChanged] = useState(false);
  const [managerChanged, setManagerChanged] = useState(false);

    // Logging only in Dev
    if (Configs.devEnvironment) console.log ("[performanceStrengths.js][main()] - selectedProfile: ", selectedProfile);
    if (Configs.devEnvironment) console.log ("[performanceStrengths.js][main()] - Campaigns: ", performanceReviewCampaigns);
    if (Configs.devEnvironment) console.log ("[performanceStrengths.js][main()] - Existing employee draft: ", performanceReviewDraft, typeof(performanceReviewDraft));
    if (Configs.devEnvironment) console.log ("[performanceStrengths.js][main()] - Existing manager draft: ", performanceReviewManagerDraft, typeof(performanceReviewManagerDraft));
    if (Configs.devEnvironment) console.log ("[performanceStrengths.js][main()] - Values: ", selectedValues, managerSelectedValues, companyValuesAux );

  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------
  useEffect (() => {
    // Fix selectedProfile
    if (!params && selectedProfile) {
      // IC
      setSelectedProfile(null); 
    } else if (params && !selectedProfile) {
      // Navigate to team
      navigate("/performance/team");
      console.error("[performanceReview.js][useEffect()] - No selectedProfile found");
    }
  }, [location, selectedProfile]);

  useEffect (() => {
    // Add event listeners for when the user wants to leave the page 
    const handleBeforeUnload = (event) => {
      // Perform actions before the user leaves the page
      event.preventDefault();
      event.returnValue = ''; // Some browsers require a return value to display a prompt
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

      // Clean-up before leving view
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };

  }, []);

  useEffect (() => {
    // Get drafts
    async function getDrafts() {
        if (!params) {
          // IC
          try {
            setIsPerformanceReviewDraftLoading(true);
            
            const response = await apiCalls.getPerformanceReviewDraft(companyID, employeeID, token, performanceReviewCampaigns[0]?._id)

            setIsPerformanceReviewDraftLoading(false);

            if (response?.length > 0) {
              setPerformanceReviewDraft(response);
              updateFrontendData(response);
            } else {
              setPerformanceReviewDraft(null);
              updateFrontendData(null);
            }
            return true;

          } catch (error) {
            setPerformanceReviewDraft(error);
            return false;
          }

        } else {
          // Manager
          try {
            setIsPerformanceReviewManagerDraftLoading(true);

            const response = await apiCalls.getPerformanceReviewManagerDraft(companyID, params?.employeeID, token, performanceReviewCampaigns[0]?._id, params.managerID);

            setIsPerformanceReviewManagerDraftLoading(false);

            if (response?.length > 0) {
              setPerformanceReviewManagerDraft(response);
              updateManagerFrontendData(response);
            } else {
              if (response === 204) {
                setPerformanceReviewManagerDraft(null);
                updateManagerFrontendData(null);
              }
            }
            return true;

          } catch (error) {
            setPerformanceReviewManagerDraft(error);
            return false;
          }
        }
      }

      getDrafts();

  }, [companyID, employeeID, token, performanceReviewCampaigns]);


  useEffect (() => {
    // Update screen only if there is a draft
    // A - IC view
    // NAVIGATION
    // IC
    if ((performanceReviewDraft) 
      && (performanceReviewDraft[0]?.status === "completed" || performanceReviewDraft[0]?.status === "submitted" ||performanceReviewDraft[0]?.status === "sent")) {
          // We have a draft that has been either submitted by IC, or even completed by Manager
          setLivePerformanceCampaign(false);
          navigate("/performance/self/discuss");
    } else {
      setLivePerformanceCampaign(true);
    }
  }, [performanceReviewDraft]); 


  useEffect (() => {
    if (params) {
      // NAVIGATION ONLY
      // B - Manager view
      if ((performanceReviewManagerDraft) 
      && (performanceReviewManagerDraft[0]?.status === "completed" || performanceReviewManagerDraft[0]?.status === "sent")) {
          // We have a draft that has been completed by Manager
            navigate("/performance/team/employee/discuss/" +
            encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                employeeID: params?.employeeID,
                employee: params?.employee,
                companyID: companyID,
                managerID: employeeID
                }),Configs.privateKey).toString()));
                setLivePerformanceCampaign(false);
      } else {
        setLivePerformanceCampaign(true);
      }
    }

  }, [performanceReviewManagerDraft]);

  // AUTO-SAVE USE EFFECT
  useEffect (() => {
    const interval = setInterval(() => {
      if (Configs.devEnvironment) console.log ("[performanceStrengths.js][useEffect()] - Auto-save: employeeChanged: ", employeeChanged, " - maangerChanged:", managerChanged);

      if (employeeChanged) {
        submitDraft(false);
        setEmployeeChanged(false);
      }

      if (managerChanged) {
        submitManagerDraft(false);
        setManagerChanged(false);
      }

    }, 30000); // Auto-save every 30 seconds

    return () => {
      clearInterval(interval);
    }
    
  }, [employeeChanged, managerChanged]);

  // ---------------------------------------------------------------------------------------------------
  // Data transformation methods
  // ---------------------------------------------------------------------------------------------------
  async function updateFrontendData (body) {
    // Get information to populate dashboard
    var numOfValuesSelectedAux = 0;
    if (body && body?.length > 0) {
      const newQuestionnaire = [];
      const newValues = [];

      body?.forEach(element => {
            if (element.type === "strength") {
                newQuestionnaire.push(...element?.questionnaire);
                newValues.push(...element?.values); 

                if (performanceReviewCampaigns[0]?.valuesSelectorType === "score" ||performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
                  calculateValuesResult([...element?.values]);
                }

                element.values?.forEach((value) => {
                    if (value.selected === true) {
                        numOfValuesSelectedAux += 1;
                    }
                });
              }
            });
                
            // Set state
            setQuestionnaire(newQuestionnaire);
            setQuestionnaireAuxEditor(newQuestionnaire);
            setSelectedValues(newValues);
            setNumOfSelectedValues(numOfValuesSelectedAux);

      } else {
        // If empty, set the default values
        if (performanceReviewCampaigns && companyValuesAux) {
          setQuestionnaire([...performanceReviewCampaigns[0]?.questionnaire]);
          setSelectedValues([...companyPreferences?.companyValues]);
        }
      }
  }

  async function updateManagerFrontendData (body) {
    // Get information to populate dashboard
    var numOfValuesSelectedAux = 0;
    if (body && body?.length > 0) {
      const newManagerQuestionnaire = [];
      const newManagerValues = [];
      
      body.forEach((element) => {
            if (element.type === "strength") {
                newManagerQuestionnaire.push(...element.questionnaire);
                newManagerValues.push(...element.values);

                if (performanceReviewCampaigns[0]?.valuesSelectorType === "score" || performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
                  calculateManagerValuesResult([...element.values]);
                }

                element?.values?.forEach(element => {
                    if (element.selected === true) {
                        numOfValuesSelectedAux += 1;
                    }
                });
              }
            });
          
      setManagerQuestionnaire(newManagerQuestionnaire);
      setManagerQuestionnaireAuxEditor(newManagerQuestionnaire);
      setManagerSelectedValues(newManagerValues);
      setManagerNumOfSelectedValues(numOfValuesSelectedAux);
    } else {
      // If empty, set the default values
      if (performanceReviewCampaigns && companyValuesAux) {
        setManagerQuestionnaire([...performanceReviewCampaigns[0]?.managerQuestionnaire]);
        setManagerSelectedValues([...companyValuesAux]);
      }
    }
  }

  // ---------------------------------------------------------------------------------------------------
  // API calls
  // ---------------------------------------------------------------------------------------------------
  async function submitManagerDraft (next) {
    // If no manager permissions, do not submit
    if (securityLevel?.includes(Configs.securityLevelManager) === false) {
      setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      return;
    }

    if (params && !selectedProfile) {
      setToasterMessage({message: t('error'), type: Configs.errorToaster});
      return;
    }

  // HTTP POST request options
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // notice the Bearer before your token
       },
    body: JSON.stringify({ 
      employeeID: params?.employeeID,
      managerID: employeeID,
      companyID: companyID,
      campaignID: performanceReviewCampaigns[0]._id,
      type: "strength",
      questionnaire: managerQuestionnaire,
      values: managerSelectedValues
    })
  };

  // Post to server      
  fetch(Configs.platformPostManagerFeedbackAPI, requestOptions)
      .then((response) => {
        // console.log (response);
        if (response.status === 200) {
          
          // Navigate to the next screen
          if (next) {
            setToasterMessage({message: t('success'), type: Configs.successToaster});
            nextStepManager();
            // if (params) getPerformanceReviewManagerDraft(performanceReviewCampaigns[0]._id, companyID, params?.employeeID, employeeID);
          } 


        } else if (response.status === 400) {
          // Something went wrong - inform user
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
        } else if (response.status === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            sessionStorage.clear();
              window.location.reload();
        } else if (response.status === 403) {
              // Force logout
              setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
              sessionStorage.clear();
              window.location.reload();
        } else if (response.status === 404) {
              // setToasterMessage({message: t('error'), type: Configs.warningToaster});
              setToasterMessage({message: t('error'), type: Configs.errorToaster});
        } else {
          //
          setToasterMessage({message: t('error'), type: Configs.errorToaster});
          throw response;
        }
      })

      .catch((error) => {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
        console.error("[performanceStrength.js][submitManagerFeedback()]  Error fetching data: ", error);
      });
  }
  
  async function submitDraft (next) {
        // HTTP POST request options
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
           },
        body: JSON.stringify({ 
          employeeID: employeeID,
          companyID: companyID,
          campaignID: performanceReviewCampaigns[0]._id,
          type: "strength",
          questionnaire: questionnaire,
          values: selectedValues
        })
      };

      // Post to server      
      fetch(Configs.platformPostSelfFeedbackAPI, requestOptions)
          .then((response) => {
            if (response.status === 200) {              
                if (next) {
                  // All good
                  // if (params) getPerformanceReviewDraft(performanceReviewCampaigns[0]._id, companyID, params?.employeeID);
                  // if (!params) getPersonalPerformanceReviewDraft(performanceReviewCampaigns[0]._id); 
                  setToasterMessage({message: t('performance.strengths.submitted.draft'), type: Configs.successToaster});
                  // Navigate to the next screen
                  nextStepIC();
                }
            } else if (response.status === 400) {
                // Something went wrong - inform user
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 401) {
                // Force logout
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
            } else if (response.status === 404) {
                // setToasterMessage({message: t('error'), type: Configs.warningToaster});
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else {
                // setToasterMessage({message: t('error'), type: Configs.errorToaster});
                // throw response;
            }
          })
    
          .catch((error) => {
            console.log("[performanceStregnths.js][submitDraft()] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          });
    }

// ---------------------------------------------------------------------------------------------------
// Data validation
// ---------------------------------------------------------------------------------------------------
async function validateDraft () {
  // Review questionnaire and selectedValues to see if there is any answer missing
  var missingAnswer = false;
  var missingValueScore = false;
  var missingValueComment = false;

  // Check questionnaire
  for (var i = 0; i < questionnaire?.length; i++) {
    if (questionnaire[i]?.answer === "" || questionnaire[i]?.answer === undefined || !questionnaire[i]?.answer || questionnaire[i]?.answer === "<p></p>") {
      missingAnswer = true;
      break;
    }
  }

  // Check selectedValues
  for (var i = 0; i < selectedValues.length; i++) {
    if (selectedValues[i].selected === "" || !selectedValues[i].selected || selectedValues[i].selected === undefined) {
      missingValueScore = true;
      break;
    }

    if (selectedValues[i].comment === "" || selectedValues[i].comment === "<p></p>" || !selectedValues[i].comment || selectedValues[i].comment === undefined) {
      missingValueComment = true;
      break;
    }
  }

  // If everything is missing, do not submit draft
  if ((!missingAnswer || !missingValueScore || !missingValueComment)) {
    // We submit the draft regardless of inputs being empty or not - to save the information
    submitDraft(true);
  }

  // If there is a missing answer, show a warning
  if (missingAnswer || missingValueScore || missingValueComment) {
    // console.log ("[performanceStrengths.js][validateDraft()] Missing answer at index " + missingAnswerIndex + " of type " + missingAnswerType);
    setToasterMessage({message: t('performance.strengths.missing.answer'), type: Configs.warningToaster});
    // alert (t('performance.strengths.missing.answer'));
    return false;
  }

}

async function validateManagerDraft () {
  // Review questionnaire and selectedValues to see if there is any answer missing
  var missingAnswer = false;
  var missingValueScore = false;
  var missingValueComment = false;

  // Check managerQuestionnaire
  for (var i = 0; i < managerQuestionnaire.length; i++) {
    if (managerQuestionnaire[i].answer === "" || managerQuestionnaire[i].answer === undefined || !managerQuestionnaire[i].answer || managerQuestionnaire[i].answer === "<p></p>") {
      missingAnswer = true;
      break;
    }
  }

  // Check managerSelectedValues
  for (var i = 0; i < managerSelectedValues.length; i++) {
    if (managerSelectedValues[i].selected === "" || !managerSelectedValues[i].selected || managerSelectedValues[i].selected === undefined) {
      missingValueScore = true;
      break;
    }

    if (managerSelectedValues[i].comment === "" || managerSelectedValues[i].comment === "<p></p>"|| !managerSelectedValues[i].comment ||managerSelectedValues[i].comment === undefined) {
      missingValueComment = true;
      break;
    }
  }

  // If nothing is missing submit draft and go to next steps
  if ((!missingAnswer && !missingValueScore && !missingValueComment)) {
      submitManagerDraft(true);
  }

  // If there is anything missing, show a warning and submit draft
  if (missingAnswer || missingValueScore || missingValueComment) {
    // console.log ("[performanceStrengths.js][validateDraft()] Missing answer at index " + missingAnswerIndex + " of type " + missingAnswerType);
    setToasterMessage({message: t('performance.strengths.missing.answer'), type: Configs.warningToaster})
    submitManagerDraft(false);
    // alert (t('performance.strengths.missing.answer'));
    return false;
  }

}


// ---------------------------------------------------------------------------------------------------
// Frontend management
// ---------------------------------------------------------------------------------------------------
async function answerUpdated (answer, type, index) {
  // Update state for AUTO-SAVE
  setEmployeeChanged(true);

    // Create the object to update
    var objectAnswer = {
        type: type,
        title: questionnaire[index].title,
        description: questionnaire[index].description,
        answer: answer
    }

    // Get an auxiliary array to update
    var auxQuestionnaire = [...questionnaire];
    auxQuestionnaire[index] = objectAnswer;

    // Set Questionnaire back to its state
    setQuestionnaire(auxQuestionnaire);

}

async function managerAnswerUpdated (answer, type, index) {
  // Update state for AUTO-SAVE
  setManagerChanged(true);
  
  // Create the object to update
  var objectAnswer = {
    type: type,
    title: managerQuestionnaire[index].title,
    description: managerQuestionnaire[index].description,
    answer: answer
}

  // Get an auxiliary array to update
  var auxQuestionnaire = [...managerQuestionnaire];
  auxQuestionnaire[index] = objectAnswer;

  // Set Questionnaire back to its state
  setManagerQuestionnaire(auxQuestionnaire);

}

async function getSelectedValues (valuesSelected, feedbackType) {
  // Update from Values widget
  if (Configs.devEnvironment) console.log ("[performanceStrengths.js][getSelectedValues()] - ", valuesSelected, feedbackType);
  if (feedbackType === "growth") {
      // setSelectedValues([...valuesSelected]);
  } else if (feedbackType === "strength") {
      // IC input
      setSelectedValues([...valuesSelected]);
  }
}

async function calculateValuesResult (inputGrades) { 
  // AUTO-SAVE
  setEmployeeChanged(true);

  if (performanceReviewCampaigns[0]?.valuesSelectorType === "score" ||performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
      // Calculate result for input
      var count = 0;
      var auxResult = 0;
      inputGrades?.forEach(element => {
          count += 1;
          auxResult = parseInt(auxResult) + parseInt(element?.selected);
      });

        setValuesResult(auxResult / count);
  }
}

async function getManagerSelectedValues (managerValuesSelectedAux, feedbackType) {
  // Update from Values widget
  if (Configs.devEnvironment) console.log ("[performanceStrengths.js][getSelectedValues()] - ", managerValuesSelectedAux, feedbackType);
  if (feedbackType === "growth") {
    // setSelectedValues([...managerValuesSelectedAux]);
  } else if (feedbackType === "strength") {
    // Manager portion
    setManagerSelectedValues([...managerValuesSelectedAux]);
  }
}

async function calculateManagerValuesResult (managerInputGrades) { 
  // AUTO-SAVE
  setManagerChanged(true);

  if (performanceReviewCampaigns[0]?.valuesSelectorType === "score" || performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") {
      // Calculate result for input
      var count = 0;
      var auxResult = 0;
      managerInputGrades?.forEach(element => {
          count += 1;
          auxResult = parseInt(auxResult) + parseInt(element?.selected);
      });
        setManagerValuesResult(auxResult / count);   
  }
}

// ---------------------------------------------------------------------------------------------------
  // Navigation methods
  // ---------------------------------------------------------------------------------------------------
  const nextStepManager = () => {
    if (selectedProfile) {
    // Navigate to the next screen
    navigate("/performance/team/employee/review/"+
    encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
        employeeID: selectedProfile?.employeeID,
        employee: selectedProfile?.employee,
        companyID: selectedProfile?.companyID,
        managerID: selectedProfile?.managerID
        }),Configs.privateKey).toString()));
      } 
  }

    const nextStepIC = () => {
      // Navigate to the next screen
      navigate("/performance/self/review");
    }


  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
  };

  // ---------------------------------------------------------------------------------------------------
  // Render methods
  // ---------------------------------------------------------------------------------------------------
  if (!performanceReviewCampaigns ||performanceReviewCampaigns === null || !performanceReviewCampaigns[0])  {
    
    <React.Fragment>
      <Header />
      <Bot />
        {t('error.404')}
    </React.Fragment>

  } else {
      return (
        <React.Fragment>
          <Header />
          <Bot />

          <div className="main">
                {params ? 
                  /* Manager */
                  <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen} params={params}/>
                :
                  /* IC */
                  <PerformanceHeader livePerformanceCampaign={livePerformanceCampaign} currentScreen={currentScreen} />          
                }

                {(isPerformanceReviewDraftLoading || isPerformanceReviewManagerDraftLoading) ?
                  <Loader />
                :
                  <React.Fragment>

              <div className='flex flex-row items-start text-left'>
                  <div className="left-big-view">
                  {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


                    <div className="flex flex-col ">
                      {params ? 
                          /* Manager - showing title of employee being assessed */
                          <React.Fragment>
                              <div className="flex flex-col">
                                  <UserListItem user={params?.employee} initials={params?.employee?.slice(0,1)} role={params?.role} level={params?.level}
                                  profilePicture={params?.profilePicture} />
                              </div>
                          </React.Fragment>
                        :   
                          /* IC */                
                          <React.Fragment>
                            <div className="flex flex-col items-start mt-2 md:ml-2 m-1">
                            <Tooltip content={t('performance.strengths.description')}>
                              <h2 className="section-title">
                                {" "}
                                {t('performance.strengths.title')}
                                </h2>
                            </Tooltip>
                          </div>
                          </React.Fragment>
                        }
                      </div>


                      {/* MANAGER PORTION */}
                        {params ? 
                          <React.Fragment>
                            <div className='subsection-title mt-3 border border-white border-b-gray-200 p-2 '>
                              {t('performance.manager-portion.title')}
                            </div>

                            <div className='flex flex-col mt-8 ml-2 m-1'>
                              <div className='flex flex-col'>
                                      {(performanceReviewCampaigns[0]?.valuesSelectorType === "score") ? 
                                          <ValuesScoring values={managerSelectedValues} selectedValues={getManagerSelectedValues} result={managerValuesResult} setValuesResult={calculateManagerValuesResult}
                                          readOnly={performanceReviewManagerDraft ? (performanceReviewManagerDraft[0]?.status === "submitted" ? true : false) : null} type="strength"/>
                                      :

                                      (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ? 
                                          <ValuesScoringWithComment values={managerSelectedValues} selectedValues={getManagerSelectedValues} result={managerValuesResult} setValuesResult={calculateManagerValuesResult}
                                          readOnly={performanceReviewManagerDraft ? (performanceReviewManagerDraft[0]?.status === "submitted" ? true : false) : null} type="strength"/>
                                      :
                                      (performanceReviewCampaigns[0]?.valuesSelectorType === "selector") ? 
                                          <ValuesSelector values={managerSelectedValues} selectedValues={getManagerSelectedValues} 
                                          readOnly={performanceReviewManagerDraft ? (performanceReviewManagerDraft[0]?.status === "submitted" ? true : false) : null} type="strength"/>
                                      :
                                      null
                                      }
                              </div>
                            </div>

                              {managerQuestionnaire?.map((element, index) => (
                                element.type === "strength" ? 
                                    <div className='flex flex-col mt-8 ml-2 m-1'>
                                      {/* Question & description */}
                                        <h3 className='flex text-l mb-4 font-bold'> {element.title}</h3>
                                        <span className='title-description'>{element.description}</span>
                                      
                                      {params && performanceReviewManagerDraft && (performanceReviewManagerDraft[0]?.status === "submitted" || performanceReviewManagerDraft[0]?.status === "completed") ? 
                                        // Non-editable Editor
                                        <div className='border-l-4 border-gray-200'>
                                          <NoneditableEditor content={element.answer}/>
                                        </div>
                                      : 
                                        <div className="flex flex-col items-start w-full input-field">
                                          <Editor setContent={managerAnswerUpdated} content={element.answer} initialContent={managerQuestionnaireAuxEditor[index]?.answer} placeholder={t('form.fill-details')}
                                          editorType={"performanceReviewFeedback"} type={element.type} index={index}/>
                                        </div>
                                      }
            
                                    </div>
            
                                : null
            
                              ))}

                              <SectionSeparator />

                            </React.Fragment>
                            :
                              /* IC - no manager input */ 
                              null
                        }

                        {/* EMPLOYEE PORTION */}
                          <div>
                            {/* For Manager: Employee portion section title */}
                            {params &&  
                              <div className='hidden subsection-title mt-3 border border-white border-b-gray-200 p-2 '>
                                {t('performance.employee-portion.title')}
                              </div>
                            } 

                          {!params && <div className='flex flex-col ml-2 m-1 mt-3'>
                                {!params ?
                                  // IC - use performanceReviewDraft
                                  (performanceReviewCampaigns[0]?.valuesSelectorType === "score") ? 
                                          <ValuesScoring values={selectedValues} selectedValues={getSelectedValues} result={valuesResult} setValuesResult={calculateValuesResult}
                                          readOnly={params ? true : performanceReviewDraft ? ((performanceReviewDraft[0]?.status === "submitted" || performanceReviewDraft[0]?.status === "completed" || performanceReviewDraft[0]?.status === "sent") ? true : false) : null} type="strength"/>
                                    :

                                    (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ? 
                                          <ValuesScoringWithComment values={selectedValues} selectedValues={getSelectedValues} result={valuesResult} setValuesResult={calculateValuesResult}
                                          readOnly={params ? true : performanceReviewDraft ? ((performanceReviewDraft[0]?.status === "submitted" || performanceReviewDraft[0]?.status === "completed" || performanceReviewDraft[0]?.status === "sent") ? true : false) : null} type="strength"/>
                                    :
                                    (performanceReviewCampaigns[0]?.valuesSelectorType === "selector") ? 
                                          <ValuesSelector values={selectedValues} selectedValues={getSelectedValues} 
                                          readOnly={params ? true : performanceReviewDraft ? ( (performanceReviewDraft[0]?.status === "submitted" || performanceReviewDraft[0]?.status === "completed" || performanceReviewDraft[0]?.status === "sent") ? true : false) : null} type="strength"/>
                                    :
                                    null
                                :
                                // Manager and employee-draft is sent/submitted - use performanceReviewDraft
                                (params && (performanceReviewDraft && (performanceReviewDraft?.length > 0))
                                && (performanceReviewDraft[0]?.status === "submitted" ||  performanceReviewDraft[0]?.status === "sent" )) ?  

                                    (performanceReviewCampaigns[0]?.valuesSelectorType === "score") ? 
                                          <ValuesScoring values={selectedValues} selectedValues={getSelectedValues} result={valuesResult} setValuesResult={calculateValuesResult}
                                          readOnly={params ? true : performanceReviewDraft ? ((performanceReviewDraft[0]?.status === "submitted" || performanceReviewDraft[0]?.status === "completed") ? true : false) : null} type="strength"/>
                                    :
                                    (performanceReviewCampaigns[0]?.valuesSelectorType === "scoreWithComments") ? 
                                          <ValuesScoringWithComment values={selectedValues} selectedValues={getSelectedValues} result={valuesResult} setValuesResult={calculateValuesResult}
                                          readOnly={params ? true : performanceReviewDraft ? ((performanceReviewDraft[0]?.status === "submitted" || performanceReviewDraft[0]?.status === "completed") ? true : false) : null} type="strength"/>
                                    :
                                    (performanceReviewCampaigns[0]?.valuesSelectorType === "selector") ? 
                                          <ValuesSelector values={selectedValues} selectedValues={getSelectedValues} 
                                          readOnly={params ? true : performanceReviewDraft ? ( (performanceReviewDraft[0]?.status === "submitted" || performanceReviewDraft[0]?.status === "completed") ? true : false) : null} type="strength"/>
                                    :
                                  null             
                                : 
                                  // non-editable Editor
                                  <div className='border-l-4 border-gray-200'>
                                    <NoneditableEditor content={t('performance.review.missing-employee-portion')}/>
                                  </div>
                                }                
                          </div>}

                            {!params && questionnaire && questionnaire?.length > 0 && 
                                <div className='flex flex-col space-y-4 mb-8'>
                                      {questionnaire?.map((element, index) => (
                                        // Only show content if there is an answer
                                        element.type === "strength" ? 
                                               
                                          <div className='flex flex-col space-y-4 mb-8'>
                                            <div className='flex flex-col space-y-1'>
                                              <h3 className='flex text-l font-bold'> {element.title} </h3>
                                              <span className='title-description'>{element.description}</span>
                                            </div>


                                            {params 
                                            || (performanceReviewDraft && (performanceReviewDraft[0]?.status === "submitted" || (performanceReviewDraft[0]?.status === "completed") || (performanceReviewDraft[0]?.status === "sent"))) ? 
                                              // non-editable Editor for Manager
                                              <div className='border-l-4 border-gray-200'>
                                                <NoneditableEditor content={(element.answer && (performanceReviewDraft[0]?.status === "submitted" ||performanceReviewDraft[0]?.status === "completed")) ? element.answer : t('performance.review.missing-employee-portion')}/>
                                              </div>
                                            : 
                                              <div className="flex flex-col items-start w-full input-field">
                                                <Editor setContent={answerUpdated} content={element.answer} initialContent={questionnaireAuxEditor[index]?.answer}  placeholder={t('form.fill-details')} 
                                                editorType={"performanceReviewFeedback"} type={element.type} index={index}/>
                                              </div>
                                            }

                                          </div>

                                        : null

                                    ))}

                                </div>
                            }
                            
                            </div>


                        

                        <div className="flex flex-row justify-center lg:justify-end w-auto mb-12">
                        
                          {params ?                                    
                                  <button className="save-button" onClick={validateManagerDraft}>
                                    {t('continue')}
                                  </button>
                          :
                              // <Link to="/performance/self/growth">
                                <button className="save-button" onClick={validateDraft}> 
                                    {t('continue')}
                                </button>
                          }
                        </div>  

                        <SectionSeparator />                         
                        
                      </div>
                      <div className="right-small-view">
                        <PerformanceHistory companyIDinput={companyID} employeeIDinput={params ? params?.employeeID : employeeID}/>
                      </div>
                    </div>
                    </React.Fragment>
                  }
            </div>
          </React.Fragment>
        );
  }
}

export default PerformanceStrengths;