// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Styles
import './index.css';
import App from './App';
import Configs from './Configs';

// Metrics
import reportWebVitals from './reportWebVitals';

// Amplitude imports
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

// i18n imports
import {I18nextProvider} from "react-i18next";
import i18n from './i18n';

// Token storage
import { AuthProvider } from './common/appContext';
import { DataProvider } from './common/dataContext';

// Azure integration imports
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

// Amplitude init - only in production
if (!Configs.devEnvironment) {
  // Standard init
  amplitude.init(Configs.amplitudeAPIKey);
  // Session replay init
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 0.50, // This configuration samples 50% of all sessions
  });
  amplitude.add(sessionReplayTracking);
}

// --------------------------------------------------------------------------------
// Error handling
// --------------------------------------------------------------------------------
// Ignore ResizeObserver loop error
const resizeObserverLoopErrHandler = () => {
  const resizeObserverErrDiv = document.createElement('div');
  resizeObserverErrDiv.style.display = 'none';
  resizeObserverErrDiv.id = 'resize-observer-error-handler';
  document.body.appendChild(resizeObserverErrDiv);
  
  const observer = new ResizeObserver(() => {
    const errorHandler = document.getElementById('resize-observer-error-handler');
    if (errorHandler) {
      errorHandler.style.display = 'block';
      setTimeout(() => {
        errorHandler.style.display = 'none';
      }, 1);
    }
  });
  
  observer.observe(document.body);
};

resizeObserverLoopErrHandler();


// --------------------------------------------------------------------------------
// Render
// --------------------------------------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <I18nextProvider i18n={i18n}>
          <AuthProvider>
              <DataProvider>
                <App />
              </DataProvider>
          </AuthProvider>
      </I18nextProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
