// React
import React, {useState, useEffect} from 'react';
import {Link, useLocation, useParams} from "react-router-dom";

// Common and widgets
import Header from '../../common/header';
import SuperAdminHeader from './superAdminHeader';
import Configs from "../../Configs";
import Bot from '../../common/support/bot'; 
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import DataTable from '../../widgets/dataTable';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import DataGrid from 'react-data-grid';
import CustomFormatters from '../../widgets/data/customFormatters'; // Import the custom formatters class

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';

import { BsStars } from 'react-icons/bs';

// ---------------------------------------------------------------------------------------------------
function CompanyDetail () {
  const {t, i18n} = useTranslation();
  const location = useLocation();

  const {token, employeeID, companyID, securityLevel} = useAuth();
  const {toasterMessage, setToasterMessage} = useData();
  const {CCOaaSReport, isCCOaaSReportLoading} = useData();
  const {CCOaaScompanyDetailsAIAnalysis, isCCOaaSAICompanyDetailsAnalysisLoading} = useData();
  const {selectedCompany} = useData();

  const {id} = useParams();

  const [companyReport, setCompanyReport] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [actionItems, setActionItems] = useState(null);

    // DataGrid
    let companyReportColumnsGrid = [
      { key: 'kpiBase', name: t('kpi'), editable: false, frozen: true },
      // // Goal
      // { key: 'week', name: t('goal'), editable: false, headerCellClass: 'custom-header-style' },
      // { key: 'week', name: t('vs-goal'), editable: false, headerCellClass: 'custom-header-style' },
      // // Empty column
      // { key: 'empty', name: '', width: 10, editable: false, headerCellClass: 'empty-header-style' },
      // Weekly - includes 5 weeks back and current, and WoW
      { key: 'week5', name: t('week'), editable: false }, // Pending to extract current week and the previous 5 weeks number
      { key: 'week4', name: t('week'), editable: false },
      { key: 'week3', name: t('week'), editable: false },
      { key: 'week2', name: t('week'), editable: false },
      { key: 'Week', name: t('admin.ccoaas.metrics.last-week'), editable: false, headerCellClass: 'custom-header-style' },
      { key: 'WoW', name: t('admin.ccoaas.metrics.wow'), editable: false, headerCellClass: 'custom-header-style', formatter: CustomFormatters.WoWPercentageChangeFormatter},
      // Empty column
      { key: 'empty', name: '', width: 10, editable: false, headerCellClass: 'empty-header-style' },
      // Monthly - 12 months back
      {key: 'month12', name: t('month'), editable: false},
      {key: 'month11', name: t('month'), editable: false},
      {key: 'month10', name: t('month'), editable: false},
      {key: 'month9', name: t('month'), editable: false},
      {key: 'month8', name: t('month'), editable: false},
      {key: 'month7', name: t('month'), editable: false},
      {key: 'month6', name: t('month'), editable: false},
      {key: 'month5', name: t('month'), editable: false},
      {key: 'month4', name: t('month'), editable: false},
      {key: 'month3', name: t('month'), editable: false},
      {key: 'month2', name: t('month'), editable: false},
      { key: 'LastMonth', name: t('admin.ccoaas.metrics.max-month'), editable: false, headerCellClass: 'custom-header-style'},
      // { key: 'MTD', name: t('admin.ccoaas.metrics.max-month'), editable: false, headerCellClass: 'custom-header-style'}, // MTD
      { key: 'MoM', name: t('admin.ccoaas.metrics.mom'), headerCellClass: 'custom-header-style', formatter: CustomFormatters.MoMPercentageChangeFormatter },
      // Empty column
      { key: 'empty', name: '', width: 10, editable: false, headerCellClass: 'empty-header-style' },
      // QTD, T13W
      { key: 'qtd', name: t('qtd'), editable: false },
      { key: 't13w', name: t('t13w'), editable: false },
      // Empty column
      { key: 'empty', name: '', width: 10, editable: false, headerCellClass: 'empty-header-style' },
      // YTD, T12M
      { key: 'ytd', name: t('ytd'), editable: false },
      { key: 't12m', name: t('t12m'), editable: false },
    ];

  if (Configs.devEnvironment) console.log('[companyDetail.js][main()] CompanyDetail - id:', id, selectedCompany);
  if (Configs.devEnvironment) console.log('[companyDetail.js][main()] CompanyDetail - CCOaaScompanyDetailsAIAnalysis:', CCOaaScompanyDetailsAIAnalysis);


  // ---------------------------------------------------------------------------------------------------
  // useEffects
  // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      // Get information to populate dashboard
      if (CCOaaSReport) transformCCOaaSReportForCompany ();
      if (CCOaaScompanyDetailsAIAnalysis) transformCCOaaSDetailedAnalysis ();

    }, [CCOaaSReport, CCOaaScompanyDetailsAIAnalysis, location?.pathname]);

  
// ---------------------------------------------------------------------------------------------------
// Data management
// ---------------------------------------------------------------------------------------------------
function transformCCOaaSReportForCompany () {
  if (Configs.devEnvironment) console.log('[companyDetail.js][transformCCOaaSReportForCompany()] CCOaaSReport:', CCOaaSReport);


  // Transform body to add a doc field
  let transformedBody = [];

  // 1. Filter CCOaaSReport by companyID (id)
  let companyData = CCOaaSReport?.filter(item => parseInt(item?.companyID) === parseInt(id))[0];
  if (Configs.devEnvironment) console.log('[companyDetail.js][transformCCOaaSReportForCompany()] CCOaaSReport:', companyData);

    // Transform 
    const kpiGroups = {};

    // Group KPIs based on the prefix
    Object.keys(companyData).forEach(key => {
      const match = key.match(/^(.*?)(LastMonth|LastWeek|MoM|WoW|Month|Week)$/);
      if (match) {
        const [, kpiBase, timeSpan] = match;
        if (!kpiGroups[kpiBase]) {
          kpiGroups[kpiBase] = { kpiBase };
        }
        kpiGroups[kpiBase][timeSpan] = companyData[key];
      }
    });

    // Convert the grouped KPIs into an array
    transformedBody = Object.values(kpiGroups);
    // Last. Update state
    setCompanyReport(transformedBody);

    if (Configs.devEnvironment) console.log('[companyDetail.js][transformCCOaaSReportForCompany()] CCOaaSReport:', transformedBody);

}

function transformCCOaaSDetailedAnalysis () {
  if (Configs.devEnvironment) console.log('[companyDetail.js][transformCCOaaSDetailedAnalysis()] CCOaaScompanyDetailsAIAnalysis:', CCOaaScompanyDetailsAIAnalysis);

  var assessmentAux = CCOaaScompanyDetailsAIAnalysis?.assessment;
  var actionItemsAux = CCOaaScompanyDetailsAIAnalysis?.actionItems;

  setAssessment(assessmentAux);
  setActionItems(actionItemsAux);
  
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
  
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
          <SuperAdminHeader />

          <div className='flex flex-col space-y-4 w-full p-2 lg:px-8 lg:max-w-screen-xl'>

              {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


              <div className="flex flex-col text-left space-y-4">
                <h2 className='section-title'>{CCOaaSReport?.find(item => parseInt(item?.companyID) === parseInt(id))?.companyName }</h2>

                { isCCOaaSReportLoading ?
                    <Loader />
                  :
                    companyReport ? 
                        <div className='flex flex-col'>
                            <DataGrid 
                                className='rounded-lg shadow-md'
                                columns={companyReportColumnsGrid}
                                rows={companyReport}
                                rowGetter={i => companyReport[i]}
                                rowsCount={companyReport?.length}
                                // onRowsChange={onRowsChange}
                                enableCellSelect={false}
                                />
                        </div>
                        :
                        null
                }

              </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default CompanyDetail;