// React 
import React, { useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Common
import Header from '../../common/header';
import OrganigramHeader from './organigramHeader';
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Organizational chart
import { Tree, TreeNode } from "react-organizational-chart";

// Icons
import { IoPersonAdd } from 'react-icons/io5';
import {GiOrganigram} from 'react-icons/gi';

// ---------------------------------------------------------------------------------------------------
function Organigram () {

    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const {employeeID, securityLevel} = useAuth();
    const {setSelectedProfile, toasterMessage, setToasterMessage, companyOrg, companyPreferences} = useData();
    const {organigramData, isOrganigramDataLoading, getOrganigramData} = useData();

    const navigate = useNavigate();

    var currentOrganigram = sessionStorage.getItem('organigram') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('organigram'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null ;
    var [organigram, setOrganigram] = useState(currentOrganigram);

    // Kebab menu
    // const [isKebabOpen, setIsKebabOpen] = useState(false);

    if (Configs.devEnvironment) console.log ("[organigramHome.js][getOrgranigam()] Organigram = ", organigram);
    if (Configs.devEnvironment) console.log("[organigramHome.js][getOrgranigam()] DEV ONLY log: - securityLevel:", securityLevel);

    // ---------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        if (organigramData === null) {
            getOrganigramData(); 
        } else {
            setOrganigram(organigramData);
        }

      }, [organigramData]);      

 
// ---------------------------------------------------------------------------------------------------
// Navigation
// ---------------------------------------------------------------------------------------------------
async function goToProfile (employeeIDaux) {

    if (employeeIDaux === employeeID) {
        setSelectedProfile(null);
        navigate("../profile/");
    } else {
        const employee = companyOrg.find( (element) => element.employeeID === employeeIDaux);
        setSelectedProfile(employee);
        navigate("../profile/");
    }
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
    };

    
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
const renderTreeNodes = (nodes) =>
nodes.map((node) => (
    <TreeNode key={node.employeeID} label={
                    <div className="flex flex-col cursor-pointer" onClick={() => goToProfile(node.employeeID)}>
                        {/* // <Link className="flex flex-col cursor-pointer"  */}
                        {/* // to={"../profile/" + encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({ */}
                        {/* //                         employeeID: node?.employeeID,
                        //                         companyID: node?.companyID,
                        //                     }),Configs.privateKey).toString())} > */}
                            <div className='flex flex-col items-center text-center p-2 bg-gray-100 shadow relative hover:bg-gray-200'>    
                                {/* Kebab menu with employee options */}
                                {/* <div className='flex flex-row items-start justify-end w-full '>
                                    <div className='flex flex-col items-end cursor-pointer hover:text-gray-400 hover:animate-pulse font-bold text-lg absolute mx-3 my-2 top-0 right-0' onClick={() => setIsKebabOpen(!isKebabOpen)}>
                                        &#8942;
                                    </div> */}

                                    {/* Kebab menu */}
                                    {/* {isKebabOpen && (
                                        <div className="flex flex-col absolute my-2 mx-2 bg-white border broder-gray-200 rounded shadow text-xs">
                                            <div className='border-b border-gray-200 py-1 px-3 hover:bg-gray-100 cursor-pointer'>Edit employee</div>
                                            <div className='border-b border-gray-200 py-1 px-3 hover:bg-gray-100 cursor-pointer'>Change manager</div>
                                            <div className='border-b border-gray-200 py-1 px-3 hover:bg-gray-100 cursor-pointer'>View profile</div>
                                        </div>
                                    )}     */}
                                {/* </div>  */}
                                
                                {/* Employee info */}
                                

                                    <div className='flex flex-col text-xs'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            {/* Profile picture */}
                                            {node?.profilePicture ?
                                                <img className='w-8 h-8 rounded-full mb-2' src={organigram[0]?.profilePicture} alt={organigram[0]?.employeeName + " profile picture"}/>
                                            :
                                                null
                                            }
                                            {/* Employee name */}
                                            <span className='font-medium mb-1'>{node?.employeeName} {(node?.employeeSurname && node?.employeeSurname !== undefined && node?.employeeSurname !== null) ? node?.employeeSurname : null}</span>
                                        </div>
                                        
                                        <div className='flex flex-col items-center text-xs text-gray-600 '>
                                            {/* Title & Role */}
                                            <div className='flex '>
                                                {node?.role ? node?.role : null}
                                                {(node?.role && node?.title && node?.role !== node?.title) ? "  -  " : null}
                                                {(node?.title && node?.title !== node?.role) ? ("   ||   ")  + node?.title : null }
                                            </div>
                                            {/* Area & Subarea */}
                                            <div className='flex hidden'>
                                            {node?.area ? node?.area : null}
                                                {(node?.area && node?.subarea && node?.area !== node?.subarea) ? "  -  " : null}
                                                {(node?.subarea && node?.area !== node?.subarea) ? node?.subarea : null } 
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        {/* // </Link> */}
                        </div>
        }>

        {node.children.length > 0 ? 
            renderTreeNodes(node.children)
            :
            null
        }
    </TreeNode>
));

return (
      <React.Fragment>
      <Header />

      <Bot/>

      <div className="flex flex-col lg:ml-[215px]">

        <OrganigramHeader />

        <div className="flex flex-col m-2 lg:m-4">


            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

            <div className="flex flex-col  text-left ">
                    <div className="flex flex-col mt-2 md:ml-2 m-1">
                        <h2 className="flex text-xl text-left mb-4 ">
                            {" "}
                            <div className='flex flex-col md:flex-row justify-left'>
                                <Tooltip content={t('organigram.home.description')}>
                                    <div id="title" className='section-title' >
                                        {t('organigram.home.title')}
                                    </div>
                                </Tooltip>
                            </div>
                        </h2>
                    </div>

                    {/* Employee actions - Create new employee, or act in bulk */}
                    { securityLevel?.includes(Configs.securityLevelHR) ?
                            <React.Fragment>
                            <div className="flex flex-row justify-center lg:justify-end m-4 space-x-4">
                                {/* Create employee */}
                                <Link id="createEmployeeButton" to="/organigram/employees/create">
                                    <button id="createEmployeeButton" className='save-button flex flex-row items-center'>
                                      <IoPersonAdd className='mr-2' />
                                            {t('organigram.list.create-single-employee')}
                                    </button>
                                </Link>

                                {/* Bulk actions - Re-org */}
                                <Link to="/organigram/employees/reorganize">
                                    <button className='save-button flex flex-row items-center'>
                                      <GiOrganigram className='mr-2' />
                                            {t('organigram.list.re-organize')}
                                    </button>
                                </Link>

                            </div>
                            </React.Fragment>

                        : 
                            null
                    }


                {isOrganigramDataLoading ? 

                    <Loader/>
                :
                
                    organigram?.length > 0 && organigram ? 
                        <div className=' overflow-x-auto overflow-y-auto bg-white p-2 py-8 rounded-lg shadow-xl'>
                            <Tree 
                            label={ organigram[0]?.employeeID === 0 ?

                                <div>
                                    <div className='flex flex-col items-center text-center p-2 '>
                                        <h2 className='font-bold text-xl'>
                                                {companyInfo?.companyName}
                                        </h2>
                                    </div>
                                </div>

                                :
                            
                                <div className='flex flex-col items-center text-center p-4 w-auto bg-gray-100 shadow '
                                    id="rootCard">
                                    <div className="flex flex-col cursor-pointer" onClick={() => goToProfile(organigram[0]?.employeeID)}>
                                    {/* to={"../profile/" + 
                                    //                     encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                                    //                         employeeID: organigram[0]?.employeeID,
                                    //                         companyID: organigram[0]?.companyID,
                                    //                     }),Configs.privateKey).toString())}
                                    //                     >*/}
                                        <div className='flex flex-col items-center'>
                                            {organigram[0]?.profilePicture ?
                                                <img className='w-12 h-12 rounded-full mb-2' src={organigram[0]?.profilePicture} alt={organigram[0]?.employeeName + " profile picture"}/>
                                            :
                                                <div className='px-2 py-2 m-2 bg-gray-500 rounded'>
                                                    {organigram[0]?.employeeName?.slice(0,1) + (organigram[0]?.employeeSurname !== undefined ? organigram[0]?.employeeSurname?.slice(0,1) : "")}
                                                </div>
                                            }
                                        </div>
                                        <div className='flex flex-col'>
                                        <h2 className='font-bold text-xl'>{organigram[0]?.employeeName}{" "}{organigram[0]?.employeeSurname}</h2>
                                            <div className='flex flex-col items-center text-xs text-gray-600 '>
                                                <div className='flex '>
                                                    {organigram[0]?.role ? organigram[0]?.role : null}  
                                                    {(organigram[0]?.title &&  organigram[0]?.title !== organigram[0]?.role) ? organigram[0]?.title : null}
                                                </div>
                                                <div className='flex'>
                                                    {organigram[0]?.area ? organigram[0]?.area : null}
                                                    {(organigram[0]?.area && organigram[0]?.subarea) ? "  -  " : null}
                                                    {organigram[0]?.subarea}
                                                </div>
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                    </div>
                                </div>
                            }
                            lineWidth={'4px'}
                            lineColor={companyPreferences?.personalization?.emailsHeaderColor ? companyPreferences?.personalization?.emailsHeaderColor : Configs.companyColor}>
                                {renderTreeNodes(organigram[0]?.children)}
                            </Tree>
                        </div>
                    :
                        null
                    
                }
            </div>
            <SectionSeparator />
        </div>
        </div>
      </React.Fragment>
    );
}

export default Organigram;