// React
import React, {useState, useEffect} from "react";

// 3P
import { useTranslation } from 'react-i18next';
import { XYPlot , ArcSeries, LabelSeries, HorizontalGridLines, VerticalGridLines} from "react-vis";
import '../../node_modules/react-vis/dist/style.css';

// Common and widgets
import Configs from '../Configs';
import Tooltip from "../common/support/tooltip";

// Icons
import {AiOutlineInfoCircle} from 'react-icons/ai';

// --------------------------------------------------------------------------------------------------
/**
 * 
 * @param {*} props
 * Input: 'values' array with Objects: {value: "", description:""} 
 * Output: SelectedValues array with Objects: {value: "", description:"", selected: "", grade:""}
 * @returns 
 */
function KeyIndicatorItemStatic(props) {
// Values that will be used 
// console.log(props)
// Translation object
const {t, i18n} = useTranslation();

// Pi number
const PI = Math.PI;

// Props
// const [collapsed, setCollapsed] = useState (props.collapsed);
const indicator = props?.indicator;
const data = props?.data;
const hint = props?.hint;
const type = props?.type;
const benchmark = props?.benchmark;
const graph = props?.graph;

// console.log(question);


const [showHint, setShowHint] = useState(false);

// Line colors
const scoreColor = "#8ED3BF";
const maxColor = "#F7D0BB";
const benchmarkColor = "#BBF7D0";

if (Configs.devEnvironment) console.log("[keyIndicatorItem.js][main()] Props: ", props);


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate graphs
        // if (data) graphDataFromArray(data);
}, [data]);

// ------------------------------------------------------------------------------------------
// Frontend support methods
// ------------------------------------------------------------------------------------------

  

// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    
    <div className='flex flex-col items-center border border-gray-100 p-8 m-2 justify-between bg-white shadow-lg rounded-lg'> 
          {/* KPI title */}
          <div className="flex flex-col items-center justify-between space-y-2">
                  <h3 className="flex text-center items-center">
                        <span className="font-medium">{indicator}</span>

                        <div className='ml-2 text-sm hover:cursor-pointer'>
                            <Tooltip content={hint}>
                                <AiOutlineInfoCircle />
                            </Tooltip>
                        </div>
                </h3>

                <div className="flex flex-col items-center">
                    {graph === Configs.KPIGraphAccelerator ? 
                    <React.Fragment>
                        <div className="flex flex-row items-end justify-center -m-8 overflow-hidden">
                            <XYPlot xDomain={[-4, 4]} yDomain={[0, 4]} width={200} height={200} style={{marginTop:-40, marginBottom:-60, margin:-12, padding: 0,}}>
                                {/* Value */}
                                <ArcSeries
                                animation
                                radiusDomain={[0, 4]}
                                center={{x: 0, y: 0}}
                                data={[
                                    {angle0: -Math.PI/2, angle: data < 50 ? (-Math.PI/2 + (Math.PI/2)*((data*2)/100)) : Math.PI/2*(((data-50)*2)/100), opacity: 1, radius: 4, radius0: 3},
                                ]}
                                style={{
                                    stroke: scoreColor,
                                    margin:0,
                                }}
                                />
                                {/* Full length */}
                                <ArcSeries
                                animation
                                radiusDomain={[0, 4]}
                                center={{x: 0, y: 0}}
                                data={[
                                    {angle0: -Math.PI/2, angle: Math.PI/2, opacity: 0.5, radius: 4, radius0: 3},
                                ]}
                                style={{
                                    stroke: maxColor,
                                    margin:0,
                                }}
                                />
                                <LabelSeries allowOffsetToBeReversed animated
                                    data={[{
                                            x: Math.PI/2+0.5,
                                            y: 0,
                                            label: (data === 100 || data === 1.0e+2) ? "100%" : (data + "%"),
                                            style: {fontSize: 32, fontWeight: "bold", fill: "000000"}
                                        }, {
                                            x: 4,
                                            y: -0.3,
                                            label: "100%",
                                            style: {fontSize: 8, fontWeight: "light", fill: "000000", opacity: 0.5}
                                        }, {
                                            x: -4,
                                            y: -0.3,
                                            label: "0%",
                                            style: {fontSize: 8, fontWeight: "light", fill: "000000", opacity: 0.5}
                                        }]} 
                                    getLabel={d => d.label}
                                    style={{margin:0, padding: 0,}}
                                />
                            </XYPlot>
                        </div>

                            {benchmark && benchmark !== "" && benchmark > 0 ?
                            <div className="flex flex-row items-cener justify-center">
                                {type === "Percentage" ?
                                <div className="">
                                    <span className='text-sm text-gray-600'>{t('benchmark') + ": "} {!isNaN(Number(benchmark)) ? (Number(benchmark).toFixed(1) + "%") : " - "}</span>
                                </div>
                                :
                                <div className="">
                                    <span className='text-sm text-gray-600'>{t('benchmark') + ": "} {!isNaN(Number(benchmark)) ? Number(benchmark).toFixed(1) : " - "}</span>
                                </div>
                                }     
                            </div>
                            :
                            null}

                    </React.Fragment>
                    :
                        <div className="flex flex-col items-center">
                            {type === "Percentage" ?
                            <div className="flex flex-row justify-center">
                                <span className='text-4xl font-bold'>{!isNaN(Number(data)) ? (Number(data).toFixed(1) + "%") : " - "}</span>
                            </div>
                            :
                            <div className="flex flex-row justify-center">
                                <span className='text-4xl font-bold'>{!isNaN(Number(data)) ? Number(data).toFixed(1) : " - "}</span>
                            </div>
                            }   

                            {benchmark && benchmark !== "" && benchmark > 0 ?
                            <div className="flex flex-row items-end justify-center">
                                {type === "Percentage" ?
                                <div className="">
                                    <span className='text-sm text-gray-600'>{t('benchmark') + ": "} {!isNaN(Number(benchmark)) ? (Number(benchmark).toFixed(1) + "%") : " - "}</span>
                                </div>
                                :
                                <div className="">
                                    <span className='text-sm text-gray-600'>{t('benchmark') + ": "} {!isNaN(Number(benchmark)) ? Number(benchmark).toFixed(1) : " - "}</span>
                                </div>
                                }     
                            </div>
                        : null}  
                        </div>
                    }

                </div>
                  
          </div>

    </div>

  );
}

export default KeyIndicatorItemStatic;

