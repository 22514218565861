import React, {useLocation, useEffect} from 'react';
import Header from '../../common/header';
import { useState } from 'react';
import RecruitmentHeader from './recruitmentHeader';

// import Footer from '../common/footer';
import {Link} from "react-router-dom";

function RecruitmentHome () {

    

    return (
      <React.Fragment>
        <Header />

        <div className="main">
        <RecruitmentHeader />

        <div className='flex flex-row items-start'>
          <div className="left-big-view">

          </div>

        <div className='right-small-view'>
          
          </div>
        </div>

        </div>

        
      </React.Fragment>
    );
}

export default RecruitmentHome;