// React
import React, {useState, useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import PulseHeader from './pulseHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import { FaEdit, FaTrash } from 'react-icons/fa';

function PulseCampaigns () {
    const location = useLocation();
    const passedMessage = location.state?.toasterMessage;

    var currentCampaigns = sessionStorage.getItem('pulseCampaigns') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('pulseCampaigns'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    var [pulseCampaigns, setPulseCampaigns] = useState(currentCampaigns);

    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    const {token, companyID, employeeID} = useAuth();
    const securityLevel = sessionStorage.getItem('securityLevel') ? CryptoJS.AES.decrypt(sessionStorage.getItem('securityLevel'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;

    // Toaster
    const [toasterMessage, setToasterMessage] = useState(null);
    const [areCampaignsLoading, setAreCampaignsLoading] = useState(false);

    if (Configs.devEnvironment) console.log ("[pulseCampaigns.js][main()] DEV ONLY - Campaigns = ", pulseCampaigns);
    if (Configs.devEnvironment) console.log ("[pulseCampaigns.js][main()] DEV ONLY - Company Info = ", companyInfo);

    if (Configs.devEnvironment) console.log (location.state);

    // ------------------------------------------------------
    // Methods to load when page is loaded
    // ------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        // if (pulseCampaigns === null) getCampaigns();
        getCampaigns();

        if (passedMessage) {
            setToasterMessage(passedMessage);
          }
      }, [passedMessage]);


    // ------------------------------------------------------
    // API calls
    // ------------------------------------------------------
    async function getCampaigns () {
        // Start loader
        setAreCampaignsLoading(true);

        // fetch from server      
        fetch(Configs.platformGetPulseCampaignsPerCompany + "?employeeID=" + employeeID + "&companyID=" + companyID, {
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}` // notice the Bearer before your token
          }})
            .then((response) => {
                // We received a response, stop loading
                setAreCampaignsLoading(false);

              if (response.status === 200) {
                // We have a match
                response.json()
                .then ( body => {
                    if (Configs.devEnvironment) console.log("[pulseCampaigns.js][getCampaigns()]  Campaigns from getCampaigns(): ", body);
                    setPulseCampaigns(body);
                    sessionStorage.setItem("pulseCampaigns", CryptoJS.AES.encrypt(JSON.stringify(body), Configs.privateKey).toString());
                    // setToasterMessage({message:(t('success')), type: Configs.successToaster});
                  })
                .catch(error => {
                    console.error("[pulseCampaigns.js][getCampaigns()] Error parsing response JSON", error);
                    setToasterMessage({message: t('error'), type: Configs.errorToaster});
                    setPulseCampaigns(null);
                    sessionStorage.clear("pulseCampaigns");
                });
                } else if (response.status === 400) {
                // Something went wrong - inform user
                setPulseCampaigns(null);
                setToasterMessage({message: t('warning'), type: Configs.warningToaster});
                sessionStorage.clear("pulseCampaigns");
              } else if (response.status === 401) {
                  // Force logout
                  setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                  sessionStorage.clear("pulseCampaigns");
                  setPulseCampaigns(null);
                    sessionStorage.clear();
                    window.location.reload();
              } else if (response.status === 403) {
                    // Force logout
                    setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                    sessionStorage.clear("pulseCampaigns");
                    setPulseCampaigns(null);
                    sessionStorage.clear();
                    window.location.reload();
              } else if (response.status === 404) {
                    setPulseCampaigns(null);
                    setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
                    sessionStorage.clear("pulseCampaigns");
              } else {
                //
                setToasterMessage({message: t('error'), type: Configs.errorToaster});
                setPulseCampaigns(null);
                throw response;
              }
            })
      
            .catch((error) => {
                console.error("[pulseCampaigns.js][getCampaigns()] Error fetching data: ", error, " - function: GetCampaigns");
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
                // We received a response, stop loading
                setAreCampaignsLoading(false);
            });
    }

    async function deleteCampaign (e, campaignID) {
        e.preventDefault();

        // Confirmation window
        if (!window.confirm(t('pulse.campaigns.delete.confirm'))) {
            // Stay
            if (Configs.devEnvironment) console.log ("[pulseCampaigns.js][deleteCampaign()] cancel");
        } else {
            // I'm sure, proceed
            if (Configs.devEnvironment) console.log("[pulseCampaigns.js][deleteCampaign()] campaignID to delete: ", campaignID);

            // Start loader
            setAreCampaignsLoading(true);

            // fetch from server
            fetch(Configs.platformDeletePulseCampaignAPI + "?employeeID=" + employeeID + "&companyID=" + companyID + "&campaignID=" + campaignID, {
                // method: 'delete',
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${token}` // notice the Bearer before your token
                }})
                .then((response) => {
                    // We received a response, stop loading
                    setAreCampaignsLoading(false);

                if (response.status === 200) {
                        if (Configs.devEnvironment) console.log("[pulseCampaigns.js][deleteCampaign()]  Campaign deleted successfully");
                        getCampaigns();
                        setToasterMessage({message:(t('success')), type: Configs.successToaster});
                } else if (response.status === 400) {
                    // Something went wrong - inform user
                    setToasterMessage({message: t('warning'), type: Configs.warningToaster});
                } else if (response.status === 401) {
                    // Force logout
                    setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                        sessionStorage.clear();
                        window.location.reload();
                } else if (response.status === 403) {
                        // Force logout
                        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                        sessionStorage.clear();
                        window.location.reload();
                } else if (response.status === 404) {
                        setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
                } else {
                    //
                    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
                    setPulseCampaigns(null);
                    throw response;
                }
                })
        
                .catch((error) => {
                    console.error("[pulseCampaigns.js][deleteCampaign()] Error fetching data: ", error);
                    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
                    // We received a response, stop loading
                    setAreCampaignsLoading(false);
                });
        }

    }
    
// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
        };
      
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
        <PulseHeader />
            
        <div className="left-big-view">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


            <div className="flex flex-col text-left ">

            <div className="flex flex-col mt-2 md:ml-2 m-1">
                    <div className='flex flex-col'>
                        <h2 className="flex text-xl mb-4 ">
                        <div className='flex flex-col md:flex-row justify-left'>
                            <Tooltip content={t('pulse.campaigns.description')}>
                                <div className='section-title'>
                                    {t('pulse.campaigns.title')}
                                </div>
                            </Tooltip>
                        </div>
                        </h2>
                    </div>

                    {securityLevel?.includes(Configs.securityLevelHR) 
                    || securityLevel?.includes(Configs.securityLevelAdmin) ? 
                        <div className='flex flex-col m-4 lg:m-2 lg:flex-row items-center justify-end '>
                            <Link to="create">
                                <button className='save-button'>
                                        {t('pulse.campaigns.create')}
                                </button>
                            </Link>
                        </div>
                    : 
                        null 
                    }
                </div>

                {
                areCampaignsLoading ? 
                <Loader />
                :

                    <React.Fragment>

                        {pulseCampaigns === null  || pulseCampaigns.length < 1 ?
                                <React.Fragment >
                                    <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                                        <span className="title-description italic">📊 {" " + t('pulse.load.success.empty')}{" "}
                                        </span>
                                    </div>
                                </React.Fragment>    

                        :
                    
                                <div className='flex flex-col space-y-8'>

                                    {pulseCampaigns?.map((type, index) => (
                                        <div className="flex flex-col items-start m-2 space-y-4 ">

                                            <Tooltip content={t('pulse.type.' + type?._id + '.description')} >
                                                <h3 className="subsection-title">
                                                    {t(type?._id)} 
                                                </h3>
                                            </Tooltip>

                                            <div className='bg-white border shadow rounded-lg p-4 w-full'>

                                                <div class=" grid grid-cols-9 gap-x-4 gap-y-4 border-b p-2">
                                                    <div className='col-span-1 '>
                                                        <label for="username" class="block text-sm font-medium leading-6 text-gray-900">{t('status')}</label>
                                                    </div>
                                                    <div className='col-span-3'>
                                                        <label for="username" class="block text-sm font-medium leading-6 text-gray-900">{t('name')}</label>
                                                    </div>
                                                    <div className='col-span-2'>
                                                        <label for="username" class="block text-sm font-medium leading-6 text-gray-900">{t('start-date')}</label>
                                                    </div>
                                                    <div className='col-span-2'>
                                                        <label for="username" class="block text-sm font-medium leading-6 text-gray-900">{t('end-date')}</label>
                                                    </div>
                                                    <div className='col-span-1'>
                                                        
                                                    </div>
                                                </div>

                                                <div class="mt-2  grid grid-cols-9 gap-x-4 gap-y-8 text-sm">

                                                    {type?.campaigns?.map((campaign, index) => (
                                                    <React.Fragment>
                                                        <div class="col-span-1 ">
                                                            <div class="mt-2">
                                                            <div class="flex flex-col items-center text-center">
                                                                    { new Date() > new Date(campaign?.startDate) && new Date() < new Date(campaign?.endDate) ?
                                                                        <Tooltip content={t('active')}>
                                                                            <div className='w-[16px] h-[16px] border-50 bg-[#8ED3BF] rounded-full animate-pulse'>
                                                                            </div>   
                                                                        </Tooltip>
                                                                        :
                                                                        <Tooltip content={t('inactive')}>
                                                                            <div className='w-[16px] h-[16px] border-50 bg-red-200 rounded-full'>   
                                                                            </div>                                           
                                                                        </Tooltip>             
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-3">
                                                            <div class="mt-2">
                                                            <div class="flex hover:underline hover:font-medium">
                                                                    <Link to={"/pulse/campaigns/" + campaign?.campaignID}>
                                                                        {campaign?.campaignName}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <div class="mt-2">
                                                                <div class="flex ">
                                                                    {new Date(campaign?.startDate).toLocaleDateString()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <div class="mt-2">
                                                            <div class="flex ">
                                                                    {new Date(campaign?.endDate).toLocaleDateString()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-1 text-left">
                                                            <div class="mt-2 text-left">
                                                                <div class="flex flex-row space-x-2 text-left text-xl">
                                                                    <Link to={"/pulse/campaigns/edit/" + campaign?.campaignID}>
                                                                        <Tooltip content={t('edit')}>
                                                                            <FaEdit className='text-base cursor-pointer text-gray-500 hover:text-black'/>
                                                                        </Tooltip>
                                                                    </Link>
                                                                    <Tooltip content={t('delete')}>
                                                                        <FaTrash className='text-sm cursor-pointer text-gray-500 hover:text-black' onClick={(e) => {deleteCampaign(e, campaign?.campaignID)}}/> 
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </React.Fragment>

                                                    ))}


                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            }
                            <SectionSeparator />
                    </React.Fragment>
                }
                
            </div>
        </div>
            <div className='right-small-view'>

            </div>
        </div>
      </React.Fragment>
    );
}

export default PulseCampaigns;