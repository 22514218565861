// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';

// Icons
import {SiCrowdsource } from "react-icons/si";

// ------------------------------------------------------
function RecruitmentHeader () {
    
    const screens = [
        {view:"Interviews", url:"/recruitment/interviews", securityLevel: [Configs.securityLevelInterviewer]},
        {view:"Candidates", url:"/recruitment/candidates", securityLevel: [Configs.securityLevelInterviewer]},
        {view:"Jobs", url:"/recruitment/jobs", securityLevel: [Configs.securityLevelManager]}, // Create Hiring Manager role
        {view:"Reports", url:"/recruitment/reports", securityLevel: [Configs.securityLevelHR, Configs.securityLevelExec]}
    ];

    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('Recruitment')} Icon={SiCrowdsource} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default RecruitmentHeader;