// React
import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';

// Configs
import Configs from '../../Configs';
import { useAuth } from '../../common/appContext';

// i18n
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------
const FeedbackPopup = ({
    // Props    
    showPopup = false, // to control the visibility of the feedback popup based on the chance calculation. 
    onFeedbackSubmitted, // to control the visibility of the feedback popup based on the result of the submission.
    showFeedbackChance = 20 // 30 means there's a 30% chance the popup will appear
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const context = location.pathname;
    
  const [showPopupAux, setShowPopupAux] = useState(null);

  const {employeeID, companyID, token} = useAuth();

// --------------------------------------------------------------------
//  UseEffect
// --------------------------------------------------------------------
  useEffect(() => {
    // If parent wants to show pop-up, then show it based on the chance
    if (showPopup) {
        let random = Math.random() * 100;
        const shouldShowPopup = random < showFeedbackChance;
        setShowPopupAux(shouldShowPopup);

        if (Configs.devEnvironment) console.log("[feedbackPopUp.js][useEffect()] - shouldShowPopup:", shouldShowPopup, random);
    }

  }, [showFeedbackChance, showPopup]);

  // --------------------------------------------------------------------
// API calls
// --------------------------------------------------------------------
async function sendFeedback(feedback) {

    fetch(Configs.platformSubmitFeedbackFormAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({
            companyID: companyID,
            employeeID: employeeID,
            type: "experience",
            message: feedback ? "Positive" : "Negative",
            location: context
        })
    })
    .then(response => {
        if (response.code === 200) {
            
            response.json()
            .then(data => {
                if (Configs.devEnvironment) console.log("[feedbackPopUp.js][sendFeedback()] - data:", data);

                // set parent state to close the popup
                onFeedbackSubmitted();
            })
            .catch(error => {
                console.log("[feedbackPopUp.js][sendFeedback()] error:",error);
            });

        } else {
            throw response;
        }
    })
    .catch(error => {
        console.log("[feedbackPopUp.js][sendFeedback()] error:",error);
    });

}

// --------------------------------------------------------------------
// Handlers
// --------------------------------------------------------------------
  const handleFeedback = (feedback) => {
    if (showPopup) {
        sendFeedback(feedback);
      // Optionally, close the popup or indicate submission success here
      setShowPopupAux(false); // Close popup after feedback
    }
  };

// --------------------------------------------------------------------
// Render
// --------------------------------------------------------------------
  // Do not render the popup if showPopup is false
  if (!showPopupAux) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50 ">
        <div className="flex flex-col items-center space-y-8 w-full max-w-lg bg-white p-6 sm:p-12 rounded-lg shadow-lg ">
            <h2 className="flex flex-row w-full text-xl sm:text-2xl font-semibold text-gray-800">📣 {t('widgets.feedback.question')}</h2>
            <div className="flex flex-row items-center space-x-4 sm:space-x-8">
                <button
                    onClick={() => handleFeedback(true)}
                    className="save-button "
                    aria-label="Good"
                >
                    <span className='text-2xl'>👍</span>
                </button>
                <button
                    onClick={() => handleFeedback(false)}
                    className="remove-button"
                    aria-label="Bad"
                >
                    <span className='text-2xl'>👎</span>
                </button>
            </div>
      </div>
    </div>
  );
};

export default FeedbackPopup;