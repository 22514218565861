// React
import React, { useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

// Frameworks
import Configs from '../../Configs';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Headers
import DaysOffHeader from '../../common/daysOffHeader';
import Header from '../../common/header';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import * as apiCalls from '../../common/apiCalls';

// Widgets
import SectionSeparator from '../../widgets/sectionSeparator';
import FileInput from '../../widgets/files/fileInput';

// Toaster
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
// import {AiOutlineInfoCircle} from 'react-icons/ai';


// ---------------------------------------------------------------------------------------------------
function DaysOffRequest () {
    // i18n
    const {t} = useTranslation();

    // Params for edition
    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    const navigate = useNavigate();

    const {token, employeeID, companyID} = useAuth();
    const {toasterMessage, setToasterMessage} = useData();

    // Holidays policy
    const policy = sessionStorage.getItem('daysOffPolicy') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('daysOffPolicy'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;

    // Toaster & Loader
    const [isLoading, setIsLoading] = useState(false);

    // Dates error message
    const [datesErrorMessage, setDatesErrorMessage] = useState(false);
    const [maxLimitExceeded, setMaxLimitExceeded] = useState(false);
    const [dateMissing, setDateMissing] = useState(false);

    // Total days requested
    const [totalDaysRequested, setTotalDaysRequested] = useState([
        {leaveType: "vacation", days: 0},
        {leaveType: "carry-over", days: 0},
        {leaveType: "sick", days: 0},
        {leaveType: "personal", days: 0},
    ]);

    // Form fields - array of objects
    const auxRequestObject = {
        leaveType: null,
        startDate: null,
        endDate: null,
    }
    const [request, setRequest] = useState(!params ? [auxRequestObject] : params?.request);

    // Form options
    // Data to populate
    let leaveTypes = [
        {value: Configs.daysOffLeaveTypeVacation, label: t('time-off.request.leaveType.vacation')},
        {value: Configs.daysOffLeaveTypeCarryOver, label: t('time-off.request.leaveType.carry-over')},
        {value: Configs.daysOffLeaveTypeSick , label: t('time-off.request.leaveType.sick')},
        {value: Configs.daysOffLeaveTypePersonal, label: t('time-off.request.leaveType.personal')},
        {value: Configs.daysOffLeaveTypeMaternity, label: t('time-off.request.leaveType.maternity')},
        {value: Configs.daysOffLeaveTypeUnpaid, label: t('time-off.request.leaveType.unpaid')},
        {valeu: Configs.daysOffLeaveTypeBereavement, label: t('time-off.request.leaveType.bereavement')},
        {value: Configs.daysOffLeaveTypeOther, label: t('time-off.request.leaveType.other')},
        {value: Configs.daysOffLeaveTypeWFH, label: t('time-off.request.leaveType.wfh')},
        {value: Configs.daysOffLeaveTypeWFHSick, label: t('time-off.request.leaveType.wfh-sick')},
        {value: Configs.daysOffLeaveTypeWedding, label: t('time-off.request.leaveType.wedding')},
    ];

    const [leaveTypesDropdown, setLeaveTypesDropdown] = useState(leaveTypes);

    if (Configs.devEnvironment) console.log("[daysoffRequest.js][main()] request: ", request);
    if (Configs.devEnvironment) console.log("[daysoffRequest.js][main()] params: ", params);

// ---------------------------------------------------------------------------------------------------
// Methods to load when page is loaded
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        if (params) {
            // getEmployee() 
        } else {
            // 
        }

        // Populate all dropdowns
        populateDropdowns();
      }, []);      



// ---------------------------------------------------------------------------------------------------
// API calls
// ---------------------------------------------------------------------------------------------------
async function postNewDaysOffRequest (daysOffObject) {
    // Indicate loading status
    setIsLoading(true);

    const data = {
        companyID: companyID,
        employeeID: employeeID,
        request: daysOffObject?.request,
        comment: daysOffObject?.comment
    };
    
    try {
        const response = await apiCalls.postNewHolidayRequest(companyID, employeeID, token, data);

        // Stop loading
        setIsLoading(false);

        if (response === 400) {
            // Something went wrong - inform user
            setToasterMessage({message: t('warning'), type: Configs.warningToaster});
        } else if (response === 401) {
            // Force logout
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
        } else if (response === 403) {
                // Force logout
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                sessionStorage.clear();
                window.location.reload();
        } else if (response === 404) {
                setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
        } else if (response === 500) {
            //
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
            
        } else {
            setToasterMessage({message: t('time-off.request.submitted'), type: Configs.successToaster});

            // Clear frontend fields
            setRequest(null);
                    
            // Navigate go back
            navigate(-1);
        }

    } catch (error) {
        console.error("[daysOffRequest.js][postNewDaysOffRequest()] error:", error);
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        setIsLoading(false);
    }  
}
// ---------------------------------------------------------------------------------------------------
// Form management methods
// ---------------------------------------------------------------------------------------------------  
async function cancel () {
    // Go back to previous page
    navigate(-1);
}

async function validateInputsCreate () {
    if (Configs.devEnvironment) console.log("[daysOffRequest.js][validateInputsCreate()] - START");
    
    // Check if the inputs are correct for each of the requests
    if (request === null) {
        setToasterMessage({type: Configs.warningToaster, message: t('time-off.request.empty-request')});
        return false;
    } else {
        // Check each of the requests to see if it is empty - the validity of the request will be checked in the backend
        var errors = 0;

        request.forEach((daysOffRequest) => {
            if (daysOffRequest.leaveType === null) {
                setToasterMessage({type: Configs.warningToaster, message: t('time-off.request.empty-leaveType')});
                errors++;  
                return false;
            }

            if (daysOffRequest.startDate === null) {
                setToasterMessage({type: Configs.warningToaster, message: t('time-off.request.empty-startDate')});
                errors++;
                return false;
            } 

            if (daysOffRequest.endDate === null) {
                setToasterMessage({type: Configs.warningToaster, message: t('time-off.request.empty-endDate')});
                errors++;
                return false;
            }

            // Check if endDate is before startDate
            if (daysOffRequest.startDate > daysOffRequest.endDate) {
                setToasterMessage({type: Configs.warningToaster, message: t('time-off.request.end-before-start')});
                errors++;
                return false;
            }

            // Sick days have to have a fileId
            if (daysOffRequest?.leaveType?.toString() === Configs.daysOffLeaveTypeSick.toString() 
            && (!daysOffRequest.fileId || daysOffRequest.fileId === null)) {
                setToasterMessage({type: Configs.warningToaster, message: t('time-off.request.empty-document')});
                errors++;
                return false;
            }
        });
    }

    if (errors > 0) {
        return false;
    }
    
    // Create auxObject to be uploaded to the database
    var auxNewRequest = {
        companyID: companyID,
        employeeID: employeeID,
        request: request,
        comment: request.comment
    }

    if (Configs.devEnvironment) console.log("[daysoffRequest.js][validateInputsCreate()] auxNewRequest: ", auxNewRequest);

    // Post request
    postNewDaysOffRequest(auxNewRequest);
}

async function validateInputsEdit  () {

}

async function populateDropdowns () {
    // Populate list of leave types
    var auxLeaveTypes = leaveTypesDropdown;

    // Sort
    auxLeaveTypes.sort((a, b) => (a.label > b.label) ? 1 : -1);

    // Set state
    setLeaveTypesDropdown([...auxLeaveTypes]);

}

async function updateRequest (e, index, field) {
    if (Configs.devEnvironment) console.log("[daysoffRequest.js][updateRequest()] - START:", e, index, field);
    
    // Update request
    var auxRequest = request;
    
    switch (field) {
        case "leaveType":
            // Update leaveType
            auxRequest[index].leaveType = e.value;

            break;
        case "startDate":
            // Update startDate
            auxRequest[index].startDate = new Date(e.target.value);
            auxRequest[index].daysCount = calculateDaysCount(auxRequest[index].startDate, auxRequest[index].endDate, index);

            break;
        case "endDate":
            // Update endDate
            auxRequest[index].endDate = new Date(e.target.value);
            auxRequest[index].daysCount = calculateDaysCount(auxRequest[index].startDate, auxRequest[index].endDate, index);

            break;
        case "fileId":
            // Update fileId
            auxRequest[index].fileId = e;

            break;
        default:
            // Do nothing
            break;
    }

    // Dev-logging
    if (Configs.devEnvironment) console.log("[daysoffRequest.js][updateRequest()] auxRequest: ", auxRequest);

    calculateTotalDaysRequested(auxRequest);

    // set state
    setRequest([...auxRequest]);

}

async function addDaysToRequest () {
    // Copy existing request
    var auxRequest = request;

    // Add new empty request
    auxRequest.push({
        leaveType: null,
        startDate: null,
        endDate: null,
    });

    // Set state
    setRequest([...auxRequest]);
}

async function removeDayFromRequest (index) {
    if (request === null || request.length === 0) {
        return false;
    }
    
    // Remove day from request
    var auxRequest = request;

    // Remove day
    auxRequest?.splice(index, 1);

    // Set state
    setRequest([...auxRequest]);
}

function calculateDaysCount (startDate, endDate, index) {
    if (Configs.devEnvironment) console.log("[daysoffRequest.js][calculateDaysCount()] - START:", startDate, endDate, index);
    // Calculate days between startDate and endDate
    // Avoid holidays or weekends if applicable according to policy
    var daysCount = 0;
    var auxStartDate = startDate ? new Date(startDate) : null;
    var auxEndDate = endDate ? new Date(endDate) : null;

    // Check if startDate and endDate are defined
    if (startDate === null || endDate === null) {
        setDateMissing(true);
        return false;
    }

    // Check if startDate is before endDate
    if (auxStartDate > auxEndDate) {
        setDatesErrorMessage(true);
        return false;
    }

    // Calculate days between startDate and endDate, it has to be inclusive of both days
    daysCount = Math.floor((Date.UTC(auxEndDate.getFullYear(), auxEndDate.getMonth(), auxEndDate.getDate()) -
     Date.UTC(auxStartDate.getFullYear(), auxStartDate.getMonth(), auxStartDate.getDate()) ) /(1000 * 60 * 60 * 24)) + 1;    

    if (Configs.devEnvironment) console.log("[daysoffRequest.js][calculateDaysCount()] - total days off:", daysCount);


    // If policy is applicable, check if there are holidays or weekends in between
    if (!policy?.includesWeekends) {
        // Check if there are weekends in between
        var auxDate = new Date(auxStartDate);
        var auxEndDate = new Date(endDate);

        // Check each day in between, including both startDate and endDate
        for (var i = 0; i < daysCount+10; i++) {
            // Check if auxDate is a weekend
            if (auxDate.getDay() === 0 || auxDate.getDay() === 6) {
                if (Configs.devEnvironment) console.log("[daysoffRequest.js][calculateDaysCount()] - auxDate is weekend:", auxDate.toDateString());
                // Weekend - remove day from daysCount
                daysCount--;
            }

            // Add one day to auxDate
            auxDate.setDate(auxDate.getDate() + 1);
            if (Configs.devEnvironment) console.log("[daysoffRequest.js][calculateDaysCount()] - next auxDate:", auxDate.toDateString());

            // Break the loop if auxDate is greater than endDate
            if (auxDate > auxEndDate) {
                break;
            }
        }
    }

    if (Configs.devEnvironment) console.log("[daysoffRequest.js][calculateDaysCount()] - daysOff after weekends:", daysCount);


    // Check if there are bank holidays involved
    if (!policy?.includesHolidays) {
        // Check if there are holidays in between
        var auxDate = new Date(auxStartDate);
        var auxEndDate = new Date(endDate);

        for (var j = 0; j < daysCount+10; j++) {
            // Check if auxDate is a holiday
            if (policy?.holidays?.includes(auxDate)) {
                // Holiday - remove day from daysCount
                daysCount--;
            }

            // Add one day to auxDate
            auxDate.setDate(auxDate.getDate() + 1);

            // Break the loop if auxDate is greater than endDate
            if (auxDate > auxEndDate) {
                break;
            }
        }
    }

    if (Configs.devEnvironment) console.log("[daysoffRequest.js][calculateDaysCount()] - daysOff after holidays:", daysCount);

    // Check if daysCount is 0
    if (daysCount < 1) {
        daysCount = 1;
    }

    // Check if daysCount is negative
    if (daysCount < 0) {
        setDatesErrorMessage(true);
        return false;
    }

    // Check if daysCount is greater than companyPolicy
    var policyMax = policy?.leaves?.filter (leave => leave.leaveType === request[index].leaveType)[0]?.max;
    if (daysCount > policyMax) {
        setMaxLimitExceeded(true);
        return false;
    }

    if (Configs.devEnvironment) console.log("[daysoffRequest.js][calculateDaysCount()] daysCount: ", daysCount);

    return daysCount;
}

async function calculateTotalDaysRequested (request) {
    if (Configs.devEnvironment) console.log("[daysoffRequest.js][calculateTotalDaysRequested()] - START:", request);

}

// ---------------------------------------------------------------------------------------------------
// File input
// ---------------------------------------------------------------------------------------------------
const handleFileSelect = (fileId, index) => {
        if (Configs.devEnvironment) console.log('[daysoffRequest.js][handleFileSelect()] Selected file:', fileId, index);
        // Additional actions when a file is selected

        // Update request
        updateRequest(fileId, index, "fileId");
    };

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
};

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
return (
      <React.Fragment>
      <Header />

      <Bot/>

      <div className="flex flex-col lg:ml-[215px]">

        <DaysOffHeader />

        <div className="flex flex-col m-2 lg:m-4">

            {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
            
            <div className="flex flex-col mt-2 md:ml-2 m-1">
                    <div className='flex flex-col'>
                        {" "}
                        <div className='flex flex-col items-start md:flex-row justify-left'>
                            
                            {params ? 
                                <div id="title" className='section-title'>
                                    {t('time-off.request.edit.title')}
                                </div>
                                :
                                <div id="title" className='section-title'>
                                    {t('time-off.request.create.title')}
                                </div>
                            }
                            
                        </div>

                        {/* <span className="title-description">
                            {params ? 
                                t('time-off.request.edit.description')
                                :
                                t('time-off.request.create.description')  
                            } */}

                        {/* {/* <a target="_blank" href="https://resources.kincode.app/manager/pulse.html">
                            <b className='underline'> {t('pulse.home.help')} </b>
                        </a> 
                        {i18next.language === 'es' ? 
                                                    <a href="https://resources.kincode.app/es/manager/pulse.html" target="_blank">
                                                        <b className='underline'>{t('pulse.home.help')}</b>
                                                    </a>
                                                        
                                                        : 

                                                    <a href="https://resources.kincode.app/manager/pulse.html" target="_blank">
                                                        <b className='underline'>{t('pulse.home.help')}</b>
                                                    </a>
                                                } */}
                        {/* </span> */}
                    </div>
            </div>

               

                       
                    <div className="flex flex-col mt-2 lg:ml-2 m-1 space-y-4 w-full text-sm">
                        {isLoading ?
                            <Loader/>
                        :
                        <React.Fragment>

                            {/* Table header - only in desktop */}
                            <div className='flex flex-col w-full hidden lg:block'>
                                <div className='flex items-center flex-row justify-between lg:space-x-2 w-full'>
                                    {/* Add button - hidden */}
                                    <div className='flex flex-col justify-center items-center invisible'>
                                            <button id="addElement" className="save-button" >+</button>
                                    </div>

                                    {/* Leave type */}
                                    <div className='flex flex-col justify-center items-center lg:w-1/3'>{t('time-off.home.leave-type')}</div>
                                    {/* startDate */}
                                    <div className='flex flex-col '>{t('start-date')}</div>
                                    {/* endDate */}
                                    <div className='flex flex-col '>{t('end-date')}</div>
                                    {/* daysCount */}
                                    {/* <div className='flex flex-col '>{t('time-off.request.days-count')}</div> */}

                                    {/* attachments */}
                                    <div className='flex flex-col lg:w-1/3'>{t('time-off.request.attachments')}</div>
                                    {/* Comment */}
                                    {/* <div className='flex flex-col justify-center items-center lg:w-1/3 hidden'>{t('comment')}</div> */}

                                    {/* Remove button - hidden */}
                                    <div className='flex flex-col justify-center items-center invisible'>
                                        <button id="removeElement" className="remove-button">-</button>
                                    </div>
                                </div>
                            </div>

                            

                            {request === null || request?.length === 0 ?

                                <div className='flex flex-col w-full space-y-4'>
                                        <div className='flex flex-col items-center justify-center space-y-2 lg:flex-row lg:justify-between lg:space-y-0 lg:space-x-2 w-full'>
                                            {/* Add button - hidden */}
                                                    <div className='flex flex-col justify-center items-center invisible'>
                                                        <Tooltip content={t('time-off.request.add-day')}>
                                                            <button id="addElement" className="save-button" >
                                                                +
                                                            </button>
                                                        </Tooltip>
                                                    </div>

                                            {/* Leave type */}
                                            <Select
                                                options={leaveTypesDropdown}
                                                id={"leaveType" + 0}
                                                key={"leaveType_" + 0}
                                                isSearchable
                                                placeholder={t('time-off.leaveType.select-an-option')}
                                                styles={Configs.formDropdownStyle}
                                                className='w-full lg:w-1/3'
                                                onChange={(e) => updateRequest(e, 0, "leaveType")}
                                                />  

                                            {/* startDate */}
                                            <input type="date" id="startDate" name="startDate" value={new Date().toISOString().slice(0, 10)}
                                                    className="input-field w-full lg:w-auto"
                                                    onChange={(e) => updateRequest(e, 0, "startDate")} />

                                            {/* endDate */}
                                            <input type="date" id="endDate" name="endDate" value={new Date().toISOString().slice(0, 10)}
                                                    className="input-field w-full lg:w-auto"
                                                    onChange={(e) => updateRequest(e, 0, "endDate")} />

                                            {/* daysCount */}
                                            <div className='flex flex-col '>
                                                {0 + t('days')}
                                            </div> 

                                            {/* attachments */}
                                            <div className='flex flex-col lg:w-1/3 invisible'>
                                                <FileInput 
                                                onFileUpload={handleFileSelect}
                                                maxFileSize={Configs.maxFileSize} 
                                                allowedTypes={Configs.allowedTypesPDF}
                                                fileType='sick'
                                                index={0} />                                           
                                            </div>

                                            {/* Comment */}
                                            {/* <input type="text" id="comment" name="comment" placeholder={t('time-off.request.comment.placeholder')} 
                                                        className="input-field w-full lg:w-1/3 hidden" 
                                                    onChange={(e) => updateRequest(e.target.value, 0, "comment")} /> */}
                                            

                                            {/* Remove button - hidden */}
                                            <Tooltip content={t('time-off.request.remove-day')}>
                                                <div className='flex flex-col justify-center items-center invisible'>
                                                    <button id="removeElement" className="remove-button">
                                                        -
                                                    </button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className='flex flex-col justify-center items-center lg:flex-row lg:items-center lg:justify-start w-full'>
                                                <button id="addElement" className="save-button" onClick={() => addDaysToRequest()}>
                                                    +
                                                </button>
                                        </div>
                                    </div>


                            :
                                request?.length > 0 ?

                                   request?.map((auxRequest, index) => (
                                        <React.Fragment>
                                            <div className='flex flex-col items-center justify-center space-y-2 lg:flex-row lg:justify-between lg:space-y-0 lg:space-x-2 w-full'>
                                                {/* Add button - hidden */}
                                                        <div className='flex flex-col justify-center items-center invisible'>
                                                            <Tooltip content={t('time-off.request.add-day')}>
                                                                <button id="addElement" className="save-button">
                                                                    +
                                                                </button>
                                                            </Tooltip>
                                                        </div>

                                                {/* Leave type */}
                                                <Select
                                                    options={leaveTypesDropdown}
                                                    id={"leaveType" + index}
                                                    key={"leaveType_" + index}
                                                    isSearchable
                                                    placeholder={t('time-off.request.leaveType.select-an-option')}
                                                    styles={Configs.formDropdownStyle}
                                                    className='w-full lg:w-1/3'
                                                    value={auxRequest.leaveType ? leaveTypesDropdown.filter (leaveType => leaveType.value === auxRequest.leaveType) : null}
                                                    onChange={(e) => updateRequest(e, index, "leaveType")}
                                                    />  

                                                {/* startDate */}
                                                <input type="date" id="startDate" name="startDate" 
                                                        value={auxRequest.startDate ? new Date(auxRequest.startDate).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10)}
                                                        className="input-field w-full lg:w-auto"
                                                        onChange={(e) => updateRequest(e, index, "startDate")} />

                                                {/* endDate */}
                                                <input type="date" id="endDate" name="endDate" 
                                                        value={auxRequest.endDate ? new Date(auxRequest.endDate).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10)}
                                                        className="input-field w-full lg:w-auto"
                                                        onChange={(e) => updateRequest(e, index, "endDate")} />

                                                {/* daysCount */}
                                                <div className='flex flex-col '>
                                                    {(auxRequest?.daysCount !== undefined && auxRequest?.daysCount ? auxRequest?.daysCount : 0) + " " + t('days')}
                                                </div>
                                                
                                                {/* attachments */}
                                                <div className='flex flex-col lg:w-1/3'>
                                                    {/* {request[index]?.leaveType === Configs.daysOffLeaveTypeSick ? */}
                                                        <FileInput 
                                                        required={auxRequest?.leaveType?.toString() === Configs.daysOffLeaveTypeSick ? true : false}
                                                        onFileUpload={handleFileSelect}                                                        
                                                        maxFileSize={Configs.maxFileSize} 
                                                        allowedTypes={Configs.allowedTypesPDF}
                                                        index={index}
                                                        fileType='sick'
                                                        />                                           
                                                    {/* :
                                                        <div className='invisible'>
                                                        </div>
                                                    } */}
                                                </div>                                                                                                      

                                                {/* Comment */}
                                                {/* <input type="text" id="comment" name="comment" placeholder={t('time-off.request.comment.placeholder')} 
                                                            className="input-field w-full lg:w-1/3 hidden "
                                                            value={auxRequest.comment ? auxRequest.comment : null}
                                                        onChange={(e) => updateRequest(e.target.value, index, "comment")} /> */}
                                                

                                                {/* Remove button */}
                                                {request.length > 1 ?
                                                    <Tooltip content={t('time-off.request.remove-day')}>
                                                        <div className='flex flex-col justify-center items-center'>
                                                            <button id="removeElement" className="remove-button" onClick={() => removeDayFromRequest(index)}>
                                                                -
                                                            </button>
                                                        </div>
                                                    </Tooltip>
                                                :
                                                    <div className='flex flex-col justify-center items-center invisible'>
                                                        <button id="removeElement" className="remove-button" onClick={() => removeDayFromRequest(index)}>
                                                            -
                                                        </button>
                                                    </div>  
                                                }
                                        </div>
                                        {index === request.length - 1 ?
                                            <div className='flex flex-col justify-center items-center lg:flex-row lg:items-center lg:justify-start w-full'>
                                                <Tooltip content={t('time-off.request.add-day')}>
                                                    <button id="addElement" className="save-button" onClick={() => addDaysToRequest()}>
                                                        +
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        :
                                            null    
                                        }
                                    </React.Fragment>
                                ))

                                :
                                    // No requests
                                    null
                                }
                                
                        </React.Fragment>
                        }
                    </div>

                        {/* Request summary */}
                        <div className='flex flex-wrap space-x-4 m-4 hidden'>
                            {totalDaysRequested?.map((type, index) => (
                            <div className='flex flex-col space-y-2 p-8 border rounded-lg shadow-lg bg-white my-2'>
                                    <div className='flex flex-row items-center justify-start font-medium'>
                                    {t(type?.leaveType)}
                                    </div>
                                    <div className='flex flex-row items-end justify-center'>
                                    <span className='text-4xl font-bold'>
                                        {type?.available}
                                    </span>
                                    <span className='text-gray-600 ml-1'>
                                        / {type?.max}
                                    </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                        {/* CTA */}
                        <div className='flex flex-col m-4 lg:m-2 lg:flex-row items-center justify-end '>
                            <button type="button" id="cancelButton" class="secondary-button" onClick={cancel}> {t('cancel')} </button>
                            {params ? 
                                <button id="editButton" className='save-button' onClick={validateInputsEdit}>{t('time-off.request.edit')}</button>
                            :
                                <button id="createButton" className='save-button' onClick={validateInputsCreate}>{t('time-off.request.submit')}</button>
                            }
                        </div>
                
                    <SectionSeparator />
            

        </div>
        </div>
      </React.Fragment>
    );
}

export default DaysOffRequest;