// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";

// Common and widgets
import Header from '../../common/header';
import SuperAdminHeader from './superAdminHeader';
import Configs from "../../Configs";
import Bot from '../../common/support/bot'; 
import { useAuth } from '../../common/appContext';
import DataTable from '../../widgets/dataTable';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';


function CompanyAdmin () {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
  const {token, employeeID, companyID} = useAuth();
  const securityLevel = sessionStorage.getItem('securityLevel') ? CryptoJS.AES.decrypt(sessionStorage.getItem('securityLevel'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;


  const [companiesData, setCompaniesData] = useState(null);

  const [forbidden, setForbidden] = useState(false);

  // Toaster and Loader variables
  const [isLoading, setIsLoading] = useState(false);
  const [toasterMessage, setToasterMessage] = useState(null);

  // Data management
  let dataColumns = [
    {type:'string',id: 'companyName', value: 'companyName', label: t('company'), sort: null, filter: null},
    {type:'string',id: 'companyID', value: 'companyID', label: t('companyID'), sort: null, filter: null},
    {type:'string',id: 'taxID',value: 'taxID', label: t('VAT/GST'), sort: null, filter: null},
    {type:'date',id: 'timestamp',value: 'timestamp', label: t('date-created'), sort: null, filter: null},
    {type:'action',id: 'action',value: 'action', label: t('action'), sort: null, filter: null},
  ];

  // ---------------------------------------------------------------------------------------------------
  // useEffects
  // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      // Get information to populate dashboard
      if (companiesData === null) getAllCompaniesData();
    }, []);

  // ---------------------------------------------------------------------------------------------------
  // API
  // ---------------------------------------------------------------------------------------------------

    async function getAllCompaniesData () {
      setIsLoading(true);

      // fetch from server      
      fetch(Configs.platformAdminGetAllCompaniesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        }
      })
          .then((response) => {
            setIsLoading(false);
            // console.log (response);
            if (response.status === 200) {
              response.json()
              .then (body => {
                  if (Configs.devEnvironment) console.log("[companyAdmin.js][getAllCompaniesData] body from request: ", body)
                  setCompaniesData(body);
                })
              .catch(error => {
                console.error("[companyAdmin.js][getAllCompaniesData] Error parsing response JSON", error)
            });
            } else if (response.status === 401) {
                // Force logout
                sessionStorage.clear();
                window.location.href = Configs.devEnvironment ? "http://localhost:3000" : "https://platform.kincode.app";
            } else if (response.status === 403) {
                // Force logout
                setForbidden(true);
            } else if (response.status === 404) {
                // Force logout

            } else {
              // There is an error - delete info
              // sessionStorage.removeItem('performanceCampaigns');
              // sessionStorage.setItem('livePerformanceCampaign', false);
              throw response;
            }
          })
    
          .catch((error) => {
            setIsLoading(false);
            console.error("[superAdminHome.js][getAllCompaniesData()] Error fetching data: ", error);
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
          });
  }

// ---------------------------------------------------------------------------------------------------
// Data management
// ---------------------------------------------------------------------------------------------------
async function viewCompanyDetails (index, row) {
  if (Configs.devEnvironment) console.log("[companyAdmin.js][viewCompanyDetails] row: ", row, index);

  // Redirect to company details
  navigate("/admin/company/" + row.companyID);
}

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
  
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="flex flex-col lg:ml-[215px]">
          <SuperAdminHeader />
            
            <div className="flex flex-col m-2 lg:m-4">
        

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


          <div className="flex flex-col text-left space-y-4">

            {/* Employee actions - Create new employee, or act in bulk */}
            { securityLevel?.includes(Configs.securityLevelHR) ?
                            <React.Fragment>
                            <div className="flex flex-row justify-center lg:justify-end m-4">
                                <Link id="createNewCompany" to="/admin/company/create">
                                    <button id="createNewCompany" className='save-button '>
                                            {t('admin.company.create')}
                                    </button>
                                </Link>
                            </div>
                            </React.Fragment>

                        : 
                            null
                    }

            <h2 className='section-title'>Companies list</h2>
         
            { isLoading ?
                <Loader />
              :
                companiesData ? 
                    <React.Fragment>
                      
                        <DataTable data={companiesData} columnNames={dataColumns} zerosForDashes={true}
                        viewMethod={viewCompanyDetails}
                        />
                    </React.Fragment>
                    :
                    null
          }

            
          </div>
        </div>
        </div>
      </React.Fragment>
    );
}

export default CompanyAdmin;