// React
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Common and Configs
import Configs from '../Configs';

// 3P
import { useTranslation } from 'react-i18next';

// ------------------------------------------------
const Calendar = (
  { 
    // Approved days off (as list of days) 
    highlightedDates = null,
    // Pending days off (as list of days)
    pendingDates = null,
    // Events === Bank holidays (as a list of days)
    events = null,
    // Weekends will be automatically disabled according to the company policy
    weekends = true,
    // detailedView is the url to navigate to when the user clicks on 'view detailed view'
    detailedView,
    // selectedDate, used to highlight the selected date
    setSelectedDate
  }) => {
    // Common
    const { t } = useTranslation();

    // State variables
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDay, setSelectedDay] = useState(null);
    const [monthDays, setMonthDays] = useState([]);
    const dayLabels = ['widgets.calendar.monday.letter',
                        'widgets.calendar.tuesday.letter',
                        'widgets.calendar.wednesday.letter',
                        'widgets.calendar.thursday.letter',
                        'widgets.calendar.friday.letter',
       'widgets.calendar.saturday.letter', 'widgets.calendar.sunday.letter']; // Starting with Monday
      // const [daysOff, setDaysOff] = useState([]);

      if (Configs.devEnvironment) console.log('[calendarView.js][main()] props - events:', events);
      if (Configs.devEnvironment) console.log('[calendarView.js][main()] props - highlightedDates:', highlightedDates);
      if (Configs.devEnvironment) console.log('[calendarView.js][main()] props - pendingDates:', pendingDates);
  
// ------------------------------------------------
// useEffect
// ------------------------------------------------ 
    useEffect(() => {
      // Initial load, then it will be updated on navigation through months
      generateCalendarDays(currentMonth);
    }, [highlightedDates, pendingDates, events, currentMonth]);
  
// ------------------------------------------------
// Methods
// ------------------------------------------------
    const generateCalendarDays = (date) => {
      const startDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
      const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      const days = [];
      const daysToAdd = startDay === 0 ? 6 : startDay - 1; // Adjust for Monday start
  
      // Add padding for days from previous month
      for (let i = 0; i < daysToAdd; i++) {
        days.push({ date: null }); // Placeholder for empty days
      }
  
      // Add actual days of the month
      for (let day = 1; day <= daysInMonth; day++) {
        const tempDate = new Date(date.getFullYear(), date.getMonth(), day);
        days.push({
          date: tempDate,
          isApproved: isDateApproved(tempDate),
          isPending: isDatePending(tempDate),
          isEvent: isDateEvent(tempDate),
          event: events?.find(event => new Date(event.date).toDateString() === tempDate.toDateString()),
          isWeekend: (tempDate.getDay() === 0 || tempDate.getDay() === 6) ? true : false
        });
      }
  
      // select today as default
      if (selectedDay === null) {
        setSelectedDay(new Date());
      }

      // Update state
      setMonthDays(days);
    };

    // Approved holidays
    function isDateApproved (date) {
      return highlightedDates?.some(highlightedDate => new Date(highlightedDate).toDateString() === date.toDateString());
      }
    // Pending holidays
    function isDatePending (date) {
      return pendingDates?.some(pendingDate => new Date(pendingDate).toDateString() === date.toDateString());
      }
    // Events
    function isDateEvent (date) {
      return events?.some(event => new Date(event.date).toDateString() === date.toDateString());
      }

      
      // ------------------------------------------------
      // Navigation controls
      // ------------------------------------------------
      const goToNextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
        generateCalendarDays(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1))
        
      };
      
      const goToPreviousMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
        generateCalendarDays(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1))
      };
      
      const goToNextYear = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear() + 1, currentMonth.getMonth(), 1));
        generateCalendarDays(new Date(currentMonth.getFullYear() + 1, currentMonth.getMonth(), 1))
      };
      
      const goToPreviousYear = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear() - 1, currentMonth.getMonth(), 1));
        generateCalendarDays(new Date(currentMonth.getFullYear() - 1, currentMonth.getMonth(), 1))
      };
      
      const selectDay = (date) => {
        if (Configs.devEnvironment) console.log ('[calendarView.js][selectDay()] date:', monthDays.find(day => day.date === date));
        
        setSelectedDay(date);
        if (setSelectedDate) setSelectedDate(date);
      }


    // ------------------------------------------------
     // Render methods
     // ------------------------------------------------     
     
         const renderEventsForDay = (date) => {
             const dayEvents = events?.filter(event => new Date(event.date).toDateString() === date.toDateString());

            //  if (dayEvents?.length === 0) return (<p className='flex flex-col items-start title-description italic ml-8'>{t('widgets.calendar.noEvents')}</p>);
         
             return (
                 <ul className='flex flex-col items-center w-full'>
                  <span className='flex flex-row items-center justify-start text-sm w-full mb-4 '>{new Date(date).toLocaleDateString()}</span>

                  {dayEvents?.length === 0 && <p className='flex flex-col items-start title-description italic ml-8'>{"🗓️ " + t('widgets.calendar.noEvents')}</p>}

                 {dayEvents?.length !== 0 && dayEvents?.map((event, index) => (
                     <li className="flex flex-col items-start justify-start w-full text-left text-sm py-1" 
                     key={index}>
                      {event?.status === Configs.daysOffStatusApproved || event?.status === Configs.daysOffStatusPending ?
                        // An approved/pending request
                        <div className={'flex flex-col items-start justify-start w-full ' + 
                        (event?.status === Configs.daysOffStatusApproved ? ' border-green-200 border-l-4 pl-2 ' : ' border-yellow-200 border-l-4 pl-2 ')}>
                          <p className='font-medium text-sm'>{event?.employee}</p>
                          <p className='text-xs text-gray-500'>{t(event?.leaveType)}</p>

                        </div>
                        : 
                        // Not a request, but a bank holiday
                        <div className='flex flex-col items-start border-l-4 pl-2 py-2'>
                          <span className=' '>{event?.name_local ? event?.name_local : event?.name}</span>
                          <span className='text-xs text-gray-500'>{event?.holidayType === "National" ? t('time-off.holidayType.national') : event?.holidayType === "Local holiday" ? t('time-off.holidayType.local') : t('time-off.holidayType.company')}</span>
                        </div>
                      }
                       
                     </li>
                 ))}
                 </ul>
             );
         };
      
  // ------------------------------------------------
// Render method
// ------------------------------------------------
return (
    <div className="calendar-container">
      <div className="calendar-navigation">
          <div className='flex flex-row items-center justify-center space-x-4'>
            <div className='flex flex-row space-x-4'>
              <button onClick={goToPreviousYear}  className='hover:font-bold'>{"<<"}</button>
              <button onClick={goToPreviousMonth} className='hover:font-bold'>{"<"}</button>
            </div>
            <span className='flex'>
              {currentMonth?.toLocaleString('default', { month: 'long' })} {currentMonth?.getFullYear()}
            </span>
            <div className='flex flex-row space-x-4'>
              <button onClick={goToNextMonth} className='hover:font-bold'> {">"} </button>
              <button onClick={goToNextYear} className='hover:font-bold'> {">>"} </button>
            </div>
          </div>
      </div>
      <div className="calendar">
        {/* Header */}
        <div className="calendar-day-header">
          {dayLabels?.map((label, index) => (
            <div key={index} className="calendar-day-label">{t(label)}</div>
          ))}
        </div>
        {/* Days */}
        <div className="calendar-grid">
          {monthDays?.map((day, index) => (
            <div
              key={index}
              className={`calendar-day ${day?.isEvent ? 'calendar-event' : (day?.isPending ? ' pending ' : (day?.isApproved ? ' highlighted ' : '' ))} ${(weekends === true && (day?.date?.getDay() === 0 || day?.date?.getDay() === 6)) ? 'weekend' : ''}`}
              onClick={() => selectDay(day?.date)}
            >
              {day?.date?.getDate()}
            </div>
          ))}
        </div>
      </div>
      {detailedView && 
        <div className="text-xs underline hover:font-bold py-2">
          <Link to={detailedView}>{t('widgets.calendar.viewDetailedView')}</Link>
        </div>
      
      }
      {selectedDay && (
        <div className="events-list">
          {renderEventsForDay(selectedDay)}
        </div>
      )}
    </div>
  );
  };
  
  export default Calendar;