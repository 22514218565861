// React
import React, {useLocation, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";

// Common & widgets
import Header from '../../common/header';
import RecruitmentHeader from './recruitmentHeader';
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import SectionSeparator from '../../widgets/sectionSeparator';
import DataTable from '../../widgets/dataTable';


// 3P
import i18next, { t } from 'i18next';
import CryptoJS from 'crypto-js';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';


function RecruitmentInterviews () {
  // Navigate
  const navigate = useNavigate();

  // Employee Info
  const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
  const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
  const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
  // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
  const { token } = useAuth();

  // Toaster & Loader
  const [toasterMessage, setToasterMessage] = useState(null);
  const [isJobsLoading, setIsJobsLoading] = useState(false);

  // Mock-up data
  const candidates = [
    { candidateID:"1231245", candidate: "Jhonny Depp", lastCVupdatedDate:"09-06-2012", latestStatus:"Overdue feedback", latestRole: "Product Manager"},
    { candidateID:"1231245", candidate: "Jhonny Depp", lastCVupdatedDate:"09-06-2012", latestStatus:"Overdue feedback", latestRole: "Product Manager"},
    { candidateID:"1231245", candidate: "Jhonny Depp", lastCVupdatedDate:"09-06-2012", latestStatus:"Overdue feedback", latestRole: "Product Manager"},
  ];

    const candidatesColumns = [
      {type:'string',id: 'candidate', value: 'candidate', label: t('candidate'), sort: null, filter: null},
      {type:'string',id: 'description', value: 'description', label: t('gender'), sort: null, filter: null},
      {type:'string',id: 'latestRole', value: 'latestRole', label: t('recruitment.candidates.latestRole'), sort: null, filter: null},
      {type:'date',id: 'lastCVupdatedDate', value: 'lastCVupdatedDate', label: t('recruitment.candidates.lastCVupdatedDate'), sort: null, filter: null},
      {type:'action',id: 'action', value: 'action', label: "", sort: null, filter: null}
    ];


// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
    
// ----------------------------------------------------------------------------
// Render method
// ----------------------------------------------------------------------------    

    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
        <RecruitmentHeader />

        <div className="flex flex-row items-start">
        <div className="left-big-view">



        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

          <div className="flex flex-col mt-2 md:ml-2 m-1">

                {/* Title */}
                <h2 className="flex text-xl mb-4 ">
                  {" "}
                  <div className='flex flex-col md:flex-row justify-left'>
                    <Tooltip content={t('recruitment.interviews.upcoming.description')} >
                      <div className='section-title'>
                        {t('recruitment.interviews.upcoming.title')}
                      </div>
                    </Tooltip>

                  </div>

                </h2>


              {candidates === null || candidates?.length < 1 ?

                  <div className='flex flex-row justify-center items-center bg-white p-4 shadow-lg rounded-lg border pt-6'>
                    <span className='title-description italic'> {t('recruitment.interviews.upcoming.empty')}</span>    
                  </div>
              :

                  <div className='flex flex-col w-full mt-4'>
                    <DataTable data={candidates} columnNames={candidatesColumns} zerosForDashes={true}
                          // editMethod={editMethod} viewMethod={viewMethod}
                          // deleteMethod={deleteMethod} 
                        />
                  </div>
              }

          </div>

          <div className="flex flex-col mt-2 md:ml-2 m-1 mt-8">

              {/* Title */}
              <h2 className="flex text-xl mb-4 ">
                <div className='flex flex-col md:flex-row justify-left'>
                  <Tooltip content={t('recruitment.interviews.past.description')} >
                    <div className='section-title'>
                      {t('recruitment.interviews.past.title')}
                    </div>
                  </Tooltip>
                </div>
              </h2>


            {candidates === null || candidates?.length < 1 ?

                <div className='flex flex-row justify-center items-center bg-white p-4 shadow-lg rounded-lg border pt-6'>
                  <span className='title-description italic'> {t('recruitment.interviews.past.empty')}</span>    
                </div>
            :

                <div className='flex flex-col w-full mt-4'>
                  <DataTable data={candidates} columnNames={candidatesColumns} zerosForDashes={true}
                        // editMethod={editMethod} viewMethod={viewMethod}
                        // deleteMethod={deleteMethod} 
                      />
                </div>
            }

          </div>
        <SectionSeparator />
        </div>
        <div className='right-small-view'>

        </div>
        </div>
      </div>
      </React.Fragment>
    );
}

export default RecruitmentInterviews;