
// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';

// Icons
import { BsFillInboxFill} from "react-icons/bs";

// -----------------------------------------------------------------
function InboxHeader () {
    
    const screens = [
        {view:t('inbox.hr.view'), url:"/inbox/hr", securityLevel:[Configs.securityLevelHR], id:"inbox.header.hr"},
        {view:t('inbox.employee.view'), url:"/inbox/", securityLevel:[Configs.securityLevelIC], id:"inbox.header.employee"},
    ];
    
    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('Inbox')} Icon={BsFillInboxFill} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default InboxHeader;