// React
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

// Common and widgets
import Configs from "../../Configs";
import Bot from '../../common/support/bot';
import Header from '../../common/header';
import RecognitionHeader from './recognitionHeader';
import SectionSeparator from '../../widgets/sectionSeparator';
import DataTable from '../../widgets/dataTable';
import FilterWidget from '../../widgets/data/filterWidget';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineDownload, AiOutlineFilter} from 'react-icons/ai';

// ---------------------------------------------------------------------------------------------------
function RecognitionLeaderboards () {
    // 3P
    const {t} = useTranslation();

    // Employee Info
    const {token, employeeID, companyID } = useAuth();
    const {companyPreferences, toasterMessage, setToasterMessage} = useData();
    const {getRecognitionLeaderboardsForCompany, companyRecognitionLeaderboardData, isCompanyRecognitionLeaderboardDataLoading} = useData();

    // Company preferences color
    const [headerColor, setHeaderColor] = useState(companyPreferences?.personalization?.color ? companyPreferences?.personalization?.color.toString() : Configs.headerColor)
    const theme = companyPreferences?.personalization?.theme ? companyPreferences?.personalization?.theme.toString() : "light";

    // Recognition leaderboards
    const [modifiedData, setModifiedData] = useState(companyRecognitionLeaderboardData ? companyRecognitionLeaderboardData : null);

    // Columns
    let columnNamesValuesTable = [
        {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
        {type:'string',id: 'subarea', value: 'subarea', label: t('subarea'), sort: null, filter: null},
        {type:'string',id: 'employee',value: 'employee', label: t('employee'), sort: null, filter: null},
        {type:'number',id: 'recognitionsGiven',value: 'recognitionsGiven', label: t('recognition.given'), sort: null, filter: null},
        {type:'number',id: 'recognitionsReceived',value: 'recognitionsReceived', label: t('recognition.received'), sort: null, filter: null},
      ];

// ---------------------------------------------------------------------------------------------------
// After loading the view
// ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      if (companyRecognitionLeaderboardData && companyRecognitionLeaderboardData?.length > 0) {
        setModifiedData(companyRecognitionLeaderboardData);
      } else {
        getRecognitionLeaderboardsForCompany();
      }

    }, [companyRecognitionLeaderboardData]);

// ---------------------------------------------------------------------------------------------------
// Filter methods
// ---------------------------------------------------------------------------------------------------   
const handleFilterChange = (filteredDataInput) => {
  if (Configs.devEnvironment) console.log("[recognitionLeaderboards.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
  setModifiedData(filteredDataInput);
};


// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
    
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="main">
        <RecognitionHeader />
            
        <div className='flex flex-row items-start'>
          <div className="left-big-view">
        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        <div className="flex flex-col text-left ">

          <div className="flex flex-col mt-2 md:ml-2 m-1">
            <h2 className="flex text-xl mb-4 ">
              {" "}
              <div className='flex flex-col items-start md:flex-row justify-left'>
                <Tooltip content={t('recognition.leaderboard.description')} >
                  <div className='section-title' id='recognition-title'>
                  {t('recognition.leaderboard.title')}
                  </div>
                </Tooltip>
              </div>
            </h2>
          </div>

          <div className='flex flex-row items-start justify-end px-8 py-4 mb-4'>
                <Link to="/recognition/create">
                    <button className='save-button'>
                      {t('recognition.home.cta')}
                    </button>
                </Link>
          </div>

          {isCompanyRecognitionLeaderboardDataLoading ? 
              <Loader />
            :
              <React.Fragment>

                {modifiedData && modifiedData?.length > 0 ?

                    <div className='flex flex-col space-y-2'>

                      <FilterWidget data={companyRecognitionLeaderboardData} onFilterChange={handleFilterChange}
                                  teamTypeFilter={false} defaultTeamTypeFilter={"null"}
                                  managerFilter={false}
                                  employeeFilter={true}
                                  areaFilter={true} 
                                  subareaFilter={true}
                       />
                    

                      <DataTable 
                          data={modifiedData} 
                          columnNames={columnNamesValuesTable}
                          zerosForDashes={true}
                      />
                    </div>
                    
                :
                    <div className="flex flex-col items-center mt-8 md:ml-2 m-1">
                        <span className="title-description italic">{t('recognition.leaderboard.empty')}</span>
                        <Link to="/recognition/create">
                            <button className='flex save-button'>
                            {t('recognition.home.cta')}
                            </button>
                        </Link>
                    </div>
                }      

                <SectionSeparator />

              </React.Fragment>
          }
        </div>
        </div>
      </div>
      </div>
    </React.Fragment>
    );
}

export default RecognitionLeaderboards;