import React, { useState, useEffect } from 'react';

const LoadingModal = (props) => {

    // variables
    const timeout = props.timeout;
    const message = props.message;

    useEffect(() => {

        // Set interval only if timer is greater than 0
        var interval = null;
        if (timeout > 0) {
            interval = setInterval(() => {
                props.onClose();
            }, timeout);
        }  
      
  
        // Method that will be run when finishing the component
        return () => {
            clearInterval(interval);
        };
    }, [props.onClose, props.timeout]);

    
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 backdrop-filter backdrop-blur">
      <div className="bg-white p-6 rounded shadow-lg">
      <h2 className="text-xl font-semibold mb-4">{props.message.title}</h2>
        <p>{props.message.message}</p>
      </div>
    </div>
  );
};

export default LoadingModal;