// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

// Common and widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import InboxHeader from './inboxHeader';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import CollapsibleListItem from '../../widgets/collapsibleListItem';
import { useAuth } from '../../common/appContext';

// Toaster & Loader
import Toaster from '../../common/support/toaster';

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';


function HRInbox () {

    const {token, companyID, employeeID} = useAuth();

    const [hrRequestsHistory, setHRRequesstHistory] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [ticketType, setTicketType] = useState("");
    const [request, setRequest] = useState("");

    // Toaster
    const [toasterMessage, setToasterMessage] = useState(null);

    if (Configs.devEnvironment) console.log ("DEV ONLY - HR Requests History = ", hrRequestsHistory);

// ---------------------------------------------------------------------------------------------------
// Page start methods
// ---------------------------------------------------------------------------------------------------    
    useEffect (() => {
        // Get information to populate dashboard
        if (hrRequestsHistory === null) getHRRequestsHistory();
        }, [ticketType, showForm]);


    async function consultationType (e) {
        e.preventDefault();

        setShowForm(true);
        setTicketType("consultation");
        
    }

    async function claimType (e) {
    e.preventDefault();

    setShowForm(true);
    setTicketType("claim");

    }

    async function submitForm (event) {
        event.preventDefault();

        if (request === '' || request === null || request === "" ){
          // Missing input
          setToasterMessage({message: t('inbox.content.empty'), type: Configs.warningToaster})

        } else {
          // Input in the request - send to HR
          postHRRequest();
        }    
    }


        
// ---------------------------------------------------------------------------------------------------
// API methods
// ---------------------------------------------------------------------------------------------------        
    async function getHRRequestsHistory () {
        // fetch from server      
        fetch(Configs.platformGetInboxMessagesForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          }
        })
            .then((response) => {
              // console.log (response);
              if (response.status === 200) {
                // Username and password mathched in the database - get Token and save it in the session
                response.json()
                .then ( body => {
                  
                    setHRRequesstHistory(body);
                    if (Configs.devEnvironment) console.log("DEV ONLY log: - History from getHRRequestHistory(): ", body);
                  })
                .catch(error => {console.error("Error parsing response JSON", error)});
              } else if (response.status === 401) {
                // Force logout
                  sessionStorage.clear();
                  window.location.reload();
              } else {
                //
                throw response;
              }
            })
      
            .catch((error) => {
              console.log("Error fetching data: ", error, " - function: GetProfile");
              setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            });
    }

    async function postHRRequest () {
        // fetch from server      
        fetch(Configs.platformPostInboxRequestAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, {
          method: 'POST',
          credentials: 'include',
          headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          },
          body: JSON.stringify({
            ticketType: ticketType,
            message: request,
            employeeID: employeeID,
            companyID: companyID
          })
        })
            .then((response) => {
              // console.log (response);
              if (response.status === 200) {
                // 
                setShowForm(false);
                setTicketType("");
                setRequest("");

                setToasterMessage({message: t('inbox.success'), type: Configs.successToaster})

              } else if (response.status === 401) {
                // Force logout
                //  sessionStorage.clear();
                //  window.location.reload();
                setToasterMessage({message: t('inbox.error'), type: Configs.errorToaster})

              } else {
                //
                throw response;
              }
            })
      
            .catch((error) => {
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
                console.error("Error fetching data: ", error, " - function: postHRRequest");
            });
    }

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
    setToasterMessage(null);
  };

// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
      <Header />
      <Bot />

      <div className="flex flex-col lg:ml-[215px]">
            <InboxHeader />
            
            <div className="flex flex-col m-2 lg:m-4">

        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

        <div className='ml-4 text-left'>
        <h1 className="text-left text-xl">{t('inbox.what-do-you-need')}</h1>
        <span className='title-description'></span>
        </div>

        {/** Section with buttons / options to perform */}
        <div className='flex flex-col lg:flex-row justify-around mt-8  overflow-x-auto'>
            
            <button className='flex bg-gray-100 px-40 py-10 m-4 rounded shadow text-xl'
            onClick={consultationType}>
                🤔 {t('inbox.hr.consultation')}
            </button>
            <button className='flex bg-gray-100 px-40 py-10 m-4 rounded shadow text-xl'
            onClick={claimType}>
                🚩 {t('inbox.hr.claim')}
            </button>

        </div>

        {/** Hidden section to write the request */}
        {showForm ? 
        <React.Fragment>
            <SectionSeparator />

            <div className='flex flex-col'>
              <h2 className="ml-4 text-left text-xl">
                  {ticketType === "claim" ?
                      <React.Fragment>🚩 {t('inbox.claim')}</React.Fragment>
                  :
                      <React.Fragment>🤔 {t('inbox.consultation')}</React.Fragment>
                  }
              </h2>

              <form>
                  <fieldset>
                      <div className="flex flex-col lg:flex-row m-4 pl-4 pr-4 rounded">
                            <label className='mt-2 text-left rounded p-4'>
                              {t('inbox.content')}
                            </label>
                            <input type="text" className='mr-2 w-full border border-gray-200 p-4 rounded' placeholder={t('inbox.content.placeholder')}
                            onChange={(e) => setRequest(e.target.value)}/>
                      </div>

                  </fieldset>


              </form>
              <div className='flex flex-row justify-center lg:justify-end'>
                <button className='text-center shadow px-4 py-2 rounded m-4 mb-12 bg-green-200' onClick={submitForm}>
                        {t('submit')}
                </button>
              </div>
            </div>


        </React.Fragment>        
            : null
        }



        {/** Section with opened cases and status */}
        <div className='flex flex-col'>
            {hrRequestsHistory != null && hrRequestsHistory?.length > 0 ? 
                        <React.Fragment>

                        <SectionSeparator />
     
                        <div className='flex flex-col'>
                                <h2 className="m-2 text-xl">
                                    {t('inbox.history')}
                                </h2>

                                <div className='flex flex-col'>
                                        {hrRequestsHistory?.map((request, index) => (
                                                <div>    
                                                    <CollapsibleListItem 
                                                    title={" " + (request.claimType === "consultation" ? "🤔" : "🚩") + 
                                                    "[" + request.status + "] " +
                                                      new Date(request.timestamp).getUTCDate() + "/" + (new Date(request.timestamp).getUTCMonth() + 1) + "/" + new Date(request.timestamp).getUTCFullYear() +
                                                      (request.title ? request.title : "")} content={request.message} collapsed={true} />
                                                </div>
                                        ))}
                                </div>
                        </div>
                        </React.Fragment>
                    : 
                        null
                }
        </div>
    
        </div>
        </div>
      </React.Fragment>
    );
}

export default HRInbox;