// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';
// Icons
import {IoPulseSharp } from "react-icons/io5";

// -------------------------------------------------
function PulseHeader () {
    
    const screens = [
        {view:t('dashboard'), url:"/pulse/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR , Configs.securityLevelExec], id:"pulse.header.dashboard"},
        {view:t('surveys'), url:"/pulse/campaigns/", securityLevel:[Configs.securityLevelHR, Configs.securityLevelExec], id:"pulse.header.surveys"},
        {view:t('reporting'), url:"/pulse/reporting/", securityLevel:[Configs.securityLevelHR, Configs.securityLevelExec], id:"pulse.header.analytics"},
    ];

    // ------------------------------------------------------
    // Methods and functions
    // ------------------------------------------------------
    function getCurrentPathWithoutLastPart (string) {
        const pathRgx = /\//g;
        const childroutecount = ((string || '').match(pathRgx) || []).length
        // console.log (childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string)
        return childroutecount > 1 ? string.slice(0, string.lastIndexOf('/')) + "/" : string;
    }

    // ------------------------------------------------------
    // Render component
    // ------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('employee-pulse')} Icon={IoPulseSharp} breadcrumbs={false}/>
        </React.Fragment>
    );

}

export default PulseHeader;