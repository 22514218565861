// React
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Common and Configs
import Configs from '../Configs';
import { useData } from '../common/dataContext';
import { useAuth } from '../common/appContext';

// Widgets
import Tooltip from '../common/support/tooltip';

// 3P
import { startOfWeek, format, addDays, subWeeks, addWeeks, subMonths, addMonths, subYears, addYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

// ------------------------------------------------
const WeekCalendar = ({ teamMembers, startDate = new Date(), setStartDate }) => {
    const {t} = useTranslation();
    const {companyID, employeeID} = useAuth();
    const { companyOrg } = useData();   

    const [currentDate, setCurrentDate] = useState(startDate);
    const [weekDays, setWeekDays] = useState([]);

    const approvedDayColor = 'bg-red-500';
    const approvedDayTextColor = 'text-red-500';
    const pendingDayColor = 'bg-yellow-500';
    const pendingDayTextColor = 'text-yellow-500';
    const freeDayColor = 'bg-green-500';
    const freeDayTextColor = 'text-green-500';

    // ------------------------------------------------
    // useEffect
    // ------------------------------------------------
    useEffect(() => {
        setCurrentDate(startDate);
    }, [startDate]);

    useEffect(() => {
        const start = startOfWeek(currentDate, { weekStartsOn: 1 });

        if (startDate.getTime() !== currentDate.getTime()) setCurrentDate(startDate);

        const days = Array.from({ length: 7 }).map((_, index) => {
            const date = addDays(start, index);
            return {
                date: date,
                dateString: format(date, 'yyyy-MM-dd'),
                dayString: format(date, 'EEEE')
            };
        });
        setWeekDays(days);

    }, [currentDate]);

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const navigate = (weeks) => {
        var currentDateAux = new Date(currentDate);
        currentDateAux = addDays(currentDateAux, weeks * 7);
        
        if (Configs.devEnvironment) console.log('[calendarDetailedWidget.js][navigate()] Weeks: ', weeks, currentDateAux, currentDate);
        setStartDate(currentDateAux);
        // setCurrentDate(currentDateAux);
    };

    const isDateInArray = (dateArray, targetDate) => {
        return dateArray.some(date => date.getTime() === targetDate.getTime());
      };
    
    const getLeaveType = (isDayOff, array, targetDate) => {

        if (isDayOff) {
            const leaveObject = array?.find(element => element.date.getTime() === targetDate.getTime())
            return leaveObject?.leaveType;
        } else {
            return null;
        }
    }

    // ------------------------------------------------
    // Render
    // ------------------------------------------------
    return (
        <div className="flex flex-col p-4 w-full">
            <div className="flex flex-row justify-center space-x-4 items-center mb-4">
                <button onClick={() => navigate(-1)}>&lt; </button>
                <span>{format(currentDate, 'MMMM yyyy')}</span>
                <button onClick={() => navigate(1)}>&gt;</button>
            </div>
            <div className="flex flex-col rounded-lg shadow overflow-x-auto">
                <table className="min-w-full text-sm border border-gray-100 ">
                    <thead>
                        <tr>
                            <th className="p-2 sticky left-0 bg-white text-xs text-gray-600">{t('employee')}</th>
                            {weekDays?.map(day => (
                                <th key={day?.dateString} className=" font-semibold text-gray-600 bg-gray-100 p-2 border-l border-r">
                                    <div className='flex flex-col items-center'>
                                        <span className='text-xs text-gray-500'>{day?.dayString === "Wednesday" && t(day.dayString)[0] === "M" ? "X" : t(day.dayString)[0]}</span>
                                        <span className='text-xs'>{format(day.date, 'dd/MM')}</span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {teamMembers?.map(member => (
                            <tr key={member?.id}>
                                <td className="bg-gray-50 text-gray-800 font-semibold border p-2 sticky left-0 text-sm hover:underline">
                                    <Link id={"direct_"+member?.id} to={"/timeoff/team/" +
                                                        encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
                                                            employee: companyOrg?.find(employee => employee?.employeeID === member.employeeID)?.employee,
                                                            employeeName: companyOrg?.find(employee => employee?.employeeID === member.employeeID)?.name,
                                                            employeeSurname: companyOrg?.find(employee => employee?.employeeID === member.employeeID)?.surname,
                                                            employeeID: member?.employeeID,
                                                            companyID: companyID,
                                                        }),Configs.privateKey).toString())}
                                                        >
                                    {companyOrg?.find(employee => parseInt(employee?.employeeID) === parseInt(member.employeeID))?.employee}
                                    </Link>
                                </td>
                                {weekDays?.map(day => {
                                    const isApproved = isDateInArray(member?.daysOffApproved, day.date);
                                    const isPending = isDateInArray(member?.daysOffPending, day.date);
                                    const isDayOff = isApproved || isPending;
                                    const tooltipContent = getLeaveType(isDayOff, member?.daysOffType, day.date);
                                    return (
                                        <td key={day?.dateString} className='p-1 border'>
                                            {!isPending && !isApproved ?
                                                <div className={`m-1 rounded-full ${isApproved ? approvedDayColor : isPending ? pendingDayColor : freeDayColor }`}>
                                                    <span className={`${isApproved ? approvedDayTextColor : isPending ? pendingDayTextColor : freeDayTextColor }`}>{" - "}</span>
                                                </div>
                                            :
                                                    <div className={`m-1 rounded-full items-center ${isApproved ? approvedDayColor : isPending ? pendingDayColor : freeDayColor }`}>
                                                        <Tooltip content={t(tooltipContent)} >
                                                            <span className={`justify-center text-center w-full ${isApproved ? approvedDayTextColor : isPending ? pendingDayTextColor : freeDayTextColor }`}>{" --- "}</span>
                                                        </Tooltip>
                                                    </div>
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* Legend */}
            <div className='flex flex-wrap justify-end mt-4 space-x-2'>
                <div className={'rounded-full text-xs text-white px-2 py-1 font-bold ' + approvedDayColor}>{t('approved')}</div>
                <div className={'rounded-full text-xs text-white px-2 py-1 font-bold ' + pendingDayColor}>{t('pending')}</div>
                <div className={'rounded-full text-xs text-white px-2 py-1 font-bold ' + freeDayColor}>{t('available')}</div>
            </div>
        </div>
    );
};

export default WeekCalendar;