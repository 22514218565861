// React
import React from 'react';
import { Link} from 'react-router-dom';

// Contexts
import { useHistoryContext } from '../historyContext';
import {useData } from '../dataContext';
import {useAuth} from '../appContext';
import Configs from '../../Configs';

// 3P
import {t} from 'i18next';
import CryptoJS from 'crypto-js';

// ----------------------------------------------------------------------------
function Breadcrumbs () {
    const {locationHistory} = useHistoryContext();
    const {companyOrg, jobLevellingGuidelinesData} = useData();
    const {employeeID, companyID} = useAuth();

  // Dictionary for friendly names
  const friendlyNames = {
      
    // ENGAGE  
        // 1:1s
        "oneonone": t("1on1"),
        // Recognition  
        "recognition": t("Recognition"),  
        // Pulse
        "pulse": t("pulse"),
        "campaigns": t("campaigns"),

    // DEVELOP
        // Development
        "development": t("development"),

    // PERFORM
        // Calibration
        "calibration": t("Calibration"),

        // PERFORMANCE
        "performance": t("performance"),

      
    // BASICS
        // Organigram
        "organigram": t("Organigram"),
        "employees": t("employees"),
        "create": t("create"),
        "edit": t("edit"),
        // Profile
        "profile": t("Profile"),
        // Time-off
        "timeoff": t("timeoff"),
        // Punch
        "punch": t("PunchInOut"),
        // HR Inbox
        "inbox": t("Inbox"),
        
        // Settings
        "settings": t("settings"), 

        "company": t("company"),
        "hr": t("HR"),
        "team": t("team"),
        "review": t("review"),
        "strengths": t("strengths"),
        "discuss": t("discuss"),
        "leaderboard": t("leaderboard"),


    };

  // Decide how many segments to display (e.g., last 3)
  const displayLimit = 4;
  const startIndex = locationHistory?.length > displayLimit ? locationHistory?.length - displayLimit : 0;

  // Process only the last `displayLimit` pathnames
  const displayPathnames = locationHistory?.slice(startIndex);

 
  function getFriendlyName (pathParts, readablePath) {

    if (!readablePath) return null;

    if (readablePath?.length > 20) {
        // 1:1s
        if (pathParts?.includes('oneonone') && (pathParts?.includes('space') || pathParts?.includes('meeting'))) {
            const params = JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(readablePath), Configs.privateKey).toString(CryptoJS.enc.Utf8));

            const employeeInfo = companyOrg?.find((employee) => { return employee?.employeeID === params?.employeeID });

            if (employeeInfo?.employeeID === employeeID) return t('one-on-one.mine');

            return t('1on1') +": "+ employeeInfo?.employee;
        } else if (pathParts?.includes('career')) {
          // CAREER
          if (pathParts?.includes('guideline')) {
            const params = JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(readablePath), Configs.privateKey).toString(CryptoJS.enc.Utf8));

            const guideline = jobLevellingGuidelinesData?.find((guideline) => { return guideline?.guidelineID === params?.guidelineID });

            return t('Career') +": "+guideline?.role + " - " + guideline?.level;

          } else if (pathParts?.includes('team')) {
            const params = JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(readablePath), Configs.privateKey).toString(CryptoJS.enc.Utf8));

            const employeeInfo = companyOrg?.find((employee) => { return employee?.employeeID === params?.employeeID });

            if (employeeInfo?.employeeID === employeeID) return t('career.header.my-path');

            return t('Career') +": "+ employeeInfo?.employee;            

          } else {
            return t('Career');
          }

          // PERFORMANCE
        } else if (pathParts?.includes('performance') && (pathParts?.includes('self') || pathParts?.includes('team'))) {

          return t('performance');
          
        
        } else if (pathParts?.includes('profile')) {
          const params = JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(readablePath), Configs.privateKey).toString(CryptoJS.enc.Utf8));

          const employeeInfo = companyOrg?.find((employee) => { return employee?.employeeID === params?.employeeID });

          if (employeeInfo?.employeeID === employeeID) return t('Profile');

          return t('Profile') +": "+ employeeInfo?.employee;

        } else {
            return readablePath?.substring(0, 15) + '...';
        }
    } else {
        return friendlyNames[readablePath] ? friendlyNames[readablePath] : readablePath;
    }
  }



// ----------------------------------------------------------------------------
// Render
// ----------------------------------------------------------------------------
  if (locationHistory?.length <= 1) {
    // If main screen, return null
    return null
  } else {
    // If breadcrumbs make sense, add
        return (
            <div className='flex flex-row text-left justify-center lg:justify-start items-center w-full text-gray-500 text-xs px-4 py-2 border-b lg:border-0 border-b-gray-200 shadow-sm lg:shadow-none'>
            {/* <Link to="/"> {t('Home')} </Link> */}
            {displayPathnames?.length > 0 && displayPathnames?.map((path, index) => {
                const isLast = index === displayPathnames?.length - 1;
                const pathParts = path?.split('/')?.filter(p => p); // Splitting and filtering out empty strings
                const readablePath = pathParts?.length ? pathParts[pathParts?.length - 1] : 0; // Taking the last part for display and limit it to 10 characters // Taking the last part for display
        
                return isLast ? (
                  <span key={path} className="flex">
                      {getFriendlyName(pathParts, readablePath)} {/* Translating each segment */}
                  </span>
                ) : (
                  <React.Fragment key={path}>
                    <Link to={path} className="flex underline m-2">
                      {getFriendlyName(pathParts, readablePath)} {/* Translating each segment */}
                    </Link>
                    <span className='mr-2'>{'>'}</span>
                  </React.Fragment>
                );
            })}
            </div>
        );
    }
};

export default Breadcrumbs;