// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link} from "react-router-dom";

// Common and widgets
import Header from '../../common/header';
import SuperAdminHeader from './superAdminHeader';
import Configs from "../../Configs";
import Bot from '../../common/support/bot'; 
import { useAuth } from '../../common/appContext';
import SectionSeparator from '../../widgets/sectionSeparator';
import ProgressBar from '../../widgets/progressBar';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Editor
import Editor from '../../widgets/editor';

// 3P
import Select from 'react-select';


// ---------------------------------------------------------------------------------------------------
function StyleGuide () {
  const {t, i18n} = useTranslation();

  const employeeID = sessionStorage.getItem('employeeID') ? CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
  const companyID = sessionStorage.getItem('companyID') ? CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
  // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
  const {token} = useAuth();

  const [activity, setActivity] = useState(null);

  // Toaster and Loader variables
  const [isLoading, setIsLoading] = useState(false);
  const [toasterMessageSuccess, setToasterMessageSuccess] = useState({message: t('success'), type: Configs.successToaster});
  const [toasterMessageWarning, setToasterMessageWarning] = useState({message: t('warning'), type: Configs.warningToaster});
  const [toasterMessageError, setToasterMessageError] = useState({message: t('error'), type: Configs.errorToaster});
  const [toasterMessageInfo, setToasterMessageInfo] = useState({message: t('info'), type: Configs.infoToaster});

  // Editor
  const [editorContent, setEditorContent] = useState(null);

  // ---------------------------------------------------------------------------------------------------
  // useEffects
  // ---------------------------------------------------------------------------------------------------
    useEffect (() => {
      // Get information to populate dashboard
    }, []);

  
// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessageSuccess({message: t('success'), type: Configs.successToaster});
  setToasterMessageWarning({message: t('warning'), type: Configs.warningToaster});
  setToasterMessageError({message: t('error'), type: Configs.errorToaster});
  };

// ---------------------------------------------------------------------------------------------------
// Editor methods
// ---------------------------------------------------------------------------------------------------

  
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
          <SuperAdminHeader />
            
          <div className='flex flex-row w-full'>
            <div className="left-big-view">

              <div className="flex flex-col text-left ">

                {/* Title & Subtitle */}
                <div className='flex flex-col items-start text-left space-y-4'>
                  <h1 className='title'>This is the top header</h1>
                  <h2 className='section-title'>This is a subsection title</h2>
                  <h3 className='subsection-title'>This is a subsubsection title</h3>
                  <span className='title-description'>This is a subtitle</span>
                </div>

                <SectionSeparator />
                
                {/* Buttons */}
                <div className='flex flex-col space-y-4'>
                  <h2 className='section-title'>Buttons</h2>
                  <span className='title-description'>Here we show the different buttons we have</span>
                
                  <div className='flex flex-row items-center justify-end space-x-4'>
                    <button className='bg-yellow-200'>Auxiliar</button>
                    <button className='secondary-button'>Secundario</button>
                    <button className='save-button'>Primario</button>
                  </div>

                  <div className='flex flex-row items-center justify-end space-x-4'>
                    <button className='save-button'>+</button>
                    <button className='remove-button'>-</button>
                  </div>
                </div>

                <SectionSeparator />
                
                {/* Data Tables & Pagination */}


                <SectionSeparator />
                
                {/* Tipografía */}
                <div className='flex flex-col'>
                  <h2 className='section-title'>Typography</h2>
                  <span className='title-description'>This is the typography seen across Kincode App</span>
                </div>

                <SectionSeparator />
                
                {/* Color palette */}
                <div className='flex flex-col'>
                  <h2 className='section-title'>Color palette</h2>
                  <span className='title-description'>These are the default colors for Kincode App</span>
                  <div className='flex flex-row items-center justify-start m-4 space-x-4'>
                    <div className='bg-green-200 w-20 h-20 rounded shadow'></div>
                    <div className='bg-[#BFC6FF] w-20 h-20 rounded shadow'></div>
                    <div className='bg-[#FFBF76] w-20 h-20 rounded shadow'></div>
                    <div className='bg-green-400 w-20 h-20 rounded shadow'></div>
                    
                  </div>


                </div>

                <SectionSeparator />

                {/* Forms */}
                <div className='flex flex-col'>
                  <h2 className='section-title'>Forms</h2>
                  <span className='title-description'>This is how forms are seen across Kincode App</span>

                  <div className='flex flex-col space-y-2'>
                    
                    <input type='text' className='input-field' placeholder='This is a text input' />
                    <input type='date' className='input-field' placeholder='This is a text input' />

                    <Select 
                      placeholder='This is a select input' 
                      options={[{value: '1', label: 'Option 1'}, {value: '2', label: 'Option 2'}]}
                      styles={Configs.formDropdownStyle}
                      className='text-sm'
                      />  

                    <div className='flex flex-col items-start w-full input-field'>
                      <Editor setContent={setEditorContent} content={editorContent} initialContent={null} placeholder={t('oneOnOne.meeting.private-notes.placeholder')}/>
                    </div>

                  </div>
                </div>

                <SectionSeparator />
                
                {/* Tooltips and help */}
                <div className='flex flex-col space-y-4'>
                  <h2 className='section-title'>Tooltip</h2>
                  <span className='title-description'>Here we show the different assistive options</span>
                
                  <div className='flex flex-row items-center justify-start space-x-4 text-sm'>
                    <Tooltip content={'This is the content'}>Hover over here</Tooltip>
                  </div>
                  <div className='flex flex-row items-center justify-start space-x-4'>
                    <Loader />
                  </div>
                  <div className='relative flex flex-col items-center justify-center space-y-4'>
                    <Toaster message={toasterMessageSuccess} timeout={1000000}  onClose={closeToast} position="relative"/>
                    <Toaster message={toasterMessageWarning} timeout={1000000}  onClose={closeToast} position="relative"/>
                    <Toaster message={toasterMessageError} timeout={1000000}  onClose={closeToast} position="relative"/>
                    <Toaster message={toasterMessageInfo} timeout={1000000}  onClose={closeToast} position="relative"/>
                  </div>

                  
                </div>

                <SectionSeparator />
                
                {/* Widgets */}
                <div className='flex flex-col space-y-4'>
                  <h2 className='section-title'>Widgets</h2>
                  <span className='title-description'>Here we show the different widgets we have</span>
                
                  <div className='flex flex-col items-center justify-start space-y-4'>
                    {/* Progress Bar */}
                    <div className='flex flex-row items-center w-2/3'>
                      <span className='w-ful text-xs'>Progress Bar:</span>
                      <ProgressBar percentage={50}/>
                    </div>

                    
                  </div>
                  
                </div>

                
              </div>
            </div>

            <div className='right-small-view'>
              <div className='section-title'>This is the right side smaller view (only in desktop)</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default StyleGuide;