// React
import React from 'react';

// 3P
import { t } from 'i18next';

// common
import Configs from '../../Configs';
import ModuleHeader from '../../common/support/moduleHeader';
// Icons
import {IoSettings } from "react-icons/io5";

// ------------------------------------------------------
function SettingsHeader () {
    
    const screens = [
        {view:t('settings'), url:"/settings/", securityLevel:[Configs.securityLevelIC], id:"settings.header.settings"},
        {view:t('settings.company'), url:"/settings/company", securityLevel:[Configs.securityLevelHR], id:"settings.header.company"},
    ];

  // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('settings')} Icon={IoSettings} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default SettingsHeader;