// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Common
import Configs from '../../Configs';
import Header from '../../common/header';
import CareerHeader from './careerHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import DataTable from '../../widgets/dataTable';
import FilterWidget from '../../widgets/data/filterWidget.js';
import KPIStaticCard from '../../widgets/keyIndicatorItemStatic';
import SectionSeparator from '../../widgets/sectionSeparator';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip.js';

// Icons
import { IoIosHelpCircleOutline } from 'react-icons/io';

// ---------------------------------------------------------------------------------------------------
function CareerHR () {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {securityLevel, employeeID} = useAuth();
    const {jobLevellingGuidelinesData,isJobLevellingGuidelinesDataLoading} = useData();
    const {toasterMessage, setToasterMessage} = useData();
    const {companyOrg, setSelectedJobLevellingGuideline, setSelectedProfile} = useData();
  
    const [filteredData, setFilteredData] = useState(null);

    // Reporting variables
    const [companyCareerPathStatus, setCompanyCareerPathStatus] = useState(null);
    const [compliance, setCompliance] = useState(0);
    const [companyCompliance, setCompanyCompliance] = useState(0);

    // Data tables
    let jobLevellingGuidelinesReportColumns = [
      {type:'string',id: 'jobFamily', value: 'jobFamily', label: t('jobFamily'), sort: null, filter: null},
      {type:'string',id: 'role', value: 'role', label: t('role'), sort: null, filter: null},
      {type:'string',id: 'level',value: 'level', label: t('level'), sort: null, filter: null},
      {type:'action',id: 'actions', value: 'actions', label: t('actions'), sort: null, filter: null}
    ];

       // Data tables
       let companyStatusColumns = [
        {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
        {type:'string',id: 'subarea', value: 'subarea', label: t('subarea'), sort: null, filter: null},
        {type:'string',id: 'manager',value: 'manager', label: t('manager'), sort: null, filter: null},
        {type:'string',id: 'employee',value: 'employee', label: t('employee'), sort: null, filter: null},
        {type: 'string', id: 'currentRole', value: 'currentRole', label: t('current-role'), sort: null, filter: null},
        {type: 'string', id: 'nextRole', value: 'nextRole', label: t('next-role'), sort: null, filter: null},
        {type:'date',id: 'lastUpdate',value: 'lastUpdate', label: t('last-update'), sort: null, filter: null},
        {type: 'action', id: 'actions', value: 'actions', label: t('actions'), sort: null, filter: null}
      ];

  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------   
  useEffect (() => {     
      if (!securityLevel?.includes(Configs.securityLevelHR) && !securityLevel?.includes(Configs.securityLevelAdmin) && !securityLevel?.includes(Configs.securityLevelExec)) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.toastTypes.error});
        navigate('/');
      }
      
      if (companyOrg) transformCareerCompanyStatusData (companyOrg);

      }, [companyOrg, jobLevellingGuidelinesData, securityLevel]);

  // ---------------------------------------------------------------------------------------------------
  // Data transformation methods
  // ---------------------------------------------------------------------------------------------------
  const transformCareerCompanyStatusData = (companyOrg) => {
    // Iterate through all employees, and extract if they have a career path status

    setCompanyCareerPathStatus(companyOrg);
    
  }
  
  // ---------------------------------------------------------------------------------------------------
  // Filter handlers
  // ---------------------------------------------------------------------------------------------------  
  const handleFilterChange = (filteredDataInput) => {
    if (Configs.devEnvironment) console.log("[careerHR.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
    setFilteredData(filteredDataInput);

  };

  // ---------------------------------------------------------------------------------------------------
  // Methods
  // ---------------------------------------------------------------------------------------------------
  const viewMethod = (index, row) => {
    if (Configs.devEnvironment) console.log("[careerHR.js][viewMethod()] data: ", row, index);
    setSelectedJobLevellingGuideline(row);

    const auxObject = {
      isEdit: false,
      isViewOnly: true,
      guidelineID: row._id
    };

    const url = '/career/guideline/' + encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(auxObject), Configs.privateKey).toString());

    if (Configs.devEnvironment) console.log("[careerHR.js][viewMethod()] url: ", url);

    navigate(url);

  }

  const editMethod = (index, row) => {
    if (Configs.devEnvironment) console.log("[careerHR.js][editMethod()] data: ", row, index);
    setSelectedJobLevellingGuideline(row);

    const auxObject = {
      isEdit: true,
      isViewOnly: false,
      guidelineID: row._id
    };

    const url = '/career/hr/guideline/edit/' + encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(auxObject), Configs.privateKey).toString());

    if (Configs.devEnvironment) console.log("[careerHR.js][viewMethod()] url: ", url);

    navigate(url);

  }

  const viewEmployeePlan = (index, row) => {
    if (Configs.devEnvironment) console.log("[careerHR.js][viewEmployeePlan()] data: ", row, index);

    const employee = companyOrg?.find(employee => employee?.employeeID === row?.employeeID);

    const params = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
      employee:employee?.name + " " + employee?.surname,
      employeeName: employee?.name,
      employeeSurname: employee?.surname,
      employeeLevel: employee?.level,
      employeeRole: employee?.role,
      employeeArea: employee?.area,
      employeeSubarea: employee?.subarea,
      employeeID: employee?.employeeID,
      employeeProfilePicture: employee?.profilePicture,
      companyID: employee?.companyID,
      managerID: employee?.managerID 
    }),Configs.privateKey).toString());
    
    if (employee?.employeeID === employeeID) {
      setSelectedProfile(null);
      navigate("/career/");
    } else {
      setSelectedProfile(employee);
      navigate("/career/team/" + params);
    }  
  }

  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
    };

  // ---------------------------------------------------------------------------------------------------
  // Page render methods
  // ---------------------------------------------------------------------------------------------------    
    return (
      <React.Fragment>
      <Header />
      < Bot/>

      <div className="main">
        <CareerHeader />
            
        <div className='flex flex-row items-start'>
        <div className="left-big-view">
              

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                      <h2 className="flex text-xl text-left mb-4 ">
                        {" "}
                        <div className='flex flex-col md:flex-row justify-left'>
                          <div className='flex flex-row items-center'>
                            <Tooltip content={t('career.hr.description')}>
                              <span className='section-title'> {t('career.hr.title')} <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span></span>
                            </Tooltip>
                            {/* {i18next.language === 'es' ? 
                              <a href="https://resources.kincode.app/es/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                              </a>
                            : 
                              <a href="https://resources.kincode.app/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                              </a>
                            } */}
                          </div>
                        </div>
                      </h2>
                  </div>

                  {securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelAdmin) || securityLevel?.includes(Configs.securityLevelExec) ? 
                        isJobLevellingGuidelinesDataLoading ? 
                          <Loader />
                        :
                                <div className='flex flex-col w-full space-y-4'>
                                  {/* Job levelling guidelines */}
                                  <div className='flex flex-col w-full space-y-2'>
                                      <div className='flex flex-row items-center justify-between m-2'>
                                              <h3 className='subsection-title'>
                                                {t('career.hr.job-levelling-guidelines')}
                                              </h3> 

                                              <button className='save-button'>
                                                <Link to="guideline/create">
                                                  {t('career.hr.guideline.create.title')}
                                                </Link>
                                              </button>
                                        </div>
                                    {jobLevellingGuidelinesData ? 
                                        securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec) || securityLevel?.includes(Configs.securityLevelAdmin) ?
                                          // HR and Exces
                                          <DataTable data={jobLevellingGuidelinesData} columnNames={jobLevellingGuidelinesReportColumns}
                                            viewMethod={viewMethod}
                                            editMethod={editMethod}
                                            deleteMethod={null}
                                            />                     

                                        :
                                          // Manager view
                                            <DataTable data={jobLevellingGuidelinesData} columnNames={jobLevellingGuidelinesReportColumns}
                                              zerosForDashes={true}
                                            />
                                        
                                    : 
                                      <div className='flex flex-col items-center w-full'>
                                        <span className='title-description italic'>
                                          📈  {t('career.hr.no-job-levelling-guidelines-data') + " "}
                                          <Link to="guideline/create" className='text-blue-500 hover:text-blue-700 underline'>
                                            {t('career.hr.no-job-levelling-guidelines-data.cta')}
                                          </Link>
                                        </span>
                                      </div>
                                    }
                                  </div>

                                  <SectionSeparator />


                                  {/* Company career path status */}
                                  <div className='flex flex-col w-full'>
                                    <div className='flex flex-row items-center justify-between m-2'>
                                            <h3 className='subsection-title'>
                                              {t('career.hr.company-status')}
                                            </h3> 
                                      </div>
                                      {companyOrg ? 
                                          <DataTable data={companyCareerPathStatus} columnNames={companyStatusColumns}
                                            viewMethod={viewEmployeePlan}
                                            />
                                      :
                                        <div className='flex flex-col items-center w-full'>
                                          <span className='title-description italic'>
                                            📈  {t('career.hr.no-company-status-data') + " "}
                                          </span>
                                        </div>
                                      }
                                    
                                  </div>
                                </div>
                  :

                  <div>
                    No permissions
                  </div>
               
            
            }
            </div>
            <div className='right-small-view'>
              {/* KPI */}
              <div className='flex flex-col lg:flex-row items-center lg:justify-center'>
                {5 > 0 ? 
                  <KPIStaticCard graph={Configs.KPIGraphAccelerator} indicator={t('career.hr.kpi.compliance')} 
                    data={50} benchmark={companyCompliance} type="Percentage" 
                    hint={t('career.hr.kpi.compliance.description')}/> 
                : 
                  null 
                }
              </div>              

            </div>  
        </div>
        </div>

      </React.Fragment>
    );
}

export default CareerHR;