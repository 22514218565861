// React
import React, {useLocation, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";

// Common & widgets
import Header from '../../common/header';
import RecruitmentHeader from './recruitmentHeader';
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import SectionSeparator from '../../widgets/sectionSeparator';

// 3P
import i18next, { t } from 'i18next';
import CryptoJS from 'crypto-js';

// Toaster and Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';


function RecruitmentJobs () {
    // Navigate
    const navigate = useNavigate();

    // Employee Info
    const employeeID = CryptoJS.AES.decrypt(sessionStorage.getItem('employeeID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    const companyID = CryptoJS.AES.decrypt(sessionStorage.getItem('companyID'), Configs.privateKey).toString(CryptoJS.enc.Utf8);
    const companyInfo = sessionStorage.getItem('companyInfo') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('companyInfo'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    // const token = sessionStorage.getItem('token') ? CryptoJS.AES.decrypt(sessionStorage.getItem('token'), Configs.privateKey).toString(CryptoJS.enc.Utf8) : null;
    const { token } = useAuth();

    // Toaster & Loader
    const [toasterMessage, setToasterMessage] = useState(null);
    const [isJobsLoading, setIsJobsLoading] = useState(false);

    // Mock-up data
    const jobsList = [
      { hiringManagerID:"1231245", hiringManager: "Jhonny Depp", role: "Sr. Product Manager", level:"6", jobID: "123412345", date:"09-06-2012", time:"10:00", status:"Draft"},
      { hiringManagerID:"1231245", hiringManager: "Will Smith", role: "Principal Product Manager", level:"7", jobID: "123412345", date:"09-06-2012", time:"10:00", status:"Pending Approval"},
      { hiringManagerID:"1231245", hiringManager: "Marlon Brando", role: "Team Leader", level:"5", jobID: "123412345", date:"09-06-2012", time:"10:00", status:"Open", pipeline:[
        {name:"Applied", count: 10},
        {name:"Phone Screen", count: 5},
        {name:"Loop", count: 3},
        {name:"Offer", count: 0},
        {name:"Hired", count: 0},
      ]},
      { hiringManagerID:"1231245", hiringManager: "Jhonny Depp", role: "Sales Inter", level:"3", jobID: "123412345", date:"09-06-2012", time:"10:00", status:"On Hold"},
      { hiringManagerID:"1231245", hiringManager: "Silvestre Stalone", role: "Key Account Manager", level:"5", jobID: "123412345", date:"09-06-2012", time:"10:00", status:"Filled"},
      { hiringManagerID:"1231245", hiringManager: "Arnold Schwarznegger", role: "Customer Specialist", level:"4", jobID: "123412345", date:"09-06-2012", time:"10:00", status:"Filled"},
      { hiringManagerID:"1231245", hiringManager: "Michael B. Jordan", role: "Product Manager", level:"5", jobID: "123412345", date:"09-06-2012", time:"10:00", status:"Deleted"},
    ]

// ---------------------------------------------------------------------------------------------------
// Toaster methods
// ---------------------------------------------------------------------------------------------------    
const closeToast = () => {
  setToasterMessage(null);
  };
    
// ----------------------------------------------------------------------------
// Render method
// ---------------------------------------------------------------------------- 
return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
        <RecruitmentHeader />

        <div className='flex flex-row items-start'>
          <div className="left-big-view">



        {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}

          <div className="flex flex-col mt-2 md:ml-2 m-1">
          {/* Title */}
              <h2 className="flex text-xl mb-4 ">
                <div className='flex flex-col md:flex-row justify-left'>
                  <Tooltip content={t('recruitment.jobs.description')} >
                    <div className='section-title'>
                      {t('recruitment.jobs.title')}
                    </div>
                  </Tooltip>
                </div>
              </h2>

              {/* HR Toolbar */}
              <div className="flex flex-row items-center lg:justify-end justify-center lg:m-4 space-x-4 ">
                <button className='bg-green-200 save-button lg:mb-0 mb-4'>{t('recruitment.jobs.create-job')}</button>             
              </div>

              {/* Table */}
              {isJobsLoading ? 
                <Loader />
                :
                
                jobsList.length > 0 ?

                <div className='flex flex-col space-y-4 lg:m-4'>

                  {jobsList.map((job, index) => (
                    <div className="flex flex-col w-full items-center justify-between bg-white border rounded-lg shadow-xl px-8 py-2"> 
                        {/* Job title, role and Hiring manager */}
                        <div className='flex flex-row w-full items-start justify-between'>
                          <div className='flex flex-col lg:w-1/2 items-start'>
                            <div className='flex flex-row items-center justify-start w-full space-x-4'>
                              <div className='flex flex-row items-center'>
                                {/* Job role (MANDATORY) */}
                                <span className='flex text-lg font-bold'>
                                  {job.role}
                                </span>
                                {/* Job level (OPTIONAL) */}
                                {job.level ? 
                                  <span className='flex text-sm ml-2 text-gray-600'>
                                    ({t('level') + ": " +job.level})
                                  </span>
                                :
                                  null
                                }
                              </div>
                              <div className='flex text-sm text-gray-800'>
                                [{t('hiring-manager') + ": "+ job.hiringManager}]
                              </div>
                            </div>
                            
                            {/* Date & Time of opening */}
                            <div className='flex flex-row w-full items-center justify-start text-xs mt-2 text-gray-600'>
                              <div className='flex'>
                                {job.date + " / " +  job.time}
                              </div>
                            </div>
                          </div>

                          {/* Status */}
                          <div className='flex flex-col lg:w-1/2 items-end'>
                            <div className='flex flex-row items-center justify-end'>
                              {job.status === "Draft" ?
                                <button className='rounded-lg px-4 py-2 text-sm shadow-xl bg-gray-300'>{job.status}</button> 
                                :
                                job.status === "Pending Approval" ?
                                <button className='rounded-lg px-4 py-2 text-sm shadow-xl bg-gray-100'>{job.status}</button> 
                                :
                                job.status === "On Hold" ?
                                <button className='rounded-lg px-4 py-2 text-sm shadow-xl bg-orange-300'>{job.status}</button> 
                                :
                                job.status === "Deleted" ?
                                <button className='rounded-lg px-4 py-2 text-sm shadow-xl bg-red-200'>{job.status}</button> 
                                :
                                job.status === "Open" ?
                                <button className='rounded-lg px-4 py-2 text-sm shadow-xl bg-green-200 animate-pulse'>{job.status}</button> 
                                :
                                job.status === "Filled" ?
                                <button className='rounded-lg px-4 py-2 text-sm shadow-xl bg-green-400'>{job.status}</button> 
                                :
                                <button className='rounded-lg px-4 py-2 text-sm shadow-xl bg-gray-200'>{job.status}</button> 
                            }
                              
                            </div>
                          </div>

                        </div>

                        {/* Pipeline */}
                        <div className='flex flex-row lg:w-1/2 items-start  mt-4 '>
                          {job.pipeline?.length > 0 ?
                            
                            job.pipeline.map((stage, index) => (
                              index === job?.pipeline?.length-1 ?
                                    // Last stage
                                    <div className='flex flex-row w-1/2 items-center justify-center space-x-4'>
                                        <div className='flex flex-col w-1/2 items-center justify-center text-sm space-y-2 '>
                                            <span className='text-xl'>{stage.count}</span>
                                            <span className='text-xs'>{stage.name}</span>
                                        </div>
                                    </div>
                                  :
                                    // Other stages
                                    <div className='flex flex-row w-1/2 items-start justify-center space-x-4'>
                                        <div className='flex flex-col w-1/2 items-center justify-center text-sm space-y-2 '>
                                              <span className='text-xl'>{stage.count}</span>
                                              <span className='text-xs'>{stage.name}</span>
                                        </div>
                                        <div className='flex flex-col w-1/2 items-center justify-center text-2xl font-light text-gray-400 '>
                                            |
                                        </div>
                                    </div>
                              ))
                          :
                            // Empty pipeline
                            null
                          }

                        </div>
                    </div>
                  ))}
                </div>  
              
              :
                // No jobs - EMPTY
                <div className='flex flex-col items-center justify-center'>
                  <span className='italic title-description'>
                    {t('recruitment.jobs.empty')}
                  </span>
                </div>
                
                }



          </div>


          <SectionSeparator />

          </div>
          <div className='right-small-view'>

          </div>
         </div>
         
        </div>
      </React.Fragment>
    );
}

export default RecruitmentJobs;