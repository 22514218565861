import React, { useEffect} from 'react';
import { useState } from 'react';

import Configs from '../../Configs';

// Props:
// { message, timeout = 5000, onClose }
function Toaster(props) {

    const [visible, setVisible] = useState(true);
    const [progress, setProgress] = useState(100);
  
// ---------------------------------------------------------------------------------------------------
// After render methods
// ---------------------------------------------------------------------------------------------------  

    useEffect(() => {
      // if (Configs.devEnvironment) console.log ("[toaster.js][useEffect()] props: " , props);
      const interval = props.timeout / 100;

      const timer = setTimeout(() => {
        setVisible(false);
        props.onClose();
      }, props.timeout);

      // Update the progress bar every interval
      const intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress <= 0) {
            clearInterval(intervalId);
            return 0;
          }
          return oldProgress - 1;
        });
      }, interval);
  
      return () => {
        clearTimeout(timer);
        clearInterval(intervalId);
      };
    }, [props.onClose, props.timeout]);

// ---------------------------------------------------------------------------------------------------
// Render method
// ---------------------------------------------------------------------------------------------------  
    return (
      
      <div 
      className={(props?.position === "relative" ? '  ' : ' fixed top-0 lg:top-20 ') + 
      ( visible ? (' block w-2/3  justify-center mt-4 text-white py-2 px-4 rounded-lg z-50 shadow-xl w-2/3 transition-all duration-300 ease-in-out opacity-100  ' + props.message.type) 
      : ' opacity-0 pointer-events-none ')}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ transitionDelay: '100ms' }} // Delay appearance
      >
        <div className="flex flex-row text-white justify-between">
          <span>{props.message.message}</span>
          <span className='font-bold hover:text-gray-200 cursor-pointer' onClick={props?.onClose}>x</span>
        </div>

        {/* Progress bar */}
        {
          props.message.type === Configs.successToaster ?
            <div className="w-full bg-gray-100 h-2 rounded-full mt-2 -mb-2">
              <div className={' h-2 rounded-full ' + Configs.successToasterDark}  style={{ width: `${progress}%` }}></div>
            </div>
          :
          props.message.type === Configs.warningToaster ?
            <div className="w-full bg-gray-100 h-2 rounded-full mt-2 -mb-2">
              <div className={' h-2 rounded-full ' + Configs.warningToasterDark}  style={{ width: `${progress}%` }}></div>
            </div>
          :
          props.message.type === Configs.errorToaster ?
            <div className="w-full bg-gray-100 h-2 rounded-full mt-2 -mb-2">
              <div className={' h-2 rounded-full ' + Configs.errorToasterDark}  style={{ width: `${progress}%` }}></div>
            </div>
          :
          <div className="w-full bg-gray-100 h-2 rounded-full mt-2 -mb-2">
            <div className="bg-blue-400 h-2 rounded-full" style={{ width: `${progress}%` }}></div>
          </div>

        }
        
      </div>
    );
  }
  
  export default Toaster;