// React
import React, { useState} from 'react';

// Tiptap
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

// Configs
import Configs from '../Configs';
import './editor/editorStyles.css';

// 3P
import { useTranslation } from 'react-i18next';

// Icons
import { FaBold, FaCode, FaEraser, FaHeading, FaItalic, FaList, FaListOl, FaParagraph, FaQuoteLeft, FaRedo, FaStrikethrough, FaUndo } from 'react-icons/fa';
import {MdHorizontalRule} from 'react-icons/md';
import {BiCodeBlock} from 'react-icons/bi';


// ------------------------------------------------------
const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <>
      <div className="flex flex-wrap lg:flex-row justify-between items-center">
        <button
          onClick={(event) => {
            event?.preventDefault();
            editor?.chain().focus().toggleBold().run();
          }}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={
            " editor-buttons " +
            (editor?.isActive("bold") ? " is-active bg-gray-600 " : "")
          }
        >
          <FaBold />
        </button>
        <button
          onClick={(event) => {
            event?.preventDefault();
            editor?.chain().focus().toggleItalic().run();
          }}
          disabled={!editor?.can().chain().focus().toggleItalic().run()}
          className={
            " editor-buttons " +
            (editor?.isActive("italic") ? " is-active bg-gray-600 " : "")
          }
        >
          <FaItalic />
        </button>
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleStrike().run();
          }}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={
            " editor-buttons " +
            (editor.isActive("strike") ? " is-active bg-gray-600 " : "")
          }
        >
          <FaStrikethrough />
        </button>
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleCode().run();
          }}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={
            " editor-buttons " +
            (editor.isActive("code") ? " is-active bg-gray-600 " : "")
          }
        >
          <FaCode />
        </button>
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().unsetAllMarks().run();
          }}
          className={" editor-buttons " }
        >
          <FaEraser />
        </button>
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().setParagraph().run();
          }}
          className={" editor-buttons "}
        >
          <FaParagraph />
        </button>
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleHeading({ level: 1 }).run();
          }}
          className={
            " editor-buttons " +
            (editor.isActive("heading", { level: 1 })
              ? " is-active bg-gray-600 "
              : "")
          }
        >
          <FaHeading />
        </button>
        {/* <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        h2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        h3
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
        h4
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
      >
        h5
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
      >
        h6
      </button> */}
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
          className={
            " editor-buttons " +
            (editor.isActive("bulletList") ? " is-active bg-gray-600 " : "")
          }
        >
          <FaList />
        </button>
        <button
          onClick={(event) => {event.preventDefault() 
            editor.chain().focus().toggleOrderedList().run()}}
          className={" editor-buttons "}
        >
          <FaListOl />
        </button>
        <button
          onClick={(event) => {event.preventDefault() 
             editor.chain().focus().toggleCodeBlock().run()}}
          className={" editor-buttons " }
        >
          <BiCodeBlock />
        </button>
        <button
          onClick={(event) => {event.preventDefault()  
            editor.chain().focus().toggleBlockquote().run()}}
          className={" editor-buttons " + (editor.isActive("blockquote") ? "is-active bg-gray-600 " : "")}
        >
          <FaQuoteLeft />
        </button>
        <button
          onClick={(event) => {event.preventDefault() 
           editor.chain().focus().setHorizontalRule().run()}}
          className={" editor-buttons " +
            (editor.isActive("bulletList") ? " is-active bg-gray-600 " : "")
          }
        >
          <MdHorizontalRule />
        </button>
        <button
          onClick={(event) => {event.preventDefault() 
             editor.chain().focus().undo().run()}}
          disabled={!editor.can().chain().focus().undo().run()}
          className={ " editor-buttons "}
        >
          <FaUndo />
        </button>
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().redo().run();
          }}
          disabled={!editor.can().chain().focus().redo().run()}
          className={" editor-buttons "}
        >
          <FaRedo />
        </button>
      </div>
    </>
  );
}

// ------------------------------------------------------
export default (props) => {
const {t, i18n} = useTranslation();
  // to allow selection of text the first time
  const [firstFocus, setFirstFocus] = useState(true);


  // if (Configs.devEnvironment) console.log ("[editor.js][default()] Initial Content: ", props.initialContent);
  // if (Configs.devEnvironment) console.log ("[editor.js][default()] Content: ", props.content);
  // if (Configs.devEnvironment) console.log ("[editor.js][default()] Placeholder: ", props.placeholder);
  

  // Content variable that is not updated, unless the editor is updated - it will be passed on to the props when onBlur
  var content = props.content ? props.content : props.initialContent ? props.initialContent : null;

  // state variable to show / not-show the menu bar
  const [showMenuBar, setShowMenuBar] = useState(false);



  const editor = useEditor({
    content: ((props.initialContent !== undefined) ? props.initialContent : (props.content !== undefined ? props.content : null)),

    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none p-4 flex-wrap ',
      },
    },
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: (props.placeholder !== undefined ? props.placeholder : t('oneOnOne.meeting.content.placeholder')),
        
      }),
      Table.configure({
        resizable: true,
        // caption: t('oneOnOne.meeting.content.table.caption'),
      }),
      TableRow,
      TableCell,
      TableHeader,
    ],
    onUpdate: ({editor}) => {
      // Get editor c ontent into the variable
        content = editor.getHTML();
    },

    onFocus({ editor, event }) {
      // Allow to select all text
      if (firstFocus) {
        editor.chain().focus().selectAll().run();
        setFirstFocus(false);
      }

      // Show menu bar when editor is focused
      setShowMenuBar(true);

    },
    onBlur({ editor, event }) {
      // The editor isn’t focused anymore.
      
      // Update state content depending on Editor type
      if (props.editorType === "performanceReviewFeedback") {
        // It is the editor present in performance review feedback
        // attributes: answer, type (strength, growth), index
        props.setContent(editor.getHTML(), props.type, props.index);
      } else if (props.editorType === "valuesSelectorWithComments" ) {
        // It is the editor present in performance review feedback
        // attributes: answer, type (strength, growth), index
        props.setContent(editor.getHTML(), props.index);
      } else {  
        props.setContent(editor.getHTML());
      }
    },
  }, [props.initialContent]);

  // // Set content after rendering - taking from props
  // useEffect(() => {
  //     const contentAfterLoad = (props.initialContent && !props.content ? props.initialContent : props.content);
  //     editor?.commands?.setContent(contentAfterLoad);

  // }, [props.initialContent, props.content]);

  return (
    <React.Fragment>
        {showMenuBar ? <MenuBar editor={editor} /> : null}
        <EditorContent className="mt-2 mb-2 w-full" editor={editor} id="editor" />
    </React.Fragment>
  )
}