// React
import React from 'react';

// 3P
import { t } from 'i18next';

// Common
import Configs from '../Configs';
import ModuleHeader from './support/moduleHeader';

// Icons
import { GiPalmTree } from "react-icons/gi";

// -----------------------------------------------------------------
function DaysOffHeader () {    
    const screens = [
        {view:t('time-off.header.home'), url:"/timeoff/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR], id:"time-off.header.home"},
        {view:t('time-off.header.team'), url:"/timeoff/team/", securityLevel:[Configs.securityLevelManager, Configs.securityLevelHR], id:"time-off.header.team"},
        {view:t('performance.header.HR'), url: "/timeoff/hr/", securityLevel:[Configs.securityLevelHR], id: "performance.header.HR"},
    ];

    // -------------------------------------------------------------------
    // Render
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <ModuleHeader subsections={screens} title={t('Time-off')} Icon={GiPalmTree} breadcrumbs={false}/>
        </React.Fragment>
    );
}

export default DaysOffHeader;