import React, {useState, useEffect} from "react";
import Configs from '../Configs';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {AiOutlineInfoCircle} from 'react-icons/ai';

import NoneditableEditor from './noneditableEditor';


// ------------------------------------------------------------------------------------------
function UserListItemCalibration({
  initials,
  employeeID,
  user,
  role,
  level,
  area,
  subarea,
  profilePicture,
  readOnly,
  index,
  performanceRate,
  potentialRate,
  // functions
  calculateCalibrationResults,
}) {
// Values that will be used 
// Translation object
const {t, i18n} = useTranslation();


// ------------------------------------------------------------------------------------------
// Page start methods
// ------------------------------------------------------------------------------------------
useEffect (() => {
        // Get information to populate dashboard
        calculateCalibrationResults();
}, []);

// ------------------------------------------------------------------------------------------
// Form management methods
// ------------------------------------------------------------------------------------------
async function changedValue (e, index, type) {
  e.preventDefault();
  // Create object to submit
  console.log ("[userListItemCalibration.js][changedValue()][calculateCalibrationResults()]  - value:", employeeID, type, parseInt(e.target.value), index);
  calculateCalibrationResults (employeeID, type, parseInt(e.target.value));
  
}


// ------------------------------------------------------------------------------------------
// Render methods
// ------------------------------------------------------------------------------------------
  return (
    <div className='flex flex-col md:flex-row m-1 border  py-8 lg:py-6 lg:px-4 items-center justify-between w-full bg-white rounded-lg shadow'>
        
        <div className="flex flex-row justify-left">
            { profilePicture ?
              <img className="rounded-full h-[60px] w-[60px] mr-8 border" src={profilePicture} alt={user + " profile picture"} />
            :
              <div className="flex h-[60px] w-[60px] p-6 mr-8 rounded bg-gray-100 items-center justify-center">
                {initials}
              </div>
            }

            <div className="flex flex-col text-left">  
                <span className="font-bold">{user}</span>

                <div className="flex flex-col text-sm">
                  <span className="text-gray-600">{role ? role : null}</span>
                  <p></p>
                  <span className="text-gray-600">{level ? t('level') + ": " + level : null}</span>
                  <span className="text-gray-600">{area ? t('area') + ": " + area : null} {subarea ? " || " + t('subarea') + ": " + subarea : null} </span>
                </div>
            
            </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-center items-center px-4">
          {/* Performance */}
          <div className="flex flex-col lg:ml-8">
                <div className='flex flex-col items-center'>
                  <div className="flex flex-row items-center justify-center border-b w-full text-center pb-2 mb-2">
                  {t("calibration.team.performance")}
                    <div className='flex group ml-2 '>
                                <AiOutlineInfoCircle />

                                <div className="hidden group-hover:block cursor-pointer text-left absolute bg-gray-100 p-1 rounded items-center justify-center shadow-2xl w-auto h-auto z-50 right-5">

                                  <NoneditableEditor 
                                      content={t('calibration.team.performance.description')}
                                      />

                                 </div>
                            </div>
                  </div>

                  <div className='flex flex-row space-x-1 items-end text-l mb-0 m-2 items-center text-center text-xs lg:text-sm'>
                      <button className={" flex px-4 py-2 bg-green-100 rounded-full shadow " + (!readOnly ? ' hover:border-4 hover:border-gray-300 ' : null) +  ((performanceRate?.toString() === "1") ? " border-4 border-black" :
                          null)}
                      value={1} onClick={(e) => changedValue(e, index, "performance")} disabled = {readOnly ? true : false}> 1 </button>
                      <button className={' flex px-4 py-2 bg-green-200 rounded-full shadow ' + (!readOnly ? ' hover:border-4 hover:border-gray-300 ' : null) + ((performanceRate?.toString() === "2") ? " border-4 border-black" :
                          null)}                            
                          value={2} onClick={(e) => changedValue(e, index, "performance")} disabled = {readOnly ? true : false}> 2 </button>
                      <button className={" flex px-4 py-2 bg-green-300 rounded-full shadow " + (!readOnly ? ' hover:border-4 hover:border-gray-300 ' : null) + ((performanceRate?.toString() === "3") ? " border-4 border-black" :
                          null)}                            
                          value={3} onClick={(e) => changedValue(e, index, "performance")} disabled = {readOnly ? true : false}> 3 </button>
                      <button className={" flex px-4 py-2 bg-green-400 rounded-full shadow " + (!readOnly ? ' hover:border-4 hover:border-gray-300 ' : null) + ((performanceRate?.toString() === "4") ? " border-4 border-black" :
                          null)}                            
                          value={4} onClick={(e) => changedValue(e, index, "performance")} disabled = {readOnly ? true : false}> 4 </button>
                      <button className={" flex px-4 py-2 bg-green-500 rounded-full shadow " + (!readOnly ? ' hover:border-4 hover:border-gray-300 ' : null )+ ((performanceRate?.toString() === "5") ? " border-4 border-black" :
                          null)}                            
                          value={5} onClick={(e) => changedValue(e, index, "performance")} disabled = {readOnly ? true : false}> 5 </button>
                  </div>
                </div>
              

          </div>
          {/* Potential */}
          <div className="flex flex-col lg:ml-8">
                <div className='flex flex-col items-center'>
                   <div className="flex flex-row items-center justify-center border-b w-full text-center pb-2 mb-2">
                   {t("calibration.team.potential")}
                    <div className='flex group ml-2 '>
                    

                                <AiOutlineInfoCircle />

                                <div className="hidden group-hover:block text-left cursor-pointer absolute bg-gray-100 p-1 rounded items-center justify-center shadow-2xl w-auto h-auto z-50 right-5 ">
                                  <NoneditableEditor
                                      content={t('calibration.team.potential.description')}
                                      />
                                 </div>
                            </div>
                  </div>

                  <div className='flex flex-row space-x-1 items-end text-l mb-0 m-2 items-center text-center text-xs lg:text-sm'>
                      <button className={(!readOnly ? ' hover:border-4 hover:border-gray-300 ' : null) + (potentialRate?.toString() === "1" ? " border-4 border-black " : null ) + " flex px-4 py-2 bg-green-100 rounded-full shadow "}
                      value={1} onClick={(e) => changedValue(e, index, "potential")} disabled = {readOnly ? true : false}> 1 </button>
                      <button className={(!readOnly ? ' hover:border-4 hover:border-gray-300 ' : null) + (potentialRate?.toString() === "2" ? " border-4 border-black " : null ) + " flex px-4 py-2 bg-green-100 rounded-full shadow "}                            
                          value={2} onClick={(e) => changedValue(e, index, "potential")} disabled = {readOnly ? true : false}> 2 </button>
                      <button className={" flex px-4 py-2 bg-green-300 rounded-full shadow " + (!readOnly ? ' hover:border-4 hover:border-gray-300 ' : " " )+  ((potentialRate?.toString() === "3") ? " border-4 border-black " :
                          " ")}                            
                          value={3} onClick={(e) => changedValue(e, index, "potential")} disabled = {readOnly ? true : false}> 3 </button>
                      <button className={" flex px-4 py-2 bg-green-400 rounded-full shadow " + (!readOnly ? ' hover:border-4 hover:border-gray-300 ' : " " )+  ((potentialRate?.toString() === "4") ? " border-4 border-black " :
                          " ")}                            
                          value={4} onClick={(e) => changedValue(e, index, "potential")} disabled = {readOnly ? true : false}> 4 </button>
                      <button className={" flex px-4 py-2 bg-green-500 rounded-full shadow " + (!readOnly ? ' hover:border-4 hover:border-gray-300 ' : " " )+  ((potentialRate?.toString() === "5") ? " border-4 border-black " :
                          " ")}                            
                          value={5} onClick={(e) => changedValue(e, index, "potential")} disabled = {readOnly ? true : false}> 5 </button>
                  </div>
                </div>
           
          </div>
        </div>

    </div>

  );
}

export default UserListItemCalibration;