// React
import React, {useState, useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";

// 3P
import CryptoJS from 'crypto-js';
import { t } from 'i18next';
import {XYPlot, XAxis,YAxis, VerticalGridLines, HorizontalGridLines, MarkSeries, LabelSeries, Hint} from 'react-vis';
import Select from 'react-select';

// Graph styles
import '../../../node_modules/react-vis/dist/style.css';

// Header
import CalibrationHeader from './calibrationHeader';
import Header from '../../common/header';

// Commong and widgets
import Configs from '../../Configs';
import Bot from '../../common/support/bot';
import SectionSeparator from '../../widgets/sectionSeparator';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import FilterWidget from '../../widgets/data/filterWidget';
import DataTable from '../../widgets/dataTable';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import {AiOutlineFilter} from 'react-icons/ai';
import {IoIosHelpCircleOutline} from 'react-icons/io';

// ---------------------------------------------------------------------------------------------------
function TalentMap () {
    // To pass location to the Header
    const location = useLocation();
    const currentScreen = location.pathname;

    const {companyID, employeeID, token, securityLevel} = useAuth();
    const {teamDirects, isDirectsLoading, teamOrg, isOrgLoading, companyOrg, isCompanyLoading, companyPreferences} = useData();
    const {calibrationCampaign, isCalibrationCampaignLoading, getCalibrationData, liveCalibrationCampaign, setLiveCalibrationCampaign} = useData();
    const {calibrationCampaignHistory, getCalibrationHistory, isCalibrationCampaignHistoryLoading} = useData();
    const {toasterMessage, setToasterMessage} = useData();
    
    const [calibrationCampaignsHistory, setCalibrationCampaignsHistory] = useState(sessionStorage.getItem('calibrationCampaignsHistory') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('calibrationCampaignsHistory'), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null);

    // Toaster & Loader
    const [isLoading, setIsLoading] = useState(false);
    const [showHint, setShowHint] = useState(false);

    // Frontend messages
    // x Performance
    // y Potential
    const myData = [
        {x: 4, y: 5, xOffset:5, yOffset:5, employee: "Name Surname"},
        {x: 5, y: 4, xOffset:5, yOffset:5, employee: "Name Surname"},
        {x: 3, y: 3, xOffset:5, yOffset:5, employee: "Name Surname"},
        {x: 1, y: 2, xOffset:5, yOffset:5, employee: "Name Surname"},
        {x: 4, y: 3, xOffset:5, yOffset:5, employee: "Name Surname"}
      ]

    // Create an array of objects of the form {x: x, y: y, xOffset: xOffset, yOffset: yOffset, emoji: emoji}
    // The offset is to move the emoji a bit to the left and up, so it appears in the corner of each box
    // const emojiData = [
    //     {x: 0.1, y: 1.3, emoji: "⚠️"},
    //     {x: 1.8, y: 1.3, emoji: "🔒"},
    //     {x: 3.7, y: 1.3, emoji: "🔥"},
    //     {x: 0.1, y: 3.2, emoji: "⏳ "},
    //     {x: 1.8, y: 3.2, emoji: "🔵 "},
    //     {x: 3.7, y: 3.2, emoji: "🚀"},
    //     {x: 0.1, y: 4.9, emoji: "🌱"},
    //     {x: 1.8, y: 4.9, emoji: "💎"},
    //     {x: 3.7, y: 4.9, emoji: "🌟 "},
    // ];
    const valuesData = [
      {x: 2.0, y: 2.0, emoji: "⚠️", value: 1},
      {x: 4.0, y: 2.0, emoji: "🔒", value: 2},
      {x: 4.9, y: 2.0, emoji: "🔥", value: 2},
      {x: 2.0, y: 4.0, emoji: "⏳ ", value: 3},
      {x: 4.3, y: 4.0, emoji: "🔵 ", value: 4},
      {x: 5.0, y: 4.0, emoji: "🚀", value: 4},
      {x: 2.0, y: 4.8, emoji: "🌱", value: 2},
      {x: 4.3, y: 4.8, emoji: "💎", value: 2},
      {x: 4.9, y: 4.8, emoji: "🌟 ", value: 1},
  ];

  const emojiData = [
    {x: 1.9, y: 1.6, emoji: "⚠️", value: 1},
    {x: 4.1, y: 1.6, emoji: "🔒", value: 2},
    {x: 5.0, y: 1.6, emoji: "🔥", value: 2},
    {x: 1.5, y: 4.0, emoji: "⏳ ", value: 3},
    {x: 4.0, y: 4.0, emoji: "🔵 ", value: 4},
    {x: 4.8, y: 4.0, emoji: "🚀", value: 4},
    {x: 1.5, y: 4.8, emoji: "🌱", value: 2},
    {x: 4.0, y: 4.8, emoji: "💎", value: 2},
    {x: 4.8, y: 4.8, emoji: "🌟 ", value: 1},
];

    const quadrantExplanation = [
        {emoji: "⚠️", name: t('calibration.map.quadrant-1.name') , explanation:t('calibration.map.quadrant-1.explanation') , actionPlan:t('calibration.map.quadrant-1.action-plan') },
        {emoji: "🔒", name: t('calibration.map.quadrant-2.name') , explanation:t('calibration.map.quadrant-2.explanation') , actionPlan:t('calibration.map.quadrant-2.action-plan') },
        {emoji: "🔥", name: t('calibration.map.quadrant-3.name') , explanation:t('calibration.map.quadrant-3.explanation') , actionPlan:t('calibration.map.quadrant-3.action-plan') },
        {emoji: "⏳", name: t('calibration.map.quadrant-4.name') , explanation:t('calibration.map.quadrant-4.explanation') , actionPlan:t('calibration.map.quadrant-4.action-plan') },
        {emoji: "🔵", name: t('calibration.map.quadrant-5.name') , explanation:t('calibration.map.quadrant-5.explanation') , actionPlan:t('calibration.map.quadrant-5.action-plan') },
        {emoji: "🚀", name: t('calibration.map.quadrant-6.name') , explanation:t('calibration.map.quadrant-6.explanation') , actionPlan:t('calibration.map.quadrant-6.action-plan') },
        {emoji: "🌱", name: t('calibration.map.quadrant-7.name') , explanation:t('calibration.map.quadrant-7.explanation') , actionPlan:t('calibration.map.quadrant-7.action-plan') },
        {emoji: "💎", name: t('calibration.map.quadrant-8.name') , explanation:t('calibration.map.quadrant-8.explanation') , actionPlan:t('calibration.map.quadrant-8.action-plan') },
        {emoji: "🌟", name: t('calibration.map.quadrant-9.name') , explanation:t('calibration.map.quadrant-9.explanation') , actionPlan:t('calibration.map.quadrant-9.action-plan') },
    ]
      const [mapData, setMapData] = useState(myData);
      const [labelData, setLabelData] = useState(myData);

      const HEIGHT = 300;
      const WIDTH= 300;
      // OLD values
      // const HEIGHT = 500;
      // const WIDTH= 500;

      const [showLabelSeries, setShowLabelSeries] = useState(false);

    // Team filtering
    const [filteredData, setFilteredData] = useState(null);

    // Data tables
    let calibrationTalentMapColumns = [
      {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
      {type:'string',id: 'subarea', value: 'subarea', label: t('subarea'), sort: null, filter: null},
      {type:'string',id: 'manager',value: 'manager', label: t('manager'), sort: null, filter: null},
      {type:'string',id: 'employee',value: 'employee', label: t('employee'), sort: null, filter: null},
      {type: 'string', id: 'performance', value: 'performance', label: t('calibration.team.performance'), sort: null, filter: null},
      {type: 'string', id: 'potential', value: 'potential', label: t('calibration.team.potential'), sort: null, filter: null},
      {type:'date',id: 'lastUpdate',value: 'lastUpdate', label: t('calibration.hr.last-calibration'), sort: null, filter: null},
      {type: 'action', id: 'actions', value: 'actions', label: t('actions'), sort: null, filter: null}
    ];

    if (Configs.devEnvironment) console.log ("[calibrationTalentMap.js][main()] calibrationCampaignHistory: ", calibrationCampaignHistory);

  // --------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------    


    // Methods to load when page is loaded
    useEffect (() => {
        // Get Calibration Campaigns History for employee
        if (calibrationCampaignHistory) updateFrontEndData(calibrationCampaignHistory[0]?.calibrations);

        // Depending on the "campaign" selected, get the data


        // Depending on the role, select the filter:
        if (securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) {
          if (companyOrg) {
            setFilteredData(companyOrg);
          }
        } else {
          if (teamDirects) {
            setFilteredData(teamDirects);
          }
        }


      }, []);

  

    // ---------------------------------------------------------------------------------------------------
    // Frontend control methods
    // ---------------------------------------------------------------------------------------------------
    function updateFrontEndData (calibrations) {
        if (Configs.devEnvironment)   console.log("[performanceTeam.js][updateFrontEndData()] calibrations: ", calibrations);

        // Aux array to store frontend data
        var auxMapData = [];
        var auxLabelData = [];

        calibrations?.forEach (calibration => {
            // Create object
            var auxObject = {
                x: calibration.performance,
                y: calibration.potential,
                employee: calibration.employeeName + " " + calibration.employeeSurname,
                size: (calibration.performance + calibration.potential)/2,
            };

            var auxObjectLabel = {
              x: calibration.performance,
              y: calibration.potential,
              employee: calibration.employeeName,
              xOffset:5,
              yOffset:5,
            }
            // Add to frontend array
            auxMapData.push(auxObject);
            auxLabelData.push(auxObjectLabel);
        });

        setMapData([...auxMapData]);
        setLabelData([...auxLabelData]);



    }

    // ---------------------------------------------------------------------------------------------------
    // Filter methods
    // ---------------------------------------------------------------------------------------------------   
    const handleFilterChange = (filteredDataInput) => {
      if (Configs.devEnvironment) console.log("[oneOnOne.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
      setFilteredData(filteredDataInput);
    };

    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
      };
    
    
   // ---------------------------------------------------------------------------------------------------
   // Render methods
   // ---------------------------------------------------------------------------------------------------
  
        return (
          
          <React.Fragment>
          <Header/>
          <Bot />

          <div className="main">
          <CalibrationHeader />
            

          <div className='flex flex-row items-start lg:ml-4'>
            <div className="left-big-view">           

                {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


                    <div className="flex flex-col text-left">
                      {/* Section title  */}
                        <div className="flex flex-row items-center mt-2 md:ml-2 m-1 mb-8">
                            <Tooltip content={t('calibration.map.description')} >
                            <h2 className="section-title">
                            🗺️ {t('calibration.map.title')}
                            </h2>
                            </Tooltip>
                            <a href={companyPreferences?.personalization?.pulseEmailsHelpURL + "#talent-map"} target="_blank" rel="noreferrer">
                              <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                            </a>
                        </div>


                        {/* Filters */}
                        { isOrgLoading ? 
                              <Loader />
                          :
                          (securityLevel?.includes(Configs.securityLevelManager) && teamOrg)
                          || (securityLevel?.includes(Configs.securityLevelHR) && companyOrg)
                          || (securityLevel?.includes(Configs.securityLevelExec) && companyOrg)
                            ? 
                              <div className="flex flex-row items-center justify-start space-x-2 m-4">
                                  {/* Filters widget */}
                                  <div className=''>
                                      <FilterWidget data={(securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) ? companyOrg : teamOrg} 
                                        onFilterChange={handleFilterChange}
                                        teamTypeFilter={true} defaultTeamTypeFilter={(securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) ? "company" : "directs"}
                                        managerFilter={true}
                                        employeeFilter={true}
                                        areaFilter={true} 
                                        subareaFilter={true}
                                      />
                                  </div>
                                  
                              </div>
                            : 
                              null
                            }
                    
                    { isLoading ?
                        <Loader />
                    :
                    
                        (securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelExec))  ? 
                          <div className='flex flex-col'>
                                {companyOrg ? 
                                          <DataTable data={filteredData} columnNames={calibrationTalentMapColumns}
                                            // viewMethod={viewEmployeePlan}
                                            />
                                :
                                  null
                                }                        
                          </div>
                        :
                          null
                      }

                      <SectionSeparator />

                        {/* Quadrant's explanation */}
                        <div className="flex flex-row items-center mt-2 md:ml-2 m-1 mb-8">
                            <Tooltip content={t('calibration.map.guide.description')} >
                            <h2 className="section-title">
                            📖 {t('calibration.map.guide.title')}
                            </h2>
                            </Tooltip>
                        </div>
                        <div className='flex flex-col items-start justify-center text-left space-y-2 bg-white shadow  rounded-lg border text-gray-800 text-sm'>

                                  <table className=" w-full mr-2 text-xs text-center mb-4 bg-white shadow rounded-lg p-8">
                                          <thead>
                                            <tr className="">
                                                <th className="border border-white p-2"></th>
                                                <th className="border border-white p-2 text-left"> {t('name')} </th>
                                                <th className="border border-white p-2 text-left"> {t('description')} </th>
                                                {/* <th className="border border-white p-2">{t('calibration.map.action-plan')}</th> */}
                                            </tr>
                                          </thead>
                                          <tbody>
                                                  {quadrantExplanation?.reverse()?.map((box, index) => (
                                                      <tr className='border-b border-gray-200 p-4  text-xs'>
                                                        <td className=" p-4 ">{box?.emoji}</td>
                                                        <td className=" p-4  text-left">{box?.name} </td>
                                                        <td className=" p-4  text-left">{box?.explanation}</td>
                                                        {/* <td className="p-4 text-sm text-left">{box?.actionPlan}</td> */}
                                                      </tr>

                                                  ))}
                                          </tbody>
                                    </table> 
                                </div>


                        
                    </div>
                  </div>
                    <div className='right-small-view'>
                            <div className=' flex flex-col space-y-8'>


                            <div className="invisible flex flex-row items-center mt-2 md:ml-2 m-1 mb-8">
                                <Tooltip content={t('calibration.map.description')} >
                                <h2 className="section-title">
                                🗺️ {t('calibration.map.title')}
                                </h2>
                                </Tooltip>
                                <a href={companyPreferences?.personalization?.pulseEmailsHelpURL + "#talent-map"} target="_blank" rel="noreferrer">
                                  <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                                </a>
                            </div>
                        
                            <div className='flex flex-col items-start justify-center text-left space-y-2 bg-white shadow  rounded-lg border text-gray-800 text-sm'>

                            {/* Talent distribution */}
                            
                            <div className="flex flex-row items-center p-4">
                                <Tooltip content={t('calibration.map.description')} >
                                  <h4 className="card-title">
                                    🔍 {t('calibration.map.title')}
                                  </h4>
                                </Tooltip>
                            </div>

                            <div className='flex flex-col w-full items-center justify-center space-y-4'>

                              <XYPlot 
                                width={WIDTH}
                                height={HEIGHT}   
                                xDomain={[0, 5]}
                                yDomain={[0, 5]}>


                                    {/* Gridlines */}
                                    <VerticalGridLines tickValues={[0,  5]} />
                                    <HorizontalGridLines tickValues={[0, 5]} />
                                    
                                    {/* Emoji */}
                                    <LabelSeries
                                        data={emojiData} getLabel={d => d.emoji}
                                        style={{opacity: 1}}
                                         />
                                    {/* Values */}
                                    <LabelSeries
                                        data={valuesData} getLabel={d => d.value}
                                        style={{opacity: 1}}
                                        // onValueMouseOver={(datapoint, event)=>{
                                        //   // does something on click
                                        //   // you can access the value of the event
                                        //       setShowHint(datapoint);

                                        //   }}
                                        //   onValueMouseOut={() => setShowHint(null)}
                                        />

                                    {/* Axis */}
                                    <XAxis title={t('calibration.team.performance')} tickValues={[1, 2, 3, 4, 5]} />
                                    <YAxis title={t('calibration.team.potential')} tickValues={[1, 2, 3, 4, 5]} />
                                    {/* <XAxis  tickValues={[]} top={(235*2)/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <XAxis  tickValues={[]} top={(235*2)*2/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <YAxis  tickValues={[]} left={(225*2)/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <YAxis  tickValues={[]} left={(225*2)*2/3} style={{line: {stroke: '#AAAAAA'},}} /> */}
                                    {/* <XAxis  tickValues={[]} top={(HEIGHT-35)/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <XAxis  tickValues={[]} top={(HEIGHT-35)*2/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <YAxis  tickValues={[]} left={(WIDTH-35)/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <YAxis  tickValues={[]} left={(WIDTH-35)*2/3} style={{line: {stroke: '#AAAAAA'},}} /> */}
                                    {/* Taxdown  */}
                                    <XAxis  tickValues={[]} top={(HEIGHT-35)*1/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <XAxis  tickValues={[]} top={(HEIGHT-35)*0.5/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <YAxis  tickValues={[]} left={(WIDTH-35)*2/3} style={{line: {stroke: '#AAAAAA'},}} />
                                    <YAxis  tickValues={[]} left={(WIDTH-35)*2.5/3} style={{line: {stroke: '#AAAAAA'},}} />

                                    { showLabelSeries ? <LabelSeries xOffset={20} data={labelData} getLabel={d => d.employee}  /> : null}
                                    {/* <MarkSeries
                                        className="mark-series-example"
                                        strokeWidth={5}
                                        opacity={1}
                                        sizeRange={[5, 8]}
                                        color ="#BBF7D0"
                                        data={mapData}

                                        onValueClick={(datapoint, event)=>{
                                            // does something on click
                                            // you can access the value of the event
                                            // console.log(datapoint, event)
                                          }}
                                        onValueMouseOver={(datapoint, event)=>{
                                        // does something on click
                                        // you can access the value of the event
                                            setShowHint(datapoint);

                                        }}
                                        onValueMouseOut={() => setShowHint(null)}/> */}
                                    
                                    {showHint ? <Hint className='rounded items-center justify-center shadow-2xl w-1/2 h-auto z-50' value={showHint} /> : null}
                                </XYPlot> 
                              </div>
                            </div>

                              
                          </div>
                    </div>

                </div>
              </div>
        </React.Fragment>
        );
}


export default TalentMap;

